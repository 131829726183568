<template>
  <tr class="similar-clients-list-entry">
    <td class="similar-clients-list-fullname">
      {{ client.fullname }}
    </td>
    <td
      class="similar-clients-list-phone"
    >
      {{ client | phone }}
    </td>
    <td
      class="similar-clients-list-birthdate"
    >
      {{ client.birthdate | birthdate }}
    </td>
  </tr>
</template>
<script>
import formatter from '@/lib/formatters/formatter'
/**
 * @typedef {{
 *   id: number,
 *   fullname: string,
 *   birthdate: string|null,
 *   formatted_phone: string|null,
 *   masked_phone: string|null
 * }} SimilarClient
 */
export default {
  name: 'SimilarClientsEntry',
  filters: {
    /**
     * @param {SimilarClient} client
     */
    phone (client) {
      return client.formatted_phone || client.masked_phone
    },
    /**
     * @param {string|null} birthdate
     */
    birthdate (birthdate) {
      return formatter.formatValue(birthdate, ['date:localDate'])
    },
  },
  props: {
    /**
     * @type {import('vue').PropOptions<SimilarClient>}
     */
    client: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasPhone () {
      return !!this.$options.filters.phone(this.client)
    },
  },
}
</script>
