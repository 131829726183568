<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>
    <si-table
      :spinner="isLoading"
      horizontal
    >
      <template #header>
        <component
          :is="tableHeaderComponent"
          :sortable-column="sortableColumn"
          @sort-change="onSortChange"
        />
      </template>
      <template
        v-if="rows.length"
        #body
      >
        <component
          :is="tableRowComponent"
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
          :row="row"
        />
      </template>
      <template #footer>
        <component
          :is="tableFooterComponent"
          :footer="footer"
          class="total"
        />
      </template>
    </si-table>
    <si-footer v-if="rows.length">
      <pagination
        :page-count="pageCount"
        layout="prev, pager, next"
        class="hidden-print"
        @current-change="loadData"
      />
    </si-footer>
  </si>
</template>

<script>
import Pagination from '@/vue_components/common/pagination'
import Si from '@/vue_components/sort_items/si'
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import SiFooter from '@/vue_components/sort_items/si_footer'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner'

import ReferralsHeaderUngrouped from '@/vue_components/reports/referrals/header/referrals_header_ungrouped'
import ReferralsHeaderGrouped from '@/vue_components/reports/referrals/header/referrals_header_grouped'
import ReferralsHeaderUngroupedReferral
  from '@/vue_components/reports/referrals/header/referrals_header_ungrouped_referral'
import ReferralsHeaderGroupedReferral from '@/vue_components/reports/referrals/header/referrals_header_grouped_referral'
import ReferralsHeaderConsolidated from '@/vue_components/reports/referrals/header/referrals_header_consolidated.vue'

import ReferralsRowUngrouped from '@/vue_components/reports/referrals/row/referrals_row_ungrouped'
import ReferralsRowGrouped from '@/vue_components/reports/referrals/row/referrals_row_grouped'
import ReferralsRowUngroupedReferral from '@/vue_components/reports/referrals/row/referrals_row_ungrouped_referral'
import ReferralsRowGroupedReferral from '@/vue_components/reports/referrals/row/referrals_row_grouped_referral'
import ReferralsRowConsolidated from '@/vue_components/reports/referrals/row/referrals_row_consolidated.vue'

import ReferralsFooterUngrouped from '@/vue_components/reports/referrals/footer/referrals_footer_ungrouped'
import ReferralsFooterGrouped from '@/vue_components/reports/referrals/footer/referrals_footer_grouped'
import ReferralsFooterUngroupedReferral
  from '@/vue_components/reports/referrals/footer/referrals_footer_ungrouped_referral'
import ReferralsFooterGroupedReferral from '@/vue_components/reports/referrals/footer/referrals_footer_grouped_referral'
import ReferralsFooterConsolidated from '@/vue_components/reports/referrals/footer/referrals_footer_consolidated.vue'

import Report from '@/vue_components/mixins/report'
import HorizontalReport from '@/vue_components/mixins/horizontalReport'
import ReportWithTooltips from '@/vue_components/mixins/withTooltips'
import {
  getReportType,
  REFERRALS_GROUPED,
  REFERRALS_GROUPED_REFERRAL,
  REFERRALS_SUMMARY,
  REFERRALS_UNGROUPED,
  REFERRALS_UNGROUPED_REFERRAL,
} from '@/specific/reports/referrals/common'
import SortableReport from '@/vue_components/mixins/sortableReport'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

export default {
  name: 'Referrals',

  components: {
    Pagination,
    Si,
    SiTable,
    SiField,
    SiFooter,
    EpicSpinner,
    ReferralsHeaderUngrouped,
    ReferralsHeaderGrouped,
    ReferralsHeaderUngroupedReferral,
    ReferralsHeaderGroupedReferral,
    ReferralsHeaderConsolidated,

    ReferralsRowUngrouped,
    ReferralsRowGrouped,
    ReferralsRowUngroupedReferral,
    ReferralsRowGroupedReferral,
    ReferralsRowConsolidated,

    ReferralsFooterUngrouped,
    ReferralsFooterGrouped,
    ReferralsFooterUngroupedReferral,
    ReferralsFooterGroupedReferral,
    ReferralsFooterConsolidated,
  },

  mixins: [
    Report,
    HorizontalReport,
    ReportWithTooltips,
    SortableReport,
  ],

  props: {
    clinic: { type: Object, required: true },
    period: { type: String, default: '' },
    groupByEntry: { type: Object, required: true },
    entry: { type: Object, required: true },
    category: { type: Object, required: true },
    medicalUser: { type: Object, required: true },
    city: { type: Object, required: true },
    referral: { type: Object, required: true },
    reportTypeFilter: { type: String, required: true },
    includingDiscounts: { type: String, required: true },
    entryWithOrder: { type: String, required: true },
  },

  data () {
    return {
      reportType: REFERRALS_UNGROUPED,
      footer: {},
    }
  },

  computed: {
    tableFooterComponent () {
      // check for current report layout, not from props
      switch (this.reportType) {
        case REFERRALS_UNGROUPED: return 'ReferralsFooterUngrouped'
        case REFERRALS_GROUPED: return 'ReferralsFooterGrouped'
        case REFERRALS_UNGROUPED_REFERRAL: return 'ReferralsFooterUngroupedReferral'
        case REFERRALS_GROUPED_REFERRAL: return 'ReferralsFooterGroupedReferral'
        case REFERRALS_SUMMARY: return 'ReferralsFooterConsolidated'
        default: return 'ReferralsFooterUngrouped'
      }
    },

    tableHeaderComponent () {
      switch (this.reportType) {
        case REFERRALS_UNGROUPED: return 'ReferralsHeaderUngrouped'
        case REFERRALS_GROUPED: return 'ReferralsHeaderGrouped'
        case REFERRALS_UNGROUPED_REFERRAL: return 'ReferralsHeaderUngroupedReferral'
        case REFERRALS_GROUPED_REFERRAL: return 'ReferralsHeaderGroupedReferral'
        case REFERRALS_SUMMARY: return 'ReferralsHeaderConsolidated'
        default: return 'ReferralsHeaderUngrouped'
      }
    },

    tableRowComponent () {
      switch (this.reportType) {
        case REFERRALS_UNGROUPED: return 'ReferralsRowUngrouped'
        case REFERRALS_GROUPED: return 'ReferralsRowGrouped'
        case REFERRALS_UNGROUPED_REFERRAL: return 'ReferralsRowUngroupedReferral'
        case REFERRALS_GROUPED_REFERRAL: return 'ReferralsRowGroupedReferral'
        case REFERRALS_SUMMARY: return 'ReferralsRowConsolidated'
        default: return 'ReferralsRowUngrouped'
      }
    },

    subtitleParts () {
      return [
        this.period,
        this.clinic.name,
        this.groupByEntry.name,
        this.entry.name,
        this.category.name,
        this.medicalUser.name,
        this.city.name,
        this.referral.name,
      ]
    },
  },

  created () {
    this.reportClient.init({
      url: Routes.report_referrals_path(),
    })
    this.requestBuilder.addStep(() => {
      // устанавливаем reportType тут отдельно, потому что он вычисляется,
      // а не устанавливается в фильтрах.
      // Помимо этого, он должен быть установлен в компоненте в один тик с обновлением данных таблицы,
      // чтобы данные заполнили нужные компоненты.
      const params = ReportFiltersFactory.defineFor('referrals', this)
      params.reportType = getReportType(this)

      return params
    })
  },

  methods: {
    fillTable (result) {
      // set report type here to avoid showing old table data in new layout
      this.reportType = getReportType(this)
      if (this.reportType === REFERRALS_SUMMARY) {
        this.rows = result.data
        this.footer = result.total

        return
      }

      if (!this.referral.id) {
        this.rows = result.data.map((row) => {
          row.firstEntry = row.entries.shift()

          return row
        })
      } else {
        this.rows = result.data
      }

      this.footer = result.total
    },

    afterReportUpdated () {
      this.updateTooltips()
    },
  },
}
</script>
