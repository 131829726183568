export default {

  _selectAllOnClick () {
    this.showUsersWithoutCabinets = false
    this.selectedCIDsInSID[this.selectedSID] = this.visibleCabinets.map((cabinet) => cabinet.id)
    this._updateStore()
    this._changeCabinetIds()
  },

  _unselectAllOnClick () {
    this.showUsersWithoutCabinets = false
    this.selectedCIDsInSID[this.selectedSID] = []
    this._updateStore()
    this._changeCabinetIds()
  },

  _specialtyOnClick (event) {
    this.selectedSID = parseInt(event.currentTarget.attributes['data-id'].value, 10)
    this._setAvailableUserIds()
    this._updateStore()
    this._changeCabinetIds()
  },

  _cabinetOnClick (id) {
    this.showUsersWithoutCabinets = false
    const selectedCabinets = this.selectedCIDsInSID[this.selectedSID]
    const index = selectedCabinets.indexOf(id)

    if (index === -1) {
      selectedCabinets.push(id)
    } else {
      selectedCabinets.splice(index, 1)
    }

    this._updateStore()
    this._changeCabinetIds()
  },

  _showSpecialtiesOnClick () {
    this.showSpecialtiesList = !this.showSpecialtiesList
    this._setVisibleSpecialties()
    this._updateStore()
  },

  _showUsersWithoutCabinetsOnClick () {
    this.showUsersWithoutCabinets = !this.showUsersWithoutCabinets
    this._updateStore()
    this._changeCabinetIds()
  },
}
