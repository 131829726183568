<template>
  <laboratory-form-extended
    :can-manage="canManage"
    :is-edit-mode="isEditMode"
    :validation-messages="validationMessages"
    :mode="mode"
    :title.sync="tempSelectedLaboratory.title"
    :selected-laboratory="selectedLaboratory"
    :entries-extra-charge-value.sync="tempSelectedLaboratory.entriesExtraChargeValue"
    :entries-extra-charge-type.sync="tempSelectedLaboratory.entriesExtraChargeType"
    :allow-same-entries.sync="tempSelectedLaboratory.allowSameEntries"
    :barcode-template.sync="tempSelectedLaboratory.barcodeTemplate"
    :barcode-template-caret-position.sync="barcodeTemplateCaretPosition"
    :is-special-laboratory="isSpecialLaboratory"
    @successSubmit="onSubmit"
    @ask-delete="deleteLaboratory"
  >
    <template #extended-fields>
      <span v-if="!tempSelectedLaboratoryComponentName" />
    </template>

    <template #allow-same>
      <span v-if="!allowSameLaboratories" />
    </template>

    <template #barcode-template>
      <span v-if="!barcodeTemplateLaboratories" />
    </template>

    <template #barcode-variables>
      <barcode-variable-editor
        v-if="barcodeTemplateLaboratories"
        :variables="barcodeVariables"
        @addVariable="addVariable"
        @ask-reset="resetBarcode(tempSelectedLaboratory.id)"
      />
    </template>

    <template v-if="isSpecialLaboratory">
      <panel-heading
        :title="t('laboratories.auth_data')"
        icon="fad fa-key"
        class="mt-10"
        custom-class="panel-orange"
      >
        <div class="form-label">
          <label for="analysis_laboratory_entries_clinic_identifier">
            {{ t('clinic') }}
          </label>
        </div>

        <div class="form-value">
          <simple-select
            v-model="clinicId"
            :items="clinics"
            :validation-messages="validationMessages.clinicId"
            attribute-label="title"
            attribute-value="id"
          />
        </div>

        <component
          :is="tempSelectedLaboratoryComponentName"
          v-bind.sync="authProps"
          :can-manage="canManage"
          :validation-messages="validationMessages.authValidations[clinicId] || {}"
        />
      </panel-heading>
    </template>
  </laboratory-form-extended>
</template>

<script>
import LaboratoryFormBase
  from '@/vue_apps/catalogs_root/analysis_laboratories/components/base_forms/LaboratoryFormBase.vue'
import HelixForm from '@/vue_apps/catalogs_root/analysis_laboratories/components/forms/HelixForm.vue'
import InvitroForm from '@/vue_apps/catalogs_root/analysis_laboratories/components/forms/InvitroForm.vue'
import {
  createLaboratory,
  SPECIAL_CLINICS,
} from '@/vue_apps/catalogs_root/analysis_laboratories/const/create_laboratories'
import {
  analysisLaboratoriesEndpoint,
} from '@/api_entities/catalogs/analysis_laboratories/analysis_laboratories_endpoint'
import LaboratoryFormExtended
  from '@/vue_apps/catalogs_root/analysis_laboratories/components/base_forms/LaboratoryFormExtended.vue'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect.vue'
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import { cloneDeep } from 'lodash'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import {
  mappedVariablesLabels,
  replaceVariables,
  variables,
  variablesParser,
} from '@/vue_apps/catalogs_root/analysis_laboratories/const/variables'
import { insertToString } from '@/_medods_standart_library/msl'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import {
  laboratoriesValidationsMixin,
} from '@/vue_apps/catalogs_root/analysis_laboratories/mixins/laboratories_validations'
import KdlForm from '@/vue_apps/catalogs_root/analysis_laboratories/components/forms/KdlForm.vue'
import KdlCheckupsForm from '@/vue_apps/catalogs_root/analysis_laboratories/components/forms/KdlCheckupsForm.vue'
import BarcodeVariableEditor
  from '@/vue_apps/catalogs_root/analysis_laboratories/components/helix_variable_editor/BarcodeVariableEditor.vue'

const ALLOW_SAME_LABS = ['helix']
const BARCODE_TEMPLATE_LABS = ['helix', 'kdl', 'kdl_checkups']

export default {
  name: 'LaboratoryMaster',
  components: {
    BarcodeVariableEditor,
    PanelHeading,
    SimpleSelect,
    LaboratoryFormExtended,
    LaboratoryFormBase,
    HelixForm,
    InvitroForm,
    KdlForm,
    KdlCheckupsForm,
  },

  mixins: [SpinnerHolder, laboratoriesValidationsMixin],

  props: {
    selectedLaboratory: {
      type: Object,
      default: () => ({}),
    },

    clinics: {
      type: Array,
      default: () => [],
    },

    laboratoryList: {
      type: Object,
      default: () => ({}),
    },

    canManage: Boolean,

    isReadOnly: Boolean,

    mode: {
      type: String,
      default: MODES.SHOW,
    },
  },

  data () {
    return {
      tempSelectedLaboratory: {},
      tempSelectedLaboratoryComponentName: '',
      barcodeTemplateCaretPosition: 0,

      clinicId: gon.application.current_clinic.id,
      labellerErrors: false,
      variables,
    }
  },

  computed: {
    getAuthAnalysisLaboratories () {
      return this.tempSelectedLaboratory.authAnalysisLaboratories || {}
    },

    authProps () {
      return this.getAuthAnalysisLaboratories[this.clinicId] || {}
    },

    allowSameLaboratories () {
      if (!this.selectedLaboratory.systemName) { return false }

      return ALLOW_SAME_LABS.includes(this.selectedLaboratory.systemName || '-')
    },

    barcodeVariables () {
      return variables(this.selectedLaboratory.systemName)
    },

    barcodeTemplateLaboratories () {
      if (!this.selectedLaboratory.systemName) { return false }

      return BARCODE_TEMPLATE_LABS.includes(this.selectedLaboratory.systemName || '-')
    },

    isEditMode () {
      return this.mode === MODES.EDIT
    },

    isAuthFieldsEnabled () {
      switch (this.tempSelectedLaboratory.systemName) {
        case SPECIAL_CLINICS.HELIX: return gon.specific.helix_enabled
        case SPECIAL_CLINICS.INVITRO: return gon.specific.invitro_enabled
        case SPECIAL_CLINICS.KDL: return gon.specific.kdl_enabled
        case SPECIAL_CLINICS.KDL_CHECKUPS: return gon.specific.kdl_enabled
        default: return false
      }
    },

    isSpecialLaboratory () {
      return Boolean(this.tempSelectedLaboratoryComponentName) && this.isAuthFieldsEnabled
    },
  },

  watch: {
    selectedLaboratory: {
      immediate: true,
      handler (to) {
        this.setComponentName(to && to.systemName)
        this.setCurrentLaboratory(to)
      },
    },

    clinics () {
      this.setComponentName(this.selectedLaboratory && this.selectedLaboratory.systemName)
      this.setCurrentLaboratory(this.selectedLaboratory)
    },
  },

  methods: {
    setComponentName (systemName) {
      if (systemName === SPECIAL_CLINICS.HELIX) { this.tempSelectedLaboratoryComponentName = 'helix-form' }
      if (systemName === SPECIAL_CLINICS.INVITRO) { this.tempSelectedLaboratoryComponentName = 'invitro-form' }
      if (systemName === SPECIAL_CLINICS.KDL) { this.tempSelectedLaboratoryComponentName = 'kdl-form' }
      if (systemName === SPECIAL_CLINICS.KDL_CHECKUPS) { this.tempSelectedLaboratoryComponentName = 'kdl-checkups-form' }
      if (!systemName) { this.tempSelectedLaboratoryComponentName = '' }
    },

    setCurrentLaboratory (selectedLaboratory) {
      const barcodeTemplate = replaceVariables(
        selectedLaboratory.barcodeTemplate,
        variablesParser(selectedLaboratory.barcodeTemplate || ''),
        mappedVariablesLabels(this.selectedLaboratory.systemName)
      )

      this.tempSelectedLaboratory = {
        ...createLaboratory(selectedLaboratory.systemName, this.clinics),
        ...selectedLaboratory,
        barcodeTemplate,
      }

      if (selectedLaboratory && selectedLaboratory.id) {
        this.fetchAuthData(selectedLaboratory.id)
      }

      this.resetValidations()
    },

    fetchAuthData (id) {
      const promise = analysisLaboratoriesEndpoint.fetchAuthData(id)
        .then((data) => {
          if (!Object.keys(data).length) { return }
          Object.assign(this.tempSelectedLaboratory.authAnalysisLaboratories, data)
        })
        .catch((err) => {
          if (err.status === 404) { return }

          Utils.reportError(
            'LaboratoryMaster:fetchAuthData()',
            reportErrorText('read_message', 'auth_data')
          )(err)
        })

      this.withSpinner(promise)
    },

    onSubmit () {
      if (this.hasErrors()) { return }

      const tempLaboratory = cloneDeep(this.tempSelectedLaboratory)
      const sameTitle = Object.keys(this.laboratoryList).includes(tempLaboratory.title)
      const sameId = tempLaboratory.id !== this.laboratoryList[tempLaboratory.title]

      if (sameTitle && sameId) {
        return Notificator.error(t('laboratories.laboratory_already_exist'))
      }

      analysisLaboratoriesEndpoint.submit(tempLaboratory)
        .then(() => {
          this.$emit('successSubmit')

          if (!tempLaboratory.id) {
            return Utils.reportSuccess(t('laboratories.success_creation'))()
          }

          Utils.reportSuccess(t('laboratories.success_update'))()
        })
        .catch(Utils.reportError(
          'LaboratoryMaster:onSubmit()',
          reportErrorText('submit_message', 'clinic_data')
        ))
        .finally(() => { this.resetValidations() })

      if (tempLaboratory.id) { return }

      this.tempSelectedLaboratory = createLaboratory()
    },

    deleteLaboratory () {
      analysisLaboratoriesEndpoint.destroy(this.tempSelectedLaboratory.id)
        .then(() => {
          this.$emit('successDelete')
        })
        .then(Utils.reportSuccess(t('laboratories.success_deletion')))
        .catch((err) => {
          Utils.reportError(
            'AnalysisLaboratories:deleteLaboratory()',
            reportErrorText('delete_message', 'laboratory')
          )(err)
        })
    },

    addVariable (variable) {
      const barcodeTemplate = this.tempSelectedLaboratory.barcodeTemplate
      const position = this.barcodeTemplateCaretPosition

      this.tempSelectedLaboratory.barcodeTemplate = insertToString(
        barcodeTemplate,
        `{{${variable.label}}}`,
        position
      )
    },

    resetBarcode (id) {
      analysisLaboratoriesEndpoint.fetchDefaultBarcode(id)
        .then((data) => {
          this.tempSelectedLaboratory.barcodeTemplate = replaceVariables(
            data.barcode_template,
            variablesParser(data.barcode_template || ''),
            mappedVariablesLabels(this.selectedLaboratory.systemName)
          )
        })
        .catch((err) => {
          Utils.reportError(
            'AnalysisLaboratories:resetBarcode()',
            reportErrorText('read_message', 'barcode_default')
          )(err)
        })
    },
  },
}
</script>
