import { CONTAINER_ICONS_CLASSES, CONTAINER_ICONS_TEXTS } from '@/vue_components/analysis_orders/constants'

export const HELIX_CONTAINER_CODES = Object.freeze({
  'ВПКРАСН': CONTAINER_ICONS_CLASSES.RED_CODE,
  'ВПГОЛУБ': CONTAINER_ICONS_CLASSES.BLUE_CODE,
  'ВПЗЕЛЕНАЯ': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  'ВПЗЕЛЕНГЕЛЬ': CONTAINER_ICONS_CLASSES.GREEN_GEL_CODE,
  'ВПФИОЛ': CONTAINER_ICONS_CLASSES.VIOLET_CODE,
  'ВПФИОЛ2': CONTAINER_ICONS_CLASSES.VIOLET_CODE_2,
  'ВПСЕРАЯ': CONTAINER_ICONS_CLASSES.GRAY_CODE,
  'ВПЖЕЛТГЕЛЬ': CONTAINER_ICONS_CLASSES.YELLOW_CODE,
})

export const HELIX_CONTAINER_TEXT = Object.freeze({
  'ВПКРАСН': CONTAINER_ICONS_TEXTS.RED_CODE,
  'ВПГОЛУБ': CONTAINER_ICONS_TEXTS.BLUE_CODE,
  'ВПЗЕЛЕНАЯ': CONTAINER_ICONS_TEXTS.GREEN_CODE,
  'ВПЗЕЛЕНГЕЛЬ': CONTAINER_ICONS_TEXTS.GREEN_GEL_CODE,
  'ВПФИОЛ': CONTAINER_ICONS_TEXTS.VIOLET_CODE,
  'ВПФИОЛ2': CONTAINER_ICONS_TEXTS.VIOLET_CODE_2,
  'ВПСЕРАЯ': CONTAINER_ICONS_TEXTS.GRAY_CODE,
  'ВПЖЕЛТГЕЛЬ': CONTAINER_ICONS_TEXTS.YELLOW_CODE,
})
