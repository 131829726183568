<template>
  <div class="protocol-modal-wrapper">
    <protocol-container :protocol="protocol" />

    <protocol-modal
      :protocol="protocol"
      :visible.sync="modalVisible"
      :is-protocol-changed.sync="isProtocolChanged"
      :is-external-created-protocol="isExternalCreatedProtocol"
      :egisz-enabled="egiszEnabled"
      :modal-type="modalType"
      :legal-representatives="legalRepresentatives"
      :disable-buttons="loading"
      :show-legals-selector.sync="showLegalsSelector"
      :confirm-protocol-opening-callback="
        () => confirmProtocolOpening({ isTrusted: true, externalOpening: true })
      "
      @registerValidator="onRegisterValidator"
      @close="closeModal"
      @openProtocolEditor="__onOpenProtocolEditor"
      @saveProtocol="onProtocolSave"
      @composeProtocol="confirmProtocolOpening"
      @onProtocolEditorClose="__removeDraft"
    >
      <template #templateOptions>
        <protocol-template-options
          :client-id="clientId"
          :protocol-id="protocol.id"
          @resetProtocolDataByTemplate="resetProtocolDataByTemplate"
          @saveAsTemplate="saveAsTemplate"
        />
      </template>

      <template #protocolDraftButton>
        <m-button
          v-if="protocolDraftData"
          :text="t('protocols.restoreFromDraft')"
          icon="history"
          type="text"
          @click="onRestoreFromDraft"
        />
      </template>

      <template #semdList>
        <semd-modal-app
          class="mb-indent-mid"
          :semd-submit-loading="semdSubmitLoading"
          :is-semds-empty="!semdList.data.length"
          :is-last-semd-outdated="Boolean(lastSemd?.isOutdatedVersion())"
          :is-new-source="!protocol.id"
          :is-source-changed="isProtocolChanged"
          :has-semd-type="Boolean(protocol.semdType)"
          @registerOpenSemd="openSemd = $event"
          @updateList="fetchSemdList()"
          @submitClick="withSpinner(onBeforeOpenSemd(lastSemd), 'semdSubmitLoading')"
        />

        <m-si-generator
          v-if="protocol.id && protocol.semdType && egiszEnabled"
          :items="semdList.data"
          :page-count="semdList.totalPages"
          :current-page="semdList.currentPage"
          :si-generator-schema="semdSchema"
          :nfr-text="t('semds.semdsNotFound')"
          @update:currentPage="fetchSemdList"
          @onItemClick="onSemdItemClick"
        />
      </template>
    </protocol-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { ProtocolAPI } from '@/vue_apps/Protocols/entities/ProtocolAPI'
import { initProtocolEditor } from '@/vue_apps/Protocols/const/initProtocolEditor'
import { updateSemdTypeInExternals } from '@/vue_apps/Protocols/const/updateSemdTypeInExternals'
import ProtocolContainer from '@/vue_apps/Protocols/components/ProtocolContainer.vue'
import ProtocolModal from '@/vue_apps/Protocols/components/ProtocolModal.vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import { snakeToCamel } from '@/_api/_requests/helpers'
import ProtocolTemplateOptions from '@/vue_apps/Protocols/components/ProtocolModal/ProtocolTemplateOptions.vue'
import htmlHelper from '@/plugins/dynamic_forms/helpers/htmlHelper'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { MListService } from '@/_api/_requests/MListService'
import { MSemdPresenter } from '@/_api/MSemdApi/MSemdPresenter'
import { SemdListItem } from '@/vue_apps/Semds/entities/list/SemdListItem'
import { getSemdListSchema } from '@/vue_apps/Semds/entities/list/getSemdListSchema'
import { SEMDS_OWNER_TYPES } from '@/vue_apps/Semds/SemdModal/const/const'
import { drawPrintAreaSelector } from '@/vue_apps/Protocols/const/drawPrintAreaSelector'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { ProtocolModalWrapperMixin } from '@/vue_apps/Protocols/mixins/ProtocolModalWrapperMixin'
import SemdModalApp from '@/vue_apps/Semds/SemdModal/SemdModalApp.vue'
import { SemdsUtils } from '@/vue_apps/Semds/entities/SemdsUtils'
import { GLOBAL_DOUBLE_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ProtocolDraftStorage } from '@/plugins/dynamic_forms/components/editor/ProtocolDraftStorage'

export default defineComponent({
  name: 'ProtocolModalWrapper',
  components: {
    MButton,
    SemdModalApp,
    MSiGenerator,
    ProtocolTemplateOptions,
    ProtocolModal,
    ProtocolContainer,
  },

  mixins: [
    ProtocolModalWrapperMixin,
    ModalMixin,
    SpinnerHolder,
    ValidationParentMixin,
  ],

  props: {
    clientId: { type: Number, required: true },
    clinic: { type: Object, required: true },
    doctor: { type: Object, required: true },
    entry: { type: Object, default: null },

    configurationPrintEntryTitle: Boolean,
    configurationPrintEntryInfo: Boolean,
    configurationPrintClinicHeader: Boolean,
    configurationPrintDoctorSignature: Boolean,
  },

  emits: [
    'registerOpenProtocol',
    'submitted',
    'generatePdf',
  ],

  data () {
    return {
      protocol: new ProtocolAPI(),
      isProtocolChanged: false,
      showLegalsSelector: false,

      semdSchema: getSemdListSchema(),
      semdList: new MListService(
        { ownerType: SEMDS_OWNER_TYPES.PROTOCOL },
        new MSemdPresenter(),
        { listItemEntity: SemdListItem }
      ),

      /* eslint-disable */
      /**
       * @param {number} [id]
       * @param {ProtocolAPI} [source]
       * @param {number} [lastSemdId]
       */
      async openSemd ({ id, source, lastSemdId }) {},
      /* eslint-enable */

      semdSubmitLoading: false,
      /** @type {IProtocolDraft} */
      protocolDraftData: null,
    }
  },

  computed: {
    isExternalCreatedProtocol () {
      return Boolean(
        this.protocol.id &&
        this.protocol.semdType &&
        !this.protocol.semdsDocumentDetailsAttributes
      )
    },

    egiszEnabled () {
      return gon.application.egisz_module_enabled
    },

    modalType () {
      return this.protocol.id
        ? MODES.WARNING
        : MODES.SUCCESS
    },

    legalRepresentatives () {
      return snakeToCamel(gon.specific.client.legal_representatives) || []
    },

    /** @return {SemdListItem} */
    lastSemd () {
      if (!this.protocol.id) { return null }

      return this.semdList.data[0]
    },
  },

  watch: {
    'protocol.semdType': {
      immediate: true,
      handler (to) {
        updateSemdTypeInExternals(to)
      },
    },
  },

  created () {
    this.$emit('registerOpenProtocol', this.openProtocol.bind(this))

    this.$pubSub.subscribe('onProtocolForceSave', (templateHtml) => {
      this.protocol.setValue('templateHtml', templateHtml)
      this.openModal()
    })

    this.$pubSub.subscribe('setProtocolChanged', (value) => {
      this.__setProtocolChanged(value !== false)
    })

    this.$pubSub.subscribe('onSaveAsTemplateClose', () => {
      this.openModal()
    })

    this.$pubSub.subscribe('onProtocolEditFinished', () => {
      this.protocolDraftData = null
    })
  },

  beforeDestroy () {
    this.$pubSub.unsubscribeAll('onSaveAsTemplateClose')
    this.$pubSub.unsubscribeAll('onProtocolForceSave')
    this.$pubSub.unsubscribeAll('setProtocolChanged')
    this.$pubSub.unsubscribeAll('onProtocolEditFinished')
  },

  methods: {
    saveAsTemplate () {
      this.closeModal()
      EntryTemplateSelector.newTemplate()
    },

    /** @param { ITemplateRecordsTreeItem } templateRecord */
    async resetProtocolDataByTemplate (templateRecord) {
      const result = await this.confirmProtocolOpening({ isTrusted: true, externalOpening: true })
      if (result?.cancel) { return }

      this.protocol.changeTemplate(templateRecord)

      const semdType = SemdsUtils.extractSemdType(
        templateRecord.semdTemplateDocumentDetail?.semdType ||
          templateRecord.semdType
      )

      if (semdType) {
        this.protocol.prohibitChangeSemdType()
      }

      Notificator.success(t('protocol_has_been_rewritten'))

      await this.confirmProtocolOpening({ isTrusted: true })

      this.$nextTick(() => {
        this.__setProtocolChanged()
        htmlHelper.clearHtml()
      })
    },

    /**
     * @param {number|null} [id]
     * @param {{
     *   forceOpenEditor?: boolean
     *   withRestoreFromDraft?: boolean
     * }} [config]
     */
    async openProtocol (id = null, config = {}) {
      application.protocolModalOpened = true
      this.protocolDraftData = new ProtocolDraftStorage(id).restore()

      this.__reinitProtocolEditor(id)
      if (id) {
        await this.protocol.load()
        this.__setProtocolChanged(this.isExternalCreatedProtocol)
      }

      this.openModal()
      this.initEditorEntities()
      await this.fetchSemdList()

      await this.$nextTick()

      if (config.forceOpenEditor) {
        setTimeout(() => {
          window.history.replaceState(null, '', window.location.pathname)

          config.withRestoreFromDraft
            ? this.onRestoreFromDraft()
            : this.confirmProtocolOpening({ isTrusted: true })
        })
      }
    },

    initEditorEntities () {
      setTimeout(() => {
        initProtocolEditor(this.protocol.templateData)
        drawPrintAreaSelector(this.protocol.templateData)
      })
    },

    /**
     * @param {PointerEvent | { isTrusted: boolean, externalOpening?: boolean }} event
     *
     * isTrusted - флаг, говорящий о том, что это событие было вызвано из браузера (никто не мешает "прикинуться" браузером)
     * externalOpening - флаг, говорящий, что открытием редактора протокола будет заниматься вызывающий метод
     *
     * @return {Promise<void | { cancel: boolean }>}
     */
    async confirmProtocolOpening (event) {
      if (!event.isTrusted) { return }
      event instanceof PointerEvent && event.stopPropagation()

      if (!this.lastSemd) { return this.__openProtocol(event) }

      if (this.lastSemd.isRegisteredInEgisz()) {
        return await this.__newSemdApprovedConfirm(event)
      }

      if (this.lastSemd.isSuccessFullySent()) {
        window.Notificator.warning(window.t('semds.errors.successfullySentSemdsExists'))

        return { cancel: true }
      }

      if (this.lastSemd.isSigned() && !this.protocol.newSignatureApproved && !this.lastSemd.isOutdatedVersion()) {
        return await this.__newSignatureApprovedConfirm(event)
      }

      this.__openProtocol(event)
    },

    /**
     * При наличии ошибок ОБЯЗАТЕЛЬНО возвращать { errors: truthy } значение
     *
     * @param forceClose
     * @return {Promise<{errors?: boolean, cancel?: boolean}|String[]|void>}
     */
    async onProtocolSave (forceClose = false) {
      if (this.hasValidationErrors()) { return { errors: true } }

      const oldProtocolId = this.protocol.id || null
      const isUnchangedExistedProtocol = oldProtocolId && !this.isProtocolChanged
      if (isUnchangedExistedProtocol && forceClose) {
        this.__removeDraft(oldProtocolId)

        return this.closeModal()
      }

      const result = await this.withSpinner(this.__protocolSave(), 'loading', GLOBAL_DOUBLE_DEBOUNCE_DEFAULT)
      if (result?.errors) { return result }

      await this.fetchSemdList()

      if (forceClose) {
        this.__removeDraft(oldProtocolId)

        return this.closeModal()
      }
    },

    async fetchSemdList (page = 1) {
      if (!this.protocol.id) { return }
      this.semdList.setFilterValue('ownerId', this.protocol.id)
      await this.withSpinner(this.semdList.fetchPage(page))
    },

    async onSemdItemClick (semd) {
      if (!this.$security.canViewEgisz) { return }

      if (this.isProtocolChanged) {
        return Notificator.warning(t('semds.errors.saveProtocolRequired'))
      }

      await this.openSemd({ id: semd.id, source: this.protocol })
    },

    async onBeforeOpenSemd (semd) {
      const newSemdRequired =
          (this.isProtocolChanged && this.protocol.newSemdApproved) ||
          this.lastSemd?.isOutdatedVersion()

      if (this.isExternalCreatedProtocol) {
        this.__setProtocolChanged()
      }

      if (this.isProtocolChanged) {
        const result = await this.onProtocolSave()

        if (result?.errors) { return }
      }

      if (newSemdRequired) {
        return await this.openSemd({
          lastSemdId: this.lastSemd.id,
          source: this.protocol,
        })
      }

      await this.openSemd({ id: semd?.id, source: this.protocol })
    },

    async onRestoreFromDraft () {
      if (!this.protocolDraftData) { return }

      const oldAttributes = {
        printingTools: this.protocol.getPrintingTools(),
        templateData: this.protocol.templateData,
        templateHtml: this.protocol.templateHtml,
        semdType: SemdsUtils.extractSemdType(this.protocol.semdType),
      }

      try {
        ProtocolDraftStorage.setProtocolValues(this.protocol, this.protocolDraftData)

        document.querySelector('input#template_data').value =
            JSON.stringify(this.protocolDraftData.templateData)
        document.querySelector('input#template_html').value =
            this.protocolDraftData.templateHtml

        await this.confirmProtocolOpening({ isTrusted: true })
      } catch (e) {
        ProtocolDraftStorage.setProtocolValues(this.protocol, oldAttributes)

        return window.Notificator.error(t('protocols.cantRestoreFromDraft'))
      }
    },
  },
})
</script>
