const createDefaultDuration = () => {
  return {
    id: null,
    duration: '',
  }
}

const getValidationMessages = () => ({
  duration: [],
})

export const creators = Object.freeze({
  formDataCreator: createDefaultDuration,
  validationMessagesCreator: getValidationMessages,
})
