<template>
  <div class="flex gap-indent-small">
    <m-select-lazy
      :value="value"
      :label="t('referral')"
      :fetch-method="fetch()"
      :search-method="(params) => referralsPresenter.search(params)"
      :m-fixed-height="false"
      :placeholder="t('selectToAll')"
      option-label="shortName"
      use-first-time-fetch
      use-user-mode
      filterable
      @change="$emit('change', $event)"
      @changeByLazy="$emit('changeByLazy', $event)"
    />

    <m-button
      icon="referral"
      type="success"
      :tooltip="t('new_referral')"
      @click="referralModalVisibility = true"
    />

    <m-modal
      :visible.sync="referralModalVisibility"
      width="600px"
      use-body-zero-top-padding
      use-hidden-footer
    >
      <template #header>
        <m-icon
          class="mr-5"
          icon="referral"
        />
        <span>{{ t('new_referral') }}</span>
      </template>

      <referral-editor
        class="w-100"
        @submitReferral="onSubmitReferral"
      />

      <template #footer />
    </m-modal>
  </div>
</template>

<script>
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import ReferralEditor from '@/vue_apps/catalogs_root/Referrals/components/ReferralEditor.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MReferralPresenter } from '@/_api/MReferral/MReferralPresenter'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'ReferralListAdd',
  components: { MButton, MModal, ReferralEditor, MIcon, MSelectLazy },
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: PropsTypes.Object(),
  },

  data () {
    return {
      referralModalVisibility: false,
      referralsPresenter: new MReferralPresenter(),
    }
  },

  methods: {
    fetch () {
      return (params) => this.referralsPresenter.list({
        ...params,
        withInternal: true,
      })
    },

    onSubmitReferral (referral) {
      this.$emit('change', referral)
      this.$emit('changeByLazy', referral)

      this.referralModalVisibility = false
    },
  },
}
</script>
