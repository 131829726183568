<template>
  <div class="egisz-user-tab">
    <div class="egisz-user-tab__register-in-frmr">
      <label>
        <el-switch
          v-model="currentUser.registered_in_frmr"
        />
        <span :class="{ 'text-primary': currentUser.registered_in_frmr }">
          {{ t('egisz.components.egisz_user_tab.registered_in_frmr') }}
        </span>
        <input
          type="hidden"
          name="user[registered_in_frmr]"
          :value="Boolean(currentUser.registered_in_frmr)"
        >
      </label>

      <div
        v-if="currentUser.registered_in_frmr"
        v-loading="loading"
        class="egisz-user-tab__user-fields"
      >
        <div class="flex-form">
          <div class="form-label">
            <label for="user[nsi_speciality_id]">
              {{ t('egisz.components.egisz_user_tab.specialty') }} <required-mark />
            </label>
          </div>
          <div class="form-value">
            <catalog-tree-modal
              id="user[nsi_speciality_id]"
              :tree-data="specialties"
              :value.sync="currentUser.nsi_speciality_id"
            />
          </div>

          <div class="form-label">
            <label for="user[nsi_position_id]">
              {{ t('egisz.components.egisz_user_tab.position') }} <required-mark />
            </label>
          </div>
          <div class="form-value">
            <input
              type="hidden"
              name="user[nsi_position_id]"
              :value="currentUser.nsi_position_id"
            >
            <reusable-local-n-s-i-list
              :value.sync="currentUser.nsi_position_id"
              dictionary="positions"
              use-custom-result="simple"
              full-width
              use-fetch-one
              :m-fixed-height="false"
            />
          </div>

          <div class="form-label">
            <label for="user[snils]">
              {{ t('egisz.components.egisz_user_tab.snils') }} <required-mark />
            </label>
          </div>
          <div class="form-value">
            <the-mask
              id="user[snils]"
              v-model="currentUser.snils"
              mask="###-###-### ##"
              name="user[snils]"
              class="w-100"
            />
          </div>

          <div class="form-label">
            <label
              v-tooltip="t('egisz.components.egisz_user_tab.clinics_in_frmo_tooltip')"
              for="user[frmr_clinic_ids]"
            >
              {{ t('egisz.components.egisz_user_tab.clinics_in_frmo') }}
            </label>
            <required-mark />
          </div>
          <div class="form-value">
            <input
              type="hidden"
              name="user[frmr_clinic_ids]"
              :value="currentUser.frmr_clinic_ids.join(',')"
            >
            <span
              v-if="!clinics.length"
              class="prompt-notice"
            >
              {{ t('egisz.frmo_clinics_not_found_1') }} <br>
              {{ t('egisz.frmo_clinics_not_found_2') }}
            </span>
            <el-checkbox-group
              id="user[frmr_clinic_ids]"
              v-model="currentUser.frmr_clinic_ids"
              size="small"
            >
              <el-checkbox
                v-for="clinic in clinics"
                :key="`clinic:${clinic.id}`"
                :label="clinic.id"
                border
              >
                {{ clinic.title }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createEmptyUser } from '@/vue_components/egisz/egisz_user_tab/const'
import { TheMask } from 'vue-the-mask'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { mapActions, mapGetters } from 'vuex'
import CatalogTreeModal from '@/vue_components/egisz/egisz_user_tab/components/CatalogTreeModal'
import RequiredMark from '@/vue_components/common/RequiredMark/RequiredMark'
import ReusableLocalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableLocalNSIList.vue'

export default {
  name: 'EgiszUserTab',
  components: {
    ReusableLocalNSIList,
    RequiredMark,
    CatalogTreeModal,
    TheMask,
  },

  mixins: [SpinnerHolder],

  props: {
    user: {
      type: Object,
      required: true,
    },

    clinics: {
      type: Array,
      required: true,
    },

    canManage: Boolean,
  },

  data () {
    return {
      currentUser: createEmptyUser(),
    }
  },

  computed: {
    ...mapGetters('egisz/user', {
      specialties: 'GET_SPECIALTIES',
    }),
  },

  watch: {
    user (to) {
      this.fillCurrentUser(to || createEmptyUser())
    },
  },

  created () {
    this.loadCatalogs()

    if (!this.user) { return }
    this.fillCurrentUser(this.user)
  },

  methods: {
    ...mapActions('egisz/user', [
      'loadCatalogs',
    ]),

    fillCurrentUser (user) {
      this.currentUser = {
        registered_in_frmr: user.registered_in_frmr,
        nsi_speciality_id: user.nsi_speciality_id ? `${user.nsi_speciality_id}` : null,
        nsi_position_id: user.nsi_position_id || null,
        snils: user.snils,
        frmr_clinic_ids: user.frmr_clinic_ids || [],
      }
    },
  },
}
</script>
