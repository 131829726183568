<template>
  <div id="biomaterial_selector">
    <m-select
      v-if="showSingleSelect"
      v-model="selectedBiomaterials"
      :items="entry.biomaterials"
      :multiple-limit="entry.multiselectLimit"
      :class="[biomaterialSelectorValid() ? '' : 'error']"
      multiple
      :disabled="!entry.biomaterials.length || onlyRequiredBiomaterials(entry.biomaterials)"
      :placeholder="T.laboratories.select_biomaterial"
      :validator-name="validatorName"
      :clearable="false"
      @registerValidator="$registerValidator($event)"
    />

    <template v-else>
      <el-select
        v-for="biomaterialSet in entry.biomaterialSets"
        :key="biomaterialSet.id"
        v-model="entry.selectedBiomaterialSets[biomaterialSet.externalLaboratoryId]"
        :class="[biomaterialSelectorValid(biomaterialSet.id) ? '' : 'error']"
        :multiple-limit="entry.multiselectLimit"
        multiple
        :disabled="onlyRequiredBiomaterials(biomaterialSet.biomaterials)"
        :placeholder="T.laboratories.select_biomaterial"
      >
        <el-option
          v-for="biomaterial in biomaterialSet.biomaterials"
          :key="biomaterial.id"
          :label="biomaterial.title"
          :value="biomaterial.id"
        />
      </el-select>
    </template>
  </div>
</template>
<script>
import { ANALYSIS_ORDER_PUBSUB_PREFIX, EVENTS } from '../constants.js'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'

export default {
  name: 'BiomaterialSelector',
  components: { MSelect },

  props: {
    entry: {
      default: Object.create,
      type: Object,
    },
  },

  data: () => ({
    selectorProperties: {},
  }),

  computed: {
    selectedBiomaterials: {
      get () {
        if (this.onlyRequiredBiomaterials(this.entry.biomaterials)) { return this.entry.biomaterials }

        return this.entry.selectedBiomaterials.map((item) => {
          return item?.id
            ? item
            : { id: item }
        })
      },
      set (value) {
        //eslint-disable-next-line
        this.entry.selectedBiomaterials = [...value]
      },
    },

    showSingleSelect () {
      return this.entry.biomaterialSets === undefined
    },

    validatorName () {
      return this.entry.entryId
        ? `biomaterial_${this.entry.entryId}`
        : `biomaterial_${this.entry.id}`
    },
  },

  mounted () {
    this.subscribeToEvents()
    this.autoSelectOneBiomaterial()
  },

  methods: {
    autoSelectOneBiomaterial () {
      if (this.showSingleSelect && this.entry) {
        if (this.entry.biomaterials.length === 1) {
          //eslint-disable-next-line
          this.entry.selectedBiomaterials.push(this.entry.biomaterials[0])
        }

        if (this.entry.biomaterials.length > 1 && this.onlyRequiredBiomaterials(this.entry.biomaterials)) {
          //eslint-disable-next-line
          this.entry.selectedBiomaterials = [...this.entry.selectedBiomaterials, ...this.entry.biomaterials]
        }
      } else {
        this.entry.biomaterialSets.forEach((biomaterialSet) => {
          this.selectorProperties[biomaterialSet.id] = {
            valid: true,
          }

          if (biomaterialSet.biomaterials.length === 1 && (biomaterialSet.externalLaboratoryId !== undefined)) {
            if (this.entry.selectedBiomaterialSets[biomaterialSet.externalLaboratoryId].length !== 0) return

            this.entry.selectedBiomaterialSets[biomaterialSet.externalLaboratoryId].push(biomaterialSet.biomaterials[0].id)
          }
        })
      }
    },

    biomaterialSelectorValid (biomaterialSetId) {
      if (this.showSingleSelect) {
        if (this.selectorProperties.valid === undefined) return true

        return this.selectorProperties.valid
      }

      if (!this.selectorProperties[biomaterialSetId]) return true

      return this.selectorProperties[biomaterialSetId].valid
    },

    onlyRequiredBiomaterials (biomaterialsArray) {
      return biomaterialsArray.reduce((acc, biomaterial) => acc && biomaterial.required === true, true)
    },

    validateEntries () {
      if (this.showSingleSelect) {
        this.selectorProperties.valid = this.entry.selectedBiomaterials.length !== 0
      } else {
        this.entry.biomaterialSets.forEach((biomaterialSet) => {
          this.selectorProperties[biomaterialSet.id].valid =
            this.entry.selectedBiomaterialSets[biomaterialSet.externalLaboratoryId].length !== 0
        })
      }

      this.$forceUpdate()
    },

    subscribeToEvents () {
      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.SHOW_ERRORS}`, (msg) => {
        this.validateEntries()
      })
    },
  },
}
</script>
