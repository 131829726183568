<template>
  <el-badge
    class="m-badge"
    :value="value"
    :type="type"
  >
    <slot />
  </el-badge>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'MBadge',
  props: {
    value: PropsTypes.Custom([String, Number]),
    type: PropsTypes.String(),
  },
}
</script>
