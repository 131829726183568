<template>
  <m-select
    :value="selectedContainer"
    :items="containers"
    :disabled="disabled"
    value-key="code"
    :required="required"
    :placeholder="t('laboratories.selectContainerType')"
    :validator-name="`biomaterial_container_${entryId}_${biomaterialId}`"
    @registerValidator="$registerValidator($event)"
    @change="setSelectedContainers"
  />
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'

export default {
  name: 'EntrySelectContainers',
  components: { MSelect },

  props: {
    containers: {
      type: Array,
      default: () => [],
    },

    entryId: { type: Number, default: 0 },

    biomaterialId: { type: Number, default: 0 },

    savedContainers: {
      type: Object,
      default: () => ({}),
    },

    disabled: Boolean,
  },

  data () {
    return {
      selectedContainer: null,
    }
  },

  mounted () {
    this.setSelectedContainers(this.setDefaultValue())
  },

  methods: {
    required () {
      return this.selectedContainer
        ? []
        : [t('errors.filled?')]
    },

    setSelectedContainers (value) {
      this.selectedContainer = value
      this.$emit('setSelectedContainerType', value)
    },

    setDefaultValue () {
      return this.containers.length === 1
        ? this.containers[0]
        : null
    },
  },
}
</script>
