<template>
  <div id="configuration_control">
    <div class="form-value configuration_control__button">
      <button
        id="force_update_price_btn"
        class="btn btn-primary btn-with-icon btn-warning btn-xs"
        type="button"
        :disabled="disabled"
        @click="updatePrice"
      >
        <div class="btn-with-icon_icon fad fa-fw fa-sync-alt" />
        <div
          v-tooltip="disabled && t('laboratories.updatePriceDisabled')"
          class="btn-with-icon_text"
        >
          {{ T.laboratories.force_update_price }}
        </div>
      </button>
    </div>

    <div class="form-value configuration_control__button">
      <button
        id="force_check_results_btn"
        class="btn btn-primary btn-with-icon btn-xs"
        type="button"
        :disabled="disabled"
        @click="updateResults"
      >
        <div class="btn-with-icon_icon fad fa-fw fa-sync-alt" />
        <div
          v-tooltip="disabled && t('laboratories.updateResultsCheckDisabled')"
          class="btn-with-icon_text"
        >
          {{ T.laboratories.force_check_results }}
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import { EVENTS } from './constants.js'
import { MLocalStorage } from '@/_api/_storage/MLocalStorage'

const cacheKey = 'update-price-button'
const disableTime = 30000

export default {
  name: 'ConfigurationControl',

  props: {
    wsHandler: {
      default: null,
      type: Object,
    },
  },

  data () {
    return {
      disabled: false,
      priceButtonCache: new MLocalStorage(cacheKey),
    }
  },

  mounted () {
    this.checkDisabledBtn()
  },

  methods: {
    sendMessage (event, params = {}) {
      this.wsHandler.sendMessage(event, params)
    },

    disableButton () {
      const blockTime = Date.now() + disableTime

      this.disabled = setTimeout(() => {
        this.disabled = false
        this.priceButtonCache.drop()
      }, disableTime)

      this.priceButtonCache.save(blockTime)
    },

    checkDisabledBtn () {
      const savedTime = this.priceButtonCache.restore()

      if (savedTime && Date.now() < parseInt(savedTime, 10)) {
        const remainingTime = Math.ceil((parseInt(savedTime, 10) - Date.now()))
        this.disabled = true

        setTimeout(() => {
          this.disabled = false
        }, remainingTime)
      }
    },

    updatePrice () {
      if (this.disabled) { return }

      this.disabled = true
      Notificator.info(T.laboratories.prices_will_be_updated)

      this.sendMessage(EVENTS.FORCE_UPDATE_PRICE)

      this.disableButton()
    },

    updateResults () {
      if (this.disabled) {
        return
      }

      this.disabled = true
      Notificator.info(T.laboratories.results_will_be_checked)

      this.sendMessage(EVENTS.FORCE_CHECK_RESULTS)

      this.disableButton()
    },
  },
}
</script>
