<template>
  <div class="report-filters">
    <template v-for="filter in structuredFilters">
      <component
        v-bind="filter.vBind"
        :is="filter.name"
        v-if="!filter.hidden"
        :key="`filter:${filter.name}`"
        class="report-filters__filter"
        :class="`report-filters__filter_${filter.name}`"
        :value="filtersData[filter.name]"
        :validator-name="filter.name"
        @registerValidator="$emit('registerValidator', $event)"
        @change="$emit('setFilterValue', { filter: filter.name, value: $event })"
      />
    </template>

    <report-filters-buttons
      :loading="loading"
      @onReportClick="$emit('onReportClick')"
      @onResetClick="$emit('onResetClick')"
    />

    <slot />
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import reportTypeFilter from '@/vue_apps/ReportsModule/_filters/components/ReportTypeFilter.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import clinicFilter from '@/vue_apps/ReportsModule/_filters/components/ClinicFilter.vue'
import periodFilter from '@/vue_apps/ReportsModule/_filters/components/PeriodFilter.vue'
import userFilter from '@/vue_apps/ReportsModule/_filters/components/UserFilter.vue'
import includingDiscountsFilter from '@/vue_apps/ReportsModule/_filters/components/IncludingDiscountsFilter.vue'
import entryWithOrderFilter from '@/vue_apps/ReportsModule/_filters/components/EntryWithOrderFilter.vue'
import userOnlyCurrentFilter from '@/vue_apps/ReportsModule/_filters/components/UserOnlyCurrentFilter.vue'
import ReportSourceFilter from '@/vue_apps/ReportsModule/_filters/components/ReportSourceFilter.vue'
import ReportFiltersButtons from '@/vue_apps/ReportsModule/_filters/components/ReportFiltersButtons.vue'

export default {
  name: 'ReportFilters',
  components: {
    ReportFiltersButtons,
    MButton,
    clinicFilter,
    periodFilter,
    userFilter,
    includingDiscountsFilter,
    entryWithOrderFilter,
    reportTypeFilter,
    userOnlyCurrentFilter,
    ReportSourceFilter,
  },

  props: {
    /** @type {string[] | TReportFilter[]}*/
    filters: PropsTypes.Array([]),

    filtersData: PropsTypes.Object({}),
    loading: Boolean,
  },

  emits: [
    'setFilterValue',
    'onReportClick',
    'onResetClick',
  ],

  computed: {
    /** @return {TReportFilter[]} */
    structuredFilters () {
      return this.filters.map((filter) => {
        return typeof filter === 'string'
          ? { name: filter, hidden: false }
          : filter
      })
    },
  },
}
</script>
