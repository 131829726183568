import { createVueApp } from '@/vue_components/create_vue_app'
import PrintReceipt from '@/vue_apps/FiscalService/printReceipt/PrintReceipt.vue'

let vueApp = null
export const createPrintReceiptApp = (dialog) => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el: '#vue_print_receipt_app_root',
    name: 'PrintReceiptApp',
    render: (h) => h(PrintReceipt, {
      props: {
        dialog,
      },
    }),
  })
}
