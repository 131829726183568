/* eslint-disable no-debugger */
import imagesLoaded from 'imagesloaded'
import { cleanUpEditor, VALID_WORD_ELEMENTS } from '@/plugins/dynamic_forms/tinymce_helpers'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import { PUB_SUB_BROADCAST_EVENTS } from '@/_declarations/pubSub/pubSubBroadcastEvents'

$document.on('rez/documents', function () {
  $('#select_card_teeth_kind').milkChecker({
    container: $('.dental-card'),
  })

  $('#select_card_teeth_kind_teeth-map').milkChecker({
    container: $('.dental-card'),
  })

  Services.pubSub.subscribe(PUB_SUB_BROADCAST_EVENTS.REMOVE_DOCUMENTS, (event) => {
    const isExits = event.some((item) =>
      item.documentType === DOCUMENT_TYPES.OLD_DOCUMENT &&
      item.documentId === gon.specific.document_id)

    if (!isExits) { return }

    window.close()
  })
})

$document.on('rez/documents/show', function () {
  const $delete = $('#delete-record')

  const redirectUrl = gon.specific.client_id
    ? Routes.documents_client_path(gon.specific.client_id)
    : Routes.documents_company_path(gon.specific.company_id)

  const onSuccess = () => {
    Services.pubSub.emit('removeDocuments', [{
      documentId: gon.specific.document_id,
      documentType: DOCUMENT_TYPES.OLD_DOCUMENT,
    }], true)

    Turbolinks.visit(redirectUrl)
  }

  $delete.deleteButton({
    url: Routes.document_path(gon.specific.document_id),
    name: t('document_1'),
    onSuccess,
  })

  $('#document_form').remote_form({ model: 'document' })
  $('#fixed_doc_form *').attr('contenteditable', 'false')
  $('.filters').hide()
})

$document.on('rez/documents/new rez/documents/edit', function () {
  const $destinationClientId = $('#destination_client_id')
  $('.date_field').trigger('change')

  $('#document_form, #fixed_document_form').remote_form({
    model: 'document',
    beforeSend () {
      const fixedDocData = $('.fixed_doc_form_container').html()
      const $documentData = $('#document_data')

      $documentData.html(fixedDocData)
    },
    onSuccess (document) {
      Turbolinks.visit(Routes.document_path(document.id))
    },
  })

  ////////////////////fixed_doc/////////////////////////////

  $('#fixed_doc_form').on('click', '.remove-diagnosis', function () {
    $(this).parents('.diagnosis').remove()
  })

  $('#fixed_doc_form').on('click', '.remove-entry', function () {
    $(this).parents('.entries').remove()
  })

  /**
   * @this {HTMLElement}
   */
  const underLine = function () {
    this.classList.toggle('underlined')
  }

  $('.underline').on('click', underLine)

  $('#submit_btn').on('click', () => {
    if (!$('.sort-date-period').val()) {
      Notificator.error(T.choose_date)

      return
    }

    $.ajax({
      url: Routes.form_289_sum_payments_path(),
      method: 'POST',
      data: {
        date: $('.sort-date-period').val(),
        clinic_id: $('#clinics').val(),
        destination_client_id: $('#clients').val(),
        printed_receipts_only: $('#printed_receipts_only').prop('checked'),
        client_id: $('#document_client_id').val(),
      },
      success (res) {
        $('#paid_sum').text(res.paid_sum)
        divideSums(res.paid_sum_by_words)
        putPaymentDates(res.payment_dates)
      },
      error (err) {
        Notificator.error(T.abstract_error_message)
        console.error(err)
      },
    })
  })

  $('#clients').on('change', function (event) {
    const data = $('#clients option:selected')

    $('.destination-client-fio').each(function () {
      $(this).text(data.attr('full_name'))
    })

    $('.destination-client-emk-number').each(function () {
      $(this).text(data.attr('emk'))
    })
  })

  $('#clients').trigger('change')

  function divideSums (sumByWords) {
    const MAX_WORDS_IN_STR = 7
    const wordsArr = sumByWords.split(' ')

    if (wordsArr.length > MAX_WORDS_IN_STR) {
      let str1 = ''; let str2 = ''
      wordsArr.forEach((word, index) => {
        if (index < MAX_WORDS_IN_STR) {
          str1 += word + ' '
        } else {
          str2 += word + ' '
        }
      })

      $('#sum_by_words').text(str1.trim())
      $('#sum_by_words2').text(str2.trim())

      return
    }

    $('#sum_by_words').text(sumByWords)
  }

  function putPaymentDates (date) {
    date ? $('.payment_dates').text(date) : $('.payment_dates').text('-')
  }

  PubSub.on('page.form.document.MKBSelector.selected', function (msg, data) {
    const diagnosis = data.item
    const $selector = $(data.selector.selector)

    $selector.find('.nested-diagnoses').append('' +
      '<div class="diagnosis">' +
        '<div class="diagnosis-title">' +
          diagnosis.title +
          '<b style="margin-right: 5px">' +
            '&nbsp;код по МКБ:&nbsp;' + diagnosis.code_string +
          '</b> ' +
        '</div>' +
        '<a class="fad fa-times-circle ' +
                  'remove-diagnosis hidden-print"' +
        '>' +
        '</a>' +
        '<div contenteditable="true" class="diagnosis-comment"></div>' +
      '</div>')
  })

  $('[id^=mkb_selector]').each(function () {
    const id = $(this).attr('id')

    FormFactory.MKBSelector({
      model: 'document',
      MKBSelector: {
        selector: $('#' + id),
      },
    })
  })

  $('.entry_type_selector').entryTypeSelector({
    onSelect (entry, $this) {
      $this.next().append('<div class="entries">' +
      '<div class="entry">' +
        '<div class="entry-title">' +
          entry.title +
        '</div>' +
        '<a class="fad fa-times-circle ' +
                  'remove-entry hidden-print"' +
        '>' +
        '</a>' +
        '<div contenteditable="true" class="entry-comment"></div>' +
      '</div>')
    },
  })

  $destinationClientId.on('keyup mouseup change', function () {
    const searchText = this.textContent

    if (searchText.length < 3) return
    const self = this

    const params = {
      url: Routes.search_select2_clients_path(),
      method: 'GET',
      queryParams: {
        term: searchText,
      },
      dataPrepare (data) {
        return data.results.map((item) => ({
          id: item.id,
          title: item.text.split(',')[0],
        }))
      },
      itemRender (res, item) {
        res.append(`<span class="cut" data-id=${item.id}>${item.title}</span>`)

        return res
      },
      onSelect (item) {
        $(self).attr('data-id', item.id)
      },
    }

    $(this).smartSearch(params)
  })
})

$document.on('rez/documents/edit', async function () {
  tinymce.remove()

  const documentData = document.querySelector('#document_data')

  const editors = await tinymce.init({
    selector: '#document_editor',
    fixed_toolbar_container: '#document_editor_toolbar',
    language: gon.localization.locale === 'ru' ? 'ru' : 'en_US',
    browser_spellcheck: true,
    paste_word_valid_elements: VALID_WORD_ELEMENTS,
    height: 350,
    theme: 'modern',
    statusbar: false,
    relative_urls: false,
    branding: false,
    inline: true,
    auto_focus: true,
    paste_remove_spans: true,
    paste_merge_formats: true,
    images_upload_url: Routes.text_editor_document_inheritor_path(),
    plugins: ['preview fullscreen table pagebreak image visualblocks lineheight paste lists advlist'],
    toolbar1: 'undo redo | variables | fontselect fontsizeselect lineheightselect',
    toolbar2: 'alignleft aligncenter alignright alignjustify | underline bold italic | subscript superscript | bullist numlist outdent indent | pagebreak | fullscreen  | image',
    image_advtab: true,
    fontsize_formats: '8px 9px 10px 11px 12px 13px ' +
                      '14px 15px 16px 17px 18px 24px 36px',
    lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2.0',
    table_default_attributes: Utils.tinymceTableDefaults,
    table_default_styles: Utils.tinymceTableStyles,
    content_css: ['/tinymce_custom.css'],
    pagebreak_separator: '<div class="mce-pagebreak"></div>',
    /**  @param {TTinyMCEEditor} editor */
    setup (editor) {
      editor.on('init', function () {
        // Устанавливаем контент в редактор
        editor.setContent(document.querySelector('#document_data').value)
      })

      editor.on('paste', () => {
        cleanUpEditor()
      })
    },
    init_instance_callback () {
      cleanUpEditor()
    },
  })

  if (!documentData) { return }

  const editor = editors[0]
  document
    .querySelector('.document-submit')
    .addEventListener('click', () => {
      documentData.value = editor.getContent()
    })
})

$document.on('rez/documents/multi_print', function () {
  $('#fixed_doc_form *').attr('contenteditable', 'false')

  imagesLoaded($('img'), () => {
    if (gon.specific.print_now) window.print()
  })
})

$document.on('rez/documents/new', function () {
  const form289SelectorName = '.289_form_clinics'
  $(form289SelectorName).select2({ width: '100%', placeholder: T.all })
})
