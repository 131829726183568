import { request } from '@/lib/transport/request'

/**
 * @param id
 * @returns {Promise<*>}
 * @deprecated
 */
export const fetchEntryType = (id) => {
  return request({
    type: 'get',
    url: Routes.entry_type_path(id),
  }).promise
}
