<template>
  <span class="payment_kind">
    <span
      :class="iconClass"
      :data-tooltip="kindText"
    />
  </span>
</template>

<script>
export default {
  name: 'PaymentKind',
  props: {
    kind: {
      type: Number,
      required: true,
    },
    noText: Boolean,
  },
  data () {
    return {
      states: [
        {
          icon: 'fad fa-fw fa-money-bill-alt tooltip-bottom orange',
          text: T._payment_kind.order_initial,
        }, {
          icon: 'fad fa-fw fa-cart-plus tooltip-bottom purple',
          text: T._payment_kind.order_subsequent,
        }, {
          icon: 'fad fa-fw fa-reply tooltip-bottom red-light',
          text: T._payment_kind.order_refund,
        }, {
          icon: 'fad fa-fw fa-user-plus fa-flip-horizontal tooltip-bottom',
          text: T._payment_kind.cash_in,
        }, {
          icon: 'fad fa-fw fa-reply tooltip-bottom red-light',
          text: T._payment_kind.cash_in_refund,
        }, {
          icon: 'fad fa-fw fa-user-minus fa-flip-horizontal tooltip-bottom',
          text: T._payment_kind.cash_out,
        }, {
          icon: 'fad fa-fw fa-shopping-cart tooltip-bottom green-light',
          text: T._payment_kind.order_full,
        }, {
          icon: 'fad fa-fw fa-credit-card tooltip-bottom brown',
          text: T._payment_kind.order_in_credit,
        },
      ],
    }
  },
  computed: {
    state () {
      return this.states[this.kind] || {}
    },
    iconClass () {
      return this.state.icon
    },
    kindText () {
      return this.state.text
    },
  },
}
</script>

<style scoped>

</style>
