import { REQUEST_KEY } from './const'
import { createCaseAdapter } from '@/lib/transport/case_adapter'

export const dentalsAdapter = createCaseAdapter(REQUEST_KEY)

export const setDataAdapter = {
  toServer (data) {
    return {
      [REQUEST_KEY]: {
        ...dentalsAdapter.toServer(data)[REQUEST_KEY],
        company_id: data.companyShortInfo ? data.companyShortInfo.id : null,
      },
    }
  },
}
