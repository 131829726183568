<template>
  <div class="based-on-protocol-modal">
    <m-modal
      :visible.sync="modalVisible"
      :dialog-title="t('select_one_of_the_previous_protocols')"
      modal-title-icon="file"
    >
      <m-si-generator
        :items="exceptCurrentIdList"
        :current-page="list.currentPage"
        :si-generator-schema="schema"
        :page-count="list.totalPages"
        @onItemClick="onItemClick"
        @update:currentPage="list.fetchPage($event)"
        @update:searchQuery="list.search($event)"
      >
        <template #filters>
          <m-checkbox
            v-model="mine"
            :label="t('only_mine')"
            @input="list.setFilterValueWithFetchAll('userIds', ($event && [currentUserId]) || undefined)"
          />
        </template>
      </m-si-generator>
    </m-modal>

    <slot :activator="openModal">
      <m-button
        :text="t('based_on_previous')"
        icon="copy"
        @click="openModal"
      />
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { getDateTitleHeadlessSchema } from '@/vue_present/_base/Tables/_schemaTemplates/getDateTitleHeadlessSchema'
import { MListService } from '@/_api/_requests/MListService'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import { MProtocolPresenter } from '@/vue_apps/Protocols/api/MProtocolPresenter'

export default defineComponent({
  name: 'BasedOnPreviousProtocolModal',
  components: { MCheckbox, MSiGenerator, MModal, MButton },

  props: {
    id: { type: Number, default: null },
    clientId: { type: Number, required: true },
  },

  emits: [
    'protocolClick',
  ],

  data () {
    return {
      mine: false,
      modalVisible: false,
      schema: getDateTitleHeadlessSchema()
        .addSearch(),
      list: new MListService(
        {
          clientId: this.clientId,
          userIds: null,
        },
        new MProtocolPresenter()
      ),
    }
  },

  computed: {
    currentUserId () {
      return gon.application.current_user.id
    },

    exceptCurrentIdList () {
      return this.list.data.filter(({ id }) => id !== this.id)
    },
  },

  methods: {
    openModal () {
      this.modalVisible = true
      this.list.fetchAll()
    },

    closeModal () {
      this.modalVisible = false
    },

    async onItemClick (item) {
      const protocol = await new MProtocolPresenter().fetch(item.id)
      if (protocol?.errors) { return }
      this.$emit('protocolClick', protocol)
      this.closeModal()
    },
  },
})
</script>
