import { ItemBase } from '@/_api/_classes/ItemBase'

/**
 * @implements ITreatmentPlansReportResponseTreatmentPlan
 */
export class TreatmentPlansReportItemBase extends ItemBase {
  _permit = [
    'client',
    'date',
    'doctor',
    'finalSum',
    'id',
    'kind',
    'paidSum',
    'responsible',
    'status',
    'title',
    'total',
    'treatmentEntriesAmount',
    'treatmentEntriesCreatedAmount',
    'treatmentEntriesCreatedTotalSum',
    'unpaidSum',
  ]

  client

  date

  doctor

  finalSum

  id

  kind

  paidSum

  responsible

  status

  title

  total

  treatmentEntriesAmount

  treatmentEntriesCreatedAmount

  treatmentEntriesCreatedTotalSum

  unpaidSum

  /**
   * @param {ITreatmentPlansReportResponseTreatmentPlan} props
   */
  constructor (props) {
    super()
    this._fillProps(props)
  }
}
