<template>
  <si-field>
    <div class="title">
      {{ t('title') }}
    </div>
    <div class="amount">
      {{ t('amount') }}
    </div>
    <div class="sum">
      {{ t('sum') }}
    </div>
    <div class="reward-sum">
      <span v-tooltip="t('reports.rewardSumTooltip')">{{ t('reports.rewardSum') }}</span>
    </div>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { ReferralHeader } from '@/vue_components/reports/referrals/header/referralHeader'

export default {
  name: 'ReferralsHeaderGroupedReferral',
  components: {
    SiField,
  },
  mixins: [ReferralHeader],
}
</script>
