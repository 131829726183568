import { request } from '@/lib/transport/request'
import {
  fetchTwoLevelsOfEntryTypesCategoriesAdapter,
} from '@/vue_apps/ReportsModule/LoyalReport/api/fetchTwoLevelsOfEntryTypesCategoriesAdapter'

export const fetchTwoLevelsOfEntryTypesCategories = async () =>
  request(
    {
      type: 'get',
      url: Routes.category_tree_for_select2_categories_path(),
    },
    fetchTwoLevelsOfEntryTypesCategoriesAdapter.toClient
  ).promise
    .catch(() => Promise.resolve([]))
