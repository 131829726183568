import Vue from 'vue/dist/vue.esm'
import InsuranceCompanies from '@/vue_components/reports/insurance_companies.vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper.js'
import { defineDateFormat, fetchExportFile } from './export.js'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import { createFiltersProfilesAppForLegacy } from '@/vue_apps/FiltersProfilesApp/index'
import { FILTERS_PROFILES_TYPES } from '@/vue_apps/FiltersProfilesApp/const/consts'
import { MAX_LIMIT } from './const.js'
import { insuranceCompaniesReportFiltersAccessors as filtersAccessor } from './insuranceCompanies/filtersAccessors'
import { insuranceCompaniesReportFiltersSelectors as filtersSelectors } from './insuranceCompanies/filtersSelectors'
import { removeFilterValidationWrapper, showFiltersErrors } from '@/vue_apps/FiltersProfilesApp/helpers/legacyErrorsRenderingHelpers'
import { saveInsuranceCompaniesReportInitialFilters as saveInitialFilters } from './insuranceCompanies/initialFilters'

$document.on('rez/reports/insurance_companies', function () {
  Report.init()
  saveInitialFilters()

  $('#report_form').on('change', removeFilterValidationWrapper)

  createFiltersProfilesAppForLegacy({
    filtersSetter ({ filters, errors }) {
      filtersAccessor.set(filters)
      showFiltersErrors(errors, filtersSelectors)
    },
    filtersGetter: filtersAccessor.get,
    profileType: FILTERS_PROFILES_TYPES.INSURANCE_COMPANIES,
  })

  const stomatologyEnabled = gon.specific.stomatologyEnabled

  const tableHeader = {
    number: '№',
    fullName: T.full_name,
    surname: T.surname,
    firstName: T.name,
    secondName: T.second_name,
    birthday: T.birthday,
    idCard: T.id_card,
    address: T.reports.address,
    card: T.card,
    police: T.police,
    date: T.custom_date,
    orderId: T.order,
    code: T.code,
    service: T.service,
    price: T.price,
    discountedPrice: T.discount_price,
    amount: T.amount,
    sum: T.sum,
    mkb: T.mkb,
    diagnoses: T.diagnoses,
    conclusions: T.conclusions,
    guaranteeLetterNumber: T.finance.guarantee_letter_number_shortly,
    guaranteeLetterDate: T.finance.guarantee_letter_issue_date_shortly,
    doctor: T.doctor,
    specialty: T.specialty,
    doctorCode: T.doctor_code,
    insuranceCompany: T.insurance_company,
    insured: T.insurant,
    teethNumbers: T.teeth_numbers,
  }

  const reportTable = new Vue({
    el: '#report_right_container',
    components: { InsuranceCompanies },
    mixins: [ReportWrapper],
    data: () => ({
      filters: filtersAccessor.get(),
    }),
    template:
      `<insurance-companies
        ref="report"
        :isHidden="isHidden"
        v-bind="filters"
      />`,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(filtersAccessor.get())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', function () {
    const filters = filtersAccessor.get()

    const data = {
      buildSettings: {
        usePatientCardNumber: false,
      },
      dateFormat: defineDateFormat(),
      csvComma: gon.application.csv_character,
      floatingComma: gon.localization.currency.decimal_character,
      headerRow: {
        number: tableHeader.number,
        clientFullName: tableHeader.fullName,
        clientSurname: tableHeader.surname,
        clientName: tableHeader.firstName,
        clientSecondName: tableHeader.secondName,
        clientBirthDate: tableHeader.birthday,
        idCard: tableHeader.idCard,
        address: tableHeader.address,
        patientCardNumber: tableHeader.card,
        medicalPolicyNumber: tableHeader.police,
        entryDate: tableHeader.date,
        orderId: tableHeader.orderId,
        entryTypeNumber: tableHeader.code,
        entryTitle: tableHeader.service,
        entrySum: tableHeader.price,
        entryFinalSum: tableHeader.discountedPrice,
        entryAmount: tableHeader.amount,
        entryPaidSum: tableHeader.sum,
        diagnosesCodes: tableHeader.mkb,
        diagnoses: tableHeader.diagnoses,
        conclusions: tableHeader.conclusions,
        financeGuaranteeLetterNumber: tableHeader.guaranteeLetterNumber,
        financeGuaranteeLetterIssueDate: tableHeader.guaranteeLetterDate,
        doctorInitials: tableHeader.doctor,
        doctorSpecialties: tableHeader.specialty,
        doctorCode: tableHeader.doctorCode,
        insuranceCompany: tableHeader.insuranceCompany,
        company: tableHeader.insured,
      },
      totalRow: {
        total: T.total,
      },
      clientTotalRow: {
        total: T.total,
      },
      offset: 0,
      limit: MAX_LIMIT,
      report: {
        period: filters.period,
      },
      ...ReportFiltersFactory.defineFor('insuranceCompanies', filters),
    }
    if (stomatologyEnabled) {
      data.headerRow.teeth = tableHeader.teethNumbers
    }

    fetchExportFile(data, Routes.report_insurance_companies_path(), T.insurance_companies)
  })

  $(filtersSelectors.insuranceCompany).select2()
})
