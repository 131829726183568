import { GLOBAL_ENTRY_STATE } from '@/_global_scripts/GLOBAL_CONSTS'

export const getStateIcon = (state) => {
  if (state === GLOBAL_ENTRY_STATE.READY) {
    return {
      icon: 'readyCircle',
      css: 'success',
      tooltip: t('change_status_to', { status: t('not_ready') }),
    }
  }

  return {
    icon: 'notReadyCircle',
    css: 'gray',
    tooltip: t('change_status_to', { status: t('ready') }),
  }
}
