import Vue from 'vue/dist/vue.esm'
import DentalCompaniesCatalog from './dental_companies'
import { createStore } from '@/vue_components/store/store.js'
import router from '@/vue_components/router/router'

export const VueDentalCompaniesEntryPoint = () => {
  const el = '#vue_dental_companies_catalog'

  if (!document.querySelector(el)) { return }
  // eslint-disable-next-line no-new
  new Vue({
    el,
    name: 'DentalCompaniesCatalogApp',
    store: createStore(),
    router,
    render: (h) => h(DentalCompaniesCatalog),
  })
}
