import { request } from '@/lib/transport/request'
import { clinicsAdapter, getClinicAdapter } from './clinics_adapter'

export const clinicsEndpoint = {
  getAll (sort_params) {
    const options = {
      type: 'POST',
      url: Routes.catalogs_clinics_list_path(),
      data: {
        sort_params,
      },
    }

    return request(options).promise
  },

  get (id) {
    const options = {
      type: 'GET',
      url: Routes.edit_clinic_path(id),
    }

    return request(options, getClinicAdapter.toClient)
  },

  update (data) {
    const options = {
      type: 'PATCH',
      url: Routes.clinic_path(data.id),
      data: clinicsAdapter.toServer(data),
    }

    return request(options).promise
  },

  create (data) {
    const options = {
      type: 'POST',
      url: Routes.clinics_path(),
      data: clinicsAdapter.toServer(data),
    }

    return request(options).promise
  },
}
