import Vue from 'vue/dist/vue.esm'
import Procedures from '@/vue_components/reports/ProceduresReport.vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper.js'
import { getReport } from '@/specific/reports/procedures/getReport'
import { createFiltersProfilesAppForLegacy } from '@/vue_apps/FiltersProfilesApp'
import { FILTERS_PROFILES_TYPES } from '@/vue_apps/FiltersProfilesApp/const/consts'
import { saveProceduresReportInitialFilters as saveInitialFilters } from './procedures/initialFilters'
import { proceduresReportFiltersAccessors as filtersAccessor } from './procedures/filtersAccessors'
import { removeFilterValidationWrapper, showFiltersErrors } from '@/vue_apps/FiltersProfilesApp/helpers/legacyErrorsRenderingHelpers'
import { proceduresReportFiltersSelectors as filtersSelectors } from './procedures/filtersSelectors'

$document.on('rez/reports/procedures', function () {
  Report.init()
  saveInitialFilters()

  $('#report_form').on('change', removeFilterValidationWrapper)

  createFiltersProfilesAppForLegacy({
    filtersSetter ({ filters, errors }) {
      filtersAccessor.set(filters)
      showFiltersErrors(errors, filtersSelectors)
    },
    filtersGetter: filtersAccessor.get,
    profileType: FILTERS_PROFILES_TYPES.PROCEDURES,
  })

  const reportTable = new Vue({
    el: '#report_right_container',
    components: { Procedures },
    mixins: [ReportWrapper],
    data: () => ({
      filters: filtersAccessor.get(),
      doctorAreaModuleEnabled: gon.application.doctor_area_module_enabled,
      workplacesEnabled: gon.application.workplaces_module_enabled,
      moneySymbol: gon.localization.currency.symbol_character,
    }),
    template: `
      <procedures
        ref="report"
        :isHidden="isHidden"
        :doctor-area-module-enabled="doctorAreaModuleEnabled"
        :workplaces-enabled="workplacesEnabled"
        :money-symbol="moneySymbol"
        v-bind="filters"
      />`,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(filtersAccessor.get())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', getReport)
})
