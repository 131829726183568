<template>
  <m-select
    v-bind="$attrs"
    :items="reportTypes"
    :label="t('reports.reportType')"
    :class="$attrs.css"
    :clearable="false"
    v-on="$listeners"
  />
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { REPORT_TYPE_FILTERS } from '@/vue_apps/ReportsModule/_filters/components/ReportTypeFilter/const'

export default {
  name: 'ReportTypeFilter',
  components: { MSelect },
  computed: {
    // todo: переписать после ватсапа
    reportTypes () {
      return REPORT_TYPE_FILTERS()
    },
  },
}
</script>
