// 16-ричный цвет в RGB
// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
function hexToRGB (hexColor) {
  let intColor = parseInt(hexColor.slice(1), 16)
  let r = (intColor >> 16) & 255
  let g = (intColor >> 8) & 255
  let b = intColor & 255

  return { r, g, b }
}

// коэффициент цвета
// https://stackoverflow.com/questions/1855884/determine-font-color-based-on-background-color
function getLuminance (color) {
  const { r, g, b } = hexToRGB(color)

  return (0.299 * r + 0.587 * g + 0.114 * b) / 255
}

// Lighten delta > 0
// Darken delta < 0
// https://css-tricks.com/snippets/javascript/lighten-darken-color/ + bug fix with padStart '0'
function lightenDarkenColor (color, delta) {
  let usePound = false

  if (color[0] === '#') {
    color = color.slice(1)
    usePound = true
  }

  let num = parseInt(color, 16)

  let r = (num >> 16) + delta

  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00FF) + delta

  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000FF) + delta

  if (g > 255) g = 255
  else if (g < 0) g = 0

  let newColor = (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')

  return (usePound ? '#' : '') + newColor
}

// цвет шрифта, видимый на фоне цвета color
export function getFontColorByBackground (color) {
  const darkFontColor = '#484848'
  const lightFontColor = '#ffffff'

  if (getLuminance(color) > 0.5) {
    return darkFontColor
  }

  return lightFontColor
}

// дополнительный цвет
export function getSecondaryColor (color) {
  const defaultDelta = 30 // сдвиг по цвету

  if (getLuminance(color) > 0.5) {
    return lightenDarkenColor(color, -defaultDelta)
  }

  return lightenDarkenColor(color, defaultDelta)
}
