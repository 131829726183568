<template>
  <div>
    <div class="form-group">
      <slot name="address-full-address">
        <dadata-select
          :value="fullAddress"
          :placeholder="t('search_by_address')"
          :disabled="targetedDisable.fullAddress"
          type="ADDRESS"
          @change="updateAddressFields"
          @update:value="$updateSync('fullAddress', $event)"
        />
      </slot>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <div class="form-group">
          <slot name="address-index">
            <validation-wrapper :errors="errors.address.index">
              <input
                :value="index"
                :placeholder="t('index')"
                :disabled="targetedDisable.index"
                type="text"
                class="form-control"
                @input="$updateSync('index', $event.target.value)"
              >
            </validation-wrapper>
          </slot>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="form-group">
          <slot name="address-country">
            <validation-wrapper :errors="errors.address.country">
              <input
                :value="country"
                :placeholder="t('country')"
                :disabled="targetedDisable.country"
                type="text"
                class="form-control"
                @input="$updateSync('country', $event.target.value)"
              >
            </validation-wrapper>
          </slot>
        </div>
      </div>
    </div>
    <div class="form-group">
      <slot name="address-region">
        <validation-wrapper :errors="errors.address.region">
          <input
            :value="region"
            :placeholder="t('region')"
            :disabled="targetedDisable.region"
            type="text"
            class="form-control"
            @input="$updateSync('region', $event.target.value)"
          >
        </validation-wrapper>
      </slot>
    </div>
    <div class="form-group">
      <slot name="address-area">
        <validation-wrapper :errors="errors.address.area">
          <input
            :value="area"
            :placeholder="t('area')"
            :disabled="targetedDisable.area"
            type="text"
            class="form-control"
            @input="$updateSync('area', $event.target.value)"
          >
        </validation-wrapper>
      </slot>
    </div>
    <div class="form-group">
      <slot name="address-city">
        <validation-wrapper :errors="errors.address.city">
          <input
            :value="city"
            :placeholder="t('cityOrVillage')"
            :disabled="targetedDisable.city"
            type="text"
            class="form-control"
            @input="$updateSync('city', $event.target.value)"
          >
        </validation-wrapper>
      </slot>
    </div>
    <div class="form-group">
      <slot name="address-street">
        <validation-wrapper :errors="errors.address.street">
          <input
            :value="street"
            :placeholder="t('street')"
            :disabled="targetedDisable.street"
            type="text"
            class="form-control"
            @input="$updateSync('street', $event.target.value)"
          >
        </validation-wrapper>
      </slot>
    </div>
    <div class="form-group">
      <slot name="address-house">
        <validation-wrapper :errors="errors.address.house">
          <input
            :value="house"
            :placeholder="t('house')"
            :disabled="targetedDisable.house"
            type="text"
            class="form-control"
            @input="$updateSync('house', $event.target.value)"
          >
        </validation-wrapper>
      </slot>
    </div>
    <div class="form-group">
      <slot name="address-flat">
        <validation-wrapper :errors="errors.address.flat">
          <input
            :value="flat"
            :placeholder="t('flat')"
            :disabled="targetedDisable.flat"
            type="text"
            class="form-control"
            @input="$updateSync('flat', $event.target.value)"
          >
        </validation-wrapper>
      </slot>
    </div>
  </div>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import props from '@/vue_components/client/client_form_address_props.js'
import DadataSelect from '@/vue_components/common/select/dadata_select/dadata_select'
import { concatHouseWithBlock } from '@/vue_present/Reuse/CommonAddressForm/helpers'
import { mapGetters } from 'vuex'
import { getSuggestionAddressCity } from '@/helpers/getSuggestionAddressCity'

export default {
  components: {
    DadataSelect,
    ValidationWrapper,
  },

  props,

  computed: {
    ...mapGetters('globalCatalogs/nsiStore/russianSubjectsDictionaryStore', {
      getRussianSubject: 'vxGetRussianSubjectMethod',
    }),
  },

  methods: {
    _extractAddressData (address) {
      if (!address) {
        return {
          city: '',
          area: '',
          region: '',
          index: '',
          street: '',
          house: '',
          flat: '',
          country: '',
          fullAddress: '',
        }
      }

      const city = getSuggestionAddressCity(address.data)

      const {
        area_with_type: area,
        region_with_type: region,
        postal_code: index,
        street_with_type: street,
        house,
        flat,
        country,
        block,
        block_type_full: blockTypeFull,
        region: regionDefault,
      } = address.data

      return {
        city,
        area,
        region,
        index,
        street,
        house: concatHouseWithBlock({ house, block, blockTypeFull }),
        flat,
        country,
        nsiRussianSubjectId: this.getRussianSubject(regionDefault)?.id,
        fullAddress: '',
      }
    },

    updateAddressFields (address) {
      const addressParts = this._extractAddressData(address)

      Object.keys(addressParts)
        .forEach((part) => {
          if (part === 'nsiRussianSubjectId') {
            return this.$updateSync(part, addressParts[part] || null)
          }

          this.$updateSync(part, addressParts[part] || '')
        })
    },
  },
}
</script>
