import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { inArrayOrUndefined } from '@/_medods_standart_library/msl'

export function fetchUser (method, sortParams) {
  const params = {
    clinicIds: inArrayOrUndefined(gon.specific.useCurrentClinic && gon.application.current_clinic?.id),
    ...sortParams,
  }

  return method(camelToSnake(params), snakeToCamel)
}
