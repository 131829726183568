<template>
  <div class="col col__entryTypes">
    <m-icon
      v-tooltip="t('services')"
      class="pointer"
      :icon="icon"
      color="primary"
      @click="$emit('click', $event)"
    />
  </div>
</template>

<script>
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { isEmpty } from 'lodash'

export default {
  name: 'AppointmentCallLogEntryTypes',
  components: { MIcon },
  props: {
    entryTypes: PropsTypes.Array(),
  },

  computed: {
    isEntryTypesEmpty () {
      return isEmpty(this.entryTypes)
    },

    icon () {
      return this.isEntryTypesEmpty ? 'entryTypesEmpty' : 'entryTypesFilled'
    },
  },
}
</script>
