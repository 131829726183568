import { ReportParamFactory, ReportParamStrict } from '../shared'
import { proceduresReportFiltersSelectors as selectors } from './filtersSelectors'
import { IProceduresReportFiltersGet } from './interfaces'

export const _getProceduresReportFilters = (): IProceduresReportFiltersGet => ({
  period: ReportParamFactory.getRawParam(selectors.period),
  clinic: ReportParamFactory.getSelectParam(selectors.clinic) as ReportParamStrict,
  entryKind: ReportParamFactory.getSelectParam(selectors.entryKind) as ReportParamStrict,
  entryWithOrder: ReportParamFactory.getCheckboxParam(selectors.entryWithOrder) as ReportParamStrict,
  entryWithDiscount: ReportParamFactory.getCheckboxParam(selectors.entryWithDiscount) as ReportParamStrict,
  doctor: ReportParamFactory.getSelectParam(selectors.doctor) as ReportParamStrict,
  administrator: ReportParamFactory.getSelectParam(selectors.administrator) as ReportParamStrict,
  entryTypes: ReportParamFactory.getSelect2Params(selectors.entryTypes, true) as ReportParamStrict[],
  entryTypeCategories: ReportParamFactory.getSelect2Params(selectors.entryTypeCategories) as ReportParamStrict[],
  client: ReportParamFactory.getSelect2Params(selectors.client) as ReportParamStrict,
  clientGroups: ReportParamFactory.getSelect2Params(selectors.clientGroups) as ReportParamStrict[],
  clientSex: ReportParamFactory.getSelectParam(selectors.clientSex) as ReportParamStrict,
  clientStatus: ReportParamFactory.getSelectParam(selectors.clientStatus) as ReportParamStrict,
  doctorSpecialtiesIds: ReportParamFactory.getSelect2Params(selectors.doctorSpecialtiesIds) as ReportParamStrict[],
})
