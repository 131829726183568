import procedures from './procedures'
import entries from './entries'
import orders from './orders'
import payments from './payments'
import expenses from './expenses'
import cancellations from './cancellations'
import appointments from './appointments'
import salary from './salary'
import insuranceCompanies from './insurance_companies'
import calls from './calls'
import referrals from './referrals'

export default {
  procedures,
  entries,
  orders,
  payments,
  expenses,
  cancellations,
  appointments,
  salary,
  insuranceCompanies,
  calls,
  referrals,
}
