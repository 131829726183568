<template>
  <div class="entry-protocols flex flex-column">
    <m-si-generator
      :items="protocolsList.data"
      :si-generator-schema="schema"
      :current-page="protocolsList.currentPage"
      :page-count="protocolsList.totalPages"
      :add-button-config="addBtnConfig"
      @update:currentPage="protocolsList.fetchPage($event)"
      @onItemClick="onProtocolClick"
      @onItemDelete="onDeleteProtocolClick"
      @onAddItem="onAddProtocolClick"
    />

    <protocol-modal-wrapper
      :doctor="doctor"
      :clinic="clinic"
      :client-id="clientId"
      :entry="entry"
      :configuration-print-clinic-header="configurationPrintClinicHeader"
      :configuration-print-doctor-signature="configurationPrintDoctorSignature"
      :configuration-print-entry-info="configurationPrintEntryInfo"
      :configuration-print-entry-title="configurationPrintEntryTitle"
      @registerOpenProtocol="openProtocol = $event"
      @submitted="protocolsList.fetchPage(1)"
      @generatePdf="onGeneratePdf"
    />
  </div>
</template>

<script>
import { ProtocolsList } from '@/vue_apps/Protocols/entities/ProtocolsList'
import { ProtocolAPI } from '@/vue_apps/Protocols/entities/ProtocolAPI'
import { ENTITIES } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import { getProtocolsSchema } from '@/vue_apps/MedicalRecords/EntryProtocols/entities/getProtocolsSchema'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import ProtocolModalWrapper from '@/vue_apps/Protocols/ProtocolModalWrapper.vue'
import { MProtocolPresenter } from '@/vue_apps/Protocols/api/MProtocolPresenter'

export default {
  name: 'EntryProtocolsApp',
  components: {
    ProtocolModalWrapper,
    MSiGenerator,
  },

  props: {
    entryId: { type: Number, required: true },
    entryTitle: { type: String, required: true },
    clientId: { type: Number, required: true },
    clinic: { type: Object, required: true },
    doctor: { type: Object, required: true },

    configurationPrintEntryTitle: Boolean,
    configurationPrintEntryInfo: Boolean,
    configurationPrintClinicHeader: Boolean,
    configurationPrintDoctorSignature: Boolean,
  },

  data () {
    return {
      protocolsList: new ProtocolsList(this.entryId),
      schema: getProtocolsSchema(),

      /* eslint-disable-next-line */
      async openProtocol (id = null, config = {}) {},
    }
  },

  computed: {
    entry () {
      return { id: this.entryId, title: this.entryTitle }
    },

    addBtnConfig () {
      return {
        tooltip: t('add'),
        canManage: 'EntryTemplate',
      }
    },
  },

  created () {
    if (tinymce.activeEditor) { tinymce.activeEditor.remove() }
    if (!this.entryId) { throw new Error('`entryId` is required') }
    this.protocolsList.fetchAll().then()
    this.wsSubscribe()
    this.subscribeToPubSubEvents()
  },

  methods: {
    wsSubscribe () {
      if (!gon.application.egisz_module_enabled) { return }

      Services.wsSubscriptions.egisz.connect(({ data, action }) => {
        if (action !== ENTITIES.PDF_GENERATED) { return }

        /** @type {ProtocolsListItem} */
        const protocolItem = this.protocolsList.data.find(({ id }) => id === data?.id)
        if (!protocolItem) { return }

        protocolItem.setPdfGenerated(true)
      })
    },

    subscribeToPubSubEvents () {
      this.$pubSub.subscribe('entryReady',
        /** @param {URLSearchParams} urlSearchParams */
        async (urlSearchParams) => {
          const protocolId = +urlSearchParams.get('protocolId')
          if (!protocolId) { return }

          await this.openProtocol(protocolId, {
            forceOpenEditor: true,
            withRestoreFromDraft: urlSearchParams.has('withRestoreFromDraft'),
          })
        }
      )
    },

    /**
     * @param {IProtocolListItem | ProtocolsListItem} protocol
     * @returns {Error | undefined} - undefined - всё ок
     */
    async onProtocolClick (protocol) {
      if (!this.$security.canManageEntryTemplate) { return }

      await this.openProtocol(protocol.id)
    },

    onAddProtocolClick () {
      this.openProtocol()
    },

    async onDeleteProtocolClick (protocol) {
      const result = await new ProtocolAPI(protocol).delete()

      if (result?.errors) { return this.errorsHandler(result.errors) }

      await this.protocolsList.fetchPage(1)
    },

    errorsHandler (errors) {
      if (errors === 'exists_error') {
        Notificator.error(t('semds.errors.semdsExists'))
      }
    },

    onGeneratePdf (protocolId) {
      new MProtocolPresenter().generatePdf(this.entryId, protocolId)
    },
  },
}
</script>
