export default {
  discountReasonId: {
    type: Number,
    default: null,
  },

  /**
   * @type import('vue').PropOptions<{id: Number, title: String}>
   */
  discountArray: {
    type: Array,
    required: true,
  },

  /**
   * Передаваемые поля в el-tree
   */
  defaultFields: {
    type: Object,
    required: true,
    default: () => ({
      id: 'id',
      label: 'title',
    }),
  },

  discountValue: {
    type: Number,
    default: 0,
  },

  /**
   * @type import('vue').PropOptions<{name: String, label: String}>
   */
  discountTypeArray: {
    type: Array,
    required: true,
  },

  discountType: {
    type: String,
    required: true,
  },

  maxDiscount: {
    type: Number,
    required: true,
  },

  isRoundOrderPrices: {
    type: Boolean,
    default: true,
  },

  isMassDiscount: {
    type: Boolean,
    default: false,
  },
}
