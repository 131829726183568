const REQUIRED_COUNTRY_CODE = '+7'
const REQUIRED_PHONE_LENGTH = 12

export const phoneValidation = (valueObj) => {
  if (!valueObj) { return [t('errors.Filled?')] }

  const value = typeof valueObj === 'string' ? valueObj : valueObj?.id

  if (!value) { return [t('errors.Filled?')] }
  if (value.includes('@')) { return [] }

  if (value.startsWith(REQUIRED_COUNTRY_CODE) && value.length === REQUIRED_PHONE_LENGTH) { return [] }

  return [t('errors.incorrectPhoneNumber')]
}
