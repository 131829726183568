import Payments from '@/vue_components/reports/PaymentsReport'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'

/**
 * @param { () => PaymentsReportFilters } getFilters
 * @return {VueConfigMin}
 */
export const getVueConfig = (getFilters) => {
  return {
    el: '#report_right_container',
    name: 'PaymentReportRoot',
    components: { Payments },
    mixins: [ReportWrapper],

    data () {
      return {
        filters: getFilters(),
      }
    },

    template: `
      <payments
        ref="report"
        :isHidden="isHidden"
        v-bind="filters"
      />`,
  }
}
