<template>
  <div class="flex">
    <div class="form-label">
      <label for="company_phone1">
        {{ t('attributes.phone1') }}
        <explanation-mark :text="t('activerecord.explanation.text')" />
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper :errors="validationErrors.phone1">
        <phone-input
          id="company_phone1"
          name="company[phone1]"
          :value="phone1"
          class="w-100"
          @input="$updateSync('phone1', $event)"
          @mask-settings-change="$emit('phone1-mask-settings-change', $event)"
          @blur="$emit('phone1-blur-input')"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label for="company_phone2">
        {{ t('attributes.phone2') }}
        <explanation-mark :text="t('activerecord.explanation.text')" />
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper :errors="validationErrors.phone2">
        <phone-input
          id="company_phone2"
          name="company[phone2]"
          :value="phone2"
          class="w-100"
          @input="$updateSync('phone2', $event)"
          @mask-settings-change="$emit('phone2-mask-settings-change', $event)"
          @blur="$emit('phone2-blur-input')"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label for="company_web">
        {{ t('web') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        id="company_web"
        name="company[web]"
        type="text"
        clearable
        :value="web"
        @input="$updateSync('web', $event)"
      />
    </div>

    <div class="form-label">
      <label for="company_email">
        {{ t('email') }}
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper :errors="validationErrors.email">
        <el-input
          id="company_email"
          name="company[email]"
          type="text"
          clearable
          :value="email"
          @input="$updateSync('email', $event)"
          @blur="$emit('email-blur-input')"
        />
      </validation-wrapper>
    </div>
  </div>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import PhoneInput from '@/vue_components/common/phone_input'
import ExplanationMark from '@/vue_components/common/explanation_mark'

export default {
  name: 'TabsContactsForm',
  components: {
    PhoneInput,
    ValidationWrapper,
    ExplanationMark,
  },

  props: {
    phone1: {
      type: String,
      default: '',
    },

    phone2: {
      type: String,
      default: '',
    },

    web: {
      type: String,
      default: '',
    },

    email: {
      type: String,
      default: '',
    },

    validationErrors: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
