<template>
  <validation-wrapper
    :errors="validationMessages"
    :fixed-height="fixedHeight"
  >
    <the-mask
      class="w-100"
      :value="value"
      :mask="SNILS_THE_MASK"
      :disabled="disabled"
      @input="$emit('input', $event)"
    />
  </validation-wrapper>
</template>

<script>

import { SNILS_THE_MASK } from '@/specific/clients/methods/snils'
import { TheMask } from 'vue-the-mask'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'

export default {
  name: 'SnilsInput',
  components: {
    ValidationWrapper,
    TheMask,
  },

  props: {
    value: {
      type: String,
      default: null,
    },

    validationMessages: {
      type: Array,
      default: () => [],
    },

    fixedHeight: Boolean,
    disabled: Boolean,
  },

  data () {
    return {
      SNILS_THE_MASK,
    }
  },
}
</script>
