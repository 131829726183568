<template>
  <div class="flex-form flex-direction-column flex-nowrap">
    <laboratory-form-base
      :title="title"
      :validation-messages="validationMessages"
      :disabled="isReadOnly || isSpecialLaboratory"
      @update:title="$updateSync('title', $event)"
    />

    <slot
      v-if="additionalFields"
      name="extended-fields"
    >
      <div class="form-label">
        <label>
          {{ t('laboratories.form_fields.extra_charge_type') }}
        </label>
      </div>

      <div class="form-value">
        <buttons-group
          :disabled="isReadOnly"
          :value="entriesExtraChargeType"
          :items="[{value: 'fixed', label: t('laboratories.fixed_value')}, {value: 'percent', label: t('laboratories.percents')}]"
          @input="$updateSync('entriesExtraChargeType', $event)"
        />
      </div>

      <div class="form-label">
        <label for="analysis_laboratory_entries_extra_charge_value">
          {{ t('activerecord.attributes.analysis_laboratory.entries_extra_charge_value') }}
        </label>
      </div>

      <div class="form-value">
        <validation-wrapper
          :errors="validationMessages.extraChargeValue"
          fixed-height
        >
          <el-input
            id="analysis_laboratory_entries_extra_charge_value"
            :disabled="isReadOnly"
            :value="entriesExtraChargeValue"
            type="number"
            size="small"
            name="analysis_laboratory[entries_extra_charge_value]"
            clearable
            @input="$updateSync('entriesExtraChargeValue', Number($event))"
          />
        </validation-wrapper>
      </div>

      <slot name="allow-same">
        <el-checkbox
          :disabled="isReadOnly"
          :value="allowSameEntries"
          @change="$updateSync('allowSameEntries', $event)"
        >
          <span class="font-size-13">
            {{ t('laboratories.allow_same_entries') }}
          </span>
        </el-checkbox>
      </slot>
    </slot>

    <slot
      v-if="isBarcodeTemplateEnabled"
      name="barcode-template"
    >
      <el-collapse>
        <el-collapse-item name="barcodeEditor">
          <template slot="title">
            <i class="fad fa-barcode-alt" />

            {{ t('laboratories.form_fields.barcode_editor') }}

            <required-mark :text="barcodeTooltip" />
          </template>

          <div class="form-value">
            <validation-wrapper
              :errors="validationMessages.barcodeTemplate"
              fixed-height
            >
              <el-input
                id="analysis_laboratory_entries_barcode_template"
                type="textarea"
                :autosize="{ minRows: 18, maxRows: 30}"
                :value="barcodeTemplate"
                :disabled="isReadOnly"
                name="analysis_laboratory[entries_barcode_template]"
                clearable
                @input="$updateSync('barcodeTemplate', $event)"
                @click.native="$updateSync('barcodeTemplateCaretPosition', $event.target.selectionStart)"
                @keyup.native="$updateSync('barcodeTemplateCaretPosition', $event.target.selectionStart)"
              />
            </validation-wrapper>
          </div>

          <slot name="barcode-variables" />
        </el-collapse-item>
      </el-collapse>
    </slot>

    <slot />
    <div class="catalogs-form__btns">
      <button
        type="button"
        :disabled="isReadOnly"
        class="btn btn-sm btn-with-icon"
        :class="btnClass"
        @click="$emit('successSubmit')"
      >
        <span class="btn-with-icon_icon fad fa-save" />
        <span>{{ t('save') }} </span>
      </button>

      <span v-if="isDelete">
        <popover
          v-tooltip="t('delete')"
          class="el-popover__reference-wrapper"
          @yes="$emit('ask-delete')"
        >
          <template slot="reference">
            <i
              v-if="canManage"
              id="delete-record"
              class="fad fa-trash red pointer"
            />
          </template>
        </popover>
      </span>
    </div>
  </div>
</template>

<script>
import LaboratoryFormBase
  from '@/vue_apps/catalogs_root/analysis_laboratories/components/base_forms/LaboratoryFormBase.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import ButtonsGroup from '@/vue_components/common/ButtonsGroup/ButtonsGroup.vue'
import popover from '@/vue_components/common/popover/popover.vue'
import requiredMark from '@/vue_components/common/RequiredMark/RequiredMark.vue'
import { SPECIAL_CLINICS } from '@/vue_apps/catalogs_root/analysis_laboratories/const/create_laboratories'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'

export default {
  name: 'LaboratoryFormExtended',
  components: { ButtonsGroup, ValidationWrapper, LaboratoryFormBase, popover, requiredMark },
  props: {
    canManage: Boolean,

    selectedLaboratory: {
      type: Object,
      default: () => ({}),
    },

    mode: {
      type: String,
      default: MODES.SHOW,
    },

    isEditMode: Boolean,

    isSpecialLaboratory: Boolean,

    validationMessages: {
      type: Object,
      default: () => ({}),
    },

    title: {
      type: String,
      default: null,
    },

    entriesExtraChargeValue: {
      type: Number,
      default: null,
    },

    entriesExtraChargeType: {
      type: String,
      default: null,
    },

    allowSameEntries: {
      type: Boolean,
      default: true,
    },

    barcodeTemplate: {
      type: String,
      default: null,
    },
  },

  computed: {
    btnClass () {
      return this.isEditMode ? 'btn-warning' : 'btn-success'
    },

    isDelete () {
      return this.canManage && !this.selectedLaboratory.systemName && (this.mode !== MODES.CREATE)
    },

    isReadOnly () {
      return !this.canManage
    },

    additionalFieldsInvitro () {
      return (this.selectedLaboratory.systemName === SPECIAL_CLINICS.INVITRO) && gon.specific.invitro_enabled
    },

    additionalFieldsHelix () {
      return (this.selectedLaboratory.systemName === SPECIAL_CLINICS.HELIX) && gon.specific.helix_enabled
    },

    additionalFieldsKdl () {
      return (this.selectedLaboratory.systemName === SPECIAL_CLINICS.KDL ||
          this.selectedLaboratory.systemName === SPECIAL_CLINICS.KDL_CHECKUPS) &&
          gon.specific.kdl_enabled
    },

    additionalFields () {
      return this.additionalFieldsInvitro ||
             this.additionalFieldsHelix
    },

    isBarcodeTemplateEnabled () {
      return this.additionalFieldsHelix || this.additionalFieldsKdl
    },

    barcodeTooltip () {
      return this.additionalFieldsHelix
        ? t('laboratories.barcode_template_editor_warning_helix')
        : t('laboratories.barcode_template_editor_warning_general')
    },
  },
}
</script>
