<template>
  <div id="btn-trigger-group">
    <div
      class="side-menu-trigger calendar-trigger"
      :class="getButtonClass(SIDEBAR_CALENDAR)"
      @click="setActiveSidebarStore(SIDEBAR_CALENDAR)"
    >
      <span class="trigger-icon fad fa-calendar-alt" />
    </div>
    <guarded-control
      tag="div"
      :permissions="['canViewAppointmentGrid']"
      class="side-menu-trigger online-recording-trigger"
      :class="getButtonClass(SIDEBAR_ONLINE_RECORDING)"
      @click:allowed="setActiveSidebarStore(SIDEBAR_ONLINE_RECORDING)"
    >
      <span class="trigger-icon fad fa-globe" />
      <span
        class="item-counter"
      >
        {{ onlineRecordingCount }}
      </span>
    </guarded-control>
    <guarded-control
      tag="div"
      :permissions="['canViewWaitingList']"
      class="side-menu-trigger waiting-list-trigger"
      :class="getButtonClass(SIDEBAR_WAITING_LIST)"
      @click:allowed="setActiveSidebarStore(SIDEBAR_WAITING_LIST)"
    >
      <span class="trigger-icon fad fa-clipboard-list" />
      <span
        class="item-counter"
      >
        {{ waitingListCount }}
      </span>
    </guarded-control>
    <guarded-control
      tag="div"
      :permissions="['canViewReminder']"
      class="side-menu-trigger reminders-trigger"
      :class="getButtonClass(SIDEBAR_REMINDERS)"
      @click:allowed="setActiveSidebarStore(SIDEBAR_REMINDERS)"
    >
      <span class="trigger-icon fad fa-tasks" />
      <span
        class="item-counter"
      >
        {{ remindersCount }}
      </span>
    </guarded-control>
  </div>
</template>

<script>
import {
  SIDEBAR_CALENDAR,
  SIDEBAR_ONLINE_RECORDING,
  SIDEBAR_REMINDERS,
  SIDEBAR_WAITING_LIST,
} from '../consts.js'

import { mapActions, mapGetters } from 'vuex'
import GuardedControl from '@/vue_components/common/guarded_control'

export default {
  components: { GuardedControl },
  props: {
    onlineRecordingCount: {
      type: Number,
      default: 0,
    },
    waitingListCount: {
      type: Number,
      default: 0,
    },
    remindersCount: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      SIDEBAR_CALENDAR,
      SIDEBAR_REMINDERS,
      SIDEBAR_WAITING_LIST,
      SIDEBAR_ONLINE_RECORDING,
    }
  },

  computed: {
    ...mapGetters(['GET_ACTIVE_SIDEBAR']),
  },

  methods: {
    ...mapActions(['setActiveSidebarStore']),
    getButtonClass (sidebar) {
      return {
        active: this.GET_ACTIVE_SIDEBAR === sidebar,
      }
    },
  },

}
</script>
