<template>
  <div class="flex">
    <div class="form-label" />

    <div class="form-value suggestion-search-value-container">
      <dadata-select
        type="PARTY"
        :value="searchQuery"
        :placeholder="t('search_by_title_inn_or_ogrn')"
        @change="setCompany"
        @update:value="$updateSync('searchQuery', $event)"
      >
        <template #default="{ optionsList }">
          <el-option
            v-for="option in optionsList"
            :key="option.data.hid"
            :value="option"
            :label="option.unrestricted_value"
          />
        </template>
      </dadata-select>
    </div>

    <div class="form-label">
      <label for="company_title">
        {{ t('title') }}

        <required-mark class="text-decoration-none" />
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper :errors="validationErrors.title">
        <el-input
          id="company_title"
          name="company[title]"
          clearable
          type="text"
          :value="title"
          @input="$updateSync('title', $event)"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label>
        {{ t('activerecord.attributes.company.company_types') }}

        <required-mark class="text-decoration-none" />
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper :errors="validationErrors.company_types">
        <items-list
          name="company[company_type_ids][]"
          :items="companyTypes"
          :selected-items-ids="companyTypeIds"
          id-item-field="id"
          displayed-item-field="title"
          @change="$updateSync('companyTypeIds', $event)"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label for="company_type_of_company">
        {{ t('type_of_company') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        id="company_type_of_company"
        name="company[type_of_company]"
        clearable
        type="text"
        :value="typeOfCompany"
        @input="$updateSync('typeOfCompany', $event)"
      />
    </div>

    <div class="form-label">
      <label for="company_individual_tax_number">
        {{ t('inn') }}
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper :errors="validationErrors.individual_tax_number">
        <el-input
          id="company_individual_tax_number"
          name="company[individual_tax_number]"
          clearable
          type="text"
          :value="individualTaxNumber"
          @input="$updateSync('individualTaxNumber', $event)"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label for="company_kpp">
        {{ t('kpp') }}
        <abbr
          v-if="isKppRequired"
          v-tooltip="t('activerecord.required.text')"
        >
          *
        </abbr>
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper :errors="validationErrors.kpp">
        <el-input
          id="company_kpp"
          name="company[kpp]"
          clearable
          type="number"
          :value="kpp"
          @input="$updateSync('kpp', $event)"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label for="company_ogrn">
        {{ t('ogrn') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        id="company_ogrn"
        name="company[ogrn]"
        clearable
        type="number"
        :value="ogrn"
        @input="$updateSync('ogrn', $event)"
      />
    </div>

    <div class="form-label">
      <label for="company_okved">
        {{ t('ogrn') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        id="company_okved"
        name="company[okved]"
        clearable
        type="text"
        :value="okved"
        @input="$updateSync('okved', $event)"
      />
    </div>

    <div class="form-label">
      <label for="company_registration_date">
        {{ t('registration_date') }}
      </label>
    </div>

    <div class="form-value">
      <date-input
        id="company_registration_date"
        name="company[registration_date]"
        :value="registrationDate"
        class="w-100"
        @input="$updateSync('registrationDate', $event)"
      />
    </div>

    <div class="form-label">
      <label for="company_note">
        {{ t('additional') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        id="company_note"
        name="company[note]"
        type="textarea"
        :rows="2"
        resize="none"
        :value="note"
        @input="$updateSync('note', $event)"
      />
    </div>
  </div>
</template>

<script>
import DadataSelect from '@/vue_components/common/select/dadata_select/dadata_select'
import RequiredMark from '@/vue_components/common/required_mark'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import ItemsList from '@/vue_components/items_list.vue'
import DateInput from '@/vue_components/common/date_input'
import formatter from '@/lib/formatters/formatter'
import props from './props'
import { mapGetters } from 'vuex'
import { INSURANCE_COMPANY_TYPE_ID } from '@/vue_components/companies/components/consts'

export default {
  name: 'TabsCompanyForm',
  components: {
    DadataSelect,
    RequiredMark,
    ItemsList,
    ValidationWrapper,
    DateInput,
  },

  props,

  computed: {
    ...mapGetters('globalCatalogs/nsiStore/russianSubjectsDictionaryStore', {
      getRussianSubject: 'vxGetRussianSubjectMethod',
    }),

    isKppRequired () {
      return this.companyTypeIds.includes(INSURANCE_COMPANY_TYPE_ID)
    },
  },

  methods: {
    setCompany (suggestion) {
      if (!suggestion) {
        return
      }

      const { data } = suggestion
      this.$updateSync('title', data.name.full_with_opf)
      this.$updateSync('individualTaxNumber', data.inn || '')
      this.$updateSync('kpp', data.kpp || '')
      this.$updateSync('ogrn', data.ogrn || '')
      this.$updateSync('okved', data.okved || '')
      this.$updateSync(
        'registrationDate',
        formatter.formatValue(data.ogrn_date, ['date:localDate'])
      )
      this.$updateSync('note', '')

      if (data.address && data.address.data) {
        const address = data.address.data

        this.$updateSync('index', address.postal_code || '')
        this.$updateSync('country', address.country || '')
        this.$updateSync('region', address.region_with_type || '')
        this.$updateSync('area', address.city_area || address.area_with_type || '')
        this.$updateSync('city', address.city_with_type || address.settlement_with_type || '')
        this.$updateSync('street', address.street_with_type || '')
        this.$updateSync('house', address.house || '')
        this.$updateSync('flat', address.flat || '')
        this.$updateSync('nsiRussianSubjectId', this.getRussianSubject(address.region)?.id || null)
      }

      const fio = data.management ? data.management.name.split(' ') : []

      this.$updateSync('headSurname', fio[0] || '')
      this.$updateSync('headName', fio[1] || '')
      this.$updateSync('headSecondName', fio[2] || '')
      this.$updateSync('headPosition', (data.management && data.management.post) || '')
      this.$updateSync('headOperatesBy', '')
    },
  },
}
</script>
