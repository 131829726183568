import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const documentListSchema = () =>
  new MSiSchema()
    .addPagination()
    .addFilterButtons()
    .addTableSchema(
      new MTableSchema()
        .addHeaders({
          date: new MTableHeader(t('common.date')).addFixedWidth('100px'),
          number: new MTableHeader(t('common.number')).addFixedWidth('100px'),
          title: new MTableHeader(t('title')),
          print: new MTableHeader().addFixedWidth('40px'),
        })
        .addDeleteIcon()
        .addCheckboxes()
    )
