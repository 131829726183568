import { request } from '@/lib/transport/request'
import { durationAdapter } from './appointment_durations_adapters'

export const durationsEndpoint = {
  getAll () {
    const options = {
      type: 'GET',
      url: Routes.list_reception_appointment_durations_path(),
    }

    return request(options).promise
  },

  update (data) {
    const options = {
      type: 'PATCH',
      url: Routes.reception_appointment_duration_path(data.id),
      data: durationAdapter.toServer(+data.duration),
    }

    return request(options).promise
  },

  create (data) {
    const options = {
      type: 'POST',
      url: Routes.reception_appointment_durations_path(),
      data: durationAdapter.toServer(+data.duration),
    }

    return request(options).promise
  },

  destroy (id) {
    const options = {
      type: 'DELETE',
      url: Routes.reception_appointment_duration_path(id),
    }

    return request(options).promise
  },
}
