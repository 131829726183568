<template>
  <small-dropdown
    class="hidden-print"
    @menu-item-click="runCommand"
  >
    <template #button-content>
      <i class="fad fa-fw fa-print" />
      {{ t('print') }}
    </template>
    <template #default>
      <el-dropdown-item :command="[print]">
        {{ t('reception.print_d_appointments') }}
      </el-dropdown-item>
      <el-dropdown-item
        :command="[visit, $routes.adjust_scheduler_print_appointments_path(), '_blank']"
      >
        {{ t('reception.print_appointments') }}
      </el-dropdown-item>
      <el-dropdown-item
        :command="[visit, $routes.adjust_scheduler_print_scheduler_path(), '_blank']"
      >
        {{ t('reception.print_d_scheduler') }}
      </el-dropdown-item>
    </template>
  </small-dropdown>
</template>

<script>
import SmallDropdown from '@/vue_components/common/small_dropdown'

export default {
  name: 'PrintDropdown',
  components: { SmallDropdown },
  methods: {
    /**
     *
     * @param {[Function, any]} command
     */
    runCommand (command) {
      command[0](...command.slice(1))
    },
    print () {
      window.print()
    },
    /**
     * @param {string} route
     * @param {string} [target]
     */
    visit (route, target = '_blank') {
      window.open(route, target)
    },
  },
}
</script>
