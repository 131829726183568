<template>
  <div
    id="online-recording-list-wrapper"
    class="side-menu-wrapper"
  >
    <div
      class="online-recording-list-control-element"
    >
      <div class="online-recording-list-only-need-approval-appointments">
        <input
          id="only-need-approval"
          type="checkbox"
          class="checkbox"
          :checked="onlyNeedApproval"
          :class="activeRequestState && 'disabled'"
          @change="toggleOnlyNeedApprovalAppointments($event.target.checked)"
        >
        <label for="only-need-approval">
          {{ T.only_unconfirmed }}
        </label>
      </div>
    </div>
    <div
      ref="list"
      class="online-recording-list"
    >
      <ul v-if="appointments.length">
        <transition-group
          :name="disableListAnimation ? 'disable' : 'online-recording-list-transition'"
        >
          <li
            v-for="appointment in appointments"
            :key="appointment.id"
            @click="$emit('edit-appointment', appointment)"
          >
            <div class="date-client-line cut">
              <friendly-date
                class="appointment-date"
                :date="appointment.date"
                :to-format="GET_LOCALIZATION_DATE_FORMAT"
              />
              <span class="client-data">
                {{ appointment.client.shortname }}
              </span>
            </div>
            <div class="doctor-line">
              <div class="doctor-line-short-name prompt-notice cut">
                {{ appointment.user.short_name }}
              </div>
              <div
                class="online-recording-list-command-icons"
              >
                <div
                  class="online-recording-list-command-icon"
                  @click.stop="$emit('show-appointment-in-schedule', {
                    userId: appointment.user.id,
                    date: appointment.date,
                    appointmentId: appointment.id,
                    clinicId: appointment.clinic_id
                  })"
                >
                  <span
                    :title="T.find_in_schedule"
                    class="tooltip-bottom fad fa-fw fa-calendar"
                    data-hintPosition="top-right"
                  />
                </div>
                <div
                  v-if="canManageAppointment"
                  class="online-recording-list-command-icon"
                  :class="changeAppointmentStatusClass(appointment)"
                  @click.stop="changeAppointmentStatus(appointment)"
                >
                  <span
                    :data-appointment-id="appointment.id"
                    :data-appointment-status="appointment.status"
                    :title="T.confirm_appointment"
                    class="tooltip-bottom fad fa-fw fa-thumbs-up"
                    data-hintPosition="top-right"
                  />
                </div>
              </div>
            </div>
          </li>
        </transition-group>
      </ul>
      <div v-if="showEmpty">
        {{ t('online_recording_empty') }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  APPOINTMENT_SOURCE_MEDODS,
  ONLINE_RECORDING_ITEM_HEIGHT,
  ONLINE_RECORDING_LIST_ANIMATION_TIMEOUT,
  SIDEBAR_LAZY_LOAD_DEBOUNCE,
  ONLY_NEED_APPROVAL_APPOINTMENTS_STORAGE_KEY,
  SIDEBAR_LAZY_LOAD_THRESHOLD, ITEM_CREATED, ITEM_UPDATED, ITEM_DELETED, isAppointmentMessage,
} from './consts.js'
import { updateAppointmentStatus } from './rest.js'
import FriendlyDate from '@/vue_components/common/friendly_date'
import { mapGetters } from 'vuex'
import { APPOINTMENT_STATUS } from '@/vue_components/appointment/const'
import { debounce, throttle } from 'lodash/function'

/**
 * Загружает список онлайн-записей на приём и отображает его.
 * Имеет два важных поля состояния - флаг "Только неподтверждённые" и сами
 * онлайн-записи. Флаг может быть проставлен снаружи, но предназначен он не для
 * этого. Он нужен, чтобы в `right_sidebar` и этом компоненте был флаг и они
 * были синхронизированы. Источником данных для флага является этот компонент,
 * так как флаги компонентов в сайдбаре - не ответственность сайдбара, он их
 * только потребляет.
 *
 * TBD: описание работы сокетов.
 */
export default {
  name: 'OnlineRecordingList',
  components: { FriendlyDate },
  props: {
    visibility: Boolean,
    onlyNeedApproval: {
      type: Boolean,
      required: true,
    },
    onlineRecordingCount: {
      type: Number,
      required: true,
    },
  },

  data () {
    return {
      appointments: [],
      appointmentsActiveRequestState: new Set(),
      activeRequestState: false,
      canManageAppointment: false,
      offset: 0,
      limit: 0,

      disableListAnimation: false,
      windowResizeTimeout: null,
      tooltipsMap: Object.create(null),
      prevClinicId: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
    }
  },

  computed: {
    ...mapGetters([
      'GET_LOCALIZATION_DATE_FORMAT',
      'GET_SCHEDULE_CURRENT_CLINIC_ID',
      'GET_LOADING_RIGHT_SIDEBAR',
    ]),

    showEmpty () {
      return !(this.GET_LOADING_RIGHT_SIDEBAR || this.appointments.length)
    },

    canLoadMoreItems () {
      return !this.GET_LOADING_RIGHT_SIDEBAR && !this.allItemsLoaded
    },

    allItemsLoaded () {
      return this.appointments.length === this.onlineRecordingCount
    },
  },

  watch: {
    appointments () {
      this.updateTooltips()
      this.offset = this.appointments.length
    },

    visibility () {
      if (this.visibility) {
        this.requestData()
      }
    },

    onlyNeedApproval () {
      if (this.visibility) {
        this.requestData(true)
      }
    },

    GET_SCHEDULE_CURRENT_CLINIC_ID (newValue, oldValue) {
      this.prevClinicId = oldValue
      this.appointments = []
    },
  },

  mounted () {
    const storedOnlyNeedApproval = localStorage.getItem(
      ONLY_NEED_APPROVAL_APPOINTMENTS_STORAGE_KEY,
    )

    this.setOnlyNeedApprovalAppointments(storedOnlyNeedApproval !== 'false')

    this.canManageAppointment = Services.security.canManageAppointment
    if (this.visibility) {
      this.requestData(true)
    }

    this.$refs.list.addEventListener('scroll', this.loadMoreItems)
    window.addEventListener('resize', this.loadMoreItems)

    Services.wsSubscriptions.appointment.connect(
      (packet) => {
        if (!isAppointmentMessage(packet)) return

        this.$emit('count-change')
        if (packet.batch) {
          this.appointmentWSBatchUpdatesHandler(packet)
        } else {
          this.appointmentsWSUpdatesHandler(packet)
        }
        this.sortList()
      },
    )
  },

  beforeDestroy () {
    this.$refs.list.removeEventListener('scroll', this.loadMoreItems)
    window.removeEventListener('resize', this.loadMoreItems)
  },

  methods: {
    /**
     *
     * @param {boolean} withNeedApproval
     */
    toggleOnlyNeedApprovalAppointments (withNeedApproval) {
      if (this.activeRequestState) return

      this.setOnlyNeedApprovalAppointments(withNeedApproval)
    },

    setOnlyNeedApprovalAppointments (value) {
      this.$updateSync('onlyNeedApproval', value)
      localStorage.setItem(
        ONLY_NEED_APPROVAL_APPOINTMENTS_STORAGE_KEY,
        value,
      )
    },

    appointmentWSBatchUpdatesHandler (batchUpdatePacket) {
      batchUpdatePacket.data.forEach((appointment) => {
        this.appointmentsWSUpdatesHandler({
          type: batchUpdatePacket.type,
          action: batchUpdatePacket.action,
          data: appointment,
        })
      })
    },

    appointmentsWSUpdatesHandler (packet) {
      const { data: appointment } = packet

      if (appointment.clinic_id !== this.GET_SCHEDULE_CURRENT_CLINIC_ID ||
        appointment.appointment_source_id === APPOINTMENT_SOURCE_MEDODS) {
        return
      }

      const handleAllAppointments = !this.onlyNeedApproval
      const statusNeedApproval = appointment.status === APPOINTMENT_STATUS.NEED_APPROVAL
      const itemConform = handleAllAppointments || statusNeedApproval

      switch (packet.action) {
        case ITEM_CREATED: {
          if (this.allItemsLoaded && itemConform) {
            this.addItem(appointment)
          }
          break
        }
        case ITEM_UPDATED: {
          const existingItemIndex = this.appointments.findIndex((item) => item.id === appointment.id)
          const itemIsPresent = existingItemIndex >= 0

          if (itemIsPresent && itemConform) {
            this.updateItem(appointment, existingItemIndex)
          } else if (itemIsPresent && !itemConform) {
            this.removeItem(appointment.id)
          } else if (!itemIsPresent && itemConform) {
            this.addItem(appointment)
          }
          break
        }
        case ITEM_DELETED: {
          if (itemConform) {
            this.removeItem(appointment.id)
          }
          break
        }
      }
    },

    calculateLimit () {
      this.limit = Math.ceil(this.$refs.list.clientHeight / ONLINE_RECORDING_ITEM_HEIGHT) * 2
    },

    loadMoreItems: throttle(function (e) {
      const bottomListPosition = (e.target.scrollTop + e.target.clientHeight) / e.target.scrollHeight
      if (bottomListPosition < SIDEBAR_LAZY_LOAD_THRESHOLD) return
      if (!this.canLoadMoreItems) return

      this.requestData()
    }, SIDEBAR_LAZY_LOAD_DEBOUNCE),

    requestData: debounce(function (resetAll = false) {
      const god = this

      this.$store.commit('SET_LOADING_RIGHT_SIDEBAR', true)

      if (this.prevClinicId !== this.GET_SCHEDULE_CURRENT_CLINIC_ID) {
        resetAll = true
        this.prevClinicId = this.GET_SCHEDULE_CURRENT_CLINIC_ID
      }

      this.calculateLimit()
      if (resetAll) {
        this.offset = 0
      }

      $.ajax({
        type: 'GET',
        url: Routes.fetch_online_recording_appointments_appointments_path(),
        data: {
          only_need_approval: this.onlyNeedApproval,
          offset: this.offset,
          limit: this.limit,
          clinic_id: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
        },
        success (appointments) {
          if (resetAll) {
            god.scrollUp()
            god.appointments = []
          }

          god.disableListAnimation = true
          god.appointments.push(...appointments)
          god.sortList()

          setTimeout(() => {
            god.disableListAnimation = false
          }, ONLINE_RECORDING_LIST_ANIMATION_TIMEOUT)
        },
        complete () {
          god.$store.commit('SET_LOADING_RIGHT_SIDEBAR', false)
        },
      })
    }, SIDEBAR_LAZY_LOAD_DEBOUNCE),

    changeAppointmentStatus ({ id, status }) {
      const god = this

      if (
        status !== APPOINTMENT_STATUS.NEED_APPROVAL ||
        god.appointmentsActiveRequestState.has(id)
      ) {
        return
      }

      god.appointmentsActiveRequestState.add(id)
      god.$store.commit('SET_LOADING_RIGHT_SIDEBAR', true)

      updateAppointmentStatus({ id, status: 'confirmed_by_administrator' })
        .then(() => {
          god.appointmentsActiveRequestState.delete(id)
        })
        .catch((error) => {
          console.error('online_recording_list:changeAppointmentStatus', error)
          Notificator.error(t('abstract_error_message'))
        })
        .finally(() => {
          god.$store.commit('SET_LOADING_RIGHT_SIDEBAR', false)
        })
    },

    addItem (appointment) {
      this.appointments.unshift(appointment)
    },

    updateItem (appointment, index) {
      this.$set(this.appointments, index, appointment)
      setTimeout(
        this.synchronizeChangeAppointmentStatusTippy,
        8,
        appointment.id,
      )
    },

    removeItem (id) {
      const appointmentIndex = this.appointments.findIndex((appointment) => appointment.id === id)
      this.appointments.splice(appointmentIndex, 1)

      if (appointmentIndex > 0) {
        const tippy = this.tooltipsMap[id]
        if (tippy) {
          tippy.destroy()
        }
      }
    },

    sortList () {
      this.appointments.sort((app1, app2) => {
        return app2.created_at_in_milliseconds - app1.created_at_in_milliseconds
      })
    },

    updateTooltips () {
      setTimeout(() => {
        const tippy = Utils.epicUpdateTooltipsContainer({
          selector: this.$refs.list,
          extendDefaultConfig: Object.freeze({ delay: [500, 20] }),
        })

        tippy.forEach((tippy) => {
          const {
            appointmentId,
            appointmentStatus,
          } = tippy.reference.dataset

          if (appointmentId) this.tooltipsMap[appointmentId] = tippy
          if (appointmentStatus) {
            this.synchronizeChangeAppointmentStatusTippy(appointmentId)
          }
        })
      })
    },

    synchronizeChangeAppointmentStatusTippy (appointmentId) {
      const tippy = this.tooltipsMap[appointmentId]

      if (!tippy) return

      const { appointmentStatus } = tippy.reference.dataset

      if (appointmentStatus === APPOINTMENT_STATUS.NEED_APPROVAL) {
        tippy.enable()
      } else {
        tippy.hide()
        tippy.disable()
      }
    },

    changeAppointmentStatusClass ({ status }) {
      if (status === APPOINTMENT_STATUS.NEED_APPROVAL) return null

      return 'disabled'
    },

    scrollUp () {
      this.$refs.list.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>
