<template>
  <div class="flex">
    <div class="form-label">
      <label for="company_head_surname">
        {{ t('activerecord.attributes.company.head_surname') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        id="company_head_surname"
        name="company[head_surname]"
        type="text"
        clearable
        :value="headSurname"
        @input="$updateSync('headSurname', $event)"
      />
    </div>

    <div class="form-label">
      <label for="company_head_name">
        {{ t('activerecord.attributes.company.head_name') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        id="company_head_name"
        name="company[head_name]"
        type="text"
        clearable
        :value="headName"
        @input="$updateSync('headName', $event)"
      />
    </div>

    <div class="form-label">
      <label for="company_head_second_name">
        {{ t('activerecord.attributes.company.head_second_name') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        id="company_head_second_name"
        name="company[head_second_name]"
        type="text"
        clearable
        :value="headSecondName"
        @input="$updateSync('headSecondName', $event)"
      />
    </div>

    <div class="form-label">
      <label for="company_head_position">
        {{ t('activerecord.attributes.company.head_position') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        id="company_head_position"
        name="company[head_position]"
        type="text"
        clearable
        :value="headPosition"
        @input="$updateSync('headPosition', $event)"
      />
    </div>

    <div class="form-label">
      <label for="company_head_operates_by">
        {{ t('activerecord.attributes.company.head_operates_by') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        id="company_head_operates_by"
        name="company[head_operates_by]"
        type="text"
        clearable
        :value="headOperatesBy"
        @input="$updateSync('headOperatesBy', $event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabsHeadForm',

  props: {
    headSurname: {
      type: String,
      default: '',
    },

    headName: {
      type: String,
      default: '',
    },

    headSecondName: {
      type: String,
      default: '',
    },

    headPosition: {
      type: String,
      default: '',
    },

    headOperatesBy: {
      type: String,
      default: '',
    },
  },
}
</script>
