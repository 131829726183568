<template>
  <si-control
    style="gap: 5px"
    @refresh="vxRefreshFilters()"
    @reset="resetFilters(true)"
  >
    <!-- Клиники -->
    <simple-select
      :value="vxFilters[clinicIds]"
      :items="vxClinics"
      :fixed-height="false"
      :class="{ 'more-than-1': moreThan1(vxFilters[clinicIds]) }"
      class="clinics el-select-multi"
      value-key="id"
      clearable
      multiple
      collapse-tags
      @change="loadCatalogs($event); setFilterValue(clinicIds, $event)"
    />

    <!-- Фильтр по ... -->
    <simple-select
      v-model="selectedFilterType"
      class="time-finder__filter-by"
      :items="vxFilterTypes"
      :fixed-height="false"
      @change="onFiltersChange(vxFilters)"
    />

    <!-- Фильтр по врачам -->
    <doctor-multi-select
      v-show="isDoctor"
      :value="vxFilters[userIds]"
      :items="vxDoctors"
      :class="{ 'more-than-1': moreThan1(vxFilters[userIds]) }"
      class="doctors el-select-multi"
      @change="setFilterValue(userIds, $event)"
    />

    <!-- Фильтр по специальностям -->
    <specialty-multi-select
      v-show="isSpecialty"
      :value="vxFilters[specialtyIds]"
      :items="vxSpecialties"
      :class="{ 'more-than-1': moreThan1(vxFilters[specialtyIds]) }"
      class="specialties el-select-multi"
      @change="setFilterValue(specialtyIds, $event)"
    />

    <!-- Фильтр по услугам -->
    <entry-type-search-multi-select
      v-show="isEntryType"
      :clinic-ids="vxFilters[clinicIds]"
      :value="vxFilters[entryTypeIds]"
      :class="{ 'more-than-1': moreThan1(vxFilters[entryTypeIds]) }"
      class="entry-types el-select-multi"
      value-key="id"
      @change="onEntryTypeFilterChange"
    />

    <!-- сегодня/завтра -->
    <date-range-picker-btn
      :date-range="vxFilters[date]"
      :buttons="rangePickerButtons"
      class="remove-mr"
      @change="setFilterValue(date, $event)"
    />

    <!-- Календарь -->
    <date-range-picker
      :date-range="vxFilters[date]"
      :application-date-format="dateFormat"
      class="hidden-print remove-mr el-input-view-fixer"
      :shortcuts="dateRangePickerShortcuts"
      @change="setFilterValue(date, $event)"
    />

    <!-- Начало по времени -->
    <el-time-select
      v-tooltip="t('reception.time_finder.start_time')"
      :value="vxFilters[startTime]"
      class="time_select hidden-print remove-mr el-input-view-fixer"
      :placeholder="t('reception.time_finder.start_time')"
      :picker-options="startTimePickerOptions"
      placement="bottom-start"
      @input="setFilterValue(startTime, $event)"
    />

    <!-- Окончание по времени -->
    <el-time-select
      v-tooltip="t('reception.time_finder.end_time')"
      :value="vxFilters[endTime]"
      class="time_select hidden-print remove-mr el-input-view-fixer"
      :placeholder="t('reception.time_finder.end_time')"
      :picker-options="endTimePickerOptions"
      placement="bottom-start"
      @input="setFilterValue(endTime, $event)"
    />

    <!-- Длительность приема -->
    <el-input-number
      v-tooltip="t('reception.time_finder.duration')"
      v-bind="$attrs"
      :value="vxFilters[targetDuration]"
      :controls="false"
      :placeholder="t('reception.time_finder.duration')"
      :min="0"
      :max="999"
      class="hidden-print duration remove-mr el-input-view-fixer"
      @change="setFilterValue(targetDuration, $event)"
    />

    <!-- Ближайшие -->
    <el-checkbox
      class="mb-0 el-checkbox_big el-input-view-fixer"
      :value="vxFilters[closestRecordingPoint]"
      :label="t('reception.time_finder.only_closest_recording_point')"
      border
      size="small"
      @change="setFilterValue(closestRecordingPoint, $event)"
    />

    <template #customButtons>
      <span
        v-if="loading"
        class="ml-5 primary prompt-notice flex align-items-center"
      >
        {{ t('loading_1') }}
      </span>
    </template>
  </si-control>
</template>

<script>

import SiControl from '@/vue_components/sort_items/si_control.vue'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  FILTER_TYPE,
  FILTERS,
  getDefaultAppointmentTimeFinderFilters,
} from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/const/filters'
import EntryTypeSearchMultiSelect
  from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/components/EntryTypeSearchMultiSelect.vue'
import DoctorMultiSelect from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/components/DoctorMultiSelect.vue'
import SpecialtyMultiSelect from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/components/SpecialtyMultiSelect.vue'
import DateRangePickerBtn from '@/vue_components/common/select/date_range_picker_btn.vue'
import {
  appointmentTimeFinderDateRangePickerShortcuts,
  appointmentTimeFinderRangePickerButtons,
  appointmentTimeFinderTimePickerOptions,
  DEFAULT_FILTRATION_DEBOUNCE,
} from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/const/const'
import DateRangePicker from '@/vue_components/common/select/date_range_picker.vue'
import { cloneDeep } from 'lodash'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { loadFromCache, removeFromCache } from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/logic/cache'

export default {
  name: 'AppointmentTimeFinderFilters',
  components: { DateRangePicker, DateRangePickerBtn, SpecialtyMultiSelect, DoctorMultiSelect, EntryTypeSearchMultiSelect, SimpleSelect, SiControl },
  props: {
    filtrationDebounce: PropsTypes.Number(DEFAULT_FILTRATION_DEBOUNCE),
    doctorSchedulesClinicId: { type: Number, required: true },
    loading: Boolean,
  },

  data () {
    return {
      selectedFilterType: FILTER_TYPE.USER,
      rangePickerButtons: appointmentTimeFinderRangePickerButtons(),
      dateRangePickerShortcuts: appointmentTimeFinderDateRangePickerShortcuts(),
      startTimePickerOptions: appointmentTimeFinderTimePickerOptions(),

      filtrationTimeout: null,

      clinicIds: FILTERS.CLINICS,
      specialtyIds: FILTERS.SPECIALTIES,
      entryTypeIds: FILTERS.ENTRY_TYPES,
      startTime: FILTERS.START_TIME,
      endTime: FILTERS.END_TIME,
      date: FILTERS.DATE,
      userIds: FILTERS.USERS,
      targetDuration: FILTERS.TARGET_DURATION,
      closestRecordingPoint: FILTERS.CLOSEST_RECORDING_POINT,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      vxClinics: 'vxGetDoctorClinics',
    }),

    ...mapGetters('appointmentTimeFinder', {
      vxFilterTypes: 'vxGetFilterTypes',
      vxDoctors: 'vxGetDoctors',
      vxSpecialties: 'vxGetSpecialties',
    }),

    ...mapGetters('appointmentTimeFinder/filtersBase', {
      vxFilters: 'vxGetFilters',
    }),

    isDoctor () {
      return this.selectedFilterType === FILTER_TYPE.USER
    },

    isSpecialty () {
      return this.selectedFilterType === FILTER_TYPE.SPECIALTY
    },

    isEntryType () {
      return this.selectedFilterType === FILTER_TYPE.ENTRY_TYPE
    },

    dateFormat () {
      return this.$store.getters.GET_LOCALIZATION_DATE_FORMAT.replace(/\//g, '.')
    },

    endTimePickerOptions () {
      return { ...this.startTimePickerOptions, minTime: this.vxFilters[this.startTime] }
    },

    moreThan1 () {
      return (filter) => filter?.length > 1
    },
  },

  watch: {
    vxFilters (to) { this.onFiltersChange(cloneDeep(to)) },
  },

  created () {
    this.initFilters()
  },

  methods: {
    ...mapMutations('appointmentTimeFinder/filtersBase', {
      vxSetFilterValue: 'vxSetFilterValue',
      vxResetFilters: 'vxResetFilters',
      vxRefreshFilters: 'vxRefreshFilters',
    }),

    ...mapActions('appointmentTimeFinder', {
      vxFetchDoctors: 'vxFetchDoctors',
      vxFetchSpecialties: 'vxFetchSpecialties',
    }),

    async initFilters () {
      const filters = { ...getDefaultAppointmentTimeFinderFilters(), ...loadFromCache() }
      filters.clinicIds = filters.clinicIds?.length
        ? filters.clinicIds
        : [this.vxClinics.find((clinic) => clinic.id === this.doctorSchedulesClinicId)]

      await this.loadCatalogs(filters.clinicIds)
      this.selectedFilterType = filters.filterType
      this.vxResetFilters(filters)
    },

    /**
     * @param {Array} clinics
     * @return {Promise<Awaited<void>[]>}
     */
    loadCatalogs (clinics = []) {
      const tempClinics = clinics?.length
        ? clinics.map((clinic) => clinic.id)
        : this.vxClinics.map((clinic) => clinic.id)

      return Promise.all([
        this.vxFetchDoctors(tempClinics),
        this.vxFetchSpecialties(tempClinics),
      ])
    },

    resetFilters (hardReset = false) {
      if (hardReset) { removeFromCache() }
      this.initFilters()
    },

    /** https://www.notion.so/medods/502faa9a04274de4bdb87b3460b1d550 */
    onEntryTypeFilterChange (entryTypes = []) {
      const totalDuration = entryTypes.reduce((acc, item) => acc + (item.appointment_duration || 0), 0)

      this.setFilterValue(this.entryTypeIds, entryTypes)
      this.setFilterValue(this.targetDuration, totalDuration)
    },

    setFilterValue (filter, value) {
      this.vxSetFilterValue({ filter, value })
    },

    onFiltersChange (newFilters) {
      clearTimeout(this.filtrationTimeout)

      this.filtrationTimeout = setTimeout(() => {
        const filters = { ...newFilters, filterType: this.selectedFilterType }
        this.$emit('filtersChanged', filters)
      }, this.filtrationDebounce)
    },
  },
}
</script>
