import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { widgetLogoAdapter } from '@/vue_components/client_widget/WidgetLogo/api/widgetLogoAdapter'

export class MClientWidgetLogoPresenter extends MPresenterBase {
  constructor () {
    super({
      location: 'MClientWidgetLogoPresenter',
      entity: 'clientWidgetLogo',
      routes: {
        one () {},
        all () {},
        list () {},
      },
    })
  }

  checkWidgetLogoExistence () {
    const notification = new MRequestNotification(
      this.location,
      'checkMessage',
      this.entity
    )

    return super.post({
      url: Routes.check_logo_existence_api_internal_client_widget_configurations_path(),
    }).promise.catch(() => {
      return MRequestError.withDefault(false, notification)()
    })
  }

  updateWidgetLogo (logoBlob) {
    const notification = new MRequestNotification(
      this.location,
      'updateMessage',
      this.entity
    )

    if (logoBlob.size > Utils.getMaxFileSize('Bit')) {
      notification.message = 'tooLargeFileMessage'

      return MRequestError.withDefault(false, notification)()
    }

    return super.post({
      url: Routes.update_logo_api_internal_client_widget_configurations_path(),
      contentType: false,
      processData: false,
      data: widgetLogoAdapter.toServer(logoBlob),
    }).promise
      .then(MRequestSuccess.withNotify(() => true, notification))
      .catch(MRequestError.withDefault(false, notification))
  }

  deleteWidgetLogo () {
    const notification = new MRequestNotification(
      this.location,
      'destroyMessage',
      this.entity
    )

    return super.delete({
      url: Routes.destroy_logo_api_internal_client_widget_configurations_path(),
      contentType: false,
      processData: false,
    }).promise
      .then(MRequestSuccess.withNotify(() => true, notification))
      .catch(MRequestError.withDefault(false, notification))
  }
}
