import { createEntryTypesBreadcrumbs } from '@/vue_components/EntryTypes/Breadcrumbs'

let entryTypesBreadcrumbsEditVM = null

const entryTypesBreadcrumbsEdit = (el, item) => {
  if (entryTypesBreadcrumbsEditVM) {
    entryTypesBreadcrumbsEditVM.$destroy()
    entryTypesBreadcrumbsEditVM = null
  }

  if (!el) { return }
  entryTypesBreadcrumbsEditVM = createEntryTypesBreadcrumbs(el, item)

  return entryTypesBreadcrumbsEditVM
}

// Form preset костыль, для отрисовки на форме entry type
export const registerEntryTypesBreadcrumbsSub = () => {
  window.entryTypesBreadcrumbsEdit = entryTypesBreadcrumbsEdit
}
