<template>
  <m-si-generator
    class="medical-records-tabs-container__tab analysis-orders-tab"
    :si-generator-schema="schema"
    :items="list.data"
    :page-count="list.totalPages"
    :current-page="list.currentPage"
    :add-button-config="addButtonConfig"
    use-context-menu
    @onItemClick="onItemClick"
    @onAddItem="onAddItemClick"
    @update:currentPage="list.fetchPage($event)"
    @onItemContextMenuClick="lastContextClickedItem = $event"
  >
    <template #contextMenu>
      <m-context-menu-new-tab
        v-if="lastContextClickedItem"
        :url="$routes.analysis_order_path(lastContextClickedItem.id)"
      />
    </template>
  </m-si-generator>
</template>

<script>
import { GLOBAL_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { MListService } from '@/_api/_requests/MListService'
import {
  MClientAnalysisOrderPresenter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AnalysisOrdersTab/api/MClientAnalysisOrderPresenter'
import {
  getListSchema,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AnalysisOrdersTab/const/getListSchema'
import MContextMenuNewTab from '@/vue_present/_base/Tables/MContextMenuNewTab.vue'

export default {
  name: 'AnalysisOrdersTab',
  components: { MContextMenuNewTab, MSiGenerator },
  props: {
    clientId: { type: Number, required: true },
  },

  data () {
    return {
      list: new MListService(
        { clientId: this.clientId },
        new MClientAnalysisOrderPresenter()
      ),

      schema: getListSchema(),
      lastContextClickedItem: null,
    }
  },

  computed: {
    addButtonConfig () {
      return {
        icon: 'analysis',
        text: t('laboratories.create_new_order_for_laboratory'),
        canManage: 'AnalysisOrder',
        hidePlusIcon: true,
      }
    },
  },

  created () {
    setTimeout(() => {
      this.list.fetchAll()
    }, GLOBAL_DEBOUNCE_DEFAULT)
  },

  methods: {
    onItemClick ({ id }) {
      if (!this.$security.canViewAnalysisOrder) { return }
      window.open(this.$routes.analysis_order_path({ id }))
    },

    onAddItemClick () {
      window.open(this.$routes.new_analysis_order_path({ client_id: this.clientId }))
    },
  },
}
</script>
