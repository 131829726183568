<template>
  <div class="client-form-tabs">
    <tabs
      type="card"
      class="client-tabs grey"
      :value="activeTab"
      @input="$updateSync('activeTab', $event)"
    >
      <el-tab-pane
        :name="TAB_ADDRESS"
        :label="t('address')"
      >
        <client-form-address
          :errors="errors"
          :full-address="fullAddress"
          :index="index"
          :country="country"
          :region="region"
          :area="area"
          :city="city"
          :street="street"
          :house="house"
          :flat="flat"
          :targeted-disable="targetedDisable"
          @update:fullAddress="$updateSync('fullAddress', $event)"
          @update:index="$updateSync('index', $event)"
          @update:country="$updateSync('country', $event)"
          @update:region="$updateSync('region', $event)"
          @update:area="$updateSync('area', $event)"
          @update:city="$updateSync('city', $event)"
          @update:street="$updateSync('street', $event)"
          @update:house="$updateSync('house', $event)"
          @update:flat="$updateSync('flat', $event)"
          @update:nsiRussianSubjectId="$updateSync('nsiRussianSubjectId', $event)"
        >
          <template #address-region>
            <reusable-global-n-s-i-list
              v-if="useNSIRussianSubjectsDictionary"
              :value="nsiRussianSubjectId"
              :placeholder="t('region')"
              :disabled="targetedDisable.region"
              full-width
              dictionary="russian_subjects"
              @change="$updateSync('nsiRussianSubjectId', $event)"
              @changeItem="$updateSync('region', $event && $event.title)"
            />
          </template>
        </client-form-address>
      </el-tab-pane>
      <el-tab-pane
        :name="TAB_DOCUMENT"
        :label="t('passport')"
      >
        <div class="form-group">
          <slot name="document-type">
            <reusable-global-n-s-i-list
              v-if="useNSIIdCardTypesDictionary"
              :disabled="targetedDisable.type"
              :value="documentType"
              :placeholder="t('multiPassport')"
              dictionary="id_card_types"
              full-width
              @change="$updateSync('document-type', $event)"
            />
            <base-select
              v-else
              :value="documentType"
              :disabled="targetedDisable.type"
              @change="$updateSync('document-type', $event)"
            >
              <el-option
                :value="null"
                :label="T.id_card"
              />
              <el-option
                v-for="type in documentTypes"
                :key="type.id"
                :value="type.id"
                :label="type.title"
              />
            </base-select>
          </slot>
        </div>
        <div class="form-group">
          <slot name="document-series">
            <validation-wrapper :errors="errors.document.series">
              <input
                :value="documentSeries"
                :placeholder="T.series"
                :disabled="targetedDisable.series"
                type="text"
                class="form-control"
                @input="$updateSync('document-series', $event.target.value)"
              >
            </validation-wrapper>
          </slot>
        </div>
        <div class="form-group">
          <slot name="document-number">
            <validation-wrapper :errors="errors.document.number">
              <input
                :value="documentNumber"
                :placeholder="T.doc_number"
                :disabled="targetedDisable.number"
                type="text"
                class="form-control"
                @input="$updateSync('document-number', $event.target.value)"
              >
            </validation-wrapper>
          </slot>
        </div>
        <div class="form-group">
          <slot name="document-who-issue">
            <validation-wrapper :errors="errors.document.whoIssue">
              <dadata-select
                :disabled="targetedDisable.whoIssue"
                :value="whoIssue"
                :placeholder="t('who_issue')"
                type="fms_unit"
                @update:value="$updateSync('who-issue', $event)"
                @change="$updateSync('who-issue', $event ? $event.value : '')"
              />
            </validation-wrapper>
            <div class="label-desc prompt-notice">
              {{ t('You_can_find_by_department_code') }}
            </div>
          </slot>
        </div>
        <div class="form-group">
          <slot name="document-issue-date">
            <validation-wrapper :errors="errors.document.issueDate">
              <date-picker
                :value="issueDate"
                popper-class="client-passport-date"
                :disabled="targetedDisable.issueDate"
                :date-format="GET_LOCALIZATION_DATEPICKER_FORMAT"
                @input="$updateSync('issue-date', formatDate($event))"
              />
            </validation-wrapper>
          </slot>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :name="TAB_JOB"
        :label="t('job')"
      >
        <div class="form-group">
          <slot name="job-company">
            <div class="input-container flex">
              <validation-wrapper
                class="flex-grow-1"
                :errors="errors.job.company"
              >
                <company-select
                  :disabled="targetedDisable.company"
                  :value="company"
                  @change="$updateSync('company', $event)"
                />
              </validation-wrapper>
              <guarded-control
                tag="button"
                :permissions="['canManageCompany']"
                :tooltip="t('add_company')"
                type="button"
                class="btn btn-primary btn-xs hidden-print input-button fad fa-building"
                :disabled="targetedDisable.company"
                @click:allowed="$emit('add-company')"
              />
            </div>
          </slot>
        </div>

        <div class="form-group">
          <reusable-local-n-s-i-list
            v-if="egiszModuleEnabled"
            :value="nsiProfession"
            :dictionary="NSI.PROFESSIONS"
            :placeholder="t('nsiProfession')"
            :use-first-time-fetch-one-if404="(id) => MNSIPresenter.fetchEntry(id)"
            :m-fixed-height="false"
            :disabled="targetedDisable.nsiProfession"
            full-width
            @changeByLazy="$updateSync('nsiProfession', $event)"
          />
        </div>

        <div class="form-group">
          <slot name="job-position">
            <validation-wrapper
              :errors="errors.job.position"
            >
              <input
                :value="position"
                :placeholder="T.position"
                :disabled="targetedDisable.position"
                type="text"
                class="form-control"
                @input="$updateSync('position', $event.target.value)"
              >
            </validation-wrapper>
          </slot>
        </div>
        <div class="form-group">
          <slot name="job-department">
            <validation-wrapper :errors="errors.job.department">
              <input
                :value="department"
                :placeholder="T.department"
                :disabled="targetedDisable.department"
                type="text"
                class="form-control"
                @input="$updateSync('department', $event.target.value)"
              >
            </validation-wrapper>
          </slot>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :name="TAB_OTHER"
        :label="t('other_1')"
      >
        <div class="flex-form">
          <div class="form-label">
            <label for="oms">
              {{ t('oms') }}
            </label>
          </div>
          <div class="form-value">
            <slot name="oms-number-value">
              <validation-wrapper :errors="errors.other.oms">
                <input
                  id="oms"
                  :value="oms"
                  :disabled="targetedDisable.oms"
                  type="text"
                  class="form-control"
                  @input="$updateSync('oms', $event.target.value)"
                >
              </validation-wrapper>
            </slot>
          </div>
          <div class="form-label">
            <label for="inn">
              {{ t('inn') }}
            </label>
          </div>
          <div class="form-value">
            <slot name="inn-value">
              <validation-wrapper :errors="errors.other.inn">
                <input
                  id="inn"
                  :value="inn"
                  :disabled="targetedDisable.inn"
                  type="text"
                  class="form-control"
                  @input="$updateSync('inn', $event.target.value)"
                >
              </validation-wrapper>
            </slot>
          </div>
          <div class="form-label">
            <label for="snils">
              {{ t('snils') }}
            </label>
          </div>
          <div class="form-value">
            <slot name="snils-value">
              <snils-input
                id="snils"
                :value="snils"
                :validation-messages="errors.other.snils"
                :disabled="targetedDisable.snils || disabled"
                @input="$updateSync('snils', $event)"
              />
            </slot>
          </div>
          <div class="form-label">
            <label>
              {{ t('adv_distribution') }}
            </label>
          </div>
          <!-- TODO: replace with Checkbox -->
          <div class="form-value">
            <slot name="adv-distribution-value">
              <div class="flex flex-column">
                <m-checkbox
                  :value="noSms"
                  :label="t('deny_sms_notifications')"
                  :disabled="targetedDisable.noSms"
                  @input="$updateSync('no-sms', $event)"
                />

                <m-checkbox
                  :value="noWhatsApp"
                  :label="t('deny_whatsapp_messages')"
                  :disabled="targetedDisable.noWhatsApp"
                  @input="$updateSync('no-whats-app', $event)"
                />

                <m-checkbox
                  :value="noSmsDispatches"
                  :label="t('deny_sms_dispatches')"
                  :disabled="targetedDisable.noSmsDispatches"
                  @input="$updateSync('no-sms-dispatches', $event)"
                />

                <m-checkbox
                  :value="noEmail"
                  :label="t('deny_email')"
                  :disabled="targetedDisable.noEmail"
                  @input="$updateSync('no-email', $event)"
                />

                <m-checkbox
                  :value="noCalls"
                  :label="t('deny_calls')"
                  :disabled="targetedDisable.noCalls"
                  @input="$updateSync('no-calls', $event)"
                />
              </div>
            </slot>
          </div>
        </div>
      </el-tab-pane>
    </tabs>
  </div>
</template>

<script>
import Tabs from '@/vue_components/common/tabs.vue'
import BaseSelect from '@/vue_components/common/select/base_select/base_select'
import ClientFormAddress from './client_form_address.vue'
import DatePicker from '@/vue_components/common/select/date_picker'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import props from './client_form_tabs_props'
import propsAddress from './client_form_address_props'
import { TAB_ADDRESS, TAB_DOCUMENT, TAB_JOB, TAB_OTHER } from '@/vue_components/client/const'
import CompanySelect from '@/vue_components/common/select/company_select'
import DadataSelect from '@/vue_components/common/select/dadata_select/dadata_select'
import GuardedControl from '@/vue_components/common/guarded_control'
import { mapGetters } from 'vuex'
import formatter from '@/lib/formatters/formatter'
import SnilsInput from '@/vue_components/common/SnilsInput'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import { NSI_DICTIONARIES } from '@/vue_present/Reuse/Lists/NSI/nsiDictionaries'
import ReusableLocalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableLocalNSIList.vue'
import ReusableGlobalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableGlobalNSIList.vue'
import { MNSIPresenter } from '@/_api/AppConfiguration/MNSIPresenter'

/**
 *
 * Emits `add-company` without arguments.
 * Emits `select-address` with Suggestion.
 * Emits `select-who-issue` with Suggestion.
 *
 * @see {Suggestion}
 */
export default {
  name: 'ClientFormTabs',

  components: {
    ReusableGlobalNSIList,
    ReusableLocalNSIList,
    MCheckbox,
    SnilsInput,
    GuardedControl,
    DadataSelect,
    CompanySelect,
    ClientFormAddress,
    ValidationWrapper,
    DatePicker,
    BaseSelect,
    Tabs,
  },

  props: {
    ...props,
    ...propsAddress,
  },

  data () {
    return {
      MNSIPresenter: new MNSIPresenter(),
      TAB_ADDRESS,
      TAB_DOCUMENT,
      TAB_JOB,
      TAB_OTHER,
      NSI: NSI_DICTIONARIES,
      egiszModuleEnabled: gon.application.egisz_module_enabled,
    }
  },

  computed: {
    ...mapGetters([
      'GET_LOCALIZATION_DATEPICKER_FORMAT',
    ]),

    useNSIIdCardTypesDictionary () {
      return gon.application.nsi.idCardTypesDictionary
    },

    useNSIRussianSubjectsDictionary () {
      return gon.application.nsi.russianSubjectsDictionary
    },
  },

  methods: {
    formatDate (date) {
      return formatter.formatValue(date, ['date:rubyDate'])
    },
  },
}
</script>
