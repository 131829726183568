import { MReportListService } from '@/_api/_requests/MReportListService'
import { getDefaultFilters } from '@/vue_apps/ReportsModule/LoyalReport/const/filters/getDefaultFilters'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import { MLoyalReportPresenter } from '@/vue_apps/ReportsModule/LoyalReport/api/MLoyalReportPresenter'
import { loyalReportAdapter } from '@/vue_apps/ReportsModule/LoyalReport/api/loyalReportAdapter'

export class LoyalReport extends MReportListService {
  constructor (sortingMap = {
    clientCell: 'clientSurname',
    phoneCell: 'phone',
    emailCell: 'email',
    totalAmountCell: 'totalAmount',
    totalFinalSumCell: 'totalFinalSum',
    totalPaidSumCell: 'totalPaidSum',
  }) {
    super(
      getDefaultFilters(),
      new MLoyalReportPresenter(),
      { sortingMap, limit: DEFAULT_LIMIT }
    )
  }

  async export () {
    return new MLoyalReportPresenter()
      .export(loyalReportAdapter.toExport(this.filtersData))
  }
}
