<template>
  <div class="protocol-container-legacy">
    <input
      id="template_data"
      type="hidden"
      :value="templateData"
    >

    <input
      id="template_html"
      type="hidden"
      :value="templateHtml"
    >

    <input
      id="current_protocol_id"
      type="hidden"
      :value="protocolId"
    >

    <div
      class="protocol-entity-template _template_diagnoses"
      data-field-index="0"
    />

    <div
      class="protocol-entity-template _template_complaints"
      data-field-index="0"
    />

    <div
      class="protocol-entity-template _template_conclusions"
      data-field-index="0"
    />

    <div
      class="protocol-entity-template _template_destinations"
      data-field-index="0"
    />

    <button
      id="change_template_protokol"
      class="hidden"
      type="button"
    />

    <button
      id="based_on_previous_modal_btn"
      class="hidden"
      type="button"
    />

    <button
      id="protokol"
      class="hidden"
      type="button"
    />

    <slot />
  </div>
</template>

<script>
import { ProtocolAPI } from '@/vue_apps/Protocols/entities/ProtocolAPI'

/**
 * Этот компонент предназначен для поддержки совместимости со старым кодом
 */
export default {
  name: 'ProtocolContainer',
  props: {
    protocol: ProtocolAPI,
  },

  computed: {
    templateData () {
      return this.protocol?.templateData || ''
    },

    templateHtml () {
      return this.protocol?.templateHtml || ''
    },

    protocolId () {
      return this.protocol?.id || ''
    },
  },
}
</script>
