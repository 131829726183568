import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

const REQUIRED_SELECTORS = '.f-entry-nested-member:not([style="display: none;"]) .amount input'
const ENTRY_FINAL_SUM_SELECTORS = '.entry_member_final_sum .entry_members_final_sum input'

const calculateTotal = (totalPriceElement, finalSum) => {
  const amountSelectors = document.querySelectorAll(REQUIRED_SELECTORS)

  const finalPrice = vueFilters.currency(finalSum)
  const totalItems = Array.from(amountSelectors)
    .reduce((acc, item) => acc + parseInt(item.value), 0)

  totalPriceElement.innerHTML = t('entriesEditorTotalTemplate', { totalItems, finalPrice })
}

const setCurrencyMask = () => {
  const finalSumSelectors = document.querySelectorAll(ENTRY_FINAL_SUM_SELECTORS)

  finalSumSelectors.forEach((item) => {
    $(item).inputmask('currency', {
      prefix: '',
      groupSeparator: ' ',
      rightAlign: false,
      jitMasking: true,
      autoUnmask: true,
    })
  })
}

/**
 * Подсчёт Итого для составляющих комплекса
 * Применение маски для стоимости
 */
export const calculateMembersTotal = () => {
  const totalPriceElement = document.querySelector('#entries_total_price')

  if (!totalPriceElement) { return }

  PubSub.on('page.form.entry.entryLists.listChanged', (target, { final_price: finalPrice }) => {
    calculateTotal(totalPriceElement, finalPrice)
    setCurrencyMask()
  })
}
