<template>
  <el-tabs
    v-model="activeTab"
    stretch
    type="card"
    class="medical-records-tabs-container__egisz-tabs"
  >
    <el-tab-pane
      v-if="SEMD_MODULE_ENABLED"
      label="СЭМД"
      name="semds"
      lazy
    >
      <egisz-semds
        :use-selectable-rows="false"
        :use-search="false"
      >
        <template #filters>
          <egisz-semds-filters :additional-filters="additionalFilters" />
        </template>
      </egisz-semds>
    </el-tab-pane>

    <el-tab-pane
      label="Курсы лечения"
      name="med_cases"
      lazy
    >
      <egisz-medical-cases-tab
        :medical-record-id="medicalRecordId"
        :can-manage="canManageEgisz"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { defineComponent } from 'vue'
import EgiszMedicalCasesTab from '@/vue_components/egisz/egisz_medical_cases_tab/EgiszMedicalCasesTab.vue'
import EgiszSemds from '@/vue_components/egisz/egisz_module/pages/EgiszSemds.vue'
import EgiszSemdsFilters from '@/vue_components/egisz/egisz_module/components/EgiszSemds/EgiszSemdsFilters.vue'

export default defineComponent({
  name: 'EgiszTab',
  components: {
    EgiszSemdsFilters,
    EgiszSemds,
    EgiszMedicalCasesTab,
  },

  props: {
    medicalRecordId: { type: Number, required: true },
    clientId: { type: Number, required: true },
  },

  data () {
    return {
      activeTab: 'semds',
    }
  },

  computed: {
    canManageEgisz () {
      return this.$security.canManageEgisz
    },

    SEMD_MODULE_ENABLED () {
      return gon.application.semd_module_enabled
    },

    additionalFilters () {
      return {
        clientId: this.clientId,
      }
    },
  },
})
</script>
