import { MPresenterBase } from '@/_api/_requests/MPresenterBase'

export class MStorePresenter extends MPresenterBase {
  constructor () {
    super({
      location: 'MStorePresenter',
      entity: 'store',
      routes: { list: Routes.list_stores_path, all () {}, one () {} },
    })
  }
}
