import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class DoctorSalaryByPaymentsReportTotal {
  /** @param {TDoctorSalaryByPaymentsEntryTypeTotal} total */
  constructor (total) {
    this.amount = total.amount
    this.salarySum = total.salarySum
    this.sum = total.sum
  }

  get tableNumber () {
    return new MTableCell(t('reports.total'))
  }

  get tableTitle () {
    return new MTableCell()
  }

  get tableEntryAmount () {
    return new MTableCell(this.amount)
  }

  get tableEntrySum () {
    return new MTableCell(vueFilters.currency(this.sum))
  }

  get tableRate () {
    return new MTableCell()
  }

  get tableSalary () {
    return new MTableCell(vueFilters.currency(this.salarySum))
  }
}
