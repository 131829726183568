import { REQUEST_KEY } from './const'

export const appointmentTypeAdapter = {
  toServer (data) {
    return {
      [REQUEST_KEY]: {
        title: data.title,
        default: data.default,
        color: data.color,
      },
    }
  },
}
