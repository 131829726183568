const PICKED_CLASS = 'picked'
const MIN_CLINICS = 1

export const subscribeToClinicsSelector = () => {
  const wrapper = $('#user_clinics_selector')
  if (!wrapper) { return }

  const clinicsButtons = wrapper.find('.select2buttons-option').toArray()
  if (!clinicsButtons.length) { return }

  const pickedButtons = new Set()
  clinicsButtons.forEach((item, index) => {
    const isPicked = item.className.includes(PICKED_CLASS)
    if (!isPicked) { return }
    pickedButtons.add(index)
  })

  clinicsButtons.forEach((item, index) => {
    item.addEventListener('click', (e) => {
      const isPicked = !e.target.className.includes(PICKED_CLASS)
      if (isPicked) {
        pickedButtons.add(index)

        return
      }

      if (pickedButtons.size <= MIN_CLINICS) {
        e.stopPropagation()

        return
      }

      pickedButtons.delete(index)
    })
  })
}
