import { DiscountReasonsEntryPoint } from '@/vue_components/catalogs/discount_reasons'

$document.on('rez/discount_reasons/edit rez/discount_reasons/new', () => {
  const form = FormFactory.build('discount_reason')

  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      break
    case 'edit':
      form.editItem()
      break
  }
})

$document.on('rez/discount_reasons', DiscountReasonsEntryPoint)
