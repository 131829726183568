<template>
  <div>
    <button
      v-if="!beginningOfTheInterval"
      class="btn btn-sm btn-warning btn-with-icon modal-save"
      @click="$emit('updateAllFromCurrentDate')"
    >
      <span class="btn-with-icon_icon fad fa-trash-alt" />
      <span class="btn-with-icon_text">{{ T.change_from_the_current_day }}</span>
    </button>
    <button
      class="btn btn-sm btn-danger btn-with-icon modal-save"
      @click="$emit('updateAll')"
    >
      <span class="btn-with-icon_icon fad fa-exclamation-circle" />
      <span class="btn-with-icon_text">{{ T.change_all }}</span>
    </button>
    <button
      class="btn btn-sm btn-primary btn-with-icon modal-close"
      @click="$emit('close')"
    >
      <span class="btn-with-icon_icon fad fa-times" />
      <span class="btn-with-icon_text">{{ T.close }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'UpdateWorkTimeModalFooter',
  props: {
    beginningOfTheInterval: Boolean,
  },
}
</script>
