import SmsEditor from '@/modules/variables_editor.js'
import { APPOINTMENT_REMIND } from '@/modules/sms/editor_presets.js'
import { smsCount } from '@/modules/sms/utils.js'

export default function appointmentRemind () {
  if (!gon.application.sms_module_enabled) return

  const $form = $('#sms_configuration_form')
  const $submit = $form.find('#sms_settings_submit').eq(0)
  const $remindEditor = $('.remind_template_editor_container')
  const $remindCb = $('#sms_configuration_remind')
  const $remindFields = $('.remind_field')
  const $remindApproximate = $('.remind_approximate_value')
  const $symbolCount = $form.find('.symbol_count')

  const remindEditor = new SmsEditor({
    container: $remindEditor,
    onPreviewChanged (text) {
      const count = smsCount(text)
      $remindApproximate.text(count)
      $symbolCount.text(text.length)
    },
    validationCallback (value) {
      $submit.prop('disabled', !value)
    },
    ...APPOINTMENT_REMIND,
  })

  remindEditor.visualize()

  $form.remote_form({
    model: 'sms_configuration',
    beforeSend () {
      remindEditor.originalize()
    },
    onSuccess (res) {
      remindEditor.visualize()
      Notificator.info(I18n.t('sms.configuration_updated'))
      $submit.loadSpin('stop')
    },
    onError (err) {
      Notificator.error(T.check_correctness_filing_form)
      console.log(err)
      remindEditor.visualize()
      $submit.loadSpin('stop')
    },
  })

  const toggleRemindFields = () => {
    $remindFields.toggleClass('hidden', !$remindCb.prop('checked'))
  }

  $('#sms_configuration_remind_before').inputmask('Regex', { regex: '^[1-9]$|^1[0-9]$|^2[0-3]$' })

  toggleRemindFields()
  $remindCb.on('change', toggleRemindFields)
  $submit.on('click', (e) => {
    if (!remindEditor.validate()) e.preventDefault()
  })
}
