export const ADMINISTRATIVE_DOCUMENT_ID = 1

export const MEDICAL_DOCUMENT_ID = 2

export const TAX_CERTIFICATE2024_ID = 3

export const DOCUMENT_KIND_ITEMS = [
  {
    id: ADMINISTRATIVE_DOCUMENT_ID,
    title: t('enums.document_type.kind.administrative'),
  },
  {
    id: MEDICAL_DOCUMENT_ID,
    title: t('enums.document_type.kind.medical'),
  },
  {
    id: TAX_CERTIFICATE2024_ID,
    title: t('taxCertificate2024'),
  },
]

export const getDefaultDocumentFilters = () => {
  return {
    period: [],
    type: null,
    kind: null,
    withLegalRepresentatives: true,
    clientId: gon.specific.client_id,
  }
}
