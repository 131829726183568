import { ItemBase } from '@/_api/_classes/ItemBase'

export class LoyalReportItemBase extends ItemBase {
  _permit = [
    'email',
    'formattedPhone',
    'fullName',
    'id',
    'maskedPhone',
    'medicalRecordId',
    'name',
    'phone',
    'secondName',
    'surname',
    'totalAmount',
    'totalFinalSum',
    'totalPaidSum',
  ]

  /** @type {string|null} */
  email = null

  /** @type {string|null} */
  formattedPhone = null

  /** @type {string|null} */
  fullName = null

  /** @type {number|null} */
  id = null

  /** @type {string|null} */
  maskedPhone = null

  /** @type {number|null} */
  medicalRecordId = null

  /** @type {string|null} */
  name = null

  /** @type {string|null} */
  phone = null

  /** @type {string|null} */
  secondName = null

  /** @type {string|null} */
  surname = null

  /** @type {number|null} */
  totalAmount = null

  /** @type {string|null} */
  totalFinalSum = null

  /** @type {string|null} */
  totalPaidSum = null

  /**  @param {ILoyalReportItemRaw} props */
  constructor (props) {
    super()
    this._fillProps(props)
  }
}
