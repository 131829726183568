import {
  REMOVE_ALL_FROM_CURRENT_DATE,
  REMOVE_ALL,
} from '../../../const.js'

export default {
  _sendData () {
    const god = this

    if (this.requiringConfirmationRequestData.length > 0) {
      const data = this.requiringConfirmationRequestData.shift()
      const time = data.workTime.work_time.split('-')

      this.activeRequiringConfirmationRequestData = data.requestData

      this.$emit('showUpdateWorkTimeModal', {
        visibility: true,
        startTime: time[0],
        endTime: time[1],
        startDate: data.template.interval_start_date,
        date: data.workTime.date,
      })

      return
    }

    if (god.isomorphicTemplates.length === 0 ||
      (this.requestData.batchCreate.length === 0 && this.requestData.batchUpdate.length === 0)) {
      god.$emit('close')

      return
    }

    god.$emit('activeRequestState', true)

    $.ajax({
      url: Routes.employees_schedule_apply_changes_path(),
      method: 'POST',
      data: {
        batch_create: JSON.stringify(god.requestData.batchCreate),
        batch_update: JSON.stringify(god.requestData.batchUpdate),
        clinic_id: gon.application.current_clinic.id,
      },
      error () {
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
      },
      complete () {
        god.requestData = null
        Services.ui.spinner().show(false)
        god.$emit('close')
      },
    })
  },

  _removeWorkTime (id) {
    const god = this

    god.$emit('activeRequestState', true)

    $.ajax({
      url: Routes.work_time_path(id),
      method: 'DELETE',
      success () {
        god._removeIsomorphicTemplateById(id)
      },
      error () {
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
      },
      complete () {
        Services.ui.spinner().show(false)
        god.$emit('showRemoveWorkTimeModal', {visibility: false})
        god.activeScheduleTemplateData = null
      },
    })
  },

  _rollbackTemplateWorkTimes (removalType) {
    const god = this

    const workTimeId = god.activeScheduleTemplateData.workTime.id
    const templateId = god.activeScheduleTemplateData.template.id

    let data = {template_id: templateId, clinic_id: gon.application.current_clinic.id}

    switch (removalType) {
      case REMOVE_ALL_FROM_CURRENT_DATE:
        data = {...data, start_date_of_deletion: god.date}
        break
      case REMOVE_ALL:
        data = {...data, rollback_all: true}
        break
      default: throw new Error('Invalid removal type')
    }

    god.$emit('activeRequestState', true)

    $.ajax({
      url: Routes.employees_schedule_rollback_work_times_path(),
      method: 'POST',
      data,
      success () {
        god._removeIsomorphicTemplateById(workTimeId)
      },
      error () {
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
      },
      complete () {
        Services.ui.spinner().show(false)
        god.$emit('showRemoveWorkTimeModal', {visibility: false})
        god.activeScheduleTemplateData = null
      },
    })
  },
}
