const ZERO_SELECTED_CABINETS = 0

class CabinetsWorkloadCalculator {
  constructor (cabinetsWorkloadStorage) {
    this.cabinetsWorkloadStorage = cabinetsWorkloadStorage
    this.numberOfSelectedCabinets = ZERO_SELECTED_CABINETS
    this._selectedCabinetsIds = []
  }

  workload (verifiableDay) {
    if (this.numberOfSelectedCabinets === ZERO_SELECTED_CABINETS) return { averagePercentage: 0 }

    let amountOfWorkloadPercentOfSelectedCabinets = 0
    this._selectedCabinetsIds.forEach((cabinetId) => {
      const dailyCabinetWorkload = this.cabinetsWorkloadStorage.dailyCabinetWorkload(cabinetId, verifiableDay)
      amountOfWorkloadPercentOfSelectedCabinets += dailyCabinetWorkload
    })

    const averagePercentage = Math.round(amountOfWorkloadPercentOfSelectedCabinets / this.numberOfSelectedCabinets)

    return { averagePercentage }
  }

  set selectedCabinetsIds (selectedCabinetsIds) {
    this._selectedCabinetsIds = selectedCabinetsIds
    this.numberOfSelectedCabinets = selectedCabinetsIds.length
  }
}

export default CabinetsWorkloadCalculator
