$document.on('rez/telephony/configurations/edit', () => {
  const $form = $('#telephony_configuration_form')
  const subBtn = $form.find("[type='submit']").eq(0)

  $form.remote_form({
    model: 'configuration',
    onSuccess (res) {
      Notificator.info(t('configuration_updated'))
      subBtn.loadSpin('stop')
    },
    onError (err) {
      Notificator.error(T.check_correctness_filing_form)
      console.log(err)
      subBtn.loadSpin('stop')
    },
  })
})
