import { DateHelpers } from '@/helpers/DateHelpers'
import { isEmpty, omit } from 'lodash'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

const getFiltersTextMap = () => ({
  /**
   * @param {{ id:number, title:string }} [clinic]
   * @return {string}
   */
  clinicFilter (clinic) {
    return clinic ? clinic.title : t('all_clinics')
  },

  /**
   * @param {{ id:number, title:string }} [clinic]
   * @return {string}
   */
  clinic (clinic) {
    return clinic ? clinic.title : t('all_clinics')
  },

  entryWithOrderFilter (value) {
    return value ? t('reports.exclude_entry_without_order') : t('reports.include_entry_without_order')
  },

  includingDiscountsFilter (value) {
    return value ? t('reports.include_discount') : t('reports.exclude_discount')
  },

  /**
   * @param {[string, string]} value
   * @return {string}
   */
  periodFilter (value) {
    const basePeriod = DateHelpers.toBasePeriod(value)

    return isEmpty(basePeriod)
      ? ''
      : `${Utils.getFormattedDate(basePeriod[0], Utils.longDateFormat)} - ${Utils.getFormattedDate(basePeriod[1], Utils.longDateFormat)}`
  },

  /**
   * @param {[string, string]} value
   * @return {string}
   */
  period (value) {
    const basePeriod = DateHelpers.toBasePeriod(value)

    return isEmpty(basePeriod)
      ? ''
      : `${Utils.getFormattedDate(basePeriod[0], Utils.longDateFormat)} - ${Utils.getFormattedDate(basePeriod[1], Utils.longDateFormat)}`
  },

  /**
   * @param {{ id:number, fullName?:string, shortName?:string }} [user]
   * @return {string}
   */
  userFilter (user) {
    return user ? user.fullName || user.shortName : ''
  },

  clientsCount (value) {
    return `${t('reports.loyal.filters.clientsCount')}: ${value}`
  },

  finalSum (value) {
    return `${t('sum')}: ${vueFilters.price(value)}`
  },

  /**
   * @param {{ id:number, title:string }[] | { id:number, title:string }} [value]
   * @return {string}
   */
  entryTypes (value) {
    if (!value) { return '' }
    if (Array.isArray(value)) { return value.map(({ title }) => title).join(', ') }

    return value.title
  },

  /**
   * @param {{ id:number, title:string }} [category]
   * @return {string}
   */
  category (category) {
    if (!category) { return '' }

    return category.title
  },
})

export const convertFiltersToString = (filtersData = {}) => {
  const filtersTextMap = getFiltersTextMap()

  /* БЛ-изврат, дата всегда первая */
  const startAcc = `${filtersTextMap.periodFilter(filtersData.periodFilter || filtersData.period)}.`

  const _filtersData = omit(filtersData, ['periodFilter', 'period'])

  return Object.entries(_filtersData)
    .reduce((acc, [filter, value]) => {
      if (!filtersTextMap[filter]) { return acc }

      const text = filtersTextMap[filter](value)
      if (!text) { return acc }

      return `${acc} ${text}.`
    }, startAcc)
}
