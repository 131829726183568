import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class SalaryByPaymentsTotal {
  /** @param {TSalaryByPaymentsTotal} totalData */
  constructor (totalData) {
    this.analysisSalary = totalData.analysisSalary
    this.goodsSalary = totalData.goodsSalary
    this.servicesSalary = totalData.servicesSalary
    this.totalSalary = totalData.totalSalary
  }

  get tableDoctor () {
    return new MTableCell(t('reports.total'))
  }

  get tableGoods () {
    return new MTableCell(vueFilters.currency(this.goodsSalary))
  }

  get tableAnalysis () {
    return new MTableCell(vueFilters.currency(this.analysisSalary))
  }

  get tableServices () {
    return new MTableCell(vueFilters.currency(this.servicesSalary))
  }

  get tableTotal () {
    return new MTableCell(vueFilters.currency(this.totalSalary))
  }
}
