import applicationFactory from '../plugins/dynamic_forms/application_factory'
import { Intent } from '@/services/intents'
import { createEntryTypesTreeApp } from '@/vue_present/Reuse/EntryTypes/EntryTypesTree'
import { subscribeToLaboratoryField } from '@/specific/entry_types_helpers/field_enable_check'

const toggleDentalOrderPrice = function () {
  if (gon.application.settings.dental_order_price) {
    $('.dental-order-price').hide()
  } else {
    $('.dental-order-price').show()
  }
}

$document.on('rez/dental_orders/index', function () {
  $.contextMenu('destroy')

  Utils.initCalendar()

  // Сортировка заказов
  SortItems.init({
    itemName: ['dental_orders'],
    url: '/dental_orders',
    defaults: {
      period: 'today',
      date_toggle: 'date',
    },
  })

  $.contextMenu(ContextMenu.clickableRow({
    items: ['openInNewWindow', 'openClientCard', 'sep1', 'fold1'],
    selector: '.clickable-row',
  }))
})

$document.on('rez/dental_orders/show', function () {
  const $delete = $('#delete-record')
  const documents = gon.specific.documents
  const template = Handlebars.compile($('#fittings_table').html())
  const $appointmentTableBody = $('#appointments_table_body')
  const addFittingBtn = document.getElementById('add_fitting')
  toggleDentalOrderPrice()

  function selectAppointment (params) {
    const id = params.currentTarget.id
    params.currentTarget.remove()
    Utils.ajaxUpdate({
      url: Routes.appointment_path(id),
      data: {
        appointment: {
          dental_order_id: params.dentalOrderId,
        },
      },
    })
  }

  function removeFromFitting (elem) {
    elem.remove()
    elem = $(elem).children().remove('.options')
    $('#select_appointment_table_body').append(elem.context)
    $(elem.context).on('click', function (e) {
      selectAppointment({
        currentTarget: e.currentTarget,
        dentalOrderId: gon.specific.dental_order_id,
      })
    })
  }

  function assignFittingEvents (fitting) {
    let deleteBtn
    let removeBtn

    if (fitting) {
      deleteBtn = fitting.getElementsByClassName('fa-trash-alt')
      removeBtn = fitting.getElementsByClassName('remove_fitting')
    } else {
      deleteBtn = $('#appointments_table .fa-trash-alt')
      removeBtn = $('.remove_fitting')
    }

    $(deleteBtn).deleteButton({
      name: t('delete_appointment'),
      onSuccess (data, elem) {
        elem.closest('tr').remove()
      },
    })

    $(removeBtn).on('click', function (e) {
      const msg = t('remove_fitting_from_dental_order?')
      bootbox.confirmYN(msg, function (res) {
        if (res) {
          const elem = e.currentTarget.parentElement.parentElement
          Utils.ajaxUpdate({
            url: Routes.appointment_path(elem.id),
            data: {
              appointment: {
                dental_order_id: null,
              },
            },
            success () {
              removeFromFitting(elem)
            },
          })
          Utils.updateTooltips(elem)
        }
      })
    })
  }

  function updateFittings (appointment) {
    const addedApppointment = document.getElementById(appointment.id) || null
    let fitting = template(appointment)

    if (appointment.deleted_by_id) {
      $(addedApppointment).remove()

      return
    }

    if (addedApppointment) {
      $(addedApppointment).replaceWith(fitting)
    } else {
      $('#appointments_table_body').append(fitting)
    }
    fitting = document.getElementById(appointment.id)
    assignFittingEvents(fitting)
    Utils.updateTooltips(document.querySelector('#appointments_table_body'))
  }

  function loadFittings (appointment) {
    $appointmentTableBody.append(template(appointment))
  }

  $delete.deleteButton({
    url: Routes.dental_order_path(gon.specific.dental_order.id),
    redirect: Routes.dental_orders_path(),
    name: t('dental_order_1'),
  })

  gon.specific.appointments.forEach(function (appointment) {
    loadFittings(appointment)
  })

  assignFittingEvents()

  $('#documents-button').on('click', function () {
    DocumentForm.show()
  })

  Services.wsSubscriptions.appointment.connect((appointment) => {
    if ((appointment.type === 'appointment') &&
      (appointment.data.dental_order_id === gon.specific.dental_order_id)) {
      updateFittings(appointment.data)
    }
  })

  DocumentForm.init({
    documents,
    client_id: gon.specific.client_id,
    clientLegalRepresentatives: gon.specific.client ? gon.specific.client.legal_representatives : [],
    clientLegalRepresentativesModalSelector: '#client_legal_representatives_modal',
    documentDatesModalSelector: '#document_dates_modal',
    order_id: gon.specific.order_id,
    dental_order_id: gon.specific.dental_order_id,
    dental_order: gon.specific.dental_order,
    source: 'createDentalOrderDoc',
  })

  $('#create_agreement_btn').on('click', function () {
    DocumentForm.show()
  })

  $('#select_fitting_btn').on('click', function () {
    $('#modal_select_fitting_show').modal('show')
    $('.clickable').on('click', function (e) {
      selectAppointment({
        currentTarget: e.currentTarget,
        dentalOrderId: gon.specific.dental_order_id,
      })
    })
  })

  addFittingBtn.addEventListener('click', function (e) {
    e.preventDefault()
    const params = {
      clientId: +this.dataset.insertClient || null,
      dentalOrderId: +this.dataset.dentalOrderId || null,
    }
    const newWindow = window.open(Routes.doctor_schedules_path(), '_blank')
    Intent.setIntent(
      newWindow,
      Intent.SCHEDULE_APPOINTMENT_CREATE,
      params
    )
  })
})

$document.on('rez/dental_orders/new', function () {
  toggleDentalOrderPrice()
  const dentalOrder = FormFactory.build('dental_order')
  dentalOrder.newItem()
})

$document.on('rez/dental_orders/edit', function () {
  toggleDentalOrderPrice()
  const dentalOrder = FormFactory.build('dental_order')
  dentalOrder.editItem()
})

$document.on('rez/dental_orders/new rez/dental_orders/edit', function () {
  DocumentForm.init({
    documents: gon.specific.documents,
    client_id: gon.specific.client_id,
    clientLegalRepresentatives: gon.specific.client ? gon.specific.client.legal_representatives : [],
    clientLegalRepresentativesModalSelector: '#client_legal_representatives_modal',
    documentDatesModalSelector: '#document_dates_modal',
    order_id: gon.specific.order_id,
    source: 'editDentalOrder',
  })
  applicationFactory.generate('backend')

  $('#create_agreement_btn').on('click', function () {
    DocumentForm.show()
  })

  adjustHeight()
  adjustModalHeight()

  $(window).on('resize', function () {
    adjustHeight()
    adjustModalHeight()
  })

  function adjustHeight () {
    $('#catalog_list ul.fancytree-container')
      .height($('.app-content')
        .height() - 120)
  }

  function adjustModalHeight () {
    $('#paste_from_order_list ul.fancytree-container')
      .height($('.app-content')
        .height() - 400)
  }

  createEntryTypesTreeApp(undefined, 'page.form.dental_order.dentalOrderEntryList.askAddEntry')
  setTimeout(subscribeToLaboratoryField, 0)
})
