import { omit, omitBy, isUndefined } from 'lodash'
import {
  IAdministratorsProductivityReportFiltersGet as FiltersGet,
  IAdministratorsProductivityReportFiltersSet as FiltersSet,
} from '@/specific/reports/administratorsProductivity/interfaces'
import {
  IAdministratorsProductivityReportFiltersProfileFiltersToClient as IFiltersToClient,
  IAdministratorsProductivityReportFiltersProfileFiltersToServer as IFiltersToServer,
} from '../../interfaces/filtersTypes/administratorsProductivityReport'
import { IFiltersProfilesFetchResponse } from '../../interfaces/IFIltersProfilesFetchResponse'
import { cleanUnpermittedValues } from '../../helpers/formatters/shared'
import { IFiltersProfilesModel } from '../../interfaces/IFiltersProfilesModel'
import { FiltersProfilesLegacyFormattersToServer as FormattersToServer } from '../../helpers/formatters/legacy/toServer'
import { FiltersProfilesLegacyFormattersToClient as FormattersToClient } from '../../helpers/formatters/legacy/toClient'
import { gatherErrors } from '../../helpers/gatherErrors'

export const administratorsProductivityReportFiltersProfileAdapter = {
  toServer (data: IFiltersProfilesModel<FiltersGet>): IFiltersProfilesModel<IFiltersToServer> {
    const { data: filters } = data

    const adaptedFilters: IFiltersToServer = {
      ...omit(filters, ['appointmentTypes']),
      period: FormattersToServer.formatPeriod(filters.period),
      clinic: FormattersToServer.formatSingle(filters.clinic),
      appointmentType: FormattersToServer.formatMulti(filters.appointmentTypes),
      countType: FormattersToServer.formatSingle(filters.countType, false),
    }

    return { ...data, data: adaptedFilters }
  },

  toClient (data: IFiltersProfilesFetchResponse<IFiltersToClient>): IFiltersProfilesModel<FiltersSet> {
    const { data: filters } = data

    const validFilters = omitBy(cleanUnpermittedValues(filters), isUndefined) as IFiltersToClient

    const adaptedFilters: FiltersSet = {
      period: FormattersToClient.formatPeriod(validFilters.period),
      clinic: FormattersToClient.formatSingle(validFilters.clinic),
      appointmentTypes: FormattersToClient.formatMulti(validFilters.appointmentType),
      countType: FormattersToClient.formatSingle(validFilters.countType),
    }

    const definedAdaptedFilters = omitBy(adaptedFilters, isUndefined) as FiltersSet

    const renamedFilters = omitBy({
      ...omit(filters, 'appointmentType'),
      appointmentTypes: filters.appointmentType,
    }, isUndefined)

    return {
      ...data,
      data: definedAdaptedFilters,
      dataErrors: gatherErrors(renamedFilters),
    }
  },
}
