<template>
  <form-block :title="T.service_card">
    <medium-editor
      class="form-control text optional tooltip-bottom"
      :content="content"
      :disabled="disabled"
      @update:content="$emit('input', $event); $updateSync('content', $event)"
    />
  </form-block>
</template>

<script>
import FormBlock from '@/vue_components/common/form_block'
import MediumEditor from '@/vue_components/common/medium_editor'

export default {
  name: 'ClientServiceCard',
  components: { MediumEditor, FormBlock },
  props: {
    content: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
}
</script>
