<template>
  <simple-select
    v-el-select-lazy="loadMore"
    :value="value"
    :items="items"
    :filter-method="filterMethod"
    :disabled="disabled"
    :fixed-height="fixedHeight"
    :full-width="fullWidth"
    :placeholder="placeholder"
    :loading="itemsLoading"
    :validation-messages="validationMessages"
    :multiple="multiple"
    :label-attribute="labelAttribute"
    :value-key="valueKey"
    filterable
    clearable
    @change="$emit('change', $event)"
    @visibleChange="onVisibleChange"
  >
    <template #prefix>
      <slot name="prefix">
        <user-avatar
          :user-id="extractUserId(value)"
          :params="{ version: 'thumb16', tag: value && value.avatar_tag }"
          :style="{ marginTop: '7px', marginLeft: '1px' }"
        />
      </slot>
    </template>

    <template
      v-for="(item, index) in items"
      #option="{ item }"
    >
      <el-option
        :key="`user:${item.id}:${index}`"
        :value="item"
        :label="item.full_name"
      >
        <user-avatar
          class="mr-5"
          :user-id="extractUserId(item)"
          :params="{ version: 'thumb16', tag: item.avatar_tag }"
        />

        {{ item.full_name }}
      </el-option>
    </template>
  </simple-select>
</template>

<script>
import { fetchDoctorsList } from '@/vue_components/doctor_schedules/rest'
import { PAGE_SIZE_SELECTOR } from '@/vue_components/common/select/consts'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import UserAvatar from '@/vue_components/user_avatar'
import { cloneDeep } from 'lodash'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'

export default {
  name: 'UserSimpleSelect',
  components: { UserAvatar, SimpleSelect },
  props: {
    getUsers: {
      type: Function,
      default: fetchDoctorsList,
    },

    clinicId: {
      type: Number,
      default: null,
    },

    disabled: Boolean,

    /**
     * @type {import('vue').PropOptions<{id: number, name?: string, surname: string, second_name?: string}>}
     */
    value: {
      type: [Object, Array],
      default: () => null,
    },

    pageSize: {
      type: Number,
      default: PAGE_SIZE_SELECTOR,
    },

    fixedHeight: Boolean,
    fullWidth: Boolean,
    multiple: Boolean,

    validationMessages: {
      type: Array,
      default: () => [],
    },

    labelAttribute: {
      type: String,
      default: 'full_name',
    },

    valueKey: {
      type: String,
      default: 'id',
    },

    placeholder: {
      type: String,
      default: t('search'),
    },

    /**
     * Атрибут для текстового поиска
     */
    searchAttribute: {
      type: String,
      default: 'search_query',
    },
  },

  data () {
    return {
      pagination: {
        offset: 0,
        totalPages: 1,
        totalItems: 0,
      },

      filterableQuery: '',

      items: [],
      itemsLoading: false,
      itemsCache: [],
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (to) {
        if (!to) { return }

        const users = Utils.ternary(Array.isArray(to), to, [to])
        const newUsers = this.getNonExistingOptions(users)
        this.addNewUsers(newUsers, true)
      },
    },
  },

  mounted () {
    this.fetchUsers()
  },

  methods: {
    extractUserId (user) {
      if (!user) { return null }

      return Utils.ternary(user.user_id !== undefined, user.user_id, user.id)
    },

    getNonExistingOptions (users = []) {
      return users.filter((user) =>
        this.itemsCache.findIndex((option) => option.id === user.id) < 0,
      )
    },

    fetchUsers (query = '') {
      this.itemsLoading = true
      const params = {
        [this.searchAttribute]: query,
        limit: this.pageSize,
        offset: Utils.ternary(query, 0, this.pagination.offset),
        clinic_id: this.clinicId,
      }

      return this.getUsers(params)
        .then((response) => {
          if (!query) {
            this.pagination.totalPages = response.total_pages
            this.pagination.totalItems = response.total_items
          }

          const newUsers = this.getNonExistingOptions(response.data)
          this.addNewUsers(newUsers, Boolean(query))
        })
        .finally(() => { this.itemsLoading = false })
    },

    addNewUsers (newUsers, unshift = false) {
      if (unshift) {
        this.items.unshift(...newUsers)
        this.itemsCache.unshift(...newUsers)

        return
      }

      this.items.push(...newUsers)
      this.itemsCache.push(...newUsers)
    },

    loadMore () {
      const currentOffset = this.pagination.offset
      const nextOffset = this.pagination.offset + this.pageSize

      if (this.filterableQuery || nextOffset > this.pagination.totalItems) { return }
      this.pagination.offset = nextOffset
      this.fetchUsers()
        .catch((err) => {
          Utils.reportError(
            reportErrorText('list_message', 'doctors'),
            'UserSimpleSelect:loadMore()',
          )(err)
          this.pagination.offset = currentOffset
        })
    },

    filterMethod (query = '') {
      query = Utils.ternary(query.length >= 3, query, '')
      this.filterableQuery = query

      if (!query) {
        this.items = cloneDeep(this.itemsCache)

        return
      }

      this.fetchUsers(query)
        .then(() => {
          this.items = this.itemsCache
            .filter((item) => item.full_name.toLowerCase().includes(query.toLowerCase()))
        })
    },

    onVisibleChange (value) {
      if (value) { return }
      this.$nextTick(() => { this.filterMethod('') })
    },
  },
}
</script>
