import { DEFAULT_REPORT_LIMIT } from '@/vue_components/store/modules/filters_base'
import { DateHelpers } from '@/helpers/DateHelpers'
import { SalaryReportItem } from '@/vue_apps/ReportsModule/SalaryReports/Salary/SalaryReportItem'
import { SalaryReportTotal } from '@/vue_apps/ReportsModule/SalaryReports/Salary/SalaryReportTotal'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { DoctorSalaryReportItem } from '@/vue_apps/ReportsModule/SalaryReports/DoctorSalary/DoctorSalaryReportItem.js'
import { DoctorSalaryReportTotal } from '@/vue_apps/ReportsModule/SalaryReports/DoctorSalary/DoctorSalaryReportTotal'
import { SalaryByPaymentsItem } from '@/vue_apps/ReportsModule/SalaryReports/SalaryByPayments/SalaryByPaymentsItem'
import { SalaryByPaymentsTotal } from '@/vue_apps/ReportsModule/SalaryReports/SalaryByPayments/SalaryByPaymentsTotal'
import formatter from '@/lib/formatters/formatter'
import {
  DoctorSalaryByPaymentsReportTotal,
} from '@/vue_apps/ReportsModule/SalaryReports/DoctorSalaryByPayments/DoctorSalaryByPaymentsReportTotal'
import {
  DoctorSalaryByPaymentsReportItem,
} from '@/vue_apps/ReportsModule/SalaryReports/DoctorSalaryByPayments/DoctorSalaryByPaymentsReportItem'
import {
  DoctorSalaryByPaymentsDetailedReportItem,
} from '@/vue_apps/ReportsModule/SalaryReports/DoctorSalaryByPaymentsDetailed/DoctorSalaryByPaymentsDetailedReportItem'
import {
  DoctorSalaryByPaymentsDetailedReportTotal,
} from '@/vue_apps/ReportsModule/SalaryReports/DoctorSalaryByPaymentsDetailed/DoctorSalaryByPaymentsDetailedReportTotal'
import {
  SalaryByPaymentsDetailedReportTotal,
} from '@/vue_apps/ReportsModule/SalaryReports/SalaryByPaymentsDetailed/SalaryByPaymentsDetailedReportTotal'
import {
  SalaryByPaymentsDetailedReportItem,
} from '@/vue_apps/ReportsModule/SalaryReports/SalaryByPaymentsDetailed/SalaryByPaymentsDetailedReportItem'
import {
  SalaryByPaymentsDetailedReportDoctorItem,
} from '@/vue_apps/ReportsModule/SalaryReports/SalaryByPaymentsDetailed/SalaryByPaymentsDetailedReportDoctorItem'
import {
  SalaryByPaymentsDetailedReportDoctorTotalItem,
} from '@/vue_apps/ReportsModule/SalaryReports/SalaryByPaymentsDetailed/SalaryByPaymentsDetailedReportDoctorTotalItem'
import { snakeToCamel } from '@/_api/_requests/helpers'
import {
  DoctorSummarySalaryReportItem,
} from '@/vue_apps/ReportsModule/SalaryReports/DoctorSummarySalary/DoctorSummarySalaryReportItem'
import {
  DoctorSummarySalaryReportTotal,
} from '@/vue_apps/ReportsModule/SalaryReports/DoctorSummarySalary/DoctorSummarySalaryReportTotal'
import { paymentKind } from '@/_api/Reports/Salary/paymentKindsTitles'

export const reportSalaryAdapter = {
  toServer (data) {
    const period = DateHelpers.toBasePeriod(data.periodFilter, Utils.longDateFormat).join(' - ')
    const salaryEntryPeriod = DateHelpers.toBasePeriod(data.periodFilter, Utils.rubyDateFormat)
    const userFilter = data.userFilter || data.userOnlyCurrentFilter
    const doctorId = (userFilter?.id)
      ? userFilter.id.toString()
      : ''
    const clinicIds = data.clinicFilter?.id
      ? [data.clinicFilter.id]
      : []

    return {
      clinicIds,
      doctorId,
      entryWithOrder: data.entryWithOrderFilter || false,
      includingDiscounts: data.includingDiscountsFilter || false,
      limit: data.limit,
      offset: data.offset,
      salaryEntryDate: {
        start: salaryEntryPeriod[0],
        end: salaryEntryPeriod[1],
      },
      report: { period },
      reportType: data.reportType,
      sorting: data.sorting,
    }
  },

  toExport (data) {
    return {
      ...reportSalaryAdapter.toServer(data),

      headerRow: data.headerRow || undefined,
      totalRow: data.totalRow || undefined,
      paymentKind,
    }
  },

  toClientSalary (response) {
    return {
      data: (response.data || [])
        .map(({ doctor }) => {
          const item = new SalaryReportItem({
            ...doctor,
            fullName: vueFilters.fullName(doctor),
          })

          return item
        }),
      total: new SalaryReportTotal(response.total),
      totalItems: response.count,
      totalPages: Math.ceil(response.count / DEFAULT_REPORT_LIMIT),
    }
  },

  toClientDoctorSalary (response) {
    return {
      data: (response.data || [])
        .map(({ salaryEntry }) => {
          const item = new DoctorSalaryReportItem(salaryEntry)

          return item
        }),
      total: new DoctorSalaryReportTotal(response.total),
      totalItems: response.count,
      totalPages: Math.ceil(response.count / DEFAULT_REPORT_LIMIT),
    }
  },

  toClientDoctorSummarySalary (response) {
    return {
      data: (response.data || [])
        .map(({ entryType }, index) => new DoctorSummarySalaryReportItem(index, entryType)),
      total: new DoctorSummarySalaryReportTotal(response.total),
      totalItems: response.count,
      totalPages: Math.ceil(response.count / DEFAULT_REPORT_LIMIT),
    }
  },

  toClientSalaryByPayments (response) {
    return {
      data: (response.data || [])
        .map(({ doctor }) => {
          doctor.fullName = formatter.formatValue(doctor, ['user:fullName'])

          return new SalaryByPaymentsItem(doctor)
        }),
      total: new SalaryByPaymentsTotal(response.total),
      totalItems: response.count,
      totalPages: Math.ceil(response.count / DEFAULT_REPORT_LIMIT),
    }
  },

  toClientDoctorSalaryByPayments (response) {
    return {
      data: (snakeToCamel(response.data) || [])
        .map(({ entryType }) => {
          return new DoctorSalaryByPaymentsReportItem(entryType)
        }),
      total: new DoctorSalaryByPaymentsReportTotal(response.total),
      totalItems: response.count,
      totalPages: Math.ceil(response.count / DEFAULT_REPORT_LIMIT),
    }
  },

  toClientDoctorSalaryByPaymentsDetailed (response) {
    return {
      data: (snakeToCamel(response.data) || [])
        .map(({ entry }) => {
          return new DoctorSalaryByPaymentsDetailedReportItem(entry)
        }),
      total: new DoctorSalaryByPaymentsDetailedReportTotal(response.total),
      totalItems: response.count,
      totalPages: Math.ceil(response.count / DEFAULT_REPORT_LIMIT),
    }
  },

  toClientSalaryByPaymentsDetailed (response) {
    const flatEntriesList = (snakeToCamel(response.data) || []).flatMap(({ doctor, entries, paidSum, totalSalary }) => {
      const doctorName = {
        doctorId: doctor.id,
        title: formatter.formatValue(doctor, ['user:fullName']),
      }

      const doctorTotal = {
        id: doctor.id,
        paidSum,
        totalSalary,
      }

      return [
        new SalaryByPaymentsDetailedReportDoctorItem(doctorName),
        ...entries.map((entry, index) => new SalaryByPaymentsDetailedReportItem({ ...entry, doctorId: doctor.id })),
        new SalaryByPaymentsDetailedReportDoctorTotalItem(doctorTotal),
      ]
    })

    return {
      data: flatEntriesList,
      total: new SalaryByPaymentsDetailedReportTotal(response.total),
      totalItems: response.count,
      totalPages: Math.ceil(response.count / DEFAULT_REPORT_LIMIT),
    }
  },
}
