export const rewriteEditorBody = () => {
  fixDatesFormat()
  fixBooleanFormat()
}

/**
 * YYYY-MM-DD to DD.MM.YYYY
 */
const fixDatesFormat = () => {
  const dates = document.querySelectorAll(
    '[data-value="medical_record&.disability_date"]'
  )

  dates.forEach((el) => {
    el.innerText = Utils.dateRubyToHuman(el.innerText)
  })
}

/**
 * true - да, false - нет
 */
const fixBooleanFormat = () => {
  const entriesVariables = document.querySelectorAll('.entries_variable')
  for (let item of entriesVariables) {
    if (item.innerText === 'true') {
      item.innerText = t('medical_records_protokol_true')
    } else if (item.innerText === 'false') {
      item.innerText = t('medical_records_protokol_false')
    }
  }
}
