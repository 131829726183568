import {
  SELECT_ALL_SPECIALTIES_ID,
  SELECT_ALL_CABINETS_ID,
  SELECT_ALL_USERS_ID,
} from '../const.js'

export default {
  _specialtyOnClick (event) {
    this._onClickCommonEventHandler(
      event,
      this.selectedSpecialtiesIds,
      SELECT_ALL_SPECIALTIES_ID)

    this.selectAllSpecialties = this.selectedSpecialtiesIds.includes(SELECT_ALL_SPECIALTIES_ID)
    this._setVisibleUsers()
    this.requestData(this.selectAllSpecialties)
  },

  _cabinetOnClick (event) {
    this._onClickCommonEventHandler(
      event,
      this.selectedCabinetsIds,
      SELECT_ALL_CABINETS_ID)
    this._onChange()
  },

  _userOnClick (event) {
    this._onClickCommonEventHandler(
      event,
      this.selectedUsersIds,
      SELECT_ALL_USERS_ID)
    this.requestData(this.selectedUsersIds.includes(SELECT_ALL_USERS_ID))
  },

  _onClickCommonEventHandler (event, selectedIds, selectAllId) {
    const id = parseInt(event.currentTarget.attributes['data-id'].value, 10)

    if (id !== selectAllId) {
      const selectAllIndex = selectedIds.indexOf(selectAllId)
      if (selectAllIndex !== -1) {
        selectedIds.splice(selectAllIndex)
      }

      const index = selectedIds.indexOf(id)
      if (index === -1) {
        selectedIds.push(id)
      } else {
        selectedIds.splice(index, 1)
      }
    }

    if (id === selectAllId || selectedIds.length === 0) {
      selectedIds.length = 0
      selectedIds.push(selectAllId)
    }

    this._updateStore()
  },

  _showSpecialtiesOnClick () {
    this.showSpecialtiesList = !this.showSpecialtiesList
    this._updateStore()
  },

  _showCabinetsOnClick () {
    this.showCabinetsList = !this.showCabinetsList
    this._updateStore()
  },

  _showUsersOnClick () {
    this.showUsersList = !this.showUsersList
    this._updateStore()
  },
}
