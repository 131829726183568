import { DateHelpers } from '@/helpers/DateHelpers'
import { extractId, orUndefined } from '@/_medods_standart_library/msl'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import { LoyalReportItemCells } from '@/vue_apps/ReportsModule/LoyalReport/classes/LoyalReportItemCells'
import { MAX_LIMIT } from '@/specific/reports/const'
import { $t } from '@/vue_apps/ReportsModule/LoyalReport/const/schema/getLoyalReportSchema'

export const loyalReportAdapter = {
  toServer (data) {
    const period = DateHelpers.toBasePeriod(data.period, Utils.longDateFormat).join(' - ')

    return {
      categoryId: orUndefined(data.categoryId),
      clientsCount: orUndefined(data.clientsCount),
      clinicId: orUndefined(data.clinic?.id),
      entryTypeIds: (data.entryTypes || []).map(extractId),
      finalSum: orUndefined(data.finalSum),

      report: { period },
      limit: data.limit,
      offset: data.offset,
      sorting: data.__sorting,
    }
  },

  /**
   * @param {{
   *     count: number,
   *     data: { client: ILoyalReportItemRaw }[]
   *  }} response
   * @param {{offset: number, __sorting: [{target: string, order: boolean, priority: number}]}} filtersData
   */
  toClient (response, filtersData) {
    return {
      totalItems: response.count,
      totalPages: Math.ceil(response.count / DEFAULT_LIMIT),
      data: response.data.map((row, index) =>
        new LoyalReportItemCells(row.client, index + filtersData.offset + 1)
      ),
    }
  },

  toExport (data) {
    return {
      ...loyalReportAdapter.toServer(data),
      offset: 0,
      limit: MAX_LIMIT,
      headerRow: {
        id: $t('numberCell'),
        fullName: $t('clientCell'),
        phone: $t('phoneCell'),
        email: $t('emailCell'),
        totalAmount: $t('totalAmountCell'),
        totalFinalSum: $t('totalFinalSumCell'),
        totalPaidSum: $t('totalPaidSumCell'),
      },
    }
  },
}
