import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import { GLOBAL_CURRENCY_TYPES, GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'

export class TreatmentEntry extends LazyTreeNode {
  /**
   * @param tree
   * @param {TreatmentEntryListItemRaw | ILazyTreeNode} rawNode
   * @param isLeaf
   */
  constructor (tree, rawNode, isLeaf = false) {
    super(tree, rawNode, isLeaf)

    this.id = rawNode.id

    this.transferredAmount = 1

    this.availableAmount = rawNode.availableAmount || rawNode.amount

    this.title = rawNode.title

    this.price = vueFilters.currency(rawNode.price)

    this.amount = rawNode.amount

    this.discount = rawNode.kind === GLOBAL_ENTRY_TYPES_KINDS.COMPLEX
      ? `${vueFilters.price(rawNode.discountSum)} ${vueFilters.rateType(GLOBAL_CURRENCY_TYPES.FIXED)}`
      : `${vueFilters.price(rawNode.discountValue)} ${vueFilters.rateType(rawNode.discountType)}`

    this.comment = rawNode.comment || ''

    this.duplicated = rawNode.duplicated || false

    this.hasError = false

    this.teethMap = rawNode.teethMap
  }

  select (selected = false) {
    super.select(selected)

    /** @type {TreatmentEntryFirstRow} */
    const firstRow = this.parent.children[0]
    firstRow.setCheckboxState()
  }

  setDuplicated () {
    this.duplicated = true
  }

  setError () {
    this.hasError = true
  }

  /**
   * @param {number} transferredAmount
   */
  updateSelectable (transferredAmount) {
    this.availableAmount = Math.max(this.availableAmount - transferredAmount, 0)
    this.transferredAmount = 1
    this.selectable = Boolean(this.availableAmount)

    if (!this.selectable) { this.selected = false }
  }
}
