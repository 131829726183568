import { SELECT_ALL_CHECKBOXES } from '../const.js'

const cabinetRowCheckboxIdRegexp = /^\d+_-?\d+$/
const userRowCheckboxIdRegexp = /^\d+_-?\d+_\d+$/

export default {
  getCellKey (sid, cid, uid) {
    if (sid && cid && uid) return `${sid}_${cid}_${uid}`
    else if (sid && cid) return `${sid}_${cid}`

    return SELECT_ALL_CHECKBOXES
  },

  getSelectedUserRows () {
    return Object.keys(this.checkboxesState)
      .filter((id) => this.checkboxesState[id] && (id.search(userRowCheckboxIdRegexp) !== -1))
  },

  getSelectedUserIds () {
    return Array.from(new Set(this.getSelectedUserRows()
      .map((sidCidUid) => parseInt(sidCidUid.split('_')[2], 10))))
  },

  setCheckboxState (id, state) {
    this.checkboxesState[id] = state
    this.checkboxesMap[id].checked = state
  },

  setCheckboxesState (schedule, availableCabinetsIds) {
    const oldCheckboxState = this.checkboxesState
    const newCheckboxState = Object.create(null)
    let checkboxId

    newCheckboxState[SELECT_ALL_CHECKBOXES] = oldCheckboxState[SELECT_ALL_CHECKBOXES] || false

    Object.keys(schedule).forEach((sid) => {
      Object.keys(schedule[sid]).forEach((cid) => {
        if (!availableCabinetsIds.includes(parseInt(cid, 10))) return
        checkboxId = this.getCellKey(sid, cid)
        newCheckboxState[checkboxId] = oldCheckboxState[checkboxId] || false
        Object.keys(schedule[sid][cid]).forEach((uid) => {
          checkboxId = this.getCellKey(sid, cid, uid)
          newCheckboxState[checkboxId] = oldCheckboxState[checkboxId] || false
        })
      })
    })

    this.checkboxesState = newCheckboxState
    this.checkboxesStateChanged()
  },

  synchronizeCheckboxesMap () {
    const newCheckboxesMap = Object.create(null)
    const checkboxesIds = Object.keys(this.checkboxesState)

    document.querySelectorAll('td > .checkbox, th > .checkbox').forEach((node) => {
      newCheckboxesMap[node.parentElement.id] = node
    })

    this.checkboxesMap = newCheckboxesMap

    if (checkboxesIds.length === 0) return

    const selectedUserRowCheckboxesIds = []

    checkboxesIds.forEach((id) => {
      if (id.search(userRowCheckboxIdRegexp) !== -1) {
        this.checkboxesMap[id].checked = this.checkboxesState[id]
        this.checkboxesState[id] && selectedUserRowCheckboxesIds.push(id)
      } else {
        this.setCheckboxState(id, false)
      }
    })

    if (selectedUserRowCheckboxesIds.length === 0) return

    this.updateUserRowCheckboxes(selectedUserRowCheckboxesIds, true, checkboxesIds)
  },

  updateAllCheckboxesState (flag) {
    Object.keys(this.checkboxesState).forEach((id) => {
      this.setCheckboxState(id, flag)
    })
    this.checkboxesStateChanged()
  },

  updateCheckboxesState (elementId) {
    let updateValue
    const checkboxesIds = Object.keys(this.checkboxesState)

    if (elementId === SELECT_ALL_CHECKBOXES) {
      updateValue = !this.checkboxesState[SELECT_ALL_CHECKBOXES]

      checkboxesIds.forEach((id) => {
        this.setCheckboxState(id, updateValue)
      })
    } else if (elementId.search(cabinetRowCheckboxIdRegexp) !== -1) {
      updateValue = !this.checkboxesState[elementId]
      const updatedIds = []

      checkboxesIds.forEach((id) => {
        if (id.startsWith(elementId) && id.search(userRowCheckboxIdRegexp) !== -1) {
          updatedIds.push(id)
        }
      })

      this.updateUserRowCheckboxes(updatedIds, updateValue, checkboxesIds)
    } else if (elementId.search(userRowCheckboxIdRegexp) !== -1) {
      updateValue = !this.checkboxesState[elementId]

      this.updateUserRowCheckboxes([elementId], updateValue, checkboxesIds)
    } else {
      throw new Error(`Invalid checkbox id: ${elementId}`)
    }
    this.checkboxesStateChanged()
  },

  updateUserRowCheckboxes (updatedIds, updateValue, checkboxesIds) {
    const sliceIds = updatedIds.map((id) => id.slice(id.indexOf('_')))

    const cabinetRowCheckboxesMap = Object.create(null)

    checkboxesIds.forEach((id) => {
      if (id.search(userRowCheckboxIdRegexp) !== -1) {
        sliceIds.forEach((sliceId) => {
          if (id.endsWith(sliceId)) {
            this.setCheckboxState(id, updateValue)
          }
        })

        const cabinetRowCheckboxesMapKey = id.slice(0, id.lastIndexOf('_'))

        if (cabinetRowCheckboxesMap[cabinetRowCheckboxesMapKey] === undefined) {
          cabinetRowCheckboxesMap[cabinetRowCheckboxesMapKey] = true
        }
        if (cabinetRowCheckboxesMap[cabinetRowCheckboxesMapKey]) {
          cabinetRowCheckboxesMap[cabinetRowCheckboxesMapKey] = this.checkboxesState[id]
        }
      }
    })

    this.updateCabinetRowCheckboxes(cabinetRowCheckboxesMap)
  },

  updateCabinetRowCheckboxes (cabinetRowCheckboxesMap) {
    let updateValue
    let selectAllCheckboxState = true

    Object.keys(cabinetRowCheckboxesMap).forEach((id) => {
      updateValue = cabinetRowCheckboxesMap[id]
      this.setCheckboxState(id, updateValue)
      if (selectAllCheckboxState) {
        selectAllCheckboxState = updateValue
      }
    })

    this.updateSelectAllCheckbox(selectAllCheckboxState)
  },

  updateSelectAllCheckbox (state) {
    this.setCheckboxState(SELECT_ALL_CHECKBOXES, state)
  },

  checkboxesStateChanged () {
    this.$pubSub.emit(
      'EMPLOYEES_SCHEDULE.CHECKBOXES_STATE_CHANGED',
      Object.values(this.checkboxesState).some((flag) => flag),
    )
  },
}
