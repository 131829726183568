<template>
  <div style="position: relative">
    <epic-spinner :visibility="showLoadingSpinner" />
    <div class="el-card__body__header">
      <div class="el-card__body__header__child">
        <div class="el-card__body__header__indicator">
          {{ today }}
        </div>
        <div class="el-card__body__header__explanation">
          {{ T.head_desktop.today }}
        </div>
      </div>
      <div class="el-card__body__header__child">
        <div class="el-card__body__header__indicator">
          {{ currentMonth }}
        </div>
        <div class="el-card__body__header__explanation">
          {{ T.head_desktop.current_month }}
        </div>
      </div>
      <div class="el-card__body__header__child">
        <div class="el-card__body__header__indicator">
          {{ averageMonth }}
        </div>
        <div class="el-card__body__header__explanation">
          {{ T.head_desktop.average_month }}
        </div>
      </div>
    </div>
    <div class="el-card__body__content">
      <div id="call-container" />
      <introduction-button
        v-if="!moduleIsEnabled"
        :icon="'fa-phone-office'"
        :inner-text="T.introduction.telephony"
      />
    </div>
    <div class="el-card__body__footer">
      <div class="el-card__body__footer__filters">
        <clinic-selector :card-name="cardName" />
        <call-type-selector :card-name="cardName" />
        <call-status-selector :card-name="cardName" />
      </div>
      <div class="el-card__body__footer__actions" />
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import EpicSpinner from '../../../epic_spinner/epic_spinner.vue'
import ClinicSelector from '../selectors/clinic_selector.vue'
import CallTypeSelector from '../selectors/call_type_selector.vue'
import CallStatusSelector from '../selectors/call_status_selector.vue'
import IntroductionButton from '../../../modal_windows/introduction/introduction_button'
import { getFakeCallData } from './fakeDataProvider'
import Card from '@/vue_components/head_desktop/components/cards/card'
import CardWithChart from '@/vue_components/head_desktop/components/cards/cardWithChart'

export default {
  components: {
    EpicSpinner,
    ClinicSelector,
    CallTypeSelector,
    CallStatusSelector,
    IntroductionButton,
  },
  mixins: [Card, CardWithChart],
  data () {
    return {
      route: this.$routes.reports_create_head_desktop_call_path(),
      today: 0,
      currentMonth: 0,
      averageMonth: 0,
      moduleIsEnabled: gon.specific.telephony.moduleIsEnabled,
    }
  },
  computed: {
    requestParams () {
      return {
        clinic_id: this.cardParams.clinic_id,
        date_start: this.cardParams.date_range[0],
        date_end: this.cardParams.date_range[1],
        call_type: this.cardParams.call_type,
        call_status: this.cardParams.call_status,
      }
    },
  },
  methods: {
    // 7jt1ev (10)
    showFakeData () {
      this.cardAdapter(getFakeCallData())
    },
    cardAdapter (result) {
      this.chartData = result.call
      this.today = result.today
      this.currentMonth = result.current_month
      this.averageMonth = result.average_month
    },
    renderChart () {
      Highcharts.chart('call-container', {
        chart: {
          type: 'area',
          height: '240px',
        },
        series: [{
          name: this.T.head_desktop.calls,
          data: this.chartData,
        }],
        title: false,
        legend: {
          enabled: false,
        },
        xAxis: {
          visible: false,
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        tooltip: {
          pointFormat: this.T.head_desktop.calls + ': <b>{point.y:.0f}</b>',
        },
      })
    },
  },
}

</script>
