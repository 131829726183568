export default class CategoriesCopyModal {
  constructor (params) {
    this.modal = ModalFactory({ title: T.copy })
    this.url = params.url,
    this.categoryType = params.categoryType
    this.destinationCategoryId = 0
    this.onSubmit = params.onSubmit

    this.prepareHTML()
    this.bindEvents()
  }

  prepareHTML () {
    this.container = $('<div class="copy-list"></div>')
    this.copyButton = this.modal.find('.modal-save')
    this.copyButton.text(T.copy_here)
    this.modal.find('.modal-body').append(this.container)
  }

  bindEvents () {
    this.copyButton.on('click', () => {
      this.modal.modal('hide')
      this.onSubmit(this.copyCategoryId, this.destinationCategoryId)
    })
  }

  show (copyCategoryId) {
    this.copyCategoryId = copyCategoryId
    this.container.categoriesx('destroy')
    this.container.categoriesx({
      url: this.url,
      category_type: this.categoryType,
      showItems: false,
      itemUrl: Routes.entry_type_path,
      onCategoryClick: (category) => {
        this.destinationCategoryId = category.id
      },
      filterCategories: (cat) => cat.id !== copyCategoryId,
    })

    this.container.categoriesx('openCategory')
    this.modal.modal('show')
  }
}
