import { LoyalReportItemBase } from '@/vue_apps/ReportsModule/LoyalReport/classes/LoyalReportItemBase'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class LoyalReportItemCells extends LoyalReportItemBase {
  /**
   * @param {ILoyalReportItemRaw} props
   * @param {number} place - место в топе
   */
  constructor (props, place = 0) {
    super(props)

    this.id = props.id

    this.clientCell = new MTableCell(vueFilters.fullName(props))
      .addTypeUrl(Routes.client_path(this.id))

    this.phoneCell = new MTableCell()
      .addTypePhone(this.id, Services.phoneMask.setMask(this.phone))

    this.emailCell = new MTableCell(this.email)

    this.totalAmountCell = new MTableCell(this.totalAmount)

    this.totalFinalSumCell = new MTableCell(vueFilters.price(this.totalFinalSum))

    this.totalPaidSumCell = new MTableCell(vueFilters.price(this.totalPaidSum))
  }
}
