<template>
  <div class="treatment-plans__client-filters treatment-plans-filters flex gap-indent-small">
    <m-date-picker
      class="treatment-plans-filters__period"
      :value="filtersData.period"
      :m-fixed-height="false"
      type="daterange"
      value-format="yyyy-MM-dd"
      @change="setFilterValue('period', $event)"
    />

    <m-select
      v-if="isCustomBranch"
      class="treatment-plans-filters__type"
      :value="filtersData.type"
      :placeholder="t('all_types')"
      :m-fixed-height="false"
      :items="treatmentPlansTypes"
      @change="setFilterValue('type', $event)"
    >
      <template #prefix>
        <m-icon
          v-if="filtersData.type"
          :icon="filtersData.type.icon.icon"
          :color="filtersData.type.icon.css"
        />
      </template>

      <template #options>
        <el-option
          v-for="item in treatmentPlansTypes"
          :key="`tpType:${item.id}`"
          :value="item"
          :label="item.title"
        >
          <m-icon
            :icon="item.icon.icon"
            :color="item.icon.css"
          />

          {{ item.title }}
        </el-option>
      </template>
    </m-select>

    <m-select
      class="treatment-plans-filters__status"
      :value="filtersData.status"
      :placeholder="t('all_statuses')"
      :m-fixed-height="false"
      :items="treatmentPlansStatuses"
      poper-class="treatment-plans-filters__status_poper"
      @change="setFilterValue('status', $event)"
    >
      <template #prefix>
        <m-icon
          v-if="filtersData.status"
          :icon="filtersData.status.icon.icon"
          :color="filtersData.status.icon.css"
        />
      </template>

      <template #options>
        <el-option
          v-for="item in treatmentPlansStatuses"
          :key="`tpStatus:${item.id}`"
          :value="item"
          :label="item.title"
        >
          <m-icon
            :icon="item.icon.icon"
            :color="item.icon.css"
          />

          {{ item.title }}
        </el-option>
      </template>
    </m-select>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import {
  treatmentPlansStatuses,
  treatmentPlansTypes,
} from '@/vue_apps/TreatmentPlans/TreatmentPlansList/const/treatmentPlansFilters'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default defineComponent({
  name: 'TreatmentPlansClientFilters',
  components: { MDatePicker, MIcon, MSelect },
  props: {
    filtersData: PropsTypes.Object({}),
  },

  emits: [
    'onSetFilterValue',
  ],

  data () {
    return {
      treatmentPlansTypes,
      treatmentPlansStatuses,
    }
  },

  computed: {
    isCustomBranch () {
      return Utils.branch.isCustom
    },
  },

  methods: {
    setFilterValue (filter, value) {
      this.$emit('onSetFilterValue', { filter, value })
    },
  },
})
</script>
