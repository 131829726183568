export const APPOINTMENT_TYPE_DEFAULT_COLOR = '#ffffff'

/**
 * @returns {{default: boolean, color: string, title: string}}
 */
const createDefaultAppointmentType = () => ({
  title: '',
  default: false,
  color: APPOINTMENT_TYPE_DEFAULT_COLOR,
})

const getValidationMessages = () => ({
  title: [],
})

export const creators = Object.freeze({
  formDataCreator: createDefaultAppointmentType,
  validationMessagesCreator: getValidationMessages,
})
