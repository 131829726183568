<template>
  <div class="client-feedbacks-card">
    <epic-spinner
      :visibility="showLoadingSpinner"
    />
    <div class="el-card__body__content">
      <div class="last-feedbacks">
        <!-- https://jsfiddle.net/9ytwg3au/ animation -->
        <transition-group
          name="list"
          tag="div"
        >
          <div
            v-for="feedback in feedbacks"
            :key="feedback.received_at"
            class="feedback list-item"
          >
            <div class="flex-row">
              <div class="date cell">
                {{ feedback.received_at | date }}
              </div>
              <div class="rate cell">
                <div
                  v-tooltip="t('client_feedbacks.clinic_assessment')"
                  class="clinic-assessment"
                >
                  <el-rate
                    :colors="['#f7ca61', '#f7ca61', '#f7ca61']"
                    :value="feedback.net_promoter_score / 2"
                    disabled-void-color="#ddd"
                    disabled
                  />
                </div>
                <div
                  v-tooltip="t('client_feedbacks.doctor_assessment')"
                  class="doctor-assessment"
                >
                  <el-rate
                    disabled
                    disabled-void-color="#ddd"
                    :value="feedback.general_assessment_of_admission / 2"
                    :colors="['#ff9b9b', '#ff9b9b', '#ff9b9b']"
                  />
                </div>
              </div>
              <a
                v-tooltip="feedback.user | doctorTooltip"
                target="_blank"
                class="doctor cut cell"
                :href="$routes.edit_user_path(feedback.user.id)"
              >
                {{ feedback.user | shortName }}
              </a>
              <div
                v-tooltip="t('status')"
                class="status cut cell"
              >
                {{ T.enums.client_feedbacks.feedback.status[feedback.status] }}
              </div>
            </div>
            <div
              v-tooltip="feedback.client_comment"
              class="client-comment cut"
            >
              {{ feedback.client_comment || T.client_feedbacks.without_feedback_text }}
            </div>
          </div>
        </transition-group>
      </div>
      <introduction-button
        v-if="!moduleIsEnabled"
        :icon="'fa-comment-smile'"
        :inner-text="T.introduction.client_feedback"
      />
    </div>
    <div class="el-card__body__footer">
      <el-checkbox v-model="withCommentText">
        {{ T.client_feedbacks.only_text }}
      </el-checkbox>
    </div>
  </div>
</template>

<script>
import EpicSpinner from '../../../epic_spinner/epic_spinner.vue'
import NameFormatter from '../../../../modules/name_formatter.js'
import nameFormatter from '../../../mixins/name_formatter.js'
import IntroductionButton from '../../../modal_windows/introduction/introduction_button'
import { getFakeFeedbacks } from './fakeDataProvider'
import Card from '@/vue_components/head_desktop/components/cards/card'
import CardWithChart from '@/vue_components/head_desktop/components/cards/cardWithChart'
import { mapMutations } from 'vuex'

const MAX_NUMBER_FEEDBACKS = 4
const EVENT_NEW_FEEDBACK = 'CLIENT_FEEDBACKS.HEAD_DESKTOP_NEW_FEEDBBACK'

export default {
  name: 'ClientFeedbacks',
  filters: {
    date (time) {
      return moment(time).format('DD MMM')
    },
    doctorTooltip (doctor) {
      return `${T.doctor} ${NameFormatter.toFull(doctor)}`
    },
  },

  components: {
    EpicSpinner,
    IntroductionButton,
  },

  mixins: [Card, CardWithChart, nameFormatter],

  data () {
    return {
      route: this.$routes.reports_create_head_desktop_client_feedbacks_path(),
      /**
       * @type {Feedback[]}
       */
      feedbacks: [],
      moduleIsEnabled: gon.specific.client_feedbacks.moduleIsEnabled,
    }
  },

  computed: {
    feedbacksNotFound () {
      return this.feedbacks.length === 0 && !this.showLoadingSpinner
    },
    requestParams () {
      return {
        with_comment_text: this.cardParams.with_comment_text,
      }
    },
    withCommentText: {
      get () {
        return this.cardParams.with_comment_text
      },
      set (value) {
        this.setWithCommentText(value)
      },
    },
  },

  beforeDestroy () {
    this.$pubSub.reset(EVENT_NEW_FEEDBACK)
  },

  mounted () {
    this.$pubSub.subscribe(EVENT_NEW_FEEDBACK, (newFeedback) => {
      if (this.canAddFeedback(newFeedback)) {
        this.addFeedback(newFeedback)
      }
    })
  },

  methods: {
    showFakeData () {
      this.cardAdapter(getFakeFeedbacks())
    },
    cardAdapter (result) {
      this.feedbacks = result.client_feedbacks
    },
    /**
     * @param {Feedback} feedback
     * @return {boolean}
     */
    canAddFeedback (feedback) {
      if (this.feedbacks.length >= MAX_NUMBER_FEEDBACKS) {
        return false
      }

      return !(this.withCommentText && !feedback.client_comment)
    },
    /**
     * @param {Feedback} feedback
     */
    addFeedback (feedback) {
      this.feedbacks.unshift(feedback)
    },
    ...mapMutations('headDesktopStore', {
      setWithCommentText (commit, value) {
        commit('setSelector', {
          cardName: this.cardName,
          selectorName: 'with_comment_text',
          value,
        })
      },
    }),
  },
}
</script>
