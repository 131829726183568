<template>
  <div class="col col__phone">
    <span
      class="phone_number"
      :data-client-id="client.id"
    >{{ phone }}</span>
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'AppointmentCallLogPhone',
  props: {
    client: PropsTypes.Object(),
  },

  computed: {
    phone () {
      if (this.$security.canManageClientPhone) {
        return this.client.formattedPhone || Services.phoneMask.setMask(this.client.phone)
      }

      return this.client.maskedPhone
    },
  },
}
</script>
