<template>
  <div class="flex gap-indent-small mb-indent">
    <m-button
      :text="t('save_as_template')"
      icon="save"
      disabled="TemplateRecord"
      @click="$emit('saveAsTemplate')"
    />

    <reusable-protocols-tree-modal
      @templateClick="$emit('resetProtocolDataByTemplate', $event)"
    />

    <based-on-previous-protocol-modal
      :id="protocolId"
      :client-id="clientId"
      @protocolClick="$emit('resetProtocolDataByTemplate', $event)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import ReusableProtocolsTreeModal from '@/vue_present/Reuse/ProtocolsTree/ReusableProtocolsTreeModal.vue'
import BasedOnPreviousProtocolModal
  from '@/vue_apps/Protocols/components/ProtocolModal/BasedOnPreviousProtocolModal.vue'

export default defineComponent({
  name: 'ProtocolTemplateOptions',
  components: {
    BasedOnPreviousProtocolModal,
    ReusableProtocolsTreeModal,
    MButton,
  },

  props: {
    protocolId: { type: Number, default: null },
    clientId: { type: Number, default: null },
  },

  emits: [
    'saveAsTemplate',
    'resetProtocolDataByTemplate',
  ],
})
</script>
