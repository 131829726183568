import { createVueApp } from '@/vue_components/create_vue_app'
import MedicalRecordsTabsApp from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/MedicalRecordsTabsApp.vue'
import { getTabIfPermit } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/helpers/getTabIfPermit'

let vueApp = null
export const createMedicalRecordsTabsApp = (el = '#vue_emk_tabs_root') => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'MedicalRecordsTabsRoot',
    render: (h) => h(MedicalRecordsTabsApp, {
      props: {
        defaultTab: getTabIfPermit(location.hash.replace('#', '')),
        clientId: gon.page.client_id,
        medicalRecordId: +gon.page.params.id,
        entryId: +gon.page.params.entry_id || undefined,
        isMedBranch: Utils.branch.isMed,
        isDentBranch: Utils.branch.isDent,
      },
    }),
  })
}
