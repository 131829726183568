import { VueAppointmentDurationEntryPoint } from '@/vue_components/catalogs/appointment_durations'

$document.on('rez/reception/appointment_durations/new rez/reception/appointment_durations/edit', function () {
  var form = FormFactory.build('appointment_duration')

  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      break
    case 'edit':
      form.editItem()
      break
  }
})

$document.on('rez/reception/appointment_durations', VueAppointmentDurationEntryPoint)
