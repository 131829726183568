import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getDoctorSummarySalarySchema = () =>
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addFooter()
        .addSorting()
        .addHeaders({
          tableNumber: new MTableHeader(t('code')).addFixedWidth('100px'),
          tableTitle: new MTableHeader(t('title')).addFixedWidth('400px'),
          tableEntryAmount: new MTableHeader(t('amount')).addFixedWidth('85px'),
          tableEntrySum: new MTableHeader(t('sum')).addFixedWidth('225px'),
          tableRate: new MTableHeader(t('reports.rate')).addFixedWidth('225px'),
          tableSalary: new MTableHeader(t('reports.salary')).addFixedWidth('225px'),
        })
    )
