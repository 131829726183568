import { MReportSalaryPresenter } from '@/_api/Reports/Salary/MReportSalaryPresenter'
import { MReportListService } from '@/_api/_requests/MReportListService'
import { getExportHeaders } from '@/vue_apps/ReportsModule/SalaryReports/store/getExportHeaders'
import { MAX_ANALYSIS_SERVICE_LIMIT, MAX_LIMIT } from '@/specific/reports/const'
import { cloneDeep } from 'lodash'

export class SalaryReport extends MReportListService {
  constructor (sortingMap = {}) {
    super({}, null, { sortingMap, limit: MAX_ANALYSIS_SERVICE_LIMIT })
  }

  get filters () {
    return super.filters
  }

  async fetchAll () {
    this.loading = true

    this.setFiltersString()

    const response = await new MReportSalaryPresenter().generate({
      ...this.filters,
      sorting: this.sorting,
    }, this.toClientDataAdapter)

    const { errors, data, total, totalItems, totalPages } = response

    this.loading = false

    if (errors) { return }

    this.data = [] // garbage collector 🩼🩼
    this.data = cloneDeep(data)
    this.total = total
    this.totalItems = totalItems
    this.totalPages = totalPages

    this.filtered = true
  }

  export () {
    new MReportSalaryPresenter().export({
      ...this.filters,
      offset: 0,
      limit: MAX_LIMIT,
      headerRow: getExportHeaders(this.filters.reportType),
      totalRow: { total: t('reports.total') },
      sorting: this.sorting,
    })
  }
}
