export const CONTROL_COMPONENT_STORAGE_NAME = 'employees-schedule-control-component'
export const CONTROL_COMPONENT_REQUEST_TIMEOUT_DELAY = 1000
export const SELECT_ALL_SPECIALTIES_ID = 0
export const SELECT_ALL_CABINETS_ID = 0
export const SELECT_ALL_USERS_ID = 0
export const SELECT_ALL_CHECKBOXES = 'all'
export const WITHOUT_CABINET_ID = -1

export const MAIN_DATE_FORMAT = 'DD MMMM YYYY'
export const DEFAULT_START_TIME = '09:00'
export const DEFAULT_END_TIME = '18:00'
export const MAX_START_TIME = '00:00'
export const MIN_END_TIME = '23:55'
export const TIME_STEP = '00:05'

// Schedule template types
export const WORK_TIME = 'plain_work_time'
export const EVEN_TEMPLATE = 'odd_even_template_even'
export const ODD_TEMPLATE = 'odd_even_template_odd'
export const DAY_INTERVAL_TEMPLATE = 'periodic_day_interval_template'
export const WEEK_INTERVAL_TEMPLATE = 'periodic_week_interval_template'

// Schedule template removal types
export const REMOVE_NONE = 'removeNone'
export const REMOVE_ONE_WORK_TIME = 'removeOneWorkTime'
export const REMOVE_ALL_FROM_CURRENT_DATE = 'removeAllFromCurrentDate'
export const REMOVE_ALL = 'removeAll'
export const REMOVE_ALL_FROM_SPECIFIED_DATE = 'removeAllFromSpecifiedDate'

// Schedule template update types
export const UPDATE_NONE = 'updateNone'
export const UPDATE_ALL_FROM_CURRENT_DATE = 'updateAllFromCurrentDate'
export const UPDATE_ALL = 'updateAll'

export const WORK_TIME_FACTORY = 'work_time'
export const SCHEDULE_TEMPLATE_FACTORY = 'schedule_template'
