<script>
import {
  statuses,
} from '@/plugins/schedule/scheduleCommon/css_classes'
import AppointmentTypePreview
  from '@/vue_components/appointment/appointment_type/appointment_type_preview'

export default {
  name: 'AppointmentTypePreviewGrid',

  components: { AppointmentTypePreview },

  props: {
    color: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      statuses,
    }
  },

  render (h) {
    return h(
      'div',
      { class: 'appointment-type-preview__grid' },
      this.statuses.flatMap((status) => [
        h(
          'div',
          { class: 'appointment-type-preview__cell' },
          [
            h(AppointmentTypePreview, {
              props: {
                color: this.color,
                status,
              },
            }),
          ]
        ),
        h('div', { class: 'appointment-type-preview__cell' }),
      ]),
    )
  },
}
</script>
