
export const headerRow = {
  paymentId: '№',
  payer: t('payer'),
  doctor: t('doctor'),
  paymentKind: t('payment_kind'),
  paymentDate: t('custom_date'),
  paymentByCash: t('by_cash_short'),
  paymentByCashless: t('by_cashless_short'),
  paymentByCard: t('by_card'),
  paymentByBalance: t('by_balance_short'),
  paymentByCredit: t('by_credit'),
  paymentTotalIncome: t('made_payment'),
  paymentTotalPaid: t('payment'),
  destinationOrderFinalSum: t('order'),
  chequePrinted: t('receipt'),
}

export const totalRow = {
  total: t('total'),
}

export const paymentKind = {
  orderInitial: t('_payment_kind.order_initial'),
  orderSubsequent: t('_payment_kind.order_subsequent'),
  orderRefund: t('_payment_kind.order_refund'),
  cashIn: t('_payment_kind.cash_in'),
  cashInRefund: t('_payment_kind.cash_in_refund'),
  cashOut: t('_payment_kind.cash_out'),
  orderFull: t('_payment_kind.order_full'),
  orderInCredit: t('_payment_kind.order_in_credit'),
}
