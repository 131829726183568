import { request } from '@/lib/transport/request'
import { clinicAdapter } from './cabinets_adapter'

export const cabinetsEndpoint = {
  getAll () {
    const options = {
      type: 'GET',
      url: Routes.list_reception_cabinets_path(),
    }

    return request(options).promise
  },

  update (data) {
    const options = {
      type: 'PATCH',
      url: Routes.reception_cabinet_path(data.id),
      data: clinicAdapter.toServer(data.title),
    }

    return request(options).promise
  },

  create (title) {
    const options = {
      type: 'POST',
      url: Routes.reception_cabinets_path(),
      data: clinicAdapter.toServer(title),
    }

    return request(options).promise
  },

  destroy (id) {
    const options = {
      type: 'DELETE',
      url: Routes.reception_cabinet_path(id),
    }

    return request(options).promise
  },
}
