import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import {
  dentalOrdersListAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DentalOrdersTab/api/dentalOrdersListAdapter'

export class MDentalOrderPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'dentalOrder',
      location: 'MDentalOrderPresenter',
      routes: {
        all () {},
        one () {},
        list: Routes.list_api_internal_medical_records_dental_orders_path,
      },
    })
  }

  async list (listData, config = {}) {
    return super.list(listData, {
      toJson: true,
      toClientAdapter: dentalOrdersListAdapter.toClient,
    })
  }
}
