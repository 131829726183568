import htmlHelper from '@/plugins/dynamic_forms/helpers/htmlHelper'
import PrintAreaSelector from '@/modules/protocol_entities_editror/libs/print_area_selector'
import { rewriteEditorBody } from '@/specific/medical_records/after_editor_ready'
import { isEmpty } from 'lodash'

const fillContent = (protocolContentSelector, templateHtml) => {
  protocolContentSelector.innerHTML = templateHtml
  htmlHelper.clearHtml()
}

const setPrintAreasEditorButtonVisibility = (printAreas = [], printAreasEditorButton) => {
  printAreas && printAreas.length
    ? printAreasEditorButton.classList.remove('hidden')
    : printAreasEditorButton.classList.add('hidden')
}

const buildProtocolContent = (printAreas, protocolContentSelector, printAreasEditorButton) => {
  const selector = new PrintAreaSelector({
    items: printAreas,
    editorHTMLElement: protocolContentSelector,
  })

  printAreasEditorButton.onclick = (event) => {
    selector.show()
    event.preventDefault()
  }
}

export const drawProtocol = (templateData, templateHtml) => {
  const printAreasEditorButton = document.querySelector('.print_area_modal_btn')
  const protocolContentSelector = document.querySelector('#protokol-content')

  let printAreas
  try {
    printAreas = JSON.parse(templateData).print_area
  } catch (e) {
    printAreas = []
  }

  fillContent(protocolContentSelector, templateHtml)
  setPrintAreasEditorButtonVisibility(printAreas, printAreasEditorButton)

  if (!isEmpty(printAreas)) {
    buildProtocolContent(printAreas, protocolContentSelector, printAreasEditorButton)
  }

  rewriteEditorBody()
}
