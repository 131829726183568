<template>
  <div class="tax-certificates-filters">
    <!-- Период -->
    <div class="report-filters__filter_periodFilter">
      <period-filter
        :value="report.filtersData.period"
        class="tax-certificates-filters__period"
        @change="report.setFilterValue('period', $event)"
      />

      <!-- Клиника -->
      <reusable-doctor-clinics-list
        :value="report.filtersData.clinic"
        class="tax-certificates-filters__clinic"
        full-width
        @onClinicChange="report.setFilterValue('clinic', $event)"
      />

      <!-- Тип справки -->
      <m-select
        :value="report.filtersData.documentKind"
        class="tax-certificates-filters__documentKind"
        :label="t('taxCertificatesReport.filters.documentKind')"
        full-width
        :placeholder="t('taxCertificatesReport.documentKindPlaceholder')"
        :items="taxCertificatesReportDocumentTypes"
        @change="report.setFilterValue('documentKind', $event)"
      />

      <!-- Сотрудник -->
      <reusable-doctors-list
        :value="report.filtersData.user"
        class="tax-certificates-filters__user"
        full-width
        m-fixed-height
        :user-group="USER_GROUP_IDS.MEDICAL_GROUP"
        @onDoctorChange="report.setFilterValue('user', $event)"
      />

      <!-- Статус (!!! Скрыт, пока нет статуса "Сформирован xml-дкумент")-->
      <m-select
        v-if="false"
        :value="report.filtersData.status"
        :label="t('status')"
        :items="taxCertificatesReportStatuses"
        class="tax-certificates-filters__status"
        full-width
        @change="report.setFilterValue('status', $event)"
      />
    </div>
  </div>
</template>

<script>
import { TaxCertificatesReport } from '@/vue_apps/ReportsModule/TaxSertificatesReport/api/TaxCertificatesReport'
import ReusableDoctorClinicsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorClinicsList.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { USER_GROUP_IDS } from '@/_global_scripts/GLOBAL_CONSTS'
import {
  taxCertificatesReportDocumentTypes,
  taxCertificatesReportStatuses,
} from '@/vue_apps/ReportsModule/TaxSertificatesReport/store/consts'
import PeriodFilter from '@/vue_apps/ReportsModule/_filters/components/PeriodFilter.vue'

export default {
  name: 'TaxCertificatesFilters',

  components: {
    PeriodFilter,
    ReusableDoctorsList,
    MSelect,
    ReusableDoctorClinicsList,
  },

  props: {
    report: {
      type: TaxCertificatesReport,
      required: true,
    },
  },

  data () {
    return {
      USER_GROUP_IDS,
      taxCertificatesReportDocumentTypes,
      taxCertificatesReportStatuses,
    }
  },
}
</script>
