<template>
  <div
    id="user-list"
    class="user-list"
  >
    <specialty-list
      v-if="!usersEmpty"
      :selected-specialty-id="selectedSID"
      :show-specialties-list="showSpecialtiesList"
      :visible-specialties="visibleSpecialties"
      @select-specialty="_specialtyOnClick"
      @toggle-show="_showSpecialtiesOnClick"
    />

    <select-entry-buttons
      v-if="!usersEmpty"
      @select-all-entries="_selectAllOnClick"
      @unselect-all-entries="_unselectAllOnClick"
    />

    <empty-users v-if="usersEmpty" />

    <ul class="specific-items-list">
      <li
        v-for="user in visibleUsers"
        :key="user.id"
        :class="{selected: selectedUIDsInSID[selectedSID].includes(user.id)}"
        :data-id="user.id"
        class="specific-item cut"
        @click="_userOnClick"
      >
        <span class="left-container cut">
          <span class="user-avatar-container">
            <user-avatar
              :user-id="user.id"
              :params="{ version: 'thumb25', tag: user.avatar_tag }"
            />
          </span>
          <span class="specific-item-info-container cut">{{ user.short_name }}</span>
        </span>
        <span
          v-if="_getWaitingListCount(user.id)"
          v-tooltip="t('waiting_list')"
          class="waiting-list-count-container"
          data-hintPosition="top-right"
          @click.stop="$emit('setWaitingListUser', user)"
        >
          {{ _getWaitingListCount(user.id) }}
        </span>
      </li>
    </ul>

    <guarded-view
      :permissions="['canManageSelfScheduleTemplateScheduleTemplateBaseModel']"
    >
      <schedule-link v-if="!usersEmpty" />
    </guarded-view>
  </div>
</template>

<script>
import EmptyUsers from '../components/empty_users.vue'
import ScheduleLink from '../components/schedule_link.vue'
import SelectEntryButtons from '../components/select_entry_buttons.vue'
import SpecialtyList from '../components/specialty_list.vue'

import globalObjectsAccessHelpers from '../../global_objects_access_helpers.js'
import core from './core.js'
import eventsMethods from './events.js'
import storeMethods from './store.js'
import watch from './watch.js'
import GuardedView from '@/vue_components/common/guarded_view'
import { mapGetters } from 'vuex'
import UserAvatar from '@/vue_components/user_avatar.vue'

export default {
  name: 'UsersList',

  components: {
    UserAvatar,
    GuardedView,
    EmptyUsers,
    ScheduleLink,
    SelectEntryButtons,
    SpecialtyList,
  },

  props: {
    contextReady: Boolean,
    tableVisibility: Boolean,

    users: {
      type: Array,
      required: true,
    },

    specialties: {
      type: Array,
      required: true,
    },

    filterUsers: Boolean,

    filteredUserIds: {
      type: Array,
      required: true,
    },

    oneVisibleUser: {
      type: Number,
      required: false,
      default: null,
    },

    showAppointment: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      showSpecialtiesList: false,
      onChangeTimeout: null,

      allSIDs: [],
      allUIDs: [],
      availableUIDs: [],
      visibleSpecialties: [],
      visibleUsers: [],
      specialtiesMap: Object.create(null),
      usersMap: Object.create(null),

      selectedSID: 0,
      selectedUIDsInSID: {},
      availableUserIds: null,
      selfFilteredUserIds: [],

      waitingListUserMap: null,
      waitingListIdsSet: new Set(),
      // данные для передачи в `setUsersAndSpecialty`,
      userToShow: null,
    }
  },

  computed: {
    usersEmpty () {
      return this.users.length === 0
    },
    ...mapGetters([
      'GET_SCHEDULE_CURRENT_CLINIC_ID',
    ]),
  },

  watch,

  mounted () {
    Services.wsSubscriptions.workTime.connect(
      (data) => this.tableVisibility && this._workTimeUpdatesHandler(data)
    )
  },

  methods: {
    ...globalObjectsAccessHelpers,
    ...eventsMethods,
    ...storeMethods,
    ...core,

    clearUsersList () {
      this.allUIDs = []
      this.usersMap = Object.create(null)
    },

    clearSpecialtyList () {
      this.allSIDs = []
      this.specialtiesMap = Object.create(null)
    },

    _onChange () {
      if (this.tableVisibility && this.contextReady) {
        this.$emit('onChange',
          this.selectedUIDsInSID[this.selectedSID].filter((uid) => this.availableUserIds.has(uid)))
      }
    },
  },
}
</script>
