import { MEntryTypesCategoriesPresenter } from '@/_api/EntryTypesCategories/MEntryTypesCategoriesPresenter'
import { mCategoriesEntryTypesAdapter } from '@/_api/EntryTypesCategories/mCategoriesEntryTypesAdapter'

export class EntryTypesCategoriesPresenter extends MEntryTypesCategoriesPresenter {
  constructor (props) {
    super(props)
    this.location = 'EntryTypesCategoriesPresenter'
  }

  /**
   * @param data
   * @param config
   * @returns {Promise<ElTreeItem[]>}
   */
  async fetchEntryTypes (data, config = {}) {
    return super.fetchEntryTypes(data, config)
      .then(mCategoriesEntryTypesAdapter.toClient)
  }
}
