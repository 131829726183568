import formatter from '@/lib/formatters/formatter'
import { request } from '@/lib/transport/request'

const prefix = gon.localization.currency.symbol_character

export function showPaymentComments (clientId) {
  request({
    type: 'POST',
    url: Routes.payments_with_comments_payments_path(),
    data: {
      client_id: clientId,
    },
  }).promise
    .then((payments) => {
      renderComments(payments)
    }).catch((error) => {
      console.error(error)
    })
}

function renderComments (payments) {
  const block = document.querySelector('.payment-comment')

  if (payments.length && block) {
    block.classList.remove('hidden')
    payments.forEach((payment) => {
      block.querySelector('ul').insertAdjacentHTML('beforeend', getCommentTemplate(payment))
    })
  }
}

const getCommentTemplate = function (payment) {
  return `<li>${formatter.formatValue(payment.created_at, ['date:localDate'])}</li>
          <li class="no_debt">${payment.deposit + prefix}</li>
          <li class="payment-comment-text">${payment.comment}</li>`
}
