import { getDateTitleHeadlessSchema } from '@/vue_present/_base/Tables/_schemaTemplates/getDateTitleHeadlessSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getListSchema = () => {
  const schema = getDateTitleHeadlessSchema()

  schema.addCreateButton()
  schema.tableSchema.addDeleteIcon()

  schema.tableSchema.headers = {
    ...schema.tableSchema.headers,
    print: new MTableHeader().addFixedWidth('30px'),
    download: new MTableHeader().addFixedWidth('30px'),
  }

  return schema
}
