import { createVueApp } from '@/vue_components/create_vue_app'
import DebtCheckerApp from '@/vue_apps/ClientsModule/components/DebtChecker/DebtCheckerApp.vue'

let vueApp = null

export const createDebtCheckerApp = (el = '#vue_correct_debt_app_root') => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'DebtCheckerRoot',
    render: (h) => h(DebtCheckerApp, {
      props: {
        clientId: gon.page.params.id,
      },
    }),
  })
}
