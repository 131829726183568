import { REQUEST_KEY } from './const'

export const getAllAdapter = {
  toServer ({offset, limit}) {
    return {
      sort_params: {
        offset,
        limit,
      },
    }
  },
}

export const defaultAdapter = {
  toServer (title) {
    return {
      [REQUEST_KEY]: {
        title,
      },
    }
  },
}
