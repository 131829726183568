<template>
  <div class="employees-schedule-modal-body">
    <div class="information">
      <div class="calendar-day">
        <div class="month">
          {{ month }}
        </div>
        <div class="day">
          <div class="date">
            {{ dayOfMonth }}
          </div>
          <div class="week-day">
            {{ weekDay }}
          </div>
        </div>
      </div>
      <div class="profile">
        <div class="full-name">
          {{ user.full_name }}
        </div>
        <div class="specialties">
          <div
            v-for="specialty in specialties"
            :key="`speciality:${specialty.id}`"
          >
            {{ specialty.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="schedule-templates">
      <div
        v-for="template in isomorphicTemplates"
        :key="`template:${template.id}`"
        class="schedule-template"
      >
        <div class="schedule-template-header">
          <div class="interval">
            <span>{{ T.interval_with }}</span>
            <span class="interval-time">{{ template.startTime }}</span>
            <span>{{ T.before }}</span>
            <span class="interval-time">{{ template.endTime }}</span>
          </div>
          <div
            class="remove-work-time"
            @click="_removeWorkingIntervalEvent(template.id)"
          >
            <i class="fad fa-trash" />
          </div>
        </div>
        <div class="schedule-template-form-body">
          <div class="schedule-template-form-item">
            <div class="schedule-template-form-left">
              <label>
                <span>{{ T.cabinet }}</span>
              </label>
            </div>
            <div class="schedule-template-form-right">
              <el-select
                v-model="template.cabinetId"
                :placeholder="T.without_cabinet"
                :no-data-text="T.no_cabinet_created"
                clearable
              >
                <el-option
                  v-for="cabinet in cabinets"
                  :key="`cabinet:${cabinet.id}`"
                  :label="cabinet.title"
                  :value="cabinet.id"
                />
              </el-select>
            </div>
          </div>
          <div class="schedule-template-form-item">
            <div class="schedule-template-form-left">
              <label>
                <span>{{ T.period }}</span>
              </label>
            </div>
            <div class="schedule-template-form-right">
              <el-time-select
                v-model="template.startTime"
                :picker-options="{ start: start, end: end, step: step, maxTime: template.endTime }"
                :clearable="false"
              />
              <div class="time-picker-divider">
                —
              </div>
              <el-time-select
                v-model="template.endTime"
                :picker-options="{ start: start, end: end, step: step, minTime: template.startTime }"
                :clearable="false"
              />
            </div>
          </div>
          <div class="schedule-template-form-item">
            <div class="schedule-template-form-left">
              <label>
                <span>{{ T.repeat }}</span>
              </label>
            </div>
            <div class="schedule-template-form-right">
              <items-list
                v-model="template.templateTypeId"
                v-bind="templateTypeProps"
              />
            </div>
          </div>
          <div
            v-if="template.templateTypeId.includes(DAY_INTERVAL_TEMPLATE)"
            class="schedule-template-form-item"
          >
            <div class="schedule-template-form-left">
              <label>
                <span>{{ T.each }}</span>
              </label>
            </div>
            <div class="schedule-template-form-right">
              <items-list
                v-model="template.templateDayIntervalId"
                v-bind="templateDayIntervalProps"
              />
            </div>
          </div>
          <template v-if="template.templateTypeId.includes(WEEK_INTERVAL_TEMPLATE)">
            <div class="schedule-template-form-item">
              <div class="schedule-template-form-left">
                <label>
                  <span>{{ T.every }}</span>
                </label>
              </div>
              <div class="schedule-template-form-right">
                <items-list
                  v-model="template.templateWeekIntervalId"
                  v-bind="templateWeekIntervalProps"
                />
              </div>
            </div>
            <div class="schedule-template-form-item">
              <div class="schedule-template-form-left">
                <label>
                  <span>{{ T.in_days }}</span>
                </label>
              </div>
              <div class="schedule-template-form-right">
                <items-list
                  v-model="template.templateSelectedDaysIds"
                  v-bind="templateDaySelectProps"
                />
              </div>
            </div>
          </template>
          <div
            v-if="onlineRecordingEnabled"
            class="schedule-template-form-item"
          >
            <div class="schedule-template-form-left">
              <label>
                <span>{{ T.client_widget.online_recording }}</span>
              </label>
            </div>
            <div class="schedule-template-form-right">
              <items-list
                v-model="template.availabilityForOnlineRecording"
                v-bind="templateAvailabilityForOnlineRecordingProps"
              />
            </div>
          </div>
          <div
            v-if="!template.templateTypeId.includes(WORK_TIME)"
            class="schedule-template-form-item"
          >
            <div class="schedule-template-form-left">
              <label>
                <span>{{ T.extendable }}</span>
              </label>
            </div>
            <div class="schedule-template-form-right">
              <items-list
                v-model="template.templateActive"
                v-bind="templateActiveProps"
              />
            </div>
          </div>
          <div
            v-show="_showExtendTemplateInput(template)"
            class="schedule-template-form-item"
          >
            <div class="schedule-template-form-left">
              <label>
                <span>{{ T.extend_to }}</span>
              </label>
            </div>
            <div
              :class="[_templateExpired(template.template) && 'template-expired']"
              class="schedule-template-form-right"
            >
              <el-date-picker
                v-model="template.propagationDate"
                :clearable="false"
                :picker-options="_getDatePickerOptions(template)"
                :format="'dd.MM.yyyy'"
                type="date"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-control-elements">
      <span
        class="add-working-interval"
        @click="_addWorkingIntervalEvent"
      >
        <i class="fad fa-plus" />
        {{ T.add_working_interval }}
      </span>
      <span
        class="add-vacation"
        @click="$emit('showAddVacationModal', { date, userId: user.id })"
      >
        <i class="fad fa-plus" />
        {{ T.add_vacation }}
      </span>
    </div>
  </div>
</template>

<script>
import ItemsList from '../../../../items_list.vue'
import TemplateFactory from '../isomorphic_templates/factory/template_factory.js'
import { workTimeFormConfiguration } from '../modal_form_config.js'
import requests from './requests.js'
import core from './core.js'
import events from './events.js'
import {
  DAY_INTERVAL_TEMPLATE,
  REMOVE_ALL,
  REMOVE_ALL_FROM_CURRENT_DATE,
  REMOVE_NONE,
  REMOVE_ONE_WORK_TIME,
  UPDATE_ALL,
  UPDATE_ALL_FROM_CURRENT_DATE,
  UPDATE_NONE,
  WEEK_INTERVAL_TEMPLATE,
  WORK_TIME,
  WORK_TIME_FACTORY,
} from '../../../const.js'

export default {
  name: 'WorkTimeModalBody',
  components: { ItemsList },
  props: {
    date: { type: String, required: true },
    month: { type: String, required: true },
    dayOfMonth: { type: String, required: true },
    weekDay: { type: String, required: true },
    user: { type: Object, required: true },
    workTimes: { type: Array, required: true },
    scheduleTemplates: { type: Array, required: true },
    scheduleTemplatesMap: { type: Object, required: true },
    onlineRecordingEnabled: { type: Boolean, required: true },
    specialties: { type: Array, required: true },
    cabinets: { type: Array, required: true },
  },
  data: () => ({
    ...workTimeFormConfiguration,
    WORK_TIME,
    DAY_INTERVAL_TEMPLATE,
    WEEK_INTERVAL_TEMPLATE,

    currentDate: new Date().setHours(0, 0, 0, 0),
    defaultDatePickerOptions: null,
    defaultPropagationDate: null,
    activeScheduleTemplateData: null,
    requestData: null,
    requiringConfirmationRequestData: [],
    activeRequiringConfirmationRequestData: null,
    isomorphicTemplates: null,
  }),
  created () {
    const moment = window.moment(this.date).subtract(1, 'days')
    const closureStartPropagationDate = moment.toDate()

    this.defaultPropagationDate = moment.clone().add(7, 'days').toDate()
    this.defaultDatePickerOptions = {
      firstDayOfWeek: gon.localization.day_week_start,
      disabledDate (time) { return time.getTime() <= closureStartPropagationDate },
    }

    const factoryOptions = {
      scheduleTemplates: this.scheduleTemplates,
      availableCabinetsIds: this.cabinets.map((cabinet) => cabinet.id),
      defaultPropagationDate: this.defaultPropagationDate,
    }

    this.templateFactory = new TemplateFactory(WORK_TIME_FACTORY, factoryOptions)

    this.isomorphicTemplates =
      this.workTimes.map((workTime) => this.templateFactory.getPersistentTemplate(workTime))
  },
  methods: {
    ...requests,
    ...core,
    ...events,

    saveChanges () {
      this.requestData = {
        batchCreate: this._prepareCreateData(),
        batchUpdate: this._prepareUpdateData(),
      }
      this._sendData()
    },

    removeTemplate (removalType) {
      if (!this.activeScheduleTemplateData) return

      switch (removalType) {
        case REMOVE_NONE:
          this.activeScheduleTemplateData = null
          this.$emit('showRemoveWorkTimeModal', { visibility: false })
          break
        case REMOVE_ONE_WORK_TIME:
          this._removeWorkTime(this.activeScheduleTemplateData.workTime.id)
          break
        case REMOVE_ALL_FROM_CURRENT_DATE:
          this._rollbackTemplateWorkTimes(REMOVE_ALL_FROM_CURRENT_DATE)
          break
        case REMOVE_ALL:
          this._rollbackTemplateWorkTimes(REMOVE_ALL)
          break
        default: throw new Error('Invalid removal type')
      }
    },

    updateTemplate (updateType) {
      if (!this.activeRequiringConfirmationRequestData) return

      switch (updateType) {
        case UPDATE_NONE: break
        case UPDATE_ALL_FROM_CURRENT_DATE:
          this.requestData.batchUpdate.push(this.activeRequiringConfirmationRequestData)
          break
        case UPDATE_ALL:
          this.activeRequiringConfirmationRequestData.apply_options.rollback_all = true
          this.requestData.batchUpdate.push(this.activeRequiringConfirmationRequestData)
          break
      }

      this.activeRequiringConfirmationRequestData = null
      this.$emit('showUpdateWorkTimeModal', { visibility: false })
      window.setTimeout(() => this._sendData(), 500)
    },
  },
}
</script>
