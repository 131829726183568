import { ICatalog } from '@/_declarations/ICatalog'
import { TDatePeriod } from '@/_declarations/TDatePeriod'
import { calendarRangeNameToPeriod, isCalendarRangeName } from '@/vue_components/calendar_ranges'
import { TFiltersProfileToClientPeriod as ToClientPeriod } from '../../interfaces/filtersTypes/shared/toClient/periods'
import {
  TFiltersProfileToClientHumanSelect as ToClientHumanSelect,
  TFiltersProfileToClientSelectMulti as ToClientSelectMulti,
  TFiltersProfileToClientSelectSingle as ToClientSelectSingle,
} from '../../interfaces/filtersTypes/shared/toClient/selects'
import { TFullOrShortNameHuman } from '@/_declarations/TFullOrShortNameHuman'
import { extractCatalog } from '@/_medods_standart_library/msl'

export class FiltersProfilesFormattersToClient {
  /* Для периода. Перевод из 'today', 'this_year' и т.д. или ['YYYY-MM-DD', 'YYYY-MM-DD'] */
  static formatPeriod (calendarRangeNameOrDatePeriod: ToClientPeriod): TDatePeriod {
    return isCalendarRangeName(calendarRangeNameOrDatePeriod)
      ? calendarRangeNameToPeriod(calendarRangeNameOrDatePeriod as string)
      : calendarRangeNameOrDatePeriod
  }

  /* Для значений селекторов */
  static formatSingle (itemInArray: ToClientSelectSingle): ICatalog {
    if (itemInArray) { return extractCatalog(itemInArray[0]) }
  }

  /* Для значений мультиселекторов */
  static formatMulti (items: ToClientSelectMulti): ICatalog[] {
    if (items) { return items.map(extractCatalog) }
  }

  /* Для значений селекторов, где выбираются людишки */
  static formatHuman (humanInArray: ToClientHumanSelect): TFullOrShortNameHuman {
    if (!humanInArray) { return }

    const [{ id, shortName, fullName }] = humanInArray

    return shortName
      ? { id, shortName }
      : { id, fullName }
  }
}
