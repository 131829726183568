<template>
  <div
    class="form-group boolean optional"
    :class="{ 'f-readonly': disabled }"
  >
    <label class="boolean optional">
      <input
        type="checkbox"
        class="boolean optional checkbox"
        :checked="checked"
        @change="onChange"
      >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    label: {
      type: String,
      default: '',
    },

    checked: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onChange () {
      if (this.disabled) return
      this.$emit('change', !this.checked)
    },
  },
}
</script>
