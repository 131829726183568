<template>
  <div class="inline-block">
    <modal
      in-body
      modal-class="relations-modal"
      :modal-visibility="visibility"
      modal-size="md"
      @close="visibility = false"
    >
      <template #header>
        <span class="fad fa-chart-network" />
        {{ t('legal_rep_relations') }}
      </template>
      <template #body>
        <div
          v-loading="loading"
          class="relations-list__container"
        >
          <div v-if="!loading && !legalReps.length">
            {{ t('no_legal_representatives') }}
          </div>
          <div v-else>
            <span class="prompt-notice">{{ t('egisz.components.legal_rep_relations_modal.subtitle_prompt') }}</span>
            <ul
              v-if="legalReps.length"
              class="relations-list"
            >
              <li
                v-for="lr in legalReps"
                :key="lr.id"
                class="relations-list__item"
              >
                <span>{{ legalRepName(lr) }}</span>
                <el-select
                  :value="loading ? null : getRelation(lr.id)"
                  @change="setRelation(lr.id, $event)"
                >
                  <el-option
                    v-for="def in relationDefinitions"
                    :key="def.id"
                    :value="def.id"
                    :label="def.title"
                  />
                </el-select>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template #footer-left>
        <button
          type="button"
          class="btn btn-sm btn-link"
          @click="loadRelativeTypes"
        >
          <span>{{ t('egisz.components.legal_rep_relations_modal.refresh_catalog') }}</span>
        </button>
      </template>

      <template #footer-right>
        <button
          class="btn btn-sm btn-success btn-with-icon"
          type="button"
          :disabled="!hasRelations"
          @click="sendRelations"
        >
          <i class="fad fa-save btn-with-icon_icon" />
          <span>{{ t('save') }}</span>
        </button>
      </template>
    </modal>

    <slot name="reference">
      <button
        v-if="canManage"
        v-tooltip="t('legal_rep_relations')"
        class="btn btn-primary btn-xs input-button"
        :disabled="disabled"
        @click="visibility = true"
      >
        <i class="fad fa-chart-network" />
      </button>
    </slot>
  </div>
</template>

<script>
import { clientsEndpoint } from '@/api_entities/clients/clients_endpoint'
import formatter from '@/lib/formatters/formatter'
import Modal from '@/vue_components/modal'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default {
  name: 'LegalRelationsModal',
  components: { Modal },
  mixin: [SpinnerHolder],
  props: {
    /**
     * @type {import('vue').PropOptions<Array<{id: string, name: string}>>}
     */
    legalReps: {
      type: Array,
      default: () => [],
    },

    relationsLoading: Boolean,
    canManage: Boolean,
    disabled: Boolean,
  },

  data () {
    return {
      relationDefinitions: [],
      relationsMap: {},
      loading: false,
      visibility: false,
    }
  },

  computed: {
    hasRelations () {
      return Object.keys(this.relationsMap).length > 0
    },
  },

  watch: {
    legalReps (to) {
      if (!to) { return }
      this.relationsMap = this.getRelationsMap()
    },
  },

  mounted () {
    this.loadRelativeTypes()
  },

  methods: {
    loadRelativeTypes () {
      this.loading = true

      return clientsEndpoint.getRelativeTypes()
        .then((defs) => {
          this.relationDefinitions = defs
        })
        .catch(Utils.reportError(
          'legalReps:loadRelativeTypes()',
          t('egisz.messages.errors.request.netrika_load_catalog_error')
        ))
        .finally(() => { this.loading = false })
    },

    legalRepName (legalRep) {
      return formatter.formatValue({
        name: legalRep.name,
        surname: legalRep.surname,
        secondName: legalRep.second_name || legalRep.secondName,
      }, ['client:fullName'])
    },

    getRelationsMap () {
      return this.legalReps.reduce((map, lr) => {
        map[lr.id] = lr

        return map
      }, {})
    },

    getRelation (legalId) {
      return this.relationsMap[legalId] && this.relationsMap[legalId].relation_type_id
    },

    setRelation (legalRepresentativeId, relationTypeId) {
      this.relationsMap[legalRepresentativeId].relation_type_id = relationTypeId
      this.relationsMap[legalRepresentativeId].relation_type_title =
          this.getRelationDefinitionTitle(relationTypeId)
    },

    getRelationDefinitionTitle (relationTypeId) {
      const relation = this.relationDefinitions.find((item) => item.id === relationTypeId)

      return relation
        ? relation.title
        : ''
    },

    sendRelations () {
      this.visibility = false
      const legalRelations = Object.values(this.relationsMap)
      this.$emit('setLegalRelations', legalRelations)
    },
  },
}
</script>
