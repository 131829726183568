import { BALANCE_REFRESH_BUTTON_MS_TIME } from '@/modules/sms/constants.js'

export default function general () {
  const $form = $('#sms_configuration_form')
  const $checkAuthBtn = $('#check_auth_btn')
  const $refreshBalanceBtn = $('#refresh_balance_btn')
  const $balanceValue = $form.find('.balance_value')
  const $login = $('#sms_configuration_login')
  const $password = $('#sms_configuration_password')
  const $submit = $form.find('#sms_settings_submit').eq(0)
  const $balanceNotification = $('#sms_configuration_balance_notification')
  const $balanceNotificationHideableFields = $('.balance-notification-hideable-field')
  let cachedLogin, cachedPassword,
    cachedAuthResult, cachedError

  const toggleAuthorization = () => {
    const isAuthFieldsEmpty = !$login.val() || !$password.val()
    $checkAuthBtn.prop('disabled', isAuthFieldsEmpty)
  }

  const toggleBalanceButton = () => {
    const isNotAuthorized = (!$login.val() || !$password.val()) && !cachedAuthResult
    $refreshBalanceBtn.prop('disabled', isNotAuthorized)
  }

  const toggleBalanceHideableFields = () => {
    // $remindFields.toggleClass('hidden', !$remindCb.prop('checked'))
    $balanceNotificationHideableFields.toggleClass('hidden', !$balanceNotification.prop('checked'))
  }

  const showBox = () => {
    const message = cachedError || t('sms.auth_ok')
    bootbox.alert(message)
  }

  const refreshInfoAjax = (onSuccess) => {
    $.ajax({
      method: 'GET',
      url: Routes.info_sms_configurations_path(),
      data: {
        sms_configuration: {
          login: $login.val(),
          password: $password.val(),
        },
      },
      success (data) {
        cachedAuthResult = data.authorization
        cachedError = data.error
        onSuccess(data)
      },
      error: (err) => console.log(err),
    })
  }

  const updateBalance = (data) => {
    $refreshBalanceBtn.loadSpin('stop')
    $refreshBalanceBtn.prop('disabled', true)
    setTimeout(() => {
      $refreshBalanceBtn.prop('disabled', !cachedAuthResult)
    }, BALANCE_REFRESH_BUTTON_MS_TIME)
    let balanceText
    if (cachedAuthResult) {
      balanceText = data.balance + ' ' + (data.currency_sign || gon.application.currency.symbol_character)
    } else {
      balanceText = t('sms.please_authorize')
    }

    $balanceValue.text(balanceText)
  }

  const checkAuth = () => {
    if (cachedLogin === $login.val() &&
      cachedPassword === $password.val()) {
      showBox()
    } else {
      $checkAuthBtn.loadSpin('start')
      $login.prop('disabled', true)
      $password.prop('disabled', true)
      refreshInfoAjax((data) => {
        cachedLogin = $login.val()
        cachedPassword = $password.val()
        updateBalance(data)
        $login.prop('disabled', false)
        $password.prop('disabled', false)
        $checkAuthBtn.loadSpin('stop')
        showBox()
      })
    }
  }

  const refreshBalance = () => {
    $refreshBalanceBtn.loadSpin('start')
    refreshInfoAjax(updateBalance)
  }

  $form.remote_form({
    model: 'sms_configuration',
    beforeSend () {
      if (!$password.val().length) {
        $password.attr('disabled', 'disabled')
      }
    },
    onSuccess (res) {
      $submit.loadSpin('stop')
      $password.attr('disabled', false)
      Notificator.info(I18n.t('sms.configuration_updated'))
    },
    onError (err) {
      Notificator.error(T.check_correctness_filing_form)
      console.log(err)
      $submit.loadSpin('stop')
      $password.attr('disabled', false)
    },
  })

  toggleAuthorization()
  toggleBalanceButton()
  toggleBalanceHideableFields()

  $login.on('input', toggleAuthorization)
  $password.on('input', toggleAuthorization)
  $balanceNotification.on('change', toggleBalanceHideableFields)
  $checkAuthBtn.click(checkAuth)
  $refreshBalanceBtn.click(refreshBalance)
  $('#sms_configuration_send_from_time').inputmask('hh:mm')
  $('#sms_configuration_send_to_time').inputmask('hh:mm')
}
