export const entryTypesTreeRenderContent = (h, { node, data }) => {
  const iconClass = data.childNodes || data.children
    ? node.expanded
      ? 'fa-folder-open warning'
      : 'fa-folder warning'
    : data.icon

  return h(
    'span',
    { class: 'entry-types-tree-node cut' },
    [
      h('i', { class: `entry-types-tree-node__icon fad fa-fw mr-5 ${iconClass}` }),
      h('span', { class: 'entry-types-tree-node__title', directives: [{ name: 'tooltip', value: data.title }] }, data.title),
    ]
  )
}
