import { createClientSearchFilterApp } from '@/vue_apps/TrashBinsModule/ClientSearchFilter'

$document.on('rez/trash_bins/orders', function () {
  $('.order_restore_btn').click((e) => {
    e.preventDefault()

    bootbox.confirmYN(`${T.restore} ?`, function (approved) {
      if (!approved) return
      const id = $(e.target).data('id')

      $.ajax({
        url: Routes.trash_bins_order_restore_path(id),
        method: 'GET',
        success () {
          $(e.target).parents('tr').remove()
        },
        error () {
          Notificator.error(T.unable_to_recover_order)
        },
      })
    })
  })
})

$document.on('rez/trash_bins/loggings', () => createClientSearchFilterApp())
