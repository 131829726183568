<template>
  <si-table-generator
    :table-schema="tableSchema"
    :items="entryTypes"
    hide-headers
    hide-scrollbars
    use-not-found-result-small
  >
    <template
      v-for="entryType in entryTypes"
      #tr-row="{ item: entryType }"
    >
      <div
        :key="`:AppointmentCallLogPopupEntryTypes:${uuid}:${entryType.id}`"
        v-tooltip="entryType.title"
        class="col col__row"
      >
        <div class="cut">
          {{ entryType.number }}
        </div>
        <div class="cut">
          {{ entryType.title }}
        </div>
      </div>
    </template>
  </si-table-generator>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import SiTableGenerator from '@/vue_components/sort_items/SiTableGenerator/SiTableGenerator.vue'

export default {
  name: 'AppointmentCallLogPopupEntryTypes',
  components: { SiTableGenerator },
  props: {
    /** @type {TAppointmentCallLogEntryType[]} */
    entryTypes: PropsTypes.Array(),
  },

  data () {
    return {
      uuid: Utils.newGUID(),
      tableSchema: {
        options: { empty: true },
        headers: { row: '' },
      },
    }
  },
}
</script>
