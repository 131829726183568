import Vue from 'vue/dist/vue.esm'
import Appointments from '@/vue_components/reports/doctor_appointments.vue'
import { defineDateFormat, fetchExportFile } from './export.js'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import { MAX_LIMIT } from '@/specific/reports/const'
import { createFiltersProfilesAppForLegacy } from '@/vue_apps/FiltersProfilesApp/index'
import { FILTERS_PROFILES_TYPES } from '@/vue_apps/FiltersProfilesApp/const/consts'
import { doctorAppointmentsReportFiltersAccessors as filtersAccessor } from './doctorAppointments/filtersAccessor'
import { doctorAppointmentsReportFiltersSelectors as filtersSelectors } from './doctorAppointments/filtersSelectors'
import { removeFilterValidationWrapper, showFiltersErrors } from '@/vue_apps/FiltersProfilesApp/helpers/legacyErrorsRenderingHelpers'
import { saveDoctorAppointmentsReportInitialFilters as saveInitialFilters } from './doctorAppointments/initialFilters'

$document.on('rez/reports/doctor_appointments', function () {
  Report.init()
  saveInitialFilters()

  $('#report_form').on('change', removeFilterValidationWrapper)

  createFiltersProfilesAppForLegacy({
    filtersSetter ({ filters, errors }) {
      filtersAccessor.set(filters)
      showFiltersErrors(errors, filtersSelectors)
    },
    filtersGetter: filtersAccessor.get,
    profileType: FILTERS_PROFILES_TYPES.DOCTOR_APPOINTMENTS_REPORT,
  })

  const tableHeader = [
    { title: t('custom_date') },
    { title: t('custom_time') },
    { title: t('status') },
    { title: t('patient') },
    { title: t('phone') },
    { title: t('services') },
    { title: t('doctor') },
    { title: t('note') },
  ]

  const reportTable = new Vue({
    el: '#report_right_container',
    components: { Appointments },
    mixins: [ReportWrapper],
    data: () => ({
      filters: filtersAccessor.get(),
    }),
    template: `<appointments
      ref="report"
      :isHidden="isHidden"
      v-bind="filters"
    />`,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(filtersAccessor.get())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', function () {
    const filters = filtersAccessor.get()

    const data = {
      dateFormat: defineDateFormat(),
      csvComma: gon.application.csv_character,
      floatingComma: gon.localization.currency.decimal_character,
      headerRow: {
        appointmentDate: tableHeader[0].title,
        appointmentTime: tableHeader[1].title,
        appointmentStatus: tableHeader[2].title,
        clientFullName: tableHeader[3].title,
        clientPhone: tableHeader[4].title,
        entryTypeTitles: tableHeader[5].title,
        doctorFullName: tableHeader[6].title,
        appointmentNote: tableHeader[7].title,
      },
      appointmentStatus: {
        needApproval: T.enums.appointment.status.need_approval,
        confirmedByAdministrator: T.enums.appointment.status.confirmed_by_administrator,
        approved: T.enums.appointment.status.approved,
        arrived: T.enums.appointment.status.arrived,
        serviced: T.enums.appointment.status.serviced,
        billed: T.enums.appointment.status.billed,
        paid: T.enums.appointment.status.paid,
        canceled: T.enums.appointment.status.canceled,
        failed: T.enums.appointment.status.failed,
      },
      offset: 0,
      limit: MAX_LIMIT,
      report: {
        period: filters.period,
      },
      ...ReportFiltersFactory.defineFor('appointments', filters),
    }

    fetchExportFile(data, Routes.report_doctor_appointments_path(), T.reports.appointments)
  })
})
