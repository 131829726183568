import {
  REMOVE_ALL,
  REMOVE_ALL_FROM_CURRENT_DATE,
  UPDATE_ALL,
  UPDATE_ALL_FROM_CURRENT_DATE,
} from '../const.js'

export default {
  showScheduleTemplateModalHandler (userId) {
    this.$refs.scheduleTemplateModal.initialSelectors = {
      userId: parseInt(userId),
    }
    this.showScheduleTemplateModal = true
  },

  showExtendScheduleTemplatesModalHandler () {
    const extendableTemplateIds = new Set()
    let maxPropagationDate = window.moment('1970-01-01')

    this.getSelectedUserRows().forEach((id) => {
      const sidCidUid = id.split('_')
      const userTemplates = this.scheduleGridGraph.schedule_templates[sidCidUid[2]]
      let cabinetId
      let lastPropagationDate

      if (userTemplates) {
        userTemplates.forEach((template) => {
          cabinetId = template.cabinet_id === null ? -1 : template.cabinet_id
          if (template.active && (cabinetId === parseInt(sidCidUid[1], 10))) {
            lastPropagationDate = window.moment(template.last_propagation_end_date)
            maxPropagationDate = lastPropagationDate.isAfter(maxPropagationDate)
              ? lastPropagationDate : maxPropagationDate
            extendableTemplateIds.add(template.id)
          }
        })
      }
    })

    const closureStartPropagationDate = maxPropagationDate.toDate()

    this.extendScheduleTemplateModalData = {
      templateIds: Array.from(extendableTemplateIds),
      defaultDate: maxPropagationDate.clone().add(6, 'days').toDate(),
      datePickerOptions: {
        disabledDate (time) { return time.getTime() <= closureStartPropagationDate },
      },
    }

    this.showExtendScheduleTemplateModal = true
  },

  showConfirmationModalHandler (visibility, field) {
    if (visibility && field) {
      this.confirmationModalData.field = field
    }

    this.showConfirmationModal = visibility
  },

  showRemoveWorkTimeModalHandler ({visibility, startTime, endTime, startDate, date}) {
    if (visibility) {
      const headerMessage = T.remove_interval
      const noEvent = () => { this.showConfirmationModal = false }

      this.removeWorkTimeModalData = {
        startTime,
        endTime,
        startDate,
        date,
        formattedDate: this.formatDate(date),
      }

      this.confirmationModalData = {
        [REMOVE_ALL]: {
          headerMessage,
          message: T.delete_all_interval_occurrences,
          yesEvent: () => this.$refs.workTimeModal.getCore().removeTemplate(REMOVE_ALL),
          noEvent,
        },
        [REMOVE_ALL_FROM_CURRENT_DATE]: {
          headerMessage,
          message: T.delete_all_interval_occurrences_with.format(this.formatDate(date)),
          yesEvent: () => this.$refs.workTimeModal.getCore().removeTemplate(REMOVE_ALL_FROM_CURRENT_DATE),
          noEvent,
        },
      }
    } else {
      this.showConfirmationModalHandler(false)
      this.removeWorkTimeModalData = Object.create(null)
      this.confirmationModalData = Object.create(null)
    }
    this.showRemoveWorkTimeModal = visibility
  },

  showUpdateWorkTimeModalHandler ({visibility, startTime, endTime, startDate, date}) {
    if (visibility) {
      const headerMessage = T.change_interval
      const noEvent = () => { this.showConfirmationModal = false }
      const formattedStartDate = this.formatDate(startDate)
      const formattedDate = this.formatDate(date)

      this.updateWorkTimeModalData = {
        startTime,
        endTime,
        date,
        startDate,
        formattedStartDate,
        formattedDate,
      }

      this.confirmationModalData = {
        [UPDATE_ALL]: {
          headerMessage,
          message: T.change_all_interval_occurrences,
          yesEvent: () => this.$refs.workTimeModal.getCore().updateTemplate(UPDATE_ALL),
          noEvent,
        },
        [UPDATE_ALL_FROM_CURRENT_DATE]: {
          headerMessage,
          message: `${T.change_all_interval_occurrences_with} ${formattedDate}?`,
          yesEvent: () => this.$refs.workTimeModal.getCore().updateTemplate(UPDATE_ALL_FROM_CURRENT_DATE),
          noEvent,
        },
      }
    } else {
      this.showConfirmationModalHandler(false)
      this.updateWorkTimeModalData = null
      this.confirmationModalData = null
    }
    this.showUpdateWorkTimeModal = visibility
  },

  showAddVacationModalHandler ({date, userId}) {
    const momentDate = window.moment(date)
    const initialStartDate = momentDate.toDate()
    const initialEndDate = momentDate.clone().add(6, 'days').toDate()

    this.addVacationModalData = {
      defaultDate: date,
      dateRange: [initialStartDate, initialEndDate],
      userId,
    }
    this.showAddVacationModal = true
  },

  showRemoveVacationModalHandler ({userId, vacationId, date}) {
    const vacation = this.scheduleGridGraph.vacations[userId].find((vacation) => vacation.id === vacationId)

    this.removeVacationModalData = {
      vacation,
      date,
    }
    this.showRemoveVacationModal = true
  },

  closeScheduleTemplateModals () {
    this.showConfirmationModal = false
    this.showWorkTimeModal = false
    this.showUpdateWorkTimeModal = false
    this.showRemoveWorkTimeModal = false
    this.showExtendScheduleTemplateModal = false
  },
}
