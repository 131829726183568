import { ReportParamFactory } from '@/specific/reports/shared'

export default function (source) {
  return {
    clinicIds: ReportParamFactory.buildArrayParam(source.clinic),
    doctorId: source.doctor.id,
    entryWithOrder: source.entryWithOrder.id,
    includingDiscounts: source.includingDiscounts.id,
    reportType: source.reportType.id,
  }
}
