import { MNSIPresenter } from '@/_api/AppConfiguration/MNSIPresenter'
import { cloneDeep, pick } from 'lodash'

/**
 * @implements INSIConfiguration
 */
export class NSIConfiguration {
  /**
   * @type {string[]}
   * @private
   */
  _permit = [
    'idCardTypes',
    'russianSubjects',
    'nsiUserKey',
  ]

  /**
   * @type {MNSIPresenter}
   * @private
   */
  _presenter = new MNSIPresenter()

  idCardTypes = {
    enabled: false,
    updatedAt: '-',
    version: '-',
  }

  russianSubjects = {
    enabled: false,
    updatedAt: '-',
    version: '-',
  }

  nsiUserKey = null

  _fillFields (data) {
    if (!data || data.errors) { return }

    this.idCardTypes = cloneDeep(data.idCardTypes.value || data.idCardTypes)
    this.idCardTypes.updatedAt = this.idCardTypes.updatedAt
      ? Utils.getFormattedDate(this.idCardTypes.updatedAt, Utils.momentDateTimeFormat)
      : t('nsi.errors.catalogNotDownloaded')

    this.russianSubjects = cloneDeep(data.russianSubjects.value || data.russianSubjects)
    this.russianSubjects.updatedAt = this.russianSubjects.updatedAt
      ? Utils.getFormattedDate(this.russianSubjects.updatedAt, Utils.momentDateTimeFormat)
      : t('nsi.errors.catalogNotDownloaded')
    this.nsiUserKey = data.nsiUserKey
  }

  async load () {
    return this._presenter.fetchConfig()
      .then((config) => { this._fillFields(config) })
  }

  async save () {
    return this._presenter.update(pick(this, this._permit))
  }

  async ping () {
    return this._presenter.ping()
  }
}
