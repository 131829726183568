<template>
  <si-field>
    <!-- Столбец: клиника -->
    <div class="col col__clinicTitle">
      {{ t('clinic') }}
    </div>

    <!-- Столбец: дата+время -->
    <div class="col col__dateTime">
      {{ t('common.date_time') }}
    </div>

    <!-- Столбец: докторФИО+специальности-->
    <div class="col col__doctorNameWithSpecialties">
      {{ t('doctor') }}
    </div>

    <!-- Столбец: Ф\nИО клиента -->
    <div class="col col__clientData">
      {{ t('client') }}
    </div>

    <!-- Столбец: телефон клиента -->
    <div class="col col__phone">
      {{ t('phone') }}
    </div>

    <!-- Столбец: статус аппоинтмента -->
    <div class="col col__status">
      <m-icon
        v-tooltip="t('status')"
        icon="needApproval"
        color="grey-dark"
      />
    </div>

    <!-- Столбец: статус звонка аппоинтмента -->
    <div class="col col__callStatus">
      <m-icon
        v-tooltip="t('activerecord.attributes.appointment.call_confirmation_status')"
        icon="pending"
        color="grey-dark"
      />
    </div>

    <!-- Столбец: примечание -->
    <div class="col col__note" />

    <!-- Столбец: список услуг -->
    <div class="col col__entryTypes" />

    <!-- Столбец: найти в расписании -->
    <div class="col col__findAppointment" />

    <!-- Столбец: редактировать апоинтмент -->
    <div class="col col__editAppointment" />
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'AppointmentCallLogTableHeader',
  components: { MIcon, SiField },
}
</script>
