import { SALARY_REPORT_TYPE } from '@/vue_apps/ReportsModule/SalaryReports/const/const'

export const getExportHeaders = (reportType) => {
  switch (reportType) {
    case SALARY_REPORT_TYPE.SALARY:
      return {
        doctorFullName: t('user'),
        goodsSalary: t('goods'),
        analysisSalary: t('entry_analyzes'),
        servicesSalary: t('services'),
        totalSalary: t('total'),
      }
    case SALARY_REPORT_TYPE.DOCTOR_SALARY:
      return {
        entryNumber: t('code'),
        entryTitle: t('title'),
        entryDate: t('custom_date'),
        orderId: t('order'),
        clientFullName: t('client'),
        entrySum: t('sum'),
        salaryEntryRate: t('reports.rate'),
        salaryEntrySum: t('reports.salary'),
      }
    case SALARY_REPORT_TYPE.DOCTOR_SUMMARY_SALARY:
      return {
        entryTypeNumber: t('code'),
        entryTypeTitle: t('title'),
        entryAmount: t('amount'),
        entrySum: t('sum'),
        salaryEntryRate: t('reports.rate'),
        salaryEntrySum: t('reports.salary'),
      }
    case SALARY_REPORT_TYPE.SALARY_BY_PAYMENTS:
      return {
        doctorFullName: t('user'),
        goodsSalary: t('goods'),
        analysisSalary: t('analyzes'),
        servicesSalary: t('services'),
        totalSalary: t('reports.total'),
      }
    case SALARY_REPORT_TYPE.DOCTOR_SALARY_BY_PAYMENTS:
      return {
        entryTypeNumber: t('code'),
        entryTypeTitle: t('title'),
        entryAmount: t('reports.amountInOrder'),
        entryPaidSum: t('reports.paidSum'),
        salaryEntryRate: t('reports.rate'),
        salaryEntrySum: t('reports.salary'),
      }
    case SALARY_REPORT_TYPE.DOCTOR_SALARY_BY_PAYMENTS_DETAILED:
      return {
        entryTitle: t('title'),
        entryDate: t('reports.entryDate'),
        orderId: t('reports.order'),
        orderDate: t('reports.orderDate'),
        clientFullName: t('client'),
        entryFinalSum: t('reports.priceWithDiscount'),
        entryAmount: t('reports.amountInOrder'),
        entrySum: t('reports.sum'),
        entryPaidSum: t('reports.paidSum'),
        lastPaymentId: t('reports.lastPayment'),
        lastPaymentDate: t('reports.lastPaymentDate'),
        lastPaymentTotalPaid: t('reports.lastPaymentTotalPaid'),
        lastPaymentKind: t('reports.lastPaymentKind'),
        lastPaymentCashierFullName: t('reports.lastPaymentUser'),
        paidSum: t('reports.sum'),
        salaryEntryRate: t('reports.rate'),
        salaryEntrySum: t('reports.salary'),
      }
    case SALARY_REPORT_TYPE.SALARY_BY_PAYMENTS_DETAILED:
      return {
        doctorFullName: t('user'),
        entryTitle: t('title'),
        entryDate: t('reports.entryDate'),
        orderId: t('reports.order'),
        orderDate: t('reports.orderDate'),
        clientFullName: t('client'),
        entryFinalSum: t('reports.priceWithDiscount'),
        entryAmount: t('reports.amountInOrder'),
        entrySum: t('reports.sum'),
        lastPaymentId: t('reports.lastPayment'),
        lastPaymentDate: t('reports.lastPaymentDate'),
        lastPaymentTotalPaid: t('reports.lastPaymentTotalPaid'),
        lastPaymentKind: t('reports.lastPaymentKind'),
        lastPaymentCashierFullName: t('reports.lastPaymentUser'),
        paidSum: t('reports.sum'),
        salaryEntryRate: t('reports.rate'),
        salaryEntrySum: t('reports.salary'),
        totalPaid: t('reports.totalPaid'),
      }
  }
}
