import FormatterInitializer from '@/modules/document_types/formatter_initializer.js'
import AnalysisOrderHandler from '@/vue_components/analysis_orders/analysis_order_handler.js'
import { VALID_WORD_ELEMENTS } from '@/plugins/dynamic_forms/tinymce_helpers'
import { createNSIConfigurationApp } from '@/vue_apps/app_configuration/NSI'

$document.on('rez/app_configurations', function () {
  const branch = $('#app_configuration_branch')
  const branchComponents = $('#branch_components')
  const BRANCH_CUSTOM = '30'

  branch.on('change', function () {
    branchComponents.hide()
    if (branch.val() === BRANCH_CUSTOM) {
      branchComponents.show()
      if (initialBranch !== BRANCH_CUSTOM) {
        branchComponents.find('.f-checkbox').each(function (i, e) {
          e.checked = true
        })
      }
    }
  })

  var initialBranch = branch.val()
  branch.change()
  $('#logo_upload_button').on('click', function () {
    $('#app_configuration_logo').click()
  })

  $('#app_configuration_form').remote_form({
    model: 'app_configuration',
    onSuccess (res) {
      Notificator.info(T.configuration_updated)
      $('#app_configuration_submit').loadSpin('stop')
    },
  })

  $('#logo-delete-btn').on('click', function () {
    $.ajax({
      method: 'POST',
      url: '/app_configurations/delete_logotype',
      success () {
        $('#logo-preview').html('')
        $('#logo-delete-btn').addClass('hidden')
      },
      error () {
        console.log('error')
      },
    })
  })

  $('#fileupload').fileupload({
    url: '/app_configurations/1.json',
  })

  const showRegistrationResult = function (text) {
    bootbox.alert(text, function () {
      // Turbolinks.visit(Routes.app_configurations_path())
      location.reload()
    })
  }

  const register = $('#register_application')
  register.on('click', function () {
    $.ajax({
      url: '/app_configurations/register',
      method: 'post',
      success (data) {
        showRegistrationResult('success')
      },
      error (data) {
        console.log(data)
        let str = ''
        const json = data.responseJSON
        if (json.body) str += json.body.join(' ')
        if (json.details) str += ';' + json.details.join(' ')
        showRegistrationResult(str)
      },
    })
  })
})

$document.on('rez/app_configurations/edit', function () {
  const isHeaderPage = $('#app_configuration_header').length
  const isLaboratoriesPage = $('#laboratories').length
  const isDifferentPage = $('#different').length

  const stylizeMce = () => {
    $("span:contains(t('variables_1'))").parent().addClass('mce-green')
    $("span:contains(t('insert_logo_1'))").parent().addClass('mce-green')
    $('.mce-i-image').parent().addClass('mce-green')
  }
  tinymce.remove()

  if (isLaboratoriesPage) {
    $('#app_configuration_laboratory_price_update_time').inputmask('hh:mm')
    AnalysisOrderHandler.configuration()
  }

  if (isHeaderPage) {
    let variablesListLoaded = false
    let tmceLoaded = false

    const formatterInitializer = new FormatterInitializer({
      url: Routes.app_configurations_set_attribute_list_path(),
      editorName: 'app_configuration_header',
      onLoad () {
        variablesListLoaded = true
        if (tmceLoaded) formatterInitializer.visualize()
      },
    })

    $('#app_configuration_form').remote_form({
      model: 'app_configuration',
      onSuccess: () => formatterInitializer.visualize(),
      onError: () => formatterInitializer.visualize(),
    })

    $('#app_configuration_submit').click((e) => {
      const invalidVariables = formatterInitializer.checkForInvalidVariables().map((v) => v.join('.')).join(', ')
      if (invalidVariables) {
        e.preventDefault()
        Notificator.error(`${T.invalid_variables}: ${invalidVariables}`)
      } else {
        formatterInitializer.originalize()
      }
    })

    tinymce.init({
      selector: '#app_configuration_header',
      browser_spellcheck: true,
      paste_word_valid_elements: VALID_WORD_ELEMENTS,
      height: 250,
      theme: 'modern',
      language: gon.localization.locale,
      statusbar: false,
      plugins: ['print preview fullscreen table image visualblocks code'],
      toolbar1: 'undo redo | logo variables | fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | fullscreen preview code |',
      image_advtab: true,
      file_browser_callback (field_name, url, type, win) {
        win.document.getElementById(field_name).value = gon.application.logo_path
      },
      images_upload_url: Routes.text_editor_clinic_header_path(),
      table_default_attributes: Utils.tinymceTableDefaults,
      relative_urls: false,
      convert_urls: false,
      file_picker_types: 'image',
      fontsize_formats: '8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 24px 36px',
      lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2.0',
      setup (editor) {
        editor.addButton('variables', {
          text: t('variables_1'),
          icon: false,
          onclick: () => formatterInitializer.show(),
        })
        editor.addButton('logo', {
          text: t('insert_logo_1'),
          icon: false,
          onclick () {
            if (gon.application.clinic_logo_exist) {
              editor.insertContent('<img class="logo-clinics-in-header" src="' + Routes.logo_clinic_path(gon.application.current_clinic.id) + '"  id="application-logo" alt="" height="100" />')
            } else bootbox.alert(t('first_upload_clinic_logo'))
          },
        })
      },
      init_instance_callback (editor) {
        stylizeMce()
        tmceLoaded = true
        if (variablesListLoaded) formatterInitializer.visualize()
        // crutch display clinic logo
        if (gon.application.clinic_logo_exist) {
          $('#app_configuration_header_ifr')
            .contents()
            .find('.logo-clinics-in-header')
            .attr('src', Routes.logo_clinic_path(gon.application.current_clinic.id))
        }
      },
      content_css: ['/tinymce_custom.css'],
      pagebreak_separator: '<div class="mce-pagebreak"></div>',
    })
  } else {
    $('.add_client_to_vip').inputmask('currency', {
      prefix: '',
      groupSeparator: ' ',
      rightAlign: false,
      jitMasking: true,
      autoUnmask: true,
    })

    $('.add_client_to_black_list').inputmask('integer', { mask: '9', rightAlign: false })

    tinymce.init({
      selector: '#app_configuration_order_print_text',
      browser_spellcheck: true,
      paste_word_valid_elements: VALID_WORD_ELEMENTS,
      height: 250,
      theme: 'modern',
      statusbar: false,
      language: gon.localization.locale,
      relative_urls: false,
      table_default_attributes: Utils.tinymceTableDefaults,
      images_upload_url: '/tinymce_assets',
      plugins: ['print preview fullscreen table visualblocks code'],
      toolbar1: 'undo redo | logo variables | fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | fullscreen preview code |',
      fontsize_formats: '8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 24px 36px',
      lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2.0',
      content_css: ['/tinymce_custom.css'],
      pagebreak_separator: '<div class="mce-pagebreak"></div>',
    })

    tinymce.init({
      selector: '#app_configuration_order_print_text_bottom',
      browser_spellcheck: true,
      paste_word_valid_elements: VALID_WORD_ELEMENTS,
      height: 250,
      theme: 'modern',
      table_default_attributes: Utils.tinymceTableDefaults,
      statusbar: false,
      plugins: ['print preview fullscreen table visualblocks code'],
      toolbar1: 'undo redo | logo variables | fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | fullscreen preview code |',
      fontsize_formats: '8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 24px 36px',
      lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2.0',
      content_css: ['/tinymce_custom.css'],
      pagebreak_separator: '<div class="mce-pagebreak"></div>',
    })

    tinymce.init({
      selector: '#app_configuration_variable_string_in_treatment_plan',
      browser_spellcheck: true,
      paste_word_valid_elements: VALID_WORD_ELEMENTS,
      height: 250,
      theme: 'modern',
      statusbar: false,
      language: gon.localization.locale,
      relative_urls: false,
      table_default_attributes: Utils.tinymceTableDefaults,
      images_upload_url: '/tinymce_assets',
      plugins: ['print preview fullscreen table visualblocks code'],
      toolbar1: 'undo redo | logo variables | fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | fullscreen preview code |',
      fontsize_formats: '8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 24px 36px',
      lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2.0',
      content_css: ['/tinymce_custom.css'],
      pagebreak_separator: '<div class="mce-pagebreak"></div>',
    })
  }

  if (isDifferentPage) {
    const assignBonusPointsConfig =
      $('#app_configuration_assign_bonus_points')
    const commentForAssignBonusPointsRequiredConfig =
      $('#app_configuration_comment_for_assign_bonus_points_required')

    const setDisabledEl = (el, val) => {
      el.attr('disabled', !val)
    }

    setDisabledEl(
      commentForAssignBonusPointsRequiredConfig,
      assignBonusPointsConfig.attr('checked')
    )

    assignBonusPointsConfig.on('change', (event) => {
      setDisabledEl(
        commentForAssignBonusPointsRequiredConfig,
        event.target.checked
      )
    })
  }
})

const WHEEL_BANNED_ELEMENTS =
  '#app_configuration_number_of_licenses_for_clinics, #app_configuration_lic_count, #app_configuration_client_widget_accessible_clinics_count'
$document.on('rez/app_configurations/edit', () => {
  const partial = gon.page.params.partial

  if (partial === 'nsi') {
    createNSIConfigurationApp()
  }

  if (partial !== 'developer') { return }

  $(WHEEL_BANNED_ELEMENTS).on('wheel', (event) => {
    event.stopPropagation()
  })
})
