<template>
  <m-si-generator
    id="report_right_container"
    v-loading="report.loading"
    :items="report.data"
    :total="report.total"
    :si-generator-schema="report.siSchema"
    :nfr-text="t('reports.reportNfr')"
    :current-page="report.currentPage"
    :page-count="report.totalPages"
    :nfr-filtered="report.filtered"
    :nfr-can-reset-filters="false"
    @update:currentPage="onCurrentPageChange"
    @onSortChange="onSortChange"
    @onItemClick="onItemClick"
  >
    <template #tableCaption>
      <div class="mb-indent-small">
        {{ report.filtersString }}
      </div>
    </template>
  </m-si-generator>
</template>

<script>
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { SalaryReport } from '@/vue_apps/ReportsModule/SalaryReports/store/SalaryReport'
import { SALARY_REPORT_TYPE } from '@/vue_apps/ReportsModule/SalaryReports/const/const'

const REPORT_TYPES_SUMMARY_COMMON = [SALARY_REPORT_TYPE.SALARY, SALARY_REPORT_TYPE.SALARY_BY_PAYMENTS]

export default {
  name: 'SalaryReportContainer',
  components: { MSiGenerator },

  props: {
    report: { type: SalaryReport, required: true },
  },

  methods: {
    onSortChange (sorting) {
      this.report.setSorting(sorting)
      this.report.fetchAll()
    },

    onCurrentPageChange (page) {
      this.report.setPage(page)
      this.report.fetchAll()
    },

    /**  @param {SalaryReportItem} item */
    onItemClick (item) {
      if (!REPORT_TYPES_SUMMARY_COMMON.includes(this.report.filters.reportType)) { return }
      this.$parent.onItemClick(item)
    },
  },
}
</script>
