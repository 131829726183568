import { MCategoriesPresenter } from '@/_api/MCategories/MCategoriesPresenter'
import { camelToSnake } from '@/_api/_requests/helpers'
import { GLOBAL_CATEGORY_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'

export class ProtocolsCategoriesPresenter extends MCategoriesPresenter {
  constructor () {
    super()
    this.location = 'ProtocolsCategoriesPresenter'
  }

  fetchNode (id) {
    return this.post({
      url: Routes.template_records_categories_path(),
      data: camelToSnake({
        id,
        categoryType: GLOBAL_CATEGORY_KINDS.TEMPLATE_RECORDS,
      }),
    }).promise
  }
}
