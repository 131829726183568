<template>
  <div
    class="sidebar-left-container hidden-print"
    :class="{ 'sidebar-left-container--collapsed': !GET_SHOW_LEFT_PANEL_STATE }"
  >
    <panel-heading-collapsed
      direction="right"
      :show="!GET_SHOW_LEFT_PANEL_STATE"
      :title="activeScheduleInfo.title"
      :icon="activeScheduleInfo.icon"
      class="sidebar-left-collapsed"
      @panel-collapsed-toggle="setShowLeftPanelStore(true)"
    />

    <panel-heading
      v-show="GET_SHOW_LEFT_PANEL_STATE"
      slim
      :title="activeScheduleInfo.title"
      :icon="activeScheduleInfo.icon"
      class="sidebar-left-panel"
    >
      <template v-slot:buttons>
        <span
          class="fad fa-angle-left"
          @click="setShowLeftPanelStore(false)"
        />
      </template>

      <entry-search
        :filter="filter"
        :filtered-user-ids="filteredUserIds"
        @change-filter="changeFilter"
        @clear-filter="$emit('clear-filter-entries')"
      />

      <div class="user-cabinet-switch-control">
        <button
          class="switch-to-users"
          :class="{'selected-switch-button': IS_ACTIVE_SCHEDULE_DOCTORS}"
          @click="switchToUsersList"
        >
          <span class="fad fa-fw fa-user-md" />
          <span class="button-text">{{ t('doctors') }}</span>
        </button>
        <button
          class="switch-to-cabinets"
          :class="{'selected-switch-button': IS_ACTIVE_SCHEDULE_CABINETS}"
          @click="switchToCabinetsList"
        >
          <span class="fad fa-fw fa-door-open" />
          <span class="button-text">{{ t('cabinets') }}</span>
        </button>
      </div>

      <users-list
        v-show="IS_ACTIVE_SCHEDULE_DOCTORS"
        :context-ready="contextReady"
        :table-visibility="IS_ACTIVE_SCHEDULE_DOCTORS"
        :users="users"
        :specialties="specialties"
        :filter-users="filterUsers"
        :filtered-user-ids="filteredUserIds"
        :one-visible-user="oneVisibleUser"
        :show-appointment="showAppointment"
        @onChange="$emit('update:userIds', $event)"
        @setWaitingListUser="setWaitingListUser"
        @waitingListCount="$emit('update:waitingCount', $event)"
      />

      <cabinets-list
        v-show="IS_ACTIVE_SCHEDULE_CABINETS"
        :context-ready="contextReady"
        :table-visibility="IS_ACTIVE_SCHEDULE_CABINETS"
        :users="users"
        :cabinets="cabinets"
        :specialties="specialties"
        :available-cabinets="availableCabinets"
        :filter-cabinets="filterCabinents"
        :filtered-user-ids="filteredUserIds"
        :one-visible-cabinet="oneVisibleCabinet"
        @onChange="$emit('update:cabinetIds', $event)"
        @update:userIds="$updateSync('userIds', $event)"
      />
    </panel-heading>
  </div>
</template>

<script>
import CabinetsList from './cabinets_list/cabinets_list.vue'
import EntrySearch from './components/entry_search.vue'
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import PanelHeadingCollapsed
  from '@/vue_components/common/panel_heading_collapsed.vue'
import UsersList from './users_list/users_list.vue'

import { mapActions, mapGetters, mapState } from 'vuex'
import {
  ACTIVE_SCHEDULE_INFO,
  CABINET_SCHEDULE,
  DOCTOR_SCHEDULE,
  SIDEBAR_WAITING_LIST,
} from './consts.js'

export default {
  name: 'LeftSidebar',
  components: {
    CabinetsList,
    EntrySearch,
    PanelHeading,
    PanelHeadingCollapsed,
    UsersList,
  },

  props: {
    contextReady: Boolean,

    currentUserId: {
      type: Number,
      required: false,
      default: null,
    },

    users: {
      type: Array,
      required: true,
    },

    cabinets: {
      type: Array,
      required: true,
    },

    specialties: {
      type: Array,
      required: true,
    },

    filter: Boolean,

    filteredUserIds: {
      type: Array,
      required: true,
    },

    userIds: {
      type: Array,
      required: true,
    },

    cabinetIds: {
      type: Array,
      required: true,
    },

    availableCabinets: {
      type: Array,
      required: true,
    },

    oneVisibleCabinet: {
      type: Number,
      required: false,
      default: null,
    },

    oneVisibleUser: {
      type: Number,
      required: false,
      default: null,
    },

    showAppointment: {
      type: Object,
      required: true,
    },

    waitingCount: {
      type: Object,
      required: true,
    },

    waitingUserFromGrid: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapState({
      scheduleUsers: (state) => state.doctorSchedules.usersList,
    }),
    ...mapGetters([
      'GET_ACTIVE_SCHEDULE',
      'GET_SHOW_LEFT_PANEL_STATE',
      'IS_ACTIVE_SCHEDULE_DOCTORS',
      'IS_ACTIVE_SCHEDULE_CABINETS',
    ]),

    activeScheduleInfo () {
      switch (this.GET_ACTIVE_SCHEDULE) {
        case DOCTOR_SCHEDULE: return ACTIVE_SCHEDULE_INFO.DOCTOR
        case CABINET_SCHEDULE: return ACTIVE_SCHEDULE_INFO.CABINET
        default: return ACTIVE_SCHEDULE_INFO.DOCTOR
      }
    },

    filterUsers () {
      return this.IS_ACTIVE_SCHEDULE_DOCTORS && this.filter
    },

    filterCabinents () {
      return this.IS_ACTIVE_SCHEDULE_CABINETS && this.filter
    },
  },

  watch: {
    /**
     * @param {number} userId
     */
    waitingUserFromGrid (userId) {
      if (userId) {
        this.setWaitingListUser(this.scheduleUsers.find(({ id }) => id === userId))
      }
    },
  },

  methods: {
    ...mapActions([
      'setActiveScheduleStore',
      'setShowLeftPanelStore',
      'setActiveSidebarStore',
      'setShowRightPanelStore',
    ]),

    switchToCabinetsList () {
      this.setActiveScheduleStore(CABINET_SCHEDULE)
    },

    switchToUsersList () {
      this.setActiveScheduleStore(DOCTOR_SCHEDULE)
    },

    /**
     * @param {object} user
     */
    setWaitingListUser (user) {
      if (user !== this.waitingUserFromGrid) {
        this.$emit('update:waitingUserFromGrid', null)
      }
      this.setActiveSidebarStore(SIDEBAR_WAITING_LIST)
      this.setShowRightPanelStore(true)
      this.$store.commit('waitingList/SET_USER', user)
    },

    changeFilter (value) {
      this.$emit('update:filter', true)
      this.$emit('update:filteredUserIds', value)
    },
  },
}
</script>
