<template>
  <modal
    id="waiting-edit-modal"
    :modal-visibility="visibility"
    :modal-header-buttons="modalButtonsAll"
    modal-class="modal-xl modal-header-orange"
    @close="close"
  >
    <template #header>
      <span class="fad fa-tasks" />
      {{ t('waiting_list') }}
    </template>

    <template #body>
      <epic-spinner :visibility="loading" />
      <div class="appointment-form-flex d-flex">
        <client-form
          :strict="!clientFormDisabled"
          :disabled="clientFormDisabled"
          :errors="validationMessages"
          :sexes="[...SEXES_VALUES]"
          :online-access-options="[...ALLOWED_PROHIBITED_FORM]"
          :client-groups="clientGroups"
          :clinic-id="form.waitingItem.currentClinicId"
          :second-name.sync="form.client.secondName"
          :name.sync="form.client.name"
          :surname.sync="form.client.surname"
          :birthdate.sync="form.client.birthdate"
          :phone.sync="form.client.phone"
          :sex.sync="form.client.sex"
          :entry-online-access.sync="form.client.onlineAccess"
          :groups.sync="form.client.groups"
          :email="form.client.email"
          :treating-doctor.sync="form.client.treatingDoctor"
          :personal-discount.sync="form.client.personalDiscount"
          :legal-representatives.sync="legalRepresentatives"
          :send-data-to-egisz.sync="form.client.sendDataToEgisz"
          :snils.sync="form.client.snils"
          :additional.sync="form.client.additional"
          :patient-card-number.sync="form.client.patientCardNumber"

          @update:surname="setClientSearchParam('surname', $event)"
          @update:secondName="setClientSearchParam('second_name', $event)"
          @update:name="setClientSearchParam('name', $event)"
          @update:phone="setClientSearchParam('phone', $event)"
          @update:email="form.client.email = $event; validationMessages.email = []"
          @add-legal-representative="$emit('add-legal-representative')"
          @request-demo="showDemoClient"
          @phone-mask-settings-change="clientPhoneMaskSettings = $event"
        >
          <template #betterCallSaul>
            <m-icon
              v-tooltip="t('contactWithPatient')"
              :data-client-id="form.clientId"
              :data-phone="form.client.phone"
              class="pointer phone_number"
              icon="betterCallSaul"
              color="primary"
            />
          </template>
        </client-form>

        <div class="appointment-form-middle block-header-orange">
          <appointment-similar-clients
            :clients="similarClients.clients"
            :selected="similarClients.selected"
            @update:selected="selectClientFromSearch"
          />

          <appointment-client-summary
            :client-id="formData.clientSummary.clientId"
            :medical-record-id="formData.clientSummary.medicalRecordId"
            :paid-sum="formData.clientSummary.paidSum"
            :credit-debt="formData.clientSummary.creditDebt"
            :last-visit="formData.clientSummary.lastVisit"
            :previous-appointment-failed="formData.clientSummary.previousAppointmentFailed"
            :last-visit-to-the-same-doctor-days-ago="formData.clientSummary.lastVisitToTheSameDoctorDaysAgo"
            :last-visit-to-the-same-doctor="formData.clientSummary.lastVisitToTheSameDoctor"
            :adv-distribution="formData.clientSummary.advDistribution"
            :client-groups="formData.clientSummary.clientGroups"
            :client-group-black-list="formData.clientSummary.clientGroupBlackList"
            :client-group-vip="formData.clientSummary.clientGroupVip"
            :active-medical-policies-titles="formData.clientSummary.activeMedicalPoliciesTitles"
          />

          <client-service-card
            v-if="formData.clientSummary.clientId"
            :disabled="clientFormDisabled"
            :content.sync="form.client.serviceCard"
          />
        </div>

        <waiting-list-form
          :disabled="!$security.canManageWaitingList"
          :validation-messages="validationMessages"
          :clinics-array="GET_CURRENT_USER_CLINICS_LIST"
          :date.sync="form.date"
          :current-doctor.sync="form.waitingItem.currentDoctor"
          :current-clinic-id.sync="form.waitingItem.currentClinicId"
          :note.sync="form.waitingItem.note"
          :is-urgent.sync="form.waitingItem.isUrgent"
          :has-depend-appointment.sync="form.waitingItem.hasDependAppointment"
          :created-by="form.waitingItem.createdBy"
          :updated-by="form.waitingItem.updatedBy"
          @load-more-doctors="$emit('load-more-doctors')"
        />
      </div>
    </template>
    <template #footer-left>
      <guarded-view :permissions="['canManageAppointment']">
        <button
          type="button"
          class="btn btn-danger btn-sm modal-delete border-0"
          @click="askDestroy"
        >
          {{ t('delete') }}
        </button>
      </guarded-view>
    </template>
    <template #footer-right>
      <guarded-control
        v-if="showCreateAppointment"
        tag="button"
        :permissions="['canManageAppointment']"
        :disabled="buttonsDisabled"
        class="btn btn-success btn-with-icon modal-save"
        type="button"
        @click:allowed="createAppointment"
      >
        <span class="btn-with-icon_icon fad fa-fw fa-plus" />
        <span class="btn-with-icon_text">
          {{ t('make_appointment') }}
        </span>
      </guarded-control>
      <guarded-control
        tag="button"
        :permissions="['canManageWaitingList']"
        :disabled="buttonsDisabled"
        class="btn btn-success btn-with-icon modal-save"
        type="button"
        @click:allowed="submit"
      >
        <span class="btn-with-icon_icon fad fa-save" />
        <span class="btn-with-icon_text">
          {{ t('save') }}
        </span>
      </guarded-control>
      <guarded-control
        v-if="showMoveAppointment"
        tag="button"
        :permissions="['canManageWaitingList', 'canManageAppointment']"
        :disabled="buttonsDisabled"
        class="btn btn-success btn-with-icon modal-save"
        type="button"
        @click:allowed="moveAppointment"
      >
        <span class="btn-with-icon_icon fad fa-fw fa-plus" />
        <span class="btn-with-icon_text">
          {{ t('reception.move_appointment') }}
        </span>
      </guarded-control>
      <guarded-control
        tag="button"
        :permissions="['canManageClient']"
        :disabled="!clientFormDisabled"
        class="btn btn-warning btn-with-icon modal-save"
        type="button"
        @click:allowed="editClient"
      >
        <span class="btn-with-icon_icon fad fa-fw fa-pencil" />
        <span class="btn-with-icon_text">
          {{ t('reception.edit_client') }}
        </span>
      </guarded-control>
      <button
        class="btn btn-primary btn-with-icon modal-close"
        type="button"
        @click="close"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">
          {{ t('close') }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>
import formatter from '@/lib/formatters/formatter'
import { birthdate, required } from '@/lib/validators/rules'
import { createPhoneValidator, requiredValidator } from '@/lib/validators/validators'
import { MODE_WAITING_LIST_INSERT, MODE_WAITING_LIST_MOVE } from '@/plugins/schedule/scheduleCommon/const'
import AppointmentClientSummary from '@/vue_components/appointment/appointment_client_summary.vue'
import AppointmentSimilarClients from '@/vue_components/appointment/appointment_similar_clients.vue'
import WaitingListForm from '@/vue_components/appointment/waiting_list_form/waiting_list_form.vue'
import ClientForm from '@/vue_components/client/client_form.vue'
import { ClientFormConsumer } from '@/vue_components/client/client_form_consumer'
import ClientServiceCard from '@/vue_components/client/client_service_card.vue'
import { SimilarClientsConsumer } from '@/vue_components/client/similar_clients_consumer'
import GuardedControl from '@/vue_components/common/guarded_control'
import GuardedView from '@/vue_components/common/guarded_view'
import { clientAdapter } from '@/vue_components/doctor_schedules/adapters'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import { CONFIRMATION_MODES } from '@/vue_components/mixins/modals/const'
import { ModalConsumer } from '@/vue_components/mixins/modals/modal_consumer'
import { ValidationHolder } from '@/vue_components/mixins/validationHolder'
import Modal from '@/vue_components/modal.vue'

import { mapGetters } from 'vuex'
import { ALLOWED_PROHIBITED_FORM, INSERT_APPOINTMENT_TYPES, SEXES_VALUES } from '../consts.js'
import {
  getDefaultFormClient,
  getDefaultFormClientSummary,
  getDefaultFormWaitingItem,
  getDefaultValidationsWaitingList,
} from '../methods/appointment_default_data.js'
import { clientEndpoint } from '@/api_entities/client/clients_endpoint.js'
import { waitingListEndpoint } from '../methods/waiting_list_methods.js'
import { fetchWaitingListItem } from '../rest.js'
import { validateSnils } from '@/vue_components/doctor_schedules/doctor_schedules_methods'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

/**
 * Данные форм в модалку передаются через метод openEditWaitingItemForm.
 * Открыть и заполнить модалку данными можно руками
 * или через миксину WaitingEditModalConsumer.
 * @see {WaitingEditModalConsumer}
 */
export default {
  components: {
    MIcon,
    GuardedView,
    GuardedControl,
    AppointmentClientSummary,
    AppointmentSimilarClients,
    ClientForm,
    ClientServiceCard,
    EpicSpinner,
    Modal,
    WaitingListForm,
  },

  mixins: [SimilarClientsConsumer, ClientFormConsumer, ValidationHolder, ModalConsumer],

  inject: ['showConfirmation', 'hideConfirmation'],

  props: {
    visibility: Boolean,
    clientGroups: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withCreateAppointment: Boolean,
    withMoveAppointment: Boolean,
  },

  data () {
    return {
      loading: false,
      clientEdited: false,
      clientTouched: false,
      clientPhoneMaskSettings: { ...Services.phoneMask.defaultMaskSettings },

      formData: {
        similarClients: {
          clients: [],
          selected: null,
        },

        clientSummary: { ...getDefaultFormClientSummary() },
      },

      form: {
        date: '',
        clientId: null,

        client: { ...getDefaultFormClient() },
        waitingItem: { ...getDefaultFormWaitingItem() },
      },

      validationMessages: getDefaultValidationsWaitingList(),

      ALLOWED_PROHIBITED_FORM,
      SEXES_VALUES,
    }
  },

  computed: {
    ...mapGetters([
      'GET_SCHEDULE_CURRENT_CLINIC_ID',
      'GET_CURRENT_USER_CLINICS_LIST',
      'GET_LOCALIZATION_DATE_FORMAT',
    ]),

    showMoveAppointment () {
      return this.withMoveAppointment && this.form.waitingItem.hasDependAppointment
    },

    showCreateAppointment () {
      return this.withCreateAppointment && !this.form.waitingItem.hasDependAppointment
    },

    legalRepresentatives: {
      get () {
        return this.$store.getters['waitingList/GET_LEGAL_REPS']
      },
      set (value) {
        this.form.client.legalRepresentatives = value
        this.$store.commit('waitingList/SET_LEGAL_REPS', value)
      },
    },

    clientFormDisabled () {
      return !this.clientEdited ||
        this.disabled ||
        this.loading
    },

    buttonsDisabled () {
      return Object
        .values(this.validationMessages)
        .some((el) => el.length > 0) || this.disabled
    },
  },

  watch: {
    'form.client.phone' (newValue) {
      const minPhoneLength = this.clientPhoneMaskSettings.length
      this.validate('phone', newValue, createPhoneValidator(minPhoneLength))
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
    },
    'form.client.surname' (newValue) {
      this.validate('surname', newValue, requiredValidator)
    },
    'form.client.secondName' () {
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
    },
    'form.client.name' (newValue) {
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
      if (!this.forcedClientEdit) return

      this.validate('name', newValue, requiredValidator)
    },
    'form.client.birthdate' (newValue) {
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
      if (!this.forcedClientEdit) return

      this.validateRules('birthdate', newValue, [birthdate, required], {
        required: t('activerecord.required.text'),
        birthdate: t('incorrect_birthdate'),
      })
    },
    'form.client.sex' (newValue) {
      if (!this.forcedClientEdit) return

      this.validate('sex_id', newValue, requiredValidator)
    },

    'form.client.snils' (newValue) {
      this.validate('snils', newValue, requiredValidator)
    },

    'form.date' (newValue) {
      this.validate('date', newValue, requiredValidator)
    },
    'form.waitingItem.currentDoctor' (newValue) {
      this.validate('user_id', newValue, requiredValidator)
    },
    'form.client.patientCardNumber' () {
      this.validationMessages.patient_card_number = []
    },
    clientsSearch: {
      deep: true,
      handler (newValue) {
        if (
          !this.similarClients.selected &&
          !this.form.clientId &&
          (newValue.surname.length > 2 ||
            newValue.name.length > 2 ||
            newValue.second_name.length > 2 ||
            newValue.phone.length > 2)
        ) {
          this.findSimilarClients(newValue)
        }
      },
    },
  },

  methods: {
    editClient () {
      this.forcedClientEdit = true
      this.clientEdited = true
    },

    selectClientFromSearch (client) {
      this.similarClients.selected = client
      this.forcedClientEdit = false
    },

    showDemoClient () {
      Utils.fakeForms.client()
        .then((client) => {
          this.similarClients.clients = []
          this.setDemoClient(client, 'form.client')
        })
    },
    /**
     * Заполняет формы данными записи в листе ожидания.
     * @param item
     */
    openEditWaitingItemForm ({ id, client_id: clientId, user_id: userId, date }) {
      this.startLoading()
      const itemRequest = fetchWaitingListItem(id)
        .then((waitingItem) => {
          this.form = {
            waitingItemId: waitingItem.id,
            date: moment(waitingItem.date).format(this.GET_LOCALIZATION_DATE_FORMAT),
            clientId: waitingItem.client_id,
            client: { ...clientAdapter.toClient(waitingItem.client) },
            waitingItem: {
              currentDoctor: waitingItem.user,
              currentClinicId: waitingItem.clinic_id,
              note: waitingItem.additional,
              isUrgent: waitingItem.urgent,
              hasDependAppointment: waitingItem.depended_appointment,
              createdBy: waitingItem.created_by,
              updatedBy: waitingItem.updated_by,
              appointmentId: waitingItem.appointment_id,
            },
          }
          this.$store.commit('waitingList/SET_LEGAL_REPS', this.form.client.legalRepresentatives)

          Services.telephony.reset()
        })
        .catch(Utils.reportError('fetchWaitingListItem'))

      const summaryParams = {
        client: { id: clientId },
        date,
        user_id: userId,
      }
      const summaryRequest = clientEndpoint.getSummary(summaryParams)
        .then((response) => {
          this.formData.clientSummary = response

          this.form.client.groups = response.clientGroups.map((group) => group.id)
        })
        .catch(Utils.reportError('fetchClientSummaryInfo'))
      Promise.all([itemRequest, summaryRequest])
        .then(() => {
          this.$store.commit('appointment/SET_MOVED_APPOINTMENT', { ...this.form })
        })
        .finally(() => {
          this.stopLoading()
        })
    },

    createAppointment () {
      this.$store.commit('SET_MODE_GRID_SCHEDULE', MODE_WAITING_LIST_INSERT)
      this.$store.commit('SET_INSERT_PARAMS_APPOINTMENT', {
        type: INSERT_APPOINTMENT_TYPES.WAITING,
        clientId: this.form.clientId,
      })

      Utils.URL.silentSetQueryParam('insert_client', this.form.clientId)
      Utils.URL.silentSetQueryParam('waiting_list_item_id', this.form.waitingItemId)

      this.close()
    },

    moveAppointment () {
      this.$store.dispatch('startMovingAppointment', {
        appointmentId: this.form.waitingItem.appointmentId,
        mode: MODE_WAITING_LIST_MOVE,
      })
      this.close()
    },

    submit () {
      if (!validateSnils(
        { form: this.form, validationMessages: this.validationMessages },
        this.setValidationMessages
      )) { return }

      let request = Promise.resolve()

      this.startLoading()

      if (this.form.clientId) {
        if (this.clientEdited) {
          request = clientEndpoint.update(this.form.clientId, {
            ...this.form.client,
            ...{ legalRepresentatives: this.legalRepresentatives },
          })
        }
      } else {
        request = clientEndpoint.create({
          ...this.form.client,
          ...{ legalRepresentatives: this.legalRepresentatives },
        })
          .then((response) => {
            this.form.clientId = response.id
          })
      }

      request
        .then(() => waitingListEndpoint.update({
          id: this.form.waitingItemId,
          date: this.form.date,
          clientId: this.form.clientId,
          ...this.form.waitingItem,
        }))
        .then(this.onEditSuccess)
        .catch((error) => this.setValidationMessages(this.validationMessages, error.responseJSON))
        .finally(this.stopLoading)
    },

    askDestroy () {
      const clientName = formatter.formatValue(this.form.client, ['user:name'])
      this.showConfirmation(
        t('delete_client_from_waiting_list_body', [clientName]),
        this.destroy,
        this.hideConfirmation,
        t('confirm_delete'),
        CONFIRMATION_MODES.DESTROY
      )
    },

    destroy () {
      this.startLoading()
      waitingListEndpoint.destroy(this.form.waitingItemId)
        .then(() => {
          this.close()
          Notificator.success(t('record_successfully_deleted'))
        })
        .catch(Utils.reportError('waiting_edit_modal:destroy'))
        .finally(this.stopLoading)
    },

    clearForm () {
      Object.assign(this.$data, this.$options.data())
      this.$store.dispatch('waitingList/resetForm')
      this.$nextTick(() => {
        this.validationMessages = getDefaultValidationsWaitingList()
      })
    },

    close () {
      this.$emit('close')
      this.clientTouched = false
      this.clientEdited = false

      this.clearForm()
    },

    onEditSuccess () {
      this.close()
      Notificator.success(t('record_successfully_updated'))
      this.$store.commit('SET_MODE_GRID_SCHEDULE', $.scheduleGrid.MODE_DEFAULT)
    },

    startLoading () {
      this.loading = true
    },

    stopLoading () {
      this.loading = false
    },
  },
}
</script>
