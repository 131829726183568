import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import MTable from '@/vue_present/_base/Tables/MTable/MTable.vue'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import {
  getDentalOrderIcon,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DentalOrdersTab/const/dentalOrdersIcons'

export class DentalOrderListItem extends MListServiceItem {
  /**
   * @param {{
   *   customNumber: string,
   *   date: string,
   *   dueDate: string,
   *   id: number,
   *   status: string,
   * }} data
   */
  constructor (data) {
    super()
    this.id = data.id
    this.number = new MTableCell(data.id)

    this.customNumber = new MTableCell(data.customNumber)
      .addTooltip(true)
      .addCut()

    this.date = new MTableCell(vueFilters.date(data.date))

    this.dueDate = new MTableCell(vueFilters.date(data.dueDate))

    const icon = getDentalOrderIcon(data.status)
    this.statusIcon = new MTableCell()
      .addTypeIcon(icon.icon)
      .addClasses(icon.css)

    this.status = new MTableCell(t(data.status, { scope: 'enums.dental_order.status' }))
  }
}
