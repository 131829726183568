import { createVueApp } from '@/vue_components/create_vue_app'
import EntryTypesSearchAdd from '@/vue_present/Reuse/EntryTypes/EntryTypesSearchAdd/EntryTypesSearchAdd.vue'

let vueApp = null

/**
 * @param {string?} el
 * @param {{
 *   entryTypeLegacyCallback?: Function,
 *   entryPackLegacyCallback?: Function,
 *   useSearch: Boolean,
 *   useEntryTypes: Boolean,
 * }} options
 */
export const createEntryTypeSearchAdd =
  (el = '#vue_entry_types_search_add', options = {}) => {
    if (vueApp?.destroy) { vueApp.destroy() }

    vueApp = createVueApp({
      el,
      name: 'EntryTypesSearchAddApp',
      render: (h) => h(EntryTypesSearchAdd, {
        props: {
          entryTypeLegacyCallback: options.entryTypeLegacyCallback,
          entryPackLegacyCallback: options.entryPackLegacyCallback,
          useSearch: options.useSearch,
          useEntryTypes: options.useEntryTypes,
          forCurrentUser: false,
        },
      }),
    })
  }
