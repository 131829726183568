import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { getStateIcon } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/const/getStateIcon'
import { GLOBAL_ENTRY_STATE, GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { iconsMap } from '@/modules/utils/medods_ui/iconsMap'

/**
 * @typedef {{
 * amount: number,
 * date: string,
 * finalSum: string,
 * id: number,
 * kind: number,
 * state: number,
 * title: string,
 * teethNumbers: string,
 * number: number,
 * orderId: number,
 * }} ClientEntriesListItemRaw
 */

export class EntriesListItem extends MListServiceItem {
  /** @param {ClientEntriesListItemRaw} data */
  constructor (data) {
    super()
    this.id = data.id

    this.date = new MTableCell(vueFilters.date(data.date))

    this.teethNumbers = new MTableCell(data.teethNumbers)
      .addTooltip(true)
      .addCut()

    this.amount = new MTableCell(data.amount)

    this.title = new MTableCell(data.title)
      .addTooltip(
        [
          data.number,
          data.title,
          vueFilters.currency(data.finalSum),
        ].filter(Boolean).join(', ')
      )
      .addCut()

    this.setEditProtocol(data.kind)
    this.setState(data.state, data.kind)
    this.setDeletable(data.orderId)
  }

  setEditProtocol (kind) {
    this.editProtocol = new MTableCell()
      .addTooltip(t('edit_protocol'))
      .addTypeIcon('protocol')
      .addClasses('primary')
      .addPreventDefault()

    if (kind === GLOBAL_ENTRY_TYPES_KINDS.GOOD) {
      this.editProtocol.addInvisibility()
    }
  }

  setState (state, kind) {
    this.state = state

    const icon = getStateIcon(state)
    this.stateIcon = new MTableCell()
      .addTypeIcon(icon.icon)
      .addClasses(icon.css)
      .addTooltip(icon.tooltip)
      .addPreventDefault()

    if (parseInt(gon.page.params.entry_id) === this.id) {
      this.__drawStatus()
    }

    if (!kind) { return }

    if (
      kind === GLOBAL_ENTRY_TYPES_KINDS.GOOD ||
      (kind === GLOBAL_ENTRY_TYPES_KINDS.ANALYSIS && !gon.specific.entryAnalysesStatusEnabled) ||
      (kind !== GLOBAL_ENTRY_TYPES_KINDS.ANALYSIS && !gon.specific.entryServicesStatusEnabled)
    ) {
      this.stateIcon.addInvisibility()
    }
  }

  setDeletable (orderId) {
    this.__deletable = !orderId
    this.__notDeletableReason = t('destroying_entry_with_related_order_prohibited')
  }

  /**
   * Синхронизация статуса с информацией об энтре (другой блок)
   * @private
   * @deprecated
   */
  __drawStatus () {
    const iconFA = iconsMap[this.stateIcon.value]
    const text = this.state === GLOBAL_ENTRY_STATE.READY
      ? t('ready')
      : t('not_ready')
    const colorCss = this.state === GLOBAL_ENTRY_STATE.READY
      ? 'success'
      : 'grey'

    const element = document.querySelector('#entry_emk_status')
    if (!element) { return }
    element.innerHTML = `<i class="fad ${iconFA} ${colorCss}"></i> ${text}`
  }
}
