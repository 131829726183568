const FORBIDDEN_ROLES = ['full_rights', 'director']

export const useRolesAdapter = (roles, authorized) =>
  roles.map((role) => {
    const disabled = !authorized && FORBIDDEN_ROLES.includes(role.name)
    const tooltip = disabled && t('not_authorized_role')

    return {
      ...role,
      disabled,
      tooltip,
    }
  })
