export default {

  _selectAllOnClick () {
    this.selectedUIDsInSID[this.selectedSID] = this.visibleUsers.map((user) => user.id)
    this._updateStore()
    this._onChange()
  },

  _unselectAllOnClick () {
    this.selectedUIDsInSID[this.selectedSID] = []
    this._updateStore()
    this._onChange()
  },

  _specialtyOnClick (event) {
    this.selectedSID = parseInt(event.currentTarget.attributes['data-id'].value, 10)
    this._setVisibleUsers()
    this._setAvailableUserIds()
    this._updateStore()
    this._onChange()
  },

  _userOnClick (event) {
    const id = parseInt(event.currentTarget.attributes['data-id'].value, 10)
    const selectedUsers = this.selectedUIDsInSID[this.selectedSID]
    const index = selectedUsers.indexOf(id)

    if (index === -1) {
      selectedUsers.push(id)
    } else {
      selectedUsers.splice(index, 1)
    }

    this._updateStore()
    this._onChange(false)
  },

  _showSpecialtiesOnClick () {
    this.showSpecialtiesList = !this.showSpecialtiesList
    this._setVisibleSpecialties()
    this._updateStore()
  },
}
