import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

/**
 * @return {TMSiGeneratorSchema}
 */
export const getEntryTypesTransferSchema = () => {
  return new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addCheckboxes()
        .addHeaders({
          tableTitle: new MTableHeader(t('entry_title')),
          tablePrice: new MTableHeader(t('price')).addFixedWidth('120px'),
          tableErrors: new MTableHeader()
            .addFixedWidth('40px'),
          tableDuplicated: new MTableHeader()
            .addTypeIcon('warningCircle')
            .addFixedWidth('40px')
            .addTooltip(t('transferWarning'))
            .addClasses('warning'),
        })
    )
}
