import { getSalaryReportDefaultFiltersValues as getDefaultFilters } from './getDefaultFilters'

let filtersValues = null

/**
 * БЛ: право "Только своя зарплата"
 */
const getUserFilter = () => {
  const name = Services.security.canSalaryReport
    ? 'userFilter'
    : 'userOnlyCurrentFilter'

  const value = filtersValues[name]

  return { name, vBind: { value, items: Utils.ternary(value, [value], []), mFixedHeight: false, fullWidth: true } }
}

/** @type {() => TReportFilter[]} */
export const getSalaryReportFilters = () => {
  if (!filtersValues) { filtersValues = getDefaultFilters() }

  const userFilter = getUserFilter()

  return [
    { name: 'clinicFilter', vBind: { mFixedHeight: false, fullWidth: true } },
    { name: 'periodFilter', vBind: { mFixedHeight: false, fullWidth: true, valueFormat: 'yyyy-MM-dd', value: filtersValues.periodFilter } },
    userFilter,
    { name: 'reportSourceFilter', vBind: { value: filtersValues.reportSourceFilter, mFixedHeight: false, fullWidth: true, css: 'mt-10' } },
    { name: 'includingDiscountsFilter', vBind: { value: filtersValues.includingDiscountsFilter } },
    { name: 'entryWithOrderFilter', vBind: { value: filtersValues.entryWithOrderFilter } },
    { name: 'reportTypeFilter', vBind: { value: filtersValues.reportTypeFilter, mFixedHeight: false, fullWidth: true, css: 'mt-10' }, hidden: userFilter.name === 'userFilter' },
  ]
}
