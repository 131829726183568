$document.on('rez/discount_systems/show', function () {
  const $delete = $('#delete-record')

  if (!$delete.length) {
    throw new Error('Delete button with id "delete-button" should exist.')
  }

  const { id, title } = gon.specific.discount_system

  $delete.deleteButton({
    url: Routes.discount_system_path(id),
    redirect: Routes.discount_systems_path(),
    name: title,
  })
})

$document.on('rez/discount_systems/new', function () {
  const form = FormFactory.build('discount_system')
  form.newItem()
})

$document.on('rez/discount_systems/edit', function () {
  const form = FormFactory.build('discount_system')
  form.editItem()
})
