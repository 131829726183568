const createDefaultCabinet = () => ({
  id: null,
  title: '',
})

const getValidationMessages = () => ({
  title: [],
})

export const creators = Object.freeze({
  formDataCreator: createDefaultCabinet,
  validationMessagesCreator: getValidationMessages,
})
