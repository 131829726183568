<template>
  <div class="flex flex-column w-100">
    <input
      :value="medicalPolicyId || ''"
      type="hidden"
      name="order[medical_policy_id]"
    >

    <input
      :value="guaranteeLetterId || ''"
      type="hidden"
      name="order[finance_guarantee_letter_id]"
    >

    <medical-policy-search-create
      :value="medicalPolicy"
      :client-id="clientId"
      use-first-time-fetch
      full-width
      active-only
      @setMedicalPolicy="selectMedicalPolicy"
    />

    <guarantee-letter-search-create
      :value="guaranteeLetter"
      :disabled="guaranteeLetterDisabled"
      :medical-policy-id="medicalPolicyId"
      :is-dms="medicalPolicy?.isDms"
      @setGuaranteeLetter="onSetGuaranteeLetter"
      @notifyGuaranteeLetterSumExceeded="notifyGuaranteeLetterSumExceeded"
    >
      <template
        v-if="guaranteeLetter?.sum"
        #prefix
      >
        <m-icon
          v-if="guaranteeLetter.sum >= currentOrderSum"
          v-tooltip="sumTooltip"
          icon="warning"
          color="warning"
        />
        <m-icon
          v-else
          v-tooltip="exceededTooltip"
          icon="warningCircle"
          color="danger"
        />
      </template>
    </guarantee-letter-search-create>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MedicalPolicySearchCreate from '@/vue_apps/ClientsModule/components/MedicalPolices/MedicalPolicySearchCreate.vue'
import {
  MedicalPolicyListItem,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/entities/list/MedicalPolicyListItem'
import GuaranteeLetterSearchCreate
  from '@/vue_apps/ClientsModule/components/MedicalPolices/GuaranteeLetterSearchCreate.vue'
import {
  GuaranteeLetterListItem,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/entities/list/GuaranteeLetterListItem'
import {
  isNewInactiveMedicalPolicyNotify,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/const/isNewInactiveMedicalPolicyNotify'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default defineComponent({
  name: 'OrderPoliciesWithGuaranteeLetters',
  components: {
    MIcon,
    GuaranteeLetterSearchCreate,
    MedicalPolicySearchCreate,
  },

  props: {
    clientId: { type: Number, required: true },
    orderMedicalPolicy: { type: Object, default: null },
    orderFinanceGuaranteeLetter: { type: Object, default: null },
  },

  data () {
    return {
      /**
       * @type {MedicalPolicyListItem}
       */
      medicalPolicy: null,
      /**
       * @type {GuaranteeLetterListItem}
       */
      guaranteeLetter: null,
      currentOrderSum: 0,
    }
  },

  computed: {
    medicalPolicyId () {
      return this.medicalPolicy?.id || null
    },

    guaranteeLetterId () {
      return this.guaranteeLetter?.id || null
    },

    guaranteeLetterDisabled () {
      return !this.medicalPolicyId && {
        disabled: true,
        tooltip: t('finance.medicalPolicyNotSelected'),
      }
    },

    sumTooltip () {
      if (this.guaranteeLetter?.sum) { return }

      return this.t('isLimitedGuaranteeLetter', { sum: vueFilters.currency(this.guaranteeLetter.sum) })
    },

    exceededTooltip () {
      if (!this.guaranteeLetter?.sum) { return }

      return t('isOrderSumExceedsGuaranteeLetterSum', { sum: vueFilters.currency(this.guaranteeLetter.sum) })
    },
  },

  mounted () {
    if (this.orderMedicalPolicy) {
      this.medicalPolicy = new MedicalPolicyListItem(this.orderMedicalPolicy)
    }

    if (this.orderFinanceGuaranteeLetter) {
      this.guaranteeLetter = this.orderFinanceGuaranteeLetter
    }

    this.$pubSub.subscribe('ORDERS.INSURANCE_COMPANY_CLEAR_MEDICAL_POLICY', () => {
      this.selectMedicalPolicy(null)
    })

    window.PubSub.on('page.form.order.orderEntryList.listChanged', (target, { final_sum: newOrderSum }) => {
      this.setCurrentOrderSum(newOrderSum)
    })
  },

  methods: {
    /**
     * @param {medicalPolicyListItemRaw | { isNew: boolean }} medicalPolicy
     * @return {Promise<*>}
     */
    async selectMedicalPolicy (medicalPolicy) {
      if (!medicalPolicy) { return this.setMedicalPolicy() }
      if (medicalPolicy.id && medicalPolicy.id === this.medicalPolicyId) { return }

      const result = await isNewInactiveMedicalPolicyNotify(medicalPolicy)
      if (!result?.active) { return }

      this.setMedicalPolicy(medicalPolicy)
    },

    setMedicalPolicy (medicalPolicy) {
      const isNewMedicalPolicy = medicalPolicy?.isNew

      this.medicalPolicy = medicalPolicy
        ? isNewMedicalPolicy
          ? new MedicalPolicyListItem(medicalPolicy)
          : medicalPolicy
        : null

      if (isNewMedicalPolicy) {
        this.appendClientMedicalPolicy(medicalPolicy)
      }

      this.updateMedicalPolicyField(this.medicalPolicy?.id)
      this.updateRelatedGuaranteeLetter(this.medicalPolicy?.id)
    },

    updateRelatedGuaranteeLetter (medicalPolicyId = null) {
      this.guaranteeLetter =
        this.orderMedicalPolicy && this.orderMedicalPolicy.id === medicalPolicyId
          ? this.orderFinanceGuaranteeLetter
          : null
    },

    onSetGuaranteeLetter (guaranteeLetter) {
      this.guaranteeLetter = !guaranteeLetter || guaranteeLetter instanceof GuaranteeLetterListItem
        ? guaranteeLetter
        : new GuaranteeLetterListItem(guaranteeLetter)

      if (!guaranteeLetter) { return }

      if (guaranteeLetter.sum < this.currentOrderSum) {
        this.notifyGuaranteeLetterSumExceeded()
      }
    },

    /* Поддержка работы легаси */
    appendClientMedicalPolicy (medicalPolicy) {
      gon.specific.client_medical_policies.push(medicalPolicy)
    },

    updateMedicalPolicyField (medicalPolicyId) {
      PubSub.emit('page.form.order.medicalPolicyField.change', {
        value: `${medicalPolicyId || ''}`,
      })
    },

    setCurrentOrderSum (newOrderSum = 0) {
      const oldOrderSum = this.currentOrderSum

      if (this.guaranteeLetter?.sum && oldOrderSum < this.guaranteeLetter.sum && newOrderSum > this.guaranteeLetter.sum) {
        this.notifyGuaranteeLetterSumExceeded()
      }

      this.currentOrderSum = newOrderSum
    },

    notifyGuaranteeLetterSumExceeded () {
      if (!this.medicalPolicy.isDms || !this.guaranteeLetter?.sum) { return }
      window.Notificator.warning(this.exceededTooltip)
    },
  },
})
</script>
