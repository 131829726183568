export enum FILTERS_PROFILES_TYPES {
  SALARY = 0,
  REFERRALS = 1,
  INSURANCE_COMPANIES = 2,
  DOCTOR_PRODUCTIVITY = 3,
  ADMINISTRATOR_PRODUCTIVITY = 4,
  DOCTOR_APPOINTMENTS_REPORT = 5,
  PROCEDURES = 6,
  TREATMENT_PLANS_REPORT = 7,
  MARKETING = 8,
}

/* При запросе шаблона бэк отправляет его тип в виде строки... */
export enum FILTERS_PROFILES_TYPES_WHEN_FETCH {
  administrator_productivity = FILTERS_PROFILES_TYPES.ADMINISTRATOR_PRODUCTIVITY,
  doctor_productivity = FILTERS_PROFILES_TYPES.DOCTOR_PRODUCTIVITY,
  insurance_companies = FILTERS_PROFILES_TYPES.INSURANCE_COMPANIES,
  appointments = FILTERS_PROFILES_TYPES.DOCTOR_APPOINTMENTS_REPORT,
  treatment_plans = FILTERS_PROFILES_TYPES.TREATMENT_PLANS_REPORT,
  procedures = FILTERS_PROFILES_TYPES.PROCEDURES,
  referrals = FILTERS_PROFILES_TYPES.REFERRALS,
  salary = FILTERS_PROFILES_TYPES.SALARY,
}

export enum FILTERS_PROFILES_OPTIONS_ERRORS {
  NAME_CHANGE = 'name_changed',
  NO_PERMISSION = 'no_permission',
  VALUE_DELETED = 'value_deleted',
}

export const FILTERS_PROFILES_OPTIONS_ERRORS_TEXTS: Record<string, string> = {
  [FILTERS_PROFILES_OPTIONS_ERRORS.NAME_CHANGE]: t('name_changed'),
  [FILTERS_PROFILES_OPTIONS_ERRORS.VALUE_DELETED]: t('value_deleted'),
  [FILTERS_PROFILES_OPTIONS_ERRORS.NO_PERMISSION]: t('no_permission'),
}
