<template>
  <div v-if="requireWorkplaces">
    <el-dropdown
      v-loading="isLoading"
      class="workplace-selector"
      :show-timeout="0"
      :placement="placement"
      :size="'small'"
      @menu-item-click="$emit('selectWorkplace', $event)"
    >
      <el-button type="primary">
        <i :class="[icon, 'title-icon']" />{{ title }}<i class="el-icon-arrow-down el-icon--right" />
      </el-button>

      <el-dropdown-menu
        slot="dropdown"
        class="workplace-selector__items"
      >
        <el-dropdown-item
          v-for="(item, index) in items"
          :key="index"
          :icon="itemIcon"
          :command="item.id"
        >
          <span>{{ item[itemTitleName] }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <button
      :title="t('laboratories.update_workplaces')"
      class="btn btn-primary btn-sm analysis_orders__workplace-updater"
      @click="$emit('getConnectedWorkplaces', $event)"
    >
      <i class="fad fa-sync" />
    </button>
  </div>

  <el-button
    v-else
    v-loading="isLoading"
    class="workplace-selector__single-button"
    type="primary"
    @click="$emit('selectWorkplace')"
  >
    <i :class="[icon, 'title-icon']" />{{ title }}
  </el-button>
</template>

<script>
export default {
  name: 'MassOrdersSendingComponent',

  props: {
    title: {
      type: String,
      default: 'title',
    },

    icon: {
      type: String,
      default: '',
    },

    items: {
      type: Array,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    itemTitleName: {
      type: String,
      default: 'title',
    },

    itemIcon: {
      type: String,
      default: 'fad fa-rectangle-landscape',
    },

    placement: {
      type: String,
      default: 'bottom-end',
    },

    requireWorkplaces: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
