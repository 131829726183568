<template>
  <div
    ref="calendarPicker"
    class="schedule-calendar"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    calendarWorkloadDye: {
      type: Object,
      required: true,
    },

    highlightedDays: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters([
      'GET_SCHEDULE_DATE',
      'GET_LOCALIZATION_LOCALE',
      'GET_LOCALIZATION_DAYS_WEEK_START',
      'GET_LOCALIZATION_DATE_FORMAT',
    ]),
  },

  watch: {
    GET_SCHEDULE_DATE (date) {
      this.setDate(date)
    },
  },

  mounted () {
    this.updateTooltips()
    this.$nextTick(() => { this.mountDatepicker() })
  },

  methods: {
    mountDatepicker () {
      $(this.$refs.calendarPicker).datepicker({
        startDate: 0,
        todayBtn: 'linked',
        language: this.GET_LOCALIZATION_LOCALE,
        todayHighlight: true,
        weekStart: this.GET_LOCALIZATION_DAYS_WEEK_START,
        format: this.GET_LOCALIZATION_DATE_FORMAT,
        beforeShowDay: (calendarDay) => this.calendarWorkloadDye.dye(Utils.dateJStoRuby(calendarDay)),
      }).on('changeDate', (e) => {
        const date = moment(e.date).format(this.$store.getters.GET_LOCALIZATION_DATE_FORMAT)
        this.$store.dispatch('setScheduleDate', date)
        this.$emit('update:highlightedDays', [date, Utils.dateJStoRuby(new Date())])
      })

      this.setDate(this.GET_SCHEDULE_DATE)

      console.warn('Календарь перерисован')

      this.$nextTick(() => {
        const [prev, next] = this.$refs.calendarPicker.querySelectorAll('.datepicker-days .prev, .datepicker-days .next')
        prev.innerHTML = '<span class="fad fa-fw fa-angle-double-left"></span>'
        next.innerHTML = '<span class="fad fa-fw fa-angle-double-right"></span>'
        this.updateTooltips()
      })
    },

    updateTooltips () {
      if (this.$refs.calendarPicker) {
        Utils.updateTooltips(this.$refs.calendarPicker)
      }
    },

    /**
     *
     * @param {string} date Дата в формате из стора
     */
    setDate (date) {
      $(this.$refs.calendarPicker).datepicker(
        'setDate',
        moment(date, this.GET_LOCALIZATION_DATE_FORMAT).toDate()
      )

      this.$nextTick(() => {
        this.updateTooltips()
      })
    },
  },
}
</script>
