import { MODAL_TYPES } from '@/vue_components/doctor_schedules/consts'

/**
 * Эта миксина предоставляет контекст для модалок, а так же удобные и единообразные
 * методы для их показа и скрытия.
 * Контекст представляет собой стек названий модалок. По нему можно понять, была
 * ли одна модалка открыта из другой, из какой модалки она была открыта и какие
 * модалки сейчас открыты (отдельного метода для этого нет, можно просто взять
 * `new Set(this.modalContext)`).
 *
 */
export const ModalHolder = {
  data () {
    return {
      modal: {},
      modalContext: [],
      MODAL_TYPES,
    }
  },

  methods: {
    openModal (modal, data) {
      this.modal[modal].visibility = true
      this.modalContext.push(modal)

      if (data) {
        Object.keys(data).forEach((key) => {
          this.modal[modal][key] = data[key]
        })
      }
    },

    closeModal (modal) {
      this.modal[modal].visibility = false
      this.removeLastModal(modal)
    },

    /**
     * Возвращает имя модалки, из которой была открыта модалка modalType.
     * Так как modalContext это стек, то модалка, лежащая по большему индексу,
     * была открыта из модалки с меньшим индексом или из самого расписания.
     *
     * @param {string} modalType
     * @return {string|null}
     */
    getParentModalType (modalType) {
      const parentIndex = this.modalContext.indexOf(modalType)
      if (parentIndex > 0) {
        return this.modalContext[parentIndex - 1]
      }

      return null
    },

    removeLastModal (modalType) {
      const index = this.modalContext.lastIndexOf(modalType)
      if (index >= 0) {
        this.modalContext.splice(index, 1)
      }
    },
  },
}
