import { SEMD_TYPE, SEMD_TYPE_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import {
  ISemd119SemdsDocumentDetailsAttributesData,
} from '@/vue_apps/Semds/entities/Semd119/ISemd119SemdsDocumentDetailsAttributesData'
import {
  ISemd224SemdsDocumentDetailsAttributesData,
} from '@/vue_apps/Semds/entities/Semd224/ISemd224SemdsDocumentDetailsAttributesData'
import { extractId } from '@/_medods_standart_library/msl'

/**
 * ПОМНИ!
 *
 * СОХРАНЕНИЕ ПРОТОКОЛА ПРОИСХОДИТ В 2 ЭТАПА:
 * 1) СОЗДАНИЕ ПРОТОКОЛА В БАЗЕ ДАННЫХ + ПОЛУЧЕНИЕ ЗАПОЛНЕННОГО TEMPLATE_HTML
 * 2) ОБНОВЛЕНИЕ SEMDS_DOCUMENT_DETAILS_ATTRIBUTES ПО ЗАПОЛНЕННОМУ TEMPLATE_HTML
 */

export const patchSemdsDocumentDetailsAttributes = (data: any, semdType: number) => {
  switch (semdType) {
    case SEMD_TYPE_ENUM[SEMD_TYPE.CONSULTATION]: return patchSemd119Details(data)
    case SEMD_TYPE_ENUM[SEMD_TYPE.INSTRUMENTAL_RESEARCH_PROTOCOL]: return patchSemd224Details(data)

    default: return data
  }
}

const patchSemd119Details = (data: ISemd119SemdsDocumentDetailsAttributesData) => {
  return {
    ...data,
    nsiMkbDiagnosisGroupId: data.nsiMkbDiagnosisGroup?.id,
    nsiCaseVisitTypeId: data.nsiCaseVisitType?.id,
    nsiMedicalCarePlaceId: data.nsiMedicalCarePlace?.id,
    nsiPatientConditionId: data.nsiPatientCondition?.id,
    nsiIdentifiedPathologyId: data.nsiIdentifiedPathology?.id,
  }
}

const patchSemd224Details = (data: ISemd224SemdsDocumentDetailsAttributesData) => {
  const getHHmmFromTime = (time: string) => time?.split(':').slice(0, 2).join(':')

  return {
    ...data,
    timeStart: getHHmmFromTime(data.timeStart),
    timeEnd: getHHmmFromTime(data.timeEnd),

    radiationExposure: data.radiationExposure?.amount,
    nsiRadiationExposureUnit: data.radiationExposure?.units,

    nsiRadiationExposureUnitId: data.radiationExposure?.units?.id,
    nsiDirectionalMkbDiagnosisGroupId: data.nsiDirectionalMkbDiagnosisGroup?.id,
    nsiCaseVisitTypeId: data.nsiCaseVisitType?.id,
    nsiMedicalCarePlaceId: data.nsiMedicalCarePlace?.id,
    nsiInstrumentalDiagnosticResearchId: data.nsiInstrumentalDiagnosticResearch?.id,
    nsiUltrasoundModeId: data.nsiUltrasoundMode?.id,
    nsiEchoImageQualityId: data.nsiEchoImageQuality?.id,
    nsiDetectedPathologyIds: data.nsiDetectedPathologies?.map(extractId),
  }
}
