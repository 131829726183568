import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getDoctorSalarySchema = () => {
  return new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addFooter()
        .addSorting()
        .addHeaders({
          tableEntryNumber: new MTableHeader(t('code')).addFixedWidth('100px'),
          tableEntryTitle: new MTableHeader(t('title')),
          tableDate: new MTableHeader(t('custom_date')).addFixedWidth('120px'),
          tableOrder: new MTableHeader(t('order')).addFixedWidth('100px'),
          tableClient: new MTableHeader(t('client')),
          tableEntrySum: new MTableHeader(t('sum')).addFixedWidth('125px'),
          tableRate: new MTableHeader(t('reports.rate')).addFixedWidth('125px'),
          tableSalary: new MTableHeader(t('reports.salary')).addFixedWidth('125px'),
        })
    )
}
