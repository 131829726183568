<template>
  <div class="col col__status">
    <m-icon
      v-tooltip="statusObject.title"
      :class="iconCss"
      :icon="statusObject.icon"
      color=""
    />
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'AppointmentCallLogAppointmentStatus',
  components: { MIcon },
  props: {
    status: PropsTypes.String(),
    appointmentStatuses: PropsTypes.Array(),
  },

  computed: {
    statusObject () {
      return this.appointmentStatuses.find(({ id }) => id === this.status) || {}
    },

    iconCss () {
      return `appointment-status-icon__${this.statusObject.id}`
    },
  },
}
</script>
