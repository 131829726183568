import { defineDateFormat, fetchExportFile } from '@/specific/reports/export'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import { headerRow, totalRow } from '@/specific/reports/procedures/const'
import { DEFAULT_OFFSET, MAX_LIMIT } from '@/specific/reports/const'
import { proceduresReportFiltersAccessors } from './filtersAccessors'

export function getReport () {
  const filters = proceduresReportFiltersAccessors.get()

  const data = {
    dateFormat: defineDateFormat(),
    csvComma: gon.application.csv_character,
    floatingComma: gon.localization.currency.decimal_character,
    headerRow,
    totalRow,
    offset: DEFAULT_OFFSET,
    limit: MAX_LIMIT,
    report: {
      period: filters.period,
    },
    ...ReportFiltersFactory.defineFor('procedures', filters),
  }

  fetchExportFile(data, Routes.report_procedures_path(), T.reports.short_titles.procedures)
}
