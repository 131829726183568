import {
  WORK_TIME,
  DAY_INTERVAL_TEMPLATE,
  WEEK_INTERVAL_TEMPLATE,
} from '../../../const.js'

const templateTypeWatcher = function () {
  const { templateTypeId } = this.immutableCopy

  return templateTypeId !== this.templateTypeId[0]
}

const dayIntervalWatcher = function () {
  const { templateTypeId, templateDayIntervalId } = this.immutableCopy

  return templateTypeId === DAY_INTERVAL_TEMPLATE &&
         templateDayIntervalId !== this.templateDayIntervalId[0]
}

const weekIntervalWatcher = function () {
  const { templateTypeId, templateWeekIntervalId } = this.immutableCopy

  return templateTypeId === WEEK_INTERVAL_TEMPLATE &&
         templateWeekIntervalId !== this.templateWeekIntervalId[0]
}

const weekSelectedDaysWatcher = function () {
  const { templateTypeId, templateSelectedDaysIds } = this.immutableCopy

  return templateTypeId === WEEK_INTERVAL_TEMPLATE &&
         (templateSelectedDaysIds.length !== this.templateSelectedDaysIds.length ||
          templateSelectedDaysIds.some((day) => !this.templateSelectedDaysIds.includes(day)))
}

const commonFieldsWatcher = function () {
  const {
    cabinetId,
    startTime,
    endTime,
    availabilityForOnlineRecording,
  } = this.immutableCopy

  return cabinetId !== this.cabinetId ||
         startTime !== this.startTime ||
         endTime !== this.endTime ||
         availabilityForOnlineRecording !== this.availabilityForOnlineRecording[0]
}

const scheduleTemplateFieldsWatcher = function () {
  const {
    templateTypeId,
    templateActive,
    propagationDate,
  } = this.immutableCopy

  return templateTypeId !== WORK_TIME &&
         (templateActive !== this.templateActive[0] ||
          propagationDate.getTime() !== this.propagationDate.getTime())
}

const intervalToIntervalTransformationWatcher = function () {
  const { templateTypeId } = this.immutableCopy

  return templateTypeId !== WORK_TIME &&
         this.templateTypeId[0] !== WORK_TIME
}

const workTimeTransformationWatchersChain = [
  templateTypeWatcher,
  commonFieldsWatcher,
  dayIntervalWatcher,
  weekIntervalWatcher,
  weekSelectedDaysWatcher,
]

const scheduleTemplateTransformationWatchersChain = [
  scheduleTemplateFieldsWatcher,
]

const intervalToIntervalTransformationWatchersChain = [
  intervalToIntervalTransformationWatcher,
]

export const injectWatchers = (target, state) => {
  state.templateSelectedDaysIds = Object.freeze(state.templateSelectedDaysIds.slice())
  target.immutableCopy = Object.freeze(state)

  target.workTimeTransformationChain =
    workTimeTransformationWatchersChain.map((watcher) => watcher.bind(target))
  target.scheduleTemplateTransformationChain =
    scheduleTemplateTransformationWatchersChain.map((watcher) => watcher.bind(target))
  target.intervalToIntervalTransformationChain =
    intervalToIntervalTransformationWatchersChain.map((watcher) => watcher.bind(target))
}
