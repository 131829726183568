<template>
  <div class="col-container">
    <div
      :class="activeTab !== API_ROLES_LIST ? 'margin-right-5px' : ''"
      class="col-item-35"
    >
      <div
        class="panel panel-primary"
      >
        <epic-spinner
          :visibility="activeRequestState"
        />
        <div
          class="panel-heading panel-heading-flex"
        >
          <div
            class="panel-heading-title"
          >
            <span
              class="panel-heading-icon fad fa-fw fa-user-lock"
            />
            <h3
              class="panel-title"
            >
              {{ T.api_page.roles.full_rights }}
            </h3>
          </div>
        </div>
        <div
          class="panel-top-btn-container"
        >
          <div
            class="flex-item"
          >
            <button
              class="btn btn-with-icon btn-success btn-sm hidden-print clients-options-btn"
              :title="t('api_page.roles.add_api_role')"
              @click="activeTab = NEW_API_ROLE, newApiRole()"
            >
              <span class="btn-with-icon_icon fad fa-fw fa-user-lock" />
              <span class="fas fa-fw fa-plus" />
            </button>
          </div>
        </div>
        <div
          class="panel-body panel-list-body"
        >
          <table
            class="table table-striped table-hover"
          >
            <tbody>
              <tr
                v-for="(apiRole) in apiRoles"
                :key="apiRole.id"
                :class="apiRole.id === activeTr[0] && 'active'"
                @click="editApiRole(apiRole, apiResources)"
              >
                <td class="p-2 options">
                  <i class="fad fa-fw fa-user-lock" />
                </td>
                <td
                  class="name cut"
                  data-name="name"
                >
                  {{ apiRole.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination
          v-if="apiRolesCount > PAGINATION_LIMIT"
          :current-page.sync="currentPage"
          :page-count="pageCount"
          :margin-top="10"
          :margin-bottom="10"
          :margin-left="15"
          :margin-right="15"
          layout="prev, pager, next"
          @current-change="loadApiRoles"
        />
      </div>
    </div>
    <div
      v-show="activeTab !== API_ROLES_LIST"
      class="col-item-65"
    >
      <api-role-new
        :show="activeTab === NEW_API_ROLE"
        @created="apiRoleCreated"
        @close="activeTab = API_ROLES_LIST"
      />
      <api-role-edit
        ref="editForm"
        :show="activeTab === EDIT_API_ROLE"
        @updated="loadApiRoles"

        @close="activeTab = API_ROLES_LIST"
      />
    </div>
  </div>
</template>

<script>
import ApiRoleNew from '@/vue_components/api/role_new.vue'
import ApiRoleEdit from '@/vue_components/api/role_edit.vue'
import Pagination from '@/vue_components/common/pagination.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'

const PAGINATION_LIMIT = 30
const API_ROLES_LIST = 2
const NEW_API_ROLE = 4
const EDIT_API_ROLE = 8

export default {
  name: 'ApiRole',

  components: {
    ApiRoleNew,
    ApiRoleEdit,
    Pagination,
    EpicSpinner,
  },

  data: () => ({
    PAGINATION_LIMIT,
    API_ROLES_LIST,
    NEW_API_ROLE,
    EDIT_API_ROLE,

    activeTab: API_ROLES_LIST,
    currentPage: 1,
    pageCount: 0,
    activeRequestState: false,
    apiRoles: [],
    apiRolesCount: 0,
    apiResources: [],
    mappedValues: null,
    activeTr: [],
  }),

  watch: {
    apiRolesCount () {
      this.pageCount = Math.ceil(this.apiRolesCount / PAGINATION_LIMIT)
    },
  },

  created () {
    this.loadApiRolesCount()
    this.loadApiRoles()
    this.loadApiResources()
  },

  methods: {
    loadApiRoles () {
      const god = this

      god.activeRequestState = true

      $.ajax({
        type: 'GET',
        url: Routes.list_api_roles_path(),
        data: {
          offset: (god.currentPage - 1) * PAGINATION_LIMIT,
          limit: PAGINATION_LIMIT,
        },
        success (apiRoles) {
          god.apiRoles = apiRoles
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    loadApiRolesCount () {
      const god = this

      god.activeRequestState = true

      $.ajax({
        type: 'GET',
        url: Routes.count_api_roles_path(),
        success (apiRolesCount) {
          god.apiRolesCount = apiRolesCount
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    setButtonsFalse (apiResources) {
      this.mappedValues = Object.create(null)

      Object.values(apiResources).forEach((resource) => {
        this.mappedValues[resource.name] = Object.create(null)
        Object.keys(resource.methods).forEach((methodId) => {
          this.mappedValues[resource.name][methodId] = false
        })
      })

      this.mappedValues = Object.freeze(this.mappedValues)
    },

    loadApiResources () {
      const god = this

      god.activeRequestState = true

      $.ajax({
        type: 'GET',
        url: Routes.api_resources_list_path(),
        success (apiResources) {
          god.apiResources = apiResources
          god.setButtonsFalse(apiResources)
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    apiRoleCreated (apiRole) {
      this.loadApiRolesCount()
      this.loadApiRoles()
      this.loadApiResources()
    },

    newApiRole () {
      if (this.activeTr.length > 0) {
        this.activeTr.pop()
      }
    },

    editApiRole (apiRole, apiResources) {
      this.setButtonsFalse(apiResources)
      const mappedValues = { ...this.mappedValues }

      this.$refs.editForm.edit(mappedValues, apiRole, apiResources)

      if (this.activeTr.length > 0) {
        this.activeTr.pop()
      }
      this.activeTr.push(apiRole.id)

      this.activeTab = EDIT_API_ROLE
    },
  },
}
</script>
