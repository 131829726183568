<template>
  <el-select
    v-model="value"
    size="small"
    :class="['entry-type-selector', {selected: isSelected}]"
  >
    <el-option
      v-for="item in options"
      :key="item[1]"
      :label="item[0]"
      :value="item[1]"
    />
  </el-select>
</template>

<script>
import CardSelector from './selector.js'

export default {
  mixins: [CardSelector],
  data () {
    return {
      options: gon.specific.head_desktop.entry_types,
      key: 'kind',
    }
  },
}
</script>
