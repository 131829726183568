import { ItemBase } from '@/_api/_classes/ItemBase'

export class AttachmentTabHyperlinkModel extends ItemBase {
  _permit = [
    'id',
    'clientId',
    'link',
    'title',
  ]

  id: number

  clientId: number

  link: string

  title: string
}
