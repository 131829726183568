
export class Promobot {
  /**
   * @private
   */
  __onGetData () {
    document.addEventListener(
      'promobotDataScanned',
      /** @param {CustomEvent} event*/
      (event) => {
        window.PubSub.emit('promobotDataScanned', event.detail)
      }
    )
  }

  /**
   * @private
   */
  __onStart () {
    document.addEventListener('promobotDataScanStarted', () => {
      window.PubSub.emit('promobotDataScanStarted')
      Notificator.info(t('scanStarted'), 'Promobot', 3000)
    })
  }

  /**
   * @private
   */
  __onFinish () {
    document.addEventListener('promobotDataScanFinished', () => {
      window.PubSub.emit('promobotDataScanFinished')
      Notificator.info(t('scanFinished'), 'Promobot', 3000)
    })
  }

  subscribe () {
    this.__onGetData()
    this.__onStart()
    this.__onFinish()
  }

  unsubscribe () {
    ;[
      'promobotDataScanned',
      'promobotDataScanStarted',
      'promobotDataScanFinished',
    ].forEach((eventName) => { document.removeEventListener(eventName, null) })
  }

  reSubscribe () {
    this.unsubscribe()
    this.subscribe()
  }
}
