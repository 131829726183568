export default {
  contextReady () {
    if (this.contextReady) {
      this.clearUsersList()
      this.clearSpecialtyList()

      this.users.forEach((user) => {
        this.allUIDs.push(user.id)
        this.usersMap[user.id] = user
      })

      this.specialties.forEach((specialty) => {
        this.allSIDs.push(specialty.id)
        this.specialtiesMap[specialty.id] = specialty
      })

      let state = this._validateStore()

      this.selectedSID = state.selectedSID
      this.showSpecialtiesList = state.showSpecialtiesList
      this.selectedUIDsInSID = state.selectedUIDsInSID

      this._setVisibleUsers()
      this._setVisibleSpecialties()

      this.fetchUserWaitingList()

      /**
       * В `setUsersAndSpecialty` уже вызывается `_onChange`.
       * Юзер повторно устанавливается здесь, так как при смене клиники
       * его может не быть в списке юзеров и он не будет выбран.
       */
      if (this.userToShow) {
        this.setUsersAndSpecialty([this.userToShow.userId], this.userToShow.specialty)
        this.userToShow = null
      } else {
        this._onChange()
      }
    }
  },

  filteredUserIds () {
    this._setVisibleSpecialties()
    this._setVisibleUsers()
    this._onChange()
  },

  oneVisibleUser () {
    this.setUsersAndSpecialty([this.oneVisibleUser])
  },

  tableVisibility (flag) {
    if (flag) {
      this._setVisibleSpecialties()
      this._setVisibleUsers()
      this._onChange()
    }
  },

  showAppointment (value) {
    this.userToShow = value
    this.setUsersAndSpecialty([value.userId], value.specialty)
  },
}
