import { createStore } from 'redux'
import reducersStorage from '@/plugins/dynamic_forms/reducers'
import EntityManager from '@/plugins/dynamic_forms/entities'
import htmlHelper from '@/plugins/dynamic_forms/helpers/htmlHelper'
import ApplicationFactory from '@/plugins/dynamic_forms/application_factory'

export const initProtocolEditor = (templateData, restoredFromDraft = false) => {
  if (tinymce.activeEditor && !restoredFromDraft) { tinymce.activeEditor.remove() }

  let storeData
  try {
    storeData = JSON.parse(templateData)
  } catch (e) {
    storeData = {}
  }

  EntityManager.load(storeData, createStore(reducersStorage))
  htmlHelper.clearHtml()
  ApplicationFactory.generate('frontend')
}
