<template>
  <div class="transparent-dom-node">
    <si-field class="flex-stretch">
      <div class="fio report-column-locked wrap">
        {{ $filters.fullName(row.referral) }}
      </div>
      <div
        class="city report-column-locked wrap"
        :data-tooltip="$filters.address(row.referral)"
      >
        {{ $filters.address(row.referral) }}
      </div>
      <div class="company report-column-locked wrap">
        {{ row.firstEntry.company && row.firstEntry.company.title }}
      </div>
      <div class="title wrap">
        {{ row.firstEntry.title }}
      </div>
      <div class="amount">
        {{ row.firstEntry.amount }}
      </div>
      <div class="sum wrap">
        {{ $filters.price(row.firstEntry.finalSum ) }}
      </div>
      <div class="reward-sum">
        {{ $filters.price(row.firstEntry.rewardFinalSum) }}
      </div>
    </si-field>
    <si-field
      v-for="(entry, index) in row.entries"
      :key="index"
      class="flex-stretch"
    >
      <div class="fio report-column-locked wrap" />
      <div class="city report-column-locked wrap" />
      <div class="company report-column-locked wrap" />
      <div class="title wrap">
        {{ entry.title }}
      </div>
      <div class="amount">
        {{ entry.amount }}
      </div>
      <div class="sum wrap">
        {{ $filters.price(entry.finalSum) }}
      </div>
      <div class="reward-sum">
        {{ $filters.price(entry.rewardFinalSum) }}
      </div>
    </si-field>
    <si-field class="section-totals">
      <div class="fio report-column-locked wrap">
        {{ t('reports.referral_total') }}
      </div>
      <div class="city report-column-locked wrap" />
      <div class="company report-column-locked wrap" />
      <div class="title wrap" />
      <div class="amount" />
      <div class="sum">
        {{ $filters.price(row.finalSum) }}
      </div>
      <div class="reward-sum">
        {{ $filters.price(row.rewardFinalSum) }}
      </div>
    </si-field>
  </div>
</template>

<script>
/**
 * TODO: посмотреть 60е строки, вроде там лишние столбцы
 */
import { ReferralRow } from './referralRow'
import SiField from '@/vue_components/sort_items/si_table/si_field'

export default {
  name: 'ReferralsRowGrouped',
  components: { SiField },
  mixins: [ReferralRow],
}
</script>
