import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class EntriesListTotalItem {
  /**
   * @param {ClientEntriesListItemRaw[]} data
   */
  constructor (data = []) {
    const totalSum = Utils.toMoney(data.reduce((acc, { finalSum }) => acc + parseFloat(finalSum), 0))
    const templateText = t('entriesTotalTemplate', {
      totalItems: data.reduce((acc, item) => acc + item.amount, 0),
      totalSum: vueFilters.currency(totalSum),
    })

    this.date =
      new MTableCell(templateText)
        .addClasses('position-absolute')
  }
}
