import { createVueApp } from '@/vue_components/create_vue_app'
import OrderMassSelect from '@/vue_apps/Orders/OrderMassSelect/OrderMassSelect.vue'
import { getStoresDisabledValue } from '@/vue_apps/Orders/helpers/getStoresDisabledValue'

let vueApp = null
export const createOrderMassSelect = () => {
  if (vueApp?.destroy) { return vueApp.destroy() }

  vueApp = createVueApp({
    el: '#vue_order_mass_select_app',
    name: 'OrderMassSelectRoot',
    render: (h) => h(OrderMassSelect, {
      props: {
        useAssistants: gon.specific.useAssistants,
        storesDisabled: getStoresDisabledValue(),
      },
    }),
  })
}
