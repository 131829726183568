import { DateHelpers } from 'src/helpers/DateHelpers'
import { _getAdministratorsProductivityReportFilters as getFilters } from './_filtersGetter'
import { IAdministratorsProductivityReportFiltersGet, IAdministratorsProductivityReportFiltersSet } from './interfaces'

let initialFilters: IAdministratorsProductivityReportFiltersGet = null

export const saveAdministratorsProductivityReportInitialFilters = (): void => {
  initialFilters = getFilters()
}

export const getAdministratorsProductivityReportInitialFilters = (): IAdministratorsProductivityReportFiltersSet => ({
  ...initialFilters,
  period: DateHelpers.getCurrentMonthPeriod(),
})
