import {
  AttachmentTabHyperlinkLogic,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/classes/AttachmentTabHyperlinkLogic'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { Api } from '@/_api/decorators/api/Api'
import {
  IAttachmentTabHyperlink,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/interfaces/IAttachmentTabHyperlink'
import { IValidationErrors } from '@/_declarations/IValidationErrors'
import { Callback } from '@/_api/decorators/callback'
import {
  attachmentTabHyperlinkAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/api/attachmentTabHyperlinkAdapter'

export class AttachmentTabHyperlinkApi extends AttachmentTabHyperlinkLogic {
  @Callback('_fillProps')
  @Api.notify('fetchMessage', 'hyperlink', { success: false })
  @Api.get(Routes.api_internal_files_hyperlink_path)
  fetch (id = this.id) {
    return {
      routeArgs: [id],
    } as ICRUDReturn as Promise<IAttachmentTabHyperlink | IValidationErrors>
  }

  @Api.get(Routes.count_api_internal_files_hyperlinks_path)
  fetchCount (clientId = this.clientId) {
    return {
      payload: {
        clientId,
      },
    } as ICRUDReturn as Promise<{ count: number } | { errors: object }>
  }

  @Api.notify('submitMessage', 'hyperlink')
  @Api.submit(Routes.api_internal_files_hyperlinks_path, Routes.api_internal_files_hyperlink_path)
  @Api.serverAdapter(attachmentTabHyperlinkAdapter)
  submit () {
    return {
      routeArgs: [this.id],
      payload: this.getAttributes(),
    } as ICRUDReturn as Promise<IAttachmentTabHyperlink | IValidationErrors>
  }

  @Api.notify('destroyMessage', 'hyperlink')
  @Api.delete(Routes.api_internal_files_hyperlink_path)
  destroy (id: number) {
    return {
      routeArgs: [id],
    } as ICRUDReturn as Promise<IAttachmentTabHyperlink | IValidationErrors>
  }
}
