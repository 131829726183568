import { camelCase } from 'lodash'
import { transformKeys } from '@/_api/_requests/helpers'

const snakeCaseTransform = transformKeys(snakeCase)
const camelCaseTransform = transformKeys(camelCase)

export const createCaseAdapter = (requestKey) => ({
  toServer (data) {
    return {
      [requestKey]: {
        ...snakeCaseTransform(data),
      },
    }
  },

  toClient (data) {
    return {
      ...camelCaseTransform(data),
    }
  },
})

/**
 * Трансформирует строку из camelCase в snakeCase. Используется как замена
 * одноименного метода из lodash, так как на стороне сервера ключи с числом
 * в названии записываются как 'value1', в то время как _.snakeCase() преобразовывает
 * такие ключи в 'value_1'.
 * @param {String} str
 * @returns {String} возвращает трансформированную строку
 */
function snakeCase (str) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}
