import { request } from '@/lib/transport/request'
const ROOT_KEY = 'refuse_result'

export const refuseResultsEndpoint = {
  /**
   * sort params
   * @param {{ offset: Number, limit: Number }} data
   * @returns {Promise<*>}
   */
  getAll (data) {
    const options = {
      type: 'post',
      url: Routes.catalogs_refuse_results_list_path(),
      data: { sort_params: data },
    }

    return request(options).promise
  },

  /**
   * @param {{ title: String }} data
   * @returns {Promise<*>}
   */
  create (data) {
    const options = {
      type: 'post',
      url: Routes.refuse_results_path(),
      data: { [ROOT_KEY]: data },
    }

    return request(options).promise
  },

  /**
   * @param {{ id: Number, title: String }} data
   * @returns {Promise<*>}
   */
  update (data) {
    const options = {
      type: 'patch',
      url: Routes.refuse_result_path(data.id),
      data: { [ROOT_KEY]: data },
    }

    return request(options).promise
  },

  /**
   * @param {Number} id
   * @returns {Promise<*>}
   */
  destroy (id) {
    const options = {
      type: 'delete',
      url: Routes.refuse_result_path(id),
    }

    return request(options).promise
  },
}
