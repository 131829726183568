<template>
  <div
    v-tooltip="tooltip"
    class="col col__doctorNameWithSpecialties"
  >
    <div>{{ doctor }}</div>
    <div class="prompt-notice cut">
      {{ specialties }}
    </div>
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'AppointmentCallLogDoctorWithSpecialties',
  props: {
    doctor: PropsTypes.String(),
    specialties: PropsTypes.String(),
  },

  computed: {
    tooltip () {
      if (this.specialties) {
        return `${this.doctor} - ${this.specialties}`
      }

      return this.doctor
    },
  },
}
</script>
