export enum proceduresReportFiltersSelectors {
  period = '.period',
  clinic = '#report_clinic_id',
  entryKind = '#report_kind',
  entryWithOrder = '#report_entry_with_order',
  entryWithDiscount = '#report_entry_with_discount',
  doctor = '#report_user_id',
  administrator = '#report_administrator_id',
  entryTypes = '#report_entry_type_ids',
  entryTypeCategories = '#report_category_ids',
  client = '#report_client_id',
  clientGroups = '#report_client_group_ids',
  clientSex = '#report_client_sex',
  clientStatus = '#report_client_deleted_at',
  doctorSpecialtiesIds = '#report_specialty_ids',
}
