import { createVueApp } from '@/vue_components/create_vue_app'
import ServiceRate from '@/vue_present/ServiceRate/ServiceRate.vue'
import { MEntityServicePresenter } from '@/_api/MEntityService/MEntityServicePresenter'

export const createUserServicesRateApp = (el = '#vue_user_services_app') => {
  if (!el || typeof el !== 'string') { return }
  if (!document.querySelector(el)) { return }

  const presenter = new MEntityServicePresenter('user')

  return createVueApp({
    el,
    name: 'UserServicesApp',
    render: (h) => h(ServiceRate, {
      props: {
        entityId: parseInt(gon.page.params.id),
        fetchCategory: (entityId, categoryId = 0) => presenter.fetchCategory(entityId, categoryId),
        updateNode: (entityId, node) => presenter.update(entityId, node),
      },
    }),
  })
}
