import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class DoctorSalaryReportItem {
  /** @param {TSalaryEntry} salaryEntry */
  constructor (salaryEntry) {
    this.entry = salaryEntry.entry
    this.id = salaryEntry.id
    this.rate = salaryEntry.rate
    this.rateType = salaryEntry.rateType
    this.sum = salaryEntry.sum
  }

  get tableEntryNumber () {
    return new MTableCell(this.entry.number || '-')
      .addTooltip(this.entry.number)
  }

  get tableEntryTitle () {
    return new MTableCell(this.entry.title)
      .addTooltip(this.entry.title)
      .addCut()
  }

  get tableDate () {
    return new MTableCell(vueFilters.date(this.entry.date))
  }

  get tableOrder () {
    return this.entry.orderId
      ? new MTableCell(this.entry.orderId)
        .addTypeUrl(Routes.order_path(this.entry.orderId))
      : new MTableCell()
  }

  get tableClient () {
    const fullName = vueFilters.fullName(this.entry.client)

    return new MTableCell(fullName)
      .addTooltip(fullName)
      .addCut()
  }

  get tableEntrySum () {
    return new MTableCell(vueFilters.price(this.entry.sum))
  }

  get tableRate () {
    return new MTableCell(`${vueFilters.price(this.rate)} ${vueFilters.rateType(this.rateType)}`)
  }

  get tableSalary () {
    return new MTableCell(vueFilters.price(this.sum))
  }
}
