import { pick } from 'lodash'
import { extractId, orUndefined } from '@/_medods_standart_library/msl'
import { SEMD_TYPE_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import {
  extractSemdsDocumentDetailsAttributes,
  getDiagnosesAttributes,
  getEntitiesAttributes,
} from '@/vue_apps/Protocols/api/helpers'

const extractTemplateData = (templateData) => {
  try {
    return JSON.parse(templateData)
  } catch (e) {
    return {}
  }
}

export const protocolAdapter = {
  /**
   * @param { ProtocolModel | { storeState: IProtocolTemplateDataObject }} data
   */
  toServer (data) {
    const {
      complaints,
      conclusions,
      destinations,
      diagnoses,
    } = extractTemplateData(data.templateData)

    const diagnosesAttributes = getDiagnosesAttributes(diagnoses)
    const complaintsAttributes = getEntitiesAttributes(complaints)
    const conclusionsAttributes = getEntitiesAttributes(conclusions)
    const destinationsAttributes = getEntitiesAttributes(destinations)

    const semdsDocumentDetailsAttributes =
      extractSemdsDocumentDetailsAttributes(data)

    return {
      ...pick(data, [
        'title',
        'templateHtml',
        'templateData',
        'printClinicHeader',
        'printDoctorSignature',
        'printEntryInfo',
        'printEntryTitle',
        'clientId',
        'onlineAccess',
        'date',
      ]),

      selectedLegalRepresentativeId: orUndefined(data.selectedLegalRepresentativeId),
      clinicId: data.clinic.id,
      doctorId: data.doctor?.id,
      entryIds: data.entries?.map(extractId) || data.entryIds,

      newSemdApproved: data.newSemdApproved || false,
      newSignatureApproved: data.newSignatureApproved || false,

      semdType: data.semdType,
      semdsDocumentDetailsAttributes,

      diagnosesAttributes,
      complaintsAttributes,
      conclusionsAttributes,
      destinationsAttributes,
    }
  },

  toClientAdapter (response) {
    if (response === true) { return }

    const result = response.value
      ? response.value
      : response

    result.semdType = SEMD_TYPE_ENUM[result.semdType] || null

    return result
  },
}
