<template>
  <div class="report-filters flex flex-column">
    <!-- Фильтры -->
    <slot />

    <!-- Кнопочки -->
    <report-filters-buttons
      :loading="report.loading"
      @onReportClick="onReportClick"
      @onResetClick="onResetClick"
    />
  </div>
</template>

<script>
import ReportFiltersButtons from '@/vue_apps/ReportsModule/_filters/components/ReportFiltersButtons.vue'
import { MReportListService } from '@/_api/_requests/MReportListService'
import { REPORT_EVENTS } from '@/vue_apps/ReportsModule/_filters/const/events'
import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { createVueApp } from '@/vue_components/create_vue_app'
import ReportContainerBase from '@/vue_apps/ReportsModule/_ReportBase/ReportContainerBase.vue'

/**
 * Базовый компонент для простейших отчетов без какого-то дополнительного функционала.
 * Для каких-то кастомных отчётов использовать на свой страх и риск
 */
export default {
  name: 'ReportBase',
  components: { ReportFiltersButtons },
  props: {
    report: MReportListService,
    schema: MSiSchema,

    containerComponent: {
      type: Object,
      default: () => ReportContainerBase,
    },
  },

  emits: [
    'onReportClick',
    'onResetClick',
  ],

  data () {
    return {
      /** @type {Vue} */
      containerApp: null,
    }
  },

  created () {
    if (!this.report || !this.schema) {
      throw new Error('Не указан отчёт или схема таблицы')
    }

    this.createReportContainerApp()

    PubSub.on(REPORT_EVENTS.EXPORT, () => { this.report.export() })
  },

  beforeDestroy () {
    PubSub.unsubscribe(REPORT_EVENTS.EXPORT)
  },

  methods: {
    createReportContainerApp () {
      this.containerApp = createVueApp({
        el: '#report_right_container',
        name: 'ReportContainerApp',
        render: (h) => h(this.containerComponent, {
          props: {
            report: this.report,
            schema: this.schema,
          },
        }),
      })
    },

    onReportClick () {
      this.report.setFiltersString()
      this.report.fetchAll()
        .then(() => {
          Services.telephony.reset()
        })
      this.$emit('onReportClick')
    },

    onResetClick () {
      this.report.resetFilters()
      this.report.setSorting('')
      this.report.resetData()
      this.$emit('onResetClick')
    },
  },
}
</script>
