import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getProtocolsSchema = () =>
  new MSiSchema()
    .addPagination()
    .addCreateButton()
    .addTableSchema(
      new MTableSchema({
        headers: {
          pdfGenerated: new MTableHeader().addFixedWidth('40px'),
          title: new MTableHeader(),
          edit: new MTableHeader().addFixedWidth('40px'),
        },
      })
        .addDeleteIcon(Services.security.canManageEntryTemplate)
    )
