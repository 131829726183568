<template>
  <div class="waiting-list-params flex-form">
    <!-- Дата -->
    <slot name="date">
      <div class="form-label">
        {{ t('common.date') }}
        <required-mark />
      </div>
      <div class="form-value">
        <validation-wrapper :errors="validationMessages.date">
          <date-picker
            :value="date"
            :disabled="disabled"
            class="el-select full-width"
            :date-format="GET_LOCALIZATION_DATEPICKER_FORMAT"
            :value-format="GET_LOCALIZATION_DATEPICKER_FORMAT"
            @input="$updateSync('date', $event)"
          />
        </validation-wrapper>
      </div>
    </slot>

    <!-- Врач -->
    <slot name="doctor">
      <div class="form-label">
        {{ t('doctor') }}
        <required-mark />
      </div>
      <div class="form-value">
        <validation-wrapper :errors="validationMessages.user_id">
          <user-simple-select
            :clinic-id="currentClinicId"
            :value="currentDoctor"
            :disabled="disabled"
            full-width
            @change="$updateSync('currentDoctor', $event)"
          />
        </validation-wrapper>
      </div>
    </slot>

    <!-- Клиника -->
    <slot name="clinic">
      <div class="form-label">
        {{ t('clinic') }}
      </div>
      <div class="form-value">
        <base-select
          :value="currentClinicId"
          :disabled="disabled"
          class="full-width"
          @change="$updateSync('currentClinicId', $event)"
        >
          <el-option
            v-if="clinicsArray.length > 1"
            :value="null"
            :label="t('all_clinics')"
          />
          <el-option
            v-for="(clinic, index) in clinicsArray"
            :key="index"
            :label="clinic.title"
            :value="clinic.id"
          />
        </base-select>
      </div>
    </slot>

    <!-- Дополнительно -->
    <slot name="note">
      <div class="form-label">
        {{ t('attributes.note') }}
      </div>
      <div class="form-value">
        <textarea
          :value="note"
          :disabled="disabled"
          rows="8"
          class="w-100"
          @input="$updateSync('note', $event.target.value)"
        />
      </div>
    </slot>

    <!-- Срочно -->
    <slot name="urgent">
      <div class="form-label">
        {{ t('urgent') }}
      </div>
      <div class="form-value">
        <checkbox
          :checked="isUrgent"
          :disabled="disabled"
          @change="$updateSync('isUrgent', $event)"
        />
      </div>
    </slot>

    <!-- Есть связанная запись -->
    <slot name="depends">
      <div class="form-label">
        {{ t('have_depended_appointment') }}
      </div>
      <div class="form-value">
        <checkbox
          disabled
          :checked="hasDependAppointment"
          @change="$updateSync('hasDependAppointment', $event)"
        />
      </div>
    </slot>

    <div class="form-value">
      <entity-info
        :created-by="createdBy"
        :updated-by="updatedBy"
      />
    </div>
  </div>
</template>

<script>
import props from './props'

import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import DatePicker from '@/vue_components/common/select/date_picker.vue'
import Checkbox from '@/vue_components/common/checkbox.vue'
import EntityInfo from '@/vue_components/entity_info.vue'
import BaseSelect from '@/vue_components/common/select/base_select/base_select.vue'
import RequiredMark from '@/vue_components/common/required_mark'
import { mapGetters } from 'vuex'
import UserSimpleSelect from '@/vue_components/common/select/UserSimpleSelect'

export default {
  name: 'WaitingListForm',
  components: {
    UserSimpleSelect,
    RequiredMark,
    ValidationWrapper,
    DatePicker,
    Checkbox,
    EntityInfo,
    BaseSelect,
  },

  props,

  data () {
    return {
      allDoctors: [],
    }
  },

  computed: {
    ...mapGetters([
      'GET_LOCALIZATION_DATEPICKER_FORMAT',
    ]),
  },
}
</script>
