<template>
  <switch-filter
    v-bind="$attrs"
    :label="t('reports.entry_with_order')"
    v-on="$listeners"
  />
</template>

<script>
import SwitchFilter from '@/vue_apps/ReportsModule/_filters/components/share/SwitchFilter.vue'

export default {
  name: 'EntryWithOrderFilter',
  components: { SwitchFilter },
}
</script>
