import { FILTERS_PROFILES_TYPES, FILTERS_PROFILES_TYPES_WHEN_FETCH } from '../const/consts'
import { IFiltersProfilesFetchResponse } from '../interfaces/IFIltersProfilesFetchResponse'
import { IFiltersProfilesModel } from '../interfaces/IFiltersProfilesModel'
import { administratorsProductivityReportFiltersProfileAdapter } from './specificAdapters/administratorsProductivityReportAdapter'
import { doctorAppointmentsReportFiltersProfileAdapter } from './specificAdapters/doctorAppointmentsReportAdapters'
import { doctorsProductivityReportFiltersProfileAdapter } from './specificAdapters/doctorsProductivityReportAdapter'
import { insuranceCompaniesReportFiltersProfileAdapter } from './specificAdapters/insuranceCompaniesReportAdapter'
import { proceduresReportFiltersProfileAdapter } from './specificAdapters/proceduresReportAdapter'
import { referralsReportFiltersProfileAdapter } from './specificAdapters/referralsReportAdapters'
import { salaryReportFiltersProfileAdapter } from './specificAdapters/salaryReportAdapter'
import { treatmentPlansReportFiltersProfileAdapter } from './specificAdapters/treatmentPlansReportAdapter'

const toClientAdaptersByType: Record<number, Function> = {
  [FILTERS_PROFILES_TYPES.ADMINISTRATOR_PRODUCTIVITY]: administratorsProductivityReportFiltersProfileAdapter.toClient,
  [FILTERS_PROFILES_TYPES.DOCTOR_PRODUCTIVITY]: doctorsProductivityReportFiltersProfileAdapter.toClient,
  [FILTERS_PROFILES_TYPES.INSURANCE_COMPANIES]: insuranceCompaniesReportFiltersProfileAdapter.toClient,
  [FILTERS_PROFILES_TYPES.DOCTOR_APPOINTMENTS_REPORT]: doctorAppointmentsReportFiltersProfileAdapter.toClient,
  [FILTERS_PROFILES_TYPES.TREATMENT_PLANS_REPORT]: treatmentPlansReportFiltersProfileAdapter.toClient,
  [FILTERS_PROFILES_TYPES.PROCEDURES]: proceduresReportFiltersProfileAdapter.toClient,
  [FILTERS_PROFILES_TYPES.REFERRALS]: referralsReportFiltersProfileAdapter.toClient,
  [FILTERS_PROFILES_TYPES.SALARY]: salaryReportFiltersProfileAdapter.toClient,
}

export const filtersProfilesGeneralFetchAdapter = {
  toClient (data: IFiltersProfilesFetchResponse): IFiltersProfilesModel {
    const profileType = FILTERS_PROFILES_TYPES_WHEN_FETCH[data.profileType]
    const toClientFetchAdapter = toClientAdaptersByType[profileType]

    return toClientFetchAdapter(data)
  },
}
