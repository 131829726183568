<template>
  <filters-profiles-app
    ref="originalApp"
    :filters-map="filtersMap"
    :profile-type="profileType"
    use-submit-delegating
    @onSubmit="onSubmit"
    @onProfileSelect="setFilters"
  />
</template>

<script>
import FiltersProfilesApp from './FiltersProfilesApp.vue'

export default {
  name: 'FiltersProfilesAppForLegacy',
  components: { FiltersProfilesApp },

  props: {
    getFilters: { type: Function, required: true },
    setFilters: { type: Function, required: true },
    profileType: { type: Number, required: true },
  },

  data () {
    return { filtersMap: null }
  },

  methods: {
    onSubmit () {
      this.filtersMap = this.getFilters()
      this.$nextTick(this.$refs.originalApp.submit)
    },
  },
}
</script>
