import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class SalaryByPaymentsItem {
  /** @param {TSalaryByPaymentsDoctorData} doctorData */
  constructor (doctorData) {
    this.id = doctorData.id
    this.fullName = doctorData.fullName
    this.analysisSalary = doctorData.analysisSalary
    this.goodsSalary = doctorData.goodsSalary
    this.servicesSalary = doctorData.servicesSalary
    this.totalSalary = doctorData.totalSalary
    this.avatarTag = doctorData.avatarTag
  }

  get tableDoctor () {
    return new MTableCell(this.fullName)
      .addTypeUserWithAvatar(this.id, this.avatarTag)
      .addTooltip(this.fullName)
  }

  get tableGoods () {
    return new MTableCell(vueFilters.price(this.goodsSalary))
  }

  get tableAnalysis () {
    return new MTableCell(vueFilters.price(this.analysisSalary))
  }

  get tableServices () {
    return new MTableCell(vueFilters.price(this.servicesSalary))
  }

  get tableTotal () {
    return new MTableCell(vueFilters.price(this.totalSalary))
  }
}
