import Vue from 'vue/dist/vue.esm'
import WaitingList from '@/vue_components/doctor_schedules/waiting_list.vue'
import { createStore } from '@/vue_components/store/store.js'
import { MODAL_TYPES } from '@/vue_components/doctor_schedules/consts'
import WaitingCreateModal from '@/vue_components/doctor_schedules/components/waiting_create_modal.vue'
import WaitingEditModal from '@/vue_components/doctor_schedules/components/waiting_edit_modal.vue'
import { fetchDoctorAppointments, fetchWaitingListCount } from '@/vue_components/doctor_schedules/rest'
import { MAX_TRY_COUNT, REQUEST_TIMEOUT_DELAY } from '@/plugins/schedule/scheduleCommon/const'
import { mapGetters } from 'vuex'
import { doctorAreasEndpoint } from '@/specific/doctor_areas/doctorAreasEndpoint'
import { ModalHolder } from '@/vue_components/mixins/modals/modal_holder'
import CreateLegalRepresentativeModal from '@/vue_components/common/client/create_legal_representative_modal'
import CompanyCreateModal from '@/vue_components/companies/components/company_create_modal'
import { companiesEndpoint } from '@/vue_components/companies/rest'
import { AlertHolder } from '@/vue_components/mixins/modals/alert_holder'
import { ConfirmationHolder } from '@/vue_components/mixins/modals/confirmation_holder'

/**
 * @enum {string}
 * @type {Readonly<{NEXT_PERIOD: string, PREV_PERIOD: string}>}
 */
const ScheduleDateSteps = Object.freeze({
  NEXT_PERIOD: 'nextPeriod',
  PREV_PERIOD: 'prevPeriod',
})

class ScheduleDateHelper {
  constructor ($schedule, $calendar) {
    this.$schedule = $schedule
    this.$calendar = $calendar
    this.loadTryCount = 0
    this.dateFormat = createStore().getters.GET_LOCALIZATION_DATE_FORMAT
  }

  /**
   *
   * @param {Date} date
   */
  setInitialDate (date) {
    this.$calendar.attr('data-date', moment(date).format(this.dateFormat))
  }

  /**
   * @param {ScheduleDateSteps} direction
   */
  stepScheduleDate (direction) {
    this.$schedule.doctorScheduleGrid(direction)
    const date = this.$schedule.doctorScheduleGrid('getDate')
    this.loadSchedule(date)
  }

  /**
   * @param {Date} date
   */
  loadSchedule (date) {
    this.$schedule.loadSpin('start')

    if (this.loadTryCount > MAX_TRY_COUNT) {
      this.$schedule.loadSpin('stop')

      return
    }

    console.log('Getting schedule, try number ' + (++this.loadTryCount))

    fetchDoctorAppointments({
      date: moment(date).format(this.dateFormat),
      user_ids: JSON.stringify([gon.application.current_user.id]),
      days_count: this.$schedule.doctorScheduleGrid('getDaysCount'),
      only_working_days: false,
      clinic_id: gon.application.current_clinic.id,
    })
      .then((schedule) => {
        this.$schedule.doctorScheduleGrid('setLoadData', schedule)
        this.setCalendarDate(date)
        this.loadTryCount = 0
      })
      .catch((error) => {
        console.error(error)
        setTimeout(() => {
          this.loadSchedule(date)
        }, REQUEST_TIMEOUT_DELAY)
      })
  }

  /**
   * @param {Date|moment} date
   */
  setCalendarDate (date) {
    const sourceDate = moment.isMoment(date) ? date : moment(date)
    if (!sourceDate.isSame(this.$calendar.datepicker('getDate'))) {
      this.$calendar.datepicker('setDate', sourceDate.toDate())
    }
  }
}

$document.on('rez/doctor_areas/index', function () {
  const $changeDayButtons = $('.change-day-buttons')
  const $nextPeriod = $changeDayButtons.find('#schedule_next_period')
  const $prevPeriod = $changeDayButtons.find('#schedule_previous_period')
  const $weekScheduleGrid = $('#week-schedule-grid')
  const $showCalendarButton = $('#show-calendar-button')
  const $scheduleContainer = $('#single_schedule')
  const $innerContainer = $scheduleContainer.find('.inner-container')
  const $scheduleWrapper = $innerContainer.find('#schedule-wrapper')
  const $doctorSchedule = $('#schedule_block')
  const $stepSelect = $('#change_step')
  const $table = $('#doctor_areas_table')
  const $buttonTriggerGroup = $('#btn-trigger-group')
  const $triggerSchedule = $buttonTriggerGroup.find('#side-menu-trigger-schedule')
  const $triggerWaitingList = $buttonTriggerGroup.find('#side-menu-trigger-waiting-list')
  const $waitingListCounter = $buttonTriggerGroup.find('#waiting-list-counter')
  let waitingList

  // Schedule Grid Appointment Manager - догадка.
  // Переименовать, если будет найден истинный смысл аббревиатуры.
  Services.SGAM = (() => {
    const DOCTOR_AREAS_STORAGE = 'doctor_areas_storage'

    return {
      page: window.location.pathname,
      WAITING_LIST: 'waitingList',
      SCHEDULE: 'schedule',
      NEXT_PERIOD: 'nextPeriod',
      PREV_PERIOD: 'prevPeriod',
      ui: {
        canViewWaitingList: Services.security.canViewWaitingList,
      },

      init () {
        const currentStorage = this.getPageStore() || Object.create(null)
        currentStorage.rightPanelActiveElement =
          currentStorage.rightPanelActiveElement === undefined ? this.SCHEDULE : currentStorage.rightPanelActiveElement
        currentStorage.rightPanelActiveElement =
          this.ui.canViewWaitingList ? currentStorage.rightPanelActiveElement : this.SCHEDULE

        this.setPageStore(currentStorage)

        this.setRightPanelActiveElement(currentStorage.rightPanelActiveElement)

        Utils.LocalStorage.loadGridCheck()
      },
      getPageStore () {
        return store.get(DOCTOR_AREAS_STORAGE)
      },
      setPageStore (stateObject) {
        store.set(DOCTOR_AREAS_STORAGE, stateObject)
      },
      updatePageStore (state) {
        const oldState = this.getPageStore()
        this.setPageStore({ ...oldState, ...state })
      },
      setRightPanelActiveElement (element) {
        switch (element) {
          case this.SCHEDULE:
            $changeDayButtons.show(50)
            $triggerSchedule.addClass('active')
            $triggerWaitingList.removeClass('active')
            this.updatePageStore({ rightPanelActiveElement: this.SCHEDULE })
            this.ui.canViewWaitingList && (waitingList.visibility = false)
            $scheduleWrapper.show(0)
            break
          case this.WAITING_LIST:
            $changeDayButtons.hide(50)
            $triggerSchedule.removeClass('active')
            $triggerWaitingList.addClass('active')
            this.updatePageStore({ rightPanelActiveElement: this.WAITING_LIST })
            this.ui.canViewWaitingList && (waitingList.visibility = true)
            $scheduleWrapper.hide(0)
            break
        }
      },
    }
  })()

  const SGAM = Services.SGAM
  const $modal074yButton = $('#modal_074y_btn')
  const $modal074y = $('#form_074_y_modal')
  const $periodInput = $modal074y.find('.doc_date_period')
  const $startNumberingWith = $modal074y.find('.doc_start_numbering_with')
  const $printHeader = $modal074y.find('.doc_print_header')
  const scheduleDateHelper = new ScheduleDateHelper($doctorSchedule, $showCalendarButton)

  //region buttons
  $nextPeriod.on('click', function () {
    scheduleDateHelper.stepScheduleDate(ScheduleDateSteps.NEXT_PERIOD)
  })

  $prevPeriod.on('click', function () {
    scheduleDateHelper.stepScheduleDate(ScheduleDateSteps.PREV_PERIOD)
  })

  $stepSelect.on('change', function () {
    const step = $stepSelect.val()
    $doctorSchedule.doctorScheduleGrid('option', { step })
  })

  $table.on('click', '.toggle-content-button', function (e) {
    const $el = $(this)
    const $row = $el.parents('tr')
    $el.toggleClass('fa-chevron-down fa-chevron-up')
    $row.find('div.hidden-content').toggleClass('hidden')
    $row.find('.js-entry-titles').toggleClass('hidden')
    e.stopPropagation()
  })
  //endregion

  //region modals
  $modal074yButton.on('click', function (e) {
    e.preventDefault()
    $modal074y.modal('show')
  })

  $modal074y.on('click', '.modal-save', function () {
    const params = {
      query_name: 'Form074y',
      fixed_doc_type: true,
      path: 'form_074_y',
      title: t('medical_card_form_074_y'),
      date_range: $periodInput.val(),
      start_numbering_with: $startNumberingWith.val(),
      print_header: $printHeader.val(),
      hide_submit: true,
    }
    const url = Routes.new_document_path(params)
    Turbolinks.visit(url)
  })
  //endregion

  //region waiting list
  if (SGAM.ui.canViewWaitingList) {
    waitingList = new Vue({
      el: '#waiting-list-wrapper',
      store: createStore(),
      components: {
        WaitingList,
        WaitingCreateModal,
        WaitingEditModal,
        CreateLegalRepresentativeModal,
        CompanyCreateModal,
      },
      mixins: [
        ModalHolder,
        AlertHolder,
        ConfirmationHolder,
      ],
      data () {
        return {
          clientGroups: [],
          documentTypes: [],
          companyTypes: [],
          preset: 'doctor',
          currentClinicId: gon.application.current_clinic.id,
          currentUserId: gon.application.current_user.id,
          waitingListCount: 0,
          visibility: false,
          modal: {
            [MODAL_TYPES.CREATE_WAITING_ITEM]: {
              visibility: false,
              disabled: false,
              loading: false,
              clientEdited: false,
              clientTouched: false,
            },

            [MODAL_TYPES.EDIT_WAITING_ITEM]: {
              visibility: false,
              disabled: false,
              loading: false,
              clientEdited: false,
              clientTouched: false,
            },

            [MODAL_TYPES.LEGAL_REPRESENTATIVE]: {
              visibility: false,
            },

            [MODAL_TYPES.COMPANY]: {
              visibility: false,
            },
          },
        }
      },
      computed: {
        ...mapGetters([
          'GET_CURRENT_USER_CLINICS_LIST',
          'GET_SCHEDULE_DATE',
        ]),
      },
      watch: {
        waitingListCount (newValue) {
          $waitingListCounter.text(newValue)
        },
      },
      created () {
        this.init()
        this.loadWaitingListCount({
          user_id: this.currentUserId,

        })
        this.fetchCompanyTypes()
        // добавляем текущего юзера в кэш, потому что из него берутся юзеры для расписания
        AppCache.store('user', this.$store.state.appConf.current_user)
      },
      methods: {
        init () {
          this.$store.dispatch('initialScheduleStore')
          doctorAreasEndpoint.getInitialData()
            .then(({
              client_groups: clientGroups,
              id_card_titles: documentTypes,
            }) => {
              this.clientGroups = clientGroups
              this.documentTypes = documentTypes
            })
            .catch(Utils.reportError('doctorAreasEndpoint.getInitialData'))
        },

        loadWaitingListCount (filters) {
          fetchWaitingListCount(filters)
            .then(this.updateWaitingListCounter)
            .catch(Utils.reportError('fetchWaitingListCount'))
        },

        fetchCompanyTypes () {
          const companyModal = this.modal[MODAL_TYPES.COMPANY]
          companyModal.loadingTypes = true
          companiesEndpoint.getCompanyTypes()
            .then((companyTypes) => {
              companyModal.companyTypes = companyTypes
            })
            .catch(Utils.reportError('companiesEndpoint.getCompanyTypes'))
            .finally(() => {
              companyModal.loadingTypes = false
            })
        },

        updateWaitingListCounter (count) {
          this.waitingListCount = count
        },

        openEditWaitingItemForm (item) {
          this.openModal(MODAL_TYPES.EDIT_WAITING_ITEM)
          this.$nextTick(() => {
            this.$refs.editWaitingItem.openEditWaitingItemForm(item)
          })
        },

        addLegalRepresentative (legalRep) {
          const newLegalRep = {
            id: legalRep.id,
            name: legalRep.name,
            surname: legalRep.surname,
            secondName: legalRep.second_name || legalRep.secondName,
            label: legalRep.short_info,
          }
          this.$store.commit('waitingList/ADD_LEGAL_REP', newLegalRep)
        },

        setCreatedCompanyData (company) {
          this.$store.commit('legalRepresentatives/SET_COMPANY', company)
        },
      },
      template:
        `
          <div
            v-if="visibility"
            class="doctor-areas__waiting-list-container"
          >
            <waiting-list
              :preset="preset"
              :currentUserId="currentUserId"
              :visibility="visibility"
              :waiting-list-count="waitingListCount"
              @count-change="loadWaitingListCount"
              @filters-change="loadWaitingListCount"
              @create-waiting-item="openModal(MODAL_TYPES.CREATE_WAITING_ITEM)"
              @edit-waiting-item="openEditWaitingItemForm"
            />

            <waiting-create-modal
              v-if="modal.createWaitingItem.visibility"
              :visibility="modal.createWaitingItem.visibility"
              :date="GET_SCHEDULE_DATE"
              :client-groups="clientGroups"
              @close="closeModal(MODAL_TYPES.CREATE_WAITING_ITEM)"
              @add-legal-representative="openModal(MODAL_TYPES.LEGAL_REPRESENTATIVE)"
            />

            <waiting-edit-modal
              ref="editWaitingItem"
              v-if="modal.editWaitingItem.visibility"
              :visibility="modal.editWaitingItem.visibility"
              :client-groups="clientGroups"
              @close="closeModal(MODAL_TYPES.EDIT_WAITING_ITEM)"
              @add-legal-representative="openModal(MODAL_TYPES.LEGAL_REPRESENTATIVE)"
            />

            <create-legal-representative-modal
              v-if="modal.legal.visibility"
              :client-groups="clientGroups"
              :document-types="documentTypes"
              @add-company="openModal(MODAL_TYPES.COMPANY)"
              @close="closeModal(MODAL_TYPES.LEGAL_REPRESENTATIVE)"
              @create="addLegalRepresentative"
            />

            <company-create-modal
              v-if="modal.company.visibility"
              :company-types="modal.company.companyTypes"
              @close="closeModal(MODAL_TYPES.COMPANY)"
              @create="setCreatedCompanyData"
            />

            <alert-modal
              v-if="modal.alert.visibility"
              v-bind="alertProps"
              modal-class="doctor-schedules-alert-modal"
              @ok="hideAlert"
              @close="hideAlert"
            />

            <confirmation-modal
              v-if="modal.confirmation.visibility"
              modal-class="doctor-schedules-confirmation-modal"
              v-bind="confirmationProps"
              @yes="confirmationYesHandler"
              @no="confirmationNoHandler"
            />
          </div>`,
    })
  } else {
    $buttonTriggerGroup.hide(0)
    $('#waiting-list-wrapper').hide(0)
  }
  //endregion

  //region calendar
  Utils.initCalendar()

  scheduleDateHelper.setInitialDate(new Date())
  $showCalendarButton.datepicker({
    todayBtn: 'linked',
    language: gon.localization.locale,
    todayHighlight: true,
    weekStart: gon.localization.day_week_start,
    format: gon.localization.date_format,
    startDate: 0,
  })

  $showCalendarButton.on('changeDate', (e) => {
    if (!e.date) return

    const date = moment(e.date)
    const prevDate = $doctorSchedule.doctorScheduleGrid('getDate')
    if (!date.isSame(prevDate)) {
      scheduleDateHelper.loadSchedule(date.toDate())
    }
    $showCalendarButton.datepicker('hide')
  })
  //endregion

  //region doctor schedule init
  $doctorSchedule.doctorScheduleGrid({
    data: gon.specific.scheduler,
    previewPreset: $.scheduleGrid.previewPresets.DOCTOR,
    disableDnD: true,
    tableVisibility: true,
    currentClinicId: gon.application.current_clinic.id,
  })

  $doctorSchedule.doctorScheduleGrid('setDaysCount', 1)
  // endregion

  //region navigation and toggling
  $triggerSchedule.on('click', function () {
    SGAM.setRightPanelActiveElement(SGAM.SCHEDULE)
  })

  $triggerWaitingList.on('click', function () {
    SGAM.setRightPanelActiveElement(SGAM.WAITING_LIST)
  })

  $weekScheduleGrid.on('click', function () {
    Turbolinks.visit(Routes.doctor_areas_schedule_path())
  })
  //endregion

  //region managers init
  SortItems.init({
    itemName: ['medical_records'],
    url: 'doctor_areas',
    defaults: { period: 'today' },
    dataAdapter (response) {
      response.data.forEach((appointment) => {
        appointment.shouldShowDropdown = shouldShowAppointmentDropdown(appointment)
        appointment.entriesDropdownTitle = entriesDropdownTitle(appointment.entries)
      })

      return response
    },
  })

  function entriesDropdownTitle (entries) {
    const restEntriesCount = entries.length - 1
    const restEntries = restEntriesCount > 0
      ? ` ${t('and_also')} ${restEntriesCount} ${t('entry_plurals', { count: restEntriesCount })}`
      : ''

    return entries.length
      ? `${entries[0].title}${restEntries}`
      : ''
  }

  function shouldShowAppointmentDropdown (appointment) {
    return appointment.entries.length > 1 ||
      (appointment.entries.length && gon.application.show_price_in_doctor_areas)
  }

  SGAM.init()
  //endregion

  $doctorSchedule.doctorScheduleGrid('cancelPaste')
})

$document.on('rez/doctor_areas/schedule', function () {
  const $doctorSchedule = $('#schedule_block')
  const $calendar = $('#schedule-calendar')
  const $stepSelect = $('#change_step')
  const $scheduleTitle = $('#schedule_title')
  const $nextPeriod = $('#schedule_next_period')
  const $prevPeriod = $('#schedule_previous_period')
  const $mySchedule = $('#my-schedule')
  const daysCount = 7
  const scheduleDateHelper = new ScheduleDateHelper($doctorSchedule, $calendar)

  scheduleDateHelper.setInitialDate(new Date())
  $calendar.datepicker({
    todayBtn: 'linked',
    language: gon.localization.locale,
    todayHighlight: true,
    weekStart: gon.localization.day_week_start,
    format: gon.localization.date_format,
  }).on('changeDate', (e) => {
    if (!e.date) return

    const date = moment(e.date)
    const prevDate = $doctorSchedule.doctorScheduleGrid('getDate')
    if (!date.isSame(prevDate)) {
      $doctorSchedule.doctorScheduleGrid('setDate', date)
      scheduleDateHelper.loadSchedule(date.toDate())
    }
  })

  $doctorSchedule.doctorScheduleGrid({
    data: gon.specific.scheduler,
    onChange (params) {
      const date = moment(params.date)
      scheduleDateHelper.setCalendarDate(date)
      let humanDate = date.format('DD MMMM YYYY dddd')
      if (daysCount > 1) {
        humanDate += ' - ' +
          date.clone().add(daysCount - 1, 'days').format('DD MMMM YYYY dddd')
      }
      $scheduleTitle.text(humanDate)
    },
    previewPreset: $.scheduleGrid.previewPresets.DOCTOR,
    disableDnD: true,
    hideUsersWithoutSchedule: false,
    tableVisibility: true,
    currentClinicId: gon.application.current_clinic.id,
  })

  $nextPeriod.on('click', () => {
    scheduleDateHelper.stepScheduleDate(ScheduleDateSteps.NEXT_PERIOD)
  })
  $prevPeriod.on('click', () => {
    scheduleDateHelper.stepScheduleDate(ScheduleDateSteps.PREV_PERIOD)
  })
  $mySchedule.on('click', () => Turbolinks.visit(Routes.doctor_areas_path()))

  $stepSelect.on('change', function () {
    const step = $stepSelect.val()
    $doctorSchedule.doctorScheduleGrid('option', { step })
  })

  $doctorSchedule.doctorScheduleGrid('setDaysCount', daysCount)
})
