import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class TreatmentPlansReportTotalCells {
  /**
   * @param {ITreatmentPlansReportResponseTotal} props
   */
  constructor (props, count = null) {
    this.numberCell = new MTableCell(t('reports.treatmentPlans.total', { count: count || '' }))

    this.finalSumCell = new MTableCell(vueFilters.currency(props.finalSum))
      .addTooltip(true)
    this.paidSumCell = new MTableCell(vueFilters.currency(props.paidSum))
      .addTooltip(true)
    this.totalCell = new MTableCell(vueFilters.currency(props.total))
      .addTooltip(true)
    this.treatmentEntriesAmountCell = new MTableCell(props.treatmentEntriesAmount)
    this.treatmentEntriesCreatedAmountCell = new MTableCell(props.treatmentEntriesCreatedAmount)
    this.treatmentEntriesCreatedTotalSumCell = new MTableCell(vueFilters.currency(props.treatmentEntriesCreatedTotalSum))
      .addTooltip(true)
    this.unpaidSumCell = new MTableCell(vueFilters.currency(props.unpaidSum))
      .addTooltip(true)
  }
}
