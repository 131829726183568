<template>
  <modal
    :modal-visibility="modalVisible"
    @close="closeModal"
  >
    <template #header>
      {{ T.print_custom_receipt }}
    </template>

    <template #body>
      <div id="receipt_form">
        <epic-spinner :visibility="showLoadingSpinner" />

        <div class="receipt_kind">
          <el-row
            type="flex"
            class="row-bg"
            justify="space-between"
          >
            <el-col :span="6">
              <div class="title">
                <span>{{ T.fiscal.receipt_kind }}</span>
                <abbr
                  class="required tooltip-bottom"
                  :title="T.fiscal.required"
                > * </abbr>
              </div>

              <div class="value">
                <el-select
                  v-model="receipt.kind"
                  size="mini"
                >
                  <el-option
                    v-for="(receiptKind, index) in receiptInfo.receiptKinds"
                    :key="`${receiptKind}_${index}`"
                    :label="T.fiscal.receipt_kinds[receiptKind]"
                    :value="receiptKind"
                  />
                </el-select>
              </div>
            </el-col>
          </el-row>
        </div>
        <hr>
        <div v-if="correctionReceipt">
          <h4>Основание коррекции</h4>
          <el-row
            type="flex"
            class="row-bg"
            justify="space-between"
          >
            <el-col :span="7">
              <div class="correction_type">
                <div class="title">
                  <span>{{ T.fiscal.correction_type }}</span>
                  <abbr
                    class="required tooltip-bottom"
                    :title="T.fiscal.required"
                  > * </abbr>
                </div>

                <div class="value">
                  <el-select
                    v-model="receipt.correction.type"
                    size="mini"
                  >
                    <el-option
                      v-for="(correctionType, index) in receiptInfo.correctionTypes"
                      :key="`${correctionType}_${index}`"
                      :label="T.fiscal.correction_types[correctionType]"
                      :value="correctionType"
                    />
                  </el-select>
                </div>
              </div>
            </el-col>

            <el-col :span="17">
              <div class="correction_reason">
                <div class="title">
                  <span>{{ T.fiscal.correction_reason }}</span>
                  <abbr
                    class="required tooltip-bottom"
                    :title="T.fiscal.required"
                  > * </abbr>
                </div>

                <div class="value">
                  <el-input v-model="receipt.correction.reason" />
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row
            type="flex"
            class="row-bg"
            justify="space-between"
          >
            <el-col :span="7">
              <div class="correction_date">
                <div class="title">
                  <span>{{ T.fiscal.correction_date }}</span>
                  <abbr
                    class="required tooltip-bottom"
                    :title="T.fiscal.required"
                  > * </abbr>
                </div>

                <div class="value">
                  <el-date-picker
                    v-model="receipt.correction.date"
                    type="date"
                    :format="dateFormat"
                  />
                </div>
              </div>
            </el-col>
            <el-col :span="17">
              <div class="correction_number">
                <div class="title">
                  <span>{{ T.fiscal.correction_number }}</span>
                  <abbr
                    class="required tooltip-bottom"
                    :title="T.fiscal.required"
                  > * </abbr>
                </div>

                <div class="value">
                  <el-input v-model="receipt.correction.number" />
                </div>
              </div>
            </el-col>
          </el-row>
          <hr>
        </div>
        <h4>Позиции чека ({{ entriesSum }})</h4>

        <div
          v-for="n in entriesCount"
          :key="`entry_${n}`"
          class="receipt_entry"
        >
          <div class="title">
            <span>{{ T.fiscal.receipt_entry.title }}</span>
            <abbr
              class="required tooltip-bottom"
              :title="T.fiscal.required"
            > * </abbr>
          </div>

          <div class="value">
            <el-input v-model="receipt.entries[n - 1].title" />
          </div>

          <el-row
            type="flex"
            class="row-bg"
            justify="space-between"
          >
            <el-col :span="6">
              <div class="title">
                <span>{{ T.fiscal.receipt_entry.kind }}</span>
                <abbr
                  class="required tooltip-bottom"
                  :title="T.fiscal.required"
                > * </abbr>
              </div>
              <div class="value">
                <el-select
                  v-model="receipt.entries[n - 1].kind"
                  size="mini"
                >
                  <el-option
                    v-for="(entryKind, index) in receiptInfo.entryKinds"
                    :key="index"
                    :label="T.fiscal.entry_kinds[entryKind]"
                    :value="entryKind"
                  />
                </el-select>
              </div>
            </el-col>

            <el-col :span="6">
              <div class="title">
                <span>{{ T.fiscal.receipt_entry.price }}</span>
                <abbr
                  class="required tooltip-bottom"
                  :title="T.fiscal.required"
                > * </abbr>
              </div>
              <div class="value">
                <el-input
                  v-model="receipt.entries[n - 1].price"
                  type="number"
                  min="0.0"
                />
              </div>
            </el-col>

            <el-col :span="6">
              <div class="title">
                <span>{{ T.fiscal.receipt_entry.amount }}</span>
                <abbr
                  class="required tooltip-bottom"
                  :title="T.fiscal.required"
                > * </abbr>
              </div>

              <div class="value">
                <el-input
                  v-model="receipt.entries[n - 1].amount"
                  type="number"
                  min="0"
                />
              </div>
            </el-col>

            <el-col
              class="calculated_sum"
              :span="6"
            >
              <div class="title">
                <span>{{ T.fiscal.receipt_entry.sum }}</span>
              </div>

              <div class="value">
                <span>
                  {{ entrySum(receipt.entries[n - 1].price, receipt.entries[n - 1].amount) }}
                </span>
              </div>
            </el-col>

            <el-col :span="1">
              <div class="title" />

              <div class="value">
                <el-button
                  class="fad fa-trash-alt btn btn-danger"
                  size="mini"
                  @click="removeEntry(n - 1)"
                />
              </div>
            </el-col>
          </el-row>
        </div>
        <el-button
          v-if="addEntryBtnShow"
          :class="['btn', 'btn-xs']"
          size="mini"
          @click="addEntry"
        >
          {{ T.fiscal.receipt_entry.add }}
        </el-button>
        <hr>
        <h4>Оплата ({{ paymentsSum }})</h4>

        <div
          v-for="n in paymentsCount"
          :key="`payment_${n}`"
          class="receipt_payments"
        >
          <el-row
            type="flex"
            class="row-bg"
            justify="space-between"
          >
            <el-col :span="6">
              <div class="title">
                <span>{{ T.fiscal.receipt_payment.kind }}</span>
                <abbr
                  class="required tooltip-bottom"
                  :title="T.fiscal.required"
                > * </abbr>
              </div>

              <div class="value">
                <el-select
                  v-model="receipt.payments[n - 1].kind"
                  size="mini"
                >
                  <el-option
                    v-for="(paymentKind, index) in receiptInfo.paymentKinds"
                    :key="index"
                    :label="T.fiscal.payment_kinds[paymentKind]"
                    :value="paymentKind"
                  />
                </el-select>
              </div>
            </el-col>

            <el-col :span="15">
              <div class="title">
                <span>{{ T.fiscal.receipt_payment.sum }}</span>
                <abbr
                  class="required tooltip-bottom"
                  :title="T.fiscal.required"
                > * </abbr>
              </div>

              <div class="value">
                <el-input
                  v-model="receipt.payments[n - 1].sum"
                  type="number"
                />
              </div>
            </el-col>

            <el-col :span="1">
              <div class="title" />

              <div class="value">
                <el-button
                  class="fad fa-trash-alt btn btn-danger"
                  size="mini"
                  @click="removePayment(n - 1)"
                />
              </div>
            </el-col>
          </el-row>
        </div>
        <el-button
          :class="['btn', 'btn-xs']"
          size="mini"
          @click="addPayment"
        >
          {{ T.fiscal.receipt_payment.add }}
        </el-button>
      </div>
    </template>

    <template #footer-right>
      <el-button
        id="send_receipt_btn"
        :class="['btn', 'btn-primary', 'btn-sm']"
        @click="sendReceipt"
      >
        {{ T.save }}
      </el-button>
    </template>
  </modal>
</template>
<script>
import Modal from '@/vue_components/modal.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'

export default {
  name: 'PrintRawReceiptModal',
  components: {
    Modal, EpicSpinner,
  },
  data: () => ({
    questionnaireValidated: false,
    receiptInfo: {},
    modalVisible: true,
    receipt: {
      correction: {},
      entries: [],
      payments: [],
    },
    showLoadingSpinner: false,
  }),
  computed: {
    dateFormat () {
      if (gon.localization.date_format) return gon.localization.date_format.replace(/mm/, 'MM')

      return `yyyy-MM-dd`
    },

    entriesCount () {
      if (this.receipt.entries instanceof Array) return this.receipt.entries.length

      return 0
    },

    paymentsCount () {
      if (this.receipt.payments instanceof Array) return this.receipt.payments.length

      return 0
    },

    correctionReceipt () {
      if (this.receipt.kind) {
        if (this.receipt.kind.includes('correction')) return true
      }

      return false
    },

    entriesSum () {
      return this.round(this.receipt.entries.reduce(
        (sum, entry) => sum + (parseFloat(entry.price) * parseInt(entry.amount)),
        0,
      ))
    },

    paymentsSum () {
      return this.round(this.receipt.payments.reduce(
        (sum, payment) => sum + parseFloat(payment.sum),
        0,
      ))
    },

    addEntryBtnShow () {
      if (this.correctionReceipt) return this.receipt.entries.length === 0

      return true
    },
  },
  mounted () {
    this.requestReceiptInfo()
  },
  methods: {
    entrySum (price, amount) {
      return this.round(parseFloat(price) * parseInt(amount))
    },

    round (number) {
      const roundedNumber = Number.isNaN(number) ? 0 : number

      return roundedNumber.toFixed(2)
    },

    requestReceiptInfo () {
      this.showLoadingSpinner = true
      Services.pubSub.subscribe('workplace.get_receipt_info', this.saveReceiptInfo)

      Services.workplace.sendMessage('get_receipt_info')
    },

    saveReceiptInfo (msg) {
      this.receiptInfo = msg.data
      this.showLoadingSpinner = false
    },

    chooseFromVariants (question) {
      if (question.variants instanceof Array) return question.variants.length !== 0

      return false
    },

    addEntry () {
      this.receipt.entries.push({})
    },

    removeEntry (index) {
      this.receipt.entries.splice(index, 1)
    },

    addPayment () {
      this.receipt.payments.push({})
    },

    removePayment (index) {
      this.receipt.payments.splice(index, 1)
    },

    sendReceipt () {
      if (!this.validateForm()) return

      Services.workplace.sendMessage('print_custom_receipt', { data: this.receipt })
    },

    validateForm () {
      this.receipt.payments = this.receipt.payments.filter((payment) => (payment.sum !== 0) && (payment.sum !== undefined))
      this.receipt.entries = this.receipt.entries.filter((entry) => (entry.title !== '') && (entry.title !== undefined))
      this.receipt.correction.date = Utils.getBaseFormattedDate(this.receipt.correction.date)

      if ((this.receipt.kind === '') || (this.receipt.kind === undefined)) {
        Notificator.error(T.fiscal.error.receipt_kind_should_be_present)

        return false
      }

      if (this.receipt.entries.length === 0) {
        Notificator.error(T.fiscal.error.entries_should_be_present)

        return false
      }

      if (this.receipt.payments.length === 0) {
        Notificator.error(T.fiscal.error.payments_should_be_present)

        return false
      }

      if (this.entriesSum !== this.paymentsSum) {
        Notificator.error(T.fiscal.error.sum_match_error)

        return false
      }

      return true
    },

    closeModal () {
      this.modalVisible = false
    },
  },
}
</script>
