<template>
  <div class="teeth-selector">
    <m-switch
      class="teeth-selector__teeth-kind mb-indent-mid"
      :value="teethKind"
      inactive-color="#3FAECA"
      active-color="#8BC34A"
      inactive-text="Постоянные зубы"
      active-text="Молочные зубы"
      inactive-value="adult"
      active-value="kid"
      @change="onChange"
    />

    <div class="up-teeth dent-container">
      <tooth
        v-for="tooth in teethMap[teethKind].slice(0, teethSideNumber)"
        :key="tooth.num"
        :tooth="tooth.num"
        :selected="tooth.selected"
        @click="onToothClick(tooth)"
      />

      <tooth
        v-for="(tooth, index) in teethMap[teethKind].slice(teethSideNumber, teethSideNumber * 2)"
        :key="tooth.num"
        :class="{ 'ml-15': !index }"
        :tooth="tooth.num"
        :selected="tooth.selected"
        @click="onToothClick(tooth)"
      />
    </div>

    <div class="down-teeth dent-container">
      <tooth
        v-for="tooth in teethMap[teethKind].slice(teethSideNumber * 2, teethSideNumber * 3)"
        :key="tooth.num"
        :tooth="tooth.num"
        :selected="tooth.selected"
        @click="onToothClick(tooth)"
      />

      <tooth
        v-for="(tooth, index) in teethMap[teethKind].slice(teethSideNumber * 3, teethSideNumber * 4)"
        :key="tooth.num"
        :class="{ 'ml-15': !index }"
        :tooth="tooth.num"
        :selected="tooth.selected"
        @click="onToothClick(tooth)"
      />
    </div>
  </div>
</template>

<script>
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import { MLocalStorage } from '@/_api/_storage/MLocalStorage'
import { getTeethSideNumber, teethMap } from '@/vue_present/Reuse/Teeth/TeethSelector/const'
import Tooth from '@/vue_present/Reuse/Teeth/TeethSelector/components/Tooth.vue'

const ls = new MLocalStorage('milkChecker_val_select_teeth_kind')

export default {
  name: 'TeethSelector',
  components: { Tooth, MSwitch },
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: { type: Array, default: () => [] },
  },

  data () {
    return {
      /**  @type {'adult' | 'kid'} */
      teethKind: ls.restore() || 'adult',
      teethMap,
    }
  },

  computed: {
    teethSideNumber () {
      return getTeethSideNumber(this.teethKind)
    },
  },

  watch: {
    value (to) {
      if (to.length) { return }
      this.teethMap[this.teethKind]
        .forEach((tooth) => { tooth.selected = false })
      this.$forceUpdate()
    },
  },

  created () {
    if (!this.value?.length) { return }
    this.value.forEach((num) => {
      const teethKind = num >= 55 ? 'kid' : 'adult'

      const tooth = this.teethMap[teethKind].find((tooth) => tooth.num === num)
      if (!tooth) { return }
      tooth.selected = true
    })
  },

  methods: {
    /** @param {'adult' | 'kid'} teethKind */
    onChange (teethKind) {
      this.teethKind = teethKind
      ls.save(teethKind)
    },

    /** @param {{ num: number, selected: boolean }} tooth */
    onToothClick (tooth) {
      const { num } = tooth
      this.$emit('change', this.value?.includes(num)
        ? this.value.filter((n) => n !== num)
        : [...this.value, num]
      )

      tooth.selected = !tooth.selected
      this.$forceUpdate()
    },
  },
}
</script>
