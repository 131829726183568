import Vue from 'vue/dist/vue.esm'
import ApiRole from '@/vue_components/api/role.vue'

$document.on('rez/api/roles', function () {
  if (gon.specific.moduleIsEnabled) {
    new Vue({
      el: '#api_roles_root_container',

      components: { ApiRole },

      template:
          `<api-role/>`,
    })
  }
})
