<template>
  <div class="treatment-plans-entries-transfer">
    <slot name="reference">
      <div class="treatment-plans-entries-transfer__reference">
        <m-badge
          v-if="treatmentPlansCount > 0"
          class="treatment-plans-entries-transfer__counter"
          :value="treatmentPlansCount"
          type="warning"
        />

        <m-button
          v-tooltip="tooltip"
          icon="treatmentPlan"
          @click="openModal"
        />
      </div>
    </slot>

    <m-modal
      class="treatment-plans-entries-transfer__modal"
      :visible.sync="modalVisible"
      destroy-on-close
      width="70%"
    >
      <template #header>
        <m-icon icon="treatmentPlan" />
        <span>{{ t('treatmentPlansEntriesTransfer.modalTitle') }}</span>
      </template>

      <template #footer-left>
        <m-button-submit
          :disabled="transferButtonDisabled"
          :text="t('add')"
          @click="onSubmit"
        />
      </template>

      <treatment-plans-tree
        v-if="modalVisible"
        :client-id="clientId"
        :tree="tree"
      />
    </m-modal>
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MBadge from '@/vue_present/_base/MBadge/MBadge.vue'
import { MTreatmentPlanPresenter } from '@/_api/MTreatmentPlan/MTreatmentPlanPresenter'
import TreatmentPlansTree
  from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/components/TreatmentPlansTree.vue'
import {
  TreatmentPlansLazyTreeApi,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/TreatmentPlansLazyTreeApi'

export default {
  name: 'TreatmentPlansEntriesTransfer',
  components: { MBadge, MButtonSubmit, TreatmentPlansTree, MIcon, MModal, MButton },

  mixins: [ModalMixin],

  props: {
    clientId: { type: Number, required: true },
    toothIds: { type: Array, default: undefined },
  },

  emits: [
    'onSubmitComplete',
  ],

  data () {
    return {
      treatmentPlansCount: 0,
      tree: new TreatmentPlansLazyTreeApi(this.clientId),
    }
  },

  computed: {
    transferButtonDisabled () {
      return this.tree.selectedNodesIds.length
        ? false
        : { disabled: true, tooltip: t('treatmentPlansEntriesTransfer.nothingSelected') }
    },

    tooltip () {
      return t('treatmentPlansEntriesTransfer.referenceTooltip', { treatmentPlansCount: this.treatmentPlansCount })
    },
  },

  created () {
    this.fetchTreatmentPlans()
  },

  methods: {
    async fetchTreatmentPlans () {
      this.treatmentPlansCount =
        await new MTreatmentPlanPresenter().activeTreatmentPlansCount(this.clientId)
    },

    async onSubmit () {
      await this.tree.transferTreeData({ toothIds: this.toothIds })
      this.$emit('onSubmitComplete')
    },
  },
}
</script>
