export enum referralsReportFiltersSelectors {
  clinic = '#report_clinic_id',
  period = '.period',
  entryWithOrder = '#report_entry_with_order_s2b',
  includingDiscounts = '#report_including_discounts',
  reportTypeFilter = '#report_report_type',
  groupByEntry = '#report_group_by_entry_type',
  entry = '#report_entry_type_id',
  category = '#report_category_id',
  medicalUser = '#report_medical_user_id',
  city = '#report_address_cities',
  referral = '#report_referral_id',

  referralSummaryFiltersContainer = '.referral_summary_filter'
}
