<template>
  <si-field>
    <div
      v-if="doctorAreaModuleEnabled"
      class="tbl-icon"
    >
      <a
        v-if="row.id"
        :href="$routes.entry_path(row.id)"
        target="_blank"
      >
        <span
          v-tooltip="byDmsTooltip"
          class="fad fa-fw fa-hand-holding-medical"
          :class="{ warning: byDms }"
        />
      </a>
    </div>

    <div class="number">
      <a
        v-if="row.orderId"
        :href="$routes.order_path(row.orderId)"
        target="_blank"
      >
        {{ row.orderId }}
      </a>
      <span v-else>
        -
      </span>
    </div>

    <div
      class="short_name cut"
      :data-tooltip="$filters.fullName(row.client)"
    >
      <a
        v-if="row.client && !row.client.deletedAt"
        :href="$routes.client_path(row.client.id)"
        target="_blank"
      >
        {{ $filters.fullName(row.client) }}
      </a>
      <span
        v-else
        class="deleted"
      >
        {{ $filters.fullName(row.client) }}
      </span>
    </div>

    <div class="age">
      {{ $filters.age(row.client ? row.client.birthDate : '') }}
    </div>

    <div class="emk_number">
      {{ row.client?.emkNumber || '' }}
    </div>

    <div class="date">
      {{ $filters.date(row.date) }}
    </div>

    <div class="title">
      {{ row.title }}
    </div>

    <div class="short_name cut">
      {{ $filters.doctors(row.doctors) }}
    </div>

    <div class="amount">
      {{ row.amount }}
    </div>

    <div class="sum">
      {{ $filters.price(row.sum) }}
    </div>

    <div class="sum">
      {{ $filters.price(row.finalSum) }}
    </div>

    <div class="sum">
      {{ $filters.price(row.paidSum) }}
    </div>

    <div class="sum">
      {{ $filters.price(row.discountValue) }}
      {{ DISCOUNT_TYPES[row.discountType] }}
    </div>

    <div
      v-if="workplacesEnabled"
      class="receipt-field"
    >
      <i
        v-if="row.receiptRecordsCount"
        v-tooltip="receiptRecordsCountTooltip"
        class="fad fa-receipt primary"
      />
    </div>

    <div class="document-number">
      {{ documentNumber }}
    </div>

    <div class="document-createdAt">
      {{ documentCreatedAt }}
    </div>
  </si-field>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { BY_DMS_TYPE } from '@/vue_components/reports/ProceduresReport/consts'

export default {
  name: 'ProceduresReportTableRow',
  components: { SiField },

  props: {
    doctorAreaModuleEnabled: PropsTypes.Boolean(undefined, true),
    workplacesEnabled: PropsTypes.Boolean(undefined, true),
    row: PropsTypes.Object(undefined, true),
    moneySymbol: PropsTypes.String(undefined, true),
  },

  data () {
    return {
      DISCOUNT_TYPES: { 1: '%', 2: this.moneySymbol },
    }
  },

  computed: {
    byDms () {
      return this.row.medical_policy?.policy_type === BY_DMS_TYPE
    },

    byDmsTooltip () {
      return this.byDms && t('reception.by_dms')
    },

    receiptRecordsCountTooltip () {
      if (this.row.receiptRecordsCount === 1) { return t('printed') }

      return t('printed_not_once')
    },

    documentNumber () {
      return this.row?.order?.document?.number || '-'
    },

    documentCreatedAt () {
      const date = this.row?.order?.document?.createdAt

      return date
        ? this.$filters.date(date)
        : '-'
    },
  },
}
</script>
