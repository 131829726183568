export const showOrderErrorNotification = (data) => {
  const errorText = data.error_details
    ? data.error_details
    : t(`laboratories.${data.error}`)

  Notificator.error(errorText)

  if (data.error_details) {
    console.error(data.error_details)
  }
}
