import {
  DAY_INTERVAL_TEMPLATE,
  EVEN_TEMPLATE,
  MAX_START_TIME,
  MIN_END_TIME,
  ODD_TEMPLATE,
  TIME_STEP,
  WEEK_INTERVAL_TEMPLATE,
  WORK_TIME,
} from '../../const.js'

const scheduleTemplateModalTemplateTypePropsItems = [
  { id: DAY_INTERVAL_TEMPLATE, title: window.t('daily') },
  { id: WEEK_INTERVAL_TEMPLATE, title: window.t('weekly') },
  { id: EVEN_TEMPLATE, title: window.t('even_days') },
  { id: ODD_TEMPLATE, title: window.t('odd_days') },
]

const workTimeModalTemplateTypePropsItems = [
  { id: WORK_TIME, title: window.t('never') },
  ...scheduleTemplateModalTemplateTypePropsItems,
]

const templateTypeProps = {
  items: null,
  multiSelect: false,
  idItemField: 'id',
  displayedItemField: 'title',
}

const templateDayIntervalProps = {
  items: [
    { id: 1, title: `1${window.t('n_day')}` },
    { id: 2, title: `2${window.t('n_day')}` },
    { id: 3, title: `3${window.t('n_day')}` },
    { id: 4, title: `4${window.t('n_day')}` },
    { id: 5, title: `5${window.t('n_day')}` },
    { id: 6, title: `6${window.t('n_day')}` },
  ],
  multiSelect: false,
  idItemField: 'id',
  displayedItemField: 'title',
}

const templateWeekIntervalProps = {
  items: [
    { id: 1, title: `1${window.t('n_week')}` },
    { id: 2, title: `2${window.t('n_week')}` },
    { id: 3, title: `3${window.t('n_week')}` },
    { id: 4, title: `4${window.t('n_week')}` },
    { id: 5, title: `5${window.t('n_week')}` },
    { id: 6, title: `6${window.t('n_week')}` },
  ],
  multiSelect: false,
  idItemField: 'id',
  displayedItemField: 'title',
}

const templateDaySelectProps = {
  items: [
    { id: 1, title: window.t('date.abbr_day_names')[1].toLowerCase() },
    { id: 2, title: window.t('date.abbr_day_names')[2].toLowerCase() },
    { id: 3, title: window.t('date.abbr_day_names')[3].toLowerCase() },
    { id: 4, title: window.t('date.abbr_day_names')[4].toLowerCase() },
    { id: 5, title: window.t('date.abbr_day_names')[5].toLowerCase() },
    { id: 6, title: window.t('date.abbr_day_names')[6].toLowerCase() },
    { id: 0, title: window.t('date.abbr_day_names')[0].toLowerCase() },
  ],
  multiSelect: true,
  idItemField: 'id',
  displayedItemField: 'title',
  minSelectedItems: 1,
}

const templateAvailabilityForOnlineRecordingProps = {
  items: [
    { active: 'arbitrary', title: window.t('enums.work_time.availability_for_online_recording.arbitrary') },
    { active: 'ordered', title: window.t('enums.work_time.availability_for_online_recording.ordered') },
    { active: 'not_available', title: window.t('enums.work_time.availability_for_online_recording.not_available') },
  ],
  multiSelect: false,
  idItemField: 'active',
  displayedItemField: 'title',
}

const templateActiveProps = {
  items: [
    { active: 'true', title: window.t('yes') },
    { active: 'false', title: window.t('no') },
  ],
  multiSelect: false,
  idItemField: 'active',
  displayedItemField: 'title',
}

export const multiSelectTemplateActiveProps = {
  items: [
    { active: 'true', title: window.t('renewable') },
    { active: 'false', title: window.t('not_renewable') },
  ],
  multiSelect: true,
  minSelectedItems: 1,
  idItemField: 'active',
  displayedItemField: 'title',
}

export const scheduleTemplateFormConfiguration = {
  start: MAX_START_TIME,
  end: MIN_END_TIME,
  step: TIME_STEP,
  templateTypeProps: {
    ...templateTypeProps,
    items: scheduleTemplateModalTemplateTypePropsItems,
  },
  templateDayIntervalProps,
  templateWeekIntervalProps,
  templateDaySelectProps,
  templateAvailabilityForOnlineRecordingProps,
  templateActiveProps,
}

export const workTimeFormConfiguration = {
  ...scheduleTemplateFormConfiguration,
  templateTypeProps: {
    ...templateTypeProps,
    items: workTimeModalTemplateTypePropsItems,
  },
}
