<template>
  <div class="">
    <div class="form-label">
      <label for="analysis_laboratory_title">
        {{ t('title') }}
      </label>

      <required-mark />
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationMessages.title || []"
        fixed-height
      >
        <el-input
          id="analysis_laboratory_title"
          :value="title"
          :disabled="disabled"
          type="text"
          name="analysis_laboratory[title]"
          clearable
          @input="$updateSync('title', $event)"
        />
      </validation-wrapper>
    </div>
  </div>
</template>

<script>
import RequiredMark from '@/vue_components/common/RequiredMark/RequiredMark.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'

export default {
  name: 'LaboratoryFormBase',
  components: { ValidationWrapper, RequiredMark },
  props: {
    title: {
      type: String,
      default: null,
    },

    disabled: Boolean,

    validationMessages: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
