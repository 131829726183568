import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import {
  ITaxCertificatesReportCertificate,
} from '@/vue_apps/ReportsModule/TaxSertificatesReport/interfaces/ITaxCertificatesReportResponse'
import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('taxCertificatesReport.tableHeaders')

export class TaxCertificateReportItemCells extends MListServiceItem {
  id: number

  certificateId: MTableCell

  certificateNumber: MTableCell

  correctionNumber: MTableCell

  payerFullName: MTableCell

  payerInn: MTableCell

  payerBirthDate: MTableCell

  payerDocumentKind: MTableCell

  payerSeriesNumber: MTableCell

  payerIssueDate: MTableCell

  mark: MTableCell

  clientFullName: MTableCell

  clientInn: MTableCell

  clientBirthdate: MTableCell

  clientDocumentKind: MTableCell

  clientSeriesNumber: MTableCell

  clientIssueDate: MTableCell

  sumOne: MTableCell

  sumTwo: MTableCell

  authenticatorFullName: MTableCell

  certificateDate: MTableCell

  certificateStatus: MTableCell

  constructor (props: ITaxCertificatesReportCertificate) {
    super()

    this.id = props.id

    this.certificateId = new MTableCell(props.id)

    this.certificateNumber = new MTableCell(props.certificateNumber)
      .addTooltip(true)

    this.correctionNumber = new MTableCell(props.correctionNumber)

    this.payerFullName = new MTableCell(vueFilters.fullName(props.payer))
      .addTooltip(true)
      .addCut()

    this.payerInn = new MTableCell(props.payer.inn)

    this.payerBirthDate = new MTableCell(vueFilters.date(props.payer.birthDate))

    this.payerDocumentKind = new MTableCell(props.payer.documentKind)

    this.payerSeriesNumber = new MTableCell(props.payer.seriesAndNumber)

    this.payerIssueDate = new MTableCell(vueFilters.date(props.payer.issueDate))

    this.mark = new MTableCell()
      .addTypeIcon(props.mark
        ? 'ready'
        : 'canceled')
      .addClasses(props.mark
        ? 'success'
        : 'danger')
      .addTooltip(props.mark
        ? ts('ready')
        : ts('notReady')
      )

    this.clientFullName = new MTableCell(vueFilters.fullName(props.client))
      .addTooltip(true)
      .addCut()

    this.clientInn = new MTableCell(props.client?.inn)

    this.clientBirthdate = new MTableCell(vueFilters.date(props.client?.birthDate))

    this.clientDocumentKind = new MTableCell(props.client?.documentKind)

    this.clientSeriesNumber = new MTableCell(props.client?.seriesAndNumber)

    this.clientIssueDate = new MTableCell(vueFilters.date(props.client?.issueDate))

    this.sumOne = new MTableCell(vueFilters.price(props.paymentSum.sumOne))

    this.sumTwo = new MTableCell(vueFilters.price(props.paymentSum.sumTwo))

    this.authenticatorFullName = new MTableCell(vueFilters.fullName(props.authenticator))
      .addTooltip(true)
      .addCut()

    this.certificateDate = new MTableCell(vueFilters.date(props.date))

    this.certificateStatus = new MTableCell(props.status)
  }
}
