import { ReportParamFactory } from '@/specific/reports/shared'

export default function (source) {
  return {
    clinicIds: ReportParamFactory.buildArrayParam(source.clinic),
    paymentType: source.paymentType.id,
    paymentKinds: ReportParamFactory.buildArrayParam(source.paymentKinds),
    payerType: source.payerType.id,
    doctorId: source.doctorId.id,
    chequePrinted: source.chequePrinted?.id,
  }
}
