import { Protocol } from '@/vue_apps/Protocols/entities/Protocol'
import { MProtocolPresenter } from '@/vue_apps/Protocols/api/MProtocolPresenter'
import { pick } from 'lodash'
import { Api } from '@/_api/decorators/api/Api'
import { ISemdSource } from '@/vue_apps/Semds/interfaces/ISemdSource'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { SEMDS_OWNER_TYPES } from '@/vue_apps/Semds/SemdModal/const/const'
import { patchSemdsDocumentDetailsAttributes } from '@/vue_apps/Protocols/entities/patchSemdsDocumentDetailsAttributes'
import { Callback } from '@/_api/decorators/callback'
import { ISemdsDocumentDetailsAttributes } from '@/vue_apps/Semds/interfaces/ISemdsDocumentDetailsAttributes'

export class ProtocolAPI<DocumentDetails>
  extends Protocol<DocumentDetails>
  implements ISemdSource {
  _presenter = new MProtocolPresenter()

  semdTypeChangingProhibited = false

  sourceType: string = SEMDS_OWNER_TYPES.PROTOCOL

  constructor (props = {}) {
    super(props)
  }

  async load () {
    const response = await this._presenter.fetch(this.id)
    if (response.errors) { return }

    this.fillProps(response)

    if (response.semdType) {
      this.prohibitChangeSemdType()
    }

    this.buildStoreState()

    return this
  }

  async save () {
    const data = {
      ...pick(this, this._permit),
      storeState: this.storeState,
    }

    return this._presenter.submit(data)
      .then((data) => {
        if (data?.id) {
          this.setValue('id', data.id)
          this.setValue('title', data.title)
          this.setValue('client', data.client)
          this.setValue('templateHtml', data.templateHtml)

          if (data.semdsDocumentDetailsAttributes?.id) {
            this.setValue('semdsDocumentDetailsAttributes.id', data.semdsDocumentDetailsAttributes.id)
          }
        }

        this.setValue('newSemdApproved', false)
        this.setValue('newSignatureApproved', false)

        return data
      })
  }

  async delete () {
    return this._presenter.destroy(this.id)
  }

  async generatePdf (entryId) {
    return this._presenter.generatePdf(entryId, this.id)
      .catch(Utils.reportError('ProtocolPresenter::generatePdf', 'Не удалось запустить создание PDF'))
  }

  prohibitChangeSemdType () {
    this.semdTypeChangingProhibited = true
  }

  @Callback('fillSemdDocumentDetailAttributes')
  @Api.notify('updateMessage', 'semdsDetails', { success: false })
  @Api.patch(window.Routes.api_internal_semds_document_detail_path)
  updateDetails (): Promise<void | { errors: Record<string, string[]> }> {
    return {
      // @ts-ignore
      routeArgs: [this.semdsDocumentDetailsAttributes.id],
      payload: {
        data: patchSemdsDocumentDetailsAttributes(this.semdsDocumentDetailsAttributes.data, this.semdType),
      },
    }
  }

  // @ts-ignore
  refresh (): Promise<ISemdSource | IApiErrors> {}

  protected fillSemdDocumentDetailAttributes (semdsDocumentDetailsAttributes: ISemdsDocumentDetailsAttributes<DocumentDetails> | IApiErrors) {
    if ('errors' in semdsDocumentDetailsAttributes) { return semdsDocumentDetailsAttributes }

    this.fillSemdData(
      (semdsDocumentDetailsAttributes as ISemdsDocumentDetailsAttributes<DocumentDetails>).data
    )
  }
}
