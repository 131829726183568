const CardWithChart = {
  data () {
    return {
      chartData: null,
    }
  },
  watch: {
    chartData () {
      this.renderChart()
    },
  },
  methods: {
    /**
     * Implement this to render a chart. No parameters are passed here,
     * use `chardData` as a source for your chart.
     */
    renderChart () {},
  },
}
export default CardWithChart
