export const taxCertificate2024MassDestroyAdapter = {
  toServer (ids) {
    const formData = new FormData()

    ids.forEach((id) => {
      formData.append('ids[]', id)
    })

    return formData
  },
}
