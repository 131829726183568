import { HAS_ERROR_CLASS, renderLegacyValidationWrapper, UNDER_FIELD_CLASS } from '@/helpers/legacy_validation_wrapper'
import { IApiError } from 'src/_declarations/IApiErrors'

const REPORTS_FILTER_WRAPPER_SELECTOR = '.form-group'

// Скорее всего придется перейти на removeLegacyValidationWrapper из legacy_validation_wrapper.js,
// потому что в маркетинге это работать не будет, но пока так, так проще
export const removeFilterValidationWrapper = (e: Event): void => {
  const formGroupElem = $(e.target).closest(REPORTS_FILTER_WRAPPER_SELECTOR)
  formGroupElem.removeClass(HAS_ERROR_CLASS)
  formGroupElem.find(`.${UNDER_FIELD_CLASS}`).remove()
}

export const showFiltersErrors = (
  filtersErrors: IApiError,
  filtersSelectors: Record<string, string>
): void => {
  Object.entries(filtersErrors).forEach(([filterName, errors]) => {
    if (!errors.length) { return }

    renderLegacyValidationWrapper(filtersSelectors[filterName], errors)
  })
}
