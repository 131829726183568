<template>
  <simple-select
    v-model="selectedClinicId"
    :items="clinics"
    :fixed-height="false"
    class="component clinic_handler"
    filterable
    clearable
  />
</template>

<script>
import { doctorPresenter } from '@/api_entities/doctors/doctorPresenter'
import { MAX_CLINICS_FOR_BUTTONS_VIEW } from '@/vue_components/users/SelectClinic/const'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import { mapActions, mapGetters } from 'vuex'

const getSelectedClinic = () => {
  let selectedId = gon.specific.clinic_id
  if (selectedId) {
    [...$('.show_user')].forEach((item) => { item.dataset.href += `?clinic_id=${selectedId}` })
    $('.panel_close_button').attr('href', `/users?clinic_id=${selectedId}`)
  } else {
    selectedId = ''
  }

  return selectedId
}

export default {
  name: 'CatalogUsersClinicFilter',
  components: {
    SimpleSelect,
  },

  data () {
    return {
      clinicId: getSelectedClinic(),
      currentUserId: gon.application.current_user.id,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      clinics: 'vxGetDoctorClinics',
    }),

    isButtonsView () {
      return this.clinics.length <= MAX_CLINICS_FOR_BUTTONS_VIEW
    },

    selectedClinicId: {
      /**
       * @param {number} clinicId
       */
      set (clinicId) {
        this.clinicId = clinicId
      },

      /**
       * @return {number}
       */
      get () {
        const clinic = this.clinics
          .find((clinic) => clinic.id === this.clinicId)

        return clinic
          ? clinic.id
          : this.defaultSelectorId(this.clinics)
      },
    },
  },

  watch: {
    /**
     * @param {number} newClinicId
     */
    async clinicId (newClinicId) {
      await doctorPresenter.filterByClinicId(newClinicId)
    },
  },

  created () {
    this.fetchClinics()
  },

  methods: {
    ...mapActions('globalCatalogs/doctorStore', {
      vxLoadClinics: 'vxLoadClinics',
    }),

    defaultSelectorId (clinics) {
      if (clinics.length === 1) return clinics[0].id
      if (clinics.length > 1) return null
    },

    async fetchClinics () {
      await this.vxLoadClinics(this.currentUserId)
    },
  },
}
</script>
