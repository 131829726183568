<template>
  <div
    :id="id"
    class="EntryTypesBreadcrumb"
  >
    <div
      v-skeleton="loading"
      class="breadcrumbs-body"
    >
      <span>{{ formattedBreadcrumbs }}</span>
    </div>
  </div>
</template>

<script>

import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { entryTypesPresenter } from '@/api_entities/entry_types/entryTypesPresenter'

export default {
  name: 'EntryTypesBreadcrumbs',

  mixins: [SpinnerHolder],

  props: {
    id: { type: String, required: true },
    entryTypeId: { type: Number, required: true },
  },

  data () {
    return {
      breadcrumbsArray: [],
    }
  },

  computed: {
    formattedBreadcrumbs () {
      return this.breadcrumbsArray.join(' / ')
    },
  },

  created () {
    this.fetchEntryTypesBreadcrumbs(this.entryTypeId)
  },

  methods: {
    async fetchEntryTypesBreadcrumbs (entryTypeId) {
      this.breadcrumbsArray = await this.withSpinner(entryTypesPresenter.fetchEntryTypesBreadcrumbs(entryTypeId))
    },
  },
}
</script>
