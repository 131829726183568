const createDefaultDiscountReason = () => ({
  id: '',
  title: '',
  active: true,
})

const getValidationMessages = () => ({
  title: [],
})

export const creators = Object.freeze({
  formDataCreator: createDefaultDiscountReason,
  validationMessagesCreator: getValidationMessages,
})

export const PAGINATION_LIMIT = 20

export const STATUSES = [
  Object.freeze({
    id: true,
    title: t('exports_page.clinic.statusActive'),
  }),
  Object.freeze({
    id: false,
    title: t('exports_page.clinic.statusInactive'),
  }),
]
