import { createVueApp } from '@/vue_components/create_vue_app'
import { createStore } from '@/vue_components/store/store'
import { mapGetters } from 'vuex'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'

let vueApp = null
export const createTreatmentPlanClinicsApp = (el = '#treatment_plan_clinic_id') => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'VueTreatmentPlanClinicsApp',
    store: createStore(),
    components: {
      MSelect,
    },

    data () {
      return {
        elementId: el.slice(1),
        clinicId: gon.specific.treatment_plan.clinic_id,
      }
    },

    computed: {
      ...mapGetters('globalCatalogs/doctorStore', {
        vxGetDoctorClinics: 'vxGetDoctorClinics',
      }),
    },

    template: `
      <div class="treatment-plan-clinics-app" :id="elementId" style="width: 200px">
        <input type="hidden" name="treatment_plan[clinic_id]" :value="clinicId">
        <m-select 
          class="treatment-plan__clinic"
          v-model="clinicId" 
          :items="vxGetDoctorClinics"
          :m-fixed-height="false"
          :placeholder="t('enums.unspecified.it')"
          label=""
          use-custom-result="simple"
        />
      </div>
    `,
  })
}
