<template>
  <m-si-generator
    class="h-100"
    :items="items"
    :si-generator-schema="schema"
    :current-page="currentPage"
    :page-count="totalPages"
    nfr-small
    use-context-menu
    @onItemClick="$emit('onItemClick', $event)"
    @onResetClick="$emit('onResetClick')"
    @onRefreshClick="$emit('onRefreshClick')"
    @update:currentPage="$updateSync('currentPage', $event)"
    @onItemContextMenuClick="lastContextClickedItem = $event"
  >
    <template #filters>
      <slot name="filters" />
    </template>

    <template #contextMenu>
      <m-context-menu-new-tab
        v-if="lastContextClickedItem"
        :url="$routes.treatment_plan_path(lastContextClickedItem.id)"
      />
    </template>
  </m-si-generator>
</template>

<script>
import { defineComponent } from 'vue'
import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MContextMenuNewTab from '@/vue_present/_base/Tables/MContextMenuNewTab.vue'

export default defineComponent({
  name: 'TreatmentPlansListView',
  components: { MContextMenuNewTab, MSiGenerator },
  props: {
    schema: MSiSchema,
    items: PropsTypes.Array([]),
    currentPage: PropsTypes.Number(),
    totalPages: PropsTypes.Number(),
  },

  emits: [
    'onItemClick',
    'onResetClick',
    'onRefreshClick',
    'update:currentPage',
  ],

  data () {
    return {
      lastContextClickedItem: null,
    }
  },
})
</script>
