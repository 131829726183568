<template>
  <div class="flex-form">
    <div class="form-label">
      <label for="company_type_title">
        {{ t('title') }}
      </label>

      <required-mark />
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationErrors.title"
        fixed-height
      >
        <el-input
          id="company_type_title"
          name="company_type[title]"
          :value="title"
          :disabled="isSystemName || !$security.canManageCompanyType"
          type="text"
          clearable
          @input="$updateSync('title', $event)"
        />
      </validation-wrapper>
    </div>
  </div>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import RequiredMark from '@/vue_components/common/required_mark'

export default {
  name: 'CompanyTypesForm',
  components: {
    ValidationWrapper,
    RequiredMark,
  },

  props: {
    isSystemName: Boolean,

    title: {
      type: String,
      default: '',
    },

    validationErrors: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
