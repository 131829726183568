import { reportSuccessText } from '@/_api/_requests/helpers'

export const subscribeToDragAndDrop = (clientId) => {
  $('body').attachmentUploader('destroy')

  const $element = $('.medical_records_page')
  $element.attachmentUploader('destroy')
  $element.attachmentUploader({
    button: $('.new_attachment'),
    done () {
      Services.pubSub.emit('updateFilesList')
      Utils.reportSuccess(reportSuccessText('uploadMessage', 'attachment'))()
    },
    formData: {
      client_id: clientId,
    },
  })
}
