import Vue from 'vue/dist/vue.esm'
import AppointmentDurationsCatalog from './appointment_durations'

export const VueAppointmentDurationEntryPoint = () => {
  const el = '#vue_appointment_durations_catalog'

  if (!document.querySelector(el)) { return }
  // eslint-disable-next-line no-new
  new Vue({
    el,
    render: (h) => h(AppointmentDurationsCatalog),
  })
}
