import { omit } from 'lodash'
import { FILTERS_PROFILES_TYPES, FILTERS_PROFILES_TYPES_WHEN_FETCH } from '../const/consts'
import { IFiltersProfilesModel } from '../interfaces/IFiltersProfilesModel'
import { administratorsProductivityReportFiltersProfileAdapter } from './specificAdapters/administratorsProductivityReportAdapter'
import { doctorAppointmentsReportFiltersProfileAdapter } from './specificAdapters/doctorAppointmentsReportAdapters'
import { doctorsProductivityReportFiltersProfileAdapter } from './specificAdapters/doctorsProductivityReportAdapter'
import { insuranceCompaniesReportFiltersProfileAdapter } from './specificAdapters/insuranceCompaniesReportAdapter'
import { proceduresReportFiltersProfileAdapter } from './specificAdapters/proceduresReportAdapter'
import { referralsReportFiltersProfileAdapter } from './specificAdapters/referralsReportAdapters'
import { salaryReportFiltersProfileAdapter } from './specificAdapters/salaryReportAdapter'
import { treatmentPlansReportFiltersProfileAdapter } from './specificAdapters/treatmentPlansReportAdapter'

const toServerAdaptersByType: Record<number, Function> = {
  [FILTERS_PROFILES_TYPES.ADMINISTRATOR_PRODUCTIVITY]: administratorsProductivityReportFiltersProfileAdapter.toServer,
  [FILTERS_PROFILES_TYPES.DOCTOR_PRODUCTIVITY]: doctorsProductivityReportFiltersProfileAdapter.toServer,
  [FILTERS_PROFILES_TYPES.INSURANCE_COMPANIES]: insuranceCompaniesReportFiltersProfileAdapter.toServer,
  [FILTERS_PROFILES_TYPES.DOCTOR_APPOINTMENTS_REPORT]: doctorAppointmentsReportFiltersProfileAdapter.toServer,
  [FILTERS_PROFILES_TYPES.TREATMENT_PLANS_REPORT]: treatmentPlansReportFiltersProfileAdapter.toServer,
  [FILTERS_PROFILES_TYPES.PROCEDURES]: proceduresReportFiltersProfileAdapter.toServer,
  [FILTERS_PROFILES_TYPES.REFERRALS]: referralsReportFiltersProfileAdapter.toServer,
  [FILTERS_PROFILES_TYPES.SALARY]: salaryReportFiltersProfileAdapter.toServer,
}

export const filtersProfilesGeneralSubmitAdapter = {
  toServer (data: IFiltersProfilesModel) {
    const profileType = typeof data.profileType === 'number'
      ? data.profileType
      : FILTERS_PROFILES_TYPES_WHEN_FETCH[data.profileType]

    const toServerFetchAdapter = toServerAdaptersByType[profileType]

    return { reportProfileParams: omit(toServerFetchAdapter(data), ['dataErrors']) }
  },
}
