<template>
  <modal
    :modal-visibility="visible"
    modal-size="md"
    @close="close"
  >
    <template #header>
      {{ t('reception.delete_appointment_type', [appointmentType.title]) }}
    </template>
    <template #body>
      <label>
        {{ t('reception.choose_replacement_appointment_type') }}
      </label>
      <el-select
        id="replacement-type-id"
        :value="replacementTypeId"
        class="full-width"
        @change="setReplacementType"
      >
        <el-option
          v-for="type in appointmentTypesWithoutCurrent"
          :key="type.id"
          :value="type.id"
          :label="type.title"
        />
      </el-select>
    </template>
    <template #footer-right>
      <button
        class="btn btn-danger btn-with-icon"
        @click="destroy"
      >
        <span class="btn-with-icon_icon fad fa-exclamation-circle" />
        <span class="btn-with-icon_text">{{ t('delete') }}</span>
      </button>
      
      <button
        class="btn btn-primary btn-with-icon"
        @click="close"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">{{ t('close') }}</span>
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal'

export default {
  name: 'AppointmentTypeDeleteModal',

  components: { Modal },

  props: {
    visible: Boolean,
    appointmentType: {
      type: Object,
      required: true,
    },
    appointmentTypes: {
      type: Array,
      required: true,
    },
  },

  data () {
    return {
      replacementTypeId: null,
    }
  },

  computed: {
    appointmentTypesWithoutCurrent () {
      return this.appointmentTypes.filter((type) => type.id !== this.appointmentType.id)
    },
  },

  watch: {
    appointmentTypesWithoutCurrent: {
      immediate: true,
      handler (newTypes) {
        const defaultType = newTypes.find((type) => type.default)
        this.replacementTypeId = defaultType ? defaultType.id : null
      },
    },
  },

  methods: {
    destroy () {
      this.$emit('delete', {
        appointmentTypeId: this.appointmentType.id,
        replacementTypeId: this.replacementTypeId,
      })
      this.close()
    },

    setReplacementType (appointmentTypeId) {
      this.replacementTypeId = appointmentTypeId
    },

    close () {
      this.$updateSync('visible', false)
    },
  },
}
</script>
