<template>
  <div
    class="nps-dynamics"
    style="position: relative"
  >
    <epic-spinner
      :visibility="showLoadingSpinner"
    />
    <div
      class="el-card__body__content"
      style="height: 290px"
    >
      <div id="chart" />
      <introduction-button
        v-if="!moduleIsEnabled"
        :icon="'fa-comment-smile'"
        :inner-text="t('introduction.client_widget')"
      />
    </div>
    <div class="el-card__body__footer">
      <div class="el-card__body__footer__filters">
        <clinic-selector :card-name="cardName" />
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import EpicSpinner from '../../../epic_spinner/epic_spinner.vue'
import ClinicSelector from '../selectors/clinic_selector.vue'
import IntroductionButton from '../../../modal_windows/introduction/introduction_button'
import { getFakeNPSData } from './fakeDataProvider'
import Card from './card.js'
import CardWithChart from './cardWithChart.js'

export default {
  name: 'NpsDynamics',
  components: {
    EpicSpinner,
    ClinicSelector,
    IntroductionButton,
  },
  mixins: [Card, CardWithChart],
  data () {
    return {
      route: this.$routes.reports_create_head_desktop_nps_dynamics_path(),
      moduleIsEnabled: gon.application.client_widget_enabled,
      serializableFields: ['clinic_id'],
    }
  },
  computed: {
    requestParams () {
      return {
        clinic_id: this.cardParams.clinic_id,
        date_start: this.cardParams.date_range[0],
        date_end: this.cardParams.date_range[1],
      }
    },
  },
  methods: {
    // 7jt1ev (10)
    showFakeData () {
      this.cardAdapter(getFakeNPSData())
    },
    cardAdapter (result) {
      this.chartData = result.nps_dynamics
    },
    renderChart () {
      Highcharts.chart('chart', {
        chart: {
          type: 'area',
          height: '290px',
        },
        series: [{
          name: t('head_desktop.average_check'),
          color: '#3faeca',
          data: this.chartData,
        }],
        title: false,
        legend: {
          enabled: false,
        },
        xAxis: {
          visible: false,
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        tooltip: {
          pointFormat: t('client_feedbacks.nps') + ': <b>{point.y:.0f}</b>',
        },
      })
    },
  },
}
</script>
