export default {
  watch: {
    isLoading (loading) {
      if (!loading) {
        if (this.$refs.table) {
          this.$refs.table.scrollTop()
        }
      }
    },
  },
}
