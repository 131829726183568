import { SPECIAL_CLINICS } from '@/vue_apps/catalogs_root/analysis_laboratories/const/create_laboratories'

const BARCODE_SCOPE = 'laboratories.form_fields.barcode_editor_consts'

export const barcodeVariablesHelix = [
  {
    label: t('label', { scope: BARCODE_SCOPE }),
    value: 'label',
  },
  {
    label: t('id', { scope: BARCODE_SCOPE }),
    value: 'id',
  },
  {
    label: t('code', { scope: BARCODE_SCOPE }),
    value: 'code',
  },
]

export const barcodeVariablesKdl = [
  {
    label: t('label', { scope: BARCODE_SCOPE }),
    value: 'label',
  },
  {
    label: t('code', { scope: BARCODE_SCOPE }),
    value: 'code',
  },
  {
    label: t('clinicTitle', { scope: BARCODE_SCOPE }),
    value: 'clinicTitle',
  },
  {
    label: t('date', { scope: BARCODE_SCOPE }),
    value: 'date',
  },
  {
    label: t('patientName', { scope: BARCODE_SCOPE }),
    value: 'patientName',
  },
]

/**
 * @param {String} str
 * @return {String[]}
 */
export const variablesParser = (str = '') => {
  return (str.match(/{{(.+?)}}/g) || [])
    .map((item) => item
      .replaceAll('{', '')
      .replaceAll('}', '')
      .trim()
    )
}

const getMappedValues = (variables) => {
  return variables.reduce((acc, item) => {
    acc[item.label] = item.value

    return acc
  }, {})
}

const getMappedLabels = (variables) => {
  return variables.reduce((acc, item) => {
    acc[item.value] = item.label

    return acc
  }, {})
}

export const helixMappedVariablesValues = getMappedValues(barcodeVariablesHelix)
export const helixMappedVariablesLabels = getMappedLabels(barcodeVariablesHelix)

export const kdlMappedVariablesValues = getMappedValues(barcodeVariablesKdl)
export const kdlMappedVariablesLabels = getMappedLabels(barcodeVariablesKdl)

export const variables = (laboratorySystemName = null) => {
  if (!laboratorySystemName) { return [] }

  return laboratorySystemName === SPECIAL_CLINICS.HELIX
    ? barcodeVariablesHelix
    : barcodeVariablesKdl
}

export const mappedVariablesValues = (laboratorySystemName = null) => {
  if (!laboratorySystemName) { return false }

  return laboratorySystemName === SPECIAL_CLINICS.HELIX
    ? helixMappedVariablesValues
    : kdlMappedVariablesValues
}

export const mappedVariablesLabels = (laboratorySystemName = null) => {
  if (!laboratorySystemName) { return false }

  return laboratorySystemName === SPECIAL_CLINICS.HELIX
    ? helixMappedVariablesLabels
    : kdlMappedVariablesLabels
}

/**
 * Вход: 012{{ Этикетка }}345{{}}{{ Код }}6789
 * Выход: 012{{ label }}345{{}}{{ code }}6789
 *
 * @param {String} str
 * @param {String[]} replaceableValues
 * @param {Record<String, String>} mappedVariables
 */
export const replaceVariables = (str, replaceableValues, mappedVariables) => {
  let newStr = str
  replaceableValues.forEach((value) => {
    const replacement = mappedVariables[value] !== undefined
      ? mappedVariables[value]
      : value

    newStr = newStr.replaceAll(value, replacement)
  })

  return newStr
}
