<template>
  <modal
    v-if="showUpdateScheduleTemplateModal"
    :modal-class="'update-schedule-template-modal'"
    :modal-visibility="showUpdateScheduleTemplateModal"
    @close="noEvent"
  >
    <span slot="header">{{ T.modify_interval_recurrence_rules }}</span>
    <template slot="body">
      <span>{{ T.changed_the_rules_for_repeating_intervals_for_employees }}</span>
      <div class="user-names-container">
        <div
          v-for="userName in usersWithChanges"
          class="user-name"
        >
          {{ userName }}
        </div>
      </div>
    </template>
    <div slot="footer-right">
      <button
        class="btn btn-sm btn-success btn-with-icon modal-save"
        @click="yesEvent"
      >
        <span class="btn-with-icon_icon fad fa-check" />
        <span class="btn-with-icon_text">{{ T.apply }}</span>
      </button>
      <button
        class="btn btn-sm btn-primary btn-with-icon modal-close"
        @click="noEvent"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">{{ T.close }}</span>
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../modal.vue'

export default {
  name: 'UpdateScheduleTemplateModal',
  components: {
    Modal,
  },
  props: {
    showUpdateScheduleTemplateModal: Boolean,
  },
  data: () => ({
    usersWithChanges: null,
    yesEvent: null,
    noEvent: null,
  }),
  methods: {
    loadData (data) {
      if (data.show) {
        this.usersWithChanges = data.usersWithChanges
        this.yesEvent = data.yesEvent
        this.noEvent = data.noEvent
      }
      this.$emit('selfVisibility', data.show)
    },
  },
}
</script>
