
export const getPDFStatus = (isGenerated) => {
  return {
    color: isGenerated
      ? 'success'
      : 'gray',
    tooltip: isGenerated
      ? 'PDF создан'
      : 'PDF не создан',
  }
}
