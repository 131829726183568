import { MTaxCertificate2024Presenter } from '@/_api/MTaxCertificate2024/MTaxCertificate2024Presenter'
import {
  tax2024ListAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/tax2024ListAdapter'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import {
  taxCertificate2024MassDestroyAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/taxCertificate2024MassDestroyAdapter'

export class DocumentTabTax2024Presenter extends MTaxCertificate2024Presenter {
  list (listData, config = {}) {
    return super.list(listData, {
      toClientAdapter: tax2024ListAdapter.toClient,
      toServerAdapter: tax2024ListAdapter.toServer,
      toJson: true,
      ...config,
    })
  }

  destroy (id, options = {}) {
    return super.destroy(id, {
      dataType: 'text',
      ...options,
    })
  }

  massDestroy (ids, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'destroyMessage',
      'documents'
    )

    return super.delete({
      url: Routes.destroy_list_api_internal_documents_certificate_payments_path(),
      data: taxCertificate2024MassDestroyAdapter.toServer(ids),
      dataType: 'text',
      contentType: false,
      processData: false,
      ...config,
    }).promise
      .then(MRequestSuccess.withNotify(() => true, notification))
      .catch(MRequestError.withDefault(false, notification))
  }
}
