import SmsEditor from '@/modules/variables_editor.js'
import { APPOINTMENT_NOTIFY } from '@/modules/sms/editor_presets.js'
import { smsCount } from '@/modules/sms/utils.js'

export default function appointmentNotify () {
  if (!gon.application.sms_module_enabled) return

  const $form = $('#sms_configuration_form')
  const $submit = $form.find('#sms_settings_submit').eq(0)
  const $notifyEditor = $('.notify_template_editor_container')
  const $notifyCb = $('#sms_configuration_notify')
  const $notifyFields = $('.notify_field')
  const $notifyApproximate = $('.notify_approximate_value')
  const $symbolCount = $form.find('.symbol_count')

  const notifyEditor = new SmsEditor({
    container: $notifyEditor,
    onPreviewChanged (text) {
      const count = smsCount(text)
      $notifyApproximate.text(count)
      $symbolCount.text(text.length)
    },
    validationCallback (value) {
      $submit.prop('disabled', !value)
    },
    ...APPOINTMENT_NOTIFY,
  })

  notifyEditor.visualize()

  $form.remote_form({
    model: 'sms_configuration',
    beforeSend (e) {
      notifyEditor.originalize()
    },
    onSuccess (res) {
      notifyEditor.visualize()
      Notificator.info(I18n.t('sms.configuration_updated'))
      $submit.loadSpin('stop')
    },
    onError (err) {
      Notificator.error(T.check_correctness_filing_form)
      console.log(err)
      notifyEditor.visualize()
      $submit.loadSpin('stop')
    },
  })

  const toggleNotifyFields = () => {
    $notifyFields.toggleClass('hidden', !$notifyCb.prop('checked'))
  }

  toggleNotifyFields()
  $notifyCb.on('change', toggleNotifyFields)
  $submit.on('click', (e) => {
    if (!notifyEditor.validate()) e.preventDefault()
  })
}
