import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { getPDFStatus } from '@/vue_apps/Protocols/const/getPDFStatus'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class ProtocolsListItem extends MListServiceItem {
  /**
   * @param {IProtocolListItem} data
   */
  constructor (data) {
    super()

    this.id = data.id

    const info = [
      data.title,
      data.doctor?.shortName,
    ].filter(Boolean).join(', ')

    this.title = new MTableCell(info)
      .addTooltip(true)

    this.date = new MTableCell(vueFilters.date(data.date))

    this.info = info

    this.edit = new MTableCell()
      .addTypeIcon('edit')
      .addTooltip(t('edit'))
      .addClasses('warning')

    this.setPdfGenerated(data.pdfGenerated)
  }

  setPdfGenerated (pdfGenerated) {
    const pdfStatus = getPDFStatus(pdfGenerated)
    this.pdfGenerated = new MTableCell()
      .addTypeIcon('filePdf')
      .addClasses(pdfStatus.color)
      .addTooltip(pdfStatus.tooltip)

    this.icon = {
      icon: 'filePdf',
      color: pdfStatus.color,
      tooltip: pdfStatus.tooltip,
    }
  }
}
