<template>
  <m-select
    :items="clinics"
    v-bind="$attrs"
    :label="label"
    :placeholder="placeholder"
    v-on="$listeners"
  />
</template>

<script>

import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ClinicFilter',
  components: { MSelect },
  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      clinics: 'vxGetDoctorClinics',
    }),

    label () {
      return this.$attrs.multiply
        ? t('clinics')
        : t('clinic')
    },

    placeholder () {
      return this.$attrs.placeholder || t('all_clinics')
    },
  },

  created () {
    this.fetchClinics(gon.application.current_user.id)
  },

  methods: {
    ...mapActions('globalCatalogs/doctorStore', {
      fetchClinics: 'vxLoadClinics',
    }),
  },
}
</script>
