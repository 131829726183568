import Vue from 'vue/dist/vue.esm'
import DiscountReasonsCatalog from './discount_reasons'

export const DiscountReasonsEntryPoint = () => {
  const el = '#vue_discount_reasons'

  if (!document.querySelector(el)) return
  // eslint-disable-next-line no-new
  new Vue({
    el,
    render: (h) => h(DiscountReasonsCatalog),
  })
}
