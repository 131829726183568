export const urlValidator = (value: string) => {
  const errors = Utils.getValidationErrors(value)

  if (errors.length) { return errors }

  if (!Utils.isValidUrl(value)) {
    return [t('errors.urlValidator')]
  }

  return []
}
