import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const emkTreatmentPlansListSchema = new MSiSchema()
  .addPagination()
  .addTableSchema(
    new MTableSchema({
      headers: {
        tableType: Utils.branch.isCustom && new MTableHeader().addFixedWidth('30px'),
        tableStatus: new MTableHeader().addFixedWidth('30px'),
        tableDate: new MTableHeader().addFixedWidth('100px'),
        tableTitle: new MTableHeader(),
        tablePerformer: gon.application.show_performer_in_treatment_plans && new MTableHeader(),
      },
    })
  )

export const clientTreatmentPlansListSchema = new MSiSchema()
  .addPagination()
  .addFilterButtons()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        tableType: Utils.branch.isCustom && new MTableHeader().addFixedWidth('30px'),
        tableStatus: new MTableHeader().addFixedWidth('30px'),
        tableDate: new MTableHeader(t('common.date')).addFixedWidth('100px'),
        tableTitle: new MTableHeader(t('title')),
        tablePerformer: gon.application.show_performer_in_treatment_plans && new MTableHeader(t('performer')),
        tableFinalSum: new MTableHeader(t('sum')).addFixedWidth('120px'),
      })
  )
