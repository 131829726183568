import iconv from 'iconv-lite'

export function fetchExportFile (data, route, fileName) {
  fetch(route, {
    method: 'POST',
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    },
    credentials: 'same-origin',
    body: JSON.stringify(data),
  })
    .then(handleErrors)
    .then((result) => {
      download(result, 'text/csv', fileName)
    })
    .catch((error) => {
      console.log(error)
      Services
        .ui
        .notificator()
        .error(T.abstract_error_message, T.error, 0)
    })
}

export function defineDateFormat () {
  if (gon.localization.date_format.charAt(0) === 'd') {
    return '02.01.2006'
  }

  return '01.02.2006'
}

function download (body, mimeType, fileName) {
  const extension = mimeType.split('/')[1]
  let blob

  if (gon.application.export_encoding === 'win1251') {
    const byteArrayWin1251 = iconv.encode(body, 'win1251')
    blob = new Blob([byteArrayWin1251], { type: `${mimeType};charset=windows-1251` })
  } else {
    blob = new Blob([body], { type: `${mimeType};charset=utf-8` })
  }

  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = `${fileName} ${T.export.toLocaleLowerCase()}.${extension}`
  link.click()
  link.remove()
}

function handleErrors (response) {
  if (!response.ok) throw Error(response.status)

  return response.text()
}
