export default {
  companyTypes: {
    type: Array,
    default: () => [],
  },

  title: {
    type: String,
    default: '',
  },

  companyTypeIds: {
    type: Array,
    default: () => [],
  },

  typeOfCompany: {
    type: String,
    default: '',
  },

  individualTaxNumber: {
    type: String,
    default: '',
  },

  kpp: {
    type: String,
    default: '',
  },

  ogrn: {
    type: String,
    default: '',
  },

  okved: {
    type: String,
    default: '',
  },

  registrationDate: {
    type: String,
    default: '',
  },

  note: {
    type: String,
    default: '',
  },

  index: {
    type: String,
    default: '',
  },

  country: {
    type: String,
    default: '',
  },

  region: {
    type: String,
    default: '',
  },

  nsiRussianSubjectId: { type: Number, default: null },

  area: {
    type: String,
    default: '',
  },

  city: {
    type: String,
    default: '',
  },

  street: {
    type: String,
    default: '',
  },

  house: {
    type: String,
    default: '',
  },

  flat: {
    type: String,
    default: '',
  },

  headSurname: {
    type: String,
    default: '',
  },

  headName: {
    type: String,
    default: '',
  },

  headSecondName: {
    type: String,
    default: '',
  },

  headPosition: {
    type: String,
    default: '',
  },

  headOperatesBy: {
    type: String,
    default: '',
  },

  validationErrors: {
    type: Object,
    default: () => ({}),
  },

  searchQuery: {
    type: String,
    default: '',
  },
}
