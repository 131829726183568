<template>
  <si-field>
    <si-column
      :name="header.number.sortable"
      :active="sortableColumn"
      class="tbl-icon"
      @sort-change="onSortChange"
    >
      {{ header.number.text }}
    </si-column>

    <si-column
      :name="header.payer.sortable"
      :active="sortableColumn"
      class="short_name cut"
      @sort-change="onSortChange"
    >
      {{ header.payer.text }}
    </si-column>

    <si-column
      :name="header.paymentKind.sortable"
      :active="sortableColumn"
      class="tbl-icon"
      @sort-change="onSortChange"
    >
      {{ header.paymentKind.text }}
      <footnote :text="t('payment_kind')" />
    </si-column>

    <si-column
      :name="header.date.sortable"
      :active="sortableColumn"
      class="date cut"
      @sort-change="onSortChange"
    >
      {{ header.date.text }}
    </si-column>

    <si-column
      :name="header.byCash.sortable"
      :active="sortableColumn"
      @sort-change="onSortChange"
    >
      {{ header.byCash.text }}
    </si-column>

    <si-column
      :name="header.byCashless.sortable"
      :data-tooltip="header.byCashless.text"
      :active="sortableColumn"
      @sort-change="onSortChange"
    >
      {{ header.byCashless.text }}
    </si-column>

    <si-column
      :name="header.byCard.sortable"
      :data-tooltip="header.byCard.text"
      :active="sortableColumn"
      @sort-change="onSortChange"
    >
      {{ header.byCard.text }}
    </si-column>

    <si-column
      :name="header.byBalance.sortable"
      :data-tooltip="header.byBalance.text"
      :active="sortableColumn"
      @sort-change="onSortChange"
    >
      {{ header.byBalance.text }}
    </si-column>

    <si-column
      :name="header.byCredit.sortable"
      :active="sortableColumn"
      @sort-change="onSortChange"
    >
      {{ header.byCredit.text }}
    </si-column>

    <si-column
      :name="header.income.sortable"
      :active="sortableColumn"
      @sort-change="onSortChange"
    >
      {{ header.income.text }}
      <footnote :text="footnotes.income" />
    </si-column>

    <si-column
      :name="header.paid.sortable"
      :active="sortableColumn"
      @sort-change="onSortChange"
    >
      {{ header.paid.text }}
      <footnote :text="footnotes.paid" />
    </si-column>

    <si-column
      :name="header.order.sortable"
      :active="sortableColumn"
      @sort-change="onSortChange"
    >
      {{ header.order.text }}
      <footnote :text="footnotes.order" />
    </si-column>

    <si-column
      :name="header.user.sortable"
      :active="sortableColumn"
      @sort-change="onSortChange"
    >
      {{ header.user.text }}
    </si-column>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field'
import SiColumn from '@/vue_components/sort_items/si_table/si_column'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import Footnote from '@/vue_components/common/footnote'

export default {
  name: 'PaymentsReportTableHeader',
  components: { Footnote, SiColumn, SiField },
  props: {
    header: PropsTypes.Object(undefined, true),
    footnotes: PropsTypes.Object(undefined, true),
    sortableColumn: PropsTypes.String(),
  },

  methods: {
    onSortChange ($event) {
      this.$emit('onSortChange', $event)
    },
  },
}
</script>
