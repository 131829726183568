<template>
  <el-menu
    :mode="menuMode"
    :active-text-color="'#3FAECA'"
    :default-active="defaultActive"
    :collapse-transition="collapseTransition"
  >
    <el-menu-item
      v-for="(menuItem, index) in items"
      :key="index"
      :index="menuItem.index || `${index}`"
      :disabled="menuItem.isCategory"
      class="font-size-13"
      @click="$emit('menuItemClick', menuItem)"
    >
      <template slot="title">
        <template v-if="menuItem.isCategory">
          {{ menuItem[titleName] }}
        </template>

        <template v-else>
          <i
            :class="commonIcon || menuItem.icon"
            :data-tooltip="menuItem.title"
          />
          <span class="primary-font-color">
            {{ menuItem[titleName] }}
          </span>
        </template>
      </template>
    </el-menu-item>
  </el-menu>
</template>

<script>
import withTooltips from '@/vue_components/mixins/withTooltips'

export default {
  name: 'MenuBar',
  mixins: [withTooltips],

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    // or 'horizontal'
    menuMode: {
      type: String,
      default: 'vertical',
    },

    titleName: {
      type: String,
      default: 'title',
    },

    commonIcon: {
      type: String,
      default: '',
    },

    collapseTransition: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    defaultActive () {
      return this.$route && this.$route.meta && this.$route.meta.menuItem
    },
  },

  mounted () {
    this.updateTooltips()
  },

  updated () {
    this.updateTooltips()
  },
}
</script>
