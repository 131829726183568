import { DateHelpers } from 'src/helpers/DateHelpers'
import { _getReferralsReportFilters as getFilters } from './_filtersGetter'
import { IReferralsReportFiltersGet, IReferralsReportFiltersSet } from './interfaces'

let initialFilters: IReferralsReportFiltersGet = null

export const saveReferralsReportInitialFilters = (): void => {
  initialFilters = getFilters()
}

export const getReferralsReportInitialFilters = (): IReferralsReportFiltersSet => ({
  ...initialFilters,
  period: DateHelpers.getCurrentMonthPeriod(),
})
