<template>
  <modal
    v-if="showWorkTimeModal"
    :modal-class="'work-time-modal'"
    :modal-visibility="showWorkTimeModal"
    @close="$emit('close')"
  >
    <template slot="header">
      <span class="fad fa-fw fa-calendar-alt" />
      <span>{{ T.user_worktime }}</span>
    </template>
    <work-time-modal-body
      slot="body"
      ref="workTimeModal"
      v-bind="workTimeModalData"
      :cabinets="cabinets"
      :schedule-templates-map="scheduleTemplatesMap"
      :online-recording-enabled="onlineRecordingEnabled"
      @close="$emit('close')"
      @showUpdateWorkTimeModal="data => $emit('showUpdateWorkTimeModal', data)"
      @showRemoveWorkTimeModal="data => $emit('showRemoveWorkTimeModal', data)"
      @showAddVacationModal="data => $emit('showAddVacationModal', data)"
      @activeRequestState="state => $emit('activeRequestState', state)"
    />
    <work-time-modal-footer
      slot="footer-right"
      @close="$emit('close')"
      @save="$refs.workTimeModal.saveChanges()"
    />
  </modal>
</template>

<script>
import Modal from '../../../../modal.vue'
import WorkTimeModalBody from './work_time_body.vue'
import WorkTimeModalFooter from './work_time_footer.vue'

export default {
  name: 'WorkTimeModal',
  components: {
    Modal,
    WorkTimeModalBody,
    WorkTimeModalFooter,
  },
  props: {
    showWorkTimeModal: { type: Boolean, required: true },
    cabinets: { type: Array, required: true },
    scheduleTemplatesMap: { type: Object, required: true },
    workTimeModalData: { type: Object, required: true },
    onlineRecordingEnabled: { type: Boolean, required: true },
  },
  methods: {
    getCore () {
      return this.$refs.workTimeModal
    },
  },
}
</script>
