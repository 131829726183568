<template>
  <div class="appointment-call-log-wrapper">
    <m-modal
      ref="modal"
      :visible.sync="modalVisibility"
      can-fullscreen
      width="80%"
      @close="close"
    >
      <template #header>
        <span>
          <m-icon
            icon="call"
            class="mr-5"
          />
          {{ t('reception.call_log.name') }}
        </span>
      </template>

      <template #body>
        <appointment-call-log
          v-if="isRendered"
          :doctor-schedules-clinic-id="doctorSchedulesClinicId"
          @close="close"

          @set-show-appointment="$emit('show-appointment-in-schedule', $event)"
          @edit-appointment="$emit('edit-appointment-modal-of-grid', $event)"

          @findAppointment="onFindAppointment"
          @editAppointment="onEditAppointment"
        />
      </template>

      <template #footer-left>
        <pagination
          v-if="vxTotalPages > 1"
          layout="prev, pager, next"
          :current-page="vxCurrentPage"
          :page-count="vxTotalPages"
          @current-change="vxSetPage"
        />
      </template>
    </m-modal>

    <slot name="reference">
      <!-- TODO: после апдейта расписания заменить кнопку-->
      <button
        v-tooltip="t('reception.call_log.name')"
        type="button"
        class="btn btn-xs btn-grey-dark"
        @click="open"
      >
        <i class="fad fa-phone-office" />
      </button>
    </slot>
  </div>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import Pagination from '@/vue_components/common/pagination.vue'
import { mapGetters, mapMutations } from 'vuex'
import AppointmentCallLog from '@/vue_apps/DoctorSchedules/AppointmentCallLog/AppointmentCallLog.vue'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

export default {
  name: 'AppointmentCallLogRoot',
  components: { AppointmentCallLog, Pagination, MIcon, MModal },
  props: {
    doctorSchedulesClinicId: { type: Number, required: true },
  },

  data () {
    return {
      modalVisibility: false,

      /* для задержки отрисовки чтобы избежать дёрганья UI */
      isRendered: false,
    }
  },

  computed: {
    ...mapGetters('appointmentCallLogStore/filtersBase', {
      vxCurrentPage: 'vxGetCurrentPage',
      vxTotalPages: 'vxGetTotalPages',
    }),
  },

  methods: {
    ...mapMutations('appointmentCallLogStore/filtersBase', {
      vxSetPage: 'vxSetPage',
    }),

    close () {
      this.modalVisibility = false
    },

    open () {
      this.modalVisibility = true
      this.$nextTick(() => { this.isRendered = true })
    },

    /**
     * 🩼🩼🩼
     * Модалки element имеют свой вычисляемый z-index, а бутстраповские нет
     * @private
     */
    __zIndexCrutch () {
      try {
        this.$refs.modal.$el.style.zIndex = 1050
      } catch (err) {}
    },

    /**
     * @param {callLogAppointment} appointment
     * @private
     */
    __confirmGate (appointment) {
      return MConfirm.warning(t('reception.call_log.clinic_change_confirmation'))
        .then(() => Promise.resolve(true))
        .catch(() => Promise.resolve(false))
    },

    /** @param {callLogAppointment} appointment */
    async onFindAppointment (appointment) {
      if (this.doctorSchedulesClinicId === appointment.clinicId) {
        this.close()

        return this.$emit('findAppointment', appointment)
      }

      const confirm = await this.__confirmGate(appointment)
      if (!confirm) { return }
      this.close()
      this.$emit('findAppointmentInOtherClinic', appointment)
    },

    /** @param {callLogAppointment} appointment */
    async onEditAppointment (appointment) {
      this.__zIndexCrutch()
      if (this.doctorSchedulesClinicId === appointment.clinicId) {
        return this.$emit('editAppointment', appointment)
      }

      const confirm = await this.__confirmGate(appointment)
      if (!confirm) { return }
      this.$emit('editAppointmentInOtherClinic', appointment)
    },
  },
}
</script>
