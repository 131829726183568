<template>
  <div
    v-loading="isWidgetLogoExist === null"
    class="widget-logo"
  >
    <m-image-picker
      v-loading="loading"
      rounded
      :img-source="imgSource"
      :is-can-delete="isWidgetLogoExist"
      :popover-message="t('client_widget.removeLogo')"
      @file="fileHandler"
      @delete="deleteWidgetLogo"
    />

    <div
      v-show="!isWidgetLogoExist"
      class="widget-logo__requirements"
    >
      <i>{{ t('client_widget.defaultLogoIsCurrentlyUsed') }}</i>
      <i>{{ t('client_widget.uploadYourLogo') }}</i>
      <i>
        {{ t('client_widget.requirements', { maxFileSize }) }}
        <span>{{ t('mbyte') }}</span>
      </i>
    </div>

    <image-cropper-modal
      preview
      rounded
      :visible.sync="showModalImageCropper"
      :img-source="imgToCrop"
      :dialog-title="t('client_widget.logoEditing')"
      @cropped-image="croppedImageHandler"
    />
  </div>
</template>

<script>
import MImagePicker from '@/vue_present/_base/MImagePicker/MImagePicker.vue'
import ImageCropperModal from '@/vue_apps/ImageCropper/ImageCropperModal.vue'
import { MClientWidgetLogoPresenter } from '@/vue_components/client_widget/WidgetLogo/api/MClientWidgetLogoPresenter'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { WIDGET_LOGO_PATH } from '@/vue_components/client_widget/WidgetLogo/const'

const presenter = new MClientWidgetLogoPresenter()

export default {
  name: 'WidgetLogo',
  components: { ImageCropperModal, MImagePicker },

  mixins: [SpinnerHolder],

  props: {
    maxFileSize: { type: Number, default: Utils.getMaxFileSize() },
  },

  data () {
    return {
      showModalImageCropper: false,
      isWidgetLogoExist: null,
      imgSource: WIDGET_LOGO_PATH,
      imgToCrop: '',
    }
  },

  beforeUnmount () {
    URL.revokeObjectURL(this.imgSource)
  },

  async mounted () {
    this.isWidgetLogoExist = await presenter.checkWidgetLogoExistence()
  },

  methods: {
    fileHandler (file) {
      this.showModalImageCropper = true
      this.imgToCrop = file
    },

    croppedImageHandler (blob) {
      URL.revokeObjectURL(this.imgSource)
      this.showModalImageCropper = false

      this.withSpinner(
        presenter
          .updateWidgetLogo(blob)
          .then((result) => {
            this.imgSource = result
              ? URL.createObjectURL(blob)
              : WIDGET_LOGO_PATH

            this.isWidgetLogoExist = this.isWidgetLogoExist || result
          })
      )
    },

    deleteWidgetLogo () {
      this.withSpinner(
        presenter
          .deleteWidgetLogo()
          .then((result) => {
            if (result) {
              URL.revokeObjectURL(this.imgSource)
              this.imgSource = WIDGET_LOGO_PATH
              this.isWidgetLogoExist = false
            }
          })
      )
    },
  },
}
</script>
