export default {
  _addWorkingIntervalEvent () {
    this.isomorphicTemplates.push(this.templateFactory.getTemplate())
  },

  _removeWorkingIntervalEvent (id) {
    if (id < 0) {
      this._removeIsomorphicTemplateById(id)

      return
    }

    const workTime = this.workTimes.find((workTime) => workTime.id === id)

    if (!workTime.schedule_template_id) {
      this._removeWorkTime(workTime.id)
    } else {
      const time = workTime.work_time.split('-')
      const template = this.scheduleTemplates.find((template) => template.id === workTime.schedule_template_id)

      this.activeScheduleTemplateData = {
        workTime,
        template,
      }

      this.$emit('showRemoveWorkTimeModal', {
        visibility: true,
        startTime: time[0],
        endTime: time[1],
        startDate: template.interval_start_date,
        date: workTime.date,
      })
    }
  },
}
