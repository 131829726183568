const WORKLOAD_NOT_FOUND = 0
const NOT_VACATION = false
const NO_WORKLOAD = false

class DoctorsWorkloadStorage {
  constructor (doctorsWorkload = {}, doctorsVacations = {}) {
    this.doctorsVacations = new Map()
    this.doctorsWorkload = new Map()

    Object
      .entries(doctorsVacations)
      .forEach(([doctorId, vacations]) => this.doctorsVacations.set(Number(doctorId), vacations))

    Object
      .entries(doctorsWorkload)
      .forEach(([doctorId, objectWithDoctorsWorkloadByDay]) => {
        const mapWithDoctorsWorkloadByDay = new Map()

        Object
          .entries(objectWithDoctorsWorkloadByDay)
          .forEach(([day, workload]) => mapWithDoctorsWorkloadByDay.set(day, workload))

        this.doctorsWorkload.set(Number(doctorId), mapWithDoctorsWorkloadByDay)
      })
  }

  // Возвращает нагрузку врача в % за определённый день
  // Если врача нет ворктайма, то возвращает 0
  dailyDoctorWorkload (doctorId, day) {
    const doctorsWorkload = this.doctorsWorkload.get(doctorId)

    if (!doctorsWorkload) return WORKLOAD_NOT_FOUND

    const dailyWorkload = doctorsWorkload.get(day)
    if (!dailyWorkload) return WORKLOAD_NOT_FOUND

    return dailyWorkload
  }

  // Возвращает нагрузку доктора в конкретный день, если она есть (даже если = 0)
  // Если у доктора нет ворктайма, то возвращает false
  doctorHasDailyWorkload (doctorId, day) {
    const doctorsWorkload = this.doctorsWorkload.get(doctorId)
    if (!doctorsWorkload) return NO_WORKLOAD

    return doctorsWorkload.get(day) >= 0
  }

  hasDoctorVacation (doctorId, day) {
    const doctorVacations = this.doctorsVacations.get(doctorId)

    return doctorVacations ? doctorVacations.includes(day) : NOT_VACATION
  }

  static instance (clinicId, clinicsWorkload) {
    try {
      const {
        doctors: {
          workload: {
            [clinicId]: workloadByClinic,
          },
          vacations,
        },
      } = clinicsWorkload

      if (workloadByClinic) return new DoctorsWorkloadStorage(workloadByClinic, vacations)
      throw new Error(`clinic workload with id ${clinicId} does not exist`)
    } catch (e) {
      console.debug(e)

      return new DoctorsWorkloadStorage()
    }
  }
}

export default DoctorsWorkloadStorage
