import Vue from 'vue/dist/vue.esm'
import UisControlPanel from '@/vue_components/communications/telephony/user/uis_control_panel.vue'
import MangoControlPanel from '@/vue_components/communications/telephony/user/mango_control_panel.vue'
import ItoolabsControlPanel from '@/vue_components/communications/telephony/user/itoolabs_control_panel.vue'
import AvatarEdit from '@/vue_components/user_avatar_uploaders/avatar_edit.vue'
import AvatarNew from '@/vue_components/user_avatar_uploaders/avatar_new.vue'
import CatalogUsersClinicSelector from '@/vue_components/users/CatalogUsersClinicSelector/CatalogUsersClinicFilter'
import { createEgiszUserTab } from '@/vue_components/egisz/egisz_user_tab'
import { subscribeToClinicsSelector } from '@/specific/users/clinics_selector'
import { subscribeToUserStatus } from '@/specific/users/user_status'
import { createVueApp } from '@/vue_components/create_vue_app'
import { createSelectClinic } from '@/vue_components/users/SelectClinic'
import { createStore } from '@/vue_components/store/store'
import { createUserRolesLegacy } from '@/vue_components/users/UserRolesLegacy'
import { registerSalaryApp } from '@/specific/users/users/registerSalaryApp'

$document.on('rez/users/edit rez/users/new', function () {
  const form = FormFactory.build('user')
  $('.user-search-field').css('padding-left', '2px')

  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      // eslint-disable-next-line
      new Vue({
        el: '#avatar-container',
        components: { AvatarNew },
        data: { defaultAvatarsPaths: gon.specific.default_avatars_paths },
        template: `<div id="avatar-container">
                     <avatar-new
                       :defaultAvatarsPaths="defaultAvatarsPaths"
                     />
                   </div>`,
      })
      break
    case 'edit':
      form.editItem()

      // eslint-disable-next-line
      new Vue({
        el: '#avatar-container',
        components: { AvatarEdit },
        template: `<div id="avatar-container">
                    <avatar-edit />
                   </div>`,
      })
      break
  }

  // Роли
  createUserRolesLegacy('.user_role_ids')

  // вкладка ЕГИСЗ у врача
  createEgiszUserTab('#vue_egisz_control_tab', gon.specific.user, gon.specific.clinics)
})

$document.on('rez/users', () => {
  createVueApp({
    el: '#clinics_container',
    name: 'UserSelectClinic',
    store: createStore(),
    components: { CatalogUsersClinicSelector },
    template: `<div id="clinics_container">
                 <catalog-users-clinic-selector />
               </div>`,
  })

  const search = $('#user_fast_search')
  const sorting = gon.specific.sorting || 'title'

  search.smartSearch({
    url: '/utils/search',
    queryParams: { model: 'user' },
    onSelect (item) {
      Utils.searchAndTurbolink({
        item,
        model: 'user',
        route: Routes.edit_user_path,
        page_number_options: { sorting, scope: 'all_not_service_with_dismissed' },
      })
    },
    itemRender (item, object) {
      const dateSpan = $('<span></span>').html(object.full_name)
      item.append(dateSpan)

      return item
    },
    resizeMenu () {
      this.menu.element.outerWidth(search.outerWidth())
    },
  })

  // specialty search start

  let specSearch = $('#user_spec_search')
  let selectedSpec

  $('#reset_spec').on('click', resetSpecialtyFilter)
  $(document).on('turbolinks:load', afterSpecFilter)
  $(document).on('turbolinks:before-visit', beforePageUpdate)

  function resetSpecialtyFilter () {
    specSearch.val('')
    // navigate if needed
    const params = Utils.getQueryParams()
    if (params.hasOwnProperty('specialty_id')) {
      delete params.specialty_id
      Turbolinks.visit(Routes.users_path(params))
    }
  }

  function afterSpecFilter () {
    // re-evaluate selector after turbolinks loads the selector
    // or it fails to find a DOM node
    specSearch = $(specSearch.selector)
    specSearch.val(selectedSpec)
    // clean up listener to not execute selectors on other pages
    $(document).off('turbolinks:load', afterSpecFilter)
  }

  function beforePageUpdate () {
    selectedSpec = specSearch.val()
    $(document).off('turbolinks:before-visit', beforePageUpdate)
  }

  specSearch.smartSearch({
    url: Routes.search_specialties_path(),
    method: 'GET',
    dataPrepare (res) {
      return res.result
    },
    onSelect (item) {
      selectedSpec = item.title
      // construct new path, keep current query params
      const params = Object.assign(Utils.getQueryParams(), { specialty_id: item.id })
      Turbolinks.visit(Routes.users_path(params))
    },
    itemRender (item, object) {
      const dateSpan = $('<span></span>').text(object.title)
      item.append(dateSpan)

      return item
    },
    resizeMenu () {
      this.menu.element.outerWidth(search.outerWidth())
    },
  })

  // speciality search end

  $('.el-select-dropdown__item').on('click', function () {
    const clinicTitle = $(this.firstElementChild).attr('data-original-title') || T.all_clinics
    $('.for_render').val(clinicTitle)
  })
  Turbolinks.clearCache()
})

$document.on('rez/users/edit', () => {
  const clinicRef = window.location.href.split('clinic_id=')[1]
  const disabledTrs = [...$('#user_form').find('.disabled')]

  disabledTrs.forEach((tr) => { $(tr).css('pointer-events', 'none') })

  if (clinicRef) {
    $('.clinic_handler').val(clinicRef.charAt(0))
  }

  function initUisControlPanel () {
    if (!gon.application.telephony.uis) return

    // eslint-disable-next-line no-unused-vars
    const uisControlPanel = new Vue({
      el: '#uis_control_panel',
      components: { UisControlPanel },
      data: {
        user: gon.specific.user,
        visibility: true,
      },
      template: '<uis-control-panel ' +
    'v-bind:user="user" ' +
    'v-bind:visibility="visibility" ' +
    '/>',
    })
  }

  function initMangoControlPanel () {
    if (!gon.application.telephony.mango) return

    // eslint-disable-next-line no-unused-vars
    const mangoControlPanel = new Vue({
      el: '#mango_control_panel',
      components: { MangoControlPanel },
      data: {
        user: gon.specific.user,
        visibility: true,
      },
      template: '<mango-control-panel ' +
    'v-bind:user="user" ' +
    'v-bind:visibility="visibility" ' +
    '/>',
    })
  }

  function initItoolabsControlPanel () {
    if (!gon.application.telephony.itoolabs) return

    // eslint-disable-next-line no-unused-vars
    const itoolabsControlPanel = new Vue({
      el: '#itoolabs_control_panel',
      components: { ItoolabsControlPanel },
      data: {
        user: gon.specific.user,
        visibility: true,
      },
      template: '<itoolabs-control-panel ' +
    'v-bind:user="user" ' +
    'v-bind:visibility="visibility" ' +
    '/>',
    })
  }

  if (gon.application.telephony.self) {
    initUisControlPanel()
    initMangoControlPanel()
    initItoolabsControlPanel()
  }

  registerSalaryApp()
})

$document.on('rez/users/select_clinic', () => {
  $('#clinic_select_form').remote_form({
    model: 'user',
    onSuccess () {
      Turbolinks.visit('/')
    },
  })

  createSelectClinic()
})

$document.on('rez/users/edit rez/users/new', function () {
  setTimeout(subscribeToClinicsSelector, 0)
  setTimeout(subscribeToUserStatus, 0)
})
