import { MListService } from '@/_api/_requests/MListService'
import {
  MClientAttachmentsPresenter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/api/MClientAttachmentsPresenter'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'

export class AttachmentsList extends MListService {
  /**
   * @type {MClientAttachmentsPresenter}
   * @private
   */
  __presenter

  constructor (clientId) {
    const presenter = new MClientAttachmentsPresenter()
    super({ clientId }, presenter)
    this.__presenter = presenter
  }

  /**
   * @param {number} id
   * @returns {Promise<boolean>}
   */
  async delete (id) {
    return this.__presenter.destroy(id)
  }

  async upload (clientId, file) {
    return this.__presenter.upload(clientId, file)
      .then(MRequestSuccess.withNotify(undefined, { entity: 'attachment', message: 'uploadMessage' }))
      .catch((err) => {
        const errorText = err.responseJSON?.file[0] || ''
        Utils.reportError('AttachmentsList.upload', errorText)(err)

        return { errors: err }
      })
  }

  async print (id, mimeType) {
    window.printJS(Routes.show_client_attachment_path(id), mimeType)
  }
}
