import { MAX_WAITING_LIST_ITEMS, SELECT_ALL_ID } from './const.js'
import { fetchUserWaitingList } from '../rest'

export default {

  fetchUserWaitingList () {
    fetchUserWaitingList({
      user_ids: JSON.stringify(this.allUIDs),
      clinic_id: this.$store.getters.GET_SCHEDULE_CURRENT_CLINIC_ID,
      limit: MAX_WAITING_LIST_ITEMS,
    })
      .then((waitingListUserMap) => {
        Object.keys(waitingListUserMap).forEach((uid) => {
          waitingListUserMap[uid].forEach((waitingListId) => this.waitingListIdsSet.add(waitingListId))
        })
        this.waitingListUserMap = waitingListUserMap
        this._emitWaitingListCount()

        Services.wsSubscriptions.waitingList.connect(
          (packet) => packet.batch
            ? this._waitingListBatchUpdatesHandler(packet)
            : this._waitingListUpdatesHandler(packet)
        )
      })
      .catch((err) => { console.log(err) })
  },

  setUsersAndSpecialty (userIds, specialtyId) {
    this.selectedSID = typeof specialtyId === 'number' ? specialtyId : this.selectedSID
    this._setVisibleUsers()
    this.selectedUIDsInSID[this.selectedSID] = userIds.filter((uid) => this.availableUserIds.has(uid))
    this._updateStore()
    this._onChange()
  },

  _setVisibleSpecialties () {
    let filteredSpecialtiesIds

    if (this.filterUsers) {
      this.selfFilteredUserIds = this.filteredUserIds.filter((id) => this.usersMap[id])

      filteredSpecialtiesIds = Array.from(this.selfFilteredUserIds.reduce((specialtiesSet, userId) => {
        specialtiesSet.add(...this.usersMap[userId].specialty_ids)

        return specialtiesSet
      }, new Set()))

      this.selectedSID = filteredSpecialtiesIds.includes(this.selectedSID) ? this.selectedSID : SELECT_ALL_ID
    }

    if (this.showSpecialtiesList) {
      this.visibleSpecialties = this.filterUsers
        ? filteredSpecialtiesIds.map((specialtyId) => this.specialtiesMap[specialtyId]) : this.specialties.slice()
    } else {
      this.visibleSpecialties = []
    }
  },

  _setVisibleUsers () {
    const god = this

    const userIds = god.filterUsers ? god.selfFilteredUserIds : god.allUIDs
    const availableUserIds = god.selectedSID === SELECT_ALL_ID
      ? userIds : userIds.filter((id) => god.usersMap[id].specialty_ids.includes(god.selectedSID))
    god.availableUserIds = new Set(availableUserIds)
    god.visibleUsers = availableUserIds.map((uid) => god.usersMap[uid])
  },

  _getWaitingListCount (userId) {
    if (this.waitingListUserMap && this.waitingListUserMap[userId]) {
      return this.waitingListUserMap[userId].length
    }
    return 0
  },

  _waitingListBatchUpdatesHandler (batchUpdatePacket) {
    batchUpdatePacket.data.forEach((waitingListItem) => {
      this._waitingListUpdatesHandler({
        type: batchUpdatePacket.type,
        action: batchUpdatePacket.action,
        data: waitingListItem,
      })
    })
  },

  _waitingListUpdatesHandler (packet) {
    if (!Array.isArray(this.waitingListUserMap[packet.data.user_id])) return

    const clinicConform = packet.data.clinic_id === null || packet.data.clinic_id === this.GET_SCHEDULE_CURRENT_CLINIC_ID

    if (packet.action === 'created' && clinicConform) {
      this._waitingListUpdatesAdd(packet)
    } else if (packet.action === 'deleted' && clinicConform) {
      this._waitingListUpdatesRemove(packet)
    } else if (packet.action === 'updated') {
      const itemIsPresent = this.waitingListIdsSet.has(packet.data.id)

      if (itemIsPresent && !clinicConform) {
        this._waitingListUpdatesRemove(packet)
      } else if (itemIsPresent && clinicConform) {
        this._waitingListUpdatesRemove(packet)
        this._waitingListUpdatesAdd(packet)
      } else if (!itemIsPresent && clinicConform) {
        this._waitingListUpdatesAdd(packet)
      }
    }
    this._emitWaitingListCount()
  },

  _waitingListUpdatesAdd (packet) {
    this.waitingListUserMap[packet.data.user_id].push(packet.data.id)
    this.waitingListIdsSet.add(packet.data.id)
  },

  _waitingListUpdatesRemove (packet) {
    const uids = this.allUIDs

    for (let i = 0; i < uids.length; i++) {
      const waitingListIds = this.waitingListUserMap[uids[i]]
      if (waitingListIds && waitingListIds.includes(packet.data.id)) {
        this.waitingListUserMap[uids[i]] = waitingListIds.filter((wlid) => wlid !== packet.data.id)
        break
      }
    }
    this.waitingListIdsSet.delete(packet.data.id)
  },

  _emitWaitingListCount () {
    const count = Object
      .keys(this.waitingListUserMap)
      .reduce((memo, uid) => {
        memo[uid] = this.waitingListUserMap[uid].length

        return memo
      }, Object.create(null))
    this.$emit('waitingListCount', count)
  },

  _setAvailableUserIds () {
    const userIds = this.filterUsers ? this.selfFilteredUserIds : this.allUIDs
    this.availableUIDs = this.selectedSID === SELECT_ALL_ID
      ? userIds
      : userIds.filter((id) => this.usersMap[id].specialty_ids.includes(this.selectedSID))
  },

  _workTimeUpdatesHandler (packet) {
    if (packet.dates_with_changes.length) {
      packet.dates_with_changes.forEach((data) => {
        if (this.GET_SCHEDULE_CURRENT_CLINIC_ID === data.template.clinic_id &&
          this.availableUIDs.includes(data.template.user_id) &&
          data.dates.some((date) => this._dates.includes(date))) {
          this._onChange()
        }
      })
    }

    if (packet.days_with_changes.length) {
      packet.days_with_changes.forEach((data) => {
        if (window.application.current_clinic.id === data.clinic_id &&
          this.availableUIDs.includes(data.user_id) &&
          data.dates.some((date) => this._dates.includes(date))) {
          this._onChange()
        }
      })
    }
  },
}
