const WHOLE_DOCUMENT_ID = 0

export default class PrintAreaSelector {
  constructor (params) {
    if (!params.items) throw new Error(`items: expected array of items, got: ${params.items}`)
    this._editorHTMLElement = params.editorHTMLElement
    this._originalHTML = this._editorHTMLElement.innerHTML
    this._modal = ModalFactory({
      title: t('print_areas'),
      showButtons: false,
      fullscreenButton: false,
    })
    this._modal[0].id = 'print_area_selector_modal'
    this._modalBody = this._modal[0].querySelector('.modal-body')
    this._modalFooter = this._modal[0].querySelector('.modal-footer')
    this._items = this._expandItems(params.items)
    this.render()
  }

  render () {
    this._modalBody.innerHTML = this._htmlItems()
    this._modalFooter.innerHTML = this._htmlFooter()
    this._bindEvents()
  }

  _htmlItems () {
    const items = this._items.reduce((acc, item) => {
      return acc + `
        <div class='print_area_selector_item cut'>
          <input type='checkbox' class='checkbox' ${item.checked} ${item.disabled} value='${item.id}'>
          <span class='print_area_item_title ${item.disabled}'>${item.title}</span>
        </div>
      `
    }, '')

    return `<div class='print_area_selector_container'>${items}</div>`
  }

  _htmlFooter () {
    return `
      <span class='print-area-all-areas pointer underlined-dashed el-link--primary'>${t('selectAllPrintAreas')}</span>
    `
  }

  _onCheckboxChanged (checkbox) {
    const item = this._findItem(checkbox.value)
    const wholeDocItem = this._findItem(0)
    const withoutWholeDocItems = this._items.filter((item) => item !== wholeDocItem)

    if (item === wholeDocItem) {
      if (item.checked) {
        withoutWholeDocItems.forEach((i) => { i.checked = false })
        wholeDocItem.disabled = 'disabled'
      }
    } else {
      if (item.checked) {
        wholeDocItem.checked = false
        wholeDocItem.disabled = false
      } else {
        if (withoutWholeDocItems.every((item) => !item.checked)) {
          wholeDocItem.checked = 'checked'
          wholeDocItem.disabled = 'disabled'
        }
      }
    }
  }

  _bindEvents () {
    const elems = this._modalBody.querySelectorAll('.print_area_selector_item')

    const handler = (checkbox, item) => {
      item.checked = checkbox.checked ? 'checked' : false
      this._onCheckboxChanged(checkbox)
      this.render()
      this._editorHTMLElement.innerHTML = this._rebuildDocument()
    }

    elems.forEach((elem) => {
      const checkbox = elem.querySelector('.checkbox')
      const title = elem.querySelector('.print_area_item_title')
      const item = this._findItem(checkbox.value)

      checkbox.onchange = () => { handler(checkbox, item) }
      title.onclick = () => {
        if (!item.disabled) {
          checkbox.checked = checkbox.checked ? false : 'checked'
          handler(checkbox, item)
        }
      }
    })

    this._bindAllAreasButtonEvents()
  }

  _expandItems (items) {
    const html = this._originalHTML
    const _items = items.map((item) => {
      const regex = new RegExp(`<p[^>]*print_area_start[^>]*data-id="${item.id}"[^>]*>.*?</p>[\\s\\S]*<p[^>]*print_area_end[^>]*data-id="${item.id}"[^>]*>.*?</p>`)
      const match = html.match(regex)

      return {
        id: parseInt(item.id),
        title: item.title,
        checked: false,
        disabled: match ? false : 'disabled',
        start: match ? match.index : null,
        end: match ? match.index + match[0].length : null,
      }
    })

    const wholeDocumentItem = {
      id: WHOLE_DOCUMENT_ID,
      title: t('whole_document'),
      checked: 'checked',
      disabled: 'disabled',
      start: html[0],
      end: html.length,
    }

    return [wholeDocumentItem, ..._items]
  }

  _findItem (id) {
    return this._items.find((item) => item.id === parseInt(id))
  }

  show () {
    this._modal.megamodal('show')
  }

  hide () {
    this._modal.megamodal('hide')
  }

  get checkedItems () {
    return this._items.filter((item) => item.checked).sort((a, b) => a.start - b.start)
  }

  _rebuildDocument () {
    let resultHTML = ''
    const currentRange = {}
    const checkedItems = this.checkedItems
    checkedItems.forEach((item) => {
      if (!Object.keys(currentRange).length || item.start > currentRange.end) {
        currentRange.start = item.start
        currentRange.end = item.end
        resultHTML += this._originalHTML.substring(item.start, item.end)
      } else if (item.start <= currentRange.end && item.end > currentRange.end) {
        resultHTML += this._originalHTML.substring(currentRange.end, item.end)
        currentRange.end = item.end
      }
    })

    return resultHTML
  }

  _bindAllAreasButtonEvents () {
    const allAreas = this._modalFooter.querySelector('.print-area-all-areas')
    allAreas.onclick = () => {
      this._items.forEach((item) => {
        item.checked = item.id === WHOLE_DOCUMENT_ID
          ? false
          : 'checked'

        if (item.id === WHOLE_DOCUMENT_ID) { item.disabled = false }
      })

      this.render()
      this._editorHTMLElement.innerHTML = this._rebuildDocument()
    }
  }
}
