<template>
  <div
    class="client-search-filter"
  >
    <client-search-input
      :value="shortInfo"
      @input="inputHandler"
      @onItemClick="onItemClickHandler"
    />

    <input
      id="client_id"
      name="client_id"
      :value="clientId"
    >
  </div>
</template>

<script>
import ClientSearchInput from '@/vue_present/Reuse/Lists/ClientSearchInput/ClientSearchInput.vue'

let form

export default {
  name: 'ClientSearchFilter',

  components: {
    ClientSearchInput,
  },

  data () {
    return {
      clientId: null,
      shortInfo: null,
    }
  },

  mounted () {
    form = document.querySelector('form.loggings-form')
    const params = new URLSearchParams(location.search)

    this.clientId = params.get('clientId')
    this.shortInfo = params.get('shortInfo')

    if (!this.clientId || !this.shortInfo) { return }

    this.updateFromAction()
  },

  methods: {
    inputHandler (value) {
      if (value !== null) { return }

      this.clientId = null
      this.shortInfo = null

      this.removeURLParams()
    },

    onItemClickHandler ({ id, shortInfo }) {
      this.clientId = id
      this.shortInfo = shortInfo

      this.updateFromAction()
    },

    updateFromAction () {
      const actionURL = new URL(form.action)

      actionURL.searchParams.set('clientId', this.clientId)
      actionURL.searchParams.set('shortInfo', this.shortInfo)

      form.action = `${actionURL.pathname}${actionURL.search}`
    },

    removeURLParams () {
      const url = new URL(location)

      url.searchParams.delete('clientId')
      url.searchParams.delete('shortInfo')

      history.replaceState(null, null, url)
      form.action = url.pathname
    },
  },
}
</script>
