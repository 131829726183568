import {
  DentalOrderListItem,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DentalOrdersTab/api/DentalOrderListItem'

export const dentalOrdersListAdapter = {
  toClient (response) {
    return {
      ...response,
      data: response.data.map((item) => new DentalOrderListItem(item)),
    }
  },
}
