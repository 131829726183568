import Vue from 'vue/dist/vue.esm'
import ApiClient from '@/vue_components/api/client.vue'

$document.on('rez/api/clients', function () {
  if (gon.specific.moduleIsEnabled) {
    new Vue({
      el: '#api_clients_root_container',

      components: { ApiClient },

      template:
        `<api-client/>`,
    })
  }
})
