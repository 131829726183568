import { ReportParamStrict } from '@/specific/reports/shared'
import { FiltersProfilesFormattersToClient as FormattersToClient } from '../toClient'
import {
  TFiltersProfileToClientSelectMulti as ToClientSelectMulti,
  TFiltersProfileToClientSelectSingle as ToClientSelectSingle,
  TFiltersProfileToClientOption as ToClientOption,
  TFiltersProfileToClientHumanSelect as ToClientHumanSelect,
} from '@/vue_apps/FiltersProfilesApp/interfaces/filtersTypes/shared/toClient/selects'
import { TFiltersProfileToClientCheckbox as ToClientCheckbox } from '@/vue_apps/FiltersProfilesApp/interfaces/filtersTypes/shared/toClient/checkbox'
import { isUndefined } from 'lodash'

const extractLegacyOption = ({ id, title }: ToClientOption): ReportParamStrict => ({
  id: id.toString(),
  name: title,
})

export class FiltersProfilesLegacyFormattersToClient {
  static formatPeriod = FormattersToClient.formatPeriod

  /* Для значений селекторов */
  static formatSingle (optionInArray: ToClientSelectSingle): ReportParamStrict {
    if (optionInArray) { return extractLegacyOption(optionInArray[0]) }
  }

  /* Для значений мультиселекторов */
  static formatMulti (options: ToClientSelectMulti): ReportParamStrict[] {
    if (options) { return options.map(extractLegacyOption) }
  }

  /* Для значений селекторов, где выбираются людишки */
  static formatHuman (humanOptionInArray: ToClientHumanSelect): ReportParamStrict {
    if (!humanOptionInArray) { return }

    const [{ id, shortName, fullName }] = humanOptionInArray

    return extractLegacyOption({ id, title: shortName ?? fullName })
  }

  /* Для чекбоксов */
  static formatCheckbox (checkbox: ToClientCheckbox): ReportParamStrict {
    if (!isUndefined(checkbox)) {
      return {
        id: checkbox ? 'true' : '',
        name: '',
      }
    }
  }

  /* Для любых значений, у которых нет name, но в объект перегнать надо */
  static formatCommon (id: string): ReportParamStrict {
    return { id: id.toString(), name: '' }
  }
}
