
export const doctorSalaryReportSortingMap = Object.freeze({
  tableEntryNumber: 'entryNumber',
  tableEntryTitle: 'entryTitle',
  tableDate: 'entryDate',
  tableOrder: 'orderId',
  tableClient: 'clientSurname',
  tableEntrySum: 'entrySum',
  tableRate: 'salaryEntryRate',
  tableSalary: 'salaryEntrySum',
})
