import { render, staticRenderFns } from "./expenses_footer_default.vue?vue&type=template&id=7c85d0cd&scoped=true"
import script from "./expenses_footer_default.vue?vue&type=script&lang=js"
export * from "./expenses_footer_default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c85d0cd",
  null
  
)

export default component.exports