<template>
  <catalog-wrapper
    table-container-class="col-item-5"
    form-container-class="col-item-5"
    custom-table-class="discount-reasons-table"
    custom-form-class="discount-reasons-form"
    catalog-icon="fad fa-fw fa-badge-percent"
    :table-title="t('discount_reasons')"
    :form-create-title="t('creating_discount_reasons')"
    :form-edit-title="t('editing_discount_reasons')"
    :add-item-text="t('add_discount_reason')"
    :items="reasons"
    :loading="loadingList"
    :selected-mode="selectedMode"
    :is-show-form="isShowForm"
    :total-pages="totalPages"
    :can-delete="false"

    @ask-create="openCreateForm"
    @close="hideForm"
    @change-pagination="onPageChange"
    @submit="handleSubmit"
  >
    <template #table>
      <discount-reasons-table
        :reasons="reasons"
        :selected-list-item="selectedListItem"
        @ask-edit="openEditForm"
      />
    </template>

    <template #form>
      <discount-reasons-form
        v-bind.sync="formData"
        :validation-errors="validationMessages"
      />
    </template>
  </catalog-wrapper>
</template>

<script>
import { isEqual } from 'lodash'
import CatalogWrapper from '../catalog_wrapper/catalog_wrapper_deprecated.vue'
import DiscountReasonsTable from './components/discount_reasons_table.vue'
import DiscountReasonsForm from './components/discount_reasons_form.vue'
import { PaginationHolder } from '@/vue_components/mixins/pagination_holder'
import { CatalogHolder } from '@/vue_components/mixins/catalogs/catalogHolder'
import { requiredValidator } from '@/lib/validators/validators'
import { discountReasonsEndpoint } from '@/api_entities/catalogs/discount_reasons/discount_reasons_endpoint'
import { creators, PAGINATION_LIMIT } from './const'
import { trimValues } from '../../helpers'
import { findPreExistingTitle } from '../helpers'
import { MODES, NOTIFICATOR_LIFETIME } from '../const'

export default {
  name: 'DiscountReasonsCatalog',
  components: {
    CatalogWrapper,
    DiscountReasonsTable,
    DiscountReasonsForm,
  },

  mixins: [PaginationHolder, CatalogHolder],

  data () {
    return {
      reasons: [],
    }
  },

  watch: {
    'formData.title' (newValue) {
      this.validate('title', newValue, requiredValidator)
    },
  },

  created () {
    this.initCatalogHolder(creators)
    this.initPaginationHolder({
      limit: PAGINATION_LIMIT,
      offsetCallback: this.getReasons,
    })
    this.getReasons()
  },

  methods: {
    getReasons () {
      this.loadingList = true

      discountReasonsEndpoint.getAll(this.pagination)
        .then((data) => {
          this.reasons = data.data
          this.totalPages = data.total_pages
        })
        .catch((err) => Utils.reportError(
          'getReasons error',
          t('reception.errors.get_list')
        )(err))
        .finally(() => { this.loadingList = false })
    },

    createReason (data) {
      if (findPreExistingTitle(this.reasons, data)) {
        Notificator.warning(
          t('errors.pre_existing_title?', { item: t('discount_reason') }),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      discountReasonsEndpoint.create(data)
        .then(() => {
          this.clearForm()
          this.getReasons()

          Notificator.success(
            t('record_successfully_created'),
            '',
            NOTIFICATOR_LIFETIME
          )
        })
        .catch((err) => {
          if (err instanceof Error) {
            Utils.reportError(
              'createReason error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.create') }
              )
            )(err)
          } else {
            this.setValidationMessages(this.validationMessages, err.responseJSON)
          }
        })
    },

    updateReason (data) {
      if (findPreExistingTitle(this.reasons, data)) {
        Notificator.warning(
          t('errors.pre_existing_title?', { item: t('discount_reason') }),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      if (isEqual(this.previousFormData, data)) {
        Notificator.warning(
          t('reception.dont_find_update'),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      discountReasonsEndpoint.update(data)
        .then(() => {
          this.getReasons()
          this.previousFormData = { ...data }

          Notificator.info(t('changes_updated'), '', NOTIFICATOR_LIFETIME)
        })
        .catch((err) => {
          if (err instanceof Error) {
            Utils.reportError(
              'updateReason error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.update') }
              )
            )(err)
          } else {
            this.setValidationMessages(this.validationMessages, err.responseJSON)
          }
        })
    },

    handleSubmit () {
      trimValues(this.formData)

      if (this.hasErrors()) return

      if (this.selectedMode === MODES.NEW) {
        this.createReason(this.formData)
      } else {
        this.updateReason(this.formData)
      }
    },
  },
}
</script>
