import { VueCompanyTypesEntryPoint } from '@/vue_components/catalogs/company_types'

$document.on('rez/company_types/new rez/company_types/edit', function () {
  const form = FormFactory.build('companyType')
  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      break
    case 'edit':
      form.editItem()
      break
  }
})

$document.on('rez/company_types', VueCompanyTypesEntryPoint)
