import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

const getHeaders = (isDentalBranch = false) => {
  const stateIcon = gon.specific.entryServicesStatusEnabled || gon.specific.entryAnalysesStatusEnabled
    ? new MTableHeader().addFixedWidth('30px')
    : undefined
  const date = new MTableHeader().addFixedWidth('100px')
  const teethNumbers = new MTableHeader().addFixedWidth('60px')
  const amount = new MTableHeader().addFixedWidth('60px')
  const title = new MTableHeader()

  return isDentalBranch
    ? { date, teethNumbers, amount, title, stateIcon }
    : { date, amount, title, stateIcon }
}

export const getListSchema = (isDentalBranch = false) => new MSiSchema()
  .addSearch()
  .addPagination()
  .addTableSchema(
    new MTableSchema({ headers: getHeaders(isDentalBranch) })
      .addDeleteIcon()
      .addEditIcon()
  )
