import { isUndefined, omit, omitBy } from 'lodash'
import {
  IReferralsReportFiltersGet as FiltersGet,
  IReferralsReportFiltersSet as FiltersSet,
} from '@/specific/reports/referrals/interfaces'
import {
  IReferralsReportFiltersProfileFiltersToClient as IFiltersToClient,
  IReferralsReportFiltersProfileFiltersToServer as IFiltersToServer,
} from '../../interfaces/filtersTypes/referralsReport'
import { IFiltersProfilesFetchResponse } from '../../interfaces/IFIltersProfilesFetchResponse'
import { cleanUnpermittedValues } from '../../helpers/formatters/shared'
import { IFiltersProfilesModel } from '../../interfaces/IFiltersProfilesModel'
import { FiltersProfilesLegacyFormattersToServer as FormattersToServer } from '../../helpers/formatters/legacy/toServer'
import { FiltersProfilesLegacyFormattersToClient as FormattersToClient } from '../../helpers/formatters/legacy/toClient'
import { gatherErrors } from '../../helpers/gatherErrors'

export const referralsReportFiltersProfileAdapter = {
  toServer (data: IFiltersProfilesModel<FiltersGet>): IFiltersProfilesModel<IFiltersToServer> {
    const { data: filters } = data

    const adaptedFilters: IFiltersToServer = {
      ...omit(filters, ['entry', 'medicalUser', 'city', 'entryWithOrder', 'includingDiscounts', 'reportTypeFilter']),
      period: FormattersToServer.formatPeriod(filters.period),
      clinic: FormattersToServer.formatSingle(filters.clinic),
      includeEntryWithoutOrder: filters.entryWithOrder === 'false',
      includeDiscounts: filters.includingDiscounts === 'true',
      reportType: filters.reportTypeFilter,
      groupByEntry: FormattersToServer.formatCheckbox(filters.groupByEntry),
      entryType: FormattersToServer.formatSingle(filters.entry),
      category: FormattersToServer.formatSingle(filters.category),
      doctor: FormattersToServer.formatHuman(filters.medicalUser, false),
      address: FormattersToServer.formatSingle(filters.city, false),
      referral: FormattersToServer.formatHuman(filters.referral),
    }

    return { ...data, data: adaptedFilters }
  },

  toClient (data: IFiltersProfilesFetchResponse<IFiltersToClient>): IFiltersProfilesModel<FiltersSet> {
    const { data: filters } = data

    const validFilters = omitBy(cleanUnpermittedValues(filters), isUndefined) as IFiltersToClient

    const adaptedFilters: FiltersSet = {
      period: FormattersToClient.formatPeriod(validFilters.period),
      clinic: FormattersToClient.formatSingle(validFilters.clinic),
      entryWithOrder: (!validFilters.includeEntryWithoutOrder).toString(),
      includingDiscounts: validFilters.includeDiscounts.toString(),
      reportTypeFilter: validFilters.reportType,
      groupByEntry: FormattersToClient.formatCheckbox(validFilters.groupByEntry),
      entry: FormattersToClient.formatSingle(validFilters.entryType),
      category: FormattersToClient.formatSingle(validFilters.category),
      medicalUser: FormattersToClient.formatHuman(validFilters.doctor),
      city: FormattersToClient.formatSingle(validFilters.address),
      referral: FormattersToClient.formatHuman(validFilters.referral),
    }

    const definedAdaptedFilters = omitBy(adaptedFilters, isUndefined) as FiltersSet

    const renamedFilters = omitBy({
      ...omit(filters, 'entryType', 'doctor', 'address', 'reportType', 'includeEntryWithoutOrder', 'includeDiscounts'),
      entry: filters.entryType,
      city: filters.address,
      medicalUser: filters.doctor,
      entryWithOrder: filters.includeEntryWithoutOrder,
      includingDiscounts: filters.includeDiscounts,
      reportTypeFilter: filters.reportType,
    }, isUndefined)

    return {
      ...data,
      data: definedAdaptedFilters,
      dataErrors: gatherErrors(renamedFilters),
    }
  },
}
