<template>
  <m-panel
    class="client-feedbacks-module"
    :title="t('feedback.feedbacks')"
    icon="star"
    is-parent-control
    :use-print-button="$route.name === FEEDBACKS_ROUTE_NAMES.EMPLOYEES_RATING"
  >
    <m-buttons-group
      :value="currentRoute"
      :items="navRoutes"
      :m-fixed-height="false"
      radio
      @change="changePage"
    />

    <router-view />
  </m-panel>
</template>

<script>
import { navRoutes } from '@/vue_components/client_feedbacks/router/navRoutes'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { mapActions } from 'vuex'
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'
import { FEEDBACKS_ROUTE_NAMES } from '@/vue_components/client_feedbacks/router/routeNames'

export default {
  name: 'ClientFeedbacksModule',
  components: { MButtonsGroup, MPanel },

  data () {
    return {
      navRoutes: navRoutes(),
      isMenuCollapsed: true,
      FEEDBACKS_ROUTE_NAMES,
    }
  },

  computed: {
    currentRoute () {
      return this.$route.name
    },
  },

  mounted () {
    this.RUN_FEEDBACK_UPDATE_SERVICE()
  },

  methods: {
    ...mapActions({
      RUN_FEEDBACK_UPDATE_SERVICE: 'RUN_FEEDBACK_UPDATE_SERVICE',
    }),

    changePage (page) {
      this.$router.push({ name: page.id })
    },
  },
}
</script>
