import { ProtocolsListItem } from '@/vue_apps/MedicalRecords/EntryProtocols/entities/ProtocolsListItem'

/**
 * @typedef {{
 *   id: number,
 *   title: string,
 *   doctorShortName: string
 * }} ProtocolShowDTO
 */

export const protocolListAdapter = {
  /**
   * @param {{
   *     data: Array<ProtocolShowDTO>,
   *     totalItems: number,
   *     totalPages: number,
   * }} response
   * @return {{
   *     data: Array<ProtocolShowDTO>,
   *     totalItems: number,
   *     totalPages: number,
   * }}
   */
  toClient (response) {
    return {
      data: (response.data || []).map((protocol) => new ProtocolsListItem(protocol)),
      totalItems: response.totalItems,
      totalPages: response.totalPages,
    }
  },
}
