<template>
  <textarea ref="textarea" />
</template>

<script>
export default {
  name: 'MediumEditor',
  props: {
    content: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  data () {
    return {
      /**
       * @type {MediumEditor}
       */
      editor: null,
      value: '',
    }
  },
  computed: {
    editorElement () {
      return this.editor && this.editor.elements[0]
    },
  },
  watch: {
    content (content) {
      if (content !== this.value) {
        this.setContent(content)
      }
    },
    disabled: 'setDisabled',
  },
  mounted () {
    if (this.editor) {
      this.editor.setup()
    } else {
      this.editor = new MediumEditor(this.$refs.textarea, {
        toolbar: {
          buttons: ['bold', 'italic', 'underline'],
        },
        placeholder: {
          text: t('write_important_information_here') +
            t('about_client_improve_service'),
          hideOnClick: false,
        },
        disableEditing: this.disabled,
      })
    }
    this.editor.subscribe('editableInput', this.onInputChange)
    this.setContent(this.content)
    this.setDisabled(this.disabled)
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  methods: {
    onInputChange () {
      this.value = this.getContent()
      this.$emit('update:content', this.value)
    },
    getContent () {
      return this.editor.getContent()
    },
    setContent (content) {
      this.editor.setContent(content)
      this.value = content
    },
    /**
     * @param {boolean} disabled
     */
    setDisabled (disabled) {
      if (this.editorElement) {
        this.editorElement.contentEditable = !disabled
        if (disabled) {
          this.editorElement.setAttribute('readonly', 'readonly')
        } else {
          this.editorElement.removeAttribute('readonly')
        }
      }
    },
  },
}
</script>
