import Vue from 'vue/dist/vue.esm'
import SiGeneratorExample from '@/vue_components/sort_items/SiGenerator/SiGeneratorExample'

export const createSiGeneratedVue = (el) => {
  if (!document.querySelector(el)) { return }

  // eslint-disable-next-line no-new
  new Vue({
    el,
    name: 'SiGeneratorExample',
    render: (h) => h(SiGeneratorExample),
  })
}
