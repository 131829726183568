import { ReportParamFactory } from '@/specific/reports/shared'

export default function (source) {
  return {
    clinicIds: ReportParamFactory.buildArrayParam(source.clinic),
    entryKinds: ReportParamFactory.buildArrayParam(source.reportKind),
    machineIds: ReportParamFactory.buildArrayParam(source.machines),
    doctorIds: ReportParamFactory.buildArrayParam(source.user),
    analysisLaboratoryIds: ReportParamFactory.buildArrayParam(source.laboratory),
    assistantIds: ReportParamFactory.buildArrayParam(source.assistant),
    entryTypeCategoryIds: ReportParamFactory.buildArrayParam(source.reportCategory),
  }
}
