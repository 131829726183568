<template>
  <panel-heading
    icon="fad fa-fw fa-atlas"
    :show-tooltip="true"
    :title="t('support_nav')"
  >
    <div
      v-loading="isLoading"
      class="tabs-nav hidden-print"
    >
      <div
        v-for="(tab, index) in listItems"
        :key="index"
        :class="[
          'tab-nav',
          { 'is-active': listItemIsActive(tab) },
          { 'is-root': !tab.isChild},
          { 'is-child': tab.isChild}
        ]"
        @click.stop="redirect(tab)"
      >
        <i
          class="tab-icon"
          :class="tab.icon"
        />

        <span class="tab-text">
          {{ tab.text }}
        </span>
      </div>
    </div>
  </panel-heading>
</template>

<script>
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import {
  CANNY_CHANGELOG,
  CANNY_FEEDBACK,
  CANNY_FEEDBACK_WITH_BOARD,
  SIMPLE_IMPORT_VALIDATOR,
  SUPPORT_EDUCATION,
} from '@/vue_components/router/modules/support/route_names.js'

export default {
  name: 'SupportNavigation',
  components: {
    PanelHeading,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },

    boardsArray: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    listItems () {
      const boards = this.boardsArray.map((item) => {
        return {
          name: CANNY_FEEDBACK_WITH_BOARD,
          text: item.name,
          icon: 'fad fa-fw fa-clipboard',
          route: CANNY_FEEDBACK_WITH_BOARD,
          isChild: true,
          isBoard: true,
          isDownloadable: true,
          params: {
            board: item.boardUrl,
            token: item.token,
          },
        }
      })

      return [

        {
          name: SUPPORT_EDUCATION,
          text: t('support.education'),
          icon: 'fad fa-fw fa-book-open',
          route: SUPPORT_EDUCATION,
          isDownloadable: true,
        },

        {
          name: CANNY_FEEDBACK,
          text: t('support.feedback'),
          icon: 'fad fa-fw fa-comments-alt',
          route: CANNY_FEEDBACK,
          isDownloadable: true,
        },

        ...boards,

        {
          name: CANNY_CHANGELOG,
          text: t('support.changelog'),
          icon: 'fad fa-fw fa-clipboard-list-check',
          route: CANNY_CHANGELOG,
          isDownloadable: true,
        },

        {
          name: SIMPLE_IMPORT_VALIDATOR,
          text: t('support.simple_import'),
          icon: 'fad fa-fw fa-file-import',
          route: SIMPLE_IMPORT_VALIDATOR,
        },
      ]
    },
  },

  methods: {
    listItemIsActive (tab) {
      let isActive = false
      if (!tab.isBoard) {
        isActive = tab.route === this.$route.name
      } else {
        isActive = tab.params.board === this.$route.params.boardId
      }

      if (isActive) {
        this.$emit('updateBodyTitle', { text: tab.text, icon: tab.icon })
      }

      return isActive
    },

    redirect (tab) {
      if (this.listItemIsActive(tab)) return

      if (tab.isBoard) {
        this.$router.push({
          name: tab.route,
          params: {
            boardId: tab.params.board,
          },
        })

        this.$emit('updateCannyBoardToken', tab.params.token)

        return
      }

      this.$router.push({ name: tab.route })
    },
  },
}
</script>
