import { VueEgiszEntryPoint } from '@/vue_components/egisz/egisz_module'

const events = [
  'rez/egisz/index',
  'rez/egisz/application/index',
  'rez/egisz/clients/index',
  'rez/egisz/medical_cases/index',
  'rez/egisz/medical_cases/new',
  'rez/egisz/medical_cases/edit',
  'rez/egisz/medical_records/medical_documents/index',
  'rez/egisz/medical_records/medical_documents/new',
  'rez/egisz/medical_records/medical_documents/edit',
].join(' ')

$document.on(events, VueEgiszEntryPoint)
