<template>
  <si class="w-100">
    <!-- Фильтры -->
    <appointment-call-log-filters
      :doctor-schedules-clinic-id="doctorSchedulesClinicId"
      :loading="loading"
      @filtersChanged="onFilterChanged"
    />

    <!-- Данные -->
    <si-table
      v-if="hasAppointments"
      v-loading="loading"
      class="w-100"
      :class="{
        'si-table-hide-clinicTitle': isClinicSelected
      }"
    >
      <!-- Таблица: Шапка -->
      <template #header>
        <appointment-call-log-table-header />
      </template>

      <!-- Таблица: Данные -->
      <template #body>
        <appointment-call-log-row
          v-for="appointment in appointments"
          :key="`appointment:${appointment.id}`"
          :appointment="appointment"
          :appointment-statuses="appointmentStatuses"
          :appointment-call-confirmation-statuses="appointmentCallConfirmationStatuses"

          @findAppointment="$emit('findAppointment', $event)"
          @editAppointment="$emit('editAppointment', $event)"

          @openCallConfirmation="openPopupCrutch('callConfirmation', appointment, $event)"
          @openNote="openPopupCrutch('note', appointment, $event)"
          @openEntryTypes="openPopupCrutch('entryTypes', appointment, $event)"
        />
      </template>
    </si-table>
    <not-found-result
      v-else
      :filtered="vxGetIsFiltered"
      @reset-filters="vxResetFilters(defaultFilters)"
    />

    <appointment-call-log-popup
      :visible="popup.visibility"
      :position="popup.position"
      :source="popup.source"
      @close="closePopupCrutch"
    >
      <appointment-call-log-popup-note
        v-if="popup.source === 'note'"
        :note="popup.appointment.note"
        @update:note="vxUpdateAppointmentNote({ id: popup.appointment.id, note: $event}); closePopupCrutch()"
      />

      <appointment-call-log-popup-call-confirmation
        v-if="popup.source === 'callConfirmation'"
        :call-status="popup.appointment.callConfirmationStatus"
        :appointment-call-confirmation-statuses="appointmentCallConfirmationStatuses"
        @setCallStatus="vxUpdateCallConfirmationStatus({ appointmentId: popup.appointment.id, callConfirmationStatus: $event }); closePopupCrutch()"
      />

      <appointment-call-log-popup-entry-types
        v-if="popup.source === 'entryTypes'"
        :entry-types="popup.appointment.entryTypes"
      />
    </appointment-call-log-popup>
  </si>
</template>

<script>
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AppointmentCallLogFilters from '@/vue_apps/DoctorSchedules/AppointmentCallLog/AppointmentCallLogFilters.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import Si from '@/vue_components/sort_items/si.vue'
import {
  getAppointmentStatuses,
  getCallConfirmationStatuses,
  getFilters,
} from '@/vue_apps/DoctorSchedules/AppointmentCallLog/store/filters'
import AppointmentCallLogPopup
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogPopup.vue'
import AppointmentCallLogTableHeader
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogTableHeader.vue'
import AppointmentCallLogRow
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogRow.vue'
import AppointmentCallLogPopupNote
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogPopup/AppointmentCallLogPopupNote.vue'
import AppointmentCallLogPopupCallConfirmation
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogPopup/AppointmentCallLogPopupCallConfirmation.vue'
import AppointmentCallLogPopupEntryTypes
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogPopup/AppointmentCallLogPopupEntryTypes.vue'
import NotFoundResult from '@/vue_components/common/not_found_result.vue'

export default {
  name: 'AppointmentCallLog',
  components: {
    NotFoundResult,
    AppointmentCallLogPopupEntryTypes,
    AppointmentCallLogPopupCallConfirmation,
    AppointmentCallLogPopupNote,
    AppointmentCallLogRow,
    AppointmentCallLogTableHeader,
    AppointmentCallLogPopup,
    Si,
    SiTable,
    AppointmentCallLogFilters,
  },
  mixins: [SpinnerHolder],

  props: {
    doctorSchedulesClinicId: { type: Number, required: true },
  },

  data () {
    return {
      /** @type {TCallLogStatus[]} */
      appointmentStatuses: getAppointmentStatuses(),

      /** @type {TCallLogStatus[]} */
      appointmentCallConfirmationStatuses: getCallConfirmationStatuses(),

      /** @type {TAppointmentCallLogPopup} */
      popup: {
        visibility: false,
        source: null,
        appointment: null,
        position: null,
      },

      defaultFilters: getFilters(), // этого не должно быть здесь, но оптимизация
    }
  },

  computed: {
    ...mapGetters('appointmentCallLogStore', {
      vxGetAppointments: 'vxGetAppointments',
    }),

    ...mapGetters('appointmentCallLogStore/filtersBase', {
      vxFilters: 'vxGetFilters',
      vxGetIsFiltered: 'vxGetIsFiltered',
    }),

    /** @return {callLogAppointment[]} */
    appointments () {
      return this.vxGetAppointments
    },

    hasAppointments () {
      return this.appointments?.length
    },

    isClinicSelected () {
      return this.vxFilters.clinic
    },
  },

  created () {
    this.subscribeToEvents()
  },

  methods: {
    ...mapActions('appointmentCallLogStore', {
      vxFetchAppointments: 'vxFetchAppointments',
      vxUpdateCallConfirmationStatus: 'vxUpdateCallConfirmationStatus',
      vxUpdateAppointmentNote: 'vxUpdateAppointmentNote',
    }),

    ...mapMutations('appointmentCallLogStore/filtersBase', {
      vxResetFilters: 'vxResetFilters',
    }),

    ...mapMutations('appointmentCallLogStore', {
      vxSetAppointmentDataByAppointmentId: 'vxSetAppointmentDataByAppointmentId',
    }),

    __onAppointmentUpdate (updatedAppointment) {
      this.vxSetAppointmentDataByAppointmentId(updatedAppointment)
    },

    subscribeToEvents () {
      this.$pubSub.subscribe('DOCTOR_SCHEDULES.APPOINTMENTS.UPDATED', (data) => this.__onAppointmentUpdate(data))
    },

    async onFilterChanged (filters) {
      await this.withSpinner(this.vxFetchAppointments(filters), undefined, false)
      Services.telephony.reset()
    },

    /**
     * @param {TAppointmentCallLogPopupSource} source
     * @param {callLogAppointment} appointment
     * @param {PointerEvent} event
     */
    openPopupCrutch (source, appointment, event) {
      this.popup.source = source
      this.popup.appointment = appointment
      this.popup.position = { x: event.clientX, y: event.clientY }
      this.popup.visibility = true
    },

    closePopupCrutch () {
      this.popup.visibility = false
      this.$nextTick(() => {
        this.popup.source = null
        this.popup.appointment = null
      })
    },
  },
}
</script>
