<template>
  <div v-if="GET_APP_CONF_ASSIGN_BONUS_POINTS">
    <button
      class="btn btn-default btn-xs btn-with-icon hidden-print"
      @click="visibility = true"
    >
      <span class="fad fa-tags" />
      <span class="btn-with-icon_text">{{ t('discount_system.edit_count_points') }}</span>
    </button>
    <modal
      :modal-visibility="visibility"
      @close="closeForm"
    >
      <template #header>
        <span class="fad fa-tags" />
        {{ t('discount_system.manual_edit_points') }}
      </template>

      <template #body>
        <epic-spinner :visibility="loading" />
        <div class="add-bonus-form">
          <div class="flex-form">
            <div class="form-label">
              <label>
                {{ t('discount_system.points') }}
              </label>
            </div>
            <div class="form-value">
              {{ bonusPoints }}
            </div>
            <div class="form-label" />
            <div class="form-value">
              <validation-wrapper :errors="validationMessages.type">
                <items-list
                  v-model="form.type"
                  id-item-field="id"
                  displayed-item-field="title"
                  :items="types"
                  :min-selected-items="1"
                  :multi-select="false"
                  :selected-items-ids="form.type"
                  :disabled="loading"
                />
              </validation-wrapper>
            </div>
            <div class="form-label">
              {{ t('discount_system.count_points') }}
              <abbr :title="t('activerecord.required.text')">
                *
              </abbr>
            </div>
            <div class="form-value">
              <validation-wrapper :errors="validationMessages.value">
                <el-input
                  v-model="form.value"
                  type="number"
                  min="0"
                  step="1"
                />
              </validation-wrapper>
            </div>
            <div class="form-label">
              {{ t('comment') }}
              <abbr
                v-if="GET_APP_CONF_COMMENT_FOR_ASSIGN_BONUS_POINTS_REQUIRED"
                :title="t('activerecord.required.text')"
              >
                *
              </abbr>
            </div>
            <div class="form-value">
              <validation-wrapper :errors="validationMessages.comment">
                <el-input
                  v-model="form.comment"
                  class="bonus-comment"
                  type="textarea"
                  rows="2"
                />
              </validation-wrapper>
            </div>
          </div>
        </div>
      </template>

      <template #footer-right>
        <button
          :disabled="disabledSubmit || loading"
          class="btn btn-success btn-with-icon modal-save"
          type="button"
          @click="submitForm"
        >
          <i class="btn-with-icon_icon fad fa-save" />
          <span class="btn-with-icon_text">
            {{ t('save') }}
          </span>
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary btn-with-icon modal-close"
          type="button"
          @click="closeForm"
        >
          <i class="btn-with-icon_icon fad fa-times" />
          <span class="btn-with-icon_text">
            {{ t('close') }}
          </span>
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import ItemsList from '@/vue_components/items_list.vue'
import Modal from '@/vue_components/modal.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'

import { assignBonusPoints, getCurrentBonusPoints } from './rest.js'
import { mapGetters } from 'vuex'

const TYPES_ASSIGN_BONUS = {
  SUBSTRACT: 'sub',
  ADD: 'add',
}

const DEFAULT_TYPE_ASSIGN_BONUS = TYPES_ASSIGN_BONUS.ADD

export default {
  name: 'BonusPointsModal',
  components: {
    EpicSpinner,
    ItemsList,
    Modal,
    ValidationWrapper,
  },

  props: {
    clientId: {
      type: Number,
      default: null,
    },
  },

  data () {
    return {
      visibility: false,
      loading: false,
      disabled: true,
      bonusPoints: 0,
      types: [
        { id: TYPES_ASSIGN_BONUS.ADD, title: t('discount_system.replenish') },
        { id: TYPES_ASSIGN_BONUS.SUBSTRACT, title: t('discount_system.chulk') },
      ],
      form: { type: [DEFAULT_TYPE_ASSIGN_BONUS], value: '', comment: '' },
      validationMessages: { type: [], value: [], comment: [] },
    }
  },

  computed: {
    ...mapGetters([
      'GET_APP_CONF_ASSIGN_BONUS_POINTS',
      'GET_APP_CONF_COMMENT_FOR_ASSIGN_BONUS_POINTS_REQUIRED',
    ]),

    disabledSubmit () {
      return Object.values(this.validationMessages).some((el) => el.length > 0) || this.disabled
    },

    bonusAssignType () {
      return this.form.type[0]
    },

    isBonusLessCurrentValue () {
      return this.bonusAssignType === TYPES_ASSIGN_BONUS.SUBSTRACT &&
        parseFloat(this.form.value) > this.bonusPoints
    },

    reqParamsPoints () {
      return {
        id: this.clientId,
        type: this.form.type,
        value: this.form.value,
        comment: this.form.comment,
      }
    },
  },

  watch: {
    visibility () {
      if (this.visibility) {
        this.getCurrentBonusPoints()
      }
    },

    'form.value' () {
      this.disabled = false
      this.validationMessages.value = []

      if (!this.form.value) {
        this.validationMessages.value.push(t('activerecord.required.text'))
      }

      if (parseFloat(this.form.value) < 1) {
        this.validationMessages.value.push(t('vaidation_num_cannot_less_zero'))
      }

      if (this.isBonusLessCurrentValue) {
        this.validationMessages.value.push(t('discount_system.points_sub_less_current'))
      }
    },

    bonusAssignType () {
      this.disabled = false
      this.validationMessages.value = []

      if (this.isBonusLessCurrentValue) {
        this.validationMessages.value.push(t('discount_system.points_sub_less_current'))
      }
    },

    'form.comment' () {
      this.disabled = false
      this.validationMessages.comment = []

      if (this.GET_APP_CONF_COMMENT_FOR_ASSIGN_BONUS_POINTS_REQUIRED) {
        if (this.form.comment.length === 0) {
          this.validationMessages.comment.push(t('activerecord.required.text'))
        }
      }
    },
  },

  methods: {
    getCurrentBonusPoints () {
      this.loading = true
      getCurrentBonusPoints({ id: this.clientId })
        .then((response) => { this.bonusPoints = response })
        .finally(() => {
          this.loading = false
        })
    },

    closeForm () {
      this.visibility = false
      this.form = { type: [DEFAULT_TYPE_ASSIGN_BONUS], value: '', comment: '' }
      this.validationMessages = { type: [], value: [], comment: [] }
    },

    submitForm () {
      this.loading = true
      this.disabled = true
      assignBonusPoints(this.reqParamsPoints)
        .then((response) => {
          this.$pubSub.emitAsync('CLIENT_BONUS_POINTS.SET_VALUE', response)
          this.closeForm()
        })
        .catch((response) => {
          const errors = response.responseJSON

          this.validationMessages.type = errors.type || []
          this.validationMessages.value = errors.value || []
          this.validationMessages.comment = errors.comment || []
        })
        .finally(() => {
          this.loading = false
          this.disabled = false
        })
    },
  },
}
</script>
