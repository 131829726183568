import {
  SELECT_ALL_SPECIALTIES_ID,
  SELECT_ALL_CABINETS_ID,
  SELECT_ALL_USERS_ID, WITHOUT_CABINET_ID,
} from '../const.js'

export default {
  _setVisibleUsers () {
    if (this.selectedSpecialtiesIds.includes(SELECT_ALL_SPECIALTIES_ID)) {
      this.visibleUsers = this._users.slice()
    } else {
      const newVisibleUsers = Object.create(null)

      this.selectedSpecialtiesIds.forEach((sid) => {
        this.allUIDsInSIDs[sid].map((uid) => this.usersMap[uid]).forEach((user) => {
          newVisibleUsers[user.id] = user
        })
      })

      this.visibleUsers = Object
        .values(newVisibleUsers)
        .sort((u1, u2) => Utils.stringsCompare(u1.surname, u2.surname))
    }
  },

  _setVisibleCabinets (cabinetIds = []) {
    this.visibleCabinets = this._cabinets.filter((cabinet) => {
      // убрать после фикса в айдишников кабинетов в work time сервисе
      return cabinet.id === WITHOUT_CABINET_ID || cabinetIds.includes(cabinet.id)
    })
  },

  _setSelectedVisibleUsersIds () {
    if (this.selectedUsersIds.includes(SELECT_ALL_USERS_ID)) {
      this.selectedVisibleUsersIds = this.visibleUsers.map((user) => user.id)
    } else {
      this.selectedVisibleUsersIds = this.visibleUsers
        .filter((user) => this.selectedUsersIds.includes(user.id))
        .map((user) => user.id)
    }
  },

  _setSelectedVisibleCabinetsIds () {
    if (this.selectedCabinetsIds.includes(SELECT_ALL_CABINETS_ID)) {
      this.selectedVisibleCabinetsIds = this.visibleCabinets.map((cabinet) => cabinet.id)
    } else {
      this.selectedVisibleCabinetsIds = this.visibleCabinets
        .filter((cabinet) => this.selectedCabinetsIds.includes(cabinet.id))
        .map((cabinet) => cabinet.id)
    }
  },

  _workTimeUpdatesHandler (packet) {
    let sendRequest = false
    let onChange = false

    if (packet.dates_with_changes.length) {
      packet.dates_with_changes.forEach((data) => {
        if (!sendRequest &&
          this.selectedVisibleUsersIds.includes(data.template.user_id) &&
          window.application.current_clinic.id === data.template.clinic_id) {
          if (data.dates.some((date) => this.dates.includes(date))) {
            sendRequest = true
            this.requestData(true)
          } else {
            const templates = this.employeesScheduleTableData.schedule_templates
            const userId = data.template.user_id
            if (!Array.isArray(templates[userId])) templates[userId] = []
            if (data.deleted) {
              templates[userId] = templates[userId]
                .filter((template) => template.id !== data.template.id)
            } else {
              let updated = false
              for (let i = 0; i < templates[userId].length; i++) {
                if (templates[userId][i].id === data.template.id) {
                  templates[userId][i] = data.template
                  updated = true
                }
              }
              if (!updated) templates[userId].push(data.template)
            }
            onChange = true
          }
        }
      })
    }

    if (!sendRequest && packet.days_with_changes.length) {
      packet.days_with_changes.forEach((data) => {
        if (window.application.current_clinic.id === data.clinic_id &&
          this.selectedVisibleUsersIds.includes(data.user_id) &&
          this.dates.includes(data.date)) {
          sendRequest = true
          this.requestData(true)
        }
      })
    }

    if (!sendRequest && onChange) this._onChange()
  },
}
