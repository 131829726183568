import {
  TREATMENT_PLANS_STATUSES,
  TREATMENT_PLANS_TYPES,
} from '@/vue_apps/TreatmentPlans/TreatmentPlansList/const/const'

export const statusIcons = Object.freeze({
  [TREATMENT_PLANS_STATUSES.DRAFT]: { icon: 'file', css: 'grey-dark' },
  [TREATMENT_PLANS_STATUSES.APPROVES]: { icon: 'approved', css: 'primary' },
  [TREATMENT_PLANS_STATUSES.IN_PROGRESS]: { icon: 'tasks', css: 'primary' },
  [TREATMENT_PLANS_STATUSES.READY]: { icon: 'ready', css: 'success' },
  [TREATMENT_PLANS_STATUSES.CANCELED]: { icon: 'canceled', css: 'danger' },
})

export const typeIcons = Object.freeze({
  [TREATMENT_PLANS_TYPES.MEDICAL]: { icon: 'briefcase', css: 'grey-dark' },
  [TREATMENT_PLANS_TYPES.DENTAL]: { icon: 'tooth', css: 'grey-dark' },
})
