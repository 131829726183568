import WSSubscriptionManager from '@/services/ws_subscription_manager.js'
import pubSub from '@/services/epic_pub_sub.js'
import { ANALYSIS_CHANNEL, ANALYSIS_ORDER_PUBSUB_PREFIX, EVENTS } from './constants'

const WAIT_TIMEOUT = 100

class WebSocketsHandler {
  constructor ({
    channel, pubSubPrefix, messageFormatter,
  } = {}) {
    this.channel = channel || ANALYSIS_CHANNEL
    this.pubSubPrefix = pubSubPrefix || ANALYSIS_ORDER_PUBSUB_PREFIX
    this.messageFormatter = messageFormatter || this._baseMessage
    this.subscription = null
    this.connected = false
  }

  subscribe ({
    received, connected,
  } = {}) {
    this.subscription = WSSubscriptionManager[this.channel].advancedConnection({
      received: received || this._receiveMessage.bind(this),
      connected: connected || this._wsConnected.bind(this),
    })
  }

  // TODO сделать из этого Promise
  sendMessage (event, params = {}) {
    if (this.connected) {
      params.clinicId = gon.application.current_clinic.id

      this.subscription.send(this.messageFormatter(event, params))
    } else {
      setTimeout(this.sendMessage.bind(this), WAIT_TIMEOUT, event, params)
    }
  }

  _receiveMessage (msg) {
    pubSub.emitAsync(`${this.pubSubPrefix}.${msg.meta.event}`, msg.data)
  }

  _wsConnected () {
    this.connected = true
    pubSub.emitAsync(`${this.pubSubPrefix}.${EVENTS.WEBSOCKETS_CONNECTED}`)
  }

  _baseMessage (event, params = {}) {
    return {
      event,
      ...params,
    }
  }
}

export default WebSocketsHandler
