<template>
  <div class="popup-note flex flex-column gap-indent">
    <div class="popup-note__textarea position-relative">
      <m-textarea
        v-model="vmNote"
        :autosize="{ minRows: 3, maxRows: 10 }"
        :m-fixed-height="false"
      />
    </div>

    <div class="popup-note__buttons flex justify-content-end">
      <m-button
        template="short_save_orange"
        @click="$emit('update:note', vmNote)"
      />
    </div>
  </div>
</template>

<script>
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default {
  name: 'AppointmentCallLogPopupNote',
  components: { MButton, MTextarea },
  props: {
    note: PropsTypes.String(),
  },

  data () {
    return {
      vmNote: this.note,
    }
  },

  watch: {
    note (to) {
      this.vmNote = to
    },
  },
}
</script>
