<template>
  <m-si-generator
    ref="attachmentsFilesTable"
    class="attachments-tab-files"
    :si-generator-schema="schema"
    :items="list.data"
    :page-count="list.totalPages"
    :current-page="list.currentPage"
    @update:currentPage="onSetPage"
    @onItemDelete="onDeleteClick"
    @onCellClick="onCellClick"
  >
    <template #add>
      <el-upload
        :action="$routes.create_client_attachment_path()"
        :http-request="onFileUpload"
        :show-file-list="false"
      >
        <m-button
          template="add"
          :icon="addButtonConfig.icon"
          :text="addButtonConfig.text"
        />
      </el-upload>
    </template>
  </m-si-generator>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import {
  AttachmentsList,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/api/AttachmentsList'
import { getListSchema } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/getListSchema'
import { GLOBAL_DEBOUNCE_DEFAULT, GLOBAL_DOUBLE_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import {
  AttachmentsListItem,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/api/AttachmentsListItem'

export default defineComponent({
  name: 'AttachmentsTabFiles',

  components: {
    MSiGenerator,
    MButton,
  },

  props: {
    clientId: { type: Number, required: true },
  },

  emits: ['updateFilesCount'],

  data () {
    return {
      list: new AttachmentsList(this.clientId),
      schema: getListSchema(),
    }
  },

  computed: {
    addButtonConfig () {
      return {
        icon: 'attachment',
        text: t('attach_file'),
        hidePlusIcon: true,
      }
    },
  },

  created () {
    this.$pubSub.subscribe('updateFilesList', () => this.fetchAll())
  },

  mounted () {
    setTimeout(() => {
      this.fetchAll()
    }, GLOBAL_DEBOUNCE_DEFAULT)
  },

  methods: {
    async onDeleteClick ({ id }) {
      await this.list.delete(id)
      await this.fetchAll()
    },

    async onFileUpload ({ file }) {
      const response = await this.list.upload(this.clientId, file)
      if (response.errors) { return }

      setTimeout(() => {
        this.fetchAll()
      }, GLOBAL_DOUBLE_DEBOUNCE_DEFAULT)
    },

    async onCellClick ({ cell, item, event }: { cell: string; item: AttachmentsListItem; event: Event }) {
      event.preventDefault()
      event.stopPropagation()
      if (cell === 'print') { await this.list.print(item.id, item.printJSMimeType) }
    },

    async fetchAll () {
      await this.list.fetchAll()
      this.$emit('updateFilesCount')
      this.subscribeForPreview()
    },

    async onSetPage (page: number) {
      await this.list.fetchPage(page)
      this.subscribeForPreview()
    },

    subscribeForPreview () {
      const $rows = $(this.$refs.attachmentsFilesTable.$el).find('.m-table-row')
      $rows.each((index, currentTarget) => {
        const dataSet = currentTarget.dataset
        $(currentTarget).attachmentPreview({
          type: dataSet.mimeType,
          fileType: dataSet.mimeType,
          headerText: dataSet.filename,
        })
      })
    },
  },
})
</script>
