import { DateHelpers } from 'src/helpers/DateHelpers'
import { _getDoctorAppointmentsReportFilters as getFilters } from './_filtersGetter'
import { IDoctorAppointmentsReportFiltersGet, IDoctorAppointmentsReportFiltersSet } from './interfaces'

let initialFilters: IDoctorAppointmentsReportFiltersGet = null

export const saveDoctorAppointmentsReportInitialFilters = (): void => {
  initialFilters = getFilters()
}

export const getDoctorAppointmentsReportInitialFilters = (): IDoctorAppointmentsReportFiltersSet => ({
  ...initialFilters,
  period: DateHelpers.getCurrentMonthPeriod(),
})
