import { Promobot } from '@/helpers/Promobot/Promobot'
import { onPromobotDataScanned } from '@/helpers/Promobot/clientLegacyHook/clientLegacyHookLogic'

/**
 * Я 🩼 который нужен исключительно для старой формы инфокарты
 * TODO: удалить
 */
export const clientLegacyPromobotHook = () => {
  const promobot = new Promobot()
  promobot.reSubscribe()

  window.PubSub.on(
    'promobotDataScanned',
    (target, data) => { onPromobotDataScanned(target, data) }
  )
}
