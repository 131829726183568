<template>
  <modal
    :modal-class="'select-vacation-modal'"
    :modal-visibility="showSelectVacationModal"
    @close="$pubSub.emit('EMPLOYEES_SCHEDULE.HIDE_SELECT_VACATION_MODAL')"
  >
    <span slot="header">{{ T.select_vacation_range }}</span>
    <ul
      slot="body"
      class="vacations-list"
    >
      <li
        v-for="vacation in vacations"
        :key="vacation.id"
        :data-id="vacation.id"
        class="vacation-item"
        @click="vacationOnClick(vacation.id)"
      >
        <span class="modal-time">{{ formatDate(vacation.start) }}</span>
        <span> — </span>
        <span class="modal-time">{{ formatDate(vacation.end) }}</span>
      </li>
    </ul>
    <button
      slot="footer-right"
      class="btn btn-sm btn-primary btn-with-icon modal-close"
      @click="$pubSub.emit('EMPLOYEES_SCHEDULE.HIDE_SELECT_VACATION_MODAL')"
    >
      <span class="btn-with-icon_icon fad fa-times" />
      <span class="btn-with-icon_text">{{ T.close }}</span>
    </button>
  </modal>
</template>

<script>
import Modal from '../../../modal.vue'
import { formatDate } from '../../utils.js'

export default {
  name: 'SelectVacationModal',
  components: {
    Modal,
  },
  props: {
    showSelectVacationModal: { type: Boolean, required: true },
    vacations: { type: Array, required: true },
    userId: { type: Number, required: true },
    date: { type: String, required: true },
  },
  methods: {
    formatDate,
    vacationOnClick (vacationId) {
      this.$pubSub.emit(
        'EMPLOYEES_SCHEDULE.SHOW_REMOVE_VACATION_MODAL',
        { userId: this.userId, vacationId, date: this.date },
      )
    },
  },
}
</script>
