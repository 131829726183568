import { LegacyFormSetters } from '@/helpers/LegacyFormSetters'
import { insuranceCompaniesReportFiltersSelectors as selectors } from './filtersSelectors'
import { IInsuranceCompaniesReportFiltersSet } from './interfaces'
import { _getInsuranceCompaniesReportFilters } from './_filtersGetter'
import { getInsuranceCompaniesReportInitialFilters as getInitialFilters } from './initialFilters'

export const insuranceCompaniesReportFiltersAccessors = {
  get: _getInsuranceCompaniesReportFilters,

  set (filtersToSet: IInsuranceCompaniesReportFiltersSet): void {
    const filters = { ...getInitialFilters(), ...filtersToSet }

    LegacyFormSetters.setSelectValue(selectors.clinic, filters.clinic.id)
    LegacyFormSetters.setDateRangePickerValue(selectors.period, filters.period)
    LegacyFormSetters.setSelect2Value(selectors.insuranceCompany, filters.insuranceCompany.id)
    LegacyFormSetters.setSelect2Value(selectors.company, filters.company.id)
    LegacyFormSetters.setButtons2Value(selectors.medicalPolicyType, filters.medicalPolicyType.id)
  },
}
