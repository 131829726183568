<template>
  <div id="head-desktop-component">
    <div class="main-selectors">
      <card-selector />
      <global-clinic-selector />
    </div>
    <card-list v-if="showCards" />
  </div>
</template>

<script>
import CardSelector from './components/card-selector.vue'
import CardList from './components/card-list.vue'
import GlobalClinicSelector from './components/selectors/global_clinic_selector.vue'
import { mapState } from 'vuex'

export default {
  components: {
    CardSelector,
    CardList,
    GlobalClinicSelector,
  },

  computed: {
    showCards () {
      return this.clinicId !== null
    },

    ...mapState('headDesktopStore', {
      clinicId: (state) => state.globalClinicSelectorValue,
    }),
  },

  mounted () {
    this.$store.dispatch('headDesktopStore/checkSelectedClinic')
  },
}
</script>
