import SmsEditor from '@/modules/variables_editor.js'
import { SERVICES_STATUS } from '@/modules/sms/editor_presets.js'
import { smsCount } from '@/modules/sms/utils.js'
import { BY_ORDER_TRIGGER_TYPE, BY_ENTRY_TRIGGER_TYPE } from '@/modules/sms/constants.js'

export default function servicesStatus () {
  if (!gon.application.sms_module_enabled) return

  const $form = $('#sms_configuration_form')
  const $submit = $form.find('#sms_settings_submit').eq(0)
  const $editor = $form.find('.services_status_template_editor_container')
  const $checkbox = $form.find('#sms_configuration_services_status')
  const $hideable = $form.find('.hideable-field')
  const $approximateCount = $form.find('.approximate_value')
  const $triggerTypeSelect = $form.find('#sms_configuration_services_status_trigger_type')
  const $symbolCount = $form.find('.symbol_count')

  // const {order, ...byEntryVars} = SERVICES_STATUS.variablesList.variables
  // const byOrderVars = SERVICES_STATUS.variablesList.variables
  const byOrderVars = SERVICES_STATUS.variablesList.variables
  const byEntryVars = SERVICES_STATUS.variablesList.variables.filter((v) => v.source !== 'order')
  const triggerTypeVariablesMap = {
    [BY_ENTRY_TRIGGER_TYPE]: byEntryVars,
    [BY_ORDER_TRIGGER_TYPE]: byOrderVars,
  }

  const currentVarsList = triggerTypeVariablesMap[$triggerTypeSelect.val()]
  if (!currentVarsList) throw new Error('wrong trigger type')

  SERVICES_STATUS.variablesList.variables = currentVarsList

  const editor = new SmsEditor({
    container: $editor,
    onPreviewChanged (text) {
      const count = smsCount(text)
      $approximateCount.text(count)
      $symbolCount.text(text.length)
    },
    validationCallback (value) {
      $submit.prop('disabled', !value)
    },
    ...SERVICES_STATUS,
  })

  editor.visualize()

  $form.remote_form({
    model: 'sms_configuration',
    beforeSend () {
      editor.originalize()
    },
    onSuccess (res) {
      editor.visualize()
      Notificator.info(I18n.t('sms.configuration_updated'))
      $submit.loadSpin('stop')
    },
    onError (err) {
      Notificator.error(T.check_correctness_filing_form)
      console.log(err)
      editor.visualize()
      $submit.loadSpin('stop')
    },
  })

  const toggleFields = () => {
    $hideable.toggleClass('hidden', !$checkbox.prop('checked'))
  }

  $triggerTypeSelect.change((e) => {
    const varsList = triggerTypeVariablesMap[e.target.value]
    if (!varsList) throw new Error('wrong trigger type')

    editor.setList(varsList)
    const valid = editor.validate()
    if (valid) editor.clearPreview()
  })
  toggleFields()
  $checkbox.on('change', toggleFields)
  $submit.on('click', (e) => {
    if (!editor.validate()) e.preventDefault()
  })
}
