<template>
  <m-modal
    class="image-cropper-modal"
    :visible="visible"
    :dialog-title="dialogTitle"
    @update:visible="$updateSync('visible', $event)"
  >
    <template #body>
      <image-cropper
        ref="cropper"
        :img-source="imgSource"
        :aspect-ratio="aspectRatio"
        :preview="preview"
        :rounded="rounded"
        @cropped-image="$emit('cropped-image', $event)"
      />
    </template>

    <template #footer-right-action>
      <slot name="footer-right-action">
        <m-button
          template="cropImage"
          @click="cropImage"
        />
      </slot>
    </template>
  </m-modal>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import ImageCropper from '@/vue_apps/ImageCropper/ImageCropper.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default {
  name: 'ImageCropperModal',

  components: { MButton, ImageCropper, MModal },

  props: {
    visible: Boolean,

    preview: { type: Boolean, default: false },

    aspectRatio: { type: Number, default: 1 }, // NaN = free aspect ratio,

    rounded: { type: Boolean, default: false },

    dialogTitle: { type: String, default: t('imageCropper.header') },

    imgSource: { type: String, required: true },
  },

  emits: ['cropped-image'],

  methods: {
    cropImage () {
      this.$refs.cropper.cropImage()
    },
  },
}
</script>
