import { MTreatmentPlanPresenter } from '@/_api/MTreatmentPlan/MTreatmentPlanPresenter'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import LazyTree from '@/vue_present/Reuse/LazyTree/store/LazyTree'
import { TREATMENT_PLANS_STATUSES } from '@/vue_apps/TreatmentPlans/TreatmentPlansList/const/const'
import { onWsEntryActions } from '@/vue_components/store/modules/egisz/ws/actions'
import {
  TreatmentPlan,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/TreatmentPlan'
import {
  TreatmentEntryEmpty,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/TreatmentEntryEmpty'
import {
  TreatmentEntryFirstRow,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/TreatmentEntryFirstRow'
import {
  TreatmentEntry,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/TreatmentEntry'
import {
  notifyByResponseResults,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/helpers'
import { orUndefined } from '@/_medods_standart_library/msl'
import { checkCanAddWithTeeth } from '@/vue_present/Reuse/EntryTypes/shared/checkCanAddWithTeeth'

const ROOT = 0

export class TreatmentPlansLazyTreeApi extends LazyTree {
  /**
   * @type {MTreatmentPlanPresenter}
   * @private
   */
  _presenter

  /**
   * @type {Record<number, TreatmentEntry>} treeMap
   */
  treeMap = {
    [ROOT]: new LazyTreeNode(this, { id: 0, title: '...', children: [] }),
  }

  /**
   * @type {number}
   * @private
   */
  _clientId

  constructor (clientId) {
    const presenter = new MTreatmentPlanPresenter()
    super(presenter)
    this._presenter = presenter
    this._clientId = clientId
  }

  _fetchClientTreatmentPlans () {
    return this._presenter.list({ clientId: this._clientId, status: { id: TREATMENT_PLANS_STATUSES.IN_PROGRESS } })
      .then(({ data: treatmentPlans = [] }) => {
        this.treeMap[ROOT].children = treatmentPlans.map((tp) => {
          this.treeMap[tp.id] = new TreatmentPlan(this, { ...tp, parentId: ROOT })

          return this.treeMap[tp.id]
        })

        return this.treeMap[ROOT].children
      })
  }

  _fetchTreatmentPlanEntries (id) {
    return this._presenter.fetchEntries(id, this._clientId)
      .then(({ data: treatmentEntries, totalItems = 0 }) => {
        if (!totalItems) {
          return [new TreatmentEntryEmpty(this, { id: Utils.newGUID(), parentId: id })]
        }

        this.treeMap[id].children = [
          new TreatmentEntryFirstRow(this, { id: Utils.newGUID(), parentId: id }),

          ...treatmentEntries.map((entry) => {
            const rawNode = { ...entry, parentId: id }
            this.treeMap[entry.id] = new TreatmentEntry(this, rawNode, true)

            return this.treeMap[entry.id]
          }),
        ]

        return this.treeMap[id].children
      })
  }

  /** @param {number} id */
  fetchNode (id = ROOT) {
    return !id
      ? this._fetchClientTreatmentPlans()
      : this._fetchTreatmentPlanEntries(id)
  }

  _unselectAll () {
    this.treeMap[ROOT].select(false)
  }

  _extractDataFromSelectedNodes (extraParams = {}) {
    return this.selectedNodesIds.map((id) => {
      const entryTypeKind = this.treeMap[id]?.kind
      const teethMap = this.treeMap[id]?.teethMap || false
      const canTeeth = checkCanAddWithTeeth({ kind: entryTypeKind, teethMap })

      return {
        id,
        transferredAmount: this.treeMap[id].transferredAmount,
        ...extraParams,
        toothIds: orUndefined(canTeeth) && (extraParams.toothIds || []),
      }
    })
  }

  transferTreeData (extraParams = {}) {
    const extractedData = this._extractDataFromSelectedNodes(extraParams)
    const mappedData = Utils.arrayToMap(extractedData)

    return this._presenter.transferTreeData(extractedData, this._clientId)
      .then(({ failedEntriesErrors = [], successfullySavedTreatmentEntryIds = [], requestError, request }) => {
        notifyByResponseResults(successfullySavedTreatmentEntryIds.length, failedEntriesErrors.length, request?.status === 404)
        this._unselectAll()

        successfullySavedTreatmentEntryIds.forEach((id) => {
          this.treeMap[id].setDuplicated()
          this.treeMap[id].updateSelectable(mappedData[id].transferredAmount)
        })

        this._handleErrors(failedEntriesErrors)

        if (requestError) { return }

        onWsEntryActions({ client: { id: this._clientId } })
      })
  }

  /**
   * @param {number[] | {created_amount: string}[]} failedEntriesErrors
   * @private
   */
  _handleErrors (failedEntriesErrors = []) {
    if (failedEntriesErrors.length && failedEntriesErrors[0]._popup_ && Array.isArray(failedEntriesErrors[0]._popup_)) {
      return Utils.reportError('TreatmentPlansLazyTreeApi::transferTreeData', failedEntriesErrors[0]._popup_.join(', '))()
    }

    if (
      failedEntriesErrors.length &&
      failedEntriesErrors[0].created_amount?.length &&
      typeof failedEntriesErrors[0].created_amount[0] === 'string'
    ) {
      Utils.reportError('TreatmentPlansLazyTreeApi::transferTreeData', failedEntriesErrors[0].created_amount[0])()
    }

    failedEntriesErrors.forEach((id) => { this.treeMap[id].setError() })
  }
}
