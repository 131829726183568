import { createWaitingItem, updateWaitingItem } from '../rest.js'
import { waitingItemAdapter } from '@/vue_components/doctor_schedules/adapters'
import { request } from '@/lib/transport/request'

export const waitingListEndpoint = {
  create (formData) {
    return createWaitingItem({
      waiting_list: waitingItemAdapter.toServer(formData),
    })
  },

  update (formData) {
    return updateWaitingItem(formData.id, {
      waiting_list: waitingItemAdapter.toServer(formData),
    })
  },

  destroy (id) {
    return request({
      type: 'DELETE',
      url: Routes.waiting_list_path(id),
    }).promise
  },
}
