<template>
  <el-card class="head-desktop__el-card box-card">
    <div
      slot="header"
      class="clearfix"
    >
      <div class="card-title flex-grow-1">
        <span :class="['el-card-title__icon', card.icon]" />
        <span class="el-card-title__title">
          <a
            :href="card.link"
            target="_blank"
          >
            {{ card.label }}
          </a>
        </span>
      </div>
      <div class="el-card-title__actions">
        <el-button
          class="el-card-title__btn fad fa-fw fa-sync-alt"
          :class="{rotate_btn: isRefreshButtonRotating}"
          type="text"
          @click="reloadBtnHandler"
        />

        <el-button
          class="el-card-title__btn fad fa-fw  fa-times"
          type="text"
          @click="$emit('removeCard', card.value)"
        />
      </div>
    </div>

    <component
      :is="card.value"
      ref="presenter"
      :card="card"
    />
  </el-card>
</template>

<script>
import Income from './cards/income'
import Appointment from './cards/appointment'
import AttractionSource from './cards/attraction_source'
import Department from './cards/department'
import AverageCheck from './cards/average_check'
import CallConversion from './cards/call_conversion'
import Call from './cards/call'
import ClientFeedbacks from './cards/client_feedbacks'
import NpsDynamics from './cards/nps_dynamics.vue'

export default {
  components: {
    NpsDynamics,
    ClientFeedbacks,
    Income,
    Appointment,
    AttractionSource,
    Department,
    AverageCheck,
    CallConversion,
    Call,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isRefreshButtonRotating: false,
    }
  },
  methods: {
    rotate () {
      this.isRefreshButtonRotating = true
      setTimeout(() => {
        this.isRefreshButtonRotating = false
      }, 1000)
    },
    reloadBtnHandler () {
      this.$refs.presenter.requestData()
      this.rotate()
    },
  },
}
</script>
