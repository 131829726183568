import Vue from 'vue/dist/vue.esm'
import Page from './page.vue'
import router from '@/vue_components/router/router.js'
import { createStore } from '@/vue_components/store/store.js'

export default () => {
  const store = createStore()
  // eslint-disable-next-line
  new Vue({
    store,
    router,
    el: '#app',
    render: (h) => h(Page),
  })
}
