<template>
  <modal
    :modal-visibility="isOpen"
    :modal-size="isAllFormsInTabs ? 'lg' : 'xl'"
    @close="$emit('close')"
  >
    <template #header>
      <span class="fad fa-building" />
      {{ t('company') }}
    </template>

    <template #body>
      <slot name="form" />
    </template>

    <template
      #[footerSlotName]
    >
      <guarded-control
        tag="button"
        :permissions="['canManageCompany']"
        class="btn btn-success btn-with-icon modal-save"
        type="button"
        @click:allowed="$emit('submit')"
      >
        <span class="btn-with-icon_icon fad fa-save" />
        <span class="btn-with-icon_text">
          {{ t('save') }}
        </span>
      </guarded-control>
      <button
        class="btn btn-primary btn-with-icon modal-close"
        type="button"
        @click="$emit('close')"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">
          {{ t('close') }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal'
import GuardedControl from '@/vue_components/common/guarded_control'

export default {
  name: 'CompanyModalTabbed',
  components: {
    Modal,
    GuardedControl,
  },

  props: {
    isOpen: Boolean,

    isAllFormsInTabs: Boolean,
  },

  computed: {
    footerSlotName () {
      return this.isAllFormsInTabs ? 'footer-left' : 'footer-right'
    },
  },
}
</script>
