import { DateHelpers } from 'src/helpers/DateHelpers'
import { IProceduresReportFiltersGet, IProceduresReportFiltersSet } from './interfaces'
import { _getProceduresReportFilters as getFilters } from './_filtersGetter'

let initialFilters: IProceduresReportFiltersGet = null

export const saveProceduresReportInitialFilters = (): void => {
  initialFilters = getFilters()
}

export const getProceduresReportInitialFilters = (): IProceduresReportFiltersSet => ({
  ...initialFilters,
  period: DateHelpers.getCurrentMonthPeriod(),
})
