import CalendarWorkloadDyeConfig from '@/components/workload_dye/config.js'

export default {
  configurationWorkload () {
    const {
      CabinetsWorkloadCalculator,
      CabinetsWorkloadStorage,
      DoctorsWorkloadCalculator,
      DoctorsWorkloadStorage,
      CalendarWorkloadDye,
      rulesForCalendarWorkloadDye,
    } = CalendarWorkloadDyeConfig

    const cabinetsWorkloadCalculator = new CabinetsWorkloadCalculator(
      CabinetsWorkloadStorage.instance(this.GET_SCHEDULE_CURRENT_CLINIC_ID, this.clinicsWorkload)
    )

    const doctorsWorkloadCalculator = new DoctorsWorkloadCalculator(
      DoctorsWorkloadStorage.instance(this.GET_SCHEDULE_CURRENT_CLINIC_ID, this.clinicsWorkload)
    )

    const calendarWorkloadDye = new CalendarWorkloadDye({
      rules: rulesForCalendarWorkloadDye,
      calcIntervals: this.clinicsWorkload.calc_intervals,
    })

    cabinetsWorkloadCalculator.cabinetsWorkloadStorage =
      CabinetsWorkloadStorage.instance(this.GET_SCHEDULE_CURRENT_CLINIC_ID, this.clinicsWorkload)

    doctorsWorkloadCalculator.doctorsWorkloadStorage =
      DoctorsWorkloadStorage.instance(this.GET_SCHEDULE_CURRENT_CLINIC_ID, this.clinicsWorkload)

    calendarWorkloadDye.calcIntervals = this.clinicsWorkload.calc_intervals

    calendarWorkloadDye
      .additionalClassHandlers
      .push((colorizedDay) => this.highlightedDays.find((day) => day === colorizedDay) ? '-highlighted' : '')

    calendarWorkloadDye.calculateWorkload = this.IS_ACTIVE_SCHEDULE_DOCTORS
      ? (day) => doctorsWorkloadCalculator.workload(day)
      : (day) => cabinetsWorkloadCalculator.workload(day)

    this.cabinetsWorkloadCalculator = cabinetsWorkloadCalculator
    this.doctorsWorkloadCalculator = doctorsWorkloadCalculator
    this.calendarWorkloadDye = calendarWorkloadDye
  },
}
