<template>
  <si-field>
    <si-column
      :name="header.referralSurname.sortable"
      :active="sortableColumn"
      class="referral"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ t('referrals') }}
    </si-column>

    <si-column
      :name="header.goods.sortable"
      :active="sortableColumn"
      class="goods"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ t('goods') }}
    </si-column>

    <si-column
      :name="header.analysis.sortable"
      :active="sortableColumn"
      class="analysis"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ t('analysis') }}
    </si-column>

    <si-column
      :name="header.services.sortable"
      :active="sortableColumn"
      class="services"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ t('services') }}
    </si-column>

    <si-column
      :name="header.total.sortable"
      :active="sortableColumn"
      class="total"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ t('reports.total') }}
    </si-column>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiColumn from '@/vue_components/sort_items/si_table/si_column.vue'

export default {
  name: 'ReferralsHeaderConsolidated',
  components: { SiColumn, SiField },

  props: {
    sortableColumn: {
      type: String,
      required: true,
    },
  },

  emits: [
    'sort-change',
  ],

  data () {
    return {
      header: {
        referralSurname: {
          text: t('referrals'),
          sortable: 'referralSurname',
        },

        goods: {
          text: t('goods'),
          sortable: 'goodsReward',
        },

        analysis: {
          text: t('analysis'),
          sortable: 'analysisReward',
        },

        services: {
          text: t('services'),
          sortable: 'servicesReward',
        },

        total: {
          text: t('reports.total'),
          sortable: 'totalReward',
        },
      },
    }
  },
}
</script>
