import Vue from 'vue/dist/vue.esm'
import AppointmentTypesCatalog from './appointment_types'

export const VueAppointmentTypesEntryPoint = () => {
  const el = '#vue_appointment_types_catalog'

  if (!document.querySelector(el)) { return }
  // eslint-disable-next-line no-new
  new Vue({
    el,
    render: (h) => h(AppointmentTypesCatalog),
  })
}
