<template>
  <div class="discount-form">
    <!-- Настройка скидки -->
    <slot name="discount">
      <div class="discount-form__discount">
        <!-- Значение скидки -->
        <div class="discount__value">
          <span>{{ t('sum_price') }}:&nbsp;</span>
          <el-input-number
            :value="discountValue"
            :label="t('sum')"
            :placeholder="'0.00'"
            :precision="2"
            :controls="false"
            :min="0"
            :max="maxDiscount"
            size="mini"
            @input="
              $emit('update:discountValue', $event);
              $emit('checkDiscountValue')
            "
          />

          <!-- Тип скидки -->
          <items-list
            class="value__discount-types"
            :items="discountTypeArray"
            :selected-items-ids="[discountType]"
            :multi-select="false"
            :id-item-field="'name'"
            :displayed-item-field="'label'"
            :item-class="'vue-list-item btn-width-37'"
            @change="
              $emit('update:discountType', $event[0]) &&
                $emit('updateDiscountValues')
            "
          />
        </div>

        <!-- Округление -->
        <div
          v-if="isMassDiscount"
          class="discount__round-order"
        >
          <el-checkbox
            :value="isRoundOrderPrices"
            @change="
              $emit('update:isRoundOrderPrices', $event) &&
                $emit('checkDiscountValue')
            "
          >
            {{ t('round_order_prices') }}
          </el-checkbox>
        </div>
      </div>
    </slot>

    <!-- Справочник скидок -->
    <slot name="catalog">
      <div class="discount-form__catalog">
        <!-- Активные -->
        <slot name="activeDiscounts">
          <el-tree
            ref="tree"
            :data="activeDiscounts"
            :props="defaultFields"
            node-key="id"
            show-checkbox
            default-expand-all
            :default-checked-keys="defaultCheckedKeys"
            @node-click="updateDiscountType"
            @check-change="handleCheckChange"
          />
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
import { DISCOUNT_TYPES, FAKE_NODE_ID1, FAKE_NODE_ID2, SYSTEM_DISCOUNTS } from './const'
import props from './discount_form_props'
import ItemsList from '@/vue_components/items_list.vue'
import { getDiscountReasonsSystemNameByReasonId } from './system_discounts'

export default {
  name: 'DiscountForm',
  components: {
    ItemsList,
  },

  props,

  data: () => ({
    DISCOUNT_TYPES,
  }),

  computed: {
    activeDiscounts () {
      const systemDiscounts = this.getSystemDiscounts
      const customDiscounts = this.getCustomDiscounts

      const discounts = []
      if (systemDiscounts.children.length) {
        discounts.push(systemDiscounts)
      }

      if (customDiscounts.children.length) {
        discounts.push(customDiscounts)
      }

      return discounts
    },

    getSystemDiscounts () {
      return {
        id: FAKE_NODE_ID1, /* для управления узлами дерева */
        title: t('system_discounts_title'),
        disabled: true,
        children: this.discountArray.filter((item) => !!item.system_name),
      }
    },

    getCustomDiscounts () {
      return {
        id: FAKE_NODE_ID2,
        title: t('active_discounts_title'),
        disabled: true,
        children: this.discountArray.filter((item) => !item.system_name),
      }
    },

    defaultCheckedKeys () {
      if (this.discountReasonId) return [this.discountReasonId]

      return []
    },
  },

  methods: {
    /**
     * Обработка нажатия на чекбокс в дереве
     */
    handleCheckChange (data, checked) {
      if (this.isBonus()) { return }

      if (checked) {
        this.$refs.tree.setCheckedKeys([data.id])
        this.$emit('setDiscountReasonId', data.id)
      } else {
        /* el-tree посылает 2 события idNew и idOld,
        * idOld обрабатывается позже,
        * чтобы убрать влияние идёт проверка на discountReasonId
        */
        if (this.discountReasonId === data.id) {
          this.$emit('setDiscountReasonId', 0)
        }
      }
    },

    /**
     * Обработка нажатия на узел в дереве
     */
    updateDiscountType (data) {
      if (this.isBonus()) { return }

      this.resetCheckedKeys()

      if (data.id >= FAKE_NODE_ID1) return

      const currentId = this.discountReasonId === data.id
        ? 0
        : data.id

      this.$emit('setDiscountReasonId', currentId)
      if (currentId) {
        this.$refs.tree.setCheckedKeys([currentId])
      }
    },

    resetCheckedKeys () {
      this.$refs.tree && this.$refs.tree.setCheckedKeys([])
    },

    isBonus () {
      if (SYSTEM_DISCOUNTS.BONUS === getDiscountReasonsSystemNameByReasonId(this.discountReasonId)) {
        this.$emit('showBonusDiscountMessage')

        return true
      }

      return false
    },
  },
}
</script>
