$document.on('rez/entry_results/index', function () {
  Utils.initCalendar()

  //Сортировка результатов
  SortItems.init({
    itemName: ['entries'],
    url: 'entry_results',
    periodRequired: true,
    defaults: { period: 'today' },
  })

  _toggleLaboratorySelector()

  $('.entry_type').on('click', function () {
    _toggleLaboratorySelector()
  })

  function _toggleLaboratorySelector () {
    if ($('#entry_analysis').is('.active')) {
      $('.laboratory-list').removeClass('visibility-dependent')
    } else {
      $('.laboratory-list').addClass('visibility-dependent').val('')
    }
  }

  PubSub.on('page.specific.sort_items.finish', () => {
    Utils.select2ButtonUpdate()
    const entrySelect = $('.entry_state_select')
    entrySelect.change((e) => {
      const select = $(e.target)
      const id = select.data('id')
      $.ajax({
        url: Routes.entry_path(id),
        method: 'PATCH',
        data: {
          entry: {
            state: select.val(),
          },
        },
        error (err) {
          console.error(err.responseJSON)
        },
      })
    })
  })
})
