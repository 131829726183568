<template>
  <m-select
    :value="$attrs.value"
    :items="$attrs.items"
    disabled
    option-label="fullName"
    use-user-mode
    :label="label"
    :placeholder="placeholder"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'

export default {
  name: 'UserOnlyCurrentFilter',
  components: { MSelect },

  computed: {
    label () {
      return this.$attrs.multiply
        ? t('users')
        : t('user')
    },

    placeholder () {
      return this.$attrs.placeholder || t('all_employees')
    },
  },
}
</script>
