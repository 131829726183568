export const promobotFieldMapLegacy = Object.freeze({
  authority: '#client_id_card_who_issue',

  birth_date_mrz: '#client_birthdate',
  birthdate: '#client_birthdate',
  birth_date: '#client_birthdate',

  gender: '#client_sex_id',
  gender_mrz: '#client_sex_id',
  gender_predicted: '#client_sex_id',

  issue_date: '#client_id_card_issue_date',
  issue_date_mrz: '#client_id_card_issue_date',

  name: '#client_name',
  name_mrz: '#client_name',

  number: '#client_id_card_number',
  number_mrz: '#client_id_card_number',
  number2: '#client_id_card_number',

  patronymic: '#client_second_name',
  patronymic_mrz: '#client_second_name',

  series: '#client_id_card_series',
  series_mrz: '#client_id_card_series',

  surname: '#client_surname',
  surname_mrz: '#client_surname',

  country: '#client_address_country',
  region: '#client_address_region',
  city: '#client_address_city',
  street: '#client_address_street',
  apartment: '#client_address_flat',
  house: '#client_address_house',

  inn: '#client_inn',
  snils: '#client_snils',
  oms: '#client_oms_number',
})
