import Vue from 'vue/dist/vue.esm'
import ClientFeedbacksConfiguration from '@/vue_components/client_feedbacks/configuration.vue'
import { createStore } from '@/vue_components/store/store.js'
import { createVueApp } from '@/vue_components/create_vue_app'
import ClientFeedbacksModule from '@/vue_components/client_feedbacks/ClientFeedbacksModule.vue'

let vueApp = null

export const createClientFeedbacksModuleVue = (el = '#vue_client_feedbacks_root') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    name: 'ClientFeedbacksModule',
    render: (h) => h(ClientFeedbacksModule),
  })
}

$document.on('rez/client_feedbacks/feedbacks', () => {
  createClientFeedbacksModuleVue()
})

$document.on('rez/client_feedbacks/configurations/edit', () => {
  if (gon.specific.moduleIsEnabled) {
    const store = createStore()
    // eslint-disable-next-line
    new Vue({
      store,
      el: '#client-feedback-configuration',
      components: { ClientFeedbacksConfiguration },
      template: '<div id="client-feedback-configuration"><client-feedbacks-configuration /></div>',
    })
  }
})
