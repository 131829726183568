import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getDateTitleHeadlessSchema = () =>
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema({
        headers: {
          date: new MTableHeader().addFixedWidth('100px'),
          title: new MTableHeader(),
        },
      })
    )
