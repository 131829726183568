<template>
  <div :class="useHiddenPrint ? 'hidden-print' : 'showed-print'">
    <div class="kdl-waybill_print-button">
      <m-button
        type="dark"
        :tooltip="t('print')"
        no-use-fw
        icon="print"
        button-style="fill"
        size="mini"
        @click="onPrintClick"
      />
    </div>
    <kdl-waybill-table
      :clinic-identifier="clinicIdentifier"
    >
      <template
        #kdl-biomaterials
      >
        <template
          v-for="(biomaterialContainer,index) in biomaterialsArray"
        >
          <tr
            v-if="getBiomaterialTitle(biomaterialContainer)"
            :key="`kdl-biomaterials:${biomaterialContainer.id}`"
          >
            <td class="number">
              {{ index + 1 }}
            </td>
            <td>
              {{ getBiomaterialTitle(biomaterialContainer) }}
            </td>
            <td>
              {{ biomaterialContainer.quantity }}
            </td>
            <td />
            <td />
          </tr>
        </template>
      </template>
      <template
        #kdl-biomaterials-for-bacteriological-examination
      >
        <template
          v-for="(biomaterialContainer, index) in biomaterialsBacteriologicalArray"
        >
          <tr
            v-if="getBiomaterialBacteriologicalTitle(biomaterialContainer)"
            :key="`kdl-biomaterials-for-bacteriological-examination:${biomaterialContainer.id}`"
          >
            <td class="number">
              {{ index + 1 }}
            </td>
            <td>
              {{ getBiomaterialBacteriologicalTitle(biomaterialContainer) }}
            </td>
            <td>
              {{ biomaterialContainer.quantity }}
            </td>
            <td />
            <td />
          </tr>
        </template>
      </template>
    </kdl-waybill-table>
  </div>
</template>

<script>
import { KDL_BIOLOGICAL_BIOMATERIALS, KDL_WAYBILL_BIOMATERIALS_CONSTS } from '../const/kdl'
import KdlWaybillTable from './KdlWaybillTable.vue'
import MButton from '../../../vue_present/_base/buttons/MButton/MButton.vue'
import { cloneDeep } from 'lodash'

export default {
  name: 'KdlWaybill',
  components: { MButton, KdlWaybillTable },

  props: {
    clinicIdentifier: {
      type: String,
      default: '',
    },

    biomaterialContainers: {
      type: Array,
      default: () => [],
    },

    useHiddenPrint: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      KDL_WAYBILL_BIOMATERIALS_CONSTS,
      KDL_BIOLOGICAL_BIOMATERIALS,
    }
  },

  computed: {
    countedContainers () {
      const containers = cloneDeep(this.biomaterialContainers)
      const map = {}

      containers.forEach((item) => {
        if (!map[item.code]) {
          map[item.code] = { ...item, quantity: 1 }
        } else {
          map[item.code].quantity++
        }
      })

      return Object.values(map)
    },

    biomaterialsArray () {
      return this.countedContainers.filter((item) => !KDL_BIOLOGICAL_BIOMATERIALS[item.code])
    },

    biomaterialsBacteriologicalArray () {
      return this.countedContainers.filter((item) => KDL_BIOLOGICAL_BIOMATERIALS[item.code])
    },
  },

  methods: {
    getBiomaterialTitle (biomaterial) {
      return KDL_WAYBILL_BIOMATERIALS_CONSTS[biomaterial.code] || biomaterial.title
    },

    getBiomaterialBacteriologicalTitle (biomaterial) {
      if (biomaterial.code === 'СК-БАК') {
        if (biomaterial.title.includes('мочи')) { return KDL_BIOLOGICAL_BIOMATERIALS['СКМ-БАК'] }
        if (biomaterial.title.includes('сперм')) { return KDL_BIOLOGICAL_BIOMATERIALS['СКС-БАК'] }
        if (biomaterial.title.includes('мокрот')) { return KDL_BIOLOGICAL_BIOMATERIALS['СКБЖ-БАК'] }
      }

      return KDL_BIOLOGICAL_BIOMATERIALS[biomaterial.code]
    },

    onPrintClick () {
      this.$emit('print-cover-letter')
      window.print()
    },
  },
}
</script>
