<template>
  <draggable
    v-model="cards"
    class="cards-container"
    group="cards"
    handle=".el-card__header"
    @start="drag=true"
    @end="drag=false"
  >
    <card
      v-for="card in cards"
      :key="card.value"
      :card="card"
      :data-id="card.value"
      @removeCard="removeCard(card.value)"
    />
  </draggable>
</template>

<script>
import Card from './card.vue'
import draggable from 'vuedraggable'

export default {
  components: {
    Card, draggable,
  },
  data () {
    return {}
  },
  computed: {
    cards: {
      get () {
        return this.$store.getters['headDesktopStore/pickedCards']
      },
      set (reorderedCards) {
        return this.$store.commit('headDesktopStore/replacePickedCardIds', reorderedCards.map((card) => card.value))
      },
    },
  },
  methods: {
    removeCard (value) {
      this.$store.commit('headDesktopStore/removeCard', value)
    },
  },
}
</script>
