import { cloneDeep } from 'lodash'
import { ValidationHolder } from '@/vue_components/mixins/validationHolder'
import { MODES } from '@/vue_components/catalogs/const'
/**
 * Эта миксина позволяет уменьшить количество кода при создании компонентов-справочников,
 * так как хранит в себе необходимый минимум из свойств и методов, используемый для создания этих компонентов.
 * Также содержит в себе миксину "ValidationHolder", необходимую для валидаций форм справочников.
 * В хуке created компонента, куда добавляется данная миксина, необходимо вызвать метод 'initCatalogHolder'
 * с параметром creators - объект с функциями, возвращающие дефолтные объекты для форм и сообщений валидаций.
 * Эти функции используются для задания начальных значений при инициализации компонента и при очистке формы
 */
export const CatalogHolder = {
  mixins: [ValidationHolder],

  data () {
    return {
      loadingList: true,
      isShowForm: false,
      selectedMode: MODES.NEW,
      selectedListItem: 0,
      firstListRequestCompleted: false,
      lastGetCurrentDataRequest: null,
      creators: null,
      formData: null,
      previousFormData: null,
      validationMessages: null,
    }
  },

  methods: {
    initCatalogHolder (creators) {
      this.creators = creators
      this.formData = creators.formDataCreator()
      this.previousFormData = creators.formDataCreator()
      this.validationMessages = creators.validationMessagesCreator()

      this.skipNextValidations()
    },

    openCreateForm () {
      this.clearForm()
      this.setSelectedItem()
      this.setMode(MODES.NEW)

      this.showForm()
    },

    openEditForm (data, setData = this.setData) {
      if (data.id !== this.selectedListItem) {
        setData(data)
      }

      this.setSelectedItem(data.id)
      this.setMode(MODES.EDIT)

      this.showForm()
    },

    setData (data) {
      this.formData = cloneDeep(data)
      this.previousFormData = cloneDeep(data)
    },

    clearForm () {
      if (this.lastGetCurrentDataRequest) {
        this.lastGetCurrentDataRequest.abort()
        this.lastGetCurrentDataRequest = null
      }

      const { formDataCreator, validationMessagesCreator } = this.creators

      this.formData = formDataCreator()
      this.previousFormData = formDataCreator()
      this.validationMessages = validationMessagesCreator()

      this.skipNextValidations()
    },

    showForm () { this.isShowForm = true },

    hideForm () {
      this.setSelectedItem()
      this.isShowForm = false
    },

    setMode (mode) { this.selectedMode = mode },

    setSelectedItem (listItemId = 0) { this.selectedListItem = listItemId },
  },
}
