import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class DoctorSalaryReportTotal {
  /** @param {TSalaryTotal} total */
  constructor (total) {
    this.sum = total.sum
    this.salarySum = total.salarySum
  }

  get tableEntryNumber () {
    return new MTableCell(t('reports.total'))
  }

  get tableEntryTitle () {
    return new MTableCell()
  }

  get tableDate () {
    return new MTableCell()
  }

  get tableOrder () {
    return new MTableCell()
  }

  get tableClient () {
    return new MTableCell()
  }

  get tableEntrySum () {
    return new MTableCell(vueFilters.currency(this.sum))
  }

  get tableRate () {
    return new MTableCell()
  }

  get tableSalary () {
    return new MTableCell(vueFilters.currency(this.salarySum))
  }
}
