
const adult = [
  { num: 18, selected: false },
  { num: 17, selected: false },
  { num: 16, selected: false },
  { num: 15, selected: false },
  { num: 14, selected: false },
  { num: 13, selected: false },
  { num: 12, selected: false },
  { num: 11, selected: false },

  { num: 21, selected: false },
  { num: 22, selected: false },
  { num: 23, selected: false },
  { num: 24, selected: false },
  { num: 25, selected: false },
  { num: 26, selected: false },
  { num: 27, selected: false },
  { num: 28, selected: false },

  { num: 48, selected: false },
  { num: 47, selected: false },
  { num: 46, selected: false },
  { num: 45, selected: false },
  { num: 44, selected: false },
  { num: 43, selected: false },
  { num: 42, selected: false },
  { num: 41, selected: false },

  { num: 31, selected: false },
  { num: 32, selected: false },
  { num: 33, selected: false },
  { num: 34, selected: false },
  { num: 35, selected: false },
  { num: 36, selected: false },
  { num: 37, selected: false },
  { num: 38, selected: false },
]

const kid = Object.freeze([
  { num: 55, selected: false },
  { num: 54, selected: false },
  { num: 53, selected: false },
  { num: 52, selected: false },
  { num: 51, selected: false },

  { num: 61, selected: false },
  { num: 62, selected: false },
  { num: 63, selected: false },
  { num: 64, selected: false },
  { num: 65, selected: false },

  { num: 85, selected: false },
  { num: 84, selected: false },
  { num: 83, selected: false },
  { num: 82, selected: false },
  { num: 81, selected: false },

  { num: 71, selected: false },
  { num: 72, selected: false },
  { num: 73, selected: false },
  { num: 74, selected: false },
  { num: 75, selected: false },
])

export const teethMap = Object.freeze({
  adult,
  kid,
})

export const getTeethSideNumber = (teethKind) =>
  teethKind === 'adult' ? 8 : 5
