<template>
  <div class="schedule-template-modal-body">
    <schedule-template-modal-body-left
      ref="selectionComponent"
      :initial-selectors="initialSelectors"
      :all-c-i-ds="allCIDs"
      :users="users"
      :cabinets="cabinets"
      :current-clinic="currentClinic"
      @triggerChanges="_getScheduleTemplates"
    />
    <schedule-template-modal-body-right
      ref="scheduleTemplatesComponent"
      :all-c-i-ds="allCIDs"
      :users="users"
      :users-map="usersMap"
      :cabinets="cabinets"
      :cabinets-map="cabinetsMap"
      :online-recording-enabled="onlineRecordingEnabled"
      @removeScheduleTemplate="data => $emit('removeScheduleTemplate', data)"
    />
  </div>
</template>

<script>
import ScheduleTemplateModalBodyLeft from './schedule_template_body_left.vue'
import ScheduleTemplateModalBodyRight from './schedule_template_body_right.vue'
import requests from './requests.js'
import { WITHOUT_CABINET_ID } from '../../../const.js'

export default {
  name: 'ScheduleTemplateModalBody',
  components: {
    ScheduleTemplateModalBodyLeft,
    ScheduleTemplateModalBodyRight,
  },
  props: {
    initialSelectors: { type: Object, required: true },
    users: { type: Array, required: true },
    usersMap: { type: Object, required: true },
    cabinets: { type: Array, required: true },
    cabinetsMap: { type: Object, required: true },
    onlineRecordingEnabled: { type: Boolean, required: true },
    currentClinic: { type: Object, required: true },
  },
  data: () => ({
    allCIDs: null,
  }),
  created () {
    this.allCIDs = this.cabinets
      .map((cabinet) => cabinet.id)
      .filter((id) => id !== WITHOUT_CABINET_ID)
    this.allCIDs.push(null)
  },
  methods: {
    ...requests,

    saveChanges () {
      const templatesWithChanges = this.$refs.scheduleTemplatesComponent.getTemplatesWithChanges()
      if (!templatesWithChanges.length) return this.$emit('close')

      const stub = Object.create(null)
      const rollbackAll = {rollback_all: true}
      const requestData = templatesWithChanges.map((template) => template.getRequestData(stub, rollbackAll))
      const usersWithChanges = Array.from(new Set(templatesWithChanges.map((template) => template.template.user_id)))
        .map((userId) => this.usersMap[userId].full_name)

      const updateScheduleTemplateModalData = {
        show: true,
        usersWithChanges,
        yesEvent: () => {
          this._sendData(requestData)
          this.$emit('updateScheduleTemplate', { show: false })
        },
        noEvent: () => {
          this.$emit('updateScheduleTemplate', { show: false })
        },
      }

      this.$emit('updateScheduleTemplate', updateScheduleTemplateModalData)
    },
  },
}
</script>
