import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'

export class TreatmentPlan extends LazyTreeNode {
  constructor (tree, rawNode, isLeaf = false) {
    super(tree, rawNode, isLeaf)
    this.performer = rawNode.performer
    this.finalSum = rawNode.finalSum
    this.kind = rawNode.kind
  }
}
