<template>
  <div class="flex-form">
    <div class="form-label">
      <label for="discount_reason_title">
        {{ t('title') }}
      </label>

      <required-mark />
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationErrors.title"
        fixed-height
      >
        <el-input
          id="discount_reason_title"
          type="text"
          name="discount_reason[title]"
          clearable
          :value="title"
          @input="$updateSync('title', $event)"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label>
        {{ t('status') }}
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="[]"
        fixed-height
      >
        <items-list
          name="discount_reason[active]"
          :items="STATUSES"
          id-item-field="id"
          displayed-item-field="title"
          :selected-items-ids="statusSelect"
          :min-selected-items="1"
          :multi-select="false"
          @change="$updateSync('active', $event[0])"
        />
      </validation-wrapper>
    </div>
  </div>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import RequiredMark from '@/vue_components/common/required_mark'
import ItemsList from '@/vue_components/items_list.vue'
import { STATUSES } from '../const'

export default {
  name: 'DiscountReasonsForm',
  components: {
    RequiredMark,
    ValidationWrapper,
    ItemsList,
  },

  props: {
    validationErrors: {
      type: Object,
      default: () => ({}),
    },

    title: {
      type: String,
      default: '',
    },

    active: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      STATUSES,
    }
  },

  computed: {
    statusSelect () {
      return [this.active]
    },
  },
}
</script>
