import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class DoctorSalaryByPaymentsReportItem {
  /** @param {TDoctorSalaryByPaymentsEntryType} entryType */
  constructor (entryType) {
    this.id = entryType.id
    this.number = entryType.number
    this.salaryEntry = entryType.salaryEntry
    this.paymentEntry = entryType.paymentEntry
    this.title = entryType.title
    this.amount = entryType.amount
  }

  get tableNumber () {
    return new MTableCell(this.number || '-')
  }

  get tableTitle () {
    return new MTableCell(this.title)
      .addTooltip(this.title)
      .addCut()
  }

  get tableEntryAmount () {
    return new MTableCell(this.amount)
  }

  get tableEntrySum () {
    return new MTableCell(vueFilters.price(this.paymentEntry.paidSum))
  }

  get tableRate () {
    return new MTableCell(`${vueFilters.price(this.salaryEntry.rate)} ${vueFilters.rateType(this.salaryEntry.rateType)}`)
  }

  get tableSalary () {
    return new MTableCell(vueFilters.price(this.salaryEntry.sum))
  }
}
