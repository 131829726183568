$document.on('rez/stores/new rez/stores/edit', function () {
  let form = FormFactory.build('store')

  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      break
    case 'edit':
      form.editItem()
      break
  }
})
