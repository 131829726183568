import { ProtocolModel } from '@/vue_apps/Protocols/entities/ProtocolModel'
import { cloneDeep } from 'lodash'
import { SEMD_TYPE_ENUM } from '@/vue_apps/Semds/const/semdTypes'

export class Protocol<DocumentsDetails> extends ProtocolModel<DocumentsDetails> {
  /** @type {IProtocolTemplateDataObject} */
  storeState = {}

  constructor (props) {
    super(props)

    this.fillSemdData()
  }

  fillProps (props) {
    this._fillProps(props)
    this.setValue('clientId', props.client?.id)
  }

  buildStoreState () {
    try {
      this.storeState = JSON.parse(this.templateData)
    } catch (e) {
      this.storeState = {}
    }
  }

  setValue (key, value) {
    if (key === 'storeState') {
      this.storeState = value

      return
    }

    return super.setValue(key, value)
  }

  fillPrintingTools ({ entryInfo, entryTitle, doctorSignature, clinicHeader }) {
    this.setValue('printEntryInfo', entryInfo)
    this.setValue('printEntryTitle', entryTitle)
    this.setValue('printDoctorSignature', doctorSignature)
    this.setValue('printClinicHeader', clinicHeader)
  }

  getPrintingTools () {
    return {
      entryInfo: this.printEntryInfo,
      entryTitle: this.printEntryTitle,
      doctorSignature: this.printDoctorSignature,
      clinicHeader: this.printClinicHeader,
    }
  }

  loadTemplateDataToStore () {
    this.buildStoreState()

    window.application.form_builder.frontend
      .container
      .get('entity_manager')
      .load(this.storeState)
  }

  /** @param { ITemplateRecordsTreeItem } templateRecord */
  changeTemplate (templateRecord) {
    this.fillPrintingTools({
      entryInfo: templateRecord.printEntryInfo,
      entryTitle: templateRecord.printEntryTitle,
      doctorSignature: templateRecord.printDoctorSignature,
      clinicHeader: templateRecord.printClinicHeader,
    })

    this.setValue('templateData', templateRecord.templateData)
    this.setValue('templateHtml', templateRecord.templateHtml)

    if (templateRecord.semdTemplateDocumentDetail) {
      const templateSemdType = templateRecord.semdTemplateDocumentDetail.semdType
      const semdType = typeof templateSemdType === 'number'
        ? templateSemdType
        : SEMD_TYPE_ENUM[templateSemdType] || null

      this.setValue('semdType', semdType)
    }

    if (templateRecord.semdType) {
      this.setValue('semdType', templateRecord.semdType)
    }

    this.loadTemplateDataToStore()
  }

  /** @param {Object} semdsDocumentDetailsAttributesData */
  fillSemdData (semdsDocumentDetailsAttributesData = {}) {
    const attributes = {
      id: this.semdsDocumentDetailsAttributes?.id,
      clinicId: this.clinic?.id,
      displayInWidgetsPersonalAccount: this.onlineAccess,
      data: cloneDeep(semdsDocumentDetailsAttributesData),
    }

    this.setValue(
      'semdsDocumentDetailsAttributes',
      attributes
    )
  }
}
