import { createVueApp } from '@/vue_components/create_vue_app'
import HeadDesktop from '@/vue_components/head_desktop/head_desktop.vue'

let vueApp = null

export const createHeadDesktop = (el = '#head_desktop_container') => {
  if (!document.querySelector(el)) { return }

  if (vueApp) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    render: (h) => h(HeadDesktop),
  })
}
