import Vue from 'vue/dist/vue.esm'
import Expenses from '@/vue_components/reports/expenses.vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper.js'
import { defineDateFormat, fetchExportFile } from '../export.js'
import { ReportParamFactory } from '@/specific/reports/shared'
import { getHeader } from '@/specific/reports/expenses/common'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

$document.on('rez/reports/expenses', function () {
  Report.init()
  const $reportCategory = $('#report_category')
  const $reportSource = $('#report_source')
  const manualOptionIndex = $reportSource.find('option[value=manual]').index()
  const findSourceOptionEl = (sourceEl, typeIdx) => sourceEl
    .next()
    .find(`[data-select-index=${typeIdx}]`)
    .parent()

  $reportCategory.on('change', (e) => {
    const $manualOption = findSourceOptionEl($reportSource, manualOptionIndex)

    if (e.target.value) {
      if ($reportSource.val() === 'manual') {
        $reportSource
          .val('')
          .trigger('change')
      }

      $manualOption.hide()
    } else {
      $manualOption.show()
    }
  })

  $reportSource.on('change', (e) => {
    if (e.target.value === 'manual') {
      $reportCategory
        .val('')
        .trigger('change')
        .parents('.filter-container')
        .hide()
    } else {
      $reportCategory
        .parents('.filter-container')
        .show()
    }
  })

  const reportTable = new Vue({
    el: '#report_right_container',
    components: { Expenses },
    mixins: [ReportWrapper],
    data: () => ({
      filters: getFilters(),
    }),
    template: `
      <expenses
        ref="report"
        :isHidden="isHidden"
        v-bind="filters"
      />`,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(getFilters())
  })

  $('#report_reset').on('click', () => {
    const $manualOption = findSourceOptionEl($reportSource, manualOptionIndex)

    $reportSource
      .val('')
      .trigger('change')

    $manualOption.show()
    reportTable.hide()
  })

  $('.export').on('click', function () {
    const filters = getFilters()
    const headerRow = getHeader(filters.expensesGroupType.id)

    const data = {
      dateFormat: defineDateFormat(),
      csvComma: gon.application.csv_character,
      floatingComma: gon.localization.currency.decimal_character,
      totalRow: {
        total: T.total,
      },
      offset: 0,
      limit: -(1 << 32 - 1),
      report: {
        period: filters.period,
      },
      headerRow,
      ...ReportFiltersFactory.defineFor('expenses', filters),
    }

    fetchExportFile(data, Routes.report_expenses_path(), T.expenses)
  })

  function getFilters () {
    return {
      period: ReportParamFactory.getRawParam('.period'),
      clinic: ReportParamFactory.getSelectParam('#report_clinic_id'),
      expenseCategories: ReportParamFactory.getButtonsParam('#report_category'),
      expenseConsumable: ReportParamFactory.getSelectParam('#report_expenses_good_type'),
      expenseSource: ReportParamFactory.getButtonsParam('#report_source'),
      expensesGroupType: ReportParamFactory.getSelectParam('#report_expenses_group_type'),
    }
  }
})
