import { FEEDBACKS_ROUTE_NAMES } from '@/vue_components/client_feedbacks/router/routeNames'

export const navRoutes = () => Object.freeze([
  {
    id: FEEDBACKS_ROUTE_NAMES.FEEDBACKS,
    title: t('feedback.menuTitles.feedbacks'),
    icon: 'commentsAlt',
  },
  {
    id: FEEDBACKS_ROUTE_NAMES.EMPLOYEES_RATING,
    title: t('feedback.menuTitles.employeesRating'),
    icon: 'doctor',
  },
  {
    id: FEEDBACKS_ROUTE_NAMES.ADMISSION_ASSESSMENT,
    title: t('feedback.menuTitles.admissionAssessment'),
    icon: 'chartLine',
  },
  {
    id: FEEDBACKS_ROUTE_NAMES.NPS,
    title: t('feedback.menuTitles.nps'),
    icon: 'chartBar',
  },
])
