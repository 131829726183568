import filters from './filters'

export default class ReportFiltersFactory {
  /**
   * Фильтрация фильтров (нах..., простите, зачем?)
   * @description Все фильтры лежат в app/javascript/src/specific/reports/filters
   * если у вас что-то не отправляется, то беда в этом каталоге
   *
   * проблемы тут
   *
   * @param {string} reportName
   * @param {object} source
   * @return {object}
   */
  static defineFor (reportName, source) {
    return filters[reportName](source)
  }
}
