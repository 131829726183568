<template>
  <modal
    v-if="showExtendScheduleTemplateModal"
    :modal-class="'extend-schedule-template-modal'"
    :modal-visibility="showExtendScheduleTemplateModal"
    @close="$emit('close')"
  >
    <span slot="header">{{ T.extend_schedule_to }}</span>
    <extend-schedule-template-modal-body
      slot="body"
      ref="modalBody"
      :has-available-templates-for-extend="hasAvailableTemplatesForExtend"
      :extend-schedule-template-modal-data="extendScheduleTemplateModalData"
    />
    <extend-schedule-template-modal-footer
      slot="footer-right"
      :show-extend-button="hasAvailableTemplatesForExtend"
      @close="$emit('close')"
      @extend="$emit('extend', $refs.modalBody.date)"
    />
  </modal>
</template>

<script>
import Modal from '../../../modal.vue'
import ExtendScheduleTemplateModalBody from './extend_schedule_template_body.vue'
import ExtendScheduleTemplateModalFooter from './extend_schedule_template_footer.vue'

export default {
  name: 'ExtendScheduleTemplateModal',
  components: {
    Modal,
    ExtendScheduleTemplateModalBody,
    ExtendScheduleTemplateModalFooter,
  },

  props: {
    showExtendScheduleTemplateModal: Boolean,
    extendScheduleTemplateModalData: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    hasAvailableTemplatesForExtend () {
      return this.extendScheduleTemplateModalData.templateIds.length > 0
    },
  },
}
</script>
