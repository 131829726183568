import {
  CABINET_LIST_STORAGE_NAME,
  SELECT_ALL_ID,
} from './const.js'

export default {

  _getStore () {
    return window.store.get(CABINET_LIST_STORAGE_NAME)
  },

  _validateStore () {
    const currentStore = this._getStore() || {}
    const ownStore = currentStore[this.GET_SCHEDULE_CURRENT_CLINIC_ID] || {}
    const validState = {}

    if (!this.allSIDs.includes(ownStore.selectedSID)) {
      validState.selectedSID = SELECT_ALL_ID
    } else {
      validState.selectedSID = ownStore.selectedSID
    }

    if (ownStore.showUsersWithoutCabinets === undefined) {
      validState.showUsersWithoutCabinets = false
    } else {
      validState.showUsersWithoutCabinets = ownStore.showUsersWithoutCabinets
    }

    if (ownStore.showSpecialtiesList === undefined) {
      validState.showSpecialtiesList = true
    } else {
      validState.showSpecialtiesList = ownStore.showSpecialtiesList
    }

    if (ownStore.selectedCIDsInSID === undefined) {
      validState.selectedCIDsInSID = {}
    } else {
      validState.selectedCIDsInSID = ownStore.selectedCIDsInSID
    }

    Object
      .keys(validState.selectedCIDsInSID)
      .forEach((sid) => {
        validState.selectedCIDsInSID[sid] =
          validState.selectedCIDsInSID[sid].filter((cid) => this.cabinetsMap[cid])
      })

    this.allSIDs.forEach((sid) => {
      if (!Array.isArray(validState.selectedCIDsInSID[sid])) {
        validState.selectedCIDsInSID[sid] = []
      }
    })

    if (!Array.isArray(validState.selectedCIDsInSID[SELECT_ALL_ID])) {
      validState.selectedCIDsInSID[SELECT_ALL_ID] = []
    }

    currentStore[this.GET_SCHEDULE_CURRENT_CLINIC_ID] = validState

    window.store.set(CABINET_LIST_STORAGE_NAME, currentStore)

    return validState
  },

  _updateStore () {
    const currentStore = this._getStore()

    const state = {
      selectedSID: this.selectedSID,
      showUsersWithoutCabinets: this.showUsersWithoutCabinets,
      showSpecialtiesList: this.showSpecialtiesList,
      selectedCIDsInSID: this.selectedCIDsInSID,
    }

    currentStore[this.GET_SCHEDULE_CURRENT_CLINIC_ID] = state
    window.store.set(CABINET_LIST_STORAGE_NAME, currentStore)
  },
}
