<template>
  <div
    v-loading="loading"
    class="flex-form"
  >
    <div class="form-label">
      <label for="dental_company_company_id">
        {{ t('company') }}
      </label>
    </div>

    <div class="form-value field-with-btn f-field-container">
      <div class="field-with-btn_field">
        <div class="form-group">
          <validation-wrapper
            :errors="[]"
            fixed-height
          >
            <company-select
              id="dental_company_company_id"
              name="dental_company[company_id]"
              class="dental-companies-form__select"
              :disabled="!$security.canManageDentalCompany"
              :value="companyShortInfo"
              @change="$updateSync('companyShortInfo', $event)"
            />
          </validation-wrapper>
        </div>
      </div>
      <guarded-control
        tag="button"
        :permissions="['canManageCompany', 'canManageDentalCompany']"
        :tooltip="t('add_company')"
        type="button"
        class="btn btn-primary btn-xs hidden-print input-button input-button_full-height fad fa-building"
        @click:allowed="$emit('open-modal')"
      />
    </div>

    <div class="form-label">
      <label for="dental_company_title">
        {{ t('title') }}
      </label>

      <required-mark />
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationErrors.title"
        fixed-height
      >
        <el-input
          id="dental_company_title"
          type="text"
          name="dental_company[title]"
          clearable
          :disabled="!$security.canManageDentalCompany"
          :value="title"
          @input="$updateSync('title', $event)"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label for="dental_company_phone">
        {{ t('phone') }}
      </label>

      <required-mark />
      <explanation-mark :text="t('activerecord.explanation.text')" />
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationErrors.phone"
        fixed-height
      >
        <phone-input
          id="dental_company_phone"
          class="w-100"
          name="dental_company[phone]"
          clearable
          :disabled="!$security.canManageDentalCompany"
          :value="phone"
          @input="$updateSync('phone', $event)"
          @mask-settings-change="$emit('phone-mask-settings-change', $event)"
          @blur="$emit('phone-blur-input')"
        />
      </validation-wrapper>
    </div>
  </div>
</template>

<script>
import CompanySelect from '@/vue_components/common/select/company_select'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import RequiredMark from '@/vue_components/common/required_mark'
import PhoneInput from '@/vue_components/common/phone_input'
import GuardedControl from '@/vue_components/common/guarded_control'
import ExplanationMark from '@/vue_components/common/explanation_mark'

export default {
  name: 'DentalCompaniesForm',
  components: {
    CompanySelect,
    ValidationWrapper,
    RequiredMark,
    PhoneInput,
    GuardedControl,
    ExplanationMark,
  },

  props: {
    companyShortInfo: {
      type: Object,
      default: null,
    },

    title: {
      type: String,
      default: '',
    },

    phone: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: true,
    },

    validationErrors: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
