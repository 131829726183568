import { MListService } from '@/_api/_requests/MListService'
import { MTreatmentPlanPresenter } from '@/_api/MTreatmentPlan/MTreatmentPlanPresenter'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'

export class TreatmentPlansList extends MListService {
  /**
   * @param clientId
   * @param {{initialFilters?: Object, config?: Object}} optional
   */
  constructor (clientId, optional = {}) {
    super(
      { clientId, period: [], status: undefined, type: undefined, ...optional.initialFilters },
      new MTreatmentPlanPresenter(),
      { limit: DEFAULT_LIMIT, ...optional.config }
    )
  }

  exportToCSV () {
    const caption = [
      'Номер',
      'Тип',
      'Статус',
      'Дата',
      'Наименование',
      'Исполнитель',
      'Сумма',
    ].join(gon.application.csv_character)

    const rows = [
      ...this.data.map(({ id, kind, status, date, title, performer, finalSum }) => [
        id,
        t(kind, { scope: 'treatmentPlanTypes' }),
        t(status, { scope: 'treatmentPlanStatuses' }),
        date,
        title,
        performer?.shortName,
        Utils.toMoney(finalSum),
      ].join(gon.application.csv_character)),
    ].join('\n')

    const body = [caption, rows].join('\n')

    Utils.downloadExportFile(body, t('treatment_plans'))
  }
}
