<template>
  <si
    class="egisz-cases-tab"
  >
    <si-control class="egisz-cases-tab__controls">
      <div class="controls__buttons">
        <m-button
          disabled="Egisz"
          template="add"
          :text="t('egisz.medical_cases.new')"
          @click="onModalEgiszCaseOpen()"
        />
      </div>

      <div class="controls__filters margin-top-10">
        <date-range-picker
          class="filters__date-range"
          :date-range="filters[filtersFields.openedAt]"
          :format="getLocalizationDatepickerFormat"
          @change="vxSetFilterValue({ filter: filtersFields.openedAt, value: $event })"
        />

        <reusable-doctors-list
          :value="doctor"
          option-label="fullName"
          label=""
          @onDoctorChange="onDoctorChange"
        />

        <catalog-tree-modal
          id="nsi_specialty_id"
          :tree-data="getSpecialties"
          :value="filters[filtersFields.nsiSpecialtyId]"
          clearable
          @update:value="vxSetFilterValue({ filter: filtersFields.nsiSpecialtyId, value: $event })"
        />

        <el-checkbox
          class="mb-0 el-checkbox_big"
          :value="forCurrentUser"
          :label="t('mine')"
          border
          size="small"
          @change="onMineSet"
        />
      </div>

      <template #refresh>
        <span class="empty-slot" />
      </template>

      <template #reset>
        <span class="empty-slot" />
      </template>
    </si-control>

    <med-card-med-case-list
      v-if="medCases && medCases.length"
      v-loading="loading"
      class="egisz-cases-tab__table margin-top-10"
      :med-cases="medCases"
      :can-manage="canManage"
      @deleteCase="deleteCase"
      @editMedCase="onModalEgiszCaseOpen"
    />
    <not-found-result
      v-else
      :filtered="isFiltered"
      small
      @reset-filters="resetFilters"
    />

    <si-footer class="egisz-cases-tab__footer">
      <pagination
        layout="prev, pager, next"
        :page-count="totalPages"
        :current-page="currentPage"
        @update:currentPage="vxSetPage"
      />
    </si-footer>

    <modal
      :modal-visibility="modalEgiszCaseVisibility"
      :modal-class="'modal-dialog-fullscreen'"
      remove-footer
      @close="modalEgiszCaseClose"
    >
      <template #header>
        <i class="fad fa-fw fa-notes-medical" />
        {{ modalEgiszCaseTitle }}
      </template>

      <template #body>
        <egisz-case
          :client="client"
          :med-case-id="selectedMedCaseId"
          :can-manage="canManage"
          @close="modalEgiszCaseClose"
          @deleteCase="deleteCase"
          @updateList="loadList"
        />
      </template>
    </modal>
  </si>
</template>

<script>
import Si from '@/vue_components/sort_items/si'
import DateRangePicker from '@/vue_components/common/select/date_range_picker'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Modal from '@/vue_components/modal'
import EgiszCase from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { egiszCasesEndpoint } from '@/api_entities/egisz/cases/egisz_cases_endpoint'
import SiControl from '@/vue_components/sort_items/si_control'
import MedCardMedCaseList from '@/vue_components/egisz/egisz_medical_cases_tab/components/MedCardMedCaseList'
import SiFooter from '@/vue_components/sort_items/si_footer'
import Pagination from '@/vue_components/common/pagination'
import { CREATE_MED_CASE_INDEX } from '@/vue_components/egisz/egisz_medical_cases_tab/const'
import NotFoundResult from '@/vue_components/common/not_found_result'
import CatalogTreeModal from '@/vue_components/egisz/egisz_user_tab/components/CatalogTreeModal'
import { CASE_FILTERS_FIELDS, getDefaultMedCasesFilters } from '@/vue_components/egisz/egisz_module/const/egisz_filters'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default {
  name: 'EgiszMedicalCasesTab',

  components: {
    MButton,
    ReusableDoctorsList,
    CatalogTreeModal,
    NotFoundResult,
    SiFooter,
    MedCardMedCaseList,
    SiControl,
    EgiszCase,
    Modal,
    DateRangePicker,
    Si,
    Pagination,
  },

  mixins: [SpinnerHolder],

  props: {
    medicalRecordId: {
      type: Number,
      required: true,
      validator (value) { return value > 0 },
    },

    canManage: Boolean,
  },

  data () {
    return {
      medCases: [],
      client: gon.specific.client,

      selectedMedCaseId: null,
      modalEgiszCaseVisibility: false,

      filtersFields: {
        openedAt: CASE_FILTERS_FIELDS.OPENED_AT,
        nsiSpecialtyId: CASE_FILTERS_FIELDS.NSI_SPECIALTY_ID,
        doctorId: CASE_FILTERS_FIELDS.DOCTOR_ID,
      },

      doctor: null,
    }
  },

  computed: {
    ...mapGetters({
      getLocalizationDatepickerFormat: 'GET_LOCALIZATION_DATEPICKER_FORMAT',
    }),

    ...mapGetters('medCases/filtersBase', {
      filters: 'vxGetFilters',
      isFiltered: 'vxGetIsFiltered',
      currentPage: 'vxGetCurrentPage',
      totalPages: 'vxGetTotalPages',
    }),

    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctorsInCurrentClinic: 'getDoctorsInCurrentClinic',
    }),

    ...mapGetters('medCases', {
      forCurrentUser: 'forCurrentUser',
      getSpecialties: 'GET_SPECIALTIES',
      getCaseCatalogsLoaded: 'GET_CASE_CATALOGS_LOADED',
    }),

    modalEgiszCaseTitle () {
      return this.selectedMedCaseId
        ? t('egisz.medical_cases.edit')
        : t('egisz.medical_cases.new')
    },
  },

  created () {
    this.loadCatalogs()
    this.resetFilters()
    this.vxSetFilterValue({
      filter: CASE_FILTERS_FIELDS.CLIENT_ID,
      value: this.client && this.client.id,
    })

    this.$watch(
      'filters',
      () => { this.loadList() },
      { immediate: true }
    )
  },

  methods: {
    ...mapMutations('medCases/filtersBase', {
      vxResetFilters: 'vxResetFilters',
      vxSetFilterValue: 'vxSetFilterValue',
      vxSetPage: 'vxSetPage',
      vxSetTotalPages: 'vxSetTotalPages',
    }),

    ...mapActions('medCases', {
      loadCatalogs: 'loadCatalogs',
      loadCaseCatalogs: 'loadCaseCatalogs',
    }),

    ...mapActions('globalCatalogs/doctorStore', {
      vxFetchDoctors: 'loadDoctors',
    }),

    resetFilters () {
      this.onDoctorChange()
      this.vxResetFilters(getDefaultMedCasesFilters())
    },

    loadList () {
      const promise = egiszCasesEndpoint.getAll({
        ...this.filters,
        medical_record_id: this.medicalRecordId,
        client_id: this.client.id,
      })
        .then(({ data, total_pages: totalPages }) => {
          this.medCases = data
          this.vxSetTotalPages(totalPages)
        })
        .catch(Utils.reportError(
          'EgiszMedicalCasesTab:loadList()',
          reportErrorText('list_message', 'med_cases')
        ))

      this.withSpinner(promise)
    },

    deleteCase (medCaseId) {
      const promise = egiszCasesEndpoint.destroy(medCaseId)
        .then(() => {
          this.medCases = this.medCases.filter((item) => item.id !== medCaseId)
          this.modalEgiszCaseClose()
        })
        .catch((err) => {
          if (Array.isArray(err.responseJSON)) {
            Utils.reportError(
              'EgiszMedicalCasesTab:deleteCase()',
              err.responseJSON.join(', ')
            )()

            return
          }
          Utils.reportError(
            'EgiszMedicalCasesTab:deleteCase()',
            reportErrorText('delete_message', 'med_case')
          )(err)
        })

      this.withSpinner(promise)
    },

    onModalEgiszCaseOpen (medCaseId = CREATE_MED_CASE_INDEX) {
      this.vxFetchDoctors()
      this.loadCatalogsForMedCase()
      this.setSelectedMedCase(medCaseId)
      this.modalEgiszCaseOpen()
    },

    loadCatalogsForMedCase () {
      if (this.getCaseCatalogsLoaded) { return }
      this.loadCaseCatalogs()
    },

    setSelectedMedCase (medCaseId = null) {
      if (!medCaseId) { return }
      this.selectedMedCaseId = medCaseId
    },

    modalEgiszCaseOpen () {
      this.modalEgiszCaseVisibility = true
    },

    modalEgiszCaseClose (successSubmit = false) {
      this.modalEgiszCaseVisibility = false
      this.selectedMedCaseId = null

      if (successSubmit === true) {
        this.loadList()
      }
    },

    onDoctorChange (doctor) {
      this.doctor = doctor
      this.vxSetFilterValue({ filter: this.filtersFields.doctorId, value: doctor?.id || null })
    },

    onMineSet (isMine = false) {
      const currentUser = gon.application.current_user
      const me = isMine
        ? { id: currentUser.id, fullName: currentUser.full_name }
        : null

      this.onDoctorChange(me)
    },
  },
}
</script>
