import { MListService } from '@/_api/_requests/MListService'
import { MEntryTypesTransferPresenter } from '@/_api/EntryTypes/MEntryTypesTransfer/MEntryTypesTransferPresenter'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'

export class EntryTypesTransferList extends MListService {
  constructor ({ clientId }) {
    super(
      { clientId },
      new MEntryTypesTransferPresenter(),
      { limit: DEFAULT_LIMIT }
    )
  }

  resetErrors (errors = []) {
    errors.forEach((error) => {
      const [[id, value]] = Object.entries(error)
      const entryType = this.data.find((item) => item.id === parseInt(id))
      entryType.resetErrors(value.join())
    })
  }

  setDuplicated (newDuplicated = []) {
    newDuplicated.forEach((entryTypeId) => {
      const entryType = this.data.find((item) => item.id === entryTypeId)
      if (!entryType) { return }
      entryType.setDuplicated()
    })
  }
}
