const FIRED_INDEX = 1 // data-select-index

export const subscribeToUserStatus = () => {
  const wrapper = $('.user_user_status_id')
  if (!wrapper) { return }

  const statusButtons = wrapper.find('.select2buttons-option').toArray()
  if (!statusButtons.length) { return }

  const allowEntryRow = $('.user_allow_sign_in').parents('tr')
  if (!allowEntryRow) { return }

  statusButtons.forEach((item) => {
    item.addEventListener('click', ({ target }) => {
      parseInt(target.dataset.selectIndex) === FIRED_INDEX
        ? allowEntryRow.hide()
        : allowEntryRow.show()
    })
  })
}
