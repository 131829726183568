<template>
  <div class="clients-documents__filters">
    <m-button
      type="success"
      icon="copy"
      plus-icon
      :tooltip="t('add_document')"
      @click="onAddButtonClick"
    />

    <m-date-picker
      :value="filters.period"
      :label="t('period')"
      type="daterange"
      value-format="yyyy-MM-dd"
      :m-fixed-height="false"
      @change="onFilterChange('period', $event)"
    />

    <m-select-lazy
      :value="filters.type"
      :label="t('all_documents')"
      :placeholder="t('choose')"
      :disabled="filters.kind === TAX_CERTIFICATE2024_ID"
      :m-fixed-height="false"
      :fetch-method="(params) => documentPresenter.fetchDocumentTypes(params)"
      use-first-time-fetch
      use-custom-result="simple"
      filterable
      @change="onFilterChange('type', $event)"
    />

    <m-select
      :value="filters.kind"
      :label="t('all_document_kinds')"
      :items="documentKindItems"
      :placeholder="t('choose')"
      :m-fixed-height="false"
      use-custom-result="simple"
      @change="onKindChange($event)"
    />
  </div>
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import {
  DOCUMENT_KIND_ITEMS,
  TAX_CERTIFICATE2024_ID,
} from '@/vue_apps/ClientsModule/components/Documents/consts/consts'
import {
  MDocumentsPresenter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/MDocumentsPresenter'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'

export default {
  name: 'DocumentFilters',

  components: { MSelectLazy, MDatePicker, MButton, MSelect },

  props: {
    list: { type: Object, required: true },
  },

  emits: ['updateList'],

  data () {
    return {
      documentPresenter: new MDocumentsPresenter(),
      TAX_CERTIFICATE2024_ID,
      documentKindItems: DOCUMENT_KIND_ITEMS,
    }
  },

  computed: {
    filters () {
      return this.list.filtersData
    },
  },

  methods: {
    onKindChange (value) {
      this.list.setFilterValue('kind', value)

      this.$emit('updateList', value === TAX_CERTIFICATE2024_ID
        ? DOCUMENT_TYPES.CERTIFICATE_PAYMENT
        : DOCUMENT_TYPES.OLD_DOCUMENT
      )
    },

    onFilterChange (name, value) {
      this.list.setFilterValueWithFetchAll(name, value)
    },

    onAddButtonClick () {
      DocumentForm.show()
    },
  },
}
</script>
