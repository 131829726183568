import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class SalaryByPaymentsDetailedReportDoctorItem {
  /** @param {TSalaryByPaymentsDetailedDoctor} doctor */
  constructor (doctor) {
    this.id = doctor.doctorId
    this.title = doctor.title
  }

  get tableTitle () {
    return new MTableCell(this.title)
      .addClasses('bold')
      .addTooltip(this.title)
  }

  get tableDate () {
    return new MTableCell()
  }

  get tableOrder () {
    return new MTableCell()
  }

  get tableOrderDate () {
    return new MTableCell()
  }

  get tableEntrySum () {
    return new MTableCell()
  }

  get tableEntryFinalSum () {
    return new MTableCell()
  }

  get tableAmountInEntry () {
    return new MTableCell()
  }

  get tableLastPayment () {
    return new MTableCell()
  }

  get tableLastPaymentDate () {
    return new MTableCell()
  }

  get tableLastPaymentTotalPaid () {
    return new MTableCell()
  }

  get tableLastPaymentKind () {
    return new MTableCell()
  }

  get tableLastPaymentCashier () {
    return new MTableCell()
  }

  get tableSum () {
    return new MTableCell()
  }

  get tableRateType () {
    return new MTableCell()
  }

  get tableSalary () {
    return new MTableCell()
  }
}
