import { request } from '@/lib/transport/request'
import { dentalsAdapter, setDataAdapter } from './dental_companies_adapter'

export const dentalCompaniesEndpoint = {
  getAll (sort_params) {
    const options = {
      type: 'POST',
      url: Routes.catalogs_dental_companies_list_path(),
      data: {
        sort_params,
      },
    }

    return request(options).promise
  },

  get (id) {
    const options = {
      type: 'GET',
      url: Routes.dental_company_path(id),
    }

    return request(options, dentalsAdapter.toClient)
  },

  update (data) {
    const options = {
      type: 'PATCH',
      url: Routes.dental_company_path(data.id),
      data: setDataAdapter.toServer(data),
    }

    return request(options).promise
  },

  create (data) {
    const options = {
      type: 'POST',
      url: Routes.dental_companies_path(),
      data: setDataAdapter.toServer(data),
    }

    return request(options).promise
  },

  destroy (id) {
    const options = {
      type: 'DELETE',
      url: Routes.dental_company_path(id),
    }

    return request(options).promise
  },
}
