export const assignBonusPoints = (params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      url: Routes.assign_bonus_points_clients_path(),
      data: params,
      success: resolve,
      error: reject,
    })
  })
}

export const getCurrentBonusPoints = (params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.fetch_bonus_points_clients_path(),
      data: params,
      success: resolve,
      error: reject,
    })
  })
}
