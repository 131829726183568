<template>
  <m-si-generator
    v-loading="list.loading"
    :si-generator-schema="schema"
    :items="list.data"
    :page-count="list.totalPages"
    :current-page="list.currentPage"
    @update:currentPage="list.setPage($event)"
    @onItemClick="onItemClick"
    @onItemEdit="onItemEdit"
    @onItemDelete="onItemDelete"
  >
    <template #add>
      <attachments-tab-hyperlink-modal
        :client-id="clientId"
        @openModalWithFetch="openModalWithFetch = $event"
        @updateHyperlinksList="list.fetchAll()"
        @updateFilesCount="$emit('updateFilesCount')"
      />
    </template>
  </m-si-generator>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import {
  getAttachmentTabHyperlinkSchema,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/consts/attachmentTabHyperlinkSchema'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import {
  AttachmentTabHyperlinkItem,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/classes/AttachmentTabHyperlinkItem'
import AttachmentsTabHyperlinkModal
  from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/components/AttachmentsTabHyperlinkModal.vue'
import {
  AttachmentTabHyperlinkApi,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/classes/AttachmentTabHyperlinkApi'

export default defineComponent({
  name: 'AttachmentsTabHyperlinks',

  components: { AttachmentsTabHyperlinkModal, MSiGenerator },

  props: {
    clientId: { type: Number, required: true },
  },

  emits: ['updateFilesCount'],

  data () {
    return {
      list: null as MListServiceApi<AttachmentTabHyperlinkItem, { clientId: number }> | null,
      schema: getAttachmentTabHyperlinkSchema(),
    }
  },

  created () {
    this.list = new MListServiceApi({
      src: Routes.list_api_internal_files_hyperlinks_path(),
      DataItemEntity: AttachmentTabHyperlinkItem,
      defaultFilters: {
        clientId: this.clientId,
      },
    })

    this.list.fetchAll()
  },

  methods: {
    // Влад, не удаляй оно юзается)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    openModalWithFetch (id: number) {},

    onItemClick ({ link }: AttachmentTabHyperlinkItem) {
      Utils.openInNewTab(link)
    },

    onItemEdit ({ id }: AttachmentTabHyperlinkItem) {
      this.openModalWithFetch(id)
    },

    async onItemDelete ({ id }: AttachmentTabHyperlinkItem) {
      if (await new AttachmentTabHyperlinkApi().destroy(id)) { return }

      this.$emit('updateFilesCount')
      await this.list.fetchAll()
    },
  },
})
</script>
