<template>
  <div class="entries-tab__filters-wrapper">
    <div class="entries-tab__filters">
      <m-date-picker
        v-model="vmDate"
        class="entries-tab__filter entries-tab__filter_date"
        type="daterange"
        :m-fixed-height="false"
        value-format="yyyy-MM-dd"
      />

      <reusable-doctor-clinics-list
        v-model="vmClinic"
        class="entries-tab__filter entries-tab__filter_clinics"
        :m-fixed-height="false"
        label=""
      />

      <m-select
        v-model="vmKind"
        :items="kinds"
        class="entries-tab__filter entries-tab__filter_kinds"
        :placeholder="t('all_kinds')"
        :m-fixed-height="false"
      />

      <m-select-lazy
        v-model="vmSpecialty"
        class="entries-tab__filter entries-tab__filter_specialties"
        :fetch-method="(params) => specialtiesPresenter.list(params)"
        :m-fixed-height="false"
        :placeholder="t('all_specialties')"
        use-first-time-fetch
        use-first-time-fetch-on-open
        filterable
      />

      <reusable-doctors-list
        v-model="vmPerformer"
        class="entries-tab__filter entries-tab__filter_performers"
        :m-fixed-height="false"
        label=""
        :placeholder="t('all_doctors')"
      />

      <m-checkbox
        v-model="vmMine"
        class="entries-tab__filter entries-tab__filter_mine"
        :label="t('mine')"
        border
      />

      <m-si-generator-filters-buttons
        :disabled="list.loading"
        @onRefreshClick="list.fetchAll()"
        @onResetClick="list.resetWithFetch()"
      />
    </div>

    <teeth-selector
      v-if="isDentBranch && dentalUiRequired"
      v-model="vmTeethIds"
    />
  </div>
</template>

<script>

import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import ReusableDoctorClinicsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorClinicsList.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import MSiGeneratorFiltersButtons
  from '@/vue_present/_base/Tables/MSiGenerator/components/MSiGeneratorFiltersButtons.vue'
import { MListService } from '@/_api/_requests/MListService'
import {
  getEntryTypesKinds,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/const/getEntryTypesKinds'
import { MSpecialtyPresenter } from '@/_api/MSpecialty/MSpecialtyPresenter'
import TeethSelector from '@/vue_present/Reuse/Teeth/TeethSelector/TeethSelector.vue'

const localBinding = (filter) => ({
  get () { return this.list.filtersData[filter] },
  set (value) { this.list.setFilterValueWithFetchAll(filter, value) },
})

export default {
  name: 'EntriesTabFilters',
  components: {
    TeethSelector,
    MSiGeneratorFiltersButtons,
    MCheckbox,
    ReusableDoctorsList,
    MSelectLazy,
    MSelect,
    ReusableDoctorClinicsList,
    MDatePicker,
  },

  props: {
    list: MListService,
    isDentBranch: Boolean,
  },

  data () {
    return {
      kinds: getEntryTypesKinds(),
      specialtiesPresenter: new MSpecialtyPresenter(),
    }
  },

  computed: {
    vmDate: localBinding('date'),
    vmClinic: localBinding('clinic'),
    vmKind: localBinding('kind'),
    vmSpecialty: localBinding('specialty'),
    vmPerformer: localBinding('performer'),
    vmTeethIds: localBinding('teethIds'),
    vmMine: localBinding('mine'),

    dentalUiRequired () {
      return gon.specific.dentalUiRequired && gon.application.ui.dent
    },
  },
}
</script>
