import { MListService } from '@/_api/_requests/MListService'
import { getDefaultFilters } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/const/getDefaultFilters'
import { MEntryPresenter } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/api/MEntryPresenter'
import { GLOBAL_ENTRY_STATE } from '@/_global_scripts/GLOBAL_CONSTS'

export class EntriesList extends MListService {
  /**
   * @param {{
   *   clientId: number,
   * }} defaults
   */
  constructor (defaults) {
    super(
      getDefaultFilters(),
      new MEntryPresenter(),
      { cacheKey: 'EMKEntriesCache' }
    )

    this.clientId = defaults.clientId
    this.__setFilterDataClientId()
  }

  __setFilterDataClientId () {
    this.filtersData.clientId = this.clientId
  }

  resetFilters (filters = this.__initialFilters) {
    super.resetFilters(filters)
    this.__setFilterDataClientId()
  }

  /**
   * @param {EntriesListItem} item
   */
  async changeState (item) {
    const newState = item.state === GLOBAL_ENTRY_STATE.READY
      ? GLOBAL_ENTRY_STATE.NOT_READY
      : GLOBAL_ENTRY_STATE.READY

    /** @type {MEntryPresenter} */
    const presenter = this.__presenter

    return presenter.changeState(item.id, newState)
      .then((data) => {
        item.setState(newState)

        return data
      })
  }

  async delete (id) {
    return this.__presenter.destroy(id)
  }
}
