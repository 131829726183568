import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getSalaryByPaymentsDetailedSchema = () =>
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addFooter()
        .addHeaders({
          tableTitle: new MTableHeader(t('title')).addFixedWidth('250px').addTooltip(t('title')),
          tableDate: new MTableHeader(t('reports.entryDate')).addFixedWidth('110px').addTooltip(t('reports.entryDate')),
          tableOrder: new MTableHeader(t('reports.order')).addFixedWidth('80px').addTooltip(t('reports.order')),
          tableOrderDate: new MTableHeader(t('reports.orderDate')).addFixedWidth('110px').addTooltip(t('reports.orderDate')),
          tableEntryFinalSum: new MTableHeader(t('reports.priceWithDiscount')).addTooltip(t('reports.priceWithDiscount')),
          tableAmountInEntry: new MTableHeader(t('reports.amount')).addFixedWidth('70px').addTooltip(t('reports.amountInOrder')),
          tableLastPayment: new MTableHeader(t('reports.payment')).addFixedWidth('70px').addTooltip(t('reports.lastPayment')),
          tableLastPaymentDate: new MTableHeader(t('reports.lastPaymentDate')).addFixedWidth('110px').addTooltip(t('reports.lastPaymentDate')),
          tableLastPaymentTotalPaid: new MTableHeader(t('reports.lastPaymentTotalPaid')).addTooltip(t('reports.lastPaymentTotalPaid')),
          tableLastPaymentKind: new MTableHeader(t('reports.kind')).addFixedWidth('55px').addTooltip(t('reports.lastPaymentKind')),
          tableLastPaymentCashier: new MTableHeader(t('reports.lastPaymentUser')).addTooltip(t('reports.lastPaymentUser')),
          tableSum: new MTableHeader(t('reports.sum')).addTooltip(t('reports.sum')),
          tableRateType: new MTableHeader(t('reports.rate')).addTooltip(t('reports.rate')),
          tableSalary: new MTableHeader(t('reports.salary')).addTooltip(t('reports.salary')),
        })
    )
