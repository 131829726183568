<template>
  <el-popover
    v-model="visible"
    popper-class="dark dark-top menu small"
    trigger="click"
  >
    <button
      slot="reference"
      class="btn btn-grey-dark btn-xs tooltip-bottom"
    >
      <i class="fad fa-print" />
    </button>

    <el-menu
      background-color="#475264"
      text-color="#FFFFFF"
      active-text-color="#FFFFFF"
    >
      <el-menu-item
        :disabled="printBarcodeBtnDisabled"
        @click="close('print-barcode')"
      >
        <i class="fad fa-print mr-5" />
        <span>{{ t('laboratories.print_barcode') }}</span>
      </el-menu-item>

      <span
        v-if="useLabeller"
        v-tooltip="{ tooltip: printLabellerBtnDisabledMap.tooltip, placement: 'bottom' }"
      >
        <el-menu-item
          :disabled="printLabellerBtnDisabledMap.disabled"
          @click="close('print-labeller')"
        >
          <i class="fad fa-print mr-5" />
          <span>{{ t('laboratories.send_to_labeller') }}</span>

        </el-menu-item>
      </span>
    </el-menu>
  </el-popover>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'BiomaterialPrintList',
  props: {
    printBarcodeBtnDisabled: Boolean,
    printLabellerBtnDisabledMap: PropsTypes.Object(),
    useLabeller: Boolean,
  },

  data () {
    return {
      visible: false,
    }
  },

  methods: {
    close (eventName) {
      this.visible = false
      this.$emit(eventName)
    },
  },
}
</script>
