import { REQUEST_KEY } from './const'

export const discountAdapter = {
  toServer (data) {
    return {
      [REQUEST_KEY]: {
        ...data,
      },
    }
  },
}
