export default {
  clientId: {
    type: Number,
    default: null,
  },
  medicalRecordId: {
    type: Number,
    default: null,
  },
  paidSum: {
    type: Number,
    required: true,
  },
  creditDebt: {
    type: Number,
    required: true,
  },
  lastVisit: {
    type: String,
    required: true,
  },
  previousAppointmentFailed: {
    type: Boolean,
    required: true,
  },
  lastVisitToTheSameDoctorDaysAgo: {
    type: String,
    default: '',
  },
  lastVisitToTheSameDoctor: {
    type: String,
    default: '',
  },
  /**
   * Объект, в котором ключи это токены настроек рассылки для i18n, а значения boolean
   *
   * @type {import('vue').PropOptions<string[]>}
   */
  advDistribution: {
    type: Array,
    required: true,
  },
  /**
   * @type {import('vue').PropOptions<{id: number, title: string}[]>}
   */
  clientGroups: {
    type: Array,
    required: true,
  },

  activeMedicalPoliciesTitles: String,
}
