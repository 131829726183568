import { clientsEndpoint } from '@/api_entities/clients/clients_endpoint'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'
import {
  CREDIT_DEBT_DEFAULT_VALUE,
  getEntriesWithoutOrderDefaults,
} from '@/vue_components/doctor_schedules/AdministratorOverpopIcons/conts'

export const clientsPresenter = {
  fetchCreditDebtValue (clientId) {
    return clientsEndpoint.fetchCreditDebtValue(clientId)
      .then((data) => Promise.resolve(data.credit_debt))
      .catch((err) => {
        Utils.reportError(
          'clientsPresenter::fetchCreditDebtValue()',
          reportErrorText('read_message', 'credit_debt_value')
        )(err)

        return Promise.resolve(CREDIT_DEBT_DEFAULT_VALUE)
      })
  },

  fetchEntriesWithoutOrderInfo (clientId) {
    return clientsEndpoint.fetchEntriesWithoutOrderInfo(clientId)
      .catch((err) => {
        Utils.reportError(
          'clientsPresenter::fetchEntriesWithoutOrderInfo()',
          reportErrorText('read_message', 'credit_debt_value')
        )(err)

        return Promise.resolve(getEntriesWithoutOrderDefaults())
      })
  },

  fetchLastEmkNumber () {
    return clientsEndpoint.fetchLastEmkNumber().promise
      .then((data) => Promise.resolve(data.number))
      .catch((err) => {
        Utils.reportError(
          'clientsPresenter::fetchLastEmkNumber()',
          reportErrorText('read_message', 'lastEmkNumber')
        )(err)

        return Promise.resolve(t('error'))
      })
  },
}
