import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export const props = {
  clinic: { type: Object, required: true },
  period: { type: String, default: '' },
  entryKind: { type: Object, required: true },
  entryWithOrder: { type: Object, required: true },
  entryWithDiscount: { type: Object, required: true },
  doctor: { type: Object, required: true },
  administrator: { type: Object, required: true },
  entryTypes: { type: Array, required: true },
  entryTypeCategories: { type: Array, required: true },
  client: { type: Object, required: true },
  clientGroups: { type: Array, required: true },
  clientSex: { type: Object, required: true },
  clientStatus: { type: Object, required: true },
  doctorSpecialtiesIds: PropsTypes.Array(undefined, true),

  doctorAreaModuleEnabled: { type: Boolean, default: true },
  workplacesEnabled: PropsTypes.Boolean(),
  moneySymbol: PropsTypes.String(undefined, true),
}
