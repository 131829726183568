/**
 * Создано для контроля отображения поля "Отправлять информацию по клиенту" для анализов в Инвитро с коронавирусом
 * setDefaultCovidFields - вызывается при открытии форм просмотра и редактирования
 * и на основе данных из entry_type отображает/скрывает поле
 * subscribeToLaboratoryField - подписывается на нажатие кнопок с лабораториями
 * и показывает поле covid_entry, если выбрана Инвитро
 */

const INVITRO_LAB = t('invitro')

const covidFieldsVisible = (covidFields, visible = false) => {
  visible
    ? covidFields.show()
    : covidFields.hide()
}

export const setDefaultCovidFields = (item) => {
  const covidFields = $('.covid-entry-visible')

  if (item.analysis_laboratory === null) { return covidFieldsVisible(covidFields, false) }

  const visibility = item.analysis_laboratory.title === INVITRO_LAB

  covidFieldsVisible(covidFields, visibility)
}

export const subscribeToLaboratoryField = () => {
  let isInvitro = false

  const covidFields = $('.covid-entry-visible')

  const wrapper = $('.entry_type_analysis_laboratory')
  if (!wrapper) { return }

  setDefaultCovidFields({ analysis_laboratory: null })

  const laboratoryButtons = wrapper.find('.select2buttons-option').toArray()
  if (!laboratoryButtons.length) { return }

  laboratoryButtons.forEach((item) => {
    item.addEventListener('click', ({ target }) => {
      isInvitro = target.innerText === INVITRO_LAB
      covidFieldsVisible(covidFields, isInvitro)
    })
  })
}
