import { createVueApp } from '@/vue_components/create_vue_app'
import { createStore } from '@/vue_components/store/store'

let vueApp = null
export const createFilters = (renderedComponent, options = {}) => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el: '#vue_report_filters_root',
    name: 'ReportRoot',
    store: createStore(),
    render: (h) => h(renderedComponent, options),
  })
}
