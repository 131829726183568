<template>
  <base-select
    class="si-control-item"
    :reset-value="1"
    :value="$store.getters.GET_SCHEDULE_CURRENT_CLINIC_ID"
    @change="onItemClick"
  >
    <el-option
      v-for="clinic in clinics"
      :key="clinic.id"
      :value="clinic.id"
      :label="clinic.title"
    />
  </base-select>
</template>

<script>
import BaseSelect from '@/vue_components/common/select/base_select/base_select.vue'
import { MODE_CLONE, MODE_MOVE } from '@/plugins/schedule/scheduleCommon/const'

export default {
  components: { BaseSelect },

  props: {
    clinics: {
      type: Array,
      default: () => ([]),
    },
  },

  methods: {
    onItemClick (id) {
      this.$store.dispatch('setScheduleCurrentClinicId', id)

      const modeGridSchedule = this.$store.getters.GET_MODE_GRID_SCHEDULE
      if (modeGridSchedule === MODE_MOVE || modeGridSchedule === MODE_CLONE) { return }

      this.$store.dispatch('changeScheduleClinic')
    },
  },
}
</script>
