import { ReportParamFactory } from '@/specific/reports/shared'

export default function (source) {
  return {
    clinicIds: ReportParamFactory.buildArrayParam(source.clinic),
    insuranceCompanyIds: ReportParamFactory.buildArrayParam(source.insuranceCompany),
    companyIds: ReportParamFactory.buildArrayParam(source.company),
    medicalPolicyTypes: ReportParamFactory.buildArrayParam(source.medicalPolicyType),
  }
}
