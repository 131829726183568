<template>
  <el-popover
    ref="popover"
    class="position-fixed"
    :value="visible"
    :width="shiftX"
    :close-delay="1"
  >
    <div
      class="flex justify-content-between position-relative"
      style="top: -5px"
    >
      <div>
        <span v-if="source">
          {{ t(source, { scope: 'reception.call_log.popupTitles' }) }}
        </span>
      </div>

      <m-icon
        v-tooltip="t('close')"
        class="pointer"
        icon="close"
        color="grey-dark"
        @click="$emit('close')"
      />
    </div>
    <slot />
  </el-popover>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

const DEFAULT_SHIFT_X = 350
const Y_GAP = 15
const X_GAP = 10

export default {
  name: 'AppointmentCallLogPopup',
  components: { MIcon },
  props: {
    visible: Boolean,
    source: PropsTypes.String(),

    /** @type {TAppointmentCallLogPopupPosition} */
    position: PropsTypes.Object(),
    shiftX: PropsTypes.Number(DEFAULT_SHIFT_X),
  },

  watch: {
    position ({ x, y }) {
      const popover = this.$refs.popover
      if (!popover) { return }

      /**
       * 🩼🩼🩼
       * Не успевает смонтироваться, поэтому $nextTick
       */
      this.$nextTick(() => {
        const popoverHeight = popover.$el.firstChild?.clientHeight
        const { clientHeight } = document.documentElement
        const fixedY = popoverHeight + y + Y_GAP >= clientHeight
          ? clientHeight - popoverHeight - Y_GAP
          : y

        popover.$el.style.left = `${x - this.shiftX - X_GAP}px`
        popover.$el.style.top = `${fixedY}px`
      })
    },
  },
}
</script>
