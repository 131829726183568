<template>
  <el-date-picker
    v-model="value"
    type="daterange"
    align="right"
    unlink-panels
    size="small"
    range-separator="-"
    start-placeholder=""
    end-placeholder=""
    :format="dateFormat"
    :picker-options="pickerOptions"
  />
</template>

<script>

import CardSelector from './selector.js'
import { toDateString } from '@/vue_components/head_desktop/helpers'

export default {
  mixins: [CardSelector],
  data () {
    return {
      pickerOptions: {
        shortcuts: Utils.elDatePickerDefaultRanges(),
        firstDayOfWeek: 1,
      },

      dateFormat: Utils.elDatePickerDateFormat,
      key: 'date_range',
    }
  },
  methods: {
    valueAdapter (value) {
      return [
        moment(value[0]),
        moment(value[1]),
      ].map(toDateString)
    },
  },
}
</script>
