import { entryTypesKindsIcons } from '@/modules/utils/medods_ui/entryTypesKindsIcons'

export const mCategoriesEntryTypesAdapter = {
  /**
   * @param {TreeNestedCategories} data
   */
  toClient (data) {
    return [
      ...data.catalogs,
      ...data.items.map((item) => ({ ...item, icon: entryTypesKindsIcons[item.kind] })),
    ]
  },
}
