<template>
  <el-input
    class="custom-emk-input-deprecated"
    :label="t('emk_id')"
    :value="patientCardNumber"
    :disabled="disabled"
    @input="$emit('input', $event)"
  >
    <template #append>
      <span v-tooltip="t('utils_report_error.entities.lastEmkNumber')">{{ lastEmkNumber }}</span>
    </template>
  </el-input>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { clientsPresenter } from '@/api_entities/clients/clientsPresenter'

export default {
  name: 'CustomEmkInputDeprecated',

  props: {
    patientCardNumber: PropsTypes.Custom([String, Number]),
    disabled: Boolean,
  },

  data () {
    return {
      lastEmkNumber: null,
    }
  },

  mounted () {
    this.wsConnect()
    this.fetchLastEmkNumber()
  },

  methods: {
    wsConnect () {
      Services.wsSubscriptions.emkLast.connect((emkNumber) => {
        if (emkNumber) { this.fetchLastEmkNumber() }
      })
    },

    async fetchLastEmkNumber () {
      this.lastEmkNumber = await clientsPresenter.fetchLastEmkNumber()
    },
  },
}
</script>
