<template>
  <si-generator
    class="w-100"
    :table-schema="tableSchema"
    :items="vxRecordingPoints"
  >
    <!-- Фильтры -->
    <template #control>
      <appointment-time-finder-filters
        :doctor-schedules-clinic-id="doctorSchedulesClinicId"
        :loading="loading"
        @filtersChanged="onFilterChanged"
      />
    </template>

    <!-- Дата -->
    <template
      v-for="item in vxRecordingPoints"
      #tr-formattedDate="{ item }"
    >
      <div :key="`itemId:${item.id}`">
        <span :class="item.dateCss">
          {{ item.formattedDate }}
        </span>
        {{ item.time }}
      </div>
    </template>

    <!-- Кнопки для работы с расписанием -->
    <template
      v-for="item in vxRecordingPoints"
      #tr-scheduleActions="{ item }"
    >
      <recording-point-schedule-actions
        :key="item.id"
        :item="item"
        :doctor-schedules-clinic-id="vxScheduleClinicId"
        @showRecordingPointInSchedule="showRecordingPointInSchedule(item, $event)"
        @createAppointmentInSchedule="createAppointmentInSchedule(item, $event)"
      />
    </template>

    <!-- Заглушка об отсутствии записей -->
    <template #not-found-result>
      <div class="recording-points-no-data">
        <not-found-result filtered>
          <template #action>
            <span />
          </template>
        </not-found-result>
      </div>
    </template>

    <!-- Пагинация вынесена в $parent -->
    <template #footer-pagination>
      <span />
    </template>
  </si-generator>
</template>

<script>
import SiGenerator from '@/vue_components/sort_items/SiGenerator/SiGenerator.vue'
import { appointmentTimeFinderTableSchema } from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/const/const'
import AppointmentTimeFinderFilters
  from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/AppointmentTimeFinderFilters.vue'
import { mapActions, mapGetters } from 'vuex'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import NotFoundResult from '@/vue_components/common/not_found_result.vue'
import RecordingPointScheduleActions
  from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/components/RecordingPointScheduleActions.vue'

export default {
  name: 'AppointmentTimeFinder',
  components: { RecordingPointScheduleActions, NotFoundResult, AppointmentTimeFinderFilters, SiGenerator },
  mixins: [SpinnerHolder],

  props: {
    doctorSchedulesClinicId: { type: Number, required: true },
  },

  computed: {
    ...mapGetters('appointmentTimeFinder', {
      vxRecordingPoints: 'vxGetRecordingPoints',
    }),

    ...mapGetters({
      vxScheduleClinicId: 'GET_SCHEDULE_CURRENT_CLINIC_ID',
    }),

    tableSchema () { return appointmentTimeFinderTableSchema },
  },

  methods: {
    ...mapActions('appointmentTimeFinder', {
      vxSearchRecordingPoints: 'vxSearchRecordingPoints',
    }),

    onFilterChanged (filters) {
      this.withSpinner(this.vxSearchRecordingPoints(filters))
    },

    scheduleActions (recordingPoint, isNotCurrentClinic, eventName) {
      if (isNotCurrentClinic) {
        this.$store.dispatch('setScheduleCurrentClinicId', recordingPoint.clinicId)
      }

      this.$emit(eventName, recordingPoint)
      this.$emit('close')
    },

    showRecordingPointInSchedule (recordingPoint, isNotCurrentClinic) {
      this.scheduleActions(recordingPoint, isNotCurrentClinic, 'showRecordingPointInSchedule')
    },

    createAppointmentInSchedule (recordingPoint, isNotCurrentClinic) {
      this.scheduleActions(recordingPoint, isNotCurrentClinic, 'createAppointmentInSchedule')
    },
  },
}
</script>
