import { doctorAppointmentsReportFiltersSelectors as selectors } from './filtersSelectors'
import { IDoctorAppointmentsReportFiltersSet } from './interfaces'
import { extractId } from '@/_medods_standart_library/msl'
import { LegacyFormSetters } from '@/helpers/LegacyFormSetters'
import { _getDoctorAppointmentsReportFilters } from './_filtersGetter'
import { getDoctorAppointmentsReportInitialFilters as getInitialFilters } from './initialFilters'

export const doctorAppointmentsReportFiltersAccessors = {
  get: _getDoctorAppointmentsReportFilters,

  set (filtersToSet: IDoctorAppointmentsReportFiltersSet): void {
    const filters = { ...getInitialFilters(), ...filtersToSet }

    LegacyFormSetters.setDateRangePickerValue(selectors.period, filters.period)
    LegacyFormSetters.setSelectValue(selectors.clinic, filters.clinic.id)
    LegacyFormSetters.setMultipleSelect2Value(selectors.clientGroups, filters.clientGroups.map(extractId) as string[])
    LegacyFormSetters.setLazySelect2Value(selectors.entryTypes, filters.entryTypes.id, filters.entryTypes.name)
    LegacyFormSetters.setMultipleSelect2Value(selectors.appointmentSources, filters.appointmentSources.map(extractId) as string[])
    LegacyFormSetters.setMultipleSelect2Value(selectors.attractionSources, filters.attractionSources.map(extractId) as string[])
    LegacyFormSetters.setMultipleSelect2Value(selectors.appointmentStatuses, filters.appointmentStatuses.map(extractId) as string[])
    LegacyFormSetters.setMultipleSelect2Value(selectors.appointmentTypes, filters.appointmentTypes.map(extractId) as string[])
    LegacyFormSetters.setSelectValue(selectors.paymentType, filters.paymentType.id)
    LegacyFormSetters.setSelect2Value(selectors.administrator, filters.administrator.id)
    LegacyFormSetters.setSelect2Value(selectors.doctor, filters.doctor.id)
    LegacyFormSetters.setLazySelect2Value(selectors.referral, filters.referral.id, filters.referral.name)
    LegacyFormSetters.setCheckboxValue(selectors.newPatients, filters.newPatients.id)
  },
}
