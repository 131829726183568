<template>
  <company-modal-tabbed
    :is-open="isOpen"
    :is-all-forms-in-tabs="isAllFormsInTabs"
    @close="close"
    @submit="submit"
  >
    <template #form>
      <div
        v-loading="loading"
        class="simple_form flex-form table-form company-form-tabbed"
        :class="{'all-in-tabs': isAllFormsInTabs}"
      >
        <div
          v-if="!isAllFormsInTabs"
          class="company-form-tabbed__box"
        >
          <tabs-company-form
            :company-types="companyTypes"
            v-bind.sync="formData"
            :index.sync="formData.addressFormal.index"
            :country.sync="formData.addressFormal.country"
            :region.sync="formData.addressFormal.region"
            :area.sync="formData.addressFormal.area"
            :city.sync="formData.addressFormal.city"
            :street.sync="formData.addressFormal.street"
            :house.sync="formData.addressFormal.house"
            :validation-errors="validationMessages"
            :search-query.sync="searchQuery"
          />
        </div>

        <tabs
          v-model="activeTab"
          type="card"
          class="company-form-tabs"
          :class="{
            'company-tab-error': isCompanyTabError,
            'contact-tab-error': isContactTabError
          }"
        >
          <el-tab-pane
            v-if="isAllFormsInTabs"
            :name="TABS.COMPANY"
            :label="t(TABS.COMPANY)"
          >
            <tabs-company-form
              :company-types="companyTypes"
              v-bind.sync="formData"
              :index.sync="formData.addressFormal.index"
              :country.sync="formData.addressFormal.country"
              :region.sync="formData.addressFormal.region"
              :nsi-russian-subject-id.sync="formData.addressFormal.nsiRussianSubjectId"
              :area.sync="formData.addressFormal.area"
              :city.sync="formData.addressFormal.city"
              :street.sync="formData.addressFormal.street"
              :house.sync="formData.addressFormal.house"
              :validation-errors="validationMessages"
              :search-query.sync="searchQuery"
            />
          </el-tab-pane>

          <el-tab-pane
            :name="TABS.ADDRESS_FORMAL"
            :label="t(TABS.ADDRESS_FORMAL)"
          >
            <tabs-address-form
              :form-type="ADDRESS_TYPE_FORMS.FORMAL"
              v-bind.sync="formData.addressFormal"
              :is-match.sync="isAddressMatch"
            />
          </el-tab-pane>

          <el-tab-pane
            :name="TABS.ADDRESS_FACT"
            :label="t(TABS.ADDRESS_FACT)"
          >
            <tabs-address-form
              :form-type="ADDRESS_TYPE_FORMS.FACT"
              v-bind.sync="formData.addressFact"
              :is-match.sync="isAddressMatch"
            />
          </el-tab-pane>

          <el-tab-pane
            :name="TABS.CONTACTS"
            :label="t(TABS.CONTACTS)"
          >
            <tabs-contacts-form
              v-bind.sync="formData"
              :validation-errors="validationMessages"

              @phone1-mask-settings-change="phone1MaskSettings = $event"
              @phone2-mask-settings-change="phone2MaskSettings = $event"
              @phone1-blur-input="validatePhoneInput('phone1')"
              @phone2-blur-input="validatePhoneInput('phone2')"
              @email-blur-input="validateEmailInput"
            />
          </el-tab-pane>

          <el-tab-pane
            :name="TABS.HEAD"
            :label="t(TABS.HEAD)"
          >
            <tabs-head-form v-bind.sync="formData" />
          </el-tab-pane>
        </tabs>
      </div>
    </template>
  </company-modal-tabbed>
</template>

<script>
import CompanyModalTabbed from './company_modal_tabbed.vue'
import Tabs from '@/vue_components/common/tabs'
import TabsCompanyForm from './tabs_forms/tabs_company_form.vue'
import TabsAddressForm from './tabs_forms/tabs_address_form.vue'
import TabsContactsForm from './tabs_forms/tabs_contacts_form.vue'
import TabsHeadForm from './tabs_forms/tabs_head_form.vue'
import { ValidationHolder } from '@/vue_components/mixins/validationHolder'
import { createPhoneValidator, emailValidator, notEmptyValidator, requiredValidator } from '@/lib/validators/validators'
import { companyEndpoint } from '@/api_entities/company_form_tabbed/company_form_tabbed_endpoint'
import { trimValues } from '../../../helpers'
import { ADDRESS_TYPE_FORMS, createDefaultCompany, getValidationMessages, TABS } from './const'
import { INSURANCE_COMPANY_TYPE_ID } from '@/vue_components/companies/components/consts'

export default {
  name: 'CompanyFormTabbed',
  components: {
    CompanyModalTabbed,
    Tabs,
    TabsCompanyForm,
    TabsAddressForm,
    TabsContactsForm,
    TabsHeadForm,
  },

  mixins: [ValidationHolder],

  props: {
    isOpen: Boolean,
    isAllFormsInTabs: Boolean,
  },

  data () {
    return {
      companyTypes: [],
      loading: true,
      activeTab: this.isAllFormsInTabs ? TABS.COMPANY : TABS.ADDRESS_FORMAL,
      searchQuery: '',
      formData: createDefaultCompany(),
      isAddressMatch: false,
      validationMessages: getValidationMessages(),
      phone1MaskSettings: { ...Services.phoneMask.defaultMaskSettings },
      phone2MaskSettings: { ...Services.phoneMask.defaultMaskSettings },
      TABS,
      ADDRESS_TYPE_FORMS,
    }
  },

  computed: {
    isMatchFormaltoFact () {
      return this.activeTab === TABS.ADDRESS_FORMAL
    },

    isCompanyTabError () {
      return this.validationMessages.title.length ||
        this.validationMessages.company_types.length ||
        this.validationMessages.individual_tax_number.length
    },

    isContactTabError () {
      return this.validationMessages.phone1.length ||
        this.validationMessages.phone2.length ||
        this.validationMessages.email.length
    },
  },

  watch: {
    isAddressMatch () {
      if (!this.isAddressMatch) return

      if (this.isMatchFormaltoFact) {
        this.formData.addressFact = { ...this.formData.addressFormal }
      } else {
        this.formData.addressFormal = { ...this.formData.addressFact }
      }
    },

    'formData.addressFormal': {
      deep: true,
      handler (newValue) {
        if (!this.isAddressMatch || !this.isMatchFormaltoFact) return

        this.formData.addressFact = { ...newValue }
      },
    },

    'formData.addressFact': {
      deep: true,
      handler (newValue) {
        if (!this.isAddressMatch || this.isMatchFormaltoFact) return

        this.formData.addressFormal = { ...newValue }
      },
    },

    'formData.title' (newValue) {
      this.validate('title', newValue, requiredValidator)
    },

    'formData.companyTypeIds' (newValue) {
      this.validate('company_types', newValue, notEmptyValidator)

      if (!newValue.includes(INSURANCE_COMPANY_TYPE_ID)) {
        this.validationMessages.kpp = []
      }
    },

    'formData.phone1' (newValue) {
      if (!this.validationMessages.phone1.length) return

      if (newValue) {
        this.validate(
          'phone1',
          newValue,
          createPhoneValidator(
            this.phone1MaskSettings.length,
            t('telephony.uis.errors.wrong_phone_number')
          )
        )
      } else {
        this.validationMessages.phone1 = []
      }
    },

    'formData.phone2' (newValue) {
      if (!this.validationMessages.phone2.length) return

      if (newValue) {
        this.validate(
          'phone2',
          newValue,
          createPhoneValidator(
            this.phone2MaskSettings.length,
            t('telephony.uis.errors.wrong_phone_number')
          )
        )
      } else {
        this.validationMessages.phone2 = []
      }
    },

    'formData.email' (newValue) {
      if (!this.validationMessages.email.length) return

      if (newValue) {
        this.validate('email', newValue, emailValidator)
      } else {
        this.validationMessages.email = []
      }
    },

    'formData.individualTaxNumber' () {
      this.validationMessages.individual_tax_number = []
    },
  },

  created () {
    this.getCompanyTypes()
  },

  methods: {
    getCompanyTypes () {
      this.loading = true

      companyEndpoint.getAll()
        .then((data) => {
          this.companyTypes = data.data
        })
        .catch((err) => Utils.reportError(
          'getCompanyTypes error',
          t('reception.errors.get_list')
        )(err))
        .finally(() => { this.loading = false })
    },

    submit () {
      trimValues(this.formData)

      if (this.hasErrors()) return

      companyEndpoint.create(this.formData)
        .then(({ id, title }) => {
          this.$emit('create', { id, title })
          this.close()

          Notificator.success(t('record_successfully_created'))
        })
        .catch((err) => {
          if (err instanceof Error) {
            Utils.reportError(
              'createCompany error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.create') }
              )
            )(err)
          } else {
            this.setValidationMessages(this.validationMessages, err.responseJSON)
          }
        })
    },

    close () {
      this.$emit('close')
      this.activeTab = this.isAllFormsInTabs
        ? TABS.COMPANY
        : TABS.ADDRESS_FORMAL
      this.searchQuery = ''
      this.formData = createDefaultCompany()
      this.setValidationMessages(this.validationMessages, getValidationMessages())

      this.skipNextValidations()
    },

    validatePhoneInput (phone) {
      if (!this.formData[phone]) return

      this.validate(
        phone,
        this.formData[phone],
        createPhoneValidator(
          this[`${phone}MaskSettings`].length,
          t('telephony.uis.errors.wrong_phone_number')
        )
      )
    },

    validateEmailInput () {
      if (!this.formData.email) return

      this.validate('email', this.formData.email, emailValidator)
    },
  },
}
</script>
