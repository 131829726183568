<template>
  <modal
    v-if="showScheduleTemplateModal"
    :modal-class="'schedule-template-modal'"
    :modal-visibility="showScheduleTemplateModal"
    @close="$emit('close')"
  >
    <template slot="header">
      <i class="fad fa-fw fa-calendar-star" />
      <span>{{ T.schedule_templates }}</span>
    </template>
    <schedule-template-modal-body
      slot="body"
      ref="scheduleTemplateModal"
      :initial-selectors="initialSelectors"
      :users="users"
      :users-map="usersMap"
      :cabinets="cabinets"
      :cabinets-map="cabinetsMap"
      :online-recording-enabled="onlineRecordingEnabled"
      :current-clinic="currentClinic"
      @close="$emit('close')"
      @removeScheduleTemplate="data => $emit('removeScheduleTemplate', data)"
      @updateScheduleTemplate="data => $emit('updateScheduleTemplate', data)"
      @hideRemoveScheduleTemplateModal="$emit('hideRemoveScheduleTemplateModal')"
    />
    <schedule-template-modal-footer
      slot="footer-right"
      @close="$emit('close')"
      @save="$refs.scheduleTemplateModal.saveChanges()"
    />
  </modal>
</template>

<script>
import Modal from '../../../../modal.vue'
import ScheduleTemplateModalBody from './schedule_template_body.vue'
import ScheduleTemplateModalFooter from './schedule_template_footer.vue'

export default {
  name: 'ScheduleTemplateModal',
  components: {
    Modal,
    ScheduleTemplateModalBody,
    ScheduleTemplateModalFooter,
  },
  props: {
    showScheduleTemplateModal: { type: Boolean, required: true },
    users: { type: Array, required: true },
    usersMap: { type: Object, required: true },
    cabinets: { type: Array, required: true },
    cabinetsMap: { type: Object, required: true },
    onlineRecordingEnabled: { type: Boolean, required: true },
    currentClinic: { type: Object, required: true },
  },
  data: () => ({
    initialSelectors: Object.create(null),
  }),
  methods: {
    removeScheduleTemplate (data) {
      this.$refs.scheduleTemplateModal._removeScheduleTemplate({ ...data, clinic_id: this.currentClinic.id })
    },
  },
}
</script>
