import { LegacyFormSetters } from 'src/helpers/LegacyFormSetters'
import { _getReferralsReportFilters } from './_filtersGetter'
import { getReferralsReportInitialFilters as getInitialFilters } from './initialFIlters'
import { IReferralsReportFiltersSet } from './interfaces'
import { referralsReportFiltersSelectors as selectors } from './filtersSelectors'
import { ReportParamStrict } from '../shared'

export const resetEntrySelect = (entry: ReportParamStrict): void => {
  LegacyFormSetters.setLazySelect2Value(selectors.entry, entry.id, entry.name)
}

export const resetCategorySelect = (category: ReportParamStrict): void => {
  LegacyFormSetters.setSelect2Value(selectors.category, category.id)
}

export const referralsReportFiltersAccessors = {
  get: _getReferralsReportFilters,

  set (filtersToSet: IReferralsReportFiltersSet): void {
    const initialFilters = getInitialFilters()
    const filters = { ...initialFilters, ...filtersToSet }

    LegacyFormSetters.setSelectValue(selectors.clinic, filters.clinic.id)
    LegacyFormSetters.setDateRangePickerValue(selectors.period, filters.period)
    LegacyFormSetters.setButtons2Value(selectors.entryWithOrder, filters.entryWithOrder)
    LegacyFormSetters.setButtons2Value(selectors.includingDiscounts, filters.includingDiscounts)
    LegacyFormSetters.setButtons2Value(selectors.reportTypeFilter, filters.reportTypeFilter)
    LegacyFormSetters.setCheckboxValue(selectors.groupByEntry, filters.groupByEntry.id)

    // Сброс зависимых фильтров услуг и категорий
    resetEntrySelect(initialFilters.entry)
    resetCategorySelect(initialFilters.category)

    if (filters.entry.id) { resetEntrySelect(filters.entry) }
    if (filters.category.id) { resetCategorySelect(filters.category) }

    LegacyFormSetters.setSelect2Value(selectors.medicalUser, filters.medicalUser.id)
    LegacyFormSetters.setSelectValue(selectors.city, filters.city.id)
    LegacyFormSetters.setLazySelect2Value(selectors.referral, filters.referral.id, filters.referral.name)
  },
}
