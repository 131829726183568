import { createVueApp } from '@/vue_components/create_vue_app'

/**
 * @typedef {{
 *   el: String,
 *   name?: String
 * }} VueConfigMin
 */

/**
 * @param {Function} getVueConfig
 * @param {Function} getFilters
 * @param {Function} exportReport
 */
export const createReportLegacy = (
  getVueConfig,
  getFilters,
  exportReport
) =>
  () => {
    Report.init()

    const reportTable = createVueApp(getVueConfig(getFilters))

    $('#report_submit').on('click', function () {
      reportTable.show()
      reportTable.updateFilters(getFilters())
    })

    $('#report_reset').on('click', () => {
      reportTable.hide()
    })

    $('.export').on('click', exportReport(getFilters))
  }
