import { LegacyFormSetters } from '@/helpers/LegacyFormSetters'
import { _getProceduresReportFilters } from './_filtersGetter'
import { getProceduresReportInitialFilters as getInitialFilters } from './initialFilters'
import { IProceduresReportFiltersSet } from './interfaces'
import { proceduresReportFiltersSelectors as selectors } from './filtersSelectors'
import { extractId } from '@/_medods_standart_library/msl'

export const proceduresReportFiltersAccessors = {
  get: _getProceduresReportFilters,

  set (filtersToSet: IProceduresReportFiltersSet): void {
    const filters = { ...getInitialFilters(), ...filtersToSet }

    LegacyFormSetters.setDateRangePickerValue(selectors.period, filters.period)
    LegacyFormSetters.setSelectValue(selectors.clinic, filters.clinic.id)
    LegacyFormSetters.setCheckboxValue(selectors.entryWithOrder, filters.entryWithOrder.id)
    LegacyFormSetters.setCheckboxValue(selectors.entryWithDiscount, filters.entryWithDiscount.id)
    LegacyFormSetters.setMultipleLazySelect2Value(
      selectors.entryTypes,
      filters.entryTypes.map(extractId) as string[],
      filters.entryTypes.map(({ name }) => name)
    )
    LegacyFormSetters.setLazySelect2Value(selectors.client, filters.client.id, filters.client.name)
    LegacyFormSetters.setMultipleSelect2Value(selectors.clientGroups, filters.clientGroups.map(extractId) as string[])
    LegacyFormSetters.setButtons2Value(selectors.clientSex, filters.clientSex.id)
    LegacyFormSetters.setSelectValue(selectors.clientStatus, filters.clientStatus.id)
    LegacyFormSetters.setMultipleSelect2Value(selectors.entryTypeCategories, filters.entryTypeCategories.map(extractId) as string[])
    LegacyFormSetters.setButtons2Value(selectors.entryKind, filters.entryKind.id)
    LegacyFormSetters.setSelect2Value(selectors.administrator, filters.administrator.id)
    LegacyFormSetters.setSelect2Value(selectors.doctor, filters.doctor.id)
    LegacyFormSetters.setMultipleSelect2Value(selectors.doctorSpecialtiesIds, filters.doctorSpecialtiesIds.map(extractId) as string[])
  },
}
