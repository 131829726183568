<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>
    <si-table :spinner="isLoading">
      <template #header>
        <si-field>
          <si-column
            :name="header.date.sortable"
            :active="sortableColumn"
            class="date cut"
            @sort-change="onSortChange"
          >
            {{ header.date.text }}
          </si-column>
          <si-column
            :name="header.time.sortable"
            :active="sortableColumn"
            class="time cut"
            @sort-change="onSortChange"
          >
            {{ header.time.text }}
          </si-column>
          <si-column
            :name="header.status.sortable"
            :active="sortableColumn"
            class="status cut"
            @sort-change="onSortChange"
          >
            {{ header.status.text }}
          </si-column>
          <si-column
            :name="header.client.sortable"
            :active="sortableColumn"
            class="full_name cut"
            @sort-change="onSortChange"
          >
            {{ header.client.text }}
          </si-column>
          <div
            class="phone cut"
          >
            {{ header.phone.text }}
          </div>
          <div
            class="title"
          >
            {{ header.services.text }}
          </div>
          <si-column
            :name="header.doctor.sortable"
            :active="sortableColumn"
            class="short_name cut"
            @sort-change="onSortChange"
          >
            {{ header.doctor.text }}
          </si-column>
          <si-column
            :name="header.note.sortable"
            :active="sortableColumn"
            @sort-change="onSortChange"
          >
            {{ header.note.text }}
          </si-column>
        </si-field>
      </template>
      <template
        v-if="rows.length"
        #body
      >
        <si-field
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
        >
          <div class="date">
            {{ row.date | date }}
          </div>
          <div class="time">
            {{ row.time }}
          </div>
          <div class="status">
            <appointment-status :status="row.status" />
          </div>
          <div
            :data-tooltip="row.client | fullName"
            class="full_name wrap"
          >
            <a :href="row.client.id | route('client_path')">
              {{ row.client | fullName }}
            </a>
          </div>
          <div class="phone">
            <phone
              :raw-phone="row.client.phone"
              :data-client-id="row.client && !row.client.deletedAt && row.client.id"
              :client-id="row.client && row.client.id"
              :popover="!row.client.deletedAt"
              :security-mask="false"
            />
          </div>
          <div
            :data-tooltip="row.entryTypes | names "
            class="title"
          >
            {{ row.entryTypes | names | preserveCell }}
          </div>
          <div class="short_name">
            {{ row.doctor | shortName }}
          </div>
          <div class="wrap">
            {{ row.note | preserveCell }}
          </div>
        </si-field>
      </template>
      <template #footer>
        <si-field
          v-if="isDirty"
          class="total"
        >
          <div>
            {{ footer.total }}: {{ recordsCount }}
          </div>
        </si-field>
      </template>
    </si-table>
    <si-footer v-if="rows.length">
      <pagination
        :page-count="pageCount"
        layout="prev, pager, next"
        class="hidden-print"
        @current-change="loadData"
      />
    </si-footer>
  </si>
</template>

<script>
import Pagination from '@/vue_components/common/pagination.vue'
import Report from '@/vue_components/mixins/report'
import SortableReport from '@/vue_components/mixins/sortableReport'
import ReportWithTooltips from '@/vue_components/mixins/withTooltips'
import Si from '@/vue_components/sort_items/si.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiColumn from '@/vue_components/sort_items/si_table/si_column.vue'
import AppointmentStatus from '@/vue_components/appointment_common/appointment_status.vue'
import formatter from '@/lib/formatters/formatter'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import Phone from '@/vue_components/common/phone'

export default {
  name: 'Appointments',

  components: {
    Phone,
    Pagination,
    Si,
    SiTable,
    SiField,
    SiFooter,
    SiColumn,
    AppointmentStatus,
  },

  filters: {
    preserveCell (value) {
      return formatter.formatValue(value, ['preserveCell'])
    },
    fullName (client) {
      return formatter.formatValue(client, ['client:fullName'])
    },
    shortName (doctor) {
      return formatter.formatValue(doctor, ['user:name'])
    },
    names (entryTypes = []) {
      return entryTypes.map((type) => type.title).join(', ')
    },
    phone (phone) {
      return formatter.formatValue(phone, ['client:phone'])
    },
  },

  mixins: [
    Report,
    ReportWithTooltips,
    SortableReport,
  ],

  props: {
    clinic: { type: Object, required: true },
    clientGroups: { type: Array, required: true },
    period: { type: String, default: '' },
    entryTypes: { type: Object, required: true },
    appointmentTypes: { type: Array, required: true },
    appointmentSources: { type: Array, required: true },
    attractionSources: { type: Array, required: true },
    appointmentStatuses: { type: Array, required: true },
    doctor: { type: Object, required: true },
    administrator: { type: Object, required: true },
    referral: { type: Object, required: true },
    paymentType: { type: Object, required: true },
    newPatients: { type: Object, required: true },
  },

  data () {
    return {
      header: {
        date: {
          text: T.custom_date,
          sortable: 'appointmentDate',
        },
        time: {
          text: T.custom_time,
          sortable: 'appointmentTime',
        },
        status: {
          text: T.status,
          sortable: 'appointmentStatus',
        },
        client: {
          text: T.patient,
          sortable: 'clientSurname',
        },
        phone: {
          text: T.phone,
        },
        services: {
          text: T.services,
          sortable: 'entryTypeTitle',
        },
        doctor: {
          text: T.doctor,
          sortable: 'doctorSurname',
        },
        note: {
          text: T.note,
          sortable: 'appointmentNote',
        },
      },
      footer: {
        total: T.reports.appointments_total,
      },
    }
  },

  computed: {
    subtitleParts () {
      return [
        this.period,
        this.clinic.name,
        this.newPatients.id && this.newPatients.name,
        this.entryTypes.name,
        this.paymentType.name,
        this.administrator.name,
        this.doctor.name,
        this.appointmentTypes.map((item) => item.name).join(', '),
        this.appointmentStatuses.map((item) => item.name).join(', '),
        this.attractionSources.map((item) => item.name).join(', '),
        this.appointmentSources.map((item) => item.name).join(', '),
      ]
    },
  },

  created () {
    this.reportClient.init({
      url: Routes.report_doctor_appointments_path(),
    })
    this.requestBuilder.addStep(() => ReportFiltersFactory.defineFor('appointments', this))
  },

  methods: {
    fillTable (result) {
      this.rows = result.data.map(({ appointment }) => appointment)
    },
    activatePhonePopover (client, event) {
      this.$pubSub.emitAsync('PHONE_POPOVER.SHOW', {
        phoneNumber: client.phone,
        clientId: client.id,
        element: event.target,
      })
    },
  },
}
</script>
