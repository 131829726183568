<template>
  <si-table-generator
    :table-schema="tableSchema"
    :items="dropDownItems"
    hide-headers
    hide-scrollbars
    @onItemClick="$emit('setCallStatus', $event)"
  >
    <template
      v-for="menuItem in dropDownItems"
      #tr-icon="{ item: menuItem }"
    >
      <m-icon
        v-if="menuItem.icon"
        :key="`appointmentCallConfirmationStatuses:${menuItem.id}`"
        :icon="menuItem.icon"
        class="ml-5 m-dropdown__icon"
        :class="menuItem.iconCss"
        color=""
      />
    </template>
  </si-table-generator>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { CALL_CONFIRMATION_STATUS } from '@/vue_apps/DoctorSchedules/AppointmentCallLog/store/const'
import SiTableGenerator from '@/vue_components/sort_items/SiTableGenerator/SiTableGenerator.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'AppointmentCallLogPopupCallConfirmation',
  components: { MIcon, SiTableGenerator },
  props: {
    callStatus: PropsTypes.String(),
    appointmentCallConfirmationStatuses: PropsTypes.Array(),
  },

  data () {
    return {
      tableSchema: {
        options: { empty: true },
        headers: {
          icon: '',
          title: '',
        },
      },
    }
  },

  computed: {
    statusObject () {
      return this.appointmentCallConfirmationStatuses.find(({ id }) => id === (this.callStatus || CALL_CONFIRMATION_STATUS.PENDING)) || {}
    },

    /** @return {TCallLogStatus[]} */
    dropDownItems () {
      if (!this.callStatus) {
        return this.appointmentCallConfirmationStatuses.filter(({ id }) => id !== CALL_CONFIRMATION_STATUS.PENDING)
      }

      return this.appointmentCallConfirmationStatuses.filter(({ id }) => id !== this.callStatus)
    },
  },
}
</script>
