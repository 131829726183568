<template>
  <el-tree
    class="entry-types-tree-app"
    :node-key="treeMeta.nodeKey"
    :props="treeMeta.props"
    :load="loadNode"
    :render-content="entryTypesTreeRenderContent"
    :indent="10"
    :empty-text="treeMeta.emptyText"
    lazy
    @node-click="onNodeClick"
  />
</template>

<script>
import { defineComponent } from 'vue'
import {
  entryTypesTreeRenderContent,
} from '@/vue_present/Reuse/EntryTypes/EntryTypesTree/store/entryTypesTreeRenderContent'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import {
  EntryTypesCategoriesPresenter,
} from '@/vue_present/Reuse/EntryTypes/EntryTypesTree/api/EntryTypesCategoriesPresenter'

export default defineComponent({
  name: 'EntryTypesTreeApp',
  props: {
    legacyCallback: PropsTypes.Function(),
  },

  data () {
    return {
      treeMeta: {
        props: {
          label: 'title',
          isLeaf: 'isLeaf',
          children: 'children',
        },
        nodeKey: 'id',
        emptyText: t('loading_data'),
      },

      firstClick: false,
    }
  },

  methods: {
    entryTypesTreeRenderContent,

    async loadNode ({ data }, resolve) {
      const nodes = await new EntryTypesCategoriesPresenter()
        .fetchEntryTypes({ id: data?.id || 0, forCurrentClinic: true })
      resolve(nodes)
    },

    nodeDblclickStop () {
      this.firstClick = clearTimeout(this.firstClick) && false
    },

    nodeDblClickStart () {
      this.firstClick = setTimeout(() => { this.nodeDblclickStop() }, 300)
    },

    onNodeClick (node) {
      if (!node.isLeaf) { return }
      if (!this.firstClick) {
        return this.nodeDblClickStart()
      }

      if (this.legacyCallback) { this.legacyCallback(node) }

      this.$emit('entryTypeSelect', node)

      this.nodeDblclickStop()
    },
  },
})
</script>
