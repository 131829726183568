$document.on('rez/sms/dispatches/index', () => {
  const cancelAjax = (id) => {
    $.ajax({
      url: Routes.cancel_sms_dispatch_path(id),
      method: 'POST',
      complete () { SortItems.refresh() },
    })
  }

  const listChanged = () => {
    const buttons = document.querySelectorAll('.cancel_dispatch_btn')
    buttons.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        bootbox.confirmYN(t('sms.cancel_dispatch'), (res) => {
          if (res) cancelAjax(e.target.closest('tr').dataset.id)
        })
      })
    })
  }

  Utils.initCalendar()
  SortItems.init({
    itemName: ['sms/dispatches'],
    url: 'dispatches',
    periodRequired: false,
    defaults: { period: 'today' },
  })

  PubSub.on('page.specific.sort_items.finish', listChanged)
})

$document.on('rez/sms/dispatches/show', () => {
  const cancelBtn = document.getElementById('cancel_btn')
  if (!cancelBtn) return
  cancelBtn.addEventListener('click', (e) => {
    bootbox.confirmYN(t('sms.cancel_dispatch'), (res) => {
      const id = gon.specific.dispatch_id
      if (res) {
        $.ajax({
          url: Routes.cancel_sms_dispatch_path(id),
          method: 'POST',
          complete () {
            Turbolinks.visit(Routes.sms_dispatches_path())
          },
        })
      }
    })
  })
})
