<template>
  <search-select
    v-model="entryFilter"
    clearable
    :search-query.sync="searchQuery"
    :reset-value="null"
    :allow-search="allowSearch"
    :not-results="notResults"
    :loading="loading"
    :disabled="false"
    :placeholder="t('filter_by_service')"
    @clearSelect="clearSelect"
  >
    <div
      v-for="option in slicedOptions"
      :key="option.id"
      class="schedule-entry-search"
    >
      <el-option-group
        :label="option.group"
      >
        <el-option
          :label="option.title"
          :value="option.id"
        />
      </el-option-group>
    </div>
  </search-select>
</template>

<script>
import SearchSelect from '@/vue_components/common/select/search_select/search_select.vue'

import { findEntries } from '../rest.js'

export default {
  components: { SearchSelect },

  props: {
    filter: Boolean,

    filteredUserIds: {
      type: Array,
      required: true,
    },
  },

  data () {
    return {
      entryFilter: null,
      searchQuery: '',
      loading: false,
      sliced: [],
    }
  },

  computed: {
    allowSearch () {
      return this.searchQuery.length >= 3
    },

    notResults () {
      return this.allowSearch && this.sliced.length < 1
    },

    reqEntriesParams () {
      if (this.searchQuery === '') {
        return {}
      }

      return {
        clinic_id: this.$store.getters.GET_SCHEDULE_CURRENT_CLINIC_ID,
        key: this.searchQuery,
      }
    },

    slicedOptions () {
      return this.sliced.map((result) => {
        return {
          ...result,
          userIds: result.user_ids,
          userCount: result.user_count,
          group: result.category_title,
        }
      })
    },

    selectedOptionUserIds () {
      const options = this.slicedOptions.find((o) => o.id === this.entryFilter)

      return options ? options.userIds : []
    },
  },

  watch: {
    reqEntriesParams () {
      if (this.allowSearch) {
        this.findEntries()
      }
    },

    entryFilter (value) {
      if (value) {
        this.$emit('change-filter', this.selectedOptionUserIds)
      }
    },
  },

  methods: {
    findEntries () {
      this.loading = true
      findEntries(this.reqEntriesParams)
        .then((response) => { this.sliced = response })
        .finally(() => { this.loading = false })
    },

    clearSelect () {
      this.entryFilter = null
      this.searchQuery = ''
      this.sliced = []
      this.$emit('clear-filter')
    },
  },
}
</script>
