<template>
  <modal
    id="select_joined_entries_modal"
    :modal-visibility="modalVisible"
    :modal-header-buttons="[
      'close'
    ]"
    :modal-class="'select_joined_entries_modal_dialog'"
    @close="closeModal"
  >
    <template #header>
      <span class="fad fa-fw fa-plus-circle" />
      {{ T.laboratories.select_joined_entries.header }}
    </template>

    <template #body>
      <epic-spinner :visibility="modalLoading" />
      <div id="select_joined_entries_modal_body">
        <div>
          <span class="prompt-notice">
            {{ T.laboratories.select_joined_entries.selected_entry }}
          </span>
        </div>
        <div>
          <div class="entry-row selected_entry">
            <div class="row-column number">
              {{ selectedEntry.number }}
            </div>

            <div
              class="row-column cut tooltip-bottom title"
              :title="selectedEntry.title"
            >
              {{ selectedEntry.title }}
            </div>

            <div class="row-column price">
              {{ selectedEntry.price }} {{ currencySign }}
            </div>

            <div class="row-column options">
              <span
                v-if="selectedEntry.preparation"
                class="preparation fad fa-fw fa-info-circle tooltip-bottom"
                :title="selectedEntry.preparation"
              />
            </div>
          </div>
        </div>
        <div>
          <span class="prompt-notice description">{{ T.laboratories.select_joined_entries.description }}</span>
        </div>
        <div>
          <div id="select_joined_entries_table_header">
            <div class="entry-row header">
              <div class="row-column select_all_btn">
                <input
                  v-model="selectAllBtnChecked"
                  class="checkbox"
                  type="checkbox"
                  @click="selectAllBtnClicked"
                >
              </div>
              <div class="row-column number">
                {{ T.code }}
              </div>

              <div class="row-column title">
                {{ T.title }}
              </div>

              <div class="row-column price">
                {{ T.price }}
              </div>

              <div class="row-column options" />
            </div>
          </div>
          <div id="select_joined_entries_table_body">
            <div
              v-for="entry in joinedEntries"
              :key="entry.id"
              class="entry-row body"
              @click="selectEntryBtnClicked(entry)"
            >
              <div class="row-column select_entry_btn">
                <input
                  v-model="entry.selected"
                  class="checkbox"
                  type="checkbox"
                >
              </div>
              <div class="row-column number">
                {{ entry.number }}
              </div>

              <div
                class="row-column cut tooltip-bottom title"
                :title="entry.title"
              >
                {{ entry.title }}
              </div>

              <div class="row-column price">
                {{ entry.price }} {{ currencySign }}
              </div>

              <div class="row-column options">
                <span
                  v-if="entry.preparation"
                  class="preparation fad fa-fw fa-info-circle tooltip-bottom"
                  :title="entry.preparation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer-right>
      <button
        class="btn btn-sm btn-success btn-with-icon modal-add-entries"
        @click="addSelectedEntries"
      >
        <span class="btn-with-icon_icon fad fa-fw  fa-plus" />
        <span class="btn-with-icon_text">{{ T.add }}</span>
      </button>
      <button
        class="btn btn-sm btn-primary btn-with-icon modal-close"
        @click="closeModal"
      >
        <span class="btn-with-icon_icon fad fa-fw  fa-times" />
        <span class="btn-with-icon_text">{{ T.close }}</span>
      </button>
    </template>
  </modal>
</template>
<script>
import Modal from '@/vue_components/modal.vue'
import EpicSpinner from '../../epic_spinner/epic_spinner.vue'

import { ANALYSIS_ORDER_PUBSUB_PREFIX, EVENTS } from '../constants.js'

export default {
  name: 'SelectJoinedEntriesModal',

  components: {
    Modal,
    EpicSpinner,
  },

  props: {
    modalVisible: {
      default: false,
      type: Boolean,
    },
    selectedEntry: {
      default: Object.create(null),
      type: Object,
    },
  },

  data: () => ({
    joinedEntries: [],
    currencySign: window.Localization.currency.symbol_character,
  }),

  computed: {
    modalLoading () {
      return this.joinedEntries.length === 0
    },

    selectAllBtnChecked: {
      get () {
        return this.joinedEntries.every((entry) => entry.selected) && this.joinedEntries.length > 0
      },
      set () {},
    },
  },

  mounted () {
    this.subscribeToEvents()
  },

  updated () {
    this.$nextTick(Utils.updateTooltips)
  },
  methods: {
    closeModal () {
      this.$emit('close-modal')

      this.joinedEntries.splice(0, this.joinedEntries.length)
    },

    subscribeToEvents () {
      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.SEARCH_ENTRY_TYPES_BY_ID}`, (data) => {
        this.joinedEntries.push(...data.result.map((result) => {
          result.selected = false

          return result
        }))

        this.$nextTick(Utils.updateTooltips)
      })
    },

    selectAllBtnClicked () {
      if (this.selectAllBtnChecked) {
        this.joinedEntries.forEach((entry) => {
          entry.selected = false
        })
      } else {
        this.joinedEntries.forEach((entry) => {
          entry.selected = true
        })
      }
    },

    selectEntryBtnClicked (entry) {
      if (entry.selected) {
        entry.selected = false
      } else {
        entry.selected = true
      }
    },

    addSelectedEntries () {
      this.$emit('add-selected-entry', this.selectedEntry, true)

      this.joinedEntries.filter((entry) => entry.selected).forEach((entry) => {
        this.$emit('add-selected-entry', entry, true)
      })

      this.closeModal()
    },
  },
}
</script>
