<template>
  <div class="flex-form">
    <div class="form-label">
      <label for="reception_appointment_type_title">
        {{ t('title') }}
      </label>

      <required-mark />
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationErrors.title"
        fixed-height
      >
        <el-input
          id="reception_appointment_type_title"
          name="reception_appointment_type[title]"
          :value="title"
          :disabled="isSystemName || !$security.canManageReceptionAppointmentType"
          type="text"
          clearable
          @input="$updateSync('title', $event)"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label>
        {{ t('attributes.default_value') }}
      </label>
    </div>

    <div class="form-value">
      <checkbox
        :checked="isDefault"
        :disabled="!$security.canManageReceptionAppointmentType"
        @change="$updateSync('isDefault', $event)"
      />
    </div>

    <div>
      <appointment-type-color-field
        :disabled="!$security.canManageReceptionAppointmentType"
        :color="color"
        @update:color="$updateSync('color', $event)"
      />
    </div>

    <appointment-type-preview-container :color="color" />
  </div>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import Checkbox from '@/vue_components/common/checkbox'
import AppointmentTypeColorField
  from '@/vue_components/appointment/appointment_type/appointment_type_color_field'
import AppointmentTypePreviewContainer
  from '@/vue_components/appointment/appointment_type/appointment_type_preview_container'
import RequiredMark from '@/vue_components/common/required_mark'
import { APPOINTMENT_TYPE_DEFAULT_COLOR } from '../const'

export default {
  name: 'AppointmentTypesForm',
  components: {
    ValidationWrapper,
    Checkbox,
    AppointmentTypeColorField,
    AppointmentTypePreviewContainer,
    RequiredMark,
  },

  props: {
    isSystemName: Boolean,

    title: {
      type: String,
      default: '',
    },

    isDefault: Boolean,

    color: {
      type: String,
      default: APPOINTMENT_TYPE_DEFAULT_COLOR,
    },

    validationErrors: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
