import Vue from 'vue/dist/vue.esm'
import SupportModule from '@/vue_components/support/SupportModule'
import router from '@/vue_components/router/router.js'

export const createSupportModule = (el) => {
  if (!document.querySelector(el)) {
    return
  }

  // eslint-disable-next-line
  new Vue({
    el,
    name: 'SupportModuleRoot',
    router,
    render: (h) => h(SupportModule),
  })
}
