$document.on('rez/medical_policies/show', () => {
  $('#delete-record').deleteButton({
    url: Routes.medical_policy_path(gon.specific.medical_policy.id),
    redirect: Routes.client_medical_policies_path(gon.specific.medical_policy.client_id),
    name: 'страховой полис',
    errorCallback (err) {
      const errors = err.responseJSON

      if (!errors) return

      let message = ''
      Object.keys(errors).forEach((errorKey) => {
        message += `${errors[errorKey].join('. ')}. `
      })

      return message
    },
  })
})

$document.on('rez/medical_policies/edit rez/medical_policies/new', () => {
  FormFactory.build('medical_policy', {
    companyField: {
      target: $('#medical_policy_insurance_company_id'),
    },
  }).newItem()
})
