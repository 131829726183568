import {
  SELECT_ALL_ID,
} from './const.js'

export default {

  _setVisibleSpecialties () {
    const god = this

    let filteredSpecialtiesIds

    if (god.filterCabinets) {
      god.selfFilteredUserIds = god.filteredUserIds.filter((id) => god.usersMap[id])

      filteredSpecialtiesIds = Array.from(god.selfFilteredUserIds.reduce((memo, userId) => {
        memo.add(...(god.usersMap[userId].specialty_ids))

        return memo
      }, new Set()))

      god.selectedSID = filteredSpecialtiesIds.includes(god.selectedSID) ? god.selectedSID : SELECT_ALL_ID
    }

    if (god.showSpecialtiesList) {
      god.visibleSpecialties = god.filterCabinets
        ? filteredSpecialtiesIds.map((specialtyId) => god.specialtiesMap[specialtyId]) : god.specialties.slice()
    } else {
      god.visibleSpecialties = []
    }
  },

  _setAvailableUserIds () {
    const userIds = this.filterCabinets ? this.selfFilteredUserIds : this.allUIDs
    this.availableUIDs = this.selectedSID === SELECT_ALL_ID
      ? userIds
      : userIds.filter((id) => this.usersMap[id].specialty_ids.includes(this.selectedSID))

    if (this.tableVisibility && this.contextReady) {
      this.$updateSync('userIds', this.availableUIDs)
    }
  },

  _workTimeUpdatesHandler (packet) {
    if (packet.dates_with_changes.length) {
      packet.dates_with_changes.forEach((data) => {
        if (
          this.GET_SCHEDULE_CURRENT_CLINIC_ID === data.template.clinic_id &&
          this.availableUIDs.includes(data.template.user_id) &&
          data.dates.some((date) => this.dates.includes(date))) {
          this._changeCabinetIds()
        }
      })
    }

    if (packet.days_with_changes.length) {
      packet.days_with_changes.forEach((data) => {
        if (this.GET_SCHEDULE_CURRENT_CLINIC_ID === data.clinic_id &&
          this.availableUIDs.includes(data.user_id) &&
          this.dates.includes(data.date)) {
          this._changeCabinetIds()
        }
      })
    }
  },
}
