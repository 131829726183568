import { SEMD_TYPE_ELEMENT_ID } from '@/vue_apps/Protocols/const/const'
import { SEMD_TYPE_INVERTED_ENUM } from '@/vue_apps/Semds/const/semdTypes'

export const updateSemdTypeInExternals = (semdType) => {
  gon.specific.__lastSemdType = semdType || null

  const selector = document.querySelector(SEMD_TYPE_ELEMENT_ID)
  if (!selector) { return }
  selector.value = SEMD_TYPE_INVERTED_ENUM[semdType] || ''
}
