<template>
  <div class="medical-records-protocol-selector hidden-print">
    <div class="flex align-items-center flex-basis-50 gap-indent-small">
      <m-select-lazy
        :value="protocolsListValue"
        option-label="info"
        use-first-time-fetch
        full-width
        :m-fixed-height="false"
        :fetch-method="(params) => presenter.list({ entryIds: [entryId], ...params })"
        use-icon
        :clearable="false"
        :label="t('documents_owners.currentMedDocument')"
        :placeholder="placeholder"
        @syncItemsStored="protocolsStoredList = $event"
        @syncItems="protocolsDisplayedList = $event"
        @firstTimeFetched="onChange($event[0])"
        @changeByLazy="onChange"
      />

      <m-button
        v-if="protocolsListValue"
        template="short_edit"
        @click="onProtocolEdit"
      />
    </div>

    <m-button
      v-if="protocolDraftData"
      class="ml-5"
      :text="t('protocols.restoreFromDraft')"
      icon="history"
      type="text"
      @click="onProtocolEditWithRestoreFromDraft"
    />
  </div>
</template>

<script>
import { ProtocolAPI } from '@/vue_apps/Protocols/entities/ProtocolAPI'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { drawProtocol } from '@/vue_apps/MedicalRecords/MedicalRecordsProtocol/actions/drawProtocol'
import { applyPrintingTools } from '@/vue_apps/MedicalRecords/MedicalRecordsProtocol/actions/applyPrintingTools'
import { ENTITIES } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MProtocolPresenter } from '@/vue_apps/Protocols/api/MProtocolPresenter'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ProtocolDraftStorage } from '@/plugins/dynamic_forms/components/editor/ProtocolDraftStorage'

export default {
  name: 'MedicalRecordsProtocolApp',
  components: { MButton, MSelectLazy },

  mixins: [
    SpinnerHolder,
  ],

  props: {
    entryId: { type: Number, required: true },
  },

  data () {
    return {
      /** @type {ProtocolsListItem} */
      protocolsListValue: null,
      presenter: new MProtocolPresenter(),
      protocol: new ProtocolAPI(),

      /**
       * Работа с protocolsStoredList и protocolsDisplayedList
       *   - это работа с дичайшими костылями для красивого обновления иконок в списке
       */
      protocolsStoredList: [],
      protocolsDisplayedList: [],
    }
  },

  computed: {
    placeholder () {
      return t('enums.unselected.he')
    },

    protocolId () {
      return this.protocolsListValue?.id
    },

    protocolDraftData () {
      return this.protocolId
        ? new ProtocolDraftStorage(this.protocolId).restore()
        : null
    },
  },

  created () {
    this.wsSubscribe()
  },

  methods: {
    wsSubscribe () {
      if (!gon.application.egisz_module_enabled) { return }

      Services.wsSubscriptions.egisz.connect(({ data, action }) => {
        if (action !== ENTITIES.PDF_GENERATED) { return }

        /** @type {ProtocolsListItem} */
        const protocolStoredListItem = this.protocolsStoredList.find(({ id }) => id === data?.id)
        if (protocolStoredListItem) { protocolStoredListItem.setPdfGenerated(true) }

        /** @type {ProtocolsListItem} */
        const protocolDisplayedListItem = this.protocolsDisplayedList.find(({ id }) => id === data?.id)
        if (protocolDisplayedListItem) { protocolDisplayedListItem.setPdfGenerated(true) }

        if (this.protocolsListValue?.id === data?.id) { this.protocolsListValue.setPdfGenerated(true) }

        if (data?.id === this.protocol.id) { this.protocol.setValue('pdfGenerated', true) }
      })
    },

    async onChange (protocolListItem) {
      if (!protocolListItem) { return }

      this.protocolsListValue = protocolListItem

      const { id } = protocolListItem

      this.protocol = new ProtocolAPI({ id })
      const {
        errors,
        templateData,
        templateHtml,
        printClinicHeader,
        printDoctorSignature,
        printEntryInfo,
        printEntryTitle,
      } = await this.withSpinner(this.protocol.load())

      if (errors) { return }

      drawProtocol(templateData, templateHtml)

      applyPrintingTools({
        printClinicHeader,
        printDoctorSignature,
        printEntryInfo,
        printEntryTitle,
      })
    },

    visitEntryEditingPage (withRestoreFromDraft = false) {
      const queryParams = {
        protocolId: this.protocolId,
        withRestoreFromDraft: withRestoreFromDraft || undefined,
      }

      location.href = this.$routes.edit_entry_path(this.entryId, queryParams)
    },

    onProtocolEdit () {
      if (this.protocolId) { this.visitEntryEditingPage() }
    },

    onProtocolEditWithRestoreFromDraft () {
      if (this.protocolId) { this.visitEntryEditingPage(true) }
    },
  },
}
</script>
