<template>
  <si-field>
    <div class="tbl-icon">
      <a
        :href="row.link"
        class="fad fa-fw fa-money-bill-wave"
        :data-tooltip="row.id"
      />
    </div>

    <div class="short_name wrap">
      <a
        v-if="row.payerLink"
        :href="row.payerLink"
      >
        {{ payer(row) }}
      </a>
      <span v-else>
        {{ payer(row) }}
      </span>
    </div>

    <div class="tbl-icon">
      <payment-kind :kind="row.kind" />
    </div>

    <div class="date">
      {{ row.date | date }}
    </div>

    <div>
      {{ row.byCash | price }}
    </div>

    <div>
      {{ row.byCashless | price }}
    </div>

    <div>
      {{ row.byCard | price }}
    </div>

    <div>
      {{ row.byBalance | price }}
    </div>

    <div>
      {{ row.byCredit | price }}
    </div>

    <div>
      {{ row.totalIncome | price }}
    </div>

    <div>
      {{ row.totalPaid | price }}
    </div>

    <div>
      <template v-if="row.destinationOrder">
        <template v-if="row.destinationOrder.id && row.destinationOrder.deletedAt">
          {{ t('order_was_deleted') }}
        </template>
        <template v-else>
          <a :href="row.destinationOrder.id | route('order_path')">
            {{ row.destinationOrder.finalSum | price }}
          </a>
        </template>
      </template>
    </div>

    <div
      class="wrap"
      :class="{'deleted': doctorInfo.deleted}"
    >
      <m-link
        v-if="doctorInfo.link && !doctorInfo.deleted"
        :href="doctorInfo.link"
        :text="doctorInfo.title"
      />

      <template v-else>
        {{ doctorInfo.title }}
      </template>
    </div>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field'
import PaymentKind from '@/vue_components/common/payments/payment_kind'
import formatter from '@/lib/formatters/formatter'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MLink from '@/vue_present/_base/MLink/MLink.vue'

export default {
  name: 'PaymentsReportTableRow',

  components: { MLink, PaymentKind, SiField },

  props: {
    row: PropsTypes.Object(undefined, true),
  },

  computed: {
    isDoctorInfoContainsUser () {
      return Boolean(parseInt(this.row.doctorInfo?.id))
    },

    doctorInfo () {
      if (!this.isDoctorInfoContainsUser) {
        return {
          title: this.row.doctorInfo?.id ?? '',
          deleted: false,
        }
      }

      return {
        link: Routes.user_path(this.row.doctorInfo.id),
        title: this.$filters.fullName(this.row.doctorInfo),
        deleted: Boolean(this.row.doctorInfo.deletedAt),
      }
    },
  },

  methods: {
    payer (payment) {
      if (payment.payerClient) {
        return formatter.formatValue(payment.payerClient, ['client:fullName'])
      }

      if (payment.payerCompany) {
        return payment.payerCompany.title
      }
    },
  },
}
</script>
