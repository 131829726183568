<template>
  <si-table class="egisz-cases-tab__table">
    <template #header>
      <si-field>
        <div class="col col__status">
          <i class="fad" />
        </div>

        <div class="col col__period">
          <span v-tooltip="t('egisz.components.med_card_med_case_list.table_header.period')">
            {{ t('egisz.components.med_card_med_case_list.table_header.period_short') }}
          </span>
        </div>

        <div class="col col__doctor">
          <span v-tooltip="t('egisz.components.med_card_med_case_list.table_header.doctor')">
            {{ t('egisz.components.med_card_med_case_list.table_header.doctor_short') }}
          </span>
        </div>

        <div class="col col__step_count">
          <i
            v-tooltip="t('egisz.components.med_card_med_case_list.table_header.steps')"
            class="fad fa-calendar-day font-size-17"
          />
        </div>

        <div class="col col__options">
          <i class="fad" />
        </div>
      </si-field>
    </template>

    <template #body>
      <si-field
        v-for="medCase in medCases"
        :key="medCase.id"
        class="pointer"
        @click="$emit('editMedCase', medCase.id)"
      >
        <div class="col col__status">
          <i
            v-tooltip="medCaseStatusText(medCase.closed, medCase.netrika_status)"
            class="font-size-17"
            :class="EGISZ_STATUSES_CASES_CSS(medCase.closed, medCase.netrika_status)"
          />
        </div>

        <div class="col col__period">
          <span v-tooltip="getMedCasePeriod(medCase.opened_at, medCase.closed_at)">
            {{ getMedCasePeriod(medCase.opened_at, medCase.closed_at) }}
          </span>
        </div>

        <div class="col col__doctor">
          <span v-tooltip="medCase.doctor_full_name">
            {{ medCase.doctor_full_name }}
          </span>
        </div>

        <div class="col col__step_count">
          {{ medCase.steps_count }}
        </div>

        <div
          class="col col__options"
          @click.stop="() => {}"
        >
          <popover
            @yes="$emit('deleteCase', medCase.id)"
          >
            <template slot="reference">
              <i
                v-if="canManage && !isStatusRegistered(medCase.netrika_status)"
                v-tooltip="t('delete')"
                class="fad fa-trash"
              />
            </template>
          </popover>
        </div>
      </si-field>
    </template>
  </si-table>
</template>

<script>
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import Popover from '@/vue_components/common/popover/popover'
import { mapGetters } from 'vuex'
import { EGISZ_STATUSES, EGISZ_STATUSES_CASES_CSS } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'

export default {
  name: 'MedCardMedCaseList',
  components: { Popover, SiField, SiTable },
  props: {
    medCases: {
      type: Array,
      default: () => [],
    },

    canManage: Boolean,
  },

  data () {
    return {
      EGISZ_STATUSES_CASES_CSS,
    }
  },

  computed: {
    ...mapGetters(['GET_LOCALIZATION_DATE_FORMAT']),
  },

  methods: {
    formatDate (date) {
      return date
        ? moment(Utils.dateRubyToJS(date)).format(this.GET_LOCALIZATION_DATE_FORMAT)
        : t('egisz.components.med_card_med_case_list.table_body.active')
    },

    medCaseStatusText (closed, rawStatus) {
      if (!closed) { return t('egisz.components.med_card_med_case_list.table_body.active') }

      return t(`egisz.common_statuses.${rawStatus}`)
    },

    getMedCasePeriod (opened, closed) {
      let period = ''
      if (opened && closed) {
        period = `${this.formatDate(opened)} - ${this.formatDate(closed)}`
      } else if (opened && !closed) {
        period = `${this.formatDate(opened)} - ` + t('egisz.components.med_card_med_case_list.table_body.active')
      } else {
        period = t('egisz.components.med_card_med_case_list.table_body.no_dates')
      }

      return period
    },

    // Удалить?
    isStatusRegistered (status) {
      return status === EGISZ_STATUSES.SUCCESS
    },
  },
}
</script>
