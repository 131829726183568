export default function onlineRecord () {
  if (!gon.application.sms_module_enabled) return

  const $form = $('#sms_configuration_form')

  $form.on('ajax:success', () => {
    Notificator.info(I18n.t('sms.configuration_updated'))
  })

  $form.on('ajax:error', (err) => {
    Notificator.error(T.check_correctness_filing_form)
    console.log(err)
  })
}
