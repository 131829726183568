import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { checkCanAddWithTeeth } from '@/vue_present/Reuse/EntryTypes/shared/checkCanAddWithTeeth'

export class EntryTypesTransferListItem extends MListServiceItem {
  /**
   * @param {TEntryTypesTransferItem} entryType
   */
  constructor (entryType) {
    super()
    this.id = entryType.id
    this.title = entryType.title
    this.price = entryType.price
    this.duplicated = entryType.duplicated
    this.implementedByUser = entryType.implementedByUser
    this.referralId = entryType.referralId
    this.errors = !entryType.implementedByUser
      ? t('transferEntryTypesImplemented')
      : entryType.errors || null
    this.kind = entryType.kind
    this.teethMap = entryType.teethMap
  }

  resetErrors (errors = '') {
    this.errors = errors
  }

  setDuplicated () {
    this.duplicated = true
  }

  get tableTitle () {
    return new MTableCell(this.title)
      .addTooltip(this.title)
  }

  get tablePrice () {
    return new MTableCell(vueFilters.currency(this.price))
  }

  get tableDuplicated () {
    const icon = this.duplicated ? 'warningCircle' : ''

    return new MTableCell()
      .addTypeIcon(icon)
      .addClasses('warning')
  }

  get tableErrors () {
    const icon = this.errors || !this.implementedByUser
      ? 'warning'
      : ''

    return new MTableCell()
      .addTypeIcon(icon)
      .addTooltip(this.errors)
      .addClasses('danger')
  }

  canAddWithTeeth () {
    return checkCanAddWithTeeth(this.getValues())
  }
}
