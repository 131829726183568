<template>
  <div class="flex-form">
    <div class="form-label">
      <label for="reception_appointment_duration_duration">
        {{ t('reception.appointment_interval_minutes') }}

        <required-mark />
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationErrors.duration"
        fixed-height
      >
        <el-input
          id="reception_appointment_duration_duration"
          name="reception_appointment_duration[duration]"
          :disabled="!$security.canManageReceptionAppointmentDuration"
          :value="formValue"
          type="number"
          clearable
          @input="$updateSync('formValue', $event)"
        />
      </validation-wrapper>
    </div>
  </div>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import RequiredMark from '@/vue_components/common/required_mark'

export default {
  name: 'AppointmentDurationForm',
  components: {
    ValidationWrapper,
    RequiredMark,
  },
  props: {
    formValue: {
      type: String,
      default: '',
    },

    validationErrors: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
