<template>
  <div class="appointment-type-preview__container">
    <p>{{ t('preview') }}</p>
    <appointment-type-preview-grid :color="color" />
  </div>
</template>

<script>
import AppointmentTypePreviewGrid
  from '@/vue_components/appointment/appointment_type/appointment_type_preview_grid'

export default {
  name: 'AppointmentTypeColorPreview',

  components: { AppointmentTypePreviewGrid },

  props: {
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
