import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { tScoped } from '@/helpers/i18nHelpers'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

const ts = tScoped('taxCertificatesReport.tableHeaders')

export const getTaxReportSchema = () =>
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addMinWidth('2600px')
        .addSorting()
        .addHeaders({
          certificateId: new MTableHeader(ts('numberCell'))
            .addFixedWidth('70px')
            .addTooltip(true),

          certificateNumber: new MTableHeader(ts('referenceNumber'))
            .addFixedWidth('150px')
            .addTooltip(true),

          correctionNumber: new MTableHeader(ts('correctionNumber'))
            .addFixedWidth('120px')
            .addTooltip(ts('correctionNumberTooltip')),

          payerFullName: new MTableHeader(ts('payerFullName'))
            .addFixedWidth('220px')
            .addTooltip(true),

          payerInn: new MTableHeader(ts('payerInn'))
            .addFixedWidth('220px')
            .addTooltip(true),

          payerBirthDate: new MTableHeader(ts('payerBirthDate'))
            .addFixedWidth('200px')
            .addTooltip(ts('payerBirthDateTooltip')),

          payerDocumentKind: new MTableHeader(ts('payerDocumentTypeCode'))
            .addFixedWidth('120px')
            .addTooltip(ts('payerDocumentTypeCodeTooltip')),

          payerSeriesNumber: new MTableHeader(ts('payerDocumentSeriesNumber'))
            .addFixedWidth('180px')
            .addTooltip(true),

          payerIssueDate: new MTableHeader(ts('payerDocumentIssueDate'))
            .addFixedWidth('160px')
            .addTooltip(true),

          mark: new MTableHeader()
            .addFixedWidth('70px')
            .addTooltip(ts('sign'))
            .addTypeIcon('ready'),

          clientFullName: new MTableHeader(ts('patientFullName'))
            .addFixedWidth('200px')
            .addTooltip(true),

          clientInn: new MTableHeader(ts('patientInn'))
            .addFixedWidth('180px')
            .addTooltip(true),

          clientBirthdate: new MTableHeader(ts('patientBirthdate'))
            .addFixedWidth('170px')
            .addTooltip(ts('patientBirthdateTooltip')),

          clientDocumentKind: new MTableHeader(ts('patientDocumentTypeCode'))
            .addTooltip(ts('patientDocumentTypeCodeTooltip'))
            .addFixedWidth('120px'),

          clientSeriesNumber: new MTableHeader(ts('patientDocumentSeriesNumber'))
            .addFixedWidth('170px')
            .addTooltip(true),

          clientIssueDate: new MTableHeader(ts('patientDocumentIssueDate'))
            .addFixedWidth('160px')
            .addTooltip(true),

          sumOne: new MTableHeader(ts('amountByServiceCode1'))
            .addFixedWidth('200px')
            .addTooltip(ts('tooltipAmountByServiceCode1')),

          sumTwo: new MTableHeader(ts('amountByServiceCode2'))
            .addFixedWidth('200px')
            .addTooltip(ts('tooltipAmountByServiceCode2')),

          authenticatorFullName: new MTableHeader(ts('issuerOfCertificate'))
            .addFixedWidth('290px')
            .addTooltip(true),

          certificateDate: new MTableHeader(ts('date'))
            .addFixedWidth('170px')
            .addTooltip(ts('dateTooltip')),

          // certificateStatus: new MTableHeader(ts('status')), // TODO: до лучших времен
        })
        .addFooter()
    )
