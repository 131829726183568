<template>
  <div class="inline-block">
    <el-dropdown
      type="primary"
      placement="top"
      @command="handleCommand"
    >
      <button
        type="button"
        class="btn btn-default btn-xs"
      >
        <i class="fad fa-brackets-curly" />
        {{ t('variables') }}
      </button>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="variable in variables"
          :key="`var:${variable.value}`"
          :command="variable"
        >
          {{ variable.label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <popover
      v-tooltip="t('laboratories.barcode_reset_tooltip')"
      placement="top-end"
      yes-style="primary"
      class="el-popover__reference-wrapper"
      @yes="$emit('ask-reset')"
    >
      <template slot="message">
        {{ t('laboratories.barcode_reset_confirm') }}
      </template>
      <template slot="reference">
        <button
          type="button"
          class="btn btn-default btn-xs"
        >
          <i class="fad fa-redo-alt" />
          {{ t('laboratories.barcode_reset') }}
        </button>
      </template>
    </popover>
  </div>
</template>

<script>
import popover from '@/vue_components/common/popover/popover.vue'

export default {
  name: 'BarcodeVariableEditor',
  components: { popover },

  props: {
    variables: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    handleCommand (command) {
      this.$emit('addVariable', command)
    },
  },
}
</script>
