<template>
  <m-panel
    id="kdl-attachments"
    :title="t('attached_files')"
    icon="fas fa-file-alt"
    m-panel-class="attachments-m-panel hidden-print"
  >
    <div class="kdl-attachments-table">
      <div id="kdl-attachments_table_header">
        <div class="attachment_row">
          <div class="date">
            {{ t('common.date') }}
          </div>

          <div class="title">
            {{ t('title') }}
          </div>

          <div class="options" />
        </div>
      </div>

      <div id="kdl-attachments_table_body">
        <div class="attachment_row">
          <div class="date">
            {{ orderDate }}
          </div>

          <div class="title">
            <m-button
              :text="waybillTitle"
              type="text"
              button-style="fill"
              @click="openWaybillModal"
            />
          </div>

          <div class="options">
            <m-button
              type="dark"
              :tooltip="t('print')"
              no-use-fw
              icon="print"
              button-style="fill"
              size="mini"
              @click="onPrintClick"
            />
          </div>
        </div>
      </div>
    </div>

    <m-modal
      :dialog-title="t('laboratories.waybill')"
      :visible.sync="waybillModalOpen"
    >
      <kdl-waybill
        :biomaterial-containers="biomaterials"
        :use-hidden-print="true"
        :clinic-identifier="clinicIdentifier"
        @print-cover-letter="$emit('print-cover-letter')"
      />
    </m-modal>
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import KdlWaybill from '@/vue_components/analysis_orders/components/KdlWaybill.vue'

export default {
  name: 'KdlAttachments',
  components: { KdlWaybill, MModal, MButton, MPanel },

  props: {
    orderNumber: {
      type: String,
      default: '',
    },

    orderDate: {
      type: String,
      default: '',
    },

    biomaterials: {
      type: Array,
      default: () => [],
    },

    clinicIdentifier: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      waybillModalOpen: false,
    }
  },

  computed: {
    waybillTitle () {
      return `${t('laboratories.waybillForOrder')} ${this.orderNumber}`
    },
  },

  methods: {
    openWaybillModal () {
      this.waybillModalOpen = true
    },

    onPrintClick () {
      this.$emit('print-cover-letter')
      window.print()
    },
  },
}
</script>
