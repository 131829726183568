import SmsEditor from '@/modules/variables_editor.js'
import { APPOINTMENT_CONFIRMATION } from '@/modules/sms/editor_presets.js'
import { smsCount } from '@/modules/sms/utils.js'

export default function appointmentConfirmation () {
  if (!gon.application.sms_module_enabled) return

  const $form = $('#sms_configuration_form')
  const $submit = $form.find('#sms_settings_submit').eq(0)
  const $appointmentConfirmationEditor = $form.find('.appointment_confirmation_template_editor_container')
  const $appointmentConfirmationCb = $form.find('#sms_configuration_appointment_confirmation')
  const $appointmentConfirmationFields = $form.find('.hideable_field')
  const $appointmentConfirmationApproximate = $form.find('.approximate_value')
  const $symbolCount = $form.find('.symbol_count')

  const appointmentConfirmationEditor = new SmsEditor({
    container: $appointmentConfirmationEditor,
    onPreviewChanged (text) {
      const count = smsCount(text)
      $appointmentConfirmationApproximate.text(count)
      $symbolCount.text(text.length)
    },
    validationCallback (value) {
      $submit.prop('disabled', !value)
    },
    ...APPOINTMENT_CONFIRMATION,
  })

  appointmentConfirmationEditor.visualize()

  $form.remote_form({
    model: 'sms_configuration',
    beforeSend () {
      appointmentConfirmationEditor.originalize()
    },
    onSuccess (res) {
      appointmentConfirmationEditor.visualize()
      Notificator.info(I18n.t('sms.configuration_updated'))
      $submit.loadSpin('stop')
    },
    onError (err) {
      Notificator.error(T.check_correctness_filing_form)
      console.log(err)
      appointmentConfirmationEditor.visualize()
      $submit.loadSpin('stop')
    },
  })

  const toggleAppointmentConfirmationFields = () => {
    $appointmentConfirmationFields.toggleClass('hidden', !$appointmentConfirmationCb.prop('checked'))
  }

  $('#sms_configuration_appointment_confirmation_send_before_hours')
    .inputmask('Regex', { regex: '^[1-9]$|^1[0-9]$|^2[0-3]$' })

  toggleAppointmentConfirmationFields()
  $appointmentConfirmationCb.on('change', toggleAppointmentConfirmationFields)
  $submit.on('click', (e) => {
    if (!appointmentConfirmationEditor.validate()) e.preventDefault()
  })
}
