<template>
  <div class="report-filters__buttons">
    <m-button
      :disabled="loading"
      class="buttons__report"
      template="report"
      @click="$emit('onReportClick')"
    />

    <m-button
      class="buttons__reset"
      type="warning"
      :tooltip="t('reset')"
      :disabled="loading"
      no-use-fw
      icon="no-filter"
      @click="$emit('onResetClick')"
    />
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default {
  name: 'ReportFiltersButtons',
  components: { MButton },
  props: {
    loading: Boolean,
  },

  emits: [
    'onReportClick',
    'onResetClick',
  ],
}
</script>
