import Vue from 'vue/dist/vue.esm'
import EmployeesScheduleTable from '../vue_components/employees_schedule/schedule_table/employees_schedule_table.vue'
import EmployeesScheduleControlComponent from '../vue_components/employees_schedule/control_component/control_component.vue'
import { WITHOUT_CABINET_ID } from '../vue_components/employees_schedule/const.js'
import tippy from 'tippy.js'

$document.on('rez/schedule_template/schedule_templates/schedule', function () {
  const $calendar = $('#schedule-calendar')
  const $calendarHideButton = $('#calendar-hide-button')
  const $hideCalendar = $('#hide-calendar')
  const $showCalendar = $('#show-calendar')
  const $controlElements = $('.employee-schedule-grid-right-panel')
  const $showControlElements = $('.show-control-elements')
  const $hideControlElements = $('.hide-control-elements')
  let $calendarDatePicker
  let $adjustScheduleTemplates
  let $extendScheduleButton
  let $printScheduleButton

  Services.SGAM = (() => {
    const SCHEDULE_STORAGE_NAME = 'employees_schedule_storage'
    const SCHEDULE_DATE_STORE_NAME = 'employees_schedule_date'

    const publicInterface = {
      page: window.location.pathname,
      helpers: {
        fullName: (surname, name, secondName) => `${surname} ${name} ${secondName}`,
        shortName: (surname, name, secondName) => `${surname} ${String(name)[0]}. ${String(secondName)[0]}.`,
      },
      init () {
        const pageStore = this.getPageStore()
        $calendar.datepicker('setDate', moment(this.getScheduleDate()).toDate())
        this.changeCalendarVisibility(true)
        this.changeControlElementsVisibility(pageStore.controlElementsVisibility)
      },
      getScheduleDate () {
        return store.get(SCHEDULE_DATE_STORE_NAME)
      },
      setScheduleDate (date) {
        store.set(SCHEDULE_DATE_STORE_NAME, Utils.dateJStoRuby(date))
      },
      getPageStore () {
        return store.get(SCHEDULE_STORAGE_NAME)
      },
      setPageStore (stateObject) {
        store.set(SCHEDULE_STORAGE_NAME, stateObject)
      },
      updatePageStore (state) {
        const oldState = this.getPageStore()
        this.setPageStore({...oldState, ...state})
      },
      changeCalendarVisibility (onlyRestore = false) {
        const storeState = this.getPageStore().calendarVisibility
        const state = onlyRestore ? storeState : !storeState

        if (state) {
          $showCalendar.hide(1)
          $hideCalendar.show(1)
          $calendarDatePicker.fadeIn(1)
        } else {
          $showCalendar.show(1)
          $hideCalendar.hide(1)
          $calendarDatePicker.fadeOut(1)
        }

        this.updatePageStore({calendarVisibility: state})
      },
      changeControlElementsVisibility (show) {
        this.updatePageStore({controlElementsVisibility: show})
        if (show) $controlElements.removeClass('hide-control-elements')
        else $controlElements.addClass('hide-control-elements')
      },
    }

    const currentStorage = publicInterface.getPageStore() || Object.create(null)
    currentStorage.calendarVisibility =
      currentStorage.calendarVisibility === undefined ? true : currentStorage.calendarVisibility
    currentStorage.controlElementsVisibility =
      currentStorage.controlElementsVisibility === undefined ? true : currentStorage.controlElementsVisibility
    publicInterface.setPageStore(currentStorage)

    if (!publicInterface.getScheduleDate()) {
      publicInterface.setScheduleDate(moment().toDate())
    }

    return publicInterface
  })()

  // short alias for specific page
  const SGAM = Services.SGAM

  const users = Object.freeze(gon.specific.users.map((raw) => Object.freeze({
    id: raw[0],
    name: raw[1],
    surname: raw[2],
    second_name: raw[3],
    short_name: SGAM.helpers.shortName(raw[2], raw[1], raw[3]),
    full_name: SGAM.helpers.fullName(raw[2], raw[1], raw[3]),
    appointment_duration: raw[4],
    specialty_ids: raw[5],
    avatar_id: raw[6],
    avatar_tag: raw[7],
  })))

  const specialties = Object.freeze(gon.specific.specialties.map((raw) => Object.freeze({
    id: raw[0],
    title: raw[1],
  })))

  gon.specific.cabinets.unshift({
    id: WITHOUT_CABINET_ID,
    title: T.doctors_without_a_cabinet,
  })

  const cabinets = Object.freeze(gon.specific.cabinets.map((cabinet) => Object.freeze(cabinet)))

  Services.pubSub.subscribe('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION', () => {
    Services.ui
      .notificator()
      .error(window.t('abstract_error_message'), window.t('error'), 0)
  })

  const schedule = new Vue({
    el: '#employees-schedule-container',
    components: { EmployeesScheduleTable },
    data: {
      users,
      specialties,
      cabinets,
      extendScheduleTippy: null,
      onlineRecordingEnabled: gon.application.client_widget_enabled,
      currentClinic: gon.application.current_clinic,
    },
    created () {
      this.$pubSub.subscribe('EMPLOYEES_SCHEDULE.CHECKBOXES_STATE_CHANGED', this.showExtendScheduleButton)
    },
    methods: {
      showExtendScheduleButton (flag) {
        this.extendScheduleTippy.state.enabled = !flag

        if (flag) {
          $extendScheduleButton.removeClass('disabled')
          this.extendScheduleTippy.disable()
        } else {
          $extendScheduleButton.addClass('disabled')
          this.extendScheduleTippy.enable()
        }
      },
      changeDatePickerDate (date) {
        $calendar.datepicker('setDate', date)
      },
    },
    template:
    `<employees-schedule-table
       ref="employeesScheduleTable"
       :users="users"
       :specialties="specialties"
       :cabinets="cabinets"
       :online-recording-enabled="onlineRecordingEnabled"
       :current-clinic="currentClinic"
       @changeDatePickerDate="changeDatePickerDate"
    />`,
  })

  new Vue({
    el: '#main-control-component',
    components: { EmployeesScheduleControlComponent },
    data: {
      specialties,
      cabinets,
      users,
      currentClinic: gon.application.current_clinic,
    },
    template:
    `<employees-schedule-control-component
       :_specialties="specialties"
       :_cabinets="cabinets"
       :_users="users"
       :currentClinic="currentClinic"
    />`,
  })

  $calendarDatePicker = $calendar.datepicker({
    todayBtn: 'linked',
    language: gon.localization.locale,
    todayHighlight: true,
    weekStart: gon.localization.day_week_start,
    format: gon.localization.date_format,
  }).on('changeDate', function (e) {
    SGAM.setScheduleDate(e.date)
    Services.pubSub.emitAsync(
      'EMPLOYEES_SCHEDULE.UPDATE_SCHEDULE_GRID_DATE',
      Utils.dateJStoRuby(e.date),
    )
  }).find('.datepicker.datepicker-inline')
    .hide(1)

  $calendarHideButton.on('click', () => SGAM.changeCalendarVisibility())

  const updateScheduleTemplatesButton =
    `
    <button id="update-schedule-templates"
            class="btn btn-grey-dark btn-xs btn-with-icon">
      <i class="fad fa-fw fa-calendar-star"></i>
      <span class="btn-with-icon_text">${T.schedule_templates}</span>
    </button>
    `

  const extendScheduleButton =
    `
    <button id="extend-employees-schedule"
            class="btn btn-grey-dark btn-xs btn-with-icon"
            title="${T.select_intervals_to_extend}">
      <i class="fad fa-fw fa-chevron-double-right"></i>
      <span class="btn-with-icon_text">${T.extend_schedule}</span>
    </button>
    `

  const printScheduleButton =
    `
    <button id="print-schedule"
            class="btn btn-grey-dark btn-xs btn-with-icon">
      <i class="fad fa-fw fa-print"></i>
      <span class="btn-with-icon_text">${T.print}</span>
    </button>
    `

  $adjustScheduleTemplates =
    $(updateScheduleTemplatesButton)
      .on('click', () => Services.pubSub.emitAsync('EMPLOYEES_SCHEDULE.SHOW_SCHEDULE_TEMPLATE_MODAL'))

  $extendScheduleButton =
    $(extendScheduleButton)
      .on('click', function () {
        if (this.classList.contains('disabled')) return
        Services.pubSub.emitAsync('EMPLOYEES_SCHEDULE.SHOW_EXTEND_SCHEDULE_TEMPLATES_MODAL')
      })

  $printScheduleButton =
    $(printScheduleButton)
      .on('click', () => Turbolinks.visit(Routes.adjust_scheduler_print_scheduler_path()))

  $('#employees-schedule .panel-heading-buttons')
    .append($adjustScheduleTemplates)
    .append($extendScheduleButton)
    .append($printScheduleButton)

  schedule.extendScheduleTippy = tippy('#extend-employees-schedule')[0]

  $showControlElements.on('click', function () {
    SGAM.changeControlElementsVisibility(true)
  })

  $hideControlElements.on('click', function () {
    SGAM.changeControlElementsVisibility(false)
  })

  SGAM.init()
})
