export default {
  _extendScheduleTemplatesRequest (date) {
    const god = this

    Services.ui.spinner().show(true)

    $.ajax({
      url: Routes.extend_schedule_templates_path(),
      method: 'POST',
      data: {
        interval_end_date: Utils.dateJStoRuby(date),
        template_ids: JSON.stringify(god.extendScheduleTemplateModalData.templateIds),
        clinic_id: gon.application.current_clinic.id,
      },
      error () {
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
      },
      complete () {
        god.updateAllCheckboxesState(false)
        god.showExtendScheduleTemplateModal = false
        Services.ui.spinner().show(false)
      },
    })
  },

  _addVacation (requestData) {
    const god = this

    Services.ui.spinner().show(true)

    $.ajax({
      url: Routes.vacations_path(),
      method: 'POST',
      data: requestData,
      error () {
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
      },
      complete () {
        god.showAddVacationModal = false
        god.showWorkTimeModal = false
        Services.ui.spinner().show(false)
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.RELOAD_SCHEDULE')
      },
    })
  },

  _removeVacation () {
    const god = this

    Services.ui.spinner().show(true)

    $.ajax({
      url: Routes.vacation_path(god.removeVacationModalData.vacation.id),
      method: 'DELETE',
      error () {
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
      },
      complete () {
        god.showRemoveVacationModal = false
        god.showSelectVacationModal = false
        Services.ui.spinner().show(false)
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.RELOAD_SCHEDULE')
      },
    })
  },

  _sliceVacation () {
    const god = this

    Services.ui.spinner().show(true)

    $.ajax({
      url: Routes.slice_vacations_path(),
      method: 'POST',
      data: {
        id: god.removeVacationModalData.vacation.id,
        date: god.removeVacationModalData.date,
      },
      error () {
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
      },
      complete () {
        god.showRemoveVacationModal = false
        god.showSelectVacationModal = false
        Services.ui.spinner().show(false)
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.RELOAD_SCHEDULE')
      },
    })
  },

  _splitVacation () {
    const god = this

    Services.ui.spinner().show(true)

    $.ajax({
      url: Routes.split_vacations_path(),
      method: 'POST',
      data: {
        id: god.removeVacationModalData.vacation.id,
        date: god.removeVacationModalData.date,
      },
      error () {
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
      },
      complete () {
        god.showRemoveVacationModal = false
        god.showSelectVacationModal = false
        Services.ui.spinner().show(false)
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.RELOAD_SCHEDULE')
      },
    })
  },
}
