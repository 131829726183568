export default {
  disabled: {
    type: Boolean,
  },

  /**
   * @type {import('vue').PropOptions<Object>}
   *
   * date: required,
   * doctorId: required
   */
  validationMessages: {
    type: Object,
    required: true,
  },

  date: {
    type: String,
    default: null,
  },

  currentDoctor: {
    type: Object,
    default: null,
  },

  currentClinicId: {
    type: Number,
    default: null,
  },

  /**
   * @type {import('vue').PropOptions<{id: Number, name: String}[]>}
   */
  clinicsArray: {
    type: Array,
    default: () => [],
  },

  note: {
    type: String,
    default: '',
  },

  isUrgent: {
    type: Boolean,
    default: false,
  },

  hasDependAppointment: {
    type: Boolean,
    default: false,
  },

  createdBy: {
    type: String,
    default: '',
  },

  updatedBy: {
    type: String,
    default: '',
  },
}
