<template>
  <modal
    id="company-create-modal"
    modal-class="modal-xl"
    modal-visibility
    @close="close"
  >
    <template #header>
      <span class="fad fa-building" />
      {{ t('company') }}
    </template>

    <template #body>
      <div class="v-company-edit">
        <company-form
          :title.sync="form.title"
          :type-ids.sync="form.company_type_ids"
          :ownership.sync="form.type_of_company"
          :inn.sync="form.individual_tax_number"
          :kpp.sync="form.kpp"
          :ogrn.sync="form.ogrn"
          :okved.sync="form.okved"
          :discount.sync="form.discount"
          :registration-date.sync="form.registration_date"
          :note.sync="form.note"
          :phone1.sync="form.phone1"
          :phone2.sync="form.phone2"
          :fax.sync="form.fax"
          :web.sync="form.web"
          :email.sync="form.email"
          :head-name.sync="form.head_name"
          :head-surname.sync="form.head_surname"
          :head-second-name.sync="form.head_second_name"
          :head-position.sync="form.head_position"
          :head-operates-by.sync="form.head_operates_by"
          :formal-address-index.sync="form.address_formal.index"
          :formal-address-region.sync="form.address_formal.region"
          :formal-nsi-russian-subject-id.sync="form.address_formal.nsi_russian_subject_id"
          :formal-address-country.sync="form.address_formal.country"
          :formal-address-area.sync="form.address_formal.area"
          :formal-address-city.sync="form.address_formal.city"
          :formal-address-street.sync="form.address_formal.street"
          :formal-address-house.sync="form.address_formal.house"
          :formal-address-flat.sync="form.address_formal.flat"
          :fact-address-index.sync="form.address_fact.index"
          :fact-address-region.sync="form.address_fact.region"
          :fact-nsi-russian-subject-id.sync="form.address_fact.nsi_russian_subject_id"
          :fact-address-country.sync="form.address_fact.country"
          :fact-address-area.sync="form.address_fact.area"
          :fact-address-city.sync="form.address_fact.city"
          :fact-address-street.sync="form.address_fact.street"
          :fact-address-house.sync="form.address_fact.house"
          :fact-address-flat.sync="form.address_fact.flat"
          :types="companyTypes"
          :validation-messages.sync="validationMessages"
          :loading="loading"
          :loading-types="loadingTypes"
        />
      </div>
    </template>

    <template #footer-right>
      <guarded-control
        tag="button"
        :permissions="['canManageCompany']"
        :disabled="hasErrors()"
        class="btn btn-success btn-with-icon modal-save"
        type="button"
        @click:allowed="submit"
      >
        <span class="btn-with-icon_icon fad fa-save" />
        <span class="btn-with-icon_text">
          {{ t('save') }}
        </span>
      </guarded-control>
      <button
        class="btn btn-primary btn-with-icon modal-close"
        type="button"
        @click="$emit('close')"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">
          {{ t('close') }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>
import GuardedControl from '@/vue_components/common/guarded_control'
import CompanyForm from '@/vue_components/companies/components/company_form/company_form'
import Modal from '@/vue_components/modal'
import { getDefaultValidationsCompany } from '@/vue_components/doctor_schedules/methods/appointment_default_data'
import { ValidationHolder } from '@/vue_components/mixins/validationHolder'
import { getDefaultCompanyData } from '@/vue_components/companies/components/consts'
import { companiesEndpoint } from '@/vue_components/companies/rest'
import { cloneDeep } from 'lodash'

export default {
  name: 'CompanyCreateModal',

  components: {
    GuardedControl,
    CompanyForm,
    Modal,
  },

  mixins: [ValidationHolder],

  props: {
    loadingTypes: Boolean,
    companyTypes: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      loading: false,
      validationMessages: getDefaultValidationsCompany(),
      form: getDefaultCompanyData(),
    }
  },

  methods: {
    close () {
      this.loading = false
      this.$emit('close')

      this.clearData()
    },

    clearData () {
      Object.assign(this.$data, this.$options.data())
      this.$nextTick(() => {
        this.validationMessages = getDefaultValidationsCompany()
      })
    },

    submit () {
      const form = cloneDeep(this.form)
      form.address_fact.nsi_russian_subject_id = typeof form.address_fact.nsi_russian_subject_id === 'object'
        ? form.address_fact.nsi_russian_subject_id?.id
        : form.address_fact.nsi_russian_subject_id

      form.address_formal.nsi_russian_subject_id = typeof form.address_formal.nsi_russian_subject_id === 'object'
        ? form.address_formal.nsi_russian_subject_id?.id
        : form.address_formal.nsi_russian_subject_id

      companiesEndpoint.create(form)
        .then((company) => {
          this.$emit('create', company)
          this.close()
          Notificator.success(t('record_successfully_created'))
        })
        .catch((error) => this.setValidationMessages(this.validationMessages, error.responseJSON))
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
