<template>
  <el-select
    v-model="value"
    size="small"
    :class="['appointment-selector', {selected: isSelected}]"
  >
    <el-option
      v-for="item in options"
      :key="item[0]"
      :label="item[1]"
      :value="item[0]"
    />
  </el-select>
</template>

<script>
import CardSelector from './selector.js'

export default {

  mixins: [CardSelector],
  data () {
    return {
      options: gon.specific.head_desktop.appointment_sources,
      key: 'appointment_source_id',
    }
  },
}
</script>
