<template>
  <div
    v-loading="loading"
    class="flex"
  >
    <validation-wrapper :errors="validationErrors">
      <items-list
        v-model="selectedItemsIds"
        :items="roles"
        displayed-item-field="title"
        id-item-field="id"
      />
    </validation-wrapper>
    <input
      v-for="item in selectedItemsIds"
      :key="`user[role_ids][${item}]`"
      type="hidden"
      name="user[role_ids][]"
      :value="item"
    >
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import ItemsList from '@/vue_components/items_list'
import { doctorPresenter } from '@/api_entities/doctors/doctorPresenter'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'

export default {
  name: 'UserRolesLegacy',
  components: { ValidationWrapper, ItemsList },
  mixins: [SpinnerHolder],

  props: {
    userId: PropsTypes.Number(),
    roles: PropsTypes.Array(),
  },

  data () {
    return {
      selectedItemsIds: [],
      validationErrors: [],
    }
  },

  created () {
    this.subscribeToErrorHandler()
    this.fetchRoles()
  },

  methods: {
    async fetchRoles () {
      if (!this.userId) { return }
      this.selectedItemsIds = await this.withSpinner(doctorPresenter.fetchRoles(this.userId))
    },

    subscribeToErrorHandler () {
      PubSub.subscribe('page.form.user.submitError', (name, { data }) => {
        this.validationErrors = data.roles || []
      })
    },
  },
}
</script>
