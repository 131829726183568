export default {
  _getScheduleTemplates (data) {
    const god = this

    Services.ui.spinner().show(true)

    $.ajax({
      url: Routes.schedule_templates_path(),
      method: 'POST',
      data,
      success (data) {
        god.$refs.scheduleTemplatesComponent.setScheduleTemplates(data)
      },
      error () {
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
      },
      complete () {
        Services.ui.spinner().show(false)
      },
    })
  },

  _sendData (data) {
    const god = this

    $.ajax({
      url: Routes.update_schedule_templates_path(),
      method: 'POST',
      data: {
        batch_update: JSON.stringify(data),
      },
      error () {
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
      },
      complete () {
        god.requestData = null
        Services.ui.spinner().show(false)
        god.$emit('close')
      },
    })
  },

  _removeScheduleTemplate (data) {
    const god = this

    $.ajax({
      url: Routes.employees_schedule_rollback_work_times_path(),
      method: 'POST',
      data,
      success () {
        if (data.rollback_all) {
          god.$refs.scheduleTemplatesComponent.removeIsomorphicTemplateById(data.template_id)
        } else {
          god.$refs.scheduleTemplatesComponent.updateTargetTemplateGroup(data)
        }
        god.$emit('hideRemoveScheduleTemplateModal')
      },
      error () {
        god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
      },
      complete () {
        Services.ui.spinner().show(false)
        god.$emit('showRemoveModal', {visibility: false})
      },
    })
  },
}
