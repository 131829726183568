import { fetchEntryType } from '@/specific/entry_types_helpers/fetchEntryType'

/**
 * @param {{id: number, _shortMode?: boolean}} entryType
 * @return {Promise<*|{ errors: true }>}
 */
export const addEntryType = async (entryType) => {
  if (entryType._shortMode) {
    return fetchEntryType(entryType.id)
  }

  return entryType
}
