import { MODAL_TYPES } from '@/vue_components/doctor_schedules/consts'
import { CONFIRMATION_MODES } from '@/vue_components/mixins/modals/const'
import { ModalHolder } from '@/vue_components/mixins/modals/modal_holder'
import ConfirmationModal from '@/vue_components/confirmation_modal'

/**
 * Эта миксина позволяет переиспользовать компонент ConfirmationModal.
 * Провайдит методы для показа и скрытия модалки, хранит данные для модалки,
 * регистрирует сам компонент (подключать его уже не нужно), а так же предоставляет
 * объект с пропсами, которые можно сразу прокинуть через v-bind.
 * Основная задача, которую решает эта миксина - обеспечение единообразности
 * подключения ConfirmationModal. Без неё нужно каждый раз описывать данные и
 * провайдить методы, от которых зависят дочерние компоненты.
 * Можно сделать более удобное решение через portal-vue, но его внедрение требует
 * времени, которого на момент релиза расписания нет.
 *
 * AlertHolder работает аналогичным образом и решает аналогичную задачу.
 */
export const ConfirmationHolder = {
  provide () {
    return {
      showConfirmation: this.showConfirmation,
      hideConfirmation: this.hideConfirmation,
    }
  },

  components: { ConfirmationModal },

  mixins: [ModalHolder],

  data () {
    return {
      modal: {
        [MODAL_TYPES.CONFIRMATION]: {
          visibility: false,
          headerMessage: '',
          message: '',
          mode: CONFIRMATION_MODES.DEFAULT,
          yesEvent () {},
          noEvent () {},
        },
      },
      CONFIRMATION_MODES,
    }
  },

  computed: {
    confirmationProps () {
      const modal = this.modal[MODAL_TYPES.CONFIRMATION]

      return {
        modalVisibility: modal.visibility,
        headerMessage: modal.headerMessage,
        message: modal.message,
        mode: modal.mode,
      }
    },
  },

  methods: {
    /**
     *
     * @param {string} question
     * @param {Function} yesHandler
     * @param {Function} noHandler
     * @param {string} [title]
     * @param {string} mode
     */
    showConfirmation (question, yesHandler, noHandler, title = '', mode = CONFIRMATION_MODES.DEFAULT) {
      Object.assign(this.modal[MODAL_TYPES.CONFIRMATION], {
        message: question,
        headerMessage: title,
        yesEvent: yesHandler,
        noEvent: noHandler,
        mode,
      })
      this.openModal(MODAL_TYPES.CONFIRMATION)
    },

    hideConfirmation () {
      this.closeModal(MODAL_TYPES.CONFIRMATION)
    },

    confirmationYesHandler () {
      this.modal[MODAL_TYPES.CONFIRMATION].yesEvent()
      this.hideConfirmation()
    },

    confirmationNoHandler () {
      this.modal[MODAL_TYPES.CONFIRMATION].noEvent()
      this.hideConfirmation()
    },
  },
}
