<template>
  <div class="appointment-time-finder-wrapper">
    <m-modal
      :visible.sync="modalVisibility"
      can-fullscreen
      width="80%"
      @close="close"
    >
      <template #header>
        <span>
          <i class="fad fa-calendar mr-5" />
          {{ referenceTooltip }}
        </span>
      </template>

      <template #body>
        <appointment-time-finder
          v-if="isRendered"
          :doctor-schedules-clinic-id="doctorSchedulesClinicId"
          @close="close"
          @showRecordingPointInSchedule="$emit('set-show-appointment', $event)"
          @createAppointmentInSchedule="$emit('set-create-appointment', $event)"
        />
      </template>

      <template #footer-left>
        <pagination
          v-if="vxTotalPages > 1"
          layout="prev, pager, next"
          :current-page="vxCurrentPage"
          :page-count="vxTotalPages"
          @current-change="vxSetPage"
        />
      </template>
    </m-modal>

    <slot name="reference">
      <button
        v-tooltip="referenceTooltip"
        type="button"
        class="btn btn-xs btn-grey-dark"
        @click="open"
      >
        <i class="fad fa-calendar mr-0" />
      </button>
    </slot>
  </div>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import AppointmentTimeFinder from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/AppointmentTimeFinder.vue'
import Pagination from '@/vue_components/common/pagination.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AppointmentTimeFinderRoot',
  components: { AppointmentTimeFinder, MModal, Pagination },
  props: {
    doctorSchedulesClinicId: { type: Number, required: true },
  },

  data () {
    return {
      modalVisibility: false,
      isRendered: false,
    }
  },

  computed: {
    ...mapGetters('appointmentTimeFinder/filtersBase', {
      vxCurrentPage: 'vxGetCurrentPage',
      vxTotalPages: 'vxGetTotalPages',
    }),

    referenceTooltip () {
      return t('reception.time_finder.name')
    },
  },

  methods: {
    ...mapMutations('appointmentTimeFinder/filtersBase', {
      vxSetPage: 'vxSetPage',
    }),

    close () {
      this.modalVisibility = false
    },

    open () {
      this.modalVisibility = true
      this.$nextTick(() => { this.isRendered = true })
    },
  },
}
</script>
