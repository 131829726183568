import { IJQueryHTMLElementWithDatepicker } from '@/_declarations/legacy/IJQueryHTMLElementWithDatepicker'
import { TDatePeriod } from '@/_declarations/TDatePeriod'

const isOptionExists = (parentSelector: string, optionValue: string): Boolean => {
  return Boolean(
    $(parentSelector)
      .find(`option[value='${optionValue}']`)
      .length
  )
}

/**
 * Набор функций для установки значений в legacy-элементы форм,
 * в том числе библиотеки Select2.
 */
export class LegacyFormSetters {
  static setDateRangePickerValue (selector: string, [start, end]: TDatePeriod): void {
    const elem = $(selector) as IJQueryHTMLElementWithDatepicker

    elem.daterangepicker(window.Utils.getDateRangePickerConfig({
      startDate: window.moment(start),
      endDate: window.moment(end),
    }))
  }

  static setSelectValue (selector: string, value: string): void {
    $(selector).val(value).trigger('change')
  }

  static setCheckboxValue (selector: string, value: string): void {
    $(selector).prop('checked', Boolean(value))
  }

  static setLazySelect2Value (selector: string, value: string, title: string): void {
    const select = $(selector)

    if (isOptionExists(selector, value)) {
      select.val(value).trigger('change')

      return
    }

    const optionDOMElem = new Option(title, value, true, true)
    select.append(optionDOMElem).trigger('change')
  }

  static setMultipleSelect2Value (selector: string, values: string[]): void {
    $(selector).val(values).trigger('change')
  }

  static setMultipleLazySelect2Value (selector: string, values: string[], titles: string[]): void {
    const select = $(selector)

    values.forEach((value, idx) => {
      if (!isOptionExists(selector, value)) {
        select.append(new Option(titles[idx], value, false, false))
      }
    })

    LegacyFormSetters.setMultipleSelect2Value(selector, values)
  }

  static setSelect2Value (selector: string, value: string): void {
    LegacyFormSetters.setSelectValue(selector, value)
  }

  static setButtons2Value (selector: string, value: string): void {
    LegacyFormSetters.setSelectValue(selector, value)
  }
}
