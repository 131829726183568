<template>
  <div
    class="sidebar-right-container hidden-print"
    :class="{ 'sidebar-right-container--collapsed': !GET_SHOW_RIGHT_PANEL_STATE }"
  >
    <panel-heading-collapsed
      direction="left"
      :show="!GET_SHOW_RIGHT_PANEL_STATE"
      :title="activeSidebarInfo.title"
      :icon="activeSidebarInfo.icon"
      class="sidebar-right-collapsed"
      :class="activeSidebarInfo.color"
      @panel-collapsed-toggle="setShowRightPanelStore(true)"
    />

    <panel-heading
      v-show="GET_SHOW_RIGHT_PANEL_STATE"
      slim
      :title="activeSidebarInfo.title"
      :icon="activeSidebarInfo.icon"
      class="sidebar-right-panel"
      :class="activeSidebarInfo.color"
    >
      <template #buttons>
        <span
          class="fad fa-angle-right"
          @click="setShowRightPanelStore(false)"
        />
      </template>

      <epic-spinner :visibility="!contextReady || GET_LOADING_RIGHT_SIDEBAR" />

      <calendar
        v-show="isActiveSidebarCalendar && contextReady"
        :user-ids="userIds"
        :cabinet-ids="cabinetIds"
        :clinics-workload="clinicsWorkload"
        :workload-ready="workloadReady"
      />

      <online-recording-list
        v-show="isActiveSidebarOnline && contextReady"
        :visibility="isActiveSidebarOnline && contextReady"
        :only-need-approval.sync="onlineRecordingNeedApproval"
        :online-recording-count="onlineRecordingCount"
        @change-only-need-approval="loadOnlineRecordingCount"
        @count-change="loadOnlineRecordingCount"
        @edit-appointment="$emit('edit-appointment-of-online', $event)"
        @show-appointment-in-schedule="$emit('show-appointment-in-schedule', $event)"
      />

      <reminders-list
        v-show="isActiveSidebarReminder && contextReady"
        :visibility="isActiveSidebarReminder && contextReady"
        :context-ready="contextReady"
        :current-user-id="currentUserId"
        :reminders-count.sync="remindersCount"
        @count-change="loadRemindersCount"
        @create-reminder-item="$emit('create-reminder-item')"
        @edit-reminder-item="$emit('edit-reminder-item', $event)"
      />

      <waiting-list
        v-show="isActiveSidebarWaiting && contextReady"
        :visibility="isActiveSidebarWaiting && contextReady"
        :preset="ADMINISTRATOR_PRESET"
        :current-user-id="currentUserId"
        :waiting-list-count="waitingListCount"
        @count-change="loadWaitingListCount"
        @filters-change="loadWaitingListCount"
        @create-waiting-item="$emit('create-waiting-item')"
        @edit-waiting-item="$emit('edit-waiting-item', $event)"
      />

      <btn-trigger-sidebar
        :online-recording-count="onlineRecordingCount"
        :reminders-count="remindersCount"
        :waiting-list-count="waitingListCount"
      />
    </panel-heading>
  </div>
</template>

<script>
import BtnTriggerSidebar from './components/btn_trigger_sidebar.vue'
import Calendar from './calendar/calendar.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import PanelHeadingCollapsed from '@/vue_components/common/panel_heading_collapsed.vue'
import OnlineRecordingList from './online_recording_list.vue'
import RemindersList from './reminders_list.vue'
import WaitingList from './waiting_list.vue'

import { mapActions, mapGetters } from 'vuex'
import {
  ACTIVE_SIDEBAR_INFO,
  ADMINISTRATOR_PRESET,
  SIDEBAR_CALENDAR,
  SIDEBAR_ONLINE_RECORDING,
  SIDEBAR_REMINDERS,
  SIDEBAR_WAITING_LIST,
} from './consts.js'
import { fetchOnlineCount, fetchRemindersCount, fetchWaitingListCount } from './rest'
import ClinicWorkload from '@/services/clinic_workload'
import { cloneDeep } from 'lodash'

export default {
  components: {
    BtnTriggerSidebar,
    Calendar,
    EpicSpinner,
    PanelHeading,
    PanelHeadingCollapsed,
    OnlineRecordingList,
    RemindersList,
    WaitingList,
  },

  props: {
    contextReady: Boolean,

    currentUserId: {
      type: Number,
      required: false,
      default: null,
    },

    currentClinicId: {
      type: Number,
      default: null,
    },

    filter: Boolean,

    userIds: {
      type: Array,
      required: true,
    },

    cabinetIds: {
      type: Array,
      required: true,
    },
  },

  data () {
    return {
      onlineRecordingCount: 0,
      remindersCount: 0,
      waitingListCount: 0,
      onlineRecordingNeedApproval: true,
      workloadReady: false,

      // для нормальной реакции на смену состояния contextReady
      waitingListCountFilters: {
        clinic_id: this.currentClinicId,
      },

      ADMINISTRATOR_PRESET,
    }
  },

  computed: {
    ...mapGetters([
      'GET_SCHEDULE_CURRENT_CLINIC_ID',
      'GET_LOADING_RIGHT_SIDEBAR',
      'GET_SCHEDULE_DATE',
      'GET_ACTIVE_SIDEBAR',
      'GET_SHOW_RIGHT_PANEL_STATE',
    ]),

    activeSidebarInfo () {
      switch (this.GET_ACTIVE_SIDEBAR) {
        case SIDEBAR_REMINDERS: return ACTIVE_SIDEBAR_INFO.REMINDERS
        case SIDEBAR_WAITING_LIST: return ACTIVE_SIDEBAR_INFO.WAITING_LIST
        case SIDEBAR_ONLINE_RECORDING: return ACTIVE_SIDEBAR_INFO.ONLINE_RECORDING
        case SIDEBAR_CALENDAR:
        default: return ACTIVE_SIDEBAR_INFO.CALENDAR
      }
    },

    isActiveSidebarCalendar () {
      return this.GET_ACTIVE_SIDEBAR === SIDEBAR_CALENDAR
    },

    isActiveSidebarOnline () {
      return this.GET_ACTIVE_SIDEBAR === SIDEBAR_ONLINE_RECORDING
    },

    isActiveSidebarReminder () {
      return this.GET_ACTIVE_SIDEBAR === SIDEBAR_REMINDERS
    },

    isActiveSidebarWaiting () {
      return this.GET_ACTIVE_SIDEBAR === SIDEBAR_WAITING_LIST
    },

    clinicsWorkload: {
      get () { return this.$store.getters.vxGetClinicsWorkload },
      set (payload) { this.$store.commit('vxSetClinicsWorkload', payload) },
    },
  },

  watch: {
    contextReady () {
      if (!this.contextReady) { return }
      this.$nextTick(() => { this.loadContext() })
    },
    onlineRecordingNeedApproval (withNeedApproval) {
      this.loadOnlineRecordingCount(withNeedApproval)
    },
  },

  created () {
    this.initWorkload()
  },

  methods: {
    ...mapActions(['setShowRightPanelStore']),

    loadContext () {
      this.loadRemindersCount()
      this.loadOnlineRecordingCount(this.onlineRecordingNeedApproval)
      this.loadWaitingListCount(this.waitingListCountFilters)
    },

    loadOnlineRecordingCount (onlyNeedApprovalAppointments = this.onlineRecordingNeedApproval) {
      if (!this.contextReady) return
      if (!this.$security.canViewAppointmentGrid) return

      fetchOnlineCount({
        only_need_approval: onlyNeedApprovalAppointments,
        clinic_id: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
      })
        .then((count) => {
          this.onlineRecordingCount = Number(count)
        })
    },

    loadRemindersCount () {
      if (!this.contextReady) return
      if (!this.$security.canViewReminder) return

      fetchRemindersCount({
        date: this.GET_SCHEDULE_DATE,
        current_clinic_id: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
      })
        .then((count) => {
          this.remindersCount = Number(count)
        })
    },

    loadWaitingListCount (filters) {
      this.waitingListCountFilters = cloneDeep(filters)
      if (!this.contextReady) return
      if (!this.$security.canViewWaitingList) return

      fetchWaitingListCount(filters)
        .then((count) => {
          this.waitingListCount = Number(count)
        })
    },

    async initWorkload () {
      this.workloadReady = false
      this.clinicsWorkload = await ClinicWorkload.connect()
      this.workloadReady = true

      this.subscribeToWorkloadUpdates()
    },

    subscribeToWorkloadUpdates () {
      const that = this
      ClinicWorkload.subscribe(
        /** @param {TClinicWorkLoadData} data */
        (data) => {
          that.workloadReady = false
          that.clinicsWorkload = data

          that.$nextTick(() => {
            that.workloadReady = true
          })
        })
    },
  },
}
</script>
