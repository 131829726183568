<template>
  <div class="new-user-notification">
    <div>{{ t('no_staff_leading_reception') }}</div>
    <div>
      <a :href="newUserPath">{{ t('create') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    newUserPath () {
      return window.Routes.new_user_path({userHasAppointment: true})
    }
  }
}
</script>
