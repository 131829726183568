const createDefaultRefuse = () => ({
  id: null,
  title: null,
  system_name: null,
})

const getValidationMessages = () => ({
  title: [],
})

export const creators = Object.freeze({
  formDataCreator: createDefaultRefuse,
  validationMessagesCreator: getValidationMessages,
})
