<template>
  <div>
    <div class="appointment-type-color-setting">
      <div class="form-label">
        <label for="reception_appointment_type[color]">
          {{ t('color') }}
        </label>
      </div>
      <div class="form-value">
        <el-color-picker
          id="reception_appointment_type[color]"
          name="reception_appointment_type[color]"
          color-format="rgb"
          :disabled="disabled"
          :predefine="PREDEFINED_COLORS"
          :value="color"
          @active-change="updateColor"
          @change="updateColor"
        />
        <input
          type="hidden"
          name="reception_appointment_type[color]"
          :value="color"
        >
      </div>
    </div>
  </div>
</template>

<script>
import rgb2hex from 'rgb2hex'
import { PREDEFINED_COLORS } from '@/vue_components/appointment/appointment_type/const'
import { APPOINTMENT_TYPE_DEFAULT_COLOR } from '@/specific/reception/appointment_durations/const'

export default {
  name: 'AppointmentTypeColorField',

  props: {
    color: {
      type: String,
      default: APPOINTMENT_TYPE_DEFAULT_COLOR,
    },

    disabled: Boolean,
  },

  data () {
    return {
      PREDEFINED_COLORS,
    }
  },

  methods: {
    updateColor (color) {
      this.$updateSync('color', rgb2hex(color || APPOINTMENT_TYPE_DEFAULT_COLOR).hex)
    },
  },
}
</script>
