<template>
  <div class="flex">
    <div class="form-label">
      <label>
        {{ addressMatchTitle }}
      </label>
    </div>

    <div class="form-value">
      <checkbox
        :checked="isMatch"
        @change="$updateSync('isMatch', $event)"
      />
    </div>

    <div class="form-label">
      <label :for="setCurrentFormInputId(ADDRESS_VALUES.INDEX)">
        {{ t('index') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        :id="setCurrentFormInputId(ADDRESS_VALUES.INDEX)"
        :name="setCurrentFormInputName(ADDRESS_VALUES.INDEX)"
        type="text"
        clearable
        :value="index"
        @input="$updateSync('index', $event)"
      />
    </div>

    <div class="form-label">
      <label :for="setCurrentFormInputId(ADDRESS_VALUES.COUNTRY)">
        {{ t('country') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        :id="setCurrentFormInputId(ADDRESS_VALUES.COUNTRY)"
        :name="setCurrentFormInputName(ADDRESS_VALUES.COUNTRY)"
        type="text"
        clearable
        :value="country"
        @input="$updateSync('country', $event)"
      />
    </div>

    <div class="form-label">
      <label :for="setCurrentFormInputId(ADDRESS_VALUES.REGION)">
        {{ t('region') }}
      </label>
    </div>

    <div class="form-value">
      <reusable-global-n-s-i-list
        v-if="useNSIRussianSubjectsDictionary"
        :value="nsiRussianSubjectId"
        :placeholder="t('region')"
        full-width
        dictionary="russian_subjects"
        @change="$updateSync('nsiRussianSubjectId', $event)"
        @changeItem="$updateSync('region', $event && $event.title)"
      />
      <el-input
        v-else
        :id="setCurrentFormInputId(ADDRESS_VALUES.REGION)"
        :name="setCurrentFormInputName(ADDRESS_VALUES.REGION)"
        type="text"
        clearable
        :value="region"
        @input="$updateSync('region', $event)"
      />
    </div>

    <div class="form-label">
      <label :for="setCurrentFormInputId(ADDRESS_VALUES.AREA)">
        {{ t('area') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        :id="setCurrentFormInputId(ADDRESS_VALUES.AREA)"
        :name="setCurrentFormInputName(ADDRESS_VALUES.AREA)"
        type="text"
        clearable
        :value="area"
        @input="$updateSync('area', $event)"
      />
    </div>

    <div class="form-label">
      <label :for="setCurrentFormInputId(ADDRESS_VALUES.CITY)">
        {{ t('attributes.city') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        :id="setCurrentFormInputId(ADDRESS_VALUES.CITY)"
        :name="setCurrentFormInputName(ADDRESS_VALUES.CITY)"
        type="text"
        clearable
        :value="city"
        @input="$updateSync('city', $event)"
      />
    </div>

    <div class="form-label">
      <label :for="setCurrentFormInputId(ADDRESS_VALUES.STREET)">
        {{ t('street') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        :id="setCurrentFormInputId(ADDRESS_VALUES.STREET)"
        :name="setCurrentFormInputName(ADDRESS_VALUES.STREET)"
        type="text"
        clearable
        :value="street"
        @input="$updateSync('street', $event)"
      />
    </div>

    <div class="form-label">
      <label :for="setCurrentFormInputId(ADDRESS_VALUES.HOUSE)">
        {{ t('house') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        :id="setCurrentFormInputId(ADDRESS_VALUES.HOUSE)"
        :name="setCurrentFormInputName(ADDRESS_VALUES.HOUSE)"
        type="text"
        clearable
        :value="house"
        @input="$updateSync('house', $event)"
      />
    </div>

    <div class="form-label">
      <label :for="setCurrentFormInputId(ADDRESS_VALUES.FLAT)">
        {{ t('attributes.flat') }}
      </label>
    </div>

    <div class="form-value">
      <el-input
        :id="setCurrentFormInputId(ADDRESS_VALUES.FLAT)"
        :name="setCurrentFormInputName(ADDRESS_VALUES.FLAT)"
        type="text"
        clearable
        :value="flat"
        @input="$updateSync('flat', $event)"
      />
    </div>
  </div>
</template>

<script>
import Checkbox from '@/vue_components/common/checkbox'
import { ADDRESS_TYPE_FORMS, ADDRESS_VALUES } from '../const'
import ReusableGlobalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableGlobalNSIList.vue'

export default {
  name: 'TabsAddressForm',
  components: {
    ReusableGlobalNSIList,
    Checkbox,
  },

  props: {
    formType: {
      type: String,
      required: true,
    },

    index: { type: String, default: null },

    country: { type: String, default: null },

    region: { type: String, default: null },

    nsiRussianSubjectId: { type: Number, default: null },

    area: { type: String, default: null },

    city: { type: String, default: null },

    street: { type: String, default: null },

    house: { type: String, default: null },

    flat: { type: String, default: null },

    isMatch: Boolean,
  },

  data () {
    return {
      ADDRESS_VALUES,
    }
  },

  computed: {
    addressMatchTitle () {
      return this.formType === ADDRESS_TYPE_FORMS.FORMAL
        ? t('matches_fact_address')
        : t('matches_formal_address')
    },

    useNSIRussianSubjectsDictionary () {
      return gon.application.nsi.russianSubjectsDictionary
    },
  },

  methods: {
    setCurrentFormInputId (elementId) {
      return `company_${this.formType}_${elementId}`
    },

    setCurrentFormInputName (elementName) {
      return `company[${this.formType}][${elementName}]`
    },
  },
}
</script>
