<template>
  <div class="image-cropper">
    <vue-cropper
      ref="cropper"
      class="cropped-image"
      preview=".preview"
      :src="imgSource"
      v-bind="settings"
    />

    <div
      v-if="preview"
      class="preview-box"
    >
      <div
        class="preview"
        :class="{ 'preview--rounded': rounded }"
      />
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import { getRoundedCanvas } from '@/vue_apps/ImageCropper/getRoundedCanvas'
import { viewModes } from '@/vue_apps/ImageCropper/const'

export default {
  name: 'ImageCropper',

  components: { VueCropper },

  props: {
    preview: {
      type: Boolean,
      default: false,
    },

    aspectRatio: {
      type: Number,
      default: 1, // NaN = free aspect ratio,
    },

    rounded: {
      type: Boolean,
      default: false,
    },

    imgSource: {
      type: String,
      required: true,
    },

    minContainerWidth: {
      type: Number,
      default: 250,
    },

    minContainerHeight: {
      type: Number,
      default: 400,
    },
  },

  emits: ['cropped-image'],

  data () {
    return {
      settings: {
        viewMode: viewModes.restrictCanvasSizeToFillFitContainer,
        dragMode: 'move',
        autoCropArea: 0.5,
        guides: false,
        background: true,
        rotatable: true,
        aspectRatio: this.aspectRatio,
        'min-container-width': this.minContainerWidth,
        'min-container-height': this.minContainerHeight,
      },
    }
  },

  watch: {
    imgSource () {
      this.$refs.cropper.replace(this.imgSource)
    },
  },

  methods: {
    cropImage () {
      const canvas = this.$refs.cropper.getCroppedCanvas()

      const croppedCanvas = this.rounded
        ? getRoundedCanvas(canvas)
        : canvas

      croppedCanvas.toBlob((blob) => {
        this.$emit('cropped-image', blob)
      })
    },
  },
}

</script>
