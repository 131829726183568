<template>
  <si-field>
    <div class="title">
      {{ staticFooter.total }}
    </div>
    <div
      :data-tooltip="footer.amount"
      class="amount cut"
    >
      {{ footer.amount }}
    </div>
    <div
      :data-tooltip="footer.price | currency"
      class="sum cut"
    >
      {{ footer.price | currency }}
    </div>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'

export default {
  name: 'ExpensesFooterGrouped',
  components: {
    SiField,
  },
  props: {
    footer: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      staticFooter: {
        total: t('total'),
      },
    }
  },
}
</script>

<style scoped>

</style>
