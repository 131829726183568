import {
  AttachmentTabHyperlinkModel,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/classes/AttachmentTabHyperlinkModel'

export class AttachmentTabHyperlinkLogic extends AttachmentTabHyperlinkModel {
  clear () {
    this.id = null
    this.link = null
    this.title = null
  }
}
