$document.on('rez/notifications/configurations/edit', () => {
  const $form = $('#notifications_configuration_form')
  const subBtn = $form.find('#notifications_configuration_submit').eq(0)
  var checkboxes = $('.notifications_configuration_types_active').find('input')

  $form.remote_form({
    model: 'notifications_configuration',
    onSuccess (res) {
      Notificator.info(t('configuration_updated'))
      subBtn.loadSpin('stop')
    },
    onError (res) {
      Notificator.error(t('errors.save_error'))
      console.error(res)
    },
  })

  checkboxes.on('click', function () {
    var delayField = $(this).closest('.notification_settings').children('.notifications_configuration_types_delay')
    if ($(this).prop('checked')) {
      delayField.show()
    } else {
      delayField.hide()
    }
  })

  checkboxes.each(function () {
    if ($(this).prop('checked')) {
      var delayField = $(this).closest('.notification_settings').children('.notifications_configuration_types_delay')
      delayField.show()
    }
  })
})
