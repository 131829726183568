<template>
  <div style="position: relative">
    <epic-spinner :visibility="showLoadingSpinner" />
    <div class="el-card__body__header">
      <div class="el-card__body__header__child">
        <div class="el-card__body__header__indicator">
          {{ today }}
        </div>
        <div class="el-card__body__header__explanation">
          {{ T.head_desktop.today }}
        </div>
      </div>
      <div class="el-card__body__header__child">
        <div class="el-card__body__header__indicator">
          {{ currentMonth }}
        </div>
        <div class="el-card__body__header__explanation">
          {{ T.head_desktop.current_month }}
        </div>
      </div>
      <div class="el-card__body__header__child">
        <div class="el-card__body__header__indicator">
          {{ averageMonth }}
        </div>
        <div class="el-card__body__header__explanation">
          {{ T.head_desktop.average_month }}
        </div>
      </div>
    </div>
    <div class="el-card__body__content">
      <div id="appointment-container" />
    </div>
    <div class="el-card__body__footer">
      <div class="el-card__body__footer__filters">
        <clinic-selector :card-name="cardName" />
        <appointment-source-selector :card-name="cardName" />
        <patient-type-selector :card-name="cardName" />
      </div>
      <div class="el-card__body__footer__actions" />
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import EpicSpinner from '../../../epic_spinner/epic_spinner.vue'
import ClinicSelector from '../selectors/clinic_selector.vue'
import AppointmentSourceSelector from '../selectors/appointment_source_selector.vue'
import PatientTypeSelector from '../selectors/patient_type_selector.vue'
import Card from './card.js'
import CardWithChart from './cardWithChart.js'

export default {
  components: {
    EpicSpinner,
    ClinicSelector,
    AppointmentSourceSelector,
    PatientTypeSelector,
  },
  mixins: [Card, CardWithChart],
  data () {
    return {
      route: this.$routes.reports_create_head_desktop_appointment_path(),
      today: null,
      currentMonth: null,
      averageMonth: null,
    }
  },
  computed: {
    requestParams () {
      return {
        clinic_id: this.cardParams.clinic_id,
        date_start: this.cardParams.date_range[0],
        date_end: this.cardParams.date_range[1],
        appointment_source_id: this.cardParams.appointment_source_id,
        patient_type: this.cardParams.patient_type,
      }
    },
  },
  methods: {
    cardAdapter (result) {
      this.chartData = result.appointment
      this.today = result.today
      this.currentMonth = result.current_month
      this.averageMonth = result.average_month
    },
    renderChart () {
      Highcharts.chart('appointment-container', {
        chart: {
          type: 'area',
          height: '240px',
        },
        series: [{
          name: this.T.head_desktop.appointments,
          color: '#FF9800',
          data: this.chartData,
        }],
        title: {
          text: '',
        },
        subtitle: {
          text: '',
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          visible: false,
        },
        yAxis: {
          title: {
            text: '',
          },
        },
      })
    },
  },
}
</script>
