
export const fetchTwoLevelsOfEntryTypesCategoriesAdapter = {

  /**
   * @typedef {{
   *   id: number,
   *   text: string,
   *   inc?: categoryRaw[]
   * }} categoryRaw
   *
   * @param {categoryRaw[]} response
   * @return {{
   *   id: number,
   *   title: string,
   *   children: {id: number, title: string, isLeaf: boolean}[]
   * }[]}
   */
  toClient (response = []) {
    return (response || []).map((category) => {
      const children = category.inc
        ? category.inc.map((child) => ({
          id: child.id,
          title: child.text,
          isLeaf: true,
        }))
        : undefined

      return {
        id: category.id,
        title: category.text,
        children,
      }
    })
  },
}
