<template>
  <context-popup ref="contextPopup">
    <div class="flex gap-indent-small">
      <!-- Врач -->
      <m-select-lazy
        v-show="showPerformer"
        ref="performer"
        v-model="currentPerformer"
        :label="t('performer')"
        :fetch-method="fetchPerformer"
        :m-fixed-height="false"
        option-label="shortName"
        filterable
        @changeByLazy="setValue('performer', $event)"
      />

      <!-- Ассистент -->
      <template v-if="useAssistants">
        <m-select-lazy
          v-show="showAssistant"
          ref="assistant"
          v-model="currentAssistant"
          :label="t('assistant')"
          :fetch-method="fetchAssistant"
          :m-fixed-height="false"
          option-label="shortName"
          filterable
          @changeByLazy="setValue('assistant', $event)"
        />
      </template>

      <!-- Реферал -->
      <m-select-lazy
        v-show="showReferral"
        ref="referral"
        v-model="currentReferral"
        :label="t('referral')"
        :fetch-method="fetchReferral()"
        :search-method="(params) => referralsPresenter.search(params)"
        :m-fixed-height="false"
        option-label="shortName"
        filterable
        @changeByLazy="setValue('referral', $event)"
      />

      <!-- Склад -->
      <m-select-lazy
        v-show="showStore"
        ref="store"
        v-model="currentStore"
        :label="t('store')"
        :fetch-method="(params) => storesPresenter.list(params)"
        :m-fixed-height="false"
        :disabled="storesDisabled"
        option-label="title"
        filterable
        :clearable="!storesDisabled"
        @changeByLazy="setValue('store', $event)"
      />

      <m-button template="close" />
    </div>
  </context-popup>
</template>

<script>
import ContextPopup from '@/vue_present/Reuse/ContextPopup/ContextPopup.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MUserPresenter } from '@/_api/MUser/MUserPresenter'
import { doctorPresenter } from '@/api_entities/doctors/doctorPresenter'
import { MReferralPresenter } from '@/_api/MReferral/MReferralPresenter'
import { MStorePresenter } from '@/_api/MStore/MStorePresenter'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { capitalize, parseInt } from 'lodash'
import { fetchUser } from '@/vue_apps/Orders/helpers/fetchUser'
import {
  getComplexIndexByComplexHeadItemNode,
  setComplexMembersAttributes,
} from '@/vue_apps/Orders/helpers/setComplexMembersAttributes'

export default {
  name: 'OrderBoostPopup',
  components: { MButton, MSelectLazy, ContextPopup },
  props: {
    targetContainer: { type: String, required: true },
    targetSelectorClass: { type: String, required: true },
    useAssistants: Boolean,
    storesDisabled: Boolean,
  },

  data () {
    return {
      doctorPresenter,
      assistantsPresenter: new MUserPresenter(),
      referralsPresenter: new MReferralPresenter(),
      storesPresenter: new MStorePresenter(),

      wasOpened: false,

      currentPerformer: null,
      currentAssistant: null,
      currentReferral: null,
      currentStore: null,

      currentTarget: null,

      isComplexHead: false,

      /** @type {TEntryFieldTarget} */
      currentTargetEntity: null,
    }
  },

  computed: {
    showPerformer () {
      return this.currentTargetEntity === 'performer'
    },

    showAssistant () {
      return this.currentTargetEntity === 'assistant'
    },

    showReferral () {
      return this.currentTargetEntity === 'referral'
    },

    showStore () {
      return this.currentTargetEntity === 'store'
    },
  },

  mounted () {
    const container = document.querySelector(this.targetContainer)
    if (!container) { return }
    container.addEventListener('click', this.onClick)
  },

  beforeDestroy () {
    const container = document.querySelector(this.targetContainer)
    if (!container) { return }
    container.removeEventListener('click', this.onClick)
  },

  methods: {
    camelToSnake,
    snakeToCamel,

    fetchData () {
      ;[
        this.$refs.performer,
        this.$refs.assistant,
        this.$refs.referral,
        this.$refs.store,
      ].forEach((ref) => ref?.onLoadMore(true))
    },

    /** @param {PointerEvent} event */
    onClick (event) {
      if (event.target.classList.contains('order-entry-disabled-field')) { return }
      if (!event.target.classList.contains(this.targetSelectorClass)) { return }
      if (!this.wasOpened) {
        this.wasOpened = true
        this.fetchData()
      }

      this.currentTarget = event.target

      this.currentTargetEntity = event.target.dataset.entity

      this.isComplexHead = event.target.parentNode.classList.contains('f-order-entry-complex')

      const id = event.target.querySelector('input')?.value
      const label = event.target.querySelector('.order-boost__label')?.innerText.trim()

      /** @type {TEntryFieldTarget} */
      const entity = event.target.dataset.entity

      const attribute = entity === 'store' ? 'title' : 'shortName'
      this[`current${capitalize(entity)}`] = { id: parseInt(id), [attribute]: label }

      this.$refs.contextPopup.onContextMenuHandler(event)
    },

    /**
     * @param {TEntryFieldTarget} target
     * @param {{id: number, shortName: string, title: string}} value
     */
    setValue (target, value) {
      this.currentTarget.querySelector('input').value = value?.id || ''
      this.currentTarget.querySelector('.order-boost__label').innerText = value?.title || value?.shortName || ''
      this.currentTarget.querySelector('.has-error')?.classList?.remove('has-error')

      if (this.isComplexHead && this.showReferral) {
        this.__setReferralInComplexItems(value)
      }

      this.currentTarget = null
      this.$refs.contextPopup.close()
    },

    __setReferralInComplexItems (value) {
      const currentComplexIndex = getComplexIndexByComplexHeadItemNode(this.currentTarget)

      setComplexMembersAttributes(
        currentComplexIndex,
        'referral_id',
        value.id
      )
    },

    fetchPerformer (sortParams) {
      return fetchUser((params) => doctorPresenter.fetchAll(params, snakeToCamel), sortParams)
    },

    fetchAssistant (sortParams) {
      return fetchUser((params) => this.assistantsPresenter.list(params), sortParams)
    },

    fetchReferral () {
      return (params) => this.referralsPresenter.list({
        ...params,
        withInternal: true,
      })
    },
  },
}
</script>
