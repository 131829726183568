<template>
  <component
    :is="tag"
    class="si-column"
    @click="setSorting"
  >
    <slot />
    <div
      class="sort-state__container"
      :class="[{ active }]"
    >
      <span
        class="sort-state sort-state_asc fad fa-caret-up"
        :class="{ 'sort-state_active': upActive }"
      />
      <span
        class="sort-state sort-state_desc fad fa-caret-down"
        :class="{ 'sort-state_active': downActive }"
      />
    </div>
  </component>
</template>

<script>

const sortStates = {
  asc: { name: 'asc', next: 'desc', order: true },
  desc: { name: 'desc', next: 'noSort', order: false },
  noSort: { name: 'noSort', next: 'asc', order: null },
}

export default {
  name: 'SiColumn',
  props: {
    name: {
      type: String,
      required: true,
    },

    active: {
      type: String,
      required: true,
    },

    tag: {
      type: String,
      default: 'div',
    },
  },

  data () {
    return {
      order: sortStates.noSort.name,
    }
  },

  computed: {
    isActive () {
      return this.active === this.name
    },

    upActive () {
      return this.isActive && this.order === sortStates.asc.name
    },

    downActive () {
      return this.isActive && this.order === sortStates.desc.name
    },
  },

  watch: {
    active () {
      if (!this.isActive) {
        this.order = sortStates.noSort.name
      }
    },
  },

  methods: {
    setSorting () {
      this.order = sortStates[this.order].next

      if (this.order === sortStates.noSort.name) {
        this.$emit('sort-change', {})

        return
      }

      this.$emit('sort-change', {
        name: this.name,
        order: sortStates[this.order].order,
      })
    },
  },
}
</script>
