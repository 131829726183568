import Vue from 'vue/dist/vue.esm'
import Calls from '@/vue_components/reports/calls.vue'
import { fetchExportFile, defineDateFormat } from './export.js'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'
import { ReportParamFactory } from '@/specific/reports/shared'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

$document.on('rez/reports/calls', function () {
  Report.init()

  let tableHeader = [
    { title: '№' },
    { title: T.date_translate },
    { title: T.telephony.type },
    { title: T.status },
    { title: T.responsible_users },
    { title: T.client },
    { title: T.phone },
    { title: T.duration },
    { title: T.result },
    { title: T.telephony.refuse_result },
    { title: T.comment },
    { title: T.attraction_source },
  ]

  const reportTable = new Vue({
    el: '#report_right_container',
    components: { Calls },
    mixins: [ReportWrapper],
    data: () => ({
      filters: getFilters(),
    }),
    template: `
      <calls
        ref="report"
        :isHidden="isHidden"
        :clinic="filters.clinic"
        :period="filters.period"
        :user="filters.user"
        :attractionSources="filters.attractionSources"
        :callType="filters.callType"
        :callStatus="filters.callStatus"
        :callResult="filters.callResult"
        :refuseReason="filters.refuseReason"
      />`,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(getFilters())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', function () {
    const filters = getFilters()

    let data = {
      'dateTimeFormat': `${defineDateFormat()}, 15:04`,
      'csvComma': gon.application.csv_character,
      'headerRow': {
        'callId': tableHeader[0].title,
        'callDateTime': tableHeader[1].title,
        'callType': tableHeader[2].title,
        'callStatus': tableHeader[3].title,
        'responsibleSurnameInitials': tableHeader[4].title,
        'clientFullName': tableHeader[5].title,
        'callPhone': tableHeader[6].title,
        'callDuration': tableHeader[7].title,
        'callResult': tableHeader[8].title,
        'refuseResultTitle': tableHeader[9].title,
        'callNote': tableHeader[10].title,
        'attractionSourceTitle': tableHeader[11].title,
      },
      'callType': {
        'incoming': T.enums.call.call_type.incoming,
        'outcoming': T.enums.call.call_type.outcoming,
      },
      'callStatus': {
        'accepted': T.enums.call.status.accepted,
        'lost': T.enums.call.status.lost,
      },
      'callResult': {
        'inProgress': T.enums.call.result.in_progress,
        'appointmentAdded': T.enums.call.result.appointment_added,
        'refused': T.enums.call.result.refused,
        'finished': T.enums.call.result.finished,
        'appointmentConfirmed': T.enums.call.result.appointment_confirmed,
        'appointmentCanceled': T.enums.call.result.appointment_canceled,
        'onHold': T.enums.call.result.on_hold,
      },
      'offset': 0,
      'limit': -(1 << 32 - 1),
      'report': {
        'period': filters.period,
      },
      ...ReportFiltersFactory.defineFor('calls', filters),
    }

    fetchExportFile(data, Routes.report_calls_path(), T.telephony.calls)
  })

  function getFilters () {
    return {
      clinic: ReportParamFactory.getSelectParam('#report_clinic_id'),
      period: ReportParamFactory.getRawParam('.period'),
      user: ReportParamFactory.getSelectParam('#report_user_id'),
      attractionSources: ReportParamFactory.getSelect2Params('#report_attraction_source_ids'),
      callType: ReportParamFactory.getButtonsParam('#report_call_type_id'),
      callStatus: ReportParamFactory.getButtonsParam('#report_call_status_id'),
      callResult: ReportParamFactory.getSelectParam('#report_call_result_id'),
      refuseReason: ReportParamFactory.getSelectParam('#report_refuse_result_id'),
    }
  }
})
