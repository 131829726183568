<template>
  <catalog-wrapper
    model="ReceptionAppointmentDuration"
    catalog-icon="fad fa-fw fa-clock"
    :table-title="t('reception.appointment_durations')"
    :form-create-title="t('reception.creating_appointment_duration')"
    :form-edit-title="t('reception.appointment_durations')"
    :add-item-text="t('add_appointment_duration')"
    :items="durations"
    :loading="loadingList"
    :selected-mode="selectedMode"
    :is-show-form="isShowForm"
    :first-loading-completed="firstListRequestCompleted"
    :is-pagination-enabled="false"

    @ask-create="openCreateForm"
    @close="hideForm"
    @submit="handleSubmit"
    @ask-delete="destroyDuration"
  >
    <template #table>
      <appointment-durations-table
        :durations="durations"
        :selected-list-item="selectedListItem"
        @ask-edit="openEditForm"
      />
    </template>

    <template #form>
      <appointment-duration-form
        :form-value.sync="formData.duration"
        :validation-errors="validationMessages"
      />
    </template>
  </catalog-wrapper>
</template>

<script>
import { isEqual } from 'lodash'
import CatalogWrapper from '../catalog_wrapper/catalog_wrapper_deprecated.vue'
import AppointmentDurationsTable from './components/appointment_durations_table'
import AppointmentDurationForm from './components/appointment_duration_form'
import { CatalogHolder } from '@/vue_components/mixins/catalogs/catalogHolder'
import { requiredValidator } from '@/lib/validators/validators'
import { durationsEndpoint } from '@/api_entities/catalogs/appointment_durations/appointment_durations_endpoint'
import { MODES, NOTIFICATOR_LIFETIME } from '../const'
import { creators } from './const'

export default {
  name: 'AppointmentDurationsCatalog',
  components: {
    CatalogWrapper,
    AppointmentDurationsTable,
    AppointmentDurationForm,
  },

  mixins: [CatalogHolder],

  data () {
    return {
      durations: [],
    }
  },

  watch: {
    'formData.duration' (newValue) {
      this.validate('duration', newValue, requiredValidator)
    },
  },

  created () {
    this.initCatalogHolder(creators)
    this.getDurations()
  },

  methods: {
    getDurations () {
      this.loadingList = true

      durationsEndpoint.getAll()
        .then((data) => {
          this.durations = data

          if (this.durations.length) {
            this.durations.forEach((item) => {
              item.duration = String(item.duration)
            })
          }
        })
        .catch((err) => Utils.reportError(
          'getDurations error',
          t('reception.errors.get_list')
        )(err))
        .finally(() => {
          this.loadingList = false
          this.firstListRequestCompleted = true
        })
    },

    createDuration (data) {
      durationsEndpoint.create(data)
        .then(() => {
          this.clearForm()
          this.getDurations()

          Notificator.success(
            t('record_successfully_created'),
            '',
            NOTIFICATOR_LIFETIME
          )
        })
        .catch((err) => {
          if (err instanceof Error) {
            Utils.reportError(
              'createDuration error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.create') }
              )
            )(err)
          } else {
            this.setValidationMessages(this.validationMessages, err.responseJSON)
          }
        })
    },

    updateDuration (data) {
      if (isEqual(this.previousFormData, data)) {
        Notificator.warning(
          t('reception.dont_find_update'),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      durationsEndpoint.update(data)
        .then(() => {
          this.getDurations()
          this.previousFormData = { ...data }

          Notificator.info(
            t('changes_updated'),
            '',
            NOTIFICATOR_LIFETIME
          )
        })
        .catch((err) => {
          if (err instanceof Error) {
            Utils.reportError(
              'updateDuration error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.update') }
              )
            )(err)
          } else {
            this.setValidationMessages(this.validationMessages, err.responseJSON)
          }
        })
    },

    destroyDuration () {
      durationsEndpoint.destroy(this.formData.id)
        .then(() => {
          this.getDurations()
          this.hideForm()

          Notificator.success(
            t('record_successfully_deleted'),
            '',
            NOTIFICATOR_LIFETIME
          )
        })
        .catch((err) => {
          if (err.status === 422) {
            Utils.reportError('destroyDuration error', `${err.responseJSON._popup_[0]}`)(err)
          } else {
            Utils.reportError(
              'destroyDuration error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.delete') }
              )
            )(err)
          }
        })
    },

    handleSubmit () {
      if (this.hasErrors()) return

      if (this.selectedMode === MODES.NEW) {
        this.createDuration(this.formData)
      } else {
        this.updateDuration(this.formData)
      }
    },
  },
}
</script>
