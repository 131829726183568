<template>
  <div>
    <button
      class="btn btn-sm btn-success btn-with-icon modal-save"
      @click="$emit('deleteThisDayOnly')"
    >
      <span class="btn-with-icon_icon fad fa-trash-alt" />
      <span class="btn-with-icon_text">{{ T.delete_this_day_only }}</span>
    </button>
    <button
      v-if="!beginningOfTheInterval"
      class="btn btn-sm btn-warning btn-with-icon modal-save"
      @click="$emit('removeFromThisDay')"
    >
      <span class="btn-with-icon_icon fad fa-trash-alt" />
      <span class="btn-with-icon_text">{{ T.remove_from_this_day }}</span>
    </button>
    <button
      class="btn btn-sm btn-danger btn-with-icon modal-save"
      @click="$emit('deleteAll')"
    >
      <span class="btn-with-icon_icon fad fa-exclamation-circle" />
      <span class="btn-with-icon_text">{{ T.delete_all }}</span>
    </button>
    <button
      class="btn btn-sm btn-primary btn-with-icon modal-close"
      @click="$emit('close')"
    >
      <span class="btn-with-icon_icon fad fa-times" />
      <span class="btn-with-icon_text">{{ T.close }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'RemoveWorkTimeModalFooter',
  props: {
    beginningOfTheInterval: Boolean,
  },
}
</script>
