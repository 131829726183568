$document.on('rez/specialties/new rez/specialties/edit', function () {
  var form = FormFactory.build('specialty')

  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      break
    case 'edit':
      form.editItem()
      break
  }
})
