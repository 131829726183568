import { GLOBAL_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import { addEntryType } from '@/specific/entry_types_helpers/addEntryType'

export const tpAddEntryTypeCallback = async (entryType) => {
  const item = await addEntryType(entryType)
  if (item.errors) { return }

  PubSub.emit(
    'page.form.treatment_plan.treatmentPlanList.askAddEntry',
    { item }
  )

  setTimeout(() => {
    Utils.reportSuccess(t('entryAdded'))()
  }, GLOBAL_DEBOUNCE_DEFAULT)
}
