import { omit, omitBy, isUndefined } from 'lodash'
import {
  IProceduresReportFiltersGet as FiltersGet,
  IProceduresReportFiltersSet as FiltersSet,
} from '@/specific/reports/procedures/interfaces'
import {
  IProceduresReportFiltersProfileFiltersToClient as IFiltersToClient,
  IProceduresReportFiltersProfileFiltersToServer as IFiltersToServer,
} from '../../interfaces/filtersTypes/proceduresReport'
import { IFiltersProfilesFetchResponse } from '../../interfaces/IFIltersProfilesFetchResponse'
import { cleanUnpermittedValues } from '../../helpers/formatters/shared'
import { IFiltersProfilesModel } from '../../interfaces/IFiltersProfilesModel'
import { FiltersProfilesLegacyFormattersToServer as FormattersToServer } from '../../helpers/formatters/legacy/toServer'
import { FiltersProfilesLegacyFormattersToClient as FormattersToClient } from '../../helpers/formatters/legacy/toClient'
import { gatherErrors } from '../../helpers/gatherErrors'

const extractFullNameFromHumanDataString = (dataString: string): string => dataString.split(',')[0]

export const proceduresReportFiltersProfileAdapter = {
  toServer (data: IFiltersProfilesModel<FiltersGet>): IFiltersProfilesModel<IFiltersToServer> {
    const { data: filters } = data

    const adaptedFilters: IFiltersToServer = {
      ...omit(filters, ['entryTypes', 'clientGroups', 'doctorSpecialtiesIds', 'entryTypeCategories']),
      period: FormattersToServer.formatPeriod(filters.period),
      clinic: FormattersToServer.formatSingle(filters.clinic),
      entryWithOrder: FormattersToServer.formatCheckbox(filters.entryWithOrder),
      entryWithDiscount: FormattersToServer.formatCheckbox(filters.entryWithDiscount),
      entryType: FormattersToServer.formatMulti(filters.entryTypes),
      client: FormattersToServer.formatHuman(filters.client, false),
      clientGroup: FormattersToServer.formatMulti(filters.clientGroups),
      clientSex: FormattersToServer.formatSingle(filters.clientSex),
      clientStatus: FormattersToServer.formatSingle(filters.clientStatus, false),
      category: FormattersToServer.formatMulti(filters.entryTypeCategories),
      entryKind: FormattersToServer.formatSingle(filters.entryKind),
      administrator: FormattersToServer.formatHuman(filters.administrator, false),
      doctor: FormattersToServer.formatHuman(filters.doctor, false),
      specialty: FormattersToServer.formatMulti(filters.doctorSpecialtiesIds),
    }

    if (adaptedFilters.client) {
      const clientDataString = adaptedFilters.client[0].fullName
      adaptedFilters.client[0].fullName = extractFullNameFromHumanDataString(clientDataString)
    }

    return { ...data, data: adaptedFilters }
  },

  toClient (data: IFiltersProfilesFetchResponse<IFiltersToClient>): IFiltersProfilesModel<FiltersSet> {
    const { data: filters } = data

    const validFilters = omitBy(cleanUnpermittedValues(filters), isUndefined) as IFiltersToClient

    const adaptedFilters: FiltersSet = {
      period: FormattersToClient.formatPeriod(validFilters.period),
      clinic: FormattersToClient.formatSingle(validFilters.clinic),
      entryWithOrder: FormattersToClient.formatCheckbox(validFilters.entryWithOrder),
      entryWithDiscount: FormattersToClient.formatCheckbox(validFilters.entryWithDiscount),
      entryTypes: FormattersToClient.formatMulti(validFilters.entryType),
      client: FormattersToClient.formatHuman(validFilters.client),
      clientGroups: FormattersToClient.formatMulti(validFilters.clientGroup),
      clientSex: FormattersToClient.formatSingle(validFilters.clientSex),
      clientStatus: FormattersToClient.formatSingle(validFilters.clientStatus),
      entryTypeCategories: FormattersToClient.formatMulti(validFilters.category),
      entryKind: FormattersToClient.formatSingle(validFilters.entryKind),
      administrator: FormattersToClient.formatHuman(validFilters.administrator),
      doctor: FormattersToClient.formatHuman(validFilters.doctor),
      doctorSpecialtiesIds: FormattersToClient.formatMulti(validFilters.specialty),
    }

    const definedAdaptedFilters = omitBy(adaptedFilters, isUndefined) as FiltersSet

    const renamedFilters = omitBy({
      ...omit(filters, 'entryType', 'specialty', 'clientGroup', 'category'),
      entryTypes: filters.entryType,
      doctorSpecialtiesIds: filters.specialty,
      clientGroups: filters.clientGroup,
      entryTypeCategories: filters.category,
    }, isUndefined)

    return {
      ...data,
      data: definedAdaptedFilters,
      dataErrors: gatherErrors(renamedFilters),
    }
  },
}
