import Vue from 'vue/dist/vue.esm'
import DoctorSchedules from '@/vue_components/doctor_schedules/doctor_schedules.vue'
import { createStore } from '@/vue_components/store/store.js'

$document.on('rez/doctor_schedules', function () {
  // eslint-disable-next-line
  new Vue({
    store: createStore(),
    el: '#app',
    name: 'DoctorSchedules',
    beforeCreate () {
      this.$store.dispatch('initialScheduleStore')
      this.$store.dispatch('loadInitialDataSchedule', this.$store.getters.GET_SCHEDULE_CURRENT_CLINIC_ID)
      this.$store.dispatch('loadAttractionSources')
    },
    render: (h) => h(DoctorSchedules),
  })
})
