<template>
  <form-block :title="T.matches">
    <table
      v-if="clients.length > 0"
      class="table table-striped table-hover fixed similar-clients-list"
    >
      <tbody v-if="selected">
        <similar-clients-entry
          :client="selected"
          @click.native="onClientClick(null)"
        />
      </tbody>
      <tbody v-else>
        <similar-clients-entry
          v-for="client in clients"
          :key="client.id"
          :client="client"
          @click.native="onClientClick(client)"
        />
      </tbody>
    </table>
  </form-block>
</template>

<script>
import SimilarClientsEntry from '@/vue_components/appointment/similar_clients_entry'
import FormBlock from '@/vue_components/common/form_block'
/**
 * @typedef {import('./similar_clients_entry.vue').SimilarClient} SimilarClient
 */

export default {
  name: 'AppointmentSimilarClients',
  components: { FormBlock, SimilarClientsEntry },
  props: {
    /**
     * @type {import('vue').PropOptions<SimilarClient[]>}
     */
    clients: {
      type: Array,
      required: true,
    },
    /**
     * @type {import('vue').PropOptions<SimilarClient|null>}
     */
    selected: {
      type: Object,
      default: null,
    },
  },
  methods: {
    /**
     * @param {SimilarClient|null} client
     */
    onClientClick (client) {
      this.selectClient(client)
    },
    selectClient (client) {
      this.$emit('update:selected', client)
    },
  },
}
</script>
