import {
  EntryTypesTransferListItem,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/EntryTypesTransfer/store/EntryTypesTransferListItem'

export const entryTypesTransferAdapter = {
  toClient (response) {
    return {
      data: (response.data || []).map((item) => new EntryTypesTransferListItem(item)),
      totalPages: response.totalPages,
      totalItems: response.totalItems,
    }
  },
}
