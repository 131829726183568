import { MReportPresenterBase } from '@/_api/Reports/_base/MReportPresenterBase'
import {
  treatmentPlansReportAdapter,
} from '@/vue_apps/ReportsModule/TreatmentPlansReports/api/treatmentPlansReportAdapter'

export class MTreatmentPlanReportPresenter extends MReportPresenterBase {
  constructor () {
    super(Routes.report_treatment_plans_path)
  }

  /**
   * @param data
   * @param config
   * @return {Promise<ITreatmentPlansReportList>}
   */
  list (data, config) {
    return super.generate(data, {
      ...config,
      toClientAdapter: treatmentPlansReportAdapter.toClient,
      toServerAdapter: treatmentPlansReportAdapter.toServer,
    })
  }

  export (data = {}, fileName) {
    super.export(data, fileName)
  }
}
