import { ReportParamFactory } from '@/specific/reports/shared'
import { strToBoolean } from '@/helpers/typeHelpers'
import { REFERRALS_SUMMARY } from '@/specific/reports/referrals/common'

export default function (source) {
  if (source.reportType === REFERRALS_SUMMARY) {
    return {
      clinicIds: ReportParamFactory.buildArrayParam(source.clinic),
      reportType: source.reportType,
      includingDiscounts: strToBoolean(source.includingDiscounts),
      entryWithOrder: strToBoolean(source.entryWithOrder),

      entryTypeIds: [],
      entryTypeCategoryIds: [],
      doctorIds: [],
      addressCities: [],
      referralId: '',
    }
  }

  return {
    clinicIds: ReportParamFactory.buildArrayParam(source.clinic),
    entryTypeIds: ReportParamFactory.buildArrayParam(source.entry),
    entryTypeCategoryIds: ReportParamFactory.buildArrayParam(source.category),
    doctorIds: ReportParamFactory.buildArrayParam(source.medicalUser),
    addressCities: ReportParamFactory.buildArrayParam(source.city),
    referralId: source.referral.id,
    reportType: source.reportType,
    includingDiscounts: strToBoolean(source.includingDiscounts),
    entryWithOrder: strToBoolean(source.entryWithOrder),
  }
}
