
export const userHasCurrentClinic = (userClinicsIds = []) =>
  userClinicsIds?.find((id) => id === gon.application.current_clinic.id)

export const getDefaultUser = (user) => {
  if (gon.page.action !== 'new') { return user }

  return userHasCurrentClinic(user?.clinicIds)
    ? user
    : null
}
