import { createVueApp } from '@/vue_components/create_vue_app'

import TreatmentPlansListClientApp from '@/vue_apps/TreatmentPlans/TreatmentPlansList/TreatmentPlansListClientApp.vue'
import TreatmentPlansListEmkApp from '@/vue_apps/TreatmentPlans/TreatmentPlansList/TreatmentPlansListEmkApp.vue'

let vueApp = null

export const createTreatmentPlansListEmkApp = (el = '#vue_client_treatment_plans', clientId = null) => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'TreatmentPlansListEmkAppRoot',
    render: (h) => h(TreatmentPlansListEmkApp, {
      props: {
        clientId,
        isMedBranch: Utils.branch.isMed,
        isDentBranch: Utils.branch.isDent,
      },
    }),
  })
}

export const createTreatmentPlansListClientApp = (el = '#vue_client_treatment_plans_app', clientId = null) => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'TreatmentPlansListAppRoot',
    render: (h) => h(TreatmentPlansListClientApp, {
      props: {
        clientId,
        isMedBranch: Utils.branch.isMed,
        isDentBranch: Utils.branch.isDent,
      },
    }),
  })
}
