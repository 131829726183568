<template>
  <simple-select
    :value="value"
    :fixed-height="false"
    :items="items"
    filterable
    multiple
    collapse-tags
    clearable
    value-key="id"
    popper-class="user-dropdown-list"
    @change="$emit('change', $event)"
  >
    <template
      v-for="item in items"
      #option="{ item }"
    >
      <el-option
        :key="item.id"
        :value="item"
        :label="item.short_name"
      >
        <div class="user-dropdown">
          <user-avatar
            :user-id="item.id"
            :params="{version: 'thumb25'}"
          />
          <span>
            {{ item.short_name }}
          </span>
        </div>
      </el-option>
    </template>
  </simple-select>
</template>

<script>
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect.vue'
import UserAvatar from '@/vue_components/user_avatar.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'DoctorMultiSelect',
  components: { UserAvatar, SimpleSelect },
  props: {
    value: PropsTypes.Array(),
    items: PropsTypes.Array(),
  },
}
</script>
