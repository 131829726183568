<template>
  <catalog-wrapper
    model="DentalCompany"
    custom-table-class="dental-companies-table"
    custom-form-class="dental-companies-form"
    catalog-icon="fad fa-fw fa-tooth"
    :table-title="t('dental_companies')"
    :form-create-title="t('creating_dental_company')"
    :form-edit-title="t('dental_company')"
    :add-item-text="t('add_dental_company')"
    :items="dentalCompanies"
    :loading="loadingList"
    :selected-mode="selectedMode"
    :is-show-form="isShowForm"
    :first-loading-completed="firstListRequestCompleted"
    :total-pages="totalPages"

    @ask-create="openCreateForm"
    @close="hideForm"
    @submit="handleSubmit"
    @ask-delete="destroyDentalCompany"
    @change-pagination="onPageChange"
  >
    <template #table>
      <dental-companies-table
        :dentals="dentalCompanies"
        :selected-list-item="selectedListItem"
        :is-show-form="isShowForm"

        @ask-edit="openEditForm($event, getDentalData)"
      />
    </template>

    <template #form>
      <dental-companies-form
        v-if="isShowForm"
        v-bind.sync="formData"
        :validation-errors="validationMessages"
        :loading="!!lastGetCurrentDataRequest"

        @open-modal="toggleCompanyModal"
        @phone-mask-settings-change="phoneMaskSettings = $event"
        @phone-blur-input="validatePhoneInput"
      />
    </template>

    <template #extra>
      <company-form-tabbed
        :is-open="isShowCompanyModal"
        is-all-forms-in-tabs
        @close="toggleCompanyModal"
        @create="updateCompanyInfo"
      />
    </template>
  </catalog-wrapper>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'
import CatalogWrapper from '../catalog_wrapper/catalog_wrapper_deprecated.vue'
import DentalCompaniesTable from './components/dental_companies_table.vue'
import DentalCompaniesForm from './components/dental_companies_form.vue'
import CompanyFormTabbed from '@/vue_components/companies/components/company_form_tabbed/company_form_tabbed'
import { PaginationHolder } from '@/vue_components/mixins/pagination_holder'
import { CatalogHolder } from '@/vue_components/mixins/catalogs/catalogHolder'
import { createPhoneValidator, requiredValidator } from '@/lib/validators/validators'
import { dentalCompaniesEndpoint } from '@/api_entities/catalogs/dental_companies/dental_companies_endpoint'
import { trimValues } from '../../helpers'
import { findPreExistingTitle } from '../helpers'
import { creators, PAGINATION_LIMIT } from './const'
import { MODES, NOTIFICATOR_LIFETIME } from '../const'

export default {
  name: 'DentalCompaniesCatalog',
  components: {
    CatalogWrapper,
    DentalCompaniesTable,
    DentalCompaniesForm,
    CompanyFormTabbed,
  },

  mixins: [PaginationHolder, CatalogHolder],

  data () {
    return {
      dentalCompanies: [],
      isShowCompanyModal: false,
      phoneMaskSettings: { ...Services.phoneMask.defaultMaskSettings },

      openedFirstTimeBySearchQuery: true,
    }
  },

  watch: {
    'formData.title' (newValue) {
      this.validate('title', newValue, requiredValidator)
    },

    'formData.phone' (newValue) {
      this.removeValidationMessage('phone', t('errors.wrong_value?'))
      this.removeValidationMessage('phone', t('errors.filled?'))

      if (!newValue) {
        this.validate('phone', newValue, requiredValidator)
        this.removeValidationMessage('phone', t('telephony.uis.errors.wrong_phone_number'))

        return
      }

      this.removeValidationMessage('phone', t('activerecord.required.text'))

      if (this.validationMessages.phone.includes(t('telephony.uis.errors.wrong_phone_number'))) {
        this.validate(
          'phone',
          newValue,
          createPhoneValidator(
            this.phoneMaskSettings.length,
            t('telephony.uis.errors.wrong_phone_number')
          )
        )
      }
    },
  },

  created () {
    this.initCatalogHolder(creators)
    this.initPaginationHolder({
      limit: PAGINATION_LIMIT,
      offsetCallback: this.getDentalCompanies,
    })
    this.getDentalCompanies()
  },

  methods: {
    getDentalCompanies () {
      this.loadingList = true

      dentalCompaniesEndpoint.getAll(this.pagination)
        .then((data) => {
          this.dentalCompanies = data.data
          this.totalPages = data.total_pages

          const currentLabId = +this.$route.query.id
          if (currentLabId && this.openedFirstTimeBySearchQuery) {
            this.openDentalCompany(currentLabId)
            this.setFirstTimeOpenedBySearchQueryFalse()
          }
        })
        .catch((err) => Utils.reportError(
          'getDentalCompanies error',
          t('reception.errors.get_list')
        )(err))
        .finally(() => {
          this.loadingList = false
          this.firstListRequestCompleted = true
        })
    },

    openDentalCompany (currentLabId) {
      this.openEditForm({ id: currentLabId }, this.getDentalData)
    },

    setFirstTimeOpenedBySearchQueryFalse () {
      this.openedFirstTimeBySearchQuery = false
    },

    createDentalCompany (data) {
      if (findPreExistingTitle(this.dentalCompanies, data)) {
        Notificator.warning(
          t(
            'errors.pre_existing_title?',
            { item: t('dental_company') }
          ),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      dentalCompaniesEndpoint.create(data)
        .then(() => {
          this.clearForm()
          this.getDentalCompanies()

          Notificator.success(
            t('record_successfully_created'),
            '',
            NOTIFICATOR_LIFETIME
          )
        })
        .catch((err) => {
          if (err instanceof Error) {
            Utils.reportError(
              'createDentalCompany error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.create') }
              )
            )(err)
          } else {
            this.setValidationMessages(this.validationMessages, err.responseJSON)
          }
        })
    },

    updateDentalCompany (data) {
      if (findPreExistingTitle(this.dentalCompanies, data)) {
        Notificator.warning(
          t(
            'errors.pre_existing_title?',
            { item: t('dental_company') }
          ),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      if (isEqual(this.previousFormData, data)) {
        Notificator.warning(
          t('reception.dont_find_update'),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      dentalCompaniesEndpoint.update(data)
        .then(() => {
          this.getDentalCompanies()
          this.previousFormData = cloneDeep(data)

          Notificator.info(t('changes_updated'), '', NOTIFICATOR_LIFETIME)
        })
        .catch((err) => {
          if (err instanceof Error) {
            Utils.reportError(
              'updateDentalCompany error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.update') }
              )
            )(err)
          } else {
            this.setValidationMessages(this.validationMessages, err.responseJSON)
          }
        })
    },

    destroyDentalCompany () {
      dentalCompaniesEndpoint.destroy(this.formData.id)
        .then(() => {
          this.getDentalCompanies()
          this.hideForm()

          Notificator.success(
            t('record_successfully_deleted'),
            '',
            NOTIFICATOR_LIFETIME
          )
        })
        .catch((err) => {
          Utils.reportError(
            'destroyDentalCompany error',
            t(
              'reception.errors.request_error',
              { action: t('reception.actions.delete') }
            )
          )(err)
        })
    },

    getDentalData (id) {
      if (this.lastGetCurrentDataRequest) {
        this.lastGetCurrentDataRequest.abort()
      }

      const { ajax, promise } = dentalCompaniesEndpoint.get(id)
      this.lastGetCurrentDataRequest = ajax

      promise
        .then((data) => {
          if (!data.id) {
            this.hideForm()
            Utils.reportError('DentalCompaniesCatalog:getDentalData()', t('dental_company_not_found'))()

            return
          }

          this.formData = cloneDeep(data)
          this.previousFormData = cloneDeep(this.formData)

          this.lastGetCurrentDataRequest = null
        })
        .catch((err) => {
          if (err.statusText && err.statusText === 'abort') return

          this.lastGetCurrentDataRequest = null
          Utils.reportError(
            'getDentalData error',
            t('reception.errors.get_list')
          )(err)
        })
        .finally(() => { this.loadingList = false })
    },

    handleSubmit () {
      if (this.lastGetCurrentDataRequest) return

      trimValues(this.formData)

      if (this.hasErrors()) return

      if (this.selectedMode === MODES.NEW) {
        this.createDentalCompany(this.formData)
      } else {
        this.updateDentalCompany(this.formData)
      }
    },

    updateCompanyInfo (company) {
      this.formData.companyShortInfo = cloneDeep(company)
    },

    validatePhoneInput () {
      if (!this.formData.phone) return

      this.validate(
        'phone',
        this.formData.phone,
        createPhoneValidator(
          this.phoneMaskSettings.length,
          t('telephony.uis.errors.wrong_phone_number')
        )
      )
    },

    toggleCompanyModal () { this.isShowCompanyModal = !this.isShowCompanyModal },
  },
}
</script>
