import AnalysisOrderHandler from '@/vue_components/analysis_orders/analysis_order_handler.js'

$document.on('rez/analysis_orders/new', function () {
  AnalysisOrderHandler.newForm()
})

$document.on('rez/analysis_orders/edit', function () {
  AnalysisOrderHandler.editForm()
})

$document.on('rez/analysis_orders/show', function () {
  AnalysisOrderHandler.show()
})

$document.on('rez/analysis_orders/index', function () {
  AnalysisOrderHandler.index()
})
