export enum doctorAppointmentsReportFiltersSelectors {
  clinic = '#report_clinic_id',
  period = '#period',
  newPatients = '#report_new_patients',
  entryTypes = '#report_entry_type_id',
  clientGroups ='#report_client_group_ids',
  paymentType = '#report_by_dms',
  appointmentTypes = '#report_appointment_type_ids',
  appointmentStatuses = '#report_appointment_status_ids',
  attractionSources = '#report_attraction_source_ids',
  appointmentSources = '#report_appointment_source_ids',
  administrator = '#report_administrator_id',
  doctor = '#report_user_id',
  referral = '#report_referral_id',
}
