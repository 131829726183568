<template>
  <m-panel
    class="clients-documents"
    :title="`${t('client_card')} - ${t('documents')}`"
    icon="copy"
  >
    <m-si-generator
      :si-generator-schema="schema"
      :items="currentList.data"
      :page-count="currentList.totalPages"
      :current-page="currentList.currentPage"
      :nfr-text="t('noDocumentsClickOnButtonToAdd')"
      :loading="currentList.loading"
      use-context-menu
      @update:currentPage="currentList.fetchPage($event)"
      @onItemClick="onDocumentClick($event)"
      @onItemContextMenuClick="lastContextClickedItem = $event"
      @onItemDelete="onDocumentDelete($event)"
      @onRefreshClick="onRefreshClick"
      @onResetClick="onResetClick"
      @onCellClick="onCellClick($event)"
      @onSetSelectedItems="selectedItems = $event"
    >
      <template #filters>
        <document-filters
          :list="currentList"
          @updateList="onUpdateList($event)"
        />
      </template>

      <template #contextMenu>
        <m-context-menu-new-tab
          v-if="lastContextClickedItem"
          :url="getDocumentRoute(lastContextClickedItem.id)"
        />
      </template>
    </m-si-generator>

    <template #footer>
      <m-button
        v-tooltip="multiPrintTooltip"
        template="print"
        :disabled="multiPrintDisabled"
        @click="onMultiPrint"
      />

      <m-button-delete
        use-button
        :disabled="!selectedItems.length"
        :popover-message="t('delete_selected_items')"
        @yes="onMassDestroy"
      />
    </template>
  </m-panel>
</template>

<script lang="ts">
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import DocumentFilters
  from '@/vue_apps/ClientsModule/components/Documents/components/DocumentFilters/DocumentFilters.vue'
import { MListService } from '@/_api/_requests/MListService'
import {
  MDocumentsPresenter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/MDocumentsPresenter'
import {
  DocumentTabTax2024Presenter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/DocumentTabTax2024Presenter'
import { getDefaultDocumentFilters } from '@/vue_apps/ClientsModule/components/Documents/consts/consts'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MContextMenuNewTab from '@/vue_present/_base/Tables/MContextMenuNewTab.vue'
import { documentListSchema } from '@/vue_apps/ClientsModule/components/Documents/consts/DocumentListSchema'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import { GLOBAL_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { extractId } from '@/_medods_standart_library/msl'
import { getMultiPrintDocumentsUrl } from '@/helpers/getMultiPrintDocumentsUrl'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import { defineComponent } from 'vue'
import { TPubSubRemoveDocuments } from '@/_declarations/pubSub/interfaces/pubSubRemoveDocuments'
import { DOCUMENT_OWNER_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentOwnerTypes'
import { IPubSubCreatedDocument } from '@/_declarations/pubSub/interfaces/IPubSubCreatedDocument'
import { PUB_SUB_EVENTS } from '@/_declarations/pubSub/pubSubEvents'
import { PUB_SUB_BROADCAST_EVENTS } from '@/_declarations/pubSub/pubSubBroadcastEvents'

export default defineComponent({
  name: 'ClientModuleDocumentsApp',

  components: {
    MButton,
    MButtonDelete,
    MContextMenuNewTab,
    MSiGenerator,
    DocumentFilters,
    MPanel,
  },

  data () {
    return {
      clientId: gon.specific.client_id,

      currentListId: DOCUMENT_TYPES.OLD_DOCUMENT,
      currentList: null,

      selectedItems: [],

      lists: {
        [DOCUMENT_TYPES.OLD_DOCUMENT]: new MListService(
          getDefaultDocumentFilters(),
          new MDocumentsPresenter()
        ),

        [DOCUMENT_TYPES.CERTIFICATE_PAYMENT]: new MListService(
          getDefaultDocumentFilters(),
          new DocumentTabTax2024Presenter()
        ),
      },

      schema: documentListSchema(),
      lastContextClickedItem: null,
    }
  },

  computed: {
    filters () {
      return {
        ...this.currentList.filtersData,
        searchQuery: this.currentList.searchQuery,
        offset: this.currentList.offset,
        limit: this.currentList.limit,
      }
    },

    multiPrintDisabled () {
      return !this.selectedItems.length ||
          this.currentListId === DOCUMENT_TYPES.CERTIFICATE_PAYMENT
    },

    multiPrintTooltip () {
      return this.currentListId === DOCUMENT_TYPES.CERTIFICATE_PAYMENT
        ? t('fixedDocument.base.multiPrintTooltip')
        : ''
    },
  },

  created () {
    this.currentList = this.lists[this.currentListId]

    this.currentList.fetchAll()

    this.subscribeToCreatedDocument()
    this.subscribeToRemoveDocuments()
  },

  methods: {
    subscribeToCreatedDocument () {
      this.$pubSub.subscribe(PUB_SUB_BROADCAST_EVENTS.CREATED_DOCUMENT, (event: IPubSubCreatedDocument) => {
        const isOwnerOfDocument = event.documentsOwners.some(({ ownerType, ownerId }) =>
          ownerType === DOCUMENT_OWNER_TYPES.CLIENT && ownerId === this.clientId)

        if (!isOwnerOfDocument) { return }

        this.currentList.fetchAll()
      })
    },

    subscribeToRemoveDocuments () {
      this.$pubSub.subscribe(PUB_SUB_BROADCAST_EVENTS.REMOVE_DOCUMENTS, (event: TPubSubRemoveDocuments) => {
        const someDocumentsExist = event.some(({ documentType, documentId }) =>
          documentType === this.currentListId &&
            this.currentList.data.some(({ id }) => id === documentId))

        if (!someDocumentsExist) { return }

        this.currentList.fetchAll()
      })
    },

    getDocumentRoute (documentId: number) {
      return this.currentListId === DOCUMENT_TYPES.CERTIFICATE_PAYMENT
        ? `${this.$routes.fixed_document_path()}/tax-certificate-2024/${documentId}/?clientId=${this.clientId}`
        : this.$routes.document_path(documentId)
    },

    onDocumentClick ({ id }) {
      Turbolinks.visit(this.getDocumentRoute(id))
    },

    async onDocumentDelete ({ id }) {
      const result = await this.currentList.__presenter.destroy(id)

      if (result?.errors) { return }

      this.$pubSub.emit(PUB_SUB_EVENTS.REMOVE_DOCUMENTS, [{
        documentId: id,
        documentType: this.currentListId,
      }], true)

      this.currentList.fetchAll()
    },

    onRefreshClick () {
      this.currentList.fetchAll()
    },

    onResetClick () {
      this.onUpdateList(DOCUMENT_TYPES.OLD_DOCUMENT, true)
    },

    onCellClick ({ cell, item: { id } }) {
      if (cell !== 'print') { return }

      switch (this.currentListId) {
        case DOCUMENT_TYPES.CERTIFICATE_PAYMENT:
          return Turbolinks.visit(`${this.getDocumentRoute(id)}&immediatePrint=true`)
        case DOCUMENT_TYPES.OLD_DOCUMENT:
          return this.currentList.__presenter.multiPrint([id])
      }
    },

    /**
     * Из-за одновременной работы со старыми и новыми документами требуется применять разные классы MListService по причине различных роутов
     *
     * @param listId {string}
     * @param purgeFilters {boolean} - если true, то сбрасывает фильтры,
     * а не делает их перенос в другой класс. Нужен для сбросов фильтров при нажатии на кнопку "сброс фильтров"
     */
    onUpdateList (listId: string, purgeFilters = false) {
      const savedFilters = purgeFilters
        ? undefined
        : this.lists[this.currentListId].filtersData

      this.currentListId = listId

      this.selectedItems = []

      this.currentList.resetCheckboxes()

      this.currentList = this.lists[this.currentListId]

      this.currentList.resetFilters(savedFilters)

      this.currentList.fetchAll()
    },

    async onMassDestroy () {
      const ids = this.selectedItems.map((item) => item.id)
      const result = await this.currentList.__presenter.massDestroy(ids)

      this.selectedItems = []

      if (!result) { return }

      const removeDocumentsPayload = ids.map((id: number) => ({
        documentId: id,
        documentType: this.currentListId,
      }))

      this.$pubSub.emit('removeDocuments', removeDocumentsPayload, true)

      // setTimeout нужен из-за шизы, что после массового удаления лист на беке обновляется не сразу
      setTimeout(() => {
        this.currentList.fetchAll()
      }, GLOBAL_DEBOUNCE_DEFAULT)
    },

    async onMultiPrint () {
      const ids = this.selectedItems.map(extractId)
      const result = await this.currentList.__presenter.multiPrint(ids)
      if (result?.errors) { return }

      Turbolinks.visit(getMultiPrintDocumentsUrl(ids))
    },
  },
})
</script>
