import { NOTIFICATOR_LIFETIME } from '@/vue_components/catalogs/const'
import { isEqual } from 'lodash'

/**
 * Определяет есть ли в массиве данных элемент с тем же названием, как и у
 * передаваемого элемента
 * @param {{title: string, id: number|string}[]} dataArr массив элементов
 * @param {{title: string, id: number|string}} data интересующий элемент
 * @returns {object|undefined}
 */
export const findPreExistingTitle = (dataArr, data) => {
  return dataArr.find((newData) => newData.title === data.title && newData.id !== data.id)
}

/**
 * Обертка для findPreExistingTitle и показа нотификации
 * @param {{title: string, id: number|string}[]} dataArr массив элементов
 * @param {{title: string, id: number|string}} data интересующий элемент
 * @param {String} [itemText] - текст нотификации (по умолчанию t('reception.catalog_items_names.common'))
 * @returns {boolean}
 */
export const checkExistingTitle = (
  dataArr,
  data,
  itemText = t('reception.catalog_items_names.common')
) => {
  if (findPreExistingTitle(dataArr, data)) {
    Notificator.warning(
      t(
        'reception.pre_existing_item',
        { item: itemText }
      ),
      '',
      NOTIFICATOR_LIFETIME
    )

    return true
  }
}

/**
 * Сравнение объектов + нотификация
 * @param prevData
 * @param data
 * @returns {boolean}
 */
export const checkIsEqual = (prevData, data) => {
  if (isEqual(prevData, data)) {
    Notificator.warning(
      t('reception.dont_find_update'),
      '',
      NOTIFICATOR_LIFETIME
    )

    return true
  }
}

/**
 * Вызов уведомления с текстом
 * @param {String} text
 */
const successActionNotify = (text) => {
  Notificator.success(
    text,
    '',
    NOTIFICATOR_LIFETIME
  )
}

export const successDestroyedNotify = () => {
  successActionNotify(t('record_successfully_deleted'))
}

export const successCreatedNotify = () => {
  successActionNotify(t('record_successfully_created'))
}

export const successUpdateNotify = () => {
  successActionNotify(t('changes_updated'))
}
