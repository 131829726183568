import { userHasCurrentClinic } from '@/vue_apps/Orders/helpers/getDefaultUser'

/**
 * Удаление исполнителя из счёта
 *
 * @param {JQuery} $container
 */
export const dropPerformer = ($container) => {
  const $performerRows = $container.find('[data-entity="performer"]')

  $performerRows.each((index, element) => {
    const $element = $(element)
    const $clinicIds = $element.find('.order-boost__performer-clinic-ids')

    try {
      const clinicIds = JSON.parse($clinicIds.text()) || []

      if (userHasCurrentClinic(clinicIds)) { return }

      $element.find('.order-boost__label').text('')
      $element.find('.order_entries_user_id input').val('')
    } catch (e) {}
  })
}
