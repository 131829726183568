<template>
  <m-si-generator
    class="medical-records-tabs-container__tab documents-tab"
    :si-generator-schema="schema"
    :items="currentList.list.data"
    :page-count="currentList.list.totalPages"
    :current-page="currentList.list.currentPage"
    :nfr-text="t('no_medical_documents')"
    use-context-menu
    @update:currentPage="currentList.list.fetchPage($event)"
    @onItemClick="onItemClick($event)"
    @onItemContextMenuClick="lastContextClickedItem = $event"
  >
    <template #filters>
      <m-select
        :value="currentListId"
        :items="items"
        full-width
        :clearable="false"
        use-custom-result="simple"
        @change="onCurrentListIdChange"
      />
    </template>

    <template #contextMenu>
      <m-context-menu-new-tab
        v-if="lastContextClickedItem"
        :url="currentList.getDocumentRoute(lastContextClickedItem.id)"
      />
    </template>
  </m-si-generator>
</template>

<script>
import { getDateTitleHeadlessSchema } from '@/vue_present/_base/Tables/_schemaTemplates/getDateTitleHeadlessSchema'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { MListService } from '@/_api/_requests/MListService'
import { GLOBAL_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import MContextMenuNewTab from '@/vue_present/_base/Tables/MContextMenuNewTab.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import {
  DEFAULT_DOCUMENT_ID,
  DEFAULT_DOCUMENT_ITEM,
  TAX_CERTIFICATE2024_ID,
  TAX_CERTIFICATE2024_ITEM,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/consts'
import {
  MDocumentsPresenter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/MDocumentsPresenter'
import {
  DocumentTabTax2024Presenter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/DocumentTabTax2024Presenter'
import { MEDICAL_DOCUMENT_ID } from '@/vue_apps/ClientsModule/components/Documents/consts/consts'

export default {
  name: 'DocumentsTab',
  components: { MSelect, MContextMenuNewTab, MSiGenerator },
  props: {
    clientId: { type: Number, required: true },
  },

  data () {
    return {
      currentListId: DEFAULT_DOCUMENT_ID,

      items: [
        DEFAULT_DOCUMENT_ITEM,
        TAX_CERTIFICATE2024_ITEM,
      ],

      lists: {
        [DEFAULT_DOCUMENT_ID]: {
          list: new MListService(
            {
              clientId: this.clientId,
              kind: MEDICAL_DOCUMENT_ID,
            },
            new MDocumentsPresenter()
          ),
          getDocumentRoute: this.getClassicDocumentRoute,
        },

        [TAX_CERTIFICATE2024_ID]: {
          list: new MListService(
            { clientId: this.clientId },
            new DocumentTabTax2024Presenter()
          ),
          getDocumentRoute: this.getTaxRoute,
        },
      },

      schema: getDateTitleHeadlessSchema(),
      lastContextClickedItem: null,
    }
  },

  computed: {
    currentList () {
      return this.lists[this.currentListId]
    },
  },

  created () {
    setTimeout(() => {
      this.currentList.list.fetchAll()
    }, GLOBAL_DEBOUNCE_DEFAULT)
  },

  methods: {
    getClassicDocumentRoute (id) {
      return this.$routes.document_path(id)
    },

    getTaxRoute (id) {
      return `${this.$routes.fixed_document_path()}/tax-certificate-2024/${id}/?clientId=${this.clientId}`
    },

    onItemClick ({ id }) {
      Turbolinks.visit(this.currentList.getDocumentRoute(id))
    },

    onCurrentListIdChange (currentListId) {
      this.currentListId = currentListId
      this.currentList.list.fetchAll()
    },
  },
}
</script>
