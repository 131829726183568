import { mapMutations, mapState } from 'vuex'

const CardSelector = {
  props: {
    cardName: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSelected () {
      return this.value !== ''
    },
    value: {
      get () {
        return this._value
      },
      set (value) {
        this.setValue(value)
      },
    },
    ...mapState('headDesktopStore', {
      _value (state) {
        return state.selectors[this.cardName][this.key]
      },
    }),
  },
  methods: {
    valueAdapter (value) {
      return value
    },
    ...mapMutations('headDesktopStore', {
      setValue (commit, value) {
        commit('setSelector', {
          cardName: this.cardName,
          selectorName: this.key,
          value: this.valueAdapter(value),
        })
      },
    }),
  },
}
export default CardSelector
