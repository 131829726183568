<template>
  <div
    id="order_goods_list"
    class="order_goods_list_wrapper"
  >
    <epic-spinner :visibility="lockUI" />
    <si>
      <si-control
        class="hidden-print"
        @reset="resetFilters"
        @refresh="refresh"
      >
        <el-input
          v-model="siFilters.title"
          :placeholder="t('search_receipts_by_product_name')"
          class="component width-unset"
          clearable
        />

        <el-select
          v-model="siFilters.clinics"
          class="component"
          :placeholder="T.all_clinics"
          clearable
          @visible-change="resetDropdownVisible"
        >
          <el-option
            v-for="clinic in clinicsList"
            :key="clinic.id"
            :label="clinic.title"
            :value="clinic.id"
          />
        </el-select>

        <el-select
          v-model="siFilters.stores"
          class="component"
          :placeholder="T.all_stores"
          clearable
          @visible-change="resetDropdownVisible"
        >
          <el-option
            v-for="store in storesList"
            :key="store.id"
            :label="store.title"
            :value="store.id"
          />
        </el-select>

        <el-select
          v-model="siFilters.suppliers"
          class="component"
          :placeholder="T.all_suppliers"
          clearable
          @visible-change="resetDropdownVisible"
        >
          <el-option
            v-for="supplier in suppliersList"
            :key="supplier.id"
            :label="supplier.title"
            :value="supplier.id"
          />
        </el-select>

        <el-select-tree
          ref="elSelectTree"
          v-model="siFilters.categories"
          class="component"
          :clearable="true"
          :placeholder="T.all_categories"
          :check-strictly="true"
          :data="categoriesList"
          :props="defaultProps"
        />
      </si-control>
      <si-table>
        <template #header>
          <si-field>
            <div class="title order_goods_si_column">
              {{ T.title }}
            </div>
            <div class="title order_goods_si_column">
              {{ T.clinic }}
            </div>
            <div class="title order_goods_si_column">
              {{ T.store }}
            </div>
            <div class="title order_goods_si_column">
              {{ T.supplier }}
            </div>
            <div class="title order_goods_si_column">
              {{ T.category }}
            </div>
            <div class="amount order_goods_si_column hidden-print">
              {{ T.amount }}
            </div>
            <div class="critical_amount order_goods_si_column hidden-print">
              {{ T.critical_amount }}
            </div>
            <div class="desired_amount order_goods_si_column hidden-print">
              {{ T.desired_amount }}
            </div>
            <div class="order_goods_count order_goods_si_column">
              {{ T.order_goods_count }}
            </div>
          </si-field>
        </template>
        <template #body>
          <div
            v-if="noData"
            class="mdlp_no_data"
          >
            <i>{{ T.mdlp.no_data }}</i>
          </div>
          <si-field
            v-for="good in goods"
            :key="good.id"
            class="clickable document-row"
          >
            <div
              class="title order_goods_si_column"
              :title="good.title"
            >
              {{ good.title }}
            </div>
            <div
              class="title order_goods_si_column"
              :title="good.clinic_title"
            >
              {{ good.clinic_title }}
            </div>
            <div
              class="title order_goods_si_column"
              :title="good.store_title"
            >
              {{ good.store_title }}
            </div>
            <div
              class="title order_goods_si_column"
              :title="good.company_title"
            >
              {{ good.company_title }}
            </div>
            <div
              class="title order_goods_si_column"
              :title="good.category_title"
            >
              {{ good.category_title }}
            </div>
            <div class="amount order_goods_si_column hidden-print">
              {{ good.amount }}
            </div>

            <div class="critical_amount order_goods_si_column hidden-print">
              {{ good.critical_amount }}
            </div>

            <div class="desired_amount order_goods_si_column hidden-print">
              {{ good.desired_amount }}
            </div>

            <div class="order_goods_count order_goods_si_column">
              {{ good.desired_amount - good.amount }}
            </div>
          </si-field>
        </template>
      </si-table>
      <si-footer>
        <pagination
          :current-page.sync="currentPage"
          :total.sync="totalPages"
          :page-size="documentsPerPage"
          class="hidden-print"
          layout="prev, pager, next"
          @current-change="onPageChange"
        />
      </si-footer>
    </si>
  </div>
</template>

<script>
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiControl from '@/vue_components/sort_items/si_control.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import Pagination from '@/vue_components/common/pagination.vue'
import vuels from '@/vue_components/mixins/vuels_deprecated.js'
import { getCategoriesTree, getGoods } from '../rest.js'
import ElSelectTree from 'el-select-tree'

const DEFAULT_FILTERS = {
  title: null,
  clinics: null,
  suppliers: null,
  stores: null,
  categories: null,
}

export default {
  name: 'OrderGoodsList',

  components: {
    SiFooter,
    SiTable,
    SiControl,
    SiField,
    Si,
    EpicSpinner,
    Pagination,
    ElSelectTree,
  },

  mixins: [
    vuels({
      name: 'mdlp_document_filters',
      data: { siFilters: DEFAULT_FILTERS },
      watch: {
        siFilters: {
          handler (value) {
            this.currentPage = 1
            this.getGoods()
          },
          deep: true,
        },
      },
    }),
  ],

  data: () => ({
    clinicsList: gon.application.current_user_clinics,
    storesList: gon.specific.stores,
    suppliersList: gon.specific.suppliers,
    categoriesList: [],
    siFilters: { ...DEFAULT_FILTERS },
    currentUser: gon.application.current_user.id,
    goods: [],
    currentPage: 1,
    activeRequest: false,
    totalPages: 1,
    documentsPerPage: 20,
    defaultProps: {
      children: 'inc',
      label: 'text',
      value: 'id',
    },
  }),

  computed: {
    lockUI () {
      return this.activeRequest
    },

    noData () {
      return !this.goods.length && !this.activeRequest
    },
  },

  watch: {
    componentConfig: {
      handler (value) {
        this.currentPage = 1
        this.getGoods()
      },
      deep: true,
    },
  },

  created () {
    this.getGoods()
    this.getCategories()
  },

  methods: {
    refresh () {
      this.getGoods()
    },

    resetFilters () {
      this.currentPage = 1
      this.siFilters = { ...DEFAULT_FILTERS }
    },

    resetDropdownVisible () {
      this.$refs.elSelectTree.visible = false
    },

    getCategories () {
      getCategoriesTree()
        .then((categoriesTree) => {
          this.categoriesList = categoriesTree
        })
        .catch((err) => {
          console.error(err)
          Services
            .ui
            .notificator()
            .error(T.abstract_error_message, T.error, 0)
        })
    },

    getGoods (page = 1) {
      this.goods = []
      this.activeRequest = true

      getGoods({
        ...this.siFilters,
        page,
        per_page: this.documentsPerPage,
      })
        .then((response) => {
          this.goods = response.data
          this.totalPages = response.total_pages * this.documentsPerPage
          this.currentPage = response.page
        })
        .catch((err) => {
          console.error(err)
          Services
            .ui
            .notificator()
            .error(T.abstract_error_message, T.error, 0)
        })
        .finally(() => {
          this.activeRequest = false
        })
    },

    onPageChange (page) {
      this.getGoods(page)
    },
  },
}
</script>
