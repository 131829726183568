import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'

export class MEntryTypePresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'entryType',
      location: 'MEntryTypePresenter',
      routes: { all: Routes.entry_types_path, one: Routes.entry_type_path },
    })
  }

  /**
   * @param {number[]} ids
   * @param {boolean} legacy - если true, то данные будут в snake_case
   * @returns {Promise<*>}
   */
  async fetchByIds (ids, legacy = false) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'entryTypes'
    )

    return this.post({
      url: Routes.fetch_api_internal_entry_types_path(),
      data: JSON.stringify({ ids }),
      dataType: 'json',
      contentType: 'application/json',
    }, (legacy && ((data) => data)) || undefined).promise
      .catch(MRequestError.withDefault([], notification))
  }
}
