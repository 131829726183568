<template>
  <div class="kdl-form-fields">
    <m-input
      :value="username"
      :label="t('laboratories.form_fields.senderId')"
      :disabled="!canManage"
      @input="$updateSyncGC('username', $event)"
    />

    <m-input
      :value="password"
      :label="t('password')"
      type="password"
      :disabled="!canManage"
      @input="$updateSyncGC('password', $event)"
    />

    <div class="labels-numbers-range">
      <div class="labels-numbers-range__title">
        {{ t('laboratories.form_fields.labelsNumbersRange') }}
      </div>

      <div class="numbers-range__fields">
        <m-input
          :value="minLabelNumber"
          :disabled="!canManage"
          @input="$updateSyncGC('minLabelNumber', $event)"
        />

        <m-input
          :value="maxLabelNumber"
          :disabled="!canManage"
          @input="$updateSyncGC('maxLabelNumber', $event)"
        />
      </div>
    </div>

    <m-checkbox
      :value="requestCostPrice"
      :label="t('laboratories.form_fields.requestCostPrice')"
      :disabled="!canManage"
      @input="$updateSyncGC('requestCostPrice', $event)"
    />
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'

export default {
  name: 'KdlFormFields',
  components: { MCheckbox, MInput },
  props: {
    username: {
      type: String,
      default: null,
    },

    password: {
      type: String,
      default: null,
    },

    minLabelNumber: {
      type: String,
      default: null,
    },

    maxLabelNumber: {
      type: String,
      default: null,
    },

    requestCostPrice: Boolean,

    canManage: Boolean,
  },
}
</script>
