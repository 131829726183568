export const DEFAULT_DOCUMENT_ID = 1

export const TAX_CERTIFICATE2024_ID = 2

export const DEFAULT_DOCUMENT_ITEM = {
  id: DEFAULT_DOCUMENT_ID,
  title: t('documents'),
}

export const TAX_CERTIFICATE2024_ITEM = {
  id: TAX_CERTIFICATE2024_ID,
  title: t('taxCertificate2024'),
}
