<template>
  <modal
    :modal-class="'remove-vacation-modal'"
    :modal-visibility="showRemoveVacationModal"
    @close="$pubSub.emit('EMPLOYEES_SCHEDULE.HIDE_REMOVE_VACATION_MODAL')"
  >
    <span slot="header">{{ T.remove_vacation }}</span>
    <template slot="body">
      <span>{{ T.remove_vacation_alt }}</span>
      <span class="modal-time">{{ formatDate(vacation.start) }}</span>
      <span> — </span>
      <span class="modal-time">{{ formatDate(vacation.end) }}</span>
      <span>?</span>
      <span>{{ T.selected_day }}</span>
      <span class="modal-time">{{ formatDate(date) }}</span>
    </template>
    <remove-vacation-modal-footer slot="footer-right" />
  </modal>
</template>

<script>
import Modal from '../../../modal.vue'
import RemoveVacationModalFooter from './remove_vacation_modal_footer.vue'
import { formatDate } from '../../utils.js'

export default {
  name: 'RemoveVacationModal',
  components: {
    Modal,
    RemoveVacationModalFooter,
  },
  props: {
    showRemoveVacationModal: { type: Boolean, required: true },
    vacation: { type: Object, required: true },
    date: { type: String, required: true },
  },
  methods: {
    formatDate,
  },
}
</script>
