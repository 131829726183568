<template>
  <div class="kdl-form">
    <kdl-form-fields
      :username="username"
      :password="password"
      :min-label-number="minLabelNumber"
      :max-label-number="maxLabelNumber"
      :request-cost-price="requestCostPrice"
      :can-manage="canManage"
      @updateSyncGC="$updateSync($event.prop, $event.value)"
    />
  </div>
</template>

<script>
import KdlFormFields from '@/vue_apps/catalogs_root/analysis_laboratories/components/kdl_form_fields/KdlFormFields.vue'

export default {
  name: 'KdlForm',
  components: { KdlFormFields },
  props: {
    username: {
      type: String,
      default: null,
    },

    password: {
      type: String,
      default: null,
    },

    minLabelNumber: {
      type: String,
      default: null,
    },

    maxLabelNumber: {
      type: String,
      default: null,
    },

    requestCostPrice: Boolean,

    canManage: Boolean,
  },
}
</script>
