<template>
  <report-filters
    :filters="filters"
    :filters-data="report.filters"
    :loading="loading"
    @setFilterValue="onSetFilterValue"
    @registerValidator="onRegisterValidator"
    @onReportClick="generateReport"
    @onResetClick="resetReport"
  >
    <filters-profiles-app
      :filters-map="report.filtersData"
      :profile-type="profileType"
      @onProfileSelect="onFiltersProfileSelect"
    />
  </report-filters>
</template>

<script>
import ReportFilters from '@/vue_apps/ReportsModule/_filters/ReportFilters.vue'
import { getSalaryReportFilters } from '@/vue_apps/ReportsModule/SalaryReports/store/getSalaryReportFilters'
import { fillFiltersData } from '@/vue_apps/ReportsModule/_filters/fillFiltersData'
import { SalaryReport } from '@/vue_apps/ReportsModule/SalaryReports/store/SalaryReport'
import { getReportConfig } from '@/vue_apps/ReportsModule/SalaryReports/store/getReportType'
import { createReport } from '@/vue_apps/ReportsModule/_filters/createReport'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { PAYMENTS_FILTER_VALUE, SALARY_REPORT_TYPE } from '@/vue_apps/ReportsModule/SalaryReports/const/const'
import SalaryReportContainer from '@/vue_apps/ReportsModule/SalaryReports/SalaryReportContainer.vue'
import { REPORT_EVENTS } from '@/vue_apps/ReportsModule/_filters/const/events'
import { REPORT_TYPE_FILTERS } from '@/vue_apps/ReportsModule/_filters/components/ReportTypeFilter/const'
import FiltersProfilesApp from '@/vue_apps/FiltersProfilesApp/FiltersProfilesApp.vue'
import { FILTERS_PROFILES_TYPES } from '@/vue_apps/FiltersProfilesApp/const/consts'
import { getSalaryReportDefaultFiltersValues as getDefaultFiltersValues } from './store/getDefaultFilters'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

let reportApp = null

export default {
  name: 'SalaryReportApp',
  components: {
    ReportFilters,
    FiltersProfilesApp,
  },

  mixins: [
    SpinnerHolder,
    ValidationParentMixin,
  ],

  data () {
    return {
      report: new SalaryReport(),
      filters: [],

      profileType: FILTERS_PROFILES_TYPES.SALARY,
    }
  },

  created () {
    this.resetReport()
    this.createReportContainer()

    PubSub.on(REPORT_EVENTS.EXPORT, () => { this.report.export() })
  },

  beforeDestroy () {
    PubSub.unsubscribe(REPORT_EVENTS.EXPORT)
  },

  methods: {
    onFiltersProfileSelect ({ filters: filtersToSet, errors }) {
      const filters = { ...getDefaultFiltersValues(), ...filtersToSet }

      Object
        .entries(filters)
        .forEach(([filterName, filterValue]) => this.onSetFilterValue({
          filter: filterName,
          value: filterValue,
        }))

      this.$nextTick(() => this.resetValidations(errors, true))
    },

    resetReport () {
      this.filters = getSalaryReportFilters()
      const filtersData = fillFiltersData(this.filters)

      this.report.resetData()
      this.report.resetFilters({
        ...filtersData,
        reportType: SALARY_REPORT_TYPE.SALARY,
      })

      this.resetValidations()
    },

    createReportContainer () {
      if (reportApp?.$destroy) { reportApp.$destroy() }

      const that = this
      const thatReport = this.report
      reportApp = createReport(SalaryReportContainer,
        {
          props: { report: this.report },
        },
        {
          methods: {
            /**
             * БЛ: в отчете зарплаты (общий),
             * при клике на строку происходит открытие отчета зарплаты врача
             *
             * @param {SalaryReportItem} item
             */
            onItemClick (item) {
              thatReport.setFilterValue('reportTypeFilter', REPORT_TYPE_FILTERS()[0])
              that.onSetFilterValue({ filter: 'userFilter', value: item })
              that.generateReport()
            },
          },
        })
    },

    async generateReport () {
      const { siSchema, reportType, sortingMap, toClientDataAdapter } =
        getReportConfig(this.report.filters)

      this.report.setSiSchema(siSchema)
      this.report.setSortingMap(sortingMap)
      this.report.setFilterValue('reportType', reportType)
      this.report.setToClientDataAdapter(toClientDataAdapter)

      await this.withSpinner(this.report.fetchAll())
    },

    onSetFilterValue ({ filter, value }) {
      this.report.setFilterValue(filter, value)

      const makeFilterHidden = (filterName) => {
        const filter = this.filters.find((item) => item.name === filterName)
        if (!filter) { return }
        filter.hidden = value?.id === PAYMENTS_FILTER_VALUE
        this.onSetFilterValue({ filter: filterName, value: false })
      }

      /**
       * Фильтр по врачу (не при правах "Только своя зарплата")
       */
      if (filter === 'userFilter') {
        const reportTypeFilter = this.filters.find((item) => item.name === 'reportTypeFilter')
        if (!reportTypeFilter) { return }

        if (this.report.filtersData.reportSourceFilter.id === 'payments') {
          this.onSetFilterValue({ filter: 'reportTypeFilter', value: REPORT_TYPE_FILTERS()[1] })
          reportTypeFilter.hidden = Boolean(value)

          return
        }

        reportTypeFilter.hidden = !value
      }

      /**
       * Фильтр по типу источника
       */
      if (filter === 'reportSourceFilter') {
        makeFilterHidden('entryWithOrderFilter')
        makeFilterHidden('includingDiscountsFilter')

        const reportTypeFilter = this.filters.find((item) => item.name === 'reportTypeFilter')
        if (!reportTypeFilter) { return }

        const userFilterData = this.report.filtersData.userFilter || this.report.filtersData.userOnlyCurrentFilter

        reportTypeFilter.hidden = value?.id !== PAYMENTS_FILTER_VALUE && !userFilterData

        if (value?.id === PAYMENTS_FILTER_VALUE && userFilterData) {
          this.onSetFilterValue({ filter: 'reportTypeFilter', value: REPORT_TYPE_FILTERS()[1] })
          reportTypeFilter.hidden = true
        }
      }
    },
  },
}
</script>
