import { cloneDeep, isEmpty } from 'lodash'

const SEXES = {
  1: t('sexes.short_male'),
  2: t('sexes.short_female'),
}

export const transformClient = (client) => {
  if (isEmpty(client)) { return {} }

  const __client = cloneDeep(client)
  __client.sex = SEXES[__client.sexId]

  if (__client.birthdate) {
    __client.birthdate = Utils.getFormattedDate(__client.birthdate * 1000)
  }

  __client.phone = Services.phoneMask.setMask(__client.phone)

  return __client
}
