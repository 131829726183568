import AlertModal from '@/vue_components/alert_modal'
import { MODAL_TYPES } from '@/vue_components/doctor_schedules/consts'

/**
 * Эта миксина позволяет переиспользовать компонент AlertModal.
 *
 * @see {ConfirmationHolder}
 */
export const AlertHolder = {
  provide () {
    return {
      showAlert: this.showAlert,
      hideAlert: this.hideAlert,
    }
  },

  components: {
    AlertModal,
  },

  computed: {
    alertProps () {
      const modal = this.modal[MODAL_TYPES.ALERT]

      return {
        modalVisibility: modal.visibility,
        headerMessage: modal.headerMessage,
        message: modal.message,
      }
    },
  },

  data () {
    return {
      modal: {
        [MODAL_TYPES.ALERT]: {
          visibility: false,
          headerMessage: '',
          message: '',
        },
      },
    }
  },

  methods: {
    /**
     * @param {string} message
     * @param {string} title
     */
    showAlert (message, title = '') {
      Object.assign(this.modal[MODAL_TYPES.ALERT], {
        message,
        headerMessage: title,
      })
      this.openModal(MODAL_TYPES.ALERT)
    },

    hideAlert () {
      this.closeModal(MODAL_TYPES.ALERT)
    },
  },
}
