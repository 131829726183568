import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'
import { entryTypesEndpoint } from '@/api_entities/entry_types/entryTypesEndpoint'

export const entryTypesPresenter = {
  fetchEntryTypesBreadcrumbs (entryTypeId) {
    return entryTypesEndpoint.fetchEntryTypesBreadcrumbs(entryTypeId)
      .catch((err) => {
        Utils.reportError(
          'entryTypesPresenter::fetchEntryTypesBreadcrumbs()',
          reportErrorText('read_message', 'entryTypesBreadcrumbs')
        )(err)

        return Promise.resolve([])
      })
  },
}
