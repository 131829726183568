export const SALARY_REPORT_TYPE = Object.freeze({
  SALARY: 'salary',
  DOCTOR_SALARY: 'doctor_salary',
  DOCTOR_SUMMARY_SALARY: 'doctor_summary_salary',
  SALARY_BY_PAYMENTS: 'salary_by_payments',
  DOCTOR_SALARY_BY_PAYMENTS: 'doctor_salary_by_payments',
  SALARY_BY_PAYMENTS_DETAILED: 'salary_by_payments_detailed',
  DOCTOR_SALARY_BY_PAYMENTS_DETAILED: 'doctor_salary_by_payments_detailed',
})

export const PAYMENTS_FILTER_VALUE = 'payments'

export const paymentKindTooltip = {
  ORDER_INITIAL: t('_payment_kind.order_initial'),
  ORDER_SUBSEQUENT: t('_payment_kind.order_subsequent'),
  ORDER_REFUND: t('_payment_kind.order_refund'),
  CASH_IN: t('_payment_kind.cash_in'),
  CASH_IN_REFUND: t('_payment_kind.cash_in_refund'),
  CASH_OUT: t('_payment_kind.cash_out'),
  ORDER_FULL: t('_payment_kind.order_full'),
  ORDER_IN_CREDIT: t('_payment_kind.order_in_credit'),
}

export const PAYMENT_KIND = Object.freeze({
  0: { tooltip: paymentKindTooltip.ORDER_INITIAL, icon: 'orderInitial', class: 'warning' },
  1: { tooltip: paymentKindTooltip.ORDER_SUBSEQUENT, icon: 'orderSubsequent', class: 'purple' },
  2: { tooltip: paymentKindTooltip.ORDER_REFUND, icon: 'orderRefund', class: 'danger' },
  3: { tooltip: paymentKindTooltip.CASH_IN, icon: 'cashIn', class: 'primary' },
  4: { tooltip: paymentKindTooltip.CASH_IN_REFUND, icon: 'cashInRefund', class: 'danger' },
  5: { tooltip: paymentKindTooltip.CASH_OUT, icon: 'cashOut', class: 'primary' },
  6: { tooltip: paymentKindTooltip.ORDER_FULL, icon: 'orderFull', class: 'success' },
  7: { tooltip: paymentKindTooltip.ORDER_IN_CREDIT, icon: 'orderInCredit', class: 'brown' },
})

const PAYMENT_KIND_ORDER_REFUND = 2
const PAYMENT_KIND_CASH_IN_REFUND = 4
const PAYMENT_KIND_CASH_OUT = 5
export const PAYMENT_KINDS_WITH_REFUND = [
  PAYMENT_KIND_ORDER_REFUND,
  PAYMENT_KIND_CASH_IN_REFUND,
  PAYMENT_KIND_CASH_OUT,
]
