<template>
  <div class="entry-types-transfer position-relative inline-block">
    <m-badge
      v-if="transferEntryTypesCount > 0"
      class="entry-types-transfer__counter"
      :value="transferEntryTypesCount"
      type="warning"
    />

    <m-button
      :tooltip="tooltip"
      icon="transfer"
      @click="modalVisibility = true"
    />

    <entry-types-transfer-modal
      :client-id="clientId"
      :modal-visibility.sync="modalVisibility"
      :has-updates.sync="hasUpdates"
      :presenter="presenter"
      :tooth-ids="toothIds"
      @fetchCount="fetchCount"
      @onSubmitComplete="$emit('onSubmitComplete')"
    />
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MBadge from '@/vue_present/_base/MBadge/MBadge.vue'

import { MEntryTypesTransferPresenter } from '@/_api/EntryTypes/MEntryTypesTransfer/MEntryTypesTransferPresenter'
import EntryTypesTransferModal
  from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/EntryTypesTransfer/components/EntryTypesTransferModal.vue'

export default {
  name: 'EntryTypesTransfer',
  components: {
    EntryTypesTransferModal,
    MBadge,
    MButton,
  },

  props: {
    clientId: { type: Number, required: true },
    toothIds: { type: Array, default: undefined },
  },

  emits: ['onSubmitComplete'],

  data () {
    return {
      transferEntryTypesCount: 0,
      modalVisibility: false,
      hasUpdates: false,
      presenter: new MEntryTypesTransferPresenter(),
    }
  },

  computed: {
    tooltip () {
      return t('transferServicesTooltip', { count: this.transferEntryTypesCount })
    },
  },

  created () {
    this.fetchCount()

    Services
      .wsSubscriptions
      .entryType.connect(({ data }) => {
        const clientId = data?.client_id
        if (this.clientId !== clientId) { return }
        this.hasUpdates = true
        this.fetchCount()
      })
  },

  beforeDestroy () {
    Services
      .wsSubscriptions
      .entryType
      .reset()
  },

  methods: {
    async fetchCount () {
      const { errors, count } = await this.presenter.count(this.clientId)
      if (errors) { return }
      this.transferEntryTypesCount = count
    },
  },
}
</script>
