<template>
  <div class="d-flex referrals-form">
    <epic-spinner :visibility="loading" />
    <referrals-form-user
      :clinic-id="clinicId"
      :targeted-disable="targetedDisable"
      :errors="errors"
      :name="name"
      :surname="surname"
      :second-name="secondName"
      :phone="phone"
      :email="email"
      :code="code"
      :company="company"
      :medical-doctor="medicalDoctor"
      :specialty="specialty"
      :additional="additional"
      @update:medicalDoctorName="$updateSync('medical-doctor-name', $event)"
      @update:company="$updateSync('company', $event)"
      @update:specialtyName="$updateSync('specialty-name', $event)"
      @update:name="$updateSync('name', $event)"
      @update:surname="$updateSync('surname', $event)"
      @update:second-name="$updateSync('secondName', $event)"
      @update:phone="$updateSync('phone', $event)"
      @update:email="$updateSync('email', $event)"
      @update:code="$updateSync('code', $event)"
      @update:medical-doctor="$updateSync('medicalDoctor', $event)"
      @update:specialty="$updateSync('specialty', $event)"
      @update:additional="$updateSync('additional', $event)"
      @add-company="$emit('add-company')"
    >
      <template #surname-label>
        <label for="surname">
          {{ t('surname') }}
          <abbr :title="t('activerecord.required.text')">
            *
          </abbr>
        </label>
      </template>
    </referrals-form-user>

    <tabs
      type="card"
      class="client-tabs grey"
      :value="activeTab"
      @input="$updateSync('activeTab', $event)"
    >
      <el-tab-pane
        :name="TAB_ADDRESS"
        :label="t('address')"
      >
        <client-form-address
          :errors="errors"
          :full-address="fullAddress"
          :index="index"
          :country="country"
          :region="region"
          :area="area"
          :city="city"
          :street="street"
          :house="house"
          :flat="flat"
          :targeted-disable="targetedDisable"
          :nsi-russian-subject-id="nsiRussianSubjectId"
          @update:fullAddress="$updateSync('fullAddress', $event)"
          @update:index="$updateSync('index', $event)"
          @update:country="$updateSync('country', $event)"
          @update:region="$updateSync('region', $event)"
          @update:nsiRussianSubjectId="$updateSync('nsiRussianSubjectId', $event)"
          @update:area="$updateSync('area', $event)"
          @update:city="$updateSync('city', $event)"
          @update:street="$updateSync('street', $event)"
          @update:house="$updateSync('house', $event)"
          @update:flat="$updateSync('flat', $event)"
        >
          <template #address-region>
            <reusable-global-n-s-i-list
              v-if="useNSIRussianSubjectsDictionary"
              :value="nsiRussianSubjectId"
              :placeholder="t('region')"
              :disabled="disabled"
              full-width
              dictionary="russian_subjects"
              @change="$updateSync('nsiRussianSubjectId', $event)"
              @changeItem="$updateSync('region', $event && $event.title)"
            />
          </template>
        </client-form-address>
      </el-tab-pane>
    </tabs>
  </div>
</template>

<script>
import ClientFormAddress from '@/vue_components/client/client_form_address.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import Tabs from '@/vue_components/common/tabs.vue'
import ReferralsFormUser from '@/vue_components/referrals/referrals_form_user.vue'

import propsAddress from '@/vue_components/client/client_form_address_props.js'
import propsReferralsUser from '@/vue_components/referrals/referrals_form_user_props.js'
import { TAB_ADDRESS } from '@/vue_components/client/const.js'
import ReusableGlobalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableGlobalNSIList.vue'

export default {
  components: {
    ReusableGlobalNSIList,
    ClientFormAddress,
    EpicSpinner,
    Tabs,
    ReferralsFormUser,
  },

  props: {
    loading: Boolean,
    disabled: Boolean,
    ...propsAddress,
    ...propsReferralsUser,
  },

  data () {
    return {
      TAB_ADDRESS,
    }
  },

  computed: {
    useNSIRussianSubjectsDictionary () {
      return gon.application.nsi.russianSubjectsDictionary
    },
  },
}
</script>
