<template>
  <div class="inner-container">
    <epic-spinner :visibility="GET_LOADING_DOCTOR_SCHEDULE || GET_LOADING_APPOINTMENTS" />
    <div
      v-show="IS_ACTIVE_SCHEDULE_DOCTORS"
      id="doctor_schedule_block"
      ref="doctorSchedule"
    />

    <div
      v-show="IS_ACTIVE_SCHEDULE_CABINETS"
      id="cabinet_schedule_block"
      ref="cabinetSchedule"
    />
    <doctor-schedules-grid-stub v-if="!$security.canViewAppointmentGrid" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { fetchCabinetsAppointments, fetchDoctorAppointments } from './rest'
import DoctorSchedulesGridStub from '@/vue_components/doctor_schedules/doctor_schedules_grid_stub'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import { APPOINTMENT_STATUS } from '@/vue_components/appointment/const'

export default {
  name: 'DoctorSchedulesGrid',
  components: { DoctorSchedulesGridStub, EpicSpinner },

  inject: [
    'showAlert',
    'showConfirmation',
    'hideAlert',
    'hideConfirmation',
    'openCopyAppointmentWithServicesModal',
    'closeCopyAppointmentWithServicesModal',
    'openUserSelectModal',
    'closeUserSelectModal',
    'openCreateWorkTimeModal',
    'closeCreateWorkTimeModal',
  ],
  props: {
    contextReady: Boolean,

    scheduleTitle: {
      type: String,
      default: '',
    },

    reservedAppointmentShow: Boolean,

    reservedAppointment: {
      type: Object,
      required: false,
      default: null,
    },

    focusOnAppointment: {
      type: Number,
      default: null,
    },

    oneVisibleCabinet: {
      type: Number,
      default: null,
    },

    oneVisibleUser: {
      type: Number,
      default: null,
    },

    users: {
      type: Array,
      required: true,
    },

    userIds: {
      type: Array,
      required: true,
    },

    cabinetIds: {
      type: Array,
      required: true,
    },

    availableCabinets: {
      type: Array,
      required: true,
    },

    waitingCount: {
      type: Object,
      required: true,
    },

    waitingUserFromGrid: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapGetters([
      'GET_SCHEDULE_CURRENT_CLINIC_ID',
      'GET_MODE_GRID_SCHEDULE',
      'GET_INSERT_PARAMS_APPOINTMENT',
      'GET_APPOINTMENT_SYSTEM_SOURCE',
      'GET_ACTIVE_SCHEDULE',
      'GET_SCHEDULE_DATE',
      'GET_SCHEDULE_DURATION',
      'GET_SCHEDULE_DAYS_COUNT',
      'GET_SIDEBAR_HIDE_USERS_WITHOUT_SCHEDULE_STATE',
      'GET_SIDEBAR_HIDE_CABINETS_WITHOUT_SCHEDULE_STATE',
      'GET_SIDEBAR_HIDE_APPOINTMENTS_SCHEDULE_STATE',
      'GET_SIDEBAR_SHOW_ONLY_WORKING_DAYS_STATE',
      'IS_ACTIVE_SCHEDULE_DOCTORS',
      'IS_ACTIVE_SCHEDULE_CABINETS',
      'GET_ACTIVE_APPOINTMENT',
      'GET_APP_CONF_CURRENT_USER_ID',
      'GET_SIDEBAR_SHOW_ONLY_NEW_PATIENTS_STATE',
      'GET_SIDEBAR_FILTRATION_APPOINTMENTS_STATUSES',
      'GET_SIDEBAR_FILTRATION_APPOINTMENTS_TYPES',
      'GET_SIDEBAR_FILTRATION_CLIENT_GROUPS',
      'GET_LOADING_DOCTOR_SCHEDULE',
      'GET_LOADING_APPOINTMENTS',
    ]),

    $doctorScheduleElement () {
      return $(this.$refs.doctorSchedule)
    },

    $cabinetScheduleElement () {
      return $(this.$refs.cabinetSchedule)
    },

    reqDoctorScheduleGridParams () {
      return {
        date: this.GET_SCHEDULE_DATE,
        user_ids: JSON.stringify(this.userIds),
        days_count: this.GET_SCHEDULE_DAYS_COUNT,
        only_working_days: this.GET_SIDEBAR_SHOW_ONLY_WORKING_DAYS_STATE,
        clinic_id: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
        only_new_patients: this.GET_SIDEBAR_SHOW_ONLY_NEW_PATIENTS_STATE,
      }
    },

    reqCabinetsScheduleGridParams () {
      return {
        date: this.GET_SCHEDULE_DATE,
        user_ids: JSON.stringify(this.userIds),
        cabinet_ids: JSON.stringify(this.cabinetIds),
        days_count: this.GET_SCHEDULE_DAYS_COUNT,
        clinic_id: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
      }
    },

    scheduleGridFiltrationParams () {
      return {
        onlyNewPatients: this.GET_SIDEBAR_SHOW_ONLY_NEW_PATIENTS_STATE,
        statuses: this.GET_SIDEBAR_FILTRATION_APPOINTMENTS_STATUSES,
        types: this.GET_SIDEBAR_FILTRATION_APPOINTMENTS_TYPES,
        clientGroups: this.GET_SIDEBAR_FILTRATION_CLIENT_GROUPS,
      }
    },
  },

  watch: {
    GET_ACTIVE_APPOINTMENT (appointment) {
      this.$doctorScheduleElement.doctorScheduleGrid('setActiveAppointment', appointment)
      this.$cabinetScheduleElement.cabinetScheduleGrid('setActiveAppointment', appointment)
    },

    GET_SCHEDULE_CURRENT_CLINIC_ID (clinicId) {
      this.$doctorScheduleElement.doctorScheduleGrid('setCurrentClinicId', clinicId)
      this.$cabinetScheduleElement.cabinetScheduleGrid('setCurrentClinicId', clinicId)
    },

    GET_MODE_GRID_SCHEDULE (mode) {
      this.$doctorScheduleElement.doctorScheduleGrid('option', { mode })
      this.$cabinetScheduleElement.cabinetScheduleGrid('option', { mode })
    },

    GET_ACTIVE_SCHEDULE () {
      this.$doctorScheduleElement.doctorScheduleGrid('setTableVisibility', this.IS_ACTIVE_SCHEDULE_DOCTORS)
      this.$cabinetScheduleElement.cabinetScheduleGrid('setTableVisibility', this.IS_ACTIVE_SCHEDULE_CABINETS)
    },

    GET_SCHEDULE_DATE (date) {
      this.$doctorScheduleElement.doctorScheduleGrid('setDate', date)
      this.$cabinetScheduleElement.cabinetScheduleGrid('setDate', date)
    },

    GET_SCHEDULE_DURATION (duration) {
      this.$doctorScheduleElement.doctorScheduleGrid('option', { step: duration })
      this.$cabinetScheduleElement.cabinetScheduleGrid('option', { step: duration })
    },

    GET_SCHEDULE_DAYS_COUNT (daysCount) {
      this.$doctorScheduleElement.doctorScheduleGrid('setDaysCount', daysCount)
      this.$cabinetScheduleElement.cabinetScheduleGrid('setDaysCount', daysCount)
    },

    GET_SIDEBAR_HIDE_USERS_WITHOUT_SCHEDULE_STATE (hideUsersWithoutSchedule) {
      this.$doctorScheduleElement.doctorScheduleGrid('option',
        { hideUsersWithoutSchedule }
      )
    },

    GET_SIDEBAR_HIDE_CABINETS_WITHOUT_SCHEDULE_STATE (hideCabinetsWithoutSchedule) {
      this.$cabinetScheduleElement.cabinetScheduleGrid('option',
        { hideCabinetsWithoutSchedule }
      )
    },

    GET_SIDEBAR_HIDE_APPOINTMENTS_SCHEDULE_STATE (hideAppointments) {
      if (hideAppointments) {
        this.$cabinetScheduleElement.addClass('hide-appointments')
      } else {
        this.$cabinetScheduleElement.removeClass('hide-appointments')
      }
    },

    userIds (userIds) {
      this.$doctorScheduleElement.doctorScheduleGrid('setUsers', userIds)
    },

    focusOnAppointment (appointmentId) {
      if (appointmentId) {
        this.$doctorScheduleElement.doctorScheduleGrid('focusOnAppointment', appointmentId)
        this.$updateSync('focusOnAppointment', null)
      }
    },

    waitingCount (doctorToWaitingCountMap) {
      this.$doctorScheduleElement.doctorScheduleGrid('setWaitingListCount', doctorToWaitingCountMap || {})
    },

    reservedAppointmentShow () {
      if (this.reservedAppointmentShow && this.reservedAppointment) {
        this.reservedInterval = setInterval(() => {
          this.$doctorScheduleElement.doctorScheduleGrid('reserve', this.reservedAppointment)
        }, 1000)
      } else {
        clearInterval(this.reservedInterval)
        this.reservedInterval = null
        this.$doctorScheduleElement.doctorScheduleGrid('cancelReserve', this.reservedAppointment)
      }
    },

    reqDoctorScheduleGridParams () {
      if (this.contextReady && this.IS_ACTIVE_SCHEDULE_DOCTORS) {
        this.fetchDoctorScheduleGrid()
      }
    },

    scheduleGridFiltrationParams () {
      if (!this.contextReady) return

      if (this.IS_ACTIVE_SCHEDULE_DOCTORS) {
        this.fetchDoctorScheduleGrid()
      } else {
        this.fetchCabinetsScheduleGrid()
      }
    },

    reqCabinetsScheduleGridParams () {
      if (this.contextReady && this.IS_ACTIVE_SCHEDULE_CABINETS) {
        this.fetchCabinetsScheduleGrid()
      }
    },
  },

  mounted () {
    const $doctorSchedule = $(this.$refs.doctorSchedule)
    const $cabinetSchedule = $(this.$refs.cabinetSchedule)
    const vm = this

    const scheduleCommonMethods = {
      onFree (appointment, gridParams) {
        if (!Services.security.canManageAppointment) {
          Notificator.error(t('errors.action_not_permitted'))

          return
        }

        if (!Number.isInteger(appointment.appointment_source_id)) {
          appointment.appointment_source_id = vm.GET_APPOINTMENT_SYSTEM_SOURCE.id
        }

        appointment.status = APPOINTMENT_STATUS.NEED_APPROVAL
        appointment.clinic_id = vm.GET_SCHEDULE_CURRENT_CLINIC_ID

        if (gridParams.mode !== $.scheduleGrid.MODE_DEFAULT) {
          Object.assign(appointment, vm.GET_INSERT_PARAMS_APPOINTMENT)
        }

        vm.$emit('create-appointment-of-grid', appointment)
      },

      onMoveFromWaitingList (appointment) {
        vm.$emit('move-appointment-from-waiting-list', appointment)
      },

      onEdit (appointment) {
        vm.$emit('edit-appointment-of-grid', appointment)
      },

      onChange (params) {
        if (params.mode === $.scheduleGrid.MODE_DEFAULT) {
          const queryParams = new URLSearchParams(window.location.search)

          if (queryParams.has('insert_client')) {
            queryParams.delete('insert_client')

            history.replaceState(null, null, '?' + queryParams.toString())
          }

          if (queryParams.has('insert_phone')) {
            queryParams.delete('insert_phone')

            history.replaceState(null, null, '?' + queryParams.toString())
          }
        }
      },

      onReminder (appointment) {
        if (!Services.security.canManageReminder) {
          Notificator.error(t('errors.action_not_permitted'))

          return
        }

        vm.$emit('create-reminder-of-grid', appointment)
      },

      onClientPreview (clientId) {
        vm.$emit('choose-last-appointment-client', clientId)
      },

      // Прячет кнопку вставки записи, вызывается при перемещении и копировании
      closeInsert () {
        vm.$store.commit('SET_MODE_GRID_SCHEDULE', $.scheduleGrid.MODE_DEFAULT)
        vm.$store.dispatch('changeScheduleClinic')
      },
    }

    /**
     * TODO: Отрефакторить работу с модалками, когда расписание будет стабильным.
     * Нужно сделать интерфейс для управление показом модалок и передачи им данных.
     * Это поможет избежать большого количества методов, которое будет расти с
     * ростом количества модалок. При этом возникает проблема того, как сообщить
     * о требуемых для модалки данных. Однако, это можно решить доками и типизацией.
     * Ну или хотя бы попытаться.
     */
    $doctorSchedule.doctorScheduleGrid({
      previewPreset: $.scheduleGrid.previewPresets.ADMINISTRATOR,
      currentUserId: this.GET_APP_CONF_CURRENT_USER_ID,
      tableVisibility: vm.IS_ACTIVE_SCHEDULE_DOCTORS,
      hideUsersWithoutSchedule: vm.GET_SIDEBAR_HIDE_USERS_WITHOUT_SCHEDULE_STATE,
      showOnlyWorkingDays: vm.GET_SIDEBAR_SHOW_ONLY_WORKING_DAYS_STATE,
      filtration: this.scheduleGridFiltrationParams,
      onFree: scheduleCommonMethods.onFree,
      onEdit: scheduleCommonMethods.onEdit,
      onChange: scheduleCommonMethods.onChange,
      onReminder: scheduleCommonMethods.onReminder,
      onClientPreview: scheduleCommonMethods.onClientPreview,
      closeInsert: scheduleCommonMethods.closeInsert,
      onMoveFromWaitingList: scheduleCommonMethods.onMoveFromWaitingList,
      setWaitingUser (uid) {
        vm.$emit('update:waitingUserFromGrid', uid)
      },
      selectOneVisibleUser (userId) {
        vm.$emit('update:oneVisibleUser', userId)
      },
      confirmationModalCall (show, data) {
        if (show) {
          const { headerMessage, message, noEvent, yesEvent } = data
          vm.showConfirmation(message, yesEvent, noEvent, headerMessage)
        } else {
          vm.hideConfirmation()
        }
      },
      createWorkTimeModalCall (show, data) {
        if (show) {
          const {
            headerMessage,
            message,
            onlyCreate,
            applyEvent,
            rejectEvent,
          } = data
          vm.openCreateWorkTimeModal(
            headerMessage,
            message,
            onlyCreate,
            applyEvent,
            rejectEvent
          )
        } else {
          vm.closeCreateWorkTimeModal()
        }
      },
      copyAppointmentWithServicesModalCall (show, data) {
        if (show) {
          const { noEvent, rejectEvent, yesEvent } = data
          vm.openCopyAppointmentWithServicesModal(yesEvent, noEvent, rejectEvent)
        } else {
          vm.closeCopyAppointmentWithServicesModal()
        }
      },
      alertCall (show, data) {
        if (show) {
          const { headerMessage, message } = data
          vm.showAlert(message, headerMessage)
        } else {
          vm.hideAlert()
        }
      },
    })

    $cabinetSchedule.cabinetScheduleGrid({
      previewPreset: $.scheduleGrid.previewPresets.ADMINISTRATOR,
      currentUserId: this.GET_APP_CONF_CURRENT_USER_ID,
      tableVisibility: vm.IS_ACTIVE_SCHEDULE_CABINETS,
      hideCabinetsWithoutSchedule: vm.GET_SIDEBAR_HIDE_CABINETS_WITHOUT_SCHEDULE_STATE,
      filtration: this.scheduleGridFiltrationParams,
      onFree: scheduleCommonMethods.onFree,
      onEdit: scheduleCommonMethods.onEdit,
      onChange: scheduleCommonMethods.onChange,
      onReminder: scheduleCommonMethods.onReminder,
      onClientPreview: scheduleCommonMethods.onClientPreview,
      closeInsert: scheduleCommonMethods.closeInsert,
      onMoveFromWaitingList: scheduleCommonMethods.onMoveFromWaitingList,
      selectOneVisibleCabinet (cabinetId) {
        vm.$emit('update:oneVisibleCabinet', cabinetId)
      },
      confirmationModalCall (show, data) {
        if (show) {
          const { headerMessage, message, noEvent, yesEvent } = data
          vm.showConfirmation(message, yesEvent, noEvent, headerMessage)
        } else {
          vm.hideConfirmation()
        }
      },
      userSelectModalCall (show, data) {
        if (show) {
          const { applyEvent, rejectEvent, selectedUserId, userIds } = data
          vm.openUserSelectModal(userIds, selectedUserId, applyEvent, rejectEvent)
        } else {
          vm.closeUserSelectModal()
        }
      },
      copyAppointmentWithServicesModalCall (show, data) {
        if (show) {
          const { noEvent, rejectEvent, yesEvent } = data
          vm.openCopyAppointmentWithServicesModal(yesEvent, noEvent, rejectEvent)
        } else {
          vm.closeCopyAppointmentWithServicesModal()
        }
      },
      alertCall (show, data) {
        if (show) {
          const { headerMessage, message } = data
          vm.showAlert(message, headerMessage)
        } else {
          vm.hideAlert()
        }
      },
    })

    $doctorSchedule.doctorScheduleGrid('option', { mode: vm.GET_MODE_GRID_SCHEDULE })
    $doctorSchedule.doctorScheduleGrid('setCurrentClinicId', vm.GET_SCHEDULE_CURRENT_CLINIC_ID)
    $doctorSchedule.doctorScheduleGrid('setDate', vm.GET_SCHEDULE_DATE)
    $doctorSchedule.doctorScheduleGrid('option', { step: vm.GET_SCHEDULE_DURATION })
    $doctorSchedule.doctorScheduleGrid('setDaysCount', vm.GET_SCHEDULE_DAYS_COUNT)

    $cabinetSchedule.cabinetScheduleGrid('option', { mode: vm.GET_MODE_GRID_SCHEDULE })
    $cabinetSchedule.cabinetScheduleGrid('setCurrentClinicId', vm.GET_SCHEDULE_CURRENT_CLINIC_ID)
    $cabinetSchedule.cabinetScheduleGrid('setDate', vm.GET_SCHEDULE_DATE)
    $cabinetSchedule.cabinetScheduleGrid('option', { step: vm.GET_SCHEDULE_DURATION })
    $cabinetSchedule.cabinetScheduleGrid('setDaysCount', vm.GET_SCHEDULE_DAYS_COUNT)
  },

  methods: {
    fetchDoctorScheduleGrid () {
      this.$store.commit('SET_LOADING_APPOINTMENTS', true)
      fetchDoctorAppointments(this.reqDoctorScheduleGridParams).then((response) => {
        this.$doctorScheduleElement.doctorScheduleGrid('setFiltration', this.scheduleGridFiltrationParams)
        this.$doctorScheduleElement.doctorScheduleGrid('setLoadData', response)
      }).finally(() => {
        this.$store.commit('SET_LOADING_APPOINTMENTS', false)
      })
    },

    fetchCabinetsScheduleGrid () {
      this.$store.commit('SET_LOADING_APPOINTMENTS', true)
      fetchCabinetsAppointments(this.reqCabinetsScheduleGridParams).then((response) => {
        this.$cabinetScheduleElement.cabinetScheduleGrid('setFiltration', this.scheduleGridFiltrationParams)
        this.$cabinetScheduleElement.cabinetScheduleGrid('setLoadData', response)
        this.$emit('update:availableCabinets', response.available_cabinets)
      }).finally(() => {
        this.$store.commit('SET_LOADING_APPOINTMENTS', false)
      })
    },
  },
}
</script>
