import { SMS_TEMPLATE_CATEGORY_TYPE } from '@/modules/sms/constants.js'

$document.on('rez/messages/templates/index', () => {
  const addItemBtn = document.getElementById('add-item')
  const addCategoryBtn = document.getElementById('add-category')
  const categoryList = $('#category-list')
  const template = FormFactory.build('messages/template', { categoryList })

  addItemBtn.addEventListener('click', (e) => {
    template.newItem()
    PubSub.emit('page.form.messages_template.editor.reset')
  })

  addCategoryBtn.addEventListener('click', (e) => {
    categoryList.categoriesx('newCategory')
  })

  categoryList.categoriesx({
    url: Routes.messages_templates_categories_path(),
    category_type: SMS_TEMPLATE_CATEGORY_TYPE,
    itemForm: template,
    editable: true,
    onItemEdit: template.editItem,
    groupEdit: false,
    onItemRemove (item) {
      Utils.askDestroy({
        title: item.title,
        url: Routes.messages_template_path(item.id) + '.json',
        success () {
          categoryList.categoriesx('removeItem', item)
        },
      })
    },
    onItemMove: template.changeCatgory,
    async onItemCopy (item) {
      const copy = { ...await item }
      template.newItem(copy)
    },
    onClick: template.editItem,
  })

  const saveButton = document.querySelector('.modal-save')
  const canManage = Services.security.canManageMessagesTemplate

  if (!canManage) {
    addItemBtn.classList.add('hide')
    addCategoryBtn.classList.add('hide')
    saveButton.classList.add('hide')
  }
})
