import applicationFactory from '../plugins/dynamic_forms/application_factory'

$document.on('rez/document_types/index', function () {
  applicationFactory.generate('documentRec')

  const addButton = document.querySelectorAll('.document-types-add-btn')
  const actionsMarked = document.querySelector('.dropdown-toggle')
  const saveButton = document.querySelector('.fa-save')
  const importMenuBtn = document.querySelector('#import-items')
  const canManage = Services.security.canManageDocumentType

  if (!canManage) {
    addButton.forEach((item) => item.classList.add('hide'))
    actionsMarked.classList.add('hide')
    saveButton.classList.add('hide')
    importMenuBtn.classList.add('hide')
  }

  $('#import-items').on('click', function () {
    $('.import-items-list').html('')
    $('#file_for_import').val('')
    $('#import-items-modal').megamodal('show')
    $('#import-items-modal').find('.modal-footer').hide()
    $('#import-items-modal').find('.megamodal_resize_full').hide()
    $('#import-button').hide()

    $('#result-import').hide()
    $('.items-form').show()
  })

  $('#import-items-modal').bind('hidden.bs.modal', (e) => {
    $('.import-items-list').html('')
    $('#file_for_import').val('')
  })

  $('#document_type_kinds').select2({ width: '100%' })

  const importButton = document.querySelector('#import-button')
  const category = $('#category')

  importButton.onclick = function () {
    $('#import-button').attr('disabled', true)
    const selectedItems = []
    $('.import-items-checkbox:checked').each(function (i, item) {
      const id = $(item).attr('data-id')
      const importElement = json.find(function (item) {
        return item.id == id
      })
      selectedItems.push(importElement)
    })

    $.ajax({
      type: 'POST',
      url: 'document_types/import',
      data: {
        items: selectedItems,
        category_id: category.categoriesx('getCategory').id,
      },
      success (data) {
        if (data.success == true) {
          $('#result-import')
            .text(t('import_complete_successfully_imported') + data.count)
          category.categoriesx('forceReload')
          $('#file_for_import').val('')
          $('.import-items-list').html('')
        } else {
          $('#result-import').text(t('errors.error_occurred_checking_file'))
        }

        $('#result-import').show()
        $('.items-form').hide()
        $('#import-button').attr('disabled', false)
      },
    })
  }
  let json
  document.querySelector('#file_for_import').addEventListener('change', handleFileSelect, false)

  function handleFileSelect (evt) {
    const files = evt.target.files

    // var output = []
    for (var i = 0, f; f = files[i]; i++) {
      const reader = new FileReader()

      // Closure to capture the file information.
      reader.onload = (function (theFile) {
        return function (e) {
          try {
            json = JSON.parse(e.target.result)

            const table = document.createElement('table')
            table.classList.add('table', 'table-striped', 'table-hover')
            const tbody = document.createElement('tbody')
            const tr = document.createElement('tr')
            const td = document.createElement('td')
            td.classList.add('options')
            const checkbox = document.createElement('input')
            checkbox.setAttribute('id', 'import-item-main')
            checkbox.type = 'checkbox'
            checkbox.onchange = function (e) {
              const importItems = document.querySelectorAll('.import-items-checkbox')
              const checked = e.target.checked
              $('.import-items-checkbox').each(function (i, item) {
                $(item).prop('checked', checked)
              })
              if (checked) {
                $('#import-button').show()
              } else {
                $('#import-button').hide()
              }
            }

            const textTd = document.createElement('td')
            textTd.innerHTML = '...'
            td.appendChild(checkbox)
            tr.appendChild(td)
            tr.appendChild(textTd)
            tbody.appendChild(tr)
            table.appendChild(tbody)

            json.forEach(function (item) {
              const tr = document.createElement('tr')
              const checkbox = document.createElement('input')
              checkbox.setAttribute('id', 'import-item-' + item.id)
              checkbox.onchange = function (e) {
                const checkItems = $('.import-items-checkbox:checked')
                if (checkItems.length > 0) {
                  $('#import-button').show()
                } else {
                  $('#import-button').hide()
                }
              }
              checkbox.classList.add('import-items-checkbox')
              checkbox.type = 'checkbox'
              checkbox.setAttribute('data-id', item.id)
              const td = document.createElement('td')
              td.classList.add('options')
              td.appendChild(checkbox)
              const textTd = document.createElement('td')
              textTd.innerHTML = item.title
              tr.appendChild(td)
              tr.appendChild(textTd)
              tbody.appendChild(tr)
            })

            document.querySelector('.import-items-list').appendChild(table)
          } catch (ex) {
            alert('ex when trying to parse json = ' + ex)
          }
        }
      })(f)
      reader.readAsText(f)
    }
  }

  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      break
    case 'edit':
      form.editItem()
      break
  }

  ////////////////////////////////////////////////////////////////////////////////////

  var form = FormFactory.build('document_type')

  DocAttrList.init({
    json_data: '/document_types/set_attribute_list',
    attribute_list: '#attribute_list',
  })
})
