<template>
  <div>
    <button
      class="btn btn-sm btn-success btn-with-icon modal-save"
      @click="$emit('add')"
    >
      <span class="btn-with-icon_icon fad fa-plus" />
      <span class="btn-with-icon_text">{{ T.add }}</span>
    </button>
    <button
      class="btn btn-sm btn-primary btn-with-icon modal-close"
      @click="$pubSub.emit('EMPLOYEES_SCHEDULE.HIDE_ADD_VACATION_MODAL')"
    >
      <span class="btn-with-icon_icon fad fa-times" />
      <span class="btn-with-icon_text">{{ T.close }}</span>
    </button>
  </div>
</template>

<script>
export default { name: 'AddVacationModalFooter' }
</script>
