import {
  IDoctorsProductivityReportFiltersGet as FiltersGet,
  IDoctorsProductivityReportFiltersSet as FiltersSet,
} from 'src/specific/reports/doctorsProductivity/interfaces'
import {
  IDoctorsProductivityReportFiltersProfileFiltersToClient as IFiltersToClient,
  IDoctorsProductivityReportFiltersProfileFiltersToServer as IFiltersToServer,
} from '../../interfaces/filtersTypes/doctorsProductivityReport'
import { IFiltersProfilesFetchResponse } from '../../interfaces/IFIltersProfilesFetchResponse'
import { cleanUnpermittedValues } from '../../helpers/formatters/shared'
import { omit, omitBy, isUndefined } from 'lodash'
import { IFiltersProfilesModel } from '../../interfaces/IFiltersProfilesModel'
import { FiltersProfilesLegacyFormattersToServer as FormattersToServer } from '../../helpers/formatters/legacy/toServer'
import { FiltersProfilesLegacyFormattersToClient as FormattersToClient } from '../../helpers/formatters/legacy/toClient'
import { gatherErrors } from '../../helpers/gatherErrors'

export const doctorsProductivityReportFiltersProfileAdapter = {
  toServer (data: IFiltersProfilesModel<FiltersGet>): IFiltersProfilesModel<IFiltersToServer> {
    const { data: filters } = data

    const adaptedFilters: IFiltersToServer = {
      period: FormattersToServer.formatPeriod(filters.period),
      clinic: FormattersToServer.formatSingle(filters.clinic),
      appointmentType: FormattersToServer.formatSingle(filters.appointmentType),
      specialty: FormattersToServer.formatSingle(filters.specialty),
      includeEntryWithoutOrder: filters.entryWithoutOrderInclusion.id === 'include_entry_without_order',
      includeUsersWithoutWorkInThisPeriod: filters.usersWithWorkTime.id === 'allow_users_with_worktime',
    }

    return { ...data, data: adaptedFilters }
  },

  toClient (data: IFiltersProfilesFetchResponse<IFiltersToClient>): IFiltersProfilesModel<FiltersSet> {
    const { data: filters } = data

    const validFilters = omitBy(cleanUnpermittedValues(filters), isUndefined) as IFiltersToClient

    const adaptedFilters: FiltersSet = {
      period: FormattersToClient.formatPeriod(validFilters.period),
      clinic: FormattersToClient.formatSingle(validFilters.clinic),
      appointmentType: FormattersToClient.formatSingle(validFilters.appointmentType),
      specialty: FormattersToClient.formatSingle(validFilters.specialty),
      entryWithoutOrderInclusion: FormattersToClient.formatCommon(
        validFilters.includeEntryWithoutOrder
          ? 'include_entry_without_order'
          : 'exclude_entry_without_order'
      ),
      usersWithWorkTime: FormattersToClient.formatCommon(
        validFilters.includeUsersWithoutWorkInThisPeriod
          ? 'allow_users_with_worktime'
          : 'disallow_users_with_worktime'
      ),
    }

    const definedAdaptedFilters = omitBy(adaptedFilters, isUndefined) as FiltersSet

    const renamedFilters = omitBy({
      ...omit(filters, 'includeEntryWithoutOrder', 'includeUsersWithoutWorkInThisPeriod'),
      entryWithoutOrderInclusion: filters.includeEntryWithoutOrder,
      usersWithWorkTime: filters.includeUsersWithoutWorkInThisPeriod,
    }, isUndefined)

    return {
      ...data,
      data: definedAdaptedFilters,
      dataErrors: gatherErrors(renamedFilters),
    }
  },
}
