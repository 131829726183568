export default {
  disabled: {
    type: Boolean,
  },
  targetedDisable: {
    type: Object,
    default () {
      return {}
    },
  },
  /**
   * @type {import('vue').PropOptions<ClientFormErrors>}
   */
  errors: {
    type: Object,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  surname: {
    type: String,
    required: true,
  },
  secondName: {
    type: String,
    required: true,
  },
  phone: {
    type: String,
    required: true,
  },
  email: {
    type: String,
    required: true,
  },
  medicalDoctor: {
    type: Object,
    default: () => ({}),
  },
  company: {
    type: Object,
    default: () => null,
  },
  specialty: {
    type: Array,
    default: null,
  },
  code: {
    type: String,
    required: true,
  },
  additional: {
    type: String,
    required: true,
  },
  clinicId: {
    type: Number,
    default: null,
  },
}
