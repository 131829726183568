import { debounce } from 'lodash/function'
import { findClients } from '@/vue_components/doctor_schedules/rest'

const getEmptySimilarClients = () => ({
  clients: [],
  selected: null,
})

const getEmptyClientsSearch = () => ({
  surname: '',
  name: '',
  second_name: '',
  phone: '',
})
/**
 * Эта миксина упрощает работу с компонентом AppointmentSimilarClients.
 * Обычный сценарий применения:
 * - Ставится вотчер на clientsSearch с deep: true
 * - в вотчере вызывается findSimilarClients
 * - на каждое изменение поля поиска вызывается setClientSearchParam с соответствующим именем
 *
 * Пример использования в waiting_edit_modal.vue и appointment_create_modal.vue
 */
export const SimilarClientsConsumer = {
  data () {
    return {
      clientsSearch: getEmptyClientsSearch(),
      similarClients: getEmptySimilarClients(),
    }
  },
  methods: {
    setClientSearchParam: debounce(function (param, value) {
      this.clientsSearch[param] = value
    }, 300),

    /**
     * @param {{
     *  surname:? string,
     *  name?: string,
     *  second_name?: string,
     *  phone?: string
     * }} searchParams
     */
    findSimilarClients (searchParams) {
      findClients({ client: searchParams })
        .then((response) => {
          this.similarClients.clients = response
        })
    },
    resetSimilarClients () {
      this.similarClients = getEmptySimilarClients()
      this.clientsSearch = getEmptyClientsSearch()
    },
  },
}
