<template>
  <catalog-wrapper
    model="CompanyType"
    catalog-icon="fad fa-fw fa-city"
    :table-title="t('company_types')"
    :form-create-title="t('company_type_creating')"
    :form-edit-title="t('company_type_editing')"
    :add-item-text="t('add_company_type')"
    :items="companyTypes"
    :loading="loadingList"
    :selected-mode="selectedMode"
    :is-show-form="isShowForm"
    :total-pages="totalPages"
    :is-show-form-btns="!isSystemName"

    @ask-create="openCreateForm"
    @close="hideForm"
    @submit="handleSubmit"
    @ask-delete="destroyCompanyType"
    @change-pagination="onPageChange"
  >
    <template #table>
      <company-types-table
        :company-types="companyTypes"
        :selected-list-item="selectedListItem"
        @ask-edit="openEditForm"
      />
    </template>

    <template #form>
      <company-types-form
        :is-system-name="isSystemName"
        :title.sync="formData.title"
        :validation-errors="validationMessages"
      />
    </template>
  </catalog-wrapper>
</template>

<script>
import { isEqual } from 'lodash'
import CatalogWrapper from '../catalog_wrapper/catalog_wrapper_deprecated.vue'
import CompanyTypesTable from './components/company_types_table.vue'
import CompanyTypesForm from './components/company_types_form.vue'
import { CatalogHolder } from '@/vue_components/mixins/catalogs/catalogHolder'
import { PaginationHolder } from '@/vue_components/mixins/pagination_holder'
import { requiredValidator } from '@/lib/validators/validators'
import { companyTypesEndpoint } from '@/api_entities/catalogs/company_types/company_types_endpoint'
import { creators, ERRORS_COMPANIES_LIMIT, PAGINATION_LIMIT } from './const'
import { MODES, NOTIFICATOR_LIFETIME } from '../const'
import { findPreExistingTitle } from '../helpers'
import { trimValues } from '../../helpers'

export default {
  name: 'CompanyTypesCatalog',
  components: {
    CatalogWrapper,
    CompanyTypesTable,
    CompanyTypesForm,
  },

  mixins: [CatalogHolder, PaginationHolder],

  data () {
    return {
      companyTypes: [],
    }
  },

  computed: {
    isSystemName () {
      return !!this.formData.system_name
    },
  },

  watch: {
    'formData.title' (newValue) {
      this.validate('title', newValue, requiredValidator)
    },
  },

  created () {
    this.initCatalogHolder(creators)
    this.initPaginationHolder({
      limit: PAGINATION_LIMIT,
      offsetCallback: this.getCompanyTypes,
    })
    this.getCompanyTypes()
  },

  methods: {
    getCompanyTypes () {
      this.loadingList = true

      companyTypesEndpoint.getAll(this.pagination)
        .then((data) => {
          this.companyTypes = data.data
          this.totalPages = data.total_pages
        })
        .catch((err) => Utils.reportError(
          'getCompanyTypes error',
          t('reception.errors.get_list')
        )(err))
        .finally(() => {
          this.loadingList = false
        })
    },

    createCompanyType (data) {
      if (findPreExistingTitle(this.companyTypes, data)) {
        Notificator.warning(
          t(
            'reception.pre_existing_item',
            { item: t('reception.catalog_items_names.company_type') }
          ),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      companyTypesEndpoint.create(data)
        .then(() => {
          this.clearForm()
          this.getCompanyTypes()

          Notificator.success(
            t('record_successfully_created'),
            '',
            NOTIFICATOR_LIFETIME
          )
        })
        .catch((err) => {
          if (err instanceof Error) {
            Utils.reportError(
              'createCompanyType error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.create') }
              )
            )(err)
          } else {
            this.setValidationMessages(this.validationMessages, err.responseJSON)
          }
        })
    },

    updateCompanyType (data) {
      if (findPreExistingTitle(this.companyTypes, data)) {
        Notificator.warning(
          t(
            'reception.pre_existing_item',
            { item: t('reception.catalog_items_names.company_type') }
          ),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      if (isEqual(this.previousFormData, data)) {
        Notificator.warning(
          t('reception.dont_find_update'),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      companyTypesEndpoint.update(data)
        .then(() => {
          this.getCompanyTypes()
          this.previousFormData = { ...data }

          Notificator.info(
            t('changes_updated'),
            '',
            NOTIFICATOR_LIFETIME
          )
        })
        .catch((err) => {
          if (err instanceof Error) {
            Utils.reportError(
              'updateCompanyType error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.update') }
              )
            )(err)
          } else {
            this.setValidationMessages(this.validationMessages, err.responseJSON)
          }
        })
    },

    destroyCompanyType () {
      companyTypesEndpoint.destroy(this.formData.id)
        .then(() => {
          this.getCompanyTypes()
          this.hideForm()

          Notificator.success(
            t('record_successfully_deleted'),
            '',
            NOTIFICATOR_LIFETIME
          )
        })
        .catch((err) => {
          if (err.status === 422) {
            let errorCompanies = err.responseJSON.there_are_dependent_companies[0]
            let message

            if (errorCompanies.length > ERRORS_COMPANIES_LIMIT) {
              message = `${t('was_error_deleting')}. ` +
              `${t('companies')}: ${errorCompanies.slice(0, ERRORS_COMPANIES_LIMIT).join(', ')} ` +
              `${t('and_also')} ${errorCompanies.length - ERRORS_COMPANIES_LIMIT} ` +
              t('companies_depend_on_type')
            } else {
              message = `${t('was_error_deleting')}. ${t('companies')}: ${errorCompanies.join(', ')} ${t('companies_depend_on_type')}`
            }

            Utils.reportError(
              'destroyCompanyType error',
              message
            )(err)
          } else {
            Utils.reportError(
              'destroyCompanyType error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.delete') }
              )
            )(err)
          }
        })
    },

    handleSubmit () {
      trimValues(this.formData)

      if (this.hasErrors()) return

      if (this.selectedMode === MODES.NEW) {
        this.createCompanyType(this.formData)
      } else {
        this.updateCompanyType(this.formData)
      }
    },
  },
}
</script>
