import { ReportParamFactory } from '@/specific/reports/shared'

export default function (source) {
  return {
    clinicIds: ReportParamFactory.buildArrayParam(source.clinic),
    responsibleIds: ReportParamFactory.buildArrayParam(source.user),
    callTypes: ReportParamFactory.buildArrayParam(source.callType),
    callStatuses: ReportParamFactory.buildArrayParam(source.callStatus),
    callResults: ReportParamFactory.buildArrayParam(source.callResult),
    refuseResultIds: ReportParamFactory.buildArrayParam(source.refuseReason),
    attractionSourceIds: ReportParamFactory.buildArrayParam(source.attractionSources),
  }
}
