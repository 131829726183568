<template>
  <div class="client-form">
    <div class="flex-form">
      <div class="form-label">
        <slot name="surname-label">
          <label for="surname">
            {{ t('surname') }}
          </label>
        </slot>
      </div>
      <div class="form-value">
        <slot name="surname-value">
          <validation-wrapper :errors="errors.surname">
            <div class="flex">
              <input
                id="surname"
                class="form-control"
                type="text"
                :value="surname"
                :disabled="targetedDisable.surname || disabled"
                @input="$updateSync('surname', $event.target.value)"
              >
            </div>
          </validation-wrapper>
        </slot>
      </div>
      <div class="form-label">
        <slot name="name-label">
          <label for="name">
            {{ t('name') }}
          </label>
        </slot>
      </div>
      <div class="form-value">
        <slot name="name-value">
          <input
            id="name"
            class="form-control"
            type="text"
            :value="name"
            :disabled="targetedDisable.name || disabled"
            @input="$updateSync('name', $event.target.value)"
          >
        </slot>
      </div>
      <div class="form-label">
        <slot name="second-name-label">
          <label for="second-name">
            {{ t('second_name') }}
          </label>
        </slot>
      </div>
      <div class="form-value">
        <slot name="second-name-value">
          <input
            id="second-name"
            class="form-control"
            type="text"
            :value="secondName"
            :disabled="targetedDisable.secondName || disabled"
            @input="$updateSync('second-name', $event.target.value)"
          >
        </slot>
      </div>
      <div class="form-label">
        <slot name="phone-label">
          <label for="phone">
            {{ t('phone') }}
          </label>
        </slot>
      </div>
      <div class="form-value">
        <slot name="phone-value">
          <phone-input
            id="phone"
            class="form-control"
            type="text"
            :value="phone"
            :disabled="targetedDisable.phone || disabled"
            @input="$updateSync('phone', $event)"
          />
        </slot>
      </div>
      <div class="form-label">
        <slot name="email-label">
          <label for="email">
            {{ t('email') }}
          </label>
        </slot>
      </div>
      <div class="form-value">
        <slot name="email-value">
          <validation-wrapper :errors="errors.email">
            <div class="flex">
              <input
                id="email"
                class="form-control"
                type="email"
                :value="email"
                :disabled="targetedDisable.email || disabled"
                @input="$updateSync('email', $event.target.value)"
              >
            </div>
          </validation-wrapper>
        </slot>
      </div>
      <div class="form-label">
        <slot name="company-label">
          <label for="company">
            {{ t('company') }}
          </label>
        </slot>
      </div>
      <div class="form-value">
        <slot name="company">
          <div class="input-container">
            <company-select
              :disabled="disabled"
              :value="company"
              @change="$updateSync('company', $event)"
            />
            <button
              v-tooltip="t('create_new_company')"
              class="btn btn-primary btn-xs hidden-print input-button input-button_full-height fad fa-building"
              type="button"
              :disabled="targetedDisable.companyId"
              @click="$emit('add-company')"
            />
          </div>
        </slot>
      </div>
      <div class="form-label">
        <slot name="specialty-label">
          <label for="specialty">
            {{ t('specialty') }}
          </label>
        </slot>
      </div>
      <div class="form-value">
        <slot name="specialty-value">
          <specialty-select
            :value="specialty"
            :disabled="targetedDisable.specialty || disabled"
            @change="$updateSync('specialty', $event)"
          />
        </slot>
      </div>

      <div class="form-label">
        <slot name="midecal-doctor-label">
          <label for="medical-doctor">
            {{ t('activerecord.attributes.referral.medical_user') }}
          </label>
        </slot>
      </div>
      <div class="form-value">
        <slot name="medical-doctor-value">
          <user-simple-select
            :clinic-id="clinicId"
            :disabled="targetedDisable.medicalDoctor || disabled"
            :value="medicalDoctor"
            full-width
            @change="$updateSync('medical-doctor', $event)"
          />
        </slot>
      </div>
      <div class="form-label">
        <slot name="code-label">
          <label for="code">
            {{ t('code') }}
          </label>
        </slot>
      </div>
      <div class="form-value">
        <slot name="code-value">
          <input
            id="code"
            class="form-control"
            type="text"
            :value="code"
            :disabled="targetedDisable.code || disabled"
            @input="$updateSync('code', $event.target.value)"
          >
        </slot>
      </div>
      <div class="form-label">
        <slot name="additional-label">
          <label for="additional">
            {{ t('additional') }}
          </label>
        </slot>
      </div>
      <div class="form-value">
        <slot name="additional-value">
          <textarea
            id="additional"
            rows="2"
            class="form-control"
            :value="additional"
            :disabled="targetedDisable.additional || disabled"
            @input="$updateSync('additional', $event.target.value)"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneInput from '@/vue_components/common/phone_input.vue'

import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import props from './referrals_form_user_props.js'
import CompanySelect from '@/vue_components/common/select/company_select'
import SpecialtySelect from '@/vue_components/common/select/specialty_select'
import UserSimpleSelect from '@/vue_components/common/select/UserSimpleSelect'

/**
 *
 * To paste demo data, subscribe to event `request-demo` in the parent component,
 * get demo data somewhere and set new props based on that data.
 *
 * Emits `request-demo`, `add-legal-representative`  without arguments
 */
export default {
  name: 'ReferralsFormUser',

  components: {
    UserSimpleSelect,
    SpecialtySelect,
    CompanySelect,
    PhoneInput,
    ValidationWrapper,
  },

  props,
}
</script>
