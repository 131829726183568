<template>
  <div class="print-receipt-modal">
    <m-modal
      :visible.sync="modalVisibility"
      :dialog-title="t('fiscal.enter_recipient_info')"
    >
      <template #body>
        <m-select
          :value.sync="value"
          :items="items"
          :clearable="false"
          allow-create
          full-width
          filterable
          :required="phoneValidation"
          validator-name="contactsSelector"
          @registerValidator="onRegisterValidator"
        />
        <div class="print-receipt_input-clarification">
          {{ t('fiscal.modalInputClarification') }}
        </div>
      </template>

      <template #footer-left>
        <div class="print-receipt-buttons">
          <m-button
            :text="t('apply')"
            type="success"
            class="print-receipt-buttons_apply"
            @click="onSave"
          />
          <m-button
            :text="t('cancel')"
            type="primary"
            @click="onClose"
          />
        </div>
      </template>
      <template #footer-right>
        <span />
      </template>
    </m-modal>
  </div>
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { phoneValidation } from '@/vue_apps/FiscalService/printReceipt/scripts/phoneValidation'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

const DEFAULT_ITEM_TYPE = 'phone'

export default {
  name: 'PrintReceipt',
  components: { MModal, MButton, MSelect },
  mixins: [ValidationParentMixin],

  data () {
    return {
      value: {},
      printMethod: 'electronically',
      modalVisibility: true,
      phoneValidation,
    }
  },

  computed: {
    items () {
      const values = Object.entries(gon.specific.receiver_info).filter((item) => item[1])

      const items = values.map((item) => {
        if (item[0].includes(DEFAULT_ITEM_TYPE)) {
          const maskedPhone = Services.phoneMask.setMask(item[1].replace('+', ''))

          return ({ id: item[1], title: `+${maskedPhone}`, type: DEFAULT_ITEM_TYPE })
        }

        return ({ id: item[1], title: item[1], type: item[0] })
      })

      return items
    },
  },

  mounted () {
    const defaultValue = this.items.find((item) => item.type === DEFAULT_ITEM_TYPE)

    if (!defaultValue) {
      return
    }

    this.value = defaultValue
  },

  methods: {
    onSave () {
      if (this.hasErrors()) { return }

      const value = this.value.id
        ? this.value.id
        : this.value

      Services.workplace.sendMessage('print_receipt', {
        print_method: this.printMethod,
        receiver_info: value,
        payment_id: gon.specific.payment.id,
      })

      this.onClose()

      $('#payments_page').loadSpin('start')
    },

    onClose () {
      this.modalVisibility = false
    },
  },
}
</script>
