<template>
  <si-field>
    <div class="title">
      {{ t('title') }}
    </div>
    <div
      v-if="false"
      class="comment"
    >
      {{ t('comment') }}
    </div>
    <div class="client">
      {{ t('client') }}
    </div>
    <div class="date">
      {{ t('custom_date') }}
    </div>
    <div class="price">
      {{ t('price') }}
    </div>
    <div
      v-tooltip="t('reports.priceWithDiscount')"
      class="price-with-discount"
    >
      {{ t('reports.priceWithDiscount') }}
    </div>
    <div class="amount">
      {{ t('amount') }}
    </div>
    <div class="sum">
      {{ t('sum') }}
    </div>
    <div class="reward">
      <span v-tooltip="t('reports.rewardTooltip')">{{ t('reports.reward') }}</span>
    </div>
    <div class="reward-sum">
      <span v-tooltip="t('reports.rewardSumTooltip')">{{ t('reports.rewardSum') }}</span>
    </div>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { ReferralHeader } from '@/vue_components/reports/referrals/header/referralHeader'

export default {
  name: 'ReferralsHeaderUngroupedReferral',
  components: {
    SiField,
  },
  mixins: [ReferralHeader],
}
</script>
