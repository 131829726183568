<template>
  <form-block :title="T.info">
    <table
      v-if="clientId"
      class="show_info_table appointment-client-summary-table"
    >
      <tbody>
        <tr>
          <td>{{ T.cards }}</td>
          <td>
            <a
              :href="clientId | route('client_path')"
              target="_blank"
            >
              {{ T.infocard }}
            </a>
            /
            <a
              :href="medicalRecordId | route('medical_record_path')"
              target="_blank"
            >
              {{ T.medcard }}
            </a>
          </td>
        </tr>
        <tr>
          <td>{{ T.made_payment }}</td>
          <td>{{ paidSum | currency }}</td>
        </tr>
        <tr>
          <td>{{ T.credit_debt }}</td>
          <td :class="debtClass">
            {{ creditDebt | currency }}
          </td>
        </tr>
        <tr>
          <td>{{ T.groups }}</td>
          <td class="client-groups-list">
            <span
              v-for="(group, index) in styledClientGroups"
              :key="index"
              :class="group.className"
            >
              {{ group.title }}
            </span>
          </td>
        </tr>
        <tr>
          <td>{{ T.reception.last_visit_short }}</td>
          <td class="last-visit">
            {{ lastVisit }}
          </td>
        </tr>
        <tr>
          <td>{{ T.reception.last_visit_to_the_same_doctor }}</td>
          <td class="last-visit">
            <span class="last-visit__days-ago">
              {{ lastVisitToTheSameDoctorDaysAgo }}
            </span>
            {{ lastVisitToTheSameDoctor }}
          </td>
        </tr>
        <tr class="special-marks">
          <td>{{ T.special_marks }}</td>
          <td class="failed-appointment">
            <div
              v-if="previousAppointmentFailed"
              class="previous_appointment_failed"
            >
              {{ T.previous_appointment }} -
              <span
                class="previous_appointments_show previous_appointments_show-text as_link"
                @click="showLastAppointments"
              >
                {{ T.failed }}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ T.adv_distribution }}</td>
          <td class="adv_distribution">
            <div
              v-for="(mark, index) in advDistribution"
              :key="index"
            >
              {{ t(mark) }}
            </div>
          </td>
        </tr>

        <!-- Медицинский полис-->
        <tr>
          <td>{{ t('medical_policy') }}</td>
          <td>{{ hasActiveMedicalPolicyText }}</td>
        </tr>
      </tbody>
    </table>
  </form-block>
</template>

<script>
import FormBlock from '@/vue_components/common/form_block'

import props from '@/vue_components/appointment/props_client_summary.js'
import { SYSTEM_CLIENT_GROUPS } from '@/vue_components/client/const'

export default {
  name: 'AppointmentClientSummary',
  components: { FormBlock },

  props,

  computed: {
    debtClass () {
      return { debt: this.creditDebt > 0 }
    },

    styledClientGroups () {
      return this.clientGroups.map((group) => ({
        ...group,
        className: this.clientGroupClass(group),
      }))
    },

    hasActiveMedicalPolicyText () {
      return this.activeMedicalPoliciesTitles || '-'
    },
  },

  methods: {
    showLastAppointments () {
      this.$emit('show-last-appointments', this.clientId)
    },

    clientGroupClass (group) {
      switch (group.system_name) {
        case SYSTEM_CLIENT_GROUPS.vip: return 'label label-red'
        case SYSTEM_CLIENT_GROUPS.blacklist: return 'label label-black'
        default: return 'label'
      }
    },
  },
}
</script>
