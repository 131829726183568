import Egisz from '@/vue_components/egisz/egisz_module/Egisz'
import { createStore } from '@/vue_components/store/store'
import { EGISZ_CHANNEL } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import { createVueApp } from '@/vue_components/create_vue_app'

let vueApp = null

export const VueEgiszEntryPoint = () => {
  const el = '#vue_egisz_module'
  if (!document.querySelector(el)) { return }
  if (vueApp?.destroy) { vueApp.destroy() }

  const store = createStore()
  store.dispatch('globalCatalogs/doctorStore/loadDoctors')
  store.dispatch('egisz/egiszWs/vxEgiszWSConnect', EGISZ_CHANNEL)

  // eslint-disable-next-line no-new
  vueApp = createVueApp({
    store,
    el,
    name: 'EgiszModule',
    render: (h) => h(Egisz),
  })
}
