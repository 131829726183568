import Vue from 'vue/dist/vue.esm'
import Cancellations from '@/vue_components/reports/cancellations'
import { fetchExportFile } from './export.js'
import ReportWrapper from '@/vue_components/mixins/reportWrapper.js'
import { ReportParamFactory } from '@/specific/reports/shared'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

$document.on('rez/reports/cancellations', function () {
  Report.init()
  const tableHeader = [
    { title: T.title },
    { title: T.amount },
    { title: T.sum },
    { title: T.cost_price },
  ]

  const reportTable = new Vue({
    el: '#report_right_container',
    components: { Cancellations },
    mixins: [ReportWrapper],
    data: () => ({
      filters: getFilters(),
    }),
    template: `
      <cancellations
        ref="report"
        :isHidden="isHidden"
        v-bind="filters"
      />`,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(getFilters())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', function () {
    const filters = getFilters()

    const data = {
      'csvComma': gon.application.csv_character,
      'floatingComma': gon.localization.currency.decimal_character,
      'headerRow': {
        'entryTypeTitle': tableHeader[0].title,
        'entryTotalAmount': tableHeader[1].title,
        'entryTotalFinalSum': tableHeader[2].title,
        'entryTotalCostSum': tableHeader[3].title,
      },
      'totalRow': {
        'total': T.total,
      },
      'entryConsumable': filters.entryConsumable.id,
      'offset': 0,
      'limit': -(1 << 32 - 1),
      'report': {
        'period': filters.period,
      },
      ...ReportFiltersFactory.defineFor('cancellations', filters),
    }

    fetchExportFile(data, Routes.report_cancellations_path(), T.cancellations)
  })

  function getFilters () {
    return {
      period: ReportParamFactory.getRawParam('.period'),
      clinic: ReportParamFactory.getSelectParam('#report_clinic_id'),
      store: ReportParamFactory.getSelectParam('#report_store_id'),
      entryConsumable: ReportParamFactory.getSelectParam('#report_consumable'),
    }
  }
})
