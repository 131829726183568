import { HTTP_METHODS, MRequest } from '@/lib/transport/MRequest'
import { fileAdapter } from '@/api_entities/entry_types/entry_types_adapters'
import { request } from '@/lib/transport/request'

class EntryTypesEndpoint extends MRequest {
  pricesValidate ({ file }) {
    const options = {
      type: HTTP_METHODS.POST,
      url: Routes.import_validations_update_prices_path(),
      data: fileAdapter.toServer(file),
      contentType: false,
      processData: false,
      dataType: 'text',
    }

    return request(options).promise
  }

  pricesImport ({ file }) {
    const options = {
      type: this.HTTP_METHODS.POST,
      url: Routes.update_prices_entry_types_path(),
      data: fileAdapter.toServer(file),
      contentType: false,
      processData: false,
    }

    return super.customRequest(options).promise
  }
}

export const entryTypesEndpoint = new EntryTypesEndpoint()
