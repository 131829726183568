export const setComplexMembersAttributes = (
  complexIndex: string | number,
  attributeName: string,
  attributeValue: string | number
) => {
  getComplexMembersAttribute(
    complexIndex,
    attributeName
  ).forEach((node: HTMLInputElement) => {
    node.value = attributeValue.toString()
  })
}

export const getComplexIndexByComplexHeadItemNode = (complexHeadItemNode: HTMLElement): string => {
  return Utils.extractDigits(
    complexHeadItemNode
      .querySelector('input')
      .name
  )
}

const getComplexMembersAttribute = (
  complexIndex: string | number,
  attributeName: string
) => {
  const startWith = `[name^='order[entries_attributes][${complexIndex}][members_attributes]']`
  const endWith = `[name$='[${attributeName}]']`

  return document.querySelectorAll(startWith + endWith)
}
