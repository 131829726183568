<template>
  <div style="position: relative">
    <epic-spinner :visibility="showLoadingSpinner" />
    <div
      class="el-card__body__content"
      style="height: 290px"
    >
      <div id="attraction_source-container" />
    </div>
    <div class="el-card__body__footer">
      <div class="el-card__body__footer__filters">
        <clinic-selector :card-name="cardName" />
        <period-selector :card-name="cardName" />
      </div>
      <div class="el-card__body__footer__actions" />
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import EpicSpinner from '../../../epic_spinner/epic_spinner.vue'
import ClinicSelector from '../selectors/clinic_selector.vue'
import PeriodSelector from '../selectors/period_selector.vue'
import Card from './card'
import CardWithChart from './cardWithChart'

export default {
  components: { EpicSpinner, ClinicSelector, PeriodSelector },
  mixins: [Card, CardWithChart],

  data () {
    return {
      route: this.$routes.reports_create_head_desktop_attraction_source_path(),
    }
  },

  computed: {
    requestParams () {
      return {
        clinic_id: this.cardParams.clinic_id,
        date_start: this.cardParams.date_range[0],
        date_end: this.cardParams.date_range[1],
      }
    },
  },

  methods: {
    cardAdapter (result) {
      this.chartData = result.attraction_source
    },

    renderChart () {
      Highcharts.chart('attraction_source-container', {
        chart: {
          type: 'column',
          height: '290px',
        },

        series: [{
          name: this.T.head_desktop.attraction_sources,
          color: '#FF5722',
          data: this.chartData,
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.0f}', // 0 decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif',
              textOutline: false,
            },
          },
        }],
        title: false,
        legend: {
          enabled: false,
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: 0,
            style: {
              fontSize: '12px',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        tooltip: {
          pointFormat: this.T.head_desktop.appointments + ': <b>{point.y:.0f}</b>',
        },
      })
    },
  },
}
</script>
