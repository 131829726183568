<template>
  <si-control
    class="gap-indent-small"
    @refresh="vxRefreshFilters()"
    @reset="resetFilters(true)"
  >
    <!-- Клиники -->
    <m-select
      v-model="filterClinic"
      class="filters__clinics flex-grow-1"
      :m-fixed-height="false"
      :items="vxClinics"
      clearable
      :placeholder="t('all_clinics')"
      @change="onFilterClinicChange"
    />

    <!-- Дата -->
    <m-date-picker-buttons
      v-model="filterDate"
      class="filter-date-picker-buttons"
      :use-only="datePickerButtons"
      :m-fixed-height="false"
    />
    <m-date-picker
      v-model="filterDate"
      :m-fixed-height="false"
      type="daterange"
      :shortcuts="datePickerShortcuts"
    />

    <!-- Специальности -->
    <m-select
      v-model="filterSpecialty"
      :items="vxSpecialties"
      :m-fixed-height="false"
      clearable
      filterable
      :custom-filter="defaultSearchFilter"
      :placeholder="t('specialties')"
    />

    <!-- Врачи (todo: требуется переделка под m-select-lazy) -->
    <m-select
      v-model="filterDoctor"
      v-tooltip="filterDoctor && filterDoctor.fullName"
      :items="vxDoctors"
      :m-fixed-height="false"
      clearable
      option-label="fullName"
      filterable
      :custom-filter="doctorSearchFilter"
      :placeholder="t('doctors')"
      use-user-mode
    >
      <template #prefix>
        <user-avatar
          :user-id="filterDoctor && filterDoctor.id"
          :params="{ version: 'thumb16', tag: filterDoctor && filterDoctor.avatarTag }"
          :style="{ marginTop: '8px', marginLeft: '1px' }"
        />
      </template>
    </m-select>

    <!-- Статус -->
    <m-select
      v-model="filterStatus"
      v-tooltip="pickedStatuses"
      :items="vxGetStatuses"
      :m-fixed-height="false"
      multiple
      collapse-tags
      clearable
      :placeholder="t('all_statuses')"
    >
      <template #options>
        <el-option
          v-for="status in vxGetStatuses"
          :key="`status:${status.id}`"
          :value="status"
          :label="status.title"
        >
          <m-icon
            :icon="status.icon"
            :class="status.iconCss"
            class="mr-5"
            color=""
          /> {{ status.title }}
        </el-option>
      </template>
    </m-select>

    <!-- Статус звонка -->
    <m-select
      v-model="filterCallStatus"
      v-tooltip="pickedCallStatuses"
      :items="vxGetCallStatuses"
      :m-fixed-height="false"
      multiple
      collapse-tags
      clearable
      :placeholder="t('all_confirmation_statuses')"
    >
      <template #options>
        <el-option
          v-for="callStatus in vxGetCallStatuses"
          :key="`callStatus:${callStatus.id}`"
          :value="callStatus"
          :label="callStatus.title"
        >
          <m-icon
            :icon="callStatus.icon"
            :class="callStatus.iconCss"
            class="mr-5"
            color=""
          /> {{ callStatus.title }}
        </el-option>
      </template>
    </m-select>
  </si-control>
</template>

<script>
import SiControl from '@/vue_components/sort_items/si_control.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import {
  APPOINTMENT_CALL_LOG_CACHE_KEY,
  DEFAULT_FILTRATION_DEBOUNCE,
  FILTERS,
} from '@/vue_apps/DoctorSchedules/AppointmentCallLog/store/const'
import { getFilters } from '@/vue_apps/DoctorSchedules/AppointmentCallLog/store/filters'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { MLocalStorage } from '@/_api/_storage/MLocalStorage'
import { cloneDeep } from 'lodash'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MDatePickerButtons from '@/vue_present/_base/MDatePickerButtons/MDatePickerButtons.vue'
import { defaultSearchFilter } from '@/helpers/lambda'
import UserAvatar from '@/vue_components/user_avatar.vue'
import { DATE_PICKER_BUTTONS } from '@/vue_present/_base/MDatePickerButtons/helpers'
import { CALENDAR_RANGE_NAME } from '@/vue_components/calendar_ranges'

const filtersBinding = (filter) => ({
  get () { return this.vxFilters[filter] },
  set (value) { this.vxSetFilterValue({ filter, value }) },
})

export default {
  name: 'AppointmentCallLogFilters',
  components: { UserAvatar, MDatePickerButtons, MDatePicker, MIcon, MSelect, SiControl },

  props: {
    filtrationDebounce: PropsTypes.Number(DEFAULT_FILTRATION_DEBOUNCE),
    doctorSchedulesClinicId: { type: Number, required: true },
    loading: Boolean,
  },

  data () {
    return {
      filtersCache: new MLocalStorage(APPOINTMENT_CALL_LOG_CACHE_KEY),
      filtrationTimeout: null,

      defaultSearchFilter,
      doctorSearchFilter: (filterValue) => defaultSearchFilter(filterValue, 'fullName'),
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      vxClinics: 'vxGetDoctorClinics',
    }),

    ...mapGetters('appointmentCallLogStore', {
      vxDoctors: 'vxGetDoctors',
      vxSpecialties: 'vxGetSpecialties',
      vxGetStatuses: 'vxGetStatuses',
      vxGetCallStatuses: 'vxGetCallStatuses',
    }),

    ...mapGetters('appointmentCallLogStore/filtersBase', {
      vxFilters: 'vxGetFilters',
    }),

    filterClinic: filtersBinding(FILTERS.CLINIC),
    filterDate: filtersBinding(FILTERS.DATE),
    filterSpecialty: filtersBinding(FILTERS.SPECIALTY),
    filterDoctor: filtersBinding(FILTERS.USER),
    filterStatus: filtersBinding(FILTERS.STATUS),
    filterCallStatus: filtersBinding(FILTERS.CALL_CONFIRMATION_STATUS),

    datePickerButtons () {
      return [DATE_PICKER_BUTTONS.TODAY, DATE_PICKER_BUTTONS.TOMORROW]
    },

    pickedStatuses () {
      return (this.filterStatus || [])
        .map(({ title }) => title)
        .join(', ')
    },

    pickedCallStatuses () {
      return (this.filterCallStatus || [])
        .map(({ title }) => title)
        .join(', ')
    },

    datePickerShortcuts () {
      return [
        CALENDAR_RANGE_NAME.TODAY,
        CALENDAR_RANGE_NAME.TOMORROW,
        CALENDAR_RANGE_NAME.SEVEN_DAYS,
        CALENDAR_RANGE_NAME.THIRTY_DAYS,
      ]
    },
  },

  watch: {
    vxFilters (to) { this.onFiltersChange(cloneDeep(to)) },
  },

  created () {
    this.initFilters()
    this.onFilterClinicChange(this.filterClinic)
  },

  methods: {
    ...mapMutations('appointmentCallLogStore/filtersBase', {
      vxSetFilterValue: 'vxSetFilterValue',
      vxResetFilters: 'vxResetFilters',
      vxRefreshFilters: 'vxRefreshFilters',
    }),

    ...mapActions('appointmentCallLogStore', {
      vxFetchCallLogDoctors: 'vxFetchCallLogDoctors',
      vxFetchCallLogSpecialties: 'vxFetchCallLogSpecialties',
    }),

    initFilters () {
      const cachedFilters = this.filtersCache.restore() || {}
      const filters = { ...getFilters(), ...cachedFilters }
      filters[FILTERS.CLINIC] = filters[FILTERS.CLINIC]
        ? filters[FILTERS.CLINIC]
        : this.vxClinics.find((clinic) => clinic.id === this.doctorSchedulesClinicId)

      this.vxResetFilters(filters)
    },

    resetFilters (dropCache = false) {
      if (dropCache) { this.filtersCache.drop() }
      this.initFilters()
    },

    onFiltersChange (newFilters = {}) {
      clearTimeout(this.filtrationTimeout)

      this.filtrationTimeout = setTimeout(() => {
        this.filtersCache.save(newFilters)
        this.$emit('filtersChanged', newFilters)
      }, this.filtrationDebounce)
    },

    /** @param {{ id: number }} clinic - объект клиники, но нужен только id */
    onFilterClinicChange (clinic) {
      const clinicOrClinics = clinic
        ? clinic?.id
        : (this.vxClinics || []).map(({ id }) => id)

      Promise.allSettled([
        this.vxFetchCallLogDoctors(clinicOrClinics),
        this.vxFetchCallLogSpecialties(clinicOrClinics),
      ])
    },
  },
}
</script>
