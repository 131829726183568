<template>
  <div class="guarantee-letter-search-create flex gap-indent-small w-100">
    <m-select-lazy
      ref="guaranteeLetterSelect"
      :value="value"
      :fetch-method="(params) => presenter.list({ ...params, medicalPolicyId })"
      :label="t('finance.guarantee_letter')"
      :disabled="disabled"
      use-first-time-fetch
      use-first-time-fetch-on-open
      full-width
      @changeByLazy="$emit('setGuaranteeLetter', $event)"
    >
      <template #prefix>
        <slot name="prefix" />
      </template>
    </m-select-lazy>

    <guarantee-letters-editor
      v-if="medicalPolicyId"
      :medical-policy-id="medicalPolicyId"
      :is-dms="isDms"
      @onSubmit="$emit('setGuaranteeLetter', $event)"
    />
    <m-button
      v-else
      template="add"
      icon="letter"
      plus-icon
      disabled
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import GuaranteeLettersEditor
  from '@/vue_apps/ClientsModule/components/MedicalPolices/components/GuaranteeLetters/GuaranteeLettersEditor.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import {
  MGuaranteeLetterPresenter,
} from '@/_api/MClient/MMedicalPoliceApi/MGuaranteeLetterApi/MGuaranteeLetterPresenter'
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from '@/vue_components/store/modules/filters_base'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default defineComponent({
  name: 'GuaranteeLetterSearchCreate',
  components: {
    MButton,
    MSelectLazy,
    GuaranteeLettersEditor,
  },

  model: {
    event: 'setGuaranteeLetter',
    prop: 'value',
  },

  props: {
    value: { type: Object, default: null },
    disabled: { type: [Boolean, Object], default: false },
    medicalPolicyId: { type: Number, default: null },
    isDms: { type: Boolean, default: false },
  },

  emit: [
    'setGuaranteeLetter',
  ],

  data () {
    return {
      presenter: new MGuaranteeLetterPresenter(),
    }
  },

  watch: {
    medicalPolicyId (medicalPolicyId) {
      if (!medicalPolicyId) { return }

      const params = {
        offset: DEFAULT_OFFSET,
        limit: DEFAULT_LIMIT,
        medicalPolicyId,
      }

      this.$refs.guaranteeLetterSelect.fetch(params, true)
    },
  },
})
</script>
