<template>
  <div
    v-show="show"
    class="panel panel-primary"
  >
    <epic-spinner
      :visibility="activeRequestState"
    />
    <div
      class="panel-heading panel-heading-flex"
    >
      <div
        class="panel-heading-title"
      >
        <span
          class="panel-heading-icon fad fa-fw fa-user"
        />
        <h3 class="panel-title">
          {{ T.api_page.clients.creating_api_client }}
        </h3>
      </div>
      <div
        class="panel-heading-buttons"
      >
        <span
          class="panel_close_button fad fa-fw  fa-times hover-white-icon"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div
      class="panel-body"
    >
      <form
        class="simple_form flex-form client-new-form"
      >
        <div
          class="form-label"
        >
          <label
            class="string required"
            for="api_client_title"
          >
            <span>
              {{ T.activerecord.attributes.api_client.title }}
            </span>
            <abbr
              :title="T.field_required"
            >
              *
            </abbr>
          </label>
        </div>
        <div
          class="form-value"
        >
          <div
            :class="titleError ? 'has-error' : ''"
            class="form-group string required api_client_title"
          >
            <input
              id="api_client_title"
              v-model="title"
              class="form-control string required tooltip-bottom"
              type="text"
              autocomplete="off"
            >
            <div
              v-if="titleError"
              class="under_field_error"
            >
              {{ titleError }}
            </div>
          </div>
        </div>
        <div class="flex-form api-client-roles">
          <div class="form-label">
            {{ T.api_page.clients.api_client_roles }}
          </div>

          <el-select
            v-model="apiRoleIds"
            multiple
            size="small"
            :placeholder="T.api_page.roles.api_roles"
          >
            <el-option
              v-for="role in apiRoles"
              :key="role.id"
              :label="role.name"
              :value="role.id"
            />
          </el-select>
        </div>
      </form>
      <div
        class="form-btns"
      >
        <div>
          <button
            :disabled="!formValid"
            class="btn btn-primary btn-sm btn-with-icon"
            @click="createApiClient"
          >
            <span class="btn-with-icon_icon fad fa-save" />
            <span class="btn-with-icon_text">{{ t('save') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'

export default {
  name: 'ApiClientNew',

  components: { EpicSpinner },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    activeRequestState: false,
    title: '',
    titleError: null,
    apiRoles: [],
    apiRoleIds: [],
  }),

  computed: {
    formValid () {
      return this.title && this.title.trim().length > 0
    },
  },

  watch: {
    show () {
      if (this.show) this.resetForm()
    },
  },

  methods: {
    new (apiRoles) {
      this.apiRoles = apiRoles
    },

    createApiClient () {
      const god = this

      god.activeRequestState = true

      $.ajax({
        type: 'POST',
        url: Routes.api_clients_path(),
        data: {
          api_client: {
            title: god.title,
            api_role_ids: god.apiRoleIds,
          },
        },
        success (client) {
          god.resetForm()
          god.$emit('created', client)
        },
        error ({ status, responseJSON: errors }) {
          if (status !== 422) return

          if (Array.isArray(errors.title) && errors.title.length) {
            god.titleError = errors.title[0]
          }
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    resetForm () {
      this.title = ''
      this.titleError = null
    },
  },
}
</script>
