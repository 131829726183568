<template>
  <catalog-wrapper
    model="RefuseResult"
    table-container-class="col-item-5"
    form-container-class="col-item-5"
    catalog-icon="fad fa-fw fa-phone-slash"
    :table-title="t('telephony.refuse_results')"
    :form-create-title="t('telephony.creating_refuse_result')"
    :form-edit-title="t('telephony.editing_refuse_result')"
    :add-item-text="t('telephony.add_refuse_result')"
    :items="refusesList"
    :loading="loadingList"
    :selected-mode="selectedMode"
    :is-show-form="isShowForm"
    :is-show-form-btns="!isSystemName"

    @ask-create="openCreateForm"
    @close="hideForm"
    @submit="handleSubmit"
    @ask-delete="handleDestroy"
  >
    <template #table>
      <refuse-results-table
        :refuses="refusesList"
        :selected-list-item="selectedListItem"
        @ask-edit="openEditForm"
      />
    </template>

    <template #form>
      <refuse-results-form
        v-bind.sync="formData"
        :validation-errors="validationMessages"
        :is-system-name="isSystemName"
      />
    </template>
  </catalog-wrapper>
</template>

<script>
import CatalogWrapper from '../catalog_wrapper/catalog_wrapper_deprecated.vue'
import RefuseResultsTable from './components/refuse_results_table.vue'
import RefuseResultsForm from './components/refuse_results_form.vue'
import { CatalogHolder } from '@/vue_components/mixins/catalogs/catalogHolder'
import { requiredValidator } from '@/lib/validators/validators'
import { creators } from './const'
import { refuseResultsEndpoint } from '@/api_entities/catalogs/refuse_results/refuse_results_endpoint'
import { trimValues } from '@/vue_components/helpers'
import { MODES } from '../const'
import {
  checkExistingTitle,
  checkIsEqual,
  successCreatedNotify,
  successDestroyedNotify,
  successUpdateNotify,
} from '@/vue_components/catalogs/helpers'

export default {
  name: 'RefuseResultsCatalog',
  components: {
    CatalogWrapper,
    RefuseResultsTable,
    RefuseResultsForm,
  },

  mixins: [CatalogHolder],

  data () {
    return {
      refusesList: [],
    }
  },

  computed: {
    isSystemName () {
      return !!this.formData.system_name
    },
  },

  watch: {
    'formData.title' (newValue) {
      this.validate('title', newValue, requiredValidator)
    },
  },

  created () {
    this.initCatalogHolder(creators)
    this.getRefusesList()
  },

  methods: {
    getRefusesList () {
      this.loadingList = true
      refuseResultsEndpoint.getAll({ offset: this.offset, limit: this.limit })
        .then((response) => { this.refusesList = response.data })
        .catch(Utils.reportError(
          'RefuseResultsCatalog:getRefusesList',
          t('reception.errors.get_list'))
        )
        .finally(() => { this.loadingList = false })
    },

    handleSubmit () {
      trimValues(this.formData)
      if (this.hasErrors()) { return }
      if (checkExistingTitle(this.refusesList, this.formData)) { return }

      this.selectedMode === MODES.NEW
        ? this.createRefuse()
        : this.updateRefuse()
    },

    handleDestroy () {
      refuseResultsEndpoint.destroy(this.formData.id)
        .then(() => {
          this.getRefusesList()
          this.hideForm()
          successDestroyedNotify()
        })
        .catch(Utils.reportError(
          'RefuseResultsCatalog:handleDestroy()',
          t(
            'reception.errors.request_error',
            { action: t('reception.actions.delete') }
          )
        ))
    },

    createRefuse () {
      refuseResultsEndpoint.create({ title: this.formData.title })
        .then(() => {
          this.getRefusesList()
          this.clearForm()
          successCreatedNotify()
        })
        .catch((err) => {
          err instanceof Error
            ? Utils.reportError(
              'RefuseResultsCatalog:createRefuse()',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.create') }
              )
            )(err)
            : this.setValidationMessages(this.validationMessages, err.responseJSON)
        })
    },

    updateRefuse () {
      if (checkIsEqual(this.previousFormData, this.formData)) { return }

      refuseResultsEndpoint.update(this.formData)
        .then(() => {
          this.getRefusesList()
          this.previousFormData = { ...this.formData }
          successUpdateNotify()
        })
        .catch((err) => {
          err instanceof Error
            ? Utils.reportError(
              'RefuseResultsCatalog:updateRefuse()',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.update') }
              )
            )(err)
            : this.setValidationMessages(this.validationMessages, err.responseJSON)
        })
    },
  },
}
</script>
