import FiscalPrinterModule from '@/modules/workplace/fiscal_printer_module.js'
import CatalogsModule from '@/modules/workplace/catalogs.js'

$document.on('rez/workplaces/new', () => {
  const form = FormFactory.build('workplace')
  form.newItem()
})

$document.on('rez/workplaces/edit', () => {
  const form = FormFactory.build('workplace')
  form.editItem()

  CatalogsModule()
})

$document.on('rez/workplaces/fiscal_printer', () => {
  FiscalPrinterModule()
})
