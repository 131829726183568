<template>
  <div
    class="schedule-grid__permission-overlay"
    @click="showStubMessage"
  />
</template>
<script>
export default {
  name: 'DoctorSchedulesGridStub',
  methods: {
    showStubMessage () {
      Notificator.warning(t('reception.warnings.schedule_access_denied'))
    },
  },
}
</script>
