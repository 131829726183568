<template>
  <modal
    v-if="visibility"
    :modal-visibility="visibility"
    modal-class="api-actions-modal"
    @close="$emit('close', $event)"
  >
    <span
      slot="header"
    >
      {{ headerMessage }}
    </span>
    <span
      slot="body"
    >
      <b>{{ permission }}</b> {{ message }}
    </span>
    <template
      slot="footer-right"
    >
      <button
        class="btn btn-sm btn-danger btn-with-icon modal-save"
        @click.prevent.stop="$emit(expectedEvent)"
      >
        <span
          class="btn-with-icon_icon fad fa-fw fa-exclamation-circle"
        />
        <span
          class="btn-with-icon_text"
        >
          {{ actionButtonMessage }}
        </span>
      </button>
      <button
        class="btn btn-sm btn-primary btn-with-icon modal-close"
        @click="$emit('close')"
      >
        <span
          class="btn-with-icon_icon fad fa-fw  fa-times"
        />
        <span
          class="btn-with-icon_text"
        >
          {{ T.api_page.roles.cancel }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal.vue'

export default {
  name: 'ApiActionsModal',

  components: { Modal },

  props: {
    visibility: Boolean,
    actionButtonDisabled: Boolean,
    expectedEvent: {type: String, default: null},
    headerMessage: {type: String, default: ''},
    message: {type: String, default: ''},
    permission: {type: String, default: ''},
    actionButtonMessage: {type: String, default: ''},
  },
}
</script>
