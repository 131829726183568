
export const DENTAL_ORDERS_STATUSES = Object.freeze({
  NEW: 'new_dental_order',
  IN_LABORATORY: 'in_laboratory',
  CLINIC: 'clinic',
  SENT_BACK: 'sent_back',
  DONE: 'done',
  CANCELED: 'canceled',
})

export const dentalOrderIcons = Object.freeze({
  [DENTAL_ORDERS_STATUSES.NEW]: { icon: 'square', css: 'primary' },
  [DENTAL_ORDERS_STATUSES.IN_LABORATORY]: { icon: 'tooth', css: 'gray' },
  [DENTAL_ORDERS_STATUSES.CLINIC]: { icon: 'home', css: 'warning' },
  [DENTAL_ORDERS_STATUSES.SENT_BACK]: { icon: 'undo', css: 'canceled' },
  [DENTAL_ORDERS_STATUSES.DONE]: { icon: 'checkSquare', css: 'success' },
  [DENTAL_ORDERS_STATUSES.CANCELED]: { icon: 'ban', css: 'danger' },
})

export const getDentalOrderIcon = (status) => {
  if (!dentalOrderIcons[status]) {
    return { icon: '', css: '' }
  }

  return dentalOrderIcons[status]
}
