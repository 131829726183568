<template>
  <si-field style="min-height: 50px;">
    <div class="referral">
      <user-avatar
        class="mr-5"
        :user-id="rowReferral.userId"
        :params="{ version: 'thumb40', tag: rowReferral.avatarTag }"
      />
      <span v-tooltip="fullName">{{ fullName }}</span>
    </div>
    <div class="goods">
      {{ $filters.price(rowReferral.goodsReward) }}
    </div>
    <div class="analysis">
      {{ $filters.price(rowReferral.analysisReward) }}
    </div>
    <div class="services">
      {{ $filters.price(rowReferral.servicesReward) }}
    </div>
    <div class="total">
      {{ $filters.price(rowReferral.totalReward) }}
    </div>
  </si-field>
</template>

<script>
import { ReferralRow } from '@/vue_components/reports/referrals/row/referralRow'
import UserAvatar from '@/vue_components/user_avatar.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'

export default {
  name: 'ReferralsRowConsolidated',
  components: { SiField, UserAvatar },
  mixins: [ReferralRow],

  computed: {
    rowReferral () {
      return this.row?.referral || {}
    },

    fullName () {
      return this.$filters.fullName(this.rowReferral)
    },
  },
}
</script>
