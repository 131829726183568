import {
  CONTROL_COMPONENT_STORAGE_NAME,
  SELECT_ALL_SPECIALTIES_ID,
  SELECT_ALL_CABINETS_ID,
  SELECT_ALL_USERS_ID,
} from '../const.js'

export default {
  _getStore () {
    return window.store.get(CONTROL_COMPONENT_STORAGE_NAME)
  },

  _validateStore () {
    const validState = {}
    const currentStore = this._getStore() || {}
    const ownStore = currentStore[this.currentClinic.id] || {}

    if (ownStore.selectedSpecialtiesIds) {
      validState.selectedSpecialtiesIds = ownStore.selectedSpecialtiesIds
        .filter((id) => id === SELECT_ALL_SPECIALTIES_ID || this.allSIDs.includes(id))

      if (validState.selectedSpecialtiesIds.length === 0) {
        validState.selectedSpecialtiesIds = [SELECT_ALL_SPECIALTIES_ID]
      }
    } else {
      validState.selectedSpecialtiesIds = [SELECT_ALL_SPECIALTIES_ID]
    }

    if (ownStore.selectedUsersIds) {
      validState.selectedUsersIds = ownStore.selectedUsersIds
        .filter((id) => id === SELECT_ALL_USERS_ID || this.allUIDs.includes(id))

      if (validState.selectedUsersIds.length === 0) {
        validState.selectedUsersIds = [SELECT_ALL_USERS_ID]
      }
    } else {
      validState.selectedUsersIds = [SELECT_ALL_USERS_ID]
    }

    if (ownStore.selectedCabinetsIds) {
      validState.selectedCabinetsIds = ownStore.selectedCabinetsIds
        .filter((id) => id === SELECT_ALL_CABINETS_ID || this.allCIDs.includes(id))

      if (validState.selectedCabinetsIds.length === 0) {
        validState.selectedCabinetsIds = [SELECT_ALL_CABINETS_ID]
      }
    } else {
      validState.selectedCabinetsIds = [SELECT_ALL_CABINETS_ID]
    }

    validState.showSpecialtiesList =
      ownStore.showSpecialtiesList === undefined ? true : ownStore.showSpecialtiesList

    validState.showUsersList =
      ownStore.showUsersList === undefined ? true : ownStore.showUsersList

    validState.showCabinetsList =
      ownStore.showCabinetsList === undefined ? true : ownStore.showCabinetsList

    currentStore[this.currentClinic.id] = validState

    window.store.set(CONTROL_COMPONENT_STORAGE_NAME, currentStore)
  },

  _updateStore () {
    const state = {
      selectedSpecialtiesIds: this.selectedSpecialtiesIds,
      selectedUsersIds: this.selectedUsersIds,
      selectedCabinetsIds: this.selectedCabinetsIds,

      showSpecialtiesList: this.showSpecialtiesList,
      showUsersList: this.showUsersList,
      showCabinetsList: this.showCabinetsList,
    }

    const currentStore = this._getStore() || {}
    currentStore[this.currentClinic.id] = state

    window.store.set(CONTROL_COMPONENT_STORAGE_NAME, currentStore)
  },
}
