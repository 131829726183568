import appointmentNotify from './appointment_notify.js'
import appointmentRemind from './appointment_remind.js'
import clientsBirthday from './clients_birthday.js'
import servicesStatus from './services_status.js'
import general from './general.js'
import onlineRecord from './online_record.js'
import clientsFeedback from './clients_feedback.js'
import appointmentConfirmation from './appointment_confirmation.js'

$document.on('rez/sms/configurations/edit', () => {
  const messageTypeSettingsName = gon.specific.message_type_settings
  switch (messageTypeSettingsName) {
    case 'general':
      general()
      break
    case 'appointment_notify':
      appointmentNotify()
      break
    case 'appointment_remind':
      appointmentRemind()
      break
    case 'clients_birthday':
      clientsBirthday()
      break
    case 'services_status':
      servicesStatus()
      break
    case 'online_record':
      onlineRecord()
      break
    case 'clients_feedback':
      clientsFeedback()
      break
    case 'appointment_confirmation':
      appointmentConfirmation()
  }
})
