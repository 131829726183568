<template>
  <wrapper
    :epic-spinner-visibility="showEpicSpinner"
    :logo-is="customAvatar"
    :avatar-source="avatarSource"
    @cropped-avatar-base64="uploadAvatar"
    @remove-avatar="removeAvatar"
  >
    <input
      type="hidden"
      :value="logo"
      name="user[avatar]"
    >
  </wrapper>
</template>

<script>
import Wrapper from './wrapper.vue'

const REQUEST_SIMULATION_TIME = 400

export default {
  name: 'AvatarNew',
  components: {
    Wrapper,
  },
  props: {
    defaultAvatarsPaths: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showEpicSpinner: false,
    customAvatar: false,
    selectedUserAvatarSource: '',
    logo: '',
    userSex: '',
    maxFileSize: gon.application.files.max_size,
  }),
  computed: {
    avatarSource () {
      if (this.customAvatar) return this.selectedUserAvatarSource
      if (this.userSex === true) return this.defaultAvatarsPaths.man
      if (this.userSex === false) return this.defaultAvatarsPaths.woman

      return this.defaultAvatarsPaths.unisex
    },
    maxFileSizeToMb () {
      return Math.floor(this.maxFileSize / (1024 * 1024))
    },
    maxFileSizeMessage () {
      return `${T.max_file_size} ${this.maxFileSizeToMb}  ${T.mbyte}`
    },
  },
  mounted () {
    this.$pubSub.subscribe('USER_FORM.SEX', (userSex) => (this.userSex = userSex))
  },
  methods: {
    uploadAvatar (dataURL) {
      const base64 = dataURL.split(',')[1]
      const fileSize = atob(base64).length

      if (fileSize > this.maxFileSize) {
        Notificator.warning(Utils.maxFileSizeMessage())

        return
      }

      this.requestSimulation(() => {
        this.customAvatar = true
        this.selectedUserAvatarSource = dataURL
        this.logo = base64

        Notificator.success(T.avatar.success_upload)
      })
    },
    removeAvatar () {
      this.requestSimulation(() => {
        this.customAvatar = false
        this.logo = ''
        Notificator.success(T.avatar.avatar_removed)
      })
    },
    requestSimulation (callback) {
      this.showEpicSpinner = true

      setTimeout(() => {
        this.showEpicSpinner = false
        callback()
      }, REQUEST_SIMULATION_TIME)
    },
  },
}
</script>
