<template>
  <search-select
    class="full-width"
    :search-query="searchQuery"
    :not-results="serviceNotResults"
    :allow-search="serviceSearchAllowSearch"
    :loading="serviceSearchLoading"
    :disabled="disabled"
    value=""
    :popper-class="'appointment__attach-to-right'"
    @update:searchQuery="$emit('searchQueryInput', $event)"
    @change="onSelect($event)"
  >
    <el-option
      v-for="(item, index) in searchResultArray"
      :key="index"
      class="service-item"
      :label="item.title"
      :value="item"
    >
      <div class="service-item__number">
        <p class="cut">
          {{ item.number }}
        </p>
      </div>

      <div class="service-item__block">
        <div class="service-item__info">
          <div
            v-if="item.analysis_laboratory"
            class="service-item__lab"
          >
            {{ item.analysis_laboratory.title }}
          </div>
          <div class="service-item__title">
            {{ item.title }}
          </div>
        </div>
        <div class="service-item__price">
          {{ item.price | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
        </div>
      </div>
    </el-option>
  </search-select>
</template>

<script>
import SearchSelect from '@/vue_components/common/select/search_select/search_select.vue'
import { currencyFormatter } from '@/vue_components/mixins/formatters.js'

export default {
  name: 'AppointmentServicesSearch',
  components: {
    SearchSelect,
  },

  mixins: [currencyFormatter],

  props: {
    searchResultArray: {
      type: Array,
      default: () => [],
    },

    searchQuery: {
      type: String,
      default: '',
    },

    serviceSearchAllowSearch: Boolean,
    serviceSearchLoading: Boolean,
    serviceNotResults: Boolean,
    disabled: Boolean,
  },

  computed: {
    GET_LOCALIZATION_CURRENCY_PARAMS () {
      return this.$store.getters.GET_LOCALIZATION_CURRENCY_PARAMS
    },
  },

  methods: {
    onSelect (item) {
      this.$emit('addService', item)
      this.$emit('searchQuery', '')
    },
  },
}
</script>
