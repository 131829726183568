import { createVueApp } from '@/vue_components/create_vue_app'
import FiltersProfilesAppForLegacy from './FiltersProfilesAppForLegacy.vue'

let vueApp = null

export const createFiltersProfilesAppForLegacy = (options, el = '#vue_filters_profiles_app') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    name: 'FiltersProfilesAppForLegacy',
    render: (h) => h(FiltersProfilesAppForLegacy, {
      props: {
        getFilters: options.filtersGetter,
        setFilters: options.filtersSetter,
        profileType: options.profileType,
      },
    }),
  })
}
