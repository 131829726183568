import { request } from '@/lib/transport/request'

/**
 * @param id
 * @param amount
 * @return {Promise<*>}
 */
export const fetchEntryType = (id, amount = 1) =>
  request({
    url: Routes.entry_type_path(id),
  }).promise
    .then((entryType) => ({ ...entryType, amount }))
    .catch(() => Promise.resolve({ errors: true }))
