import { IApiError } from 'src/_declarations/IApiErrors'
import { FILTERS_PROFILES_OPTIONS_ERRORS_TEXTS as ERRORS_TEXTS } from '../const/consts'
import { TFiltersProfileWithError } from '../interfaces/filtersTypes/shared/toClient/_base'

type WithError = TFiltersProfileWithError & ({ title: string } | { shortName: string } | { fullName: string })

type MaybeWithError = any | WithError[]

const formErrorTextWithTitle = ({ title, shortName, fullName, error }) => {
  const errorTextWithoutTitle = ERRORS_TEXTS[error]

  if (title) {
    return `${title} - ${errorTextWithoutTitle}`
  }

  if (shortName) {
    return `${shortName} - ${errorTextWithoutTitle}`
  }

  return `${fullName} - ${errorTextWithoutTitle}`
}

const formErrorArrayForMultiselect = (errorsFilters: WithError[]): string[] =>
  errorsFilters.map(formErrorTextWithTitle)

const formErrorArray = ([errorsFilter]: [WithError]): [string] => [ERRORS_TEXTS[errorsFilter.error]]

const formErrorEntry = ([filterName, filterValue]: [string, MaybeWithError]): [string, string[]] => {
  const noErrorsReturn = [filterName, []] as [string, string[]]

  if (!Array.isArray(filterValue)) { return noErrorsReturn }

  const filterValuesWithError: WithError[] = filterValue.filter(({ error }) => error)

  if (!filterValuesWithError.length) { return noErrorsReturn }

  return [
    filterName,
    filterValue.length > 1
      ? formErrorArrayForMultiselect(filterValuesWithError)
      : formErrorArray(filterValuesWithError as [WithError]),
  ]
}

export const gatherErrors = (filters: Record<string, MaybeWithError>): IApiError => {
  const errorsEntries = Object
    .entries(filters)
    .map(formErrorEntry)

  return Object.fromEntries(errorsEntries)
}
