import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import {
  $tpLocale,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/helpers'

export class TreatmentEntryFirstRow extends LazyTreeNode {
  isFirstRow = true

  indeterminate = false

  /**
   * @param tree
   * @param {{ id: string, parentId: number }} rawNode
   * @param isLeaf
   */
  constructor (tree, rawNode, isLeaf = false) {
    super(tree, rawNode, isLeaf)

    this.id = rawNode.id

    this.amount = ''

    this.title = $tpLocale('title')

    this.price = $tpLocale('price')

    this.availableAmount = $tpLocale('amount')

    this.discount = $tpLocale('discount')

    this.comment = $tpLocale('comment')

    this.duplicated = ''

    this.isLeaf = true
  }

  select (selected = false) {
    this.selected = selected
    this.parent.children.forEach((child, index) => {
      if (!index) { return }
      child.select(selected)
    })
  }

  setCheckboxState () {
    this.indeterminate = true

    const selectable = this.parent.children.filter(
      /**
       * @param {TreatmentEntryFirstRow | TreatmentEntry}child
       * @return {boolean}
       */
      (child) => child.selectable && !child.isFirstRow
    )

    const selected = this.parent.children.filter(
      /**
       * @param {TreatmentEntryFirstRow | TreatmentEntry}child
       * @return {boolean}
       */
      (child) => child.selected && !child.isFirstRow
    )

    if (!selected.length) {
      this.selected = false
      this.indeterminate = false

      return
    }

    if (selected.length === selectable.length) {
      this.indeterminate = false
      this.selected = true
    }
  }
}
