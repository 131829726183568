<template>
  <div>
    <button
      v-if="showExtendButton"
      class="btn btn-sm btn-success btn-with-icon modal-save"
      @click="$emit('extend')"
    >
      <span class="btn-with-icon_icon fad fa-chevron-double-right" />
      <span class="btn-with-icon_text">{{ T.extend }}</span>
    </button>
    <button
      class="btn btn-sm btn-primary btn-with-icon modal-close"
      @click="$emit('close')"
    >
      <span class="btn-with-icon_icon fad fa-times" />
      <span class="btn-with-icon_text">{{ T.close }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ExtendScheduleTemplateModalFooter',
  props: {
    showExtendButton: Boolean,
  },
}
</script>
