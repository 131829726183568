$document.on('rez/roles/edit rez/roles/new', function () {
  var form = FormFactory.build('role')

  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      break
    case 'edit':
      form.editItem()
      break
  }
})

$document.on('rez/roles/edit', function () {
  const singlePermissionForceList = [
    t('permissions.permission_manage'),
    t('permissions.permission_no'),
    t('permissions.permission_view'),
  ]

  const $permissions_table = $('#permissions_table')

  function updateSelected () {
    var selectedElems = []
    $('select.permissions').filter(function () {
      return $(this).prop('multiple')
    }).each(function () {
      selectedElems.push({
        elem: this,
        selected: $(this).val(),
      })
    })

    return selectedElems
  }

  function checkSpecialPermission (checkedValue) {
    return singlePermissionForceList.indexOf(checkedValue.text()) !== -1
  }

  $permissions_table.tablesorter({ sortList: [[0, 0]], headers: { 1: { sorter: false } } })

  var multiSelectorCache = updateSelected()
  $('select.permissions').on('change', function (e) {
    let select = $(this)
    let selectSubjectClass = select.attr('data-subject_class')
    let panelBody = $permissions_table.find('.panel-body')

    panelBody.loadSpin('start')

    if (select.prop('multiple')) {
      var selectedElems = e.currentTarget.selectedOptions
      multiSelectorCache.forEach(function (savedElement) {
        if ($(savedElement.elem).data('subject_class') === selectSubjectClass) {
          if (savedElement.selected) {
            var lastSelected = $(selectedElems).filter(function () {
              return savedElement.selected.indexOf($(this).val()) === -1
            })
            if (checkSpecialPermission(lastSelected)) {
              $(selectedElems).not(lastSelected).attr('selected', false)
            } else if (lastSelected.prevObject.length === 0) {
              let lastIndex = savedElement.selected.toString()
              $(event.currentTarget.previousSibling).val(lastIndex)
            } else {
              $(selectedElems).filter(function () {
                return checkSpecialPermission($(this))
              }).attr('selected', false)
            }
          }
        }
      })
    }

    let selectedPermissionIds = select.val()

    $.ajax({
      method: 'get',
      url: '/roles/change_permission',
      data: {id: pageInfo.id, permission_id: selectedPermissionIds, subject_class: selectSubjectClass},
      error (data) {
        Notificator.error(t('error'))
        console.error(data)
      },
      complete () {
        panelBody.loadSpin('stop')
        multiSelectorCache = updateSelected()
      },
    })
  })
})
