<template>
  <si-field>
    <div class="title wrap">
      {{ row.entry.title }}
    </div>
    <div class="amount">
      {{ row.entry.amount }}
    </div>
    <div class="sum wrap">
      {{ $filters.price(row.entry.finalSum) }}
    </div>
    <div class="reward-sum">
      {{ $filters.price(row.entry.rewardFinalSum) }}
    </div>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { ReferralRow } from '@/vue_components/reports/referrals/row/referralRow'

export default {
  name: 'ReferralsRowGroupedReferral',
  components: {
    SiField,
  },
  mixins: [ReferralRow],
}
</script>
