<template>
  <div
    v-loading="isLoading"
    class="m-image-picker"
    :class="{ 'm-image-picker--rounded': rounded }"
  >
    <img
      class="m-image-picker__image"
      :src="imgSource"
    >

    <div class="m-image-picker__controls">
      <m-button-upload
        template="shortUpload"
        button-style="circle"
        :accept="accept"
        @files="onFiles"
      />

      <m-button-delete
        v-show="isCanDelete"
        template="short_delete"
        button-style="circle"
        use-button
        :use-text="false"
        :popover-message="popoverMessage"
        @yes="$emit('delete')"
      />
    </div>
  </div>
</template>

<script>
import MButtonUpload from '@/vue_present/_base/buttons/MButtonUpload/MButtonUpload.vue'
import { MEDIA_TYPES } from '@/helpers/mediaTypes'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'

export default {
  name: 'MImagePicker',
  components: { MButtonDelete, MButtonUpload },

  props: {
    isLoading: Boolean,

    imgSource: { type: String, required: true },

    rounded: Boolean,

    isCanDelete: Boolean,

    popoverMessage: { type: String, default: t('imageCropper.delete') },
  },

  emits: ['file', 'delete'],

  data () {
    return {
      imgURL: null,
      accept: [
        MEDIA_TYPES.JPEG,
        MEDIA_TYPES.PNG,
        MEDIA_TYPES.GIF,
      ],
    }
  },

  beforeUnmount () {
    URL.revokeObjectURL(this.imgURL)
  },

  methods: {
    onFiles (files) {
      URL.revokeObjectURL(this.imgURL)
      this.imgURL = URL.createObjectURL(files[0])

      this.$emit('file', this.imgURL)
    },
  },
}
</script>
