import SmsEditor from '@/modules/variables_editor.js'
import { CLIENTS_FEEDBACK } from '@/modules/sms/editor_presets.js'
import { smsCount } from '@/modules/sms/utils.js'

export default function clientsFeedback () {
  if (!gon.application.sms_module_enabled) return

  const $form = $('#sms_configuration_form')
  const $submit = $form.find('#sms_settings_submit').eq(0)
  const $clientsFeedbackEditor = $form.find('.clients_feedback_template_editor_container')
  const $clientsFeedbackCb = $form.find('#sms_configuration_clients_feedback')
  const $clientsFeedbackFields = $form.find('.hideable_field')
  const $clientsFeedbackApproximate = $form.find('.approximate_value')
  const $symbolCount = $form.find('.symbol_count')

  const clientsFeedbackEditor = new SmsEditor({
    container: $clientsFeedbackEditor,
    onPreviewChanged (text) {
      const count = smsCount(text)
      $clientsFeedbackApproximate.text(count)
      $symbolCount.text(text.length)
    },
    validationCallback (value) {
      $submit.prop('disabled', !value)
    },
    ...CLIENTS_FEEDBACK,
  })

  clientsFeedbackEditor.visualize()

  $form.remote_form({
    model: 'sms_configuration',
    beforeSend () {
      clientsFeedbackEditor.originalize()
    },
    onSuccess (res) {
      clientsFeedbackEditor.visualize()
      Notificator.info(I18n.t('sms.configuration_updated'))
      $submit.loadSpin('stop')
    },
    onError (err) {
      Notificator.error(T.check_correctness_filing_form)
      console.log(err)
      clientsFeedbackEditor.visualize()
      $submit.loadSpin('stop')
    },
  })

  const toggleClientsFeedbackFields = () => {
    $clientsFeedbackFields.toggleClass('hidden', !$clientsFeedbackCb.prop('checked'))
  }

  toggleClientsFeedbackFields()
  $clientsFeedbackCb.on('change', toggleClientsFeedbackFields)
  $submit.on('click', (e) => {
    if (!clientsFeedbackEditor.validate()) e.preventDefault()
  })
}
