<template>
  <div
    class="button-select-wrapper dropdown"
    :class="`dropdown-${theme}`"
  >
    <el-button
      :id="buttonId"
      type="button"
      class="btn dropdown-toggle"
      :class="getClasses"
      data-toggle="dropdown"
      aria-expanded="false"
      :loading="loading"
      :icon="icon"
      :disabled="disabled"
    >
      <slot name="button-content">
        <span>{{ buttonLabel }}</span>
      </slot>
    </el-button>

    <ul
      :aria-labelledby="buttonId"
      class="dropdown-menu dropdown-menu-right"
      :class="`${theme}-theme`"
      role="menu"
    >
      <template v-if="loading">
        <li
          v-for="index in [0, 1, 2, 3]"
          :key="index"
          :class="itemClass"
        >
          <skeleton
            width="140px"
            height="14px"
          />
        </li>
      </template>
      <template v-else>
        <li
          v-for="(item, index) in items"
          :key="index"
          class="ellipsis"
          :class="itemClass"
          @click="$emit('change', item)"
        >
          {{ item[itemLabelName] }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import Skeleton from '@/vue_components/skeleton'

export default {
  name: 'ButtonSelect',

  components: { Skeleton },

  props: {
    buttonId: {
      type: String,
      required: true,
    },

    buttonLabel: {
      type: String,
      default: '',
    },

    items: {
      type: Array,
      default: () => [],
    },

    itemLabelName: {
      type: String,
      default: 'title',
    },

    loading: {
      type: Boolean,
      default: true,
    },

    theme: {
      type: String,
      default: 'grey-dark',
    },

    buttonSize: {
      type: String,
      default: 'btn-xs',
    },

    icon: {
      type: String,
      default: '',
    },

    itemClass: {
      type: String,
      default: 'dropdown__menu-item',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getClasses () {
      return [
        { 'vue-skeleton-rect': this.loading },
        this.buttonSize,
        `btn-${this.theme}`,
        this.theme,
      ]
    },
  },
}
</script>
