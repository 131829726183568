<template>
  <span
    class="dent-tooth-container"
    :class="{ selected }"
    @click="$emit('click')"
  >
    <span class="dent-tooth" />
    <span class="dent-num">{{ tooth }}</span>
  </span>
</template>

<script>
export default {
  name: 'Tooth',
  props: {
    tooth: {
      type: Number,
      required: true,
    },

    selected: Boolean,
  },
}
</script>
