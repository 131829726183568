export const findPayers = (searchString) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.payer_search_orders_path(),
      data: { search_string: searchString },
      success: resolve,
      error: reject,
    })
  })
}
