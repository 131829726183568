import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getSalaryByPaymentsSchema = () =>
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addFooter()
        .addSorting()
        .addHeaders({
          tableDoctor: new MTableHeader(t('user')),
          tableGoods: new MTableHeader(t('goods')),
          tableAnalysis: new MTableHeader(t('analyzes')),
          tableServices: new MTableHeader(t('services')),
          tableTotal: new MTableHeader(t('reports.total')),
        })
    )
