import { ISalaryReportFilters as ReportFilters } from '@/vue_apps/ReportsModule/SalaryReports/interfaces/ISalaryReportFilters'
import { IFiltersProfilesModel } from '../../interfaces/IFiltersProfilesModel'
import {
  ISalaryReportFiltersProfileFiltersToClient as FiltersToClient,
  ISalaryReportFiltersProfileFiltersToServer as FiltersToServer,
} from '../../interfaces/filtersTypes/salaryReportAdapter'
import { omitBy, isUndefined } from 'lodash'
import { FiltersProfilesFormattersToClient as FormattersToClient } from '../../helpers/formatters/toClient'
import { FiltersProfilesFormattersToServer as FormattersToServer } from '../../helpers/formatters/toServer'
import { IFiltersProfilesFetchResponse } from '../../interfaces/IFIltersProfilesFetchResponse'
import { cleanUnpermittedValues } from '../../helpers/formatters/shared'
import { gatherErrors } from '../../helpers/gatherErrors'
import { TOnlyFullNameHuman } from '@/_declarations/TOnlyFullNameHuman'

export const salaryReportFiltersProfileAdapter = {
  toServer (data: IFiltersProfilesModel<ReportFilters>): IFiltersProfilesModel<FiltersToServer> {
    const { data: filters } = data

    const userFilterValue = Services.security.canSalaryReport
      ? filters.userFilter
      : filters.userOnlyCurrentFilter

    const adaptedFilters: FiltersToServer = {
      period: FormattersToServer.formatPeriod(filters.periodFilter),
      clinic: FormattersToServer.formatSingle(filters.clinicFilter),
      user: FormattersToServer.formatHuman(userFilterValue, false),
      reportSource: FormattersToServer.formatSingle(filters.reportSourceFilter),
      includingDiscounts: filters.includingDiscountsFilter,
      onlyEntriesWithOrder: filters.entryWithOrderFilter,
      reportType: FormattersToServer.formatSingle(filters.reportTypeFilter),
    }

    return { ...data, data: adaptedFilters }
  },

  toClient (data: IFiltersProfilesFetchResponse<FiltersToClient>): IFiltersProfilesModel<Partial<ReportFilters>> {
    const { data: filters } = data

    const validFilters = omitBy(cleanUnpermittedValues(filters), isUndefined) as FiltersToClient

    const userFieldName = Services.security.canSalaryReport
      ? 'userFilter'
      : 'userOnlyCurrentFilter'

    const adaptedFilters: ReportFilters = {
      periodFilter: FormattersToClient.formatPeriod(validFilters.period),
      clinicFilter: FormattersToClient.formatSingle(validFilters.clinic),
      [userFieldName]: FormattersToClient.formatHuman(validFilters.user) as TOnlyFullNameHuman,
      reportSourceFilter: FormattersToClient.formatSingle(validFilters.reportSource),
      includingDiscountsFilter: validFilters.includingDiscounts,
      entryWithOrderFilter: validFilters.onlyEntriesWithOrder,
      reportTypeFilter: FormattersToClient.formatSingle(validFilters.reportType),
    }

    const definedAdaptedFilters: Partial<ReportFilters> = omitBy(adaptedFilters, isUndefined)

    const renamedFilters = omitBy({
      periodFilter: filters.period,
      clinicFilter: filters.clinic,
      [userFieldName]: filters.user,
      reportSourceFilter: filters.reportSource,
      includingDiscountsFilter: filters.includingDiscounts,
      entryWithOrderFilter: filters.onlyEntriesWithOrder,
      reportTypeFilter: filters.reportType,
    }, isUndefined)

    return {
      ...data,
      data: definedAdaptedFilters,
      dataErrors: gatherErrors(renamedFilters),
    }
  },
}
