<template>
  <si-field>
    <!-- Столбец: клиника -->
    <div
      v-tooltip="appointment.clinicTitle"
      class="col col__clinicTitle"
    >
      {{ appointment.clinicTitle }}
    </div>

    <!-- Столбец: дата+время -->
    <div class="col col__dateTime">
      <span :class="appointment.dateCss">{{ appointment.formattedDate }}</span>
      {{ appointment.time }}
    </div>

    <!-- Столбец: докторФИО+специальности-->
    <appointment-call-log-doctor-with-specialties
      :doctor="appointment.doctor"
      :specialties="appointment.specialties"
    />

    <!-- Столбец: Ф\nИО клиента -->
    <appointment-call-log-client-data :client="appointment.client" />

    <!-- Столбец: телефон клиента -->
    <appointment-call-log-phone :client="appointment.client" />

    <!-- Столбец: статус аппоинтмента -->
    <appointment-call-log-appointment-status
      :status="appointment.status"
      :appointment-statuses="appointmentStatuses"
    />

    <!-- Столбец: статус звонка аппоинтмента -->
    <appointment-call-log-call-status
      :appointment-call-confirmation-statuses="appointmentCallConfirmationStatuses"
      :call-status="appointment.callConfirmationStatus"
      @click="$emit('openCallConfirmation', $event)"
    />

    <!-- Столбец: примечание -->
    <appointment-call-log-note
      :note="appointment.note"
      @click="$emit('openNote', $event)"
    />

    <!-- Столбец: список услуг -->
    <appointment-call-log-entry-types
      :entry-types="appointment.entryTypes"
      @click="$emit('openEntryTypes', $event)"
    />

    <!-- Столбец: найти в расписании -->
    <div class="col col__findAppointment">
      <m-icon
        v-tooltip="t('find_in_schedule')"
        class="pointer fa-flip-horizontal"
        icon="search"
        color="primary"
        @click="$emit('findAppointment', appointment)"
      />
    </div>

    <!-- Столбец: редактировать апоинтмент -->
    <div class="col col__editAppointment">
      <m-icon
        v-tooltip="t('edit')"
        class="pointer"
        icon="edit"
        color="warning"
        @click="$emit('editAppointment', appointment)"
      />
    </div>
  </si-field>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import AppointmentCallLogDoctorWithSpecialties
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogRow/AppointmentCallLogDoctorWithSpecialties.vue'
import AppointmentCallLogClientData
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogRow/AppointmentCallLogClientData.vue'
import AppointmentCallLogPhone
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogRow/AppointmentCallLogPhone.vue'
import AppointmentCallLogAppointmentStatus
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogRow/AppointmentCallLogAppointmentStatus.vue'
import AppointmentCallLogCallStatus
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogRow/AppointmentCallLogCallStatus.vue'
import AppointmentCallLogNote
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogRow/AppointmentCallLogNote.vue'
import AppointmentCallLogEntryTypes
  from '@/vue_apps/DoctorSchedules/AppointmentCallLog/components/AppointmentCallLog/AppointmentCallLogRow/AppointmentCallLogEntryTypes.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'AppointmentCallLogRow',
  components: { MIcon, AppointmentCallLogEntryTypes, AppointmentCallLogNote, AppointmentCallLogCallStatus, AppointmentCallLogAppointmentStatus, AppointmentCallLogPhone, AppointmentCallLogClientData, AppointmentCallLogDoctorWithSpecialties, SiField },
  props: {
    /** @type { callLogAppointment } */
    appointment: PropsTypes.Object(),

    /** @type { TCallLogStatus[] } */
    appointmentStatuses: PropsTypes.Array(),

    /** @type { TCallLogStatus[] } */
    appointmentCallConfirmationStatuses: PropsTypes.Array(),
  },
}
</script>
