import Vue from 'vue/dist/vue.esm'
import WidgetCategoriesPage from '@/vue_components/online_recording_categories/online_recording_categories.vue'

$document.on('rez/online_recording_categories/index', () => {
  const el = '#online_recording_categories_container'

  if (!document.querySelector(el)) { return }

  return new Vue({
    el,

    components: { WidgetCategoriesPage },

    template: `<widget-categories-page />`,
  })
})
