import { createVueApp } from '@/vue_components/create_vue_app'
import AnalysisLaboratories from '@/vue_apps/catalogs_root/analysis_laboratories/AnalysisLaboratories.vue'
import { createStore } from '@/vue_components/store/store'

export const createAnalysesLaboratoriesVue = (el) => {
  createVueApp({
    el,
    name: 'AnalysisLaboratoriesRoot',
    store: createStore(),
    render: (h) => h(AnalysisLaboratories),
  })
}
