import { MReportListService } from '@/_api/_requests/MReportListService'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import {
  MTreatmentPlanReportPresenter,
} from '@/vue_apps/ReportsModule/TreatmentPlansReports/api/MTreatmentPlanReportPresenter'
import { getDefaultFilters } from '@/vue_apps/ReportsModule/TreatmentPlansReports/store/getDefaultFilters'
import {
  treatmentPlansReportAdapter,
} from '@/vue_apps/ReportsModule/TreatmentPlansReports/api/treatmentPlansReportAdapter'

export class TreatmentPlansReport extends MReportListService {
  constructor (sortingMap = {
    numberCell: 'id',
    statusCell: 'status',
    dateCell: 'date',
    clientCell: 'clientSurname',
    titleCell: 'title',
    doctorCell: 'doctorSurname',
    responsibleCell: 'responsibleSurname',
    treatmentEntriesAmountCell: 'treatmentEntriesAmount',
    finalSumCell: 'finalSum',
    treatmentEntriesCreatedAmountCell: 'treatmentEntriesCreatedAmount',
    treatmentEntriesCreatedTotalSumCell: 'treatmentEntriesCreatedTotalSum',
    paidSumCell: 'paidSum',
    unpaidSumCell: 'unpaidSum',
    balanceCell: 'clientFinance',
    totalCell: 'total',
  }) {
    super(
      getDefaultFilters(),
      new MTreatmentPlanReportPresenter(),
      { sortingMap, limit: DEFAULT_LIMIT }
    )
  }

  async export () {
    return new MTreatmentPlanReportPresenter()
      .export(treatmentPlansReportAdapter.toExport(this.filtersData))
  }
}
