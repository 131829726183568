<template>
  <el-select
    v-model="clinicSelector"
    size="small"
    class="global-clinic-selector"
    :placeholder="t('select_clinic')"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      options: gon.specific.head_desktop.clinics,
    }
  },

  computed: {
    clinicSelector: {
      get () {
        return this.$store.state.headDesktopStore.globalClinicSelectorValue
      },
      set (value) {
        this.selectClinic(value)
      },
    },
  },

  methods: {
    ...mapActions('headDesktopStore', {
      selectClinic: 'selectClinic',
    }),
  },
}
</script>
