export const EVENTS = Object.freeze({
  ANALYSIS_ORDER: 'analysis_order',
  FETCH_DATA: 'fetch_data',
  FETCH_LABORATORIES: 'fetch_laboratories',
  FETCH_NOTIFICATION_DEFAULTS: 'fetch_notification_defaults',
  SEARCH_ENTRY_TYPES: 'search_entry_types',
  SEARCH_ENTRY_TYPES_BY_ID: 'search_entry_types_by_id',
  BUILD_TREE: 'build_tree',
  FETCH_ANALYSIS_ORDER: 'fetch_analysis_order',
  PREANALYTICS_REQUEST: 'preanalytics_request',
  HEALTH_CHECK_LABELLER: 'health_check_labeller',
  FETCH_CLIENT: 'fetch_client',
  PRINT_BARCODE: 'print_barcode',
  PRINT_BARCODE_LABELLER: 'print_barcode_labeller',
  REQUEST_QUESTIONS: 'request_questions',
  REQUEST_ANALYSIS_ORDER_STEPS: 'request_analysis_order_steps',
  SAVE_QUESTIONNAIRE: 'save_questionnaire',
  SEND_ANALYSIS_ORDER: 'send_analysis_order',
  SEND_ANALYSIS_ORDERS: 'send_analysis_orders',
  SAVE_ANALYSIS_ORDER: 'save_analysis_order',
  CANCEL_ANALYSIS_ORDER: 'cancel_analysis_order',
  CHECK_RESULT: 'check_result',
  REMOVE_ANALYSIS_ORDER: 'remove_analysis_order',
  SAVE_SUCCESSFUL: 'save_successful',
  SAVE_ERROR: 'save_error',
  HANDSHAKE: 'handshake',
  WEBSOCKETS_CONNECTED: 'websockets_connected',
  FETCH_ANALYSIS_ORDERS: 'fetch_analysis_orders',
  ORDER_REMOVED: 'order_removed',
  ERROR: 'error',
  SHOW_ERRORS: 'show_errors',
  FORCE_UPDATE_PRICE: 'force_update_price',
  UPDATE_BIOMATERIAL_CONTAINER: 'update_biomaterial_container',
  GET_CONNECTED_WORKPLACES: 'get_connected_workplaces',
  GET_PRICE_INFO: 'get_price_info',
  VALIDATE_BARCODE: 'validate_barcode',
  FETCH_COVID_CODES: 'fetch_covid_codes',
  CALCULATION_CONTAINERS: 'calculation_containers',
  DELETE_CONTAINERS: 'delete_containers',
  FORCE_CHECK_RESULTS: 'force_check_results',
})

export const STEPS = Object.freeze({
  QUESTIONNAIRE: 'questionnaire',
  CALCULATION_CONTAINERS: 'calculation_containers',
  SEND_TO_LAB: 'send_to_lab',
  PREANALYTICS: 'preanalytics',
  HEALTH_CHECK_LABELLER: 'health_check_labeller',
  PRINT_BARCODES: 'print_barcodes',
  PRINT_COVER_LETTER: 'print_cover_letter',
  ASSIGN_ENTRIES_TO_CONTAINERS: 'assign_entries_to_containers',
})

export const CONTROL_PANEL_ICONS = Object.freeze({
  CHECKED: 'fad fa-check-square',
  UNCHEKED: 'fad fa-square fa-swap-opacity',
})

export const ANALYSIS_ORDER_STATES = Object.freeze({
  PREPARED: 1,
  SENT: 2,
  ACCEPTED: 3,
  REFUSED: 4,
  READY: 5,
  ALMOST_READY: 6,
})

export const TREE_MODES = Object.freeze({
  PRICE: 'price',
  ORDER: 'order',
})

export const ANALYSIS_ORDER_HUMAN_STATES = Object.freeze({
  [ANALYSIS_ORDER_STATES.PREPARED]: 'prepared',
  [ANALYSIS_ORDER_STATES.SENT]: 'sent',
  [ANALYSIS_ORDER_STATES.ACCEPTED]: 'accepted',
  [ANALYSIS_ORDER_STATES.REFUSED]: 'refused',
  [ANALYSIS_ORDER_STATES.READY]: 'ready',
  [ANALYSIS_ORDER_STATES.ALMOST_READY]: 'almostReady',
})

export const ANALYSIS_ORDER_STATE_ICONS = Object.freeze({
  [ANALYSIS_ORDER_STATES.PREPARED]: 'fad fa-square fa-swap-opacity',
  [ANALYSIS_ORDER_STATES.SENT]: 'fad fa-box-alt',
  [ANALYSIS_ORDER_STATES.ACCEPTED]: 'fad fa-flask',
  [ANALYSIS_ORDER_STATES.REFUSED]: 'fad fa-undo',
  [ANALYSIS_ORDER_STATES.READY]: 'fad fa-check-square',
  [ANALYSIS_ORDER_STATES.ALMOST_READY]: 'fad fa-tasks',
})

export const LABORATORY_SYSTEM_NAMES = Object.freeze({
  HELIX: 'helix',
  INVITRO: 'invitro',
  KDL: 'kdl',
  KDL_CHECKUPS: 'kdl_checkups',
})

export const DEFAULT_INDEX_FILTERS = Object.freeze({
  date: null,
  state: null,
  laboratoryId: null,
})

export const ANALYSIS_ORDERS_PER_PAGE = 50

export const ANALYSIS_CHANNEL = 'analysis'

export const ANALYSIS_ORDER_PUBSUB_PREFIX = 'analysis_order'

export const SEND_ORDER_MAX_WAIT_TIME_MS = 5000
export const SEND_ORDER_MAX_WAIT_TIME_MS_X2 = 10000

export const CONTAINER_CODES = Object.freeze({
  RED_CODE: 'ВПКРАСН',
  BLUE_CODE: 'ВПГОЛУБ',
  GREEN_CODE: 'ВПЗЕЛЕНАЯ',
  GREEN_GEL_CODE: 'ВПЗЕЛЕНГЕЛЬ',
  VIOLET_CODE: 'ВПФИОЛ',
  VIOLET_CODE_2: 'ВПФИОЛ2',
  GRAY_CODE: 'ВПСЕРАЯ',
  YELLOW_CODE: 'ВПЖЕЛТГЕЛЬ',
})

export const CONTAINER_ICONS_CLASSES = Object.freeze({
  RED_CODE: 'container-icon-red',
  BLUE_CODE: 'container-icon-blue',
  GREEN_CODE: 'container-icon-green',
  GREEN_GEL_CODE: 'container-icon-green-gel',
  VIOLET_CODE: 'container-icon-violet',
  VIOLET_CODE_2: 'container-icon-violet2',
  GRAY_CODE: 'container-icon-gray',
  YELLOW_CODE: 'container-icon-yellow-gel',
  LILAC_CODE: 'container-icon-lilac',
  WHITE_CODE: 'container-icon-white',
})

export const CONTAINER_ICONS_TEXTS = Object.freeze({
  RED_CODE: 'К',
  BLUE_CODE: 'Г',
  GREEN_CODE: 'З',
  GREEN_GEL_CODE: 'ЗГ',
  VIOLET_CODE: 'Ф',
  VIOLET_CODE_2: 'Ф2',
  GRAY_CODE: 'СЕ',
  YELLOW_CODE: 'Ж',
  LILAC_CODE: 'СИ',
  WHITE_CODE: 'Б',
})

export const QR = Object.freeze({
  TYPE_NUMBER: 4,
  ERROR_CORRECTION_LEVEL: 'H',
})
