<template>
  <m-modal
    :visible="modalVisibility"
    @update:visible="$updateSync('modalVisibility', $event)"
    @close="$updateSync('modalVisibility', $event)"
  >
    <template #header>
      <m-icon
        class="mr-5"
        icon="transfer"
      />
      <span>{{ t('transferServices') }}</span>
    </template>

    <template #body>
      <entry-types-transfer-body
        :list="entryTypesTransferList"
        @onSetSelectedItems="selectedItems = $event"
      />
    </template>

    <template #footer-left>
      <m-button
        template="transfer"
        :disabled="buttonsDisabled"
        @click="onCreate"
      />

      <m-popover
        :disabled="buttonsDisabled"
        :message="t('delete_confirmation_mass')"
        @yes="onDelete"
      >
        <template #reference>
          <m-button
            template="delete"
            :disabled="buttonsDisabled"
          />
        </template>
      </m-popover>

      <span
        v-if="hasUpdates"
        class="ml-5 pointer primary text-decoration-underline"
        @click="fetchList"
      >
        {{ t('hasUpdates') }}
      </span>
    </template>
  </m-modal>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MEntryTypesTransferPresenter } from '@/_api/EntryTypes/MEntryTypesTransfer/MEntryTypesTransferPresenter'
import EntryTypesTransferBody
  from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/EntryTypesTransfer/components/EntryTypesTransferBody.vue'
import {
  EntryTypesTransferList,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/EntryTypesTransfer/store/EntryTypesTransferList'
import { orUndefined } from '@/_medods_standart_library/msl'

export default {
  name: 'EntryTypesTransferModal',
  components: { MPopover, MButton, MIcon, MModal, EntryTypesTransferBody },
  props: {
    clientId: { type: Number, required: true },
    presenter: { type: MPresenterBase, required: true, default: () => new MEntryTypesTransferPresenter() },
    modalVisibility: Boolean,
    hasUpdates: Boolean,
    toothIds: { type: Array, default: undefined },
  },

  emits: [
    'fetchCount',
    'onSubmitComplete',
  ],

  data () {
    return {
      entryTypesTransferList: new EntryTypesTransferList({ clientId: this.clientId }),
      selectedItems: [],
    }
  },

  computed: {
    buttonsDisabled () {
      return !this.selectedItems.length || !this.entryTypesTransferList.data.length
    },

    presenterData () {
      return {
        clientId: this.clientId,
        entriesParams: (this.selectedItems || [])
          .map((entryType) => ({
            entryTypeId: entryType.id,
            referralId: entryType.referralId,
            toothIds: orUndefined(entryType.canAddWithTeeth()) && this.toothIds,
          })),
      }
    },
  },

  watch: {
    modalVisibility (to) {
      if (!to) { return }
      this.fetchList()
    },
  },

  methods: {
    async __checkDuplicates () {
      const hasDuplicates = this.selectedItems.some(({ duplicated }) => duplicated)

      return hasDuplicates
        ? await MConfirm.warning(t('transferDuplicatesConfirm'))
          .catch(() => Promise.resolve(null))
        : Promise.resolve(false)
    },

    async onCreate () {
      if (this.buttonsDisabled) { return }
      if (await this.__checkDuplicates() === null) { return }

      const { failedEntriesErrors, successfullySavedEntryTypeIds } =
        await this.presenter.createFromAppointments(this.presenterData)

      this.entryTypesTransferList.resetErrors(failedEntriesErrors || [])
      this.entryTypesTransferList.setDuplicated(successfullySavedEntryTypeIds || [])
      this.entryTypesTransferList.resetCheckboxes()

      this.$emit('onSubmitComplete')
    },

    async onDelete () {
      if (this.buttonsDisabled) { return }

      const { errors } = await this.presenter.deleteFromAppointments(this.presenterData)
      if (errors) { return }

      this.fetchList()
      this.$emit('fetchCount')
    },

    async fetchList () {
      this.$updateSync('hasUpdates', false)

      return this.entryTypesTransferList.fetchAll()
    },
  },
}
</script>
