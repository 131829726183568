export const PERCENT = 'percent'
export const FIXED = 'fixed'

export const EXTRA_CHARGE_TYPES = [
  Object.freeze({
    id: FIXED,
    title: t('enums.analysis_laboratory.entries_extra_charge_type.fixed'),
  }),
  Object.freeze({
    id: PERCENT,
    title: t('enums.analysis_laboratory.entries_extra_charge_type.percent'),
  }),
]
