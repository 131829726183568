/**
 * Requires from consumer component:
 * route: string;
 * cardAdapter: (object) => any;
 * requestParams: object;
 * Also,
 * @see {Card.computed.requestParams}
 * @see {Card.methods.cardAdapter}
 */
const Card = {
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      route: '',
      showLoadingSpinner: false,
      cardName: this.card.value,
      storeParamPrefix: `head_desktop_${this.card.value}_stored_params`,
      moduleIsEnabled: true,
      isInitialized: false,
    }
  },
  computed: {
    /**
     * In card component, return an object with request params.
     * They will be saved in `localStorage` after successful request.
     * @see {saveCardParams}
     * @abstract
     * @return {object}
     */
    requestParams () {
      return {}
    },
    cardParams () {
      return this.$store.state.headDesktopStore.selectors[this.cardName]
    },
  },
  mounted () {
    this.restoreCardParams()
    this.$nextTick(() => {
      // only make request on mount if it hasn't been made yet
      if (!this.isInitialized) {
        this.requestData()
      }
    })
  },
  watch: {
    requestParams () {
      this.requestData()
    },
  },
  methods: {
    requestData () {
      if (!this.moduleIsEnabled) {
        this.showFakeData()

        return
      }

      this.showLoadingSpinner = true
      // check if a request has been made after card mount
      if (!this.isInitialized) {
        this.isInitialized = true
      }

      const vm = this
      const prm = { report: { ...this.requestParams } }

      $.ajax({
        type: 'post',
        url: this.route,
        data: prm,
        success (result) {
          vm.cardAdapter(result)
          vm.saveCardParams(prm.report)
        },
        error (err) {
          console.error(err)
        },
        complete () {
          vm.showLoadingSpinner = false
        },
      })
    },
    /**
     * Uses global `store` object to save card params to `localStorage`
     * @param {object} params
     */
    saveCardParams (params) {
      for (const key of Object.keys(params)) {
        store.set(this.getStoreKey(key), params[key])
      }
    },
    /**
     * Restores vuex card state from `localStorage`
     */
    restoreCardParams () {
      const params = this.getParamsFromStore()
      this.$store.commit('headDesktopStore/setCardParams', {
        cardName: this.cardName,
        params,
      })
    },
    /**
     * Reads card params from `localStorage`
     * @return {object}
     */
    getParamsFromStore () {
      return Object.keys(this.requestParams).reduce((params, key) => {
        const fieldKey = this.getStoreKey(key)
        const value = store.get(fieldKey)
        if (value !== undefined) {
          params[key] = value
        }

        return params
      }, {})
    },
    /**
     * @param {string} param
     * @return {string} A `localStorage` key for card's parameter
     */
    getStoreKey (param) {
      return `${this.storeParamPrefix}_${param}`
    },
    /**
     * This method is required to set data from report request
     * @abstract
     * @param {any} result
     */
    cardAdapter (result) {},
    /**
     * Implement this method to set fake data for the card
     * @abstract
     */
    showFakeData () {},
  },
}
export default Card
