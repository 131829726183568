import {
  TreatmentEntryFirstRow,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/TreatmentEntryFirstRow'

export class TreatmentEntryEmpty extends TreatmentEntryFirstRow {
  isEmptyRow = true

  constructor (tree, rawNode, isLeaf = false) {
    super(tree, rawNode, true)
    this.title = t('treatmentPlansEntriesTransfer.nfrText')
  }
}
