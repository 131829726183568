<template>
  <div
    :key="item.id"
    class="col col__scheduleActions"
  >
    <popover
      placement="top-end"
      yes-style="primary"
      :disabled="!isNotScheduleClinic"
      @yes="$emit('showRecordingPointInSchedule', isNotScheduleClinic)"
    >
      <template #message>
        <div>{{ t('reception.time_finder.clinic_change_confirmation_row1') }}</div>
        <div>{{ t('reception.time_finder.clinic_change_confirmation_row2') }}</div>
      </template>

      <i
        slot="reference"
        v-tooltip="t('find_in_schedule')"
        class="fad fa-calendar-day primary mr-5"
        @click="!isNotScheduleClinic && $emit('showRecordingPointInSchedule', isNotScheduleClinic)"
      />
    </popover>

    <popover
      placement="top-end"
      yes-style="primary"
      :disabled="!isNotScheduleClinic"
      @yes="$emit('createAppointmentInSchedule', isNotScheduleClinic)"
    >
      <template #message>
        <div>{{ t('reception.time_finder.clinic_change_confirmation_row1') }}</div>
        <div>{{ t('reception.time_finder.clinic_change_confirmation_row2') }}</div>
      </template>

      <i
        slot="reference"
        v-tooltip="t('make_appointment')"
        class="fad fa-calendar-plus primary"
        @click="!isNotScheduleClinic && $emit('createAppointmentInSchedule', isNotScheduleClinic) "
      />
    </popover>
  </div>
</template>

<script>
import Popover from '@/vue_components/common/popover/popover.vue'

export default {
  name: 'RecordingPointScheduleActions',
  components: { Popover },

  props: {
    item: { type: Object, required: true },
    doctorSchedulesClinicId: { type: Number, required: true },
  },

  computed: {
    isNotScheduleClinic () {
      return this.item.clinicId !== this.doctorSchedulesClinicId
    },
  },
}
</script>
