export default {
  _handleErrors (response) {
    if (!response.ok) throw Error(response.status)

    return response.text()
  },

  _errorNotify (err) {
    console.error(err)
    Services
      .ui
      .notificator()
      .error(T.abstract_error_message, T.error, 0)
  },
}
