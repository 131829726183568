import { REQUEST_KEY } from './const'

export const clinicAdapter = {
  toServer (title) {
    return {
      [REQUEST_KEY]: {
        title,
      },
    }
  },
}
