<template>
  <si-field>
    <si-column
      :name="header.number.sortable"
      :active="sortableColumn"
      class="tbl-icon"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ header.number.text }}
    </si-column>
    <si-column
      :name="header.date.sortable"
      :active="sortableColumn"
      class="date cut"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ header.date.text }}
    </si-column>
    <si-column
      :name="header.title.sortable"
      :active="sortableColumn"
      class="title cut"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ header.title.text }}
    </si-column>
    <si-column
      :name="header.sum.sortable"
      :active="sortableColumn"
      class="sum cut"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ header.sum.text }}
    </si-column>
    <si-column
      :name="header.additional.sortable"
      :active="sortableColumn"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ header.additional.text }}
    </si-column>
    <si-column
      :name="header.expenseGroup.sortable"
      :active="sortableColumn"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ header.expenseGroup.text }}
    </si-column>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiColumn from '@/vue_components/sort_items/si_table/si_column.vue'

export default {
  name: 'ExpensesHeaderDefault',
  components: {
    SiField,
    SiColumn,
  },
  props: {
    sortableColumn: {
      type: String,
      required: true,
    },
    header: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
