<template>
  <modal
    id="appointment-create-modal"
    :modal-visibility="visibility"
    :modal-header-buttons="modalButtonsAll"
    modal-class="modal-xl"
    @close="$emit('close-modal')"
  >
    <template #header>
      <span class="fad fa-calendar-plus" />
      {{ t('appointment') }}
    </template>

    <template #body>
      <epic-spinner :visibility="loading" />
      <div class="appointment-form-flex d-flex">
        <div>
          <div class="flex-form no-margin">
            <div class="form-label">
              {{ t('custom_date') }}
            </div>
            <div class="form-value">
              <friendly-date
                v-if="date"
                :date="date"
              />
            </div>
            <div class="form-label">
              {{ t('custom_time') }}
            </div>
            <div class="form-value">
              {{ time }}
            </div>

            <div
              v-if="doctor.id"
              class="form-label"
            >
              <label>
                {{ t('doctor') }}
              </label>
            </div>
            <div
              v-if="doctor.id"
              class="form-value"
            >
              <user-avatar
                :user-id="doctor.id"
                :params="{ version: 'thumb16', tag: doctor.avatarTag }"
              />
              {{ doctor.shortName }}
            </div>
          </div>
          <client-form
            :strict="forcedClientEdit"
            :disabled="clientFormDisabled"
            :sexes="sexes"
            :online-access-options="onlineAccessOptions"
            :client-groups="clientGroups"
            :clinic-id="clinicId"

            :doctor="doctor"
            :errors="errors"
            :date="date"
            :time="time"

            :second-name="secondName"
            :name="name"
            :surname="surname"
            :birthdate="birthdate"
            :phone="phone"
            :sex="sex"
            :entry-online-access="entryOnlineAccess"
            :groups="groups"
            :email="email"
            :treating-doctor="treatingDoctor"
            :personal-discount="personalDiscount"
            :legal-representatives="legalRepresentatives"
            :additional="additional"
            :send-data-to-egisz="sendDataToEgisz"
            :snils="snils"
            :patient-card-number="patientCardNumber"

            @update:second-name="onClientFilterChange('second_name', 'secondName', $event)"
            @update:name="onClientFilterChange('name', 'name', $event)"
            @update:birthdate="$updateSync('birthdate', $event)"
            @update:sex="$updateSync('sex', $event)"
            @update:phone="onClientFilterChange('phone', 'phone', $event)"
            @update:entry-online-access="$updateSync('entryOnlineAccess', $event)"
            @update:groups="$updateSync('groups', $event)"
            @update:email="$updateSync('email', $event)"
            @update:treating-doctor="$updateSync('treatingDoctor', $event)"
            @update:personal-discount="$updateSync('personalDiscount', parseFloat($event))"
            @update:legal-representatives="$updateSync('legalRepresentatives', $event)"
            @update:sendDataToEgisz="$updateSync('sendDataToEgisz', $event)"
            @update:snils="$updateSync('snils', $event)"
            @update:additional="$updateSync('additional', $event)"
            @update:patientCardNumber="$updateSync('patientCardNumber', $event)"
            @add-legal-representative="$emit('add-legal-representative')"
            @request-demo="insertDemoData"
            @phone-mask-settings-change="$emit('phone-mask-settings-change', $event)"
          >
            <template #surname-value>
              <div class="form-value">
                <validation-wrapper :errors="errors.surname">
                  <div class="flex">
                    <input
                      id="surname"
                      ref="firstField"
                      class="form-control"
                      type="text"
                      :value="surname"
                      :disabled="clientFormDisabled"
                      @input="onClientFilterChange('surname', 'surname', $event.target.value)"
                    >
                    <button
                      v-tooltip="showTabs ? t('infoAndCoincidencesTooltip') : t('addressAndPassportDataTooltip')"
                      class="btn btn-primary btn-xs input-button fad client-field-btn"
                      tabindex="-1"
                      :class="`fa-caret-${showTabs ? 'left' : 'right'}`"
                      @click="showTabs = !showTabs"
                    />
                  </div>
                </validation-wrapper>
              </div>
            </template>
          </client-form>
        </div>

        <div class="appointment-form-middle">
          <client-form-tabs
            v-if="showTabs"
            :targeted-disable="tabsTargetDisable"
            :errors="errors"
            :full-address="fullAddress"
            :document-types="documentTypes"
            :company="company"

            :index="index"
            :country="country"
            :region="region"
            :nsi-russian-subject-id="nsiRussianSubjectId"
            :area="area"
            :city="city"
            :street="street"
            :house="house"
            :flat="flat"
            :document-type="documentType"
            :document-series="documentSeries"
            :document-number="documentNumber"
            :who-issue="whoIssue"
            :issue-date="issueDate"
            :position="position"
            :nsi-profession="nsiProfession"
            :department="department"
            :oms="oms"
            :inn="inn"
            :snils="snils"
            :no-sms="noSms"
            :no-whats-app="noWhatsApp"
            :no-sms-dispatches="noSmsDispatches"
            :no-calls="noCalls"
            :no-email="noEmail"

            @update:fullAddress="$updateSync('fullAddress', $event)"
            @update:who-issue="$updateSync('whoIssue', $event)"
            @update:company="$updateSync('company', $event)"

            @update:index="$updateSync('index', $event)"
            @update:country="$updateSync('country', $event)"
            @update:region="$updateSync('region', $event)"
            @update:nsiRussianSubjectId="$updateSync('nsiRussianSubjectId', $event)"
            @update:area="$updateSync('area', $event)"
            @update:city="$updateSync('city', $event)"
            @update:street="$updateSync('street', $event)"
            @update:house="$updateSync('house', $event)"
            @update:flat="$updateSync('flat', $event)"
            @update:document-type="$updateSync('documentType', $event)"
            @update:document-series="$updateSync('documentSeries', $event)"
            @update:document-number="$updateSync('documentNumber', $event)"
            @update:issue-date="$updateSync('issueDate', $event)"
            @update:position="$updateSync('position', $event)"
            @update:nsiProfession="$updateSync('nsiProfession', $event)"
            @update:department="$updateSync('department', $event)"
            @update:oms="$updateSync('oms', $event)"
            @update:inn="$updateSync('inn', $event)"
            @update:snils="$updateSync('snils', $event)"
            @update:no-sms="$updateSync('noSms', $event)"
            @update:no-whats-app="$updateSync('noWhatsApp', $event)"
            @update:no-sms-dispatches="$updateSync('noSmsDispatches', $event)"
            @update:no-calls="$updateSync('noCalls', $event)"
            @update:no-email="$updateSync('noEmail', $event)"
            @add-company="$emit('add-company')"
          />

          <appointment-similar-clients
            v-if="!showTabs"
            :clients="similarClients.clients"
            :selected="similarClients.selected"
            @update:selected="selectClientFromSearch"
          />

          <appointment-client-summary
            v-if="!showTabs"
            :client-id="clientSummary.clientId"
            :medical-record-id="clientSummary.medicalRecordId"
            :paid-sum="clientSummary.paidSum"
            :credit-debt="clientSummary.creditDebt"
            :last-visit="clientSummary.lastVisit"
            :previous-appointment-failed="clientSummary.previousAppointmentFailed"
            :last-visit-to-the-same-doctor-days-ago="clientSummary.lastVisitToTheSameDoctorDaysAgo"
            :last-visit-to-the-same-doctor="clientSummary.lastVisitToTheSameDoctor"
            :adv-distribution="clientSummary.advDistribution"
            :client-groups="clientSummary.clientGroups"
            :client-group-black-list="clientSummary.clientGroupBlackList"
            :client-group-vip="clientSummary.clientGroupVip"
            :active-medical-policies-titles="clientSummary.activeMedicalPoliciesTitles"
          />

          <client-service-card
            v-if="clientId || showTabs"
            :disabled="clientFormDisabled"
            :content="serviceCard"
            @input="$updateSync('serviceCard', $event)"
          />
        </div>

        <appointment-form
          :disabled="loading || disabled"
          :service-search-query="serviceSearchQuery"
          :services-search-result-array="servicesSearchResultArray"
          :service-search-allow-search="serviceSearchAllowSearch"
          :service-not-results="serviceNotResults"
          :service-search-loading="serviceSearchLoading"
          :currency-symbol="currencySymbol"
          :currency-format="currencyFormat"
          :durations-array="durationsArrayWithCustom"
          :min-duration="minDuration"
          :max-duration="maxDuration"
          :appointment-types-array="appointmentTypesArray"
          :appointment-type-id-default="appointmentTypeIdDefault"
          :attraction-source-array="attractionSourceArray"
          :validation-messages="errors"
          :clinic-id="clinicId"

          :entry-types="entryTypes"
          :note="note"
          :client-comment="clientComment"
          :duration="duration"
          :appointment-type-id="appointmentTypeId"
          :referral="referral"
          :attraction-source-id="attractionSourceId"
          :by-dms="byDms"
          :appointment-status="appointmentStatus"
          :appointment-source="appointmentSource"
          :add-to-waiting-list="addToWaitingList"
          :sms-remind-option="smsRemindOption"
          :sms-notify-option="smsNotifyOption"
          :sms-confirmation-option="smsConfirmationOption"
          :uis-remind-option="uisRemindOption"
          :uis-confirm-option="uisConfirmOption"
          :remind-before-date="remindBeforeDate"
          :notify-after-save="notifyAfterSave"
          :send-sms-confirmation="sendSmsConfirmation"
          :remind-by-call="remindByCall"
          :confirm-by-call="confirmByCall"
          :remind-before-date-stub-modal-visible="remindBeforeDateStubModalVisible"
          :notify-after-save-stub-modal-visible="notifyAfterSaveStubModalVisible"
          :send-sms-confirmation-stub-modal-visible="sendSmsConfirmationStubModalVisible"
          :remind-by-call-stub-modal-visible="remindByCallStubModalVisible"
          :confirm-by-call-stub-modal-visible="confirmByCallStubModalVisible"
          :last-sms-confirmation="lastSmsConfirmation"
          :last-sms-notify="lastSmsNotify"
          :last-sms-remind="lastSmsRemind"
          :created-by="createdBy"
          :updated-by="updatedBy"

          @update:serviceSearchQuery="$updateSync('serviceSearchQuery', $event)"
          @update:entryTypes="$updateSync('entryTypes', $event)"
          @update:note="$updateSync('note', $event)"
          @update:clientComment="$updateSync('clientComment', $event)"
          @update:duration="$updateSync('duration', $event)"
          @update:appointmentTypeId="$updateSync('appointmentTypeId', $event)"
          @update:referral="$updateSync('referral', $event)"
          @update:attractionSourceId="$updateSync('attractionSourceId', $event)"
          @update:byDms="$updateSync('byDms', $event)"
          @update:appointmentStatus="$updateSync('appointmentStatus', $event)"
          @update:addToWaitingList="$updateSync('addToWaitingList', $event)"
          @update:smsRemindOption="$updateSync('smsRemindOption', $event)"
          @update:smsNotifyOption="$updateSync('smsNotifyOption', $event)"
          @update:smsConfirmationOption="$updateSync('smsConfirmationOption', $event)"
          @update:uisRemindOption="$updateSync('uisRemindOption', $event)"
          @update:uisConfirmOption="$updateSync('uisConfirmOption', $event)"
          @update:remindBeforeDate="$updateSync('remindBeforeDate', $event)"
          @update:notifyAfterSave="$updateSync('notifyAfterSave', $event)"
          @update:sendSmsConfirmation="$updateSync('sendSmsConfirmation', $event)"
          @update:remindByCall="$updateSync('remindByCall', $event)"
          @update:confirmByCall="$updateSync('confirmByCall', $event)"
          @update:remindBeforeDateStubModalVisible="$updateSync('remindBeforeDateStubModalVisible', $event)"
          @update:notifyAfterSaveStubModalVisible="$updateSync('notifyAfterSaveStubModalVisible', $event)"
          @update:sendSmsConfirmationStubModalVisible="$updateSync('sendSmsConfirmationStubModalVisible', $event)"
          @update:remindByCallStubModalVisible="$updateSync('remindByCallStubModalVisible', $event)"
          @update:confirmByCallStubModalVisible="$updateSync('confirmByCallStubModalVisible', $event)"
          @update:lastSmsConfirmation="$updateSync('lastSmsConfirmation', $event)"
          @update:lastSmsNotify="$updateSync('lastSmsNotify', $event)"
          @update:lastSmsRemind="$updateSync('lastSmsRemind', $event)"
          @addService="addEntry"
          @entryRemove="removeEntry"
          @add-referral="$emit('add-referral')"
          @add-duration="addNewDuration"
        />
      </div>
    </template>

    <template #footer-left>
      <guarded-control
        tag="button"
        :permissions="['canManageClient']"
        :disabled="cleared"
        class="btn btn-warning btn-with-icon modal-clear"
        type="button"
        @click:allowed="clearForm"
      >
        <span class="btn-with-icon_icon fad fa-fw fa-eraser" />
        <span class="btn-with-icon_text">
          {{ t('clear') }}
        </span>
      </guarded-control>
      <guarded-control
        tag="button"
        :permissions="['canManageClient']"
        :disabled="!clientFormDisabled"
        class="btn btn-warning btn-with-icon modal-edit-client"
        type="button"
        @click:allowed="editClient"
      >
        <span class="btn-with-icon_icon fad fa-fw fa-pencil" />
        <span class="btn-with-icon_text">
          {{ t('reception.e_client') }}
        </span>
      </guarded-control>
    </template>

    <template #footer-right>
      <guarded-control
        tag="button"
        :permissions="['canManageAppointment']"
        :disabled="disableButtons"
        class="btn btn-success btn-with-icon modal-save"
        type="button"
        @click:allowed="$emit('submit-form')"
      >
        <span class="btn-with-icon_icon fad fa-save" />
        <span class="btn-with-icon_text">
          {{ t('save') }}
        </span>
      </guarded-control>
      <button
        class="btn btn-primary btn-with-icon modal-close"
        type="button"
        @click="$emit('close-modal')"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">
          {{ t('close') }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>
import AppointmentClientSummary from '@/vue_components/appointment/appointment_client_summary.vue'
import AppointmentForm from '@/vue_components/appointment/appointment_form.vue'
import AppointmentSimilarClients from '@/vue_components/appointment/appointment_similar_clients.vue'
import ClientForm from '@/vue_components/client/client_form.vue'
import ClientFormTabs from '@/vue_components/client/client_form_tabs.vue'
import ClientServiceCard from '@/vue_components/client/client_service_card.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import Modal from '@/vue_components/modal.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'

import propsClient from '@/vue_components/client/client_form_props.js'
import propsClientTabs from '@/vue_components/client/client_form_tabs_props.js'
import propsClientAddress from '@/vue_components/client/client_form_address_props.js'
import propsAppointment from '@/vue_components/appointment/props.js'
import { searchEntries } from '../rest'
import UserAvatar from '@/vue_components/user_avatar'
import FriendlyDate from '@/vue_components/common/friendly_date'
import { SimilarClientsConsumer } from '@/vue_components/client/similar_clients_consumer'
import GuardedControl from '@/vue_components/common/guarded_control'
import { ModalConsumer } from '@/vue_components/mixins/modals/modal_consumer'

export default {
  name: 'AppointmentCreateModal',

  components: {
    GuardedControl,
    FriendlyDate,
    UserAvatar,
    AppointmentClientSummary,
    AppointmentForm,
    AppointmentSimilarClients,
    ClientForm,
    ClientFormTabs,
    ClientServiceCard,
    EpicSpinner,
    Modal,
    ValidationWrapper,
  },

  mixins: [SimilarClientsConsumer, ModalConsumer],

  props: {
    visibility: Boolean,
    loading: Boolean,
    forcedClientEdit: Boolean,
    disableButtons: Boolean,
    cleared: Boolean,
    /**
     * @type {import('vue').PropOptions<{id: number, shortName: string}>}
     */
    doctor: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    date: {
      type: String,
      default: '',
      required: false,
    },

    time: {
      type: String,
      required: false,
      default: '',
    },

    clientId: {
      type: Number,
      default: null,
    },

    clientSummary: {
      type: Object,
      default: () => ({}),
    },

    serviceCard: {
      type: String,
      default: '',
    },

    clientEdited: Boolean,

    ...propsClient,
    ...propsClientTabs,
    ...propsClientAddress,
    ...propsAppointment,
  },

  data () {
    return {
      showTabs: false,
      durationsArrayCurrent: [...this.durationsArray],
    }
  },

  computed: {
    clientFormDisabled () {
      return !this.clientEdited ||
        this.disabled ||
        this.loading
    },

    tabsTargetDisable () {
      if (this.clientFormDisabled) {
        return {
          fullAddress: true,
          index: true,
          country: true,
          region: true,
          area: true,
          city: true,
          street: true,
          house: true,
          flat: true,
          type: true,
          series: true,
          number: true,
          whoIssue: true,
          issueDate: true,
          company: true,
          position: true,
          nsiProfession: true,
          department: true,
          oms: true,
          inn: true,
          snils: true,
          noSms: true,
          noWhatsApp: true,
          noSmsDispatches: true,
          noCalls: true,
          noEmail: true,
        }
      }

      return {}
    },

    reqEntriesParams () {
      if (this.serviceSearchQuery === '') {
        return {}
      }

      return {
        for_current_clinics: true,
        clinic_ids: [this.$store.getters.GET_SCHEDULE_CURRENT_CLINIC_ID],
        user_id: this.doctor.id,
        title: this.serviceSearchQuery,
      }
    },

    durationsArrayWithCustom: {
      get () {
        return [...this.durationsArrayCurrent]
          .filter((duration) => duration <= this.maxDuration)
          .sort((a, b) => a - b)
      },
      set (value) {
        this.durationsArrayCurrent = value
      },
    },
  },

  watch: {
    reqEntriesParams () {
      if (this.serviceSearchAllowSearch) {
        this.findEntries()
      }
    },
    clientsSearch: {
      deep: true,
      handler (newValue) {
        if (
          !this.similarClients.selected &&
          !this.clientId &&
          (newValue.surname.length > 2 ||
            newValue.name.length > 2 ||
            newValue.second_name.length > 2 ||
            newValue.phone.length > 2)
        ) {
          this.findSimilarClients(newValue)
        }
      },
    },
    serviceSearchAllowSearch () {
      if (!this.serviceSearchAllowSearch && this.servicesSearchResultArray.length) {
        this.$emit('update:servicesSearchResultArray', [])
      }
    },

    entryTypes (entryTypes) {
      const entriesWithDuration = entryTypes.filter((entry) => Boolean(entry.appointment_duration))
      if (!entriesWithDuration.length) return

      this.changeAppointmentDurationFromEntry(entriesWithDuration)
    },
  },

  mounted () {
    this.checkLimitOfDuration()
    this.$refs.firstField.focus()
  },

  methods: {
    addNewDuration (duration) {
      this.durationsArrayCurrent.push(duration)
      this.$nextTick(() => {
        this.$updateSync('duration', duration)
      })
    },
    clearForm () {
      this.$emit('clear-form')
      this.$updateSync('forcedClientEdit', false)
      this.resetSimilarClients()
    },
    editClient () {
      this.$updateSync('forcedClientEdit', true)
      this.$updateSync('clientEdited', true)
    },
    selectClientFromSearch (client) {
      this.$updateSync('similar-clients-selected', client)
      this.$updateSync('clientEdited', false)
      this.resetSimilarClients()
    },
    onClientFilterChange (filter, prop, value) {
      this.setClientSearchParam(filter, value)
      this.$updateSync(prop, value)
    },
    insertDemoData () {
      if (!this.clientFormDisabled) {
        Utils.fakeForms.client()
          .then((client) => {
            this.resetSimilarClients()
            this.$emit('demo-client', client)
          })
      }
    },

    /**
     * @duplicate
     */
    changeAppointmentDurationFromEntry (entriesWithDuration) {
      const finallyDuration = entriesWithDuration.reduce((sum, entry) => sum + entry.appointment_duration, 0)

      if (finallyDuration > this.maxDuration) {
        this.$updateSync('duration', this.maxDuration)

        return
      }

      if (!this.durationsArrayWithCustom.includes(finallyDuration)) {
        this.addNewDuration(finallyDuration)

        return
      }

      this.$updateSync('duration', finallyDuration)
    },

    checkLimitOfDuration () {
      if (this.duration <= this.maxDuration) return

      this.$updateSync('duration', this.durationsArrayWithCustom[this.durationsArrayWithCustom.length - 1])
    },

    findEntries () {
      this.$emit('update:serviceSearchLoading', true)
      searchEntries(this.reqEntriesParams)
        .then((response) => {
          this.$emit('update:servicesSearchResultArray', response)
        })
        .catch(() => {
          Notificator.error(t('abstract_error_message'))
        })
        .finally(() => {
          this.$emit('update:serviceSearchLoading', false)
        })
    },

    addEntry (entry) {
      this.$updateSync('entryTypes', [...this.entryTypes, entry])
    },

    removeEntry (entryId) {
      const entryIndex = this.entryTypes.findIndex((entry) => entry.id === entryId)

      this.$updateSync('entryTypes', this.entryTypes.filter((e, index) => index !== entryIndex))
    },
  },
}
</script>
