import {
  IInsuranceCompaniesReportFiltersGet as FiltersGet,
  IInsuranceCompaniesReportFiltersSet as FiltersSet,
} from '@/specific/reports/insuranceCompanies/interfaces'
import {
  IInsuranceCompaniesReportFiltersProfileFiltersToServer as IFiltersToServer,
  IInsuranceCompaniesReportFiltersProfileFiltersToClient as IFiltersToClient,
} from '../../interfaces/filtersTypes/insuranceCompaniesReport'
import { IFiltersProfilesFetchResponse } from '../../interfaces/IFIltersProfilesFetchResponse'
import { FiltersProfilesLegacyFormattersToServer as FormattersToServer } from '../../helpers/formatters/legacy/toServer'
import { FiltersProfilesLegacyFormattersToClient as FormattersToClient } from '../../helpers/formatters/legacy/toClient'
import { gatherErrors } from '../../helpers/gatherErrors'
import { IFiltersProfilesModel } from '../../interfaces/IFiltersProfilesModel'
import { cleanUnpermittedValues } from '../../helpers/formatters/shared'
import { omitBy, isUndefined } from 'lodash'

export const insuranceCompaniesReportFiltersProfileAdapter = {
  toServer (data: IFiltersProfilesModel<FiltersGet>): IFiltersProfilesModel<IFiltersToServer> {
    const { data: filters } = data

    const adaptedFilters: IFiltersToServer = {
      period: FormattersToServer.formatPeriod(filters.period),
      clinic: FormattersToServer.formatSingle(filters.clinic),
      insuranceCompany: FormattersToServer.formatSingle(filters.insuranceCompany),
      company: FormattersToServer.formatSingle(filters.company),
      medicalPolicyType: FormattersToServer.formatSingle(filters.medicalPolicyType),
    }

    return { ...data, data: adaptedFilters }
  },

  toClient (data: IFiltersProfilesFetchResponse<IFiltersToClient>): IFiltersProfilesModel<FiltersSet> {
    const { data: filters } = data

    const validFilters = omitBy(cleanUnpermittedValues(filters), isUndefined) as IFiltersToClient

    const adaptedFilters: FiltersSet = {
      period: FormattersToClient.formatPeriod(validFilters.period),
      clinic: FormattersToClient.formatSingle(validFilters.clinic),
      insuranceCompany: FormattersToClient.formatSingle(validFilters.insuranceCompany),
      company: FormattersToClient.formatSingle(validFilters.company),
      medicalPolicyType: FormattersToClient.formatSingle(validFilters.medicalPolicyType),
    }

    const definedAdaptedFilters = omitBy(adaptedFilters, isUndefined) as FiltersSet

    return {
      ...data,
      data: definedAdaptedFilters,
      dataErrors: gatherErrors(filters),
    }
  },
}
