export const ElSelectFix = {
  props: {
    // Будет дублироваться в компонентах, чтобы типизировать пропсы
    defaultOptions: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      optionsList: this.getDefaultOptions(),
    }
  },
  watch: {
    /**
     * Смотрим за значением селекта, чтобы можно было
     * прокинуть начальное значение после того,
     * как селект будет отрендерен. Это частая ситуация,
     * так как обычно форма сначала создаётся пустой,
     * а потом заполняется данными, возможно, асинхронно.
     */
    value () {
      this.cacheSelectedOptions()
    },
    defaultOptions () {
      this.optionsList = this.getDefaultOptions()
    },
  },
  mounted () {
    this.cacheSelectedOptions()
  },
  methods: {
    /**
     * Хак, так как мы не можем управлять отображением тегов.
     * Чтобы опции, которые были переданы в value, отобразились корректно,
     * нужно отобразить из в списке опций в дропдауне. Сам дропдаун при этом не показывается,
     * но опции инициализруются и обновляют данные в селекте, проставляя лейблы тегам.
     * Потом список опций можно вернуть обратно.
     * -50 социальный рейтинг тот кто писал теги в селект
     */
    cacheSelectedOptions () {
      if (this.value) {
        const values = Array.isArray(this.value) ? this.value : [this.value]
        values.forEach((val) => {
          if (!this.optionsList.find((option) => option.id === val.id)) {
            this.optionsList.push(val)
          }
        })
      }
    },
    getDefaultOptions () {
      return this.defaultOptions.slice(0)
    },
  },
}
