import { createVueApp } from '@/vue_components/create_vue_app'
import EntryTypesTreeApp from '@/vue_present/Reuse/EntryTypes/EntryTypesTree/EntryTypesTreeApp.vue'
import { fetchEntryType } from '@/vue_present/Reuse/EntryTypes/EntryTypesTree/legacy/fetchEntryType'

let vueApp = null
export const createEntryTypesTreeApp = (el = '#vue_entry_types_tree', target = 'page.form.order.orderEntryList.askAddEntry') => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'EntryTypesTreeRoot',
    render: (h) => h(EntryTypesTreeApp, {
      props: {
        async legacyCallback (item) {
          const entryType = await fetchEntryType(item.id)
          PubSub.emit(target, { item: entryType })
        },
      },
    }),
  })
}
