import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'

export const getAttachmentTabHyperlinkSchema = () => new MSiSchema()
  .addCreateButton()
  .addTableSchema(new MTableSchema({
    headers: {
      date: new MTableHeader().addFixedWidth('100px'),
      title: new MTableHeader(),
    },
  })
    .addEditIcon()
    .addDeleteIcon()
  )
  .addPagination()
