export default () => {
  const workplaceId = gon.specific.workplace.id
  let updateKey = $('#update-key')
  let testConnection = $('#test-connection')

  testConnection.on('click', () => {
    $.ajax(Routes.connection_test_workplace_path(workplaceId), {
      method: 'GET',
      error () {
        bootbox.alert(t('error'))
      },
    })
  })

  Services.pubSub.subscribe('workplace.handshake', (msg) => {
    Services.workplace.sendMessage('get_info', { workplace_id: workplaceId })
  })

  Services.pubSub.subscribe('workplace.get_info', (msg) => {
    $('#fiscal-printer-title').text(msg.data.info)
  })

  const forceUpdateKey = () => {
    $.ajax(Routes.update_key_workplace_path(workplaceId), {
      method: 'POST',
      success () {
        bootbox.alert(t('key_updated'), function () {
          Turbolinks.visit(Routes.workplace_path(workplaceId))
        })
      },
      error () {
        bootbox.alert(t('error'))
      },
    })
  }

  updateKey.on('click', () => {
    const msg = t('update_api_key_po')
    bootbox.confirmYN(msg, (confirm) => {
      if (!confirm) return
      forceUpdateKey()
    })
  })
}
