import Vue from 'vue/dist/vue.esm'
import clientWidgetConfiguration from '@/vue_components/client_widget/configuration.vue'

$document.on('rez/client_widget/configurations/edit', () => {
  if (gon.specific.moduleIsEnabled) {
    const { configuration } = gon.specific

    // eslint-disable-next-line
    new Vue({
      el: '#client_widget_settings_container',
      components: { clientWidgetConfiguration },
      data: () => ({ configuration }),
      template: `<div id="client_widget_settings_container">
                <client-widget-configuration :configuration="configuration"/>
               </div>`,
    })
  }
})
