<template>
  <div class="flex gap-indent-small ml-15">
    <!-- Врач -->
    <m-select-lazy
      v-model="currentUser"
      :label="t('performer')"
      :fetch-method="fetchPerformer"
      :m-fixed-height="false"
      :placeholder="t('selectToAll')"
      option-label="shortName"
      use-first-time-fetch
      use-user-mode
      filterable
      @changeByLazy="setMassValue('performer', $event)"
    />

    <!-- Ассистент -->
    <m-select-lazy
      v-if="useAssistants"
      v-model="currentAssistant"
      :label="t('assistant')"
      :fetch-method="fetchAssistant"
      :m-fixed-height="false"
      :placeholder="t('selectToAll')"
      option-label="shortName"
      use-first-time-fetch
      use-user-mode
      filterable
      @changeByLazy="setMassValue('assistant', $event)"
    />

    <!-- Реферал -->
    <referral-list-add
      v-model="currentReferral"
      @changeByLazy="setMassValue('referral', $event)"
    />

    <!-- Склад -->
    <m-select-lazy
      v-model="currentStore"
      :label="t('store')"
      :fetch-method="(params) => storesPresenter.list(params)"
      :m-fixed-height="false"
      :disabled="storesDisabled"
      :placeholder="t('selectToAll')"
      :use-first-time-fetch="!storesDisabled"
      :clearable="!storesDisabled"
      filterable
      @changeByLazy="setMassValue('store', $event)"
    />
  </div>
</template>

<script>
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { doctorPresenter } from '@/api_entities/doctors/doctorPresenter'
import { MUserPresenter } from '@/_api/MUser/MUserPresenter'
import { MStorePresenter } from '@/_api/MStore/MStorePresenter'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import ReferralListAdd from '@/vue_present/Reuse/ReferralListAdd/ReferralListAdd.vue'
import { cloneDeep } from 'lodash'
import { fetchUser } from '@/vue_apps/Orders/helpers/fetchUser'
import { emitMassRowsUpdate } from '@/vue_apps/Orders/helpers/emitMassRowsUpdate'
import { getDefaultUser } from '@/vue_apps/Orders/helpers/getDefaultUser'

export default {
  name: 'OrderMassSelect',
  components: { ReferralListAdd, MSelectLazy },
  props: {
    useAssistants: Boolean,
    storesDisabled: Boolean,
  },

  data () {
    return {
      doctorPresenter,
      assistantsPresenter: new MUserPresenter(),

      storesPresenter: new MStorePresenter(),
      snakeToCamel,

      currentUser: null,
      currentAssistant: null,
      currentReferral: null,
      currentStore: null,
    }
  },

  created () {
    this.fillVariables()
    this.fillGon()
  },

  beforeDestroy () {
    // обнуление
    gon.specific.performerLast = undefined
    gon.specific.assistantLast = undefined
    gon.specific.referralLast = undefined
    gon.specific.storeLast = undefined
  },

  methods: {
    camelToSnake,
    fillVariables () {
      this.currentUser = cloneDeep(getDefaultUser(gon.specific.defaultUser) || null)
      this.currentAssistant = cloneDeep(gon.specific.defaultAssistant || null)
      this.currentReferral = cloneDeep(gon.specific.defaultReferral || null)
      this.currentStore = cloneDeep(gon.specific.defaultStore || null)
    },

    /** todo: удалить когда-нибудь */
    fillGon () {
      gon.specific.performerLast = this.currentUser
      gon.specific.assistantLast = this.currentAssistant
      gon.specific.referralLast = this.currentReferral
      gon.specific.storeLast = this.currentStore
    },

    /**
     * @param {TEntryFieldTarget} target
     * @param {{id: number, shortName: string, title: string}} value
     */
    setMassValue (target, value) {
      if (this.storesDisabled && target === 'store') { return }

      emitMassRowsUpdate(target, value)

      gon.specific[`${target}Last`] = value
    },

    fetchPerformer (sortParams) {
      return fetchUser((params) => doctorPresenter.fetchAll(params, snakeToCamel), sortParams)
    },

    fetchAssistant (sortParams) {
      return fetchUser((params) => this.assistantsPresenter.list(params), sortParams)
    },
  },
}
</script>
