<template>
  <si-field>
    <si-column
      :name="headerTitle.sortable"
      :active="sortableColumn"
      class="title cut"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ headerTitle.text }}
    </si-column>
    <si-column
      :name="header.amount.sortable"
      :active="sortableColumn"
      class="amount cut"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ header.amount.text }}
    </si-column>
    <si-column
      :name="header.sum.sortable"
      :active="sortableColumn"
      class="sum cut"
      @sort-change="$emit('sort-change', $event)"
    >
      {{ header.sum.text }}
    </si-column>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiColumn from '@/vue_components/sort_items/si_table/si_column.vue'

export default {
  name: 'ExpensesHeaderGrouped',
  components: {
    SiField,
    SiColumn,
  },
  props: {
    header: {
      type: Object,
      required: true,
    },
    sortableColumn: {
      type: String,
      required: true,
    },
  },

  computed: {
    headerTitle () {
      return this.header.expenseGroup
        ? this.header.expenseGroup
        : this.header.title
    },
  },
}
</script>

<style scoped>

</style>
