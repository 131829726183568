<template>
  <el-tabs
    id="attachments-tabs"
    v-model="activeTab"
    stretch
    type="card"
    class="medical-records-tabs-container__tab"
  >
    <el-tab-pane
      name="files"
      :label="t('files')"
    >
      <attachments-tab-files
        :client-id="clientId"
        @updateFilesCount="$emit('updateFilesCount')"
      />
    </el-tab-pane>

    <el-tab-pane
      :label="t$('hyperlinks')"
      name="hyperlinks"
    >
      <attachments-tab-hyperlinks
        :client-id="clientId"
        @updateFilesCount="$emit('updateFilesCount')"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts">
import AttachmentsTabFiles
  from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/components/AttachmentsTabFiles.vue'
import { defineComponent } from 'vue'
import AttachmentsTabHyperlinks
  from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/components/AttachmentsTabHyperlinks.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default defineComponent({
  name: 'AttachmentsTab',

  components: {
    AttachmentsTabFiles,
    AttachmentsTabHyperlinks,
  },

  mixins: [i18nScopeMixin],

  props: {
    clientId: { type: Number, required: true },
  },

  emits: ['updateFilesCount'],

  data () {
    return {
      activeTab: 'files',
    }
  },

  created () {
    this.setI18nScope('medicalRecord.tab.hyperlink')
  },
})
</script>
