<template>
  <el-collapse-item>
    <template slot="title">
      <i class="fad fa-fw fa-money-bill-wave-alt" />
      <span class="collapse-text">{{ T.exports_page.payment.head }}</span>
    </template>
    <div class="payments si-control vue-items-list">
      <div class="filters">
        <date-range-picker
          v-model="paymentDateRange"
          :value-format="'yyyy-MM-dd'"
          :application-date-format="dateFormat"
        />
        <base-select
          v-model="paymentClinicIds"
          clearable
          multiple
          collapse-tags
          size="small"
          :placeholder="T.all_clinics"
        >
          <el-option
            v-for="clinic in clinics"
            :key="clinic.id"
            :value="clinic.id"
            :label="clinic.title"
          />
        </base-select>
      </div>
      <div class="buttons vue-list">
        <button
          class="vue-list-item"
          @click="exportPayments()"
        >
          {{ T.download }}
        </button>
      </div>
    </div>
  </el-collapse-item>
</template>

<script>
import { payments } from '../request_data.js'
import { headers } from '../const.js'
import eventMethods from '../events.js'

import DateRangePicker from '@/vue_components/common/select/date_range_picker.vue'
import BaseSelect from '@/vue_components/common/select/base_select/base_select.vue'

export default {
  name: 'PaymentsExport',

  components: {
    BaseSelect,
    DateRangePicker,
  },

  props: {
    withLocale: {
      default: false,
      type: Boolean,
    },
    clinics: {
      default: null,
      type: Array,
    },
  },

  data: () => ({
    paymentClinicIds: [],
    paymentDateRange: [],
    dateFormat: gon.localization.date_format,
  }),

  methods: {
    ...eventMethods,

    exportPayments () {
      this.$emit('request-state')

      let data = {
        ...payments,
        withLocale: this.withLocale,
        clinicIds: this.paymentClinicIds,
        date: this.paymentDateRange,
      }

      fetch(Routes.exports_payments_path(), {
        method: 'POST',
        headers: {
          ...headers,
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        credentials: 'same-origin',
        body: JSON.stringify(data),
      })
        .then(this._handleErrors)
        .then((result) => {
          Utils.downloadExportFile(result, T.exports_page.payment.head)
        })
        .catch((error) => {
          this._errorNotify(error)
        })
        .finally(() => {
          this.$emit('request-state')
        })
    },
  },
}
</script>
