import { ItemBase } from '@/_api/_classes/ItemBase'
import { IFiltersProfilesModel } from '../interfaces/IFiltersProfilesModel'
import { IApiError } from '@/_declarations/IApiErrors'

export class FiltersProfileModel extends ItemBase implements IFiltersProfilesModel {
  _permit = [
    'id',
    'title',
    'userId',
    'clinicId',
    'profileType',
    'data',
    'dataErrors',
  ]

  id: number = null

  title: string = null

  userId: number = gon.application.current_user.id

  clinicId: number = gon.application.current_clinic.id

  profileType: number = null

  data: object = null

  dataErrors: IApiError = null
}
