import { ReportParamFactory, ReportParamStrict } from '../shared'
import { insuranceCompaniesReportFiltersSelectors as selectors } from './filtersSelectors'
import { IInsuranceCompaniesReportFiltersGet } from './interfaces'

export const _getInsuranceCompaniesReportFilters = (): IInsuranceCompaniesReportFiltersGet => ({
  period: ReportParamFactory.getRawParam(selectors.period),
  clinic: ReportParamFactory.getSelectParam(selectors.clinic) as ReportParamStrict,
  insuranceCompany: ReportParamFactory.getSelectParam(selectors.insuranceCompany) as ReportParamStrict,
  company: ReportParamFactory.getSelectParam(selectors.company) as ReportParamStrict,
  medicalPolicyType: ReportParamFactory.getButtonsParam(selectors.medicalPolicyType) as ReportParamStrict,
})
