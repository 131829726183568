import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import {
  getTransferAvailability,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/helpers'

/**
 * @param h
 * @param {TreatmentEntry} treatmentEntry
 */
const getIcon = (h, treatmentEntry) => {
  if (treatmentEntry.hasError) {
    return h(MIcon, {
      props: {
        icon: 'warningCircle',
        color: 'danger',
      },
      directives: [
        {
          name: 'tooltip',
          value: t('treatmentPlansEntriesTransfer.entries.hasError'),
        },
      ],
    })
  }

  return (treatmentEntry.duplicated && h(MIcon, {
    props: {
      icon: 'warning',
      color: 'warning',
    },
    directives: [
      {
        name: 'tooltip',
        value: t('treatmentPlansEntriesTransfer.entries.alreadyHas'),
      },
    ],
  })) || null
}

export const TreatmentEntryNodeRenderMixin = {
  components: {
    MCheckbox,
    MInput,
    MIcon,
  },

  /**
   * @param h
   * @param node
   * @param {TreatmentEntry | TreatmentEntryFirstRow} data
   * @param store
   */
  methods: {
    _getTreatmentEntryNodeContent (h, { node, data, store }) {
      if (data.isFirstRow) {
        return this._getFirstTreatmentEntryNodeContent(h, { node, data, store })
      }

      const checkbox =
        h(MCheckbox, {
          class: 'treatment-entry-node__checkbox',
          props: {
            value: data.selected,
            disabled: !data.selectable,
          },
          on: {
            input (value) {
              data.select(Boolean(value))
            },
          },
        })

      const maxTransferredAmount = getTransferAvailability().isWithAmountChecking
        ? data.availableAmount
        : Infinity

      const transferredAmount =
        h(MInput, {
          class: 'treatment-entry-node__transferred-amount',
          props: {
            value: data.transferredAmount,
            type: 'number',
            min: 1,
            max: maxTransferredAmount,
            precision: 0,
            controls: true,
            controlsPosition: 'right',
            mFixedHeight: false,
            disabled: !data.selectable,
          },
          on: {
            input (value) { data.transferredAmount = value },
          },
        })

      const title =
        h('div', {
          class: 'treatment-entry-node__title cut',
          directives: [
            {
              name: 'tooltip',
              value: data.title,
            },
          ],
        }, data.title)

      const price =
        h('div', { class: 'treatment-entry-node__price' }, data.price)

      const availableAmount =
        h('div', { class: 'treatment-entry-node__available-amount' }, data.availableAmount)

      const discount =
        h('div', { class: 'treatment-entry-node__discount' }, data.discount)

      const comment =
        h('div', {
          class: 'treatment-entry-node__comment cut',
          directives: [
            {
              name: 'tooltip',
              value: data.comment,
            },
          ],
        }, data.comment)

      const icon =
        h('div', { class: 'treatment-entry-node__state-icon' }, [getIcon(h, data)])

      return h('div', { class: 'custom-tree-node treatment-entry-node' }, [
        checkbox,
        transferredAmount,
        title,
        price,
        availableAmount,
        discount,
        comment,
        icon,
      ])
    },

    _getFirstTreatmentEntryNodeContent  (h, { node, data, store }) {
      if (data.isEmptyRow) {
        return this._getEmptyTreatmentEntryNodeContent(h, { node, data, store })
      }

      const checkbox =
        h(MCheckbox, {
          class: 'treatment-entry-node__checkbox',
          props: {
            value: data.selected,
            indeterminate: data.indeterminate,
          },
          on: {
            input (value) { data.select(value) },
          },
        })

      const transferredAmount =
        h('div', { class: 'treatment-entry-node__transferred-amount' })

      const title =
        h('div', { class: 'treatment-entry-node__title' }, data.title)

      const price =
        h('div', { class: 'treatment-entry-node__price' }, data.price)

      const availableAmount =
        h('div', { class: 'treatment-entry-node__available-amount' }, data.availableAmount)

      const discount =
        h('div', { class: 'treatment-entry-node__discount' }, data.discount)

      const comment =
        h('div', { class: 'treatment-entry-node__comment' }, data.comment)

      const duplicated =
        h('div', { class: 'treatment-entry-node__state-icon' })

      return h('div', { class: 'custom-tree-node treatment-entry-node treatment-entry-node_first' }, [
        checkbox,
        transferredAmount,
        title,
        price,
        availableAmount,
        discount,
        comment,
        duplicated,
      ])
    },

    _getEmptyTreatmentEntryNodeContent (h, { node, data, store }) {
      return h('div', { class: 'custom-tree-node treatment-entry-node treatment-entry-node_empty' }, [
        h('div', { class: 'italic ml-15' }, data.title),
      ])
    },
  },
}
