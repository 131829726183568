import {
  SCHEDULE_TEMPLATE_FACTORY,
} from '../../../../const.js'
import { commonFields } from './template_factory.js'
import PersistentTemplate from '../persistent_template.js'

export default {
  initializePreset (options) {
    this.preset = SCHEDULE_TEMPLATE_FACTORY
  },

  getPersistentTemplate (scheduleTemplate) {
    const { id } = scheduleTemplate
    const cabinetId = this.availableCabinetsIds.has(scheduleTemplate.cabinet_id) ? scheduleTemplate.cabinet_id : null
    const [startTime, endTime] = scheduleTemplate.work_time.split('-')
    const templateTypeId = scheduleTemplate.type
    const {
      templateDayIntervalId,
      templateWeekIntervalId,
      templateSelectedDaysIds,
    } = commonFields(templateTypeId, scheduleTemplate)
    const availabilityForOnlineRecording = scheduleTemplate.availability_for_online_recording
    const templateActive = scheduleTemplate.active.toString()
    const propagationDate = moment(scheduleTemplate.last_propagation_end_date).toDate()

    const initialData = {
      id,
      cabinetId,
      startTime,
      endTime,
      templateTypeId,
      templateDayIntervalId,
      templateWeekIntervalId,
      templateSelectedDaysIds,
      availabilityForOnlineRecording,
      templateActive,
      propagationDate,
    }

    return new PersistentTemplate(initialData, this.preset, scheduleTemplate)
  },
}
