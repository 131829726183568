<template>
  <si-field>
    <div class="tbl-icon">
      <span
        :data-tooltip="row.id"
        class="fad fa-fw fa-coin tooltip-bottom"
      />
    </div>
    <div class="date cut">
      {{ row.date | date }}
    </div>
    <div class="title cut">
      {{ row.title }}
    </div>
    <div class="sum cut">
      {{ row.price | price }}
    </div>
    <div>
      {{ row.additional }}
    </div>
    <div>
      {{ row.expenseGroup | expenseGroup }}
    </div>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'

export default {
  name: 'ExpensesRowDefault',
  components: {
    SiField,
  },
  filters: {
    expenseGroup (group) {
      return group ? group.title : ''
    },
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
