<template>
  <el-select
    v-model="value"
    size="small"
    :class="['patient-type-selector', {selected: isSelected}]"
  >
    <el-option
      v-for="item in options"
      :key="item[0]"
      :label="item[1]"
      :value="item[0]"
    />
  </el-select>
</template>

<script>
import CardSelector from './selector.js'

export default {
  mixins: [CardSelector],
  data () {
    return {
      options: [['', this.T.all_patients], ['new', this.T.new_patients]],
      key: 'patient_type',
    }
  },
}
</script>
