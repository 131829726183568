
export const headerRow = {
  entryId: '№',
  orderId: t('order'),
  clientFullName: t('client'),
  clientBirthDate: t('age'),
  clientEmkNumber: t('emkNumber'),
  entryDate: t('custom_date'),
  performers: t('executor'),
  entryTitle: t('title'),
  entryAmount: t('amount_short'),
  entrySum: t('sum'),
  entryDiscountValue: t('discount'),
  entryDiscountType: t('exports_page.entry.discountType'),
  entryFinalSum: t('pay'),
  entryPaidSum: t('reports.paid_for'),
  byDms: t('reception.by_dms'),
  receiptRecordsCount: t('reports.receipt_records_count'),
  documentNumber: t('exports_page.activity.reports.documentNumber'),
  documentCreatedAt: t('exports_page.activity.reports.documentCreatedAt'),
}

export const totalRow = {
  total: t('total'),
}
