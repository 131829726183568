const createDefaultCompanyType = () => ({
  title: '',
})

const getDefaultValidattionMessages = () => ({
  title: [],
})

export const creators = Object.freeze({
  formDataCreator: createDefaultCompanyType,
  validationMessagesCreator: getDefaultValidattionMessages,
})

export const PAGINATION_LIMIT = 20

export const ERRORS_COMPANIES_LIMIT = 6
