<template>
  <div class="treatment-plans-reports flex flex-column report-filters">
    <!--    Клиники   -->
    <reusable-doctor-clinics-list
      :value="report.filtersData.clinic"
      full-width
      :m-fixed-height="false"
      validator-name="clinic"
      @registerValidator="onRegisterValidator"
      @onClinicChange="report.setFilterValue('clinic', $event)"
    />

    <!--    Период   -->
    <div class="report-filters__filter_periodFilter">
      <period-filter
        :value="report.filtersData.period"
        :m-fixed-height="false"
        value-format="yyyy-MM-dd"
        @change="report.setFilterValue('period', $event)"
      />
    </div>

    <!--    Виды   -->
    <m-select
      :value="report.filtersData.treatmentPlanKinds"
      :m-fixed-height="false"
      :items="treatmentPlansTypes"
      :label="t('type')"
      :placeholder="t('all_types')"
      full-width
      use-icon
      validator-name="treatmentPlanKinds"
      @registerValidator="onRegisterValidator"
      @change="report.setFilterValue('treatmentPlanKinds', $event)"
    />

    <!--    Статус   -->
    <m-select
      :value="report.filtersData.treatmentPlanStatuses"
      :m-fixed-height="false"
      :items="treatmentPlansStatuses"
      :label="t('status')"
      :placeholder="t('all_statuses')"
      use-icon
      full-width
      multiple
      use-custom-result="simple"
      validator-name="treatmentPlanStatuses"
      @registerValidator="onRegisterValidator"
      @changeItem="report.setFilterValue('treatmentPlanStatuses', $event)"
    />

    <!-- Исполнитель -->
    <reusable-doctors-list
      :value="report.filtersData.doctor"
      :label="t('performer')"
      full-width
      validator-name="doctor"
      @registerValidator="onRegisterValidator"
      @onDoctorChange="report.setFilterValue('doctor', $event)"
    />

    <!-- Ответственный -->
    <reusable-doctors-list
      :value="report.filtersData.responsible"
      :label="t('responsible_user')"
      full-width
      validator-name="responsible"
      @registerValidator="onRegisterValidator"
      @onDoctorChange="report.setFilterValue('responsible', $event)"
    />

    <!-- Кнопочки -->
    <report-filters-buttons
      :loading="report.loading"
      @onReportClick="onReportClick"
      @onResetClick="onResetClick"
    />

    <!-- Шаблон -->
    <filters-profiles-app
      class="treatment-plans-reports__filters-app"
      :profile-type="FILTERS_PROFILES_TYPES.TREATMENT_PLANS_REPORT"
      :filters-map="report.filtersData"
      @onProfileSelect="onFiltersProfileSelect"
    />
  </div>
</template>

<script>
import { TreatmentPlansReport } from '@/vue_apps/ReportsModule/TreatmentPlansReports/classes/TreatmentPlansReport'
import ReusableDoctorClinicsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorClinicsList.vue'
import PeriodFilter from '@/vue_apps/ReportsModule/_filters/components/PeriodFilter.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import {
  treatmentPlansStatuses,
  treatmentPlansTypes,
} from '@/vue_apps/TreatmentPlans/TreatmentPlansList/const/treatmentPlansFilters'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import ReportFiltersButtons from '@/vue_apps/ReportsModule/_filters/components/ReportFiltersButtons.vue'
import { createVueApp } from '@/vue_components/create_vue_app'
import TreatmentPlansReportsContainer
  from '@/vue_apps/ReportsModule/TreatmentPlansReports/TreatmentPlansReportsContainer.vue'
import { REPORT_EVENTS } from '@/vue_apps/ReportsModule/_filters/const/events'
import FiltersProfilesApp from '@/vue_apps/FiltersProfilesApp/FiltersProfilesApp.vue'
import { FILTERS_PROFILES_TYPES } from '@/vue_apps/FiltersProfilesApp/const/consts'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

export default {
  name: 'TreatmentPlansReports',
  components: {
    ReportFiltersButtons,
    ReusableDoctorsList,
    MSelect,
    PeriodFilter,
    ReusableDoctorClinicsList,

    FiltersProfilesApp,
  },

  mixins: [ValidationParentMixin],

  data () {
    return {
      report: new TreatmentPlansReport(),
      treatmentPlansStatuses,
      treatmentPlansTypes,

      containerApp: null,

      FILTERS_PROFILES_TYPES,
    }
  },

  created () {
    this.containerApp = createVueApp({
      el: '#report_right_container',
      name: 'TreatmentPlansReportsContainerApp',
      render: (h) => h(TreatmentPlansReportsContainer, {
        props: {
          report: this.report,
        },
      }),
    })

    PubSub.on(REPORT_EVENTS.EXPORT, () => { this.report.export() })
  },

  beforeDestroy () {
    PubSub.unsubscribe(REPORT_EVENTS.EXPORT)
  },

  methods: {
    onReportClick () {
      this.report.fetchAll().then()
    },

    onResetClick () {
      this.report.resetFilters()
      this.report.resetData()
      this.resetValidations()
    },

    onFiltersProfileSelect ({ filters, errors }) {
      this.report.resetFilters(filters)
      this.$nextTick(() => this.resetValidations(errors, true))
    },
  },
}
</script>
