<template>
  <si-table>
    <template v-slot:header>
      <si-field class="appointment-types-table__header">
        <div class="catalogs_table__title">
          <span>
            {{ t('reception.appointment_type') }}
          </span>
        </div>

        <div class="appointment-types-table__color-title">
          <span>
            {{ t('reception.appointment_type_color') }}
          </span>
        </div>
      </si-field>
    </template>

    <template v-slot:body>
      <si-field
        v-for="type in appointmentTypes"
        :key="type.id"
        :class="{ active: type.id === selectedListItem }"
        class="catalogs_table__item"
        @click="$emit('ask-edit', type)"
      >
        <div class="catalogs_table__title">
          <div class="appointment-types-table__default-icon">
            <basic-tooltip
              v-if="type.default"
              :text="t('default_value')"
              placement="top"
            >
              <span
                class="fad fa-fw fa-check-circle default-value"
                :class="{ transparent: !type.default }"
              />
            </basic-tooltip>
          </div>

          <span class="fad fa-fw fa-calendar-alt catalogs_table__icon" />

          <span class="cut">
            {{ type.title }}
          </span>
        </div>

        <div class="appointment-types-table__color-title">
          <div
            class="appointment-types-table__color"
            :style="{ backgroundColor: type.color }"
          />
        </div>
      </si-field>
    </template>
  </si-table>
</template>

<script>
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import BasicTooltip from '@/vue_components/common/basic_tooltip'

export default {
  name: 'AppointmentTypesTable',
  components: {
    SiTable,
    SiField,
    BasicTooltip,
  },

  props: {
    appointmentTypes: {
      type: Array,
      default: () => [],
    },

    selectedListItem: {
      type: Number,
      default: 0,
    },
  },
}
</script>
