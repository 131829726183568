import { permissionGuard } from '@/vue_components/mixins/permission_guard'

/**
 * Компонент для условной отрисовки других компонентов на основе прав пользователя.
 * Отделяет сокрытие компонентов согласно логике их работы от сокрытия по причине
 * отсутствия прав. Позволяет не писать вычисляемые свойства для проверок прав.
 */
export default {
  name: 'GuardedView',

  mixins: [permissionGuard],

  render () {
    return this.disabled
      ? null
      : this.$slots.default
  },
}
