function getRandomInt (min, max) {
  // модифицированный метод
  // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Math/random
  min = Math.ceil(min)
  max = Math.floor(max + 1)

  return Math.floor(Math.random() * (max - min)) + min
}

/**
 * @typedef {{
 *   client_comment: string,
 *   general_assessment_of_admission: number,
 *   id: number,
 *   net_promoter_score: number,
 *   received_at: number,
 *   responsible_user_comment: string | null,
 *   responsible_user_id: number | null,
 *   status: string,
 *   user: {
 *     id: number,
 *     name: string,
 *     second_name: string,
 *     surname: string
 *   }
 * }} Feedback
 */

/**
 * метод генерирущий отзывы
 * @return {{client_feedbacks: Feedback[]}}
 */
export function getFakeFeedbacks () {
  const feedbacksCount = 4
  const data = {
    client_feedbacks: [],
  }

  for (let i = 0; i < feedbacksCount; i++) {
    data.client_feedbacks.push(
      {
        client_comment: T.head_desktop.fake_feedbacks.feedbacks[i],
        general_assessment_of_admission: getRandomInt(8, 10),
        id: i + 1,
        net_promoter_score: getRandomInt(8, 10),
        received_at: `2020-0${i + 1}-0${i + 2}T15:19:48.693+03:00`,
        responsible_user_comment: null,
        responsible_user_id: null,
        status: 'client_feedback_received',
        user: {
          id: (i + 1) * 10,
          name: T.head_desktop.fake_feedbacks.doctors[i].name,
          second_name: T.head_desktop.fake_feedbacks.doctors[i].second_name,
          surname: T.head_desktop.fake_feedbacks.doctors[i].surname,
        },
      },
    )
  }

  return data
}

/**
 * метод возвращающий данные NPS
 * @return {{nps_dynamics: [string, number][]}}
 */
export function getFakeNPSData () {
  return {
    nps_dynamics: [
      ['2020-10-01', 0],
      ['2020-10-02', 50],
      ['2020-10-03', 35],
      ['2020-10-04', 75],
      ['2020-10-05', 60],
      ['2020-10-06', 100],
    ],
  }
}

/**
 * метод, возвращающий данные Звонков
 * @return {{call: [string, number][], current_month: number, today: number, average_month: number}}
 */
export function getFakeCallData () {
  return {
    call: [
      ['2020-10-01', 513],
      ['2020-10-02', 518],
      ['2020-10-03', 550],
      ['2020-10-04', 558],
      ['2020-10-05', 570],
    ],
    today: 570,
    current_month: 2709,
    average_month: 542,
  }
}

/**
 * метод возвращающий данные Конверсии звонков
 * @return {{call_conversion: [string, number][], current_month: number, today: string, average_month: string}}
 */
export function getFakeCallConversionData () {
  // тоже самое, что и в getFakeCallData (), но в %
  // 100% это currentMonth
  const callData = getFakeCallData()

  return {
    call_conversion: callData.call.map((item) => [item[0], item[1] / callData.current_month * 100]),
    today: (callData.call[callData.call.length - 1][1] / callData.current_month * 100).toFixed(0),
    current_month: 100,
    average_month: (callData.average_month / callData.current_month * 100).toFixed(0),
  }
}
