<template>
  <ul class="specialties-list">
    <li
      :class="{selected: selectedSpecialtyId === 0}"
      class="specialty cut"
      data-id="0"
      @click="$emit('select-specialty', $event)"
    >
      <span>{{ t('all') }}</span>
      <span
        class="hide-specialties"
        @click.stop="$emit('toggle-show')"
      >
        <span
          v-if="showSpecialtiesList"
          class="specialty-list-icon-minimize fad fa-fw fa-chevron-down"
        />
        <span
          v-else
          class="specialty-list-icon-unminimize fad fa-fw fa-chevron-right"
        />
      </span>
    </li>

    <li
      v-for="specialty in visibleSpecialties"
      :key="specialty.id"
      :class="{selected: selectedSpecialtyId === specialty.id}"
      :data-id="specialty.id"
      class="specialty"
      @click="$emit('select-specialty', $event)"
    >
      <div class="cut">
        {{ specialty.title }}
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SpecialtyList',

  props: {
    selectedSpecialtyId: {
      type: Number,
      default: 0,
    },

    showSpecialtiesList: Boolean,

    visibleSpecialties: {
      type: Array,
      default: () => ([]),
    },
  },

}
</script>
