import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class Tax2024ListItem extends MListServiceItem {
  /**
   * @param {{
   *   createdAt: string,
   *   id: number,
   *   number: number,
   *   title: string
   * }} data
   */
  constructor (data) {
    super()
    this.id = data.id

    this.date = new MTableCell(vueFilters.date(data.createdAt))

    this.number = new MTableCell(data.number)

    this.title = new MTableCell(data.title)
      .addCut()
      .addTooltip(true)

    this.print = new MTableCell('print')
      .addTypeIcon('print')
      .addTooltip(t('print'))
      .addPreventDefault()
  }
}
