<template>
  <m-date-picker
    type="daterange"
    v-bind="$attrs"
    :label="label"
    :clearable="false"
    v-on="$listeners"
  />
</template>

<script>
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'

export default {
  name: 'PeriodFilter',
  components: { MDatePicker },
  computed: {
    label () {
      return typeof this.$attrs.label === 'string'
        ? this.$attrs.label
        : t('period')
    },
  },
}
</script>
