<template>
  <div id="entry_type_selector">
    <el-autocomplete
      ref="searchInput"
      v-model="inputValue"
      :fetch-suggestions="searchMethod"
      :placeholder="T.laboratories.find_entry_type_by_title_or_code"
      :trigger-on-focus="false"
      :highlight-first-item="true"
      :disabled="searchDisabled"
      type="string"
      class="inline-input"
      popper-class="entry-types-suggestion-list"
      @select="handleSelect"
    >
      <template slot-scope="{ item }">
        <div
          v-if="item.message"
          class="message"
        >
          <span
            v-tooltip="item.title"
            class="title cut"
          >
            {{ item.title }}
          </span>
        </div>
        <div
          v-else
          class="suggestion"
        >
          <span
            v-if="item.number"
            v-tooltip="item.number"
            class="number cut"
          >
            {{ item.number }}
          </span>
          <span
            v-tooltip="item.title"
            class="title cut"
          >
            {{ item.title }}
          </span>
          <span
            v-if="item.price"
            class="price"
          >
            {{ item.price }} {{ currencySign }}
          </span>
        </div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'EntryTypeSelector',
  props: {
    searchMethod: {
      default: undefined,
      type: Function,
    },
    searchDisabled: {
      default: false,
      type: Boolean,
    },
  },

  data: () => ({
    inputValue: '',
    currencySign: window.Localization.currency.symbol_character,
  }),

  methods: {
    handleSelect (item) {
      if (item.message) return

      this.$emit('entry-type-selected', item)
      this.inputValue = ''
      this.$refs.searchInput.focus()
    },
  },
}
</script>
