<template>
  <si-field>
    <div class="referral">
      {{ t('reports.total') }}
    </div>
    <div class="goods">
      {{ $filters.currency(footer.goodsReward) }}
    </div>
    <div class="analysis">
      {{ $filters.currency(footer.analysisReward) }}
    </div>
    <div class="services">
      {{ $filters.currency(footer.servicesReward) }}
    </div>
    <div class="total">
      {{ $filters.currency(footer.totalReward) }}
    </div>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import { ReferralFooter } from '@/vue_components/reports/referrals/footer/referralFooter'

export default {
  name: 'ReferralsFooterConsolidated',
  components: { SiField },
  mixins: [ReferralFooter],
}
</script>
