<template>
  <div
    id="scheduler_container"
    class="main-container schedule-container"
  >
    <left-sidebar
      :context-ready="GET_CONTEXT_READY"
      :current-user-id.sync="currentUserId"
      :users="users"
      :cabinets="cabinets"
      :specialties="specialties"
      :filter.sync="filter"
      :filtered-user-ids.sync="filteredUserIds"
      :user-ids.sync="userIds"
      :cabinet-ids.sync="cabinetIds"
      :available-cabinets="availableCabinets"
      :one-visible-user="oneVisibleUser"
      :one-visible-cabinet="oneVisibleCabinet"
      :show-appointment="showAppointment"
      :waiting-count.sync="waitingCount"
      :waiting-user-from-grid.sync="waitingUserFromGrid"
      @clear-filter-entries="clearFilterEntries"
    />

    <doctor-schedules-container
      :context-ready="GET_CONTEXT_READY"
      :clinics="GET_CURRENT_USER_CLINICS_LIST"
      :users="users"
      :specialties="specialties"
      :available-cabinets.sync="availableCabinets"
      :user-ids="userIds"
      :cabinet-ids="cabinetIds"
      :focus-on-appointment.sync="focusOnAppointment"
      :one-visible-user.sync="oneVisibleUser"
      :one-visible-cabinet.sync="oneVisibleCabinet"
      :reserved-appointment-show="reservedAppointmentShow"
      :reserved-appointment="reservedAppointment"
      :waiting-count="waitingCount"
      :waiting-user-from-grid.sync="waitingUserFromGrid"
      @show-appointment-in-schedule="showAppointmentInSchedule"
      @create-appointment-in-schedule="createAppointmentInSchedule"
      @create-appointment-modal-of-grid="openCreateAppointmentForm"
      @edit-appointment-modal-of-grid="loadEditAppointmentForm"
      @create-reminder-of-grid="openCreateReminderItemForm"
      @choose-last-appointment-client="chooseLastAppointmentClient"
      @clear-filter-entries="clearFilterEntries"
      @move-appointment-from-waiting-list="moveAppointmentFromWaitingList"
    />

    <right-sidebar
      :context-ready="GET_CONTEXT_READY"
      :current-user-id="currentUserId"
      :filter="filter"
      :user-ids="userIds"
      :cabinet-ids="cabinetIds"
      :current-clinic-id="$store.getters.GET_SCHEDULE_CURRENT_CLINIC_ID"
      @edit-appointment-of-online="loadEditAppointmentForm"
      @show-appointment-in-schedule="showAppointmentInSchedule"
      @create-reminder-item="openCreateReminderItemForm"
      @edit-reminder-item="openEditReminderItemForm"
      @create-waiting-item="openCreateWaitingItemForm"
      @edit-waiting-item="openEditWaitingItemForm"
      @clear-filter-entries="clearFilterEntries"
    />

    <modal
      v-if="modal.userSelect.visibility"
      modal-class="doctor-schedules-select-user-modal"
      :modal-visibility="modal.userSelect.visibility"
      @close="modal.userSelect.rejectEvent"
    >
      <span slot="header">{{ T.select_doctor }}</span>
      <items-list
        slot="body"
        v-model="modal.userSelect.selectedUserId"
        v-bind="userListProps"
      />
      <template slot="footer-right">
        <button
          class="btn btn-sm btn-success btn-with-icon modal-save"
          @click="modal.userSelect.applyEvent(modal.userSelect.selectedUserId[0])"
        >
          <span class="btn-with-icon_icon fad fa-fw  fa-check" />
          <span class="btn-with-icon_text">{{ t('yes1') }}</span>
        </button>
        <button
          class="btn btn-sm btn-primary btn-with-icon modal-close"
          @click="modal.userSelect.rejectEvent()"
        >
          <span class="btn-with-icon_icon fad fa-fw  fa-times" />
          <span class="btn-with-icon_text">{{ t('no1') }}</span>
        </button>
      </template>
    </modal>

    <modal
      v-if="modal.createWorkTime.visibility"
      :modal-class="'doctor-schedules-create-work-time-modal'"
      :modal-visibility="modal.createWorkTime.visibility"
      modal-size="md"
      @close="modal.createWorkTime.rejectEvent()"
    >
      <span slot="header">{{ modal.createWorkTime.headerMessage }}</span>
      <div slot="body">
        <div class="schedule-form-item">
          <div class="main-message">
            <span>{{ modal.createWorkTime.message }}</span>
          </div>
          <div
            v-if="!modal.createWorkTime.onlyCreate"
            class="action-select"
          >
            <items-list
              v-model="modal.createWorkTime.action"
              :items="[
                {id: CREATE_WORK_TIME, title: T.working},
                {id: REMOVE_WORK_TIME, title: T.not_working},
              ]"
              :multi-select="false"
              id-item-field="id"
              displayed-item-field="title"
              :min-selected-items="1"
            />
          </div>
        </div>
        <div
          v-if="modal.createWorkTime.action[0] === CREATE_WORK_TIME"
          class="schedule-form-item"
        >
          <div class="schedule-form-left">
            <label>
              <span>{{ t('cabinet') }}</span>
            </label>
          </div>
          <div class="schedule-form-right">
            <el-select
              v-model="modal.createWorkTime.cabinetId"
              clearable
              :placeholder="t('without_cabinet')"
              :no-data-text="t('no_cabinet_created')"
            >
              <el-option
                v-for="cabinet in cabinetsWithId"
                :key="cabinet.id"
                :label="cabinet.title"
                :value="cabinet.id"
              />
            </el-select>
          </div>
        </div>
      </div>
      <template slot="footer-right">
        <button
          class="btn btn-sm btn-success btn-with-icon modal-save"
          @click="modal.createWorkTime.applyEvent(workTimeResponse)"
        >
          <span class="btn-with-icon_icon fad fa-fw  fa-check" />
          <span class="btn-with-icon_text">{{ t('apply') }}</span>
        </button>
        <button
          class="btn btn-sm btn-primary btn-with-icon modal-close"
          @click="modal.createWorkTime.rejectEvent()"
        >
          <span class="btn-with-icon_icon fad fa-fw  fa-times" />
          <span class="btn-with-icon_text">{{ t('cancel') }}</span>
        </button>
      </template>
    </modal>

    <modal
      v-if="modal.copyAppointmentWithServices.visibility"
      modal-class="doctor-schedules-copy-appointment-with-services"
      :modal-visibility="modal.copyAppointmentWithServices.visibility"
      @close="modal.copyAppointmentWithServices.rejectEvent()"
    >
      <span slot="header">{{ t('reception.copying_an_appointment') }}</span>
      <span slot="body">{{ t('reception.appointment_copy_with_services') }}</span>
      <template slot="footer-right">
        <button
          class="btn btn-sm btn-success btn-with-icon modal-save"
          @click="modal.copyAppointmentWithServices.yesEvent()"
        >
          <span class="btn-with-icon_icon fad fa-fw  fa-check" />
          <span class="btn-with-icon_text">{{ t('yes1') }}</span>
        </button>
        <button
          class="btn btn-sm btn-warning btn-with-icon modal-close"
          @click="modal.copyAppointmentWithServices.noEvent()"
        >
          <span class="btn-with-icon_icon fad fa-fw fa-info-circle" />
          <span class="btn-with-icon_text">{{ t('no1') }}</span>
        </button>
        <button
          class="btn btn-sm btn-primary btn-with-icon modal-close"
          @click="modal.copyAppointmentWithServices.rejectEvent()"
        >
          <span class="btn-with-icon_icon fad fa-fw fa-times" />
          <span class="btn-with-icon_text">{{ t('cancel') }}</span>
        </button>
      </template>
    </modal>

    <modal
      v-if="modal.lastAppointment.visibility"
      id="last-appointments-modal"
      :modal-visibility="modal.lastAppointment.visibility"
      modal-class="modal-xl"
      @close="onLastAppointmentsClose"
    >
      <template #header>
        <span class="fad fa-tasks" />
        {{ t('reception.last_appointments') }}
      </template>

      <template #body>
        <last-appointments
          :client="modal.lastAppointment.client"
          :appointments="modal.lastAppointment.appointments"
          :selected-appointments.sync="modal.lastAppointment.selectedAppointments"
          :need-clinic-info="needClinicInfo"
          @close-modal="onLastAppointmentsClose"
          @create="createAppointmentFromLastAppointments"
          @move="moveLastAppointment"
          @edit="loadEditAppointmentForm"
          @cancel="cancelLastAppointment"
        />
      </template>

      <template #footer-left>
        <button
          class="btn btn-warning btn-with-icon modal-close"
          :disabled="!modal.lastAppointment.selectedAppointments.length"
          @click="createLastAppointmentsOrder"
        >
          <span class="btn-with-icon_icon fad fa-cart-plus" />
          <span class="btn-with-icon_text">
            {{ t('add_order') }}
          </span>
        </button>
      </template>

      <template #footer-right>
        <button
          :disabled="modal.lastAppointment.loading"
          class="btn btn-primary btn-with-icon modal-close"
          type="button"
          @click="onLastAppointmentsClose"
        >
          <span class="btn-with-icon_icon fad fa-times" />
          <span class="btn-with-icon_text">
            {{ t('close') }}
          </span>
        </button>
      </template>
    </modal>

    <appointment-create-modal
      v-if="modal.createAppointment.visibility"
      :visibility="modal.createAppointment.visibility"
      :loading="modal.createAppointment.loading"
      :disable-buttons="modalHasErrors('createAppointment')"
      :disabled="modal.createAppointment.disabled"
      :errors="modal.createAppointment.validationMessages"
      :validation-messages="modal.createAppointment.validationMessages"
      :cleared="modal.createAppointment.cleared"
      :doctor="modal.formData.doctor"
      :date="modal.createAppointment.form.date"
      :time="modal.createAppointment.form.time"
      :clinic-id="$store.getters.GET_SCHEDULE_CURRENT_CLINIC_ID"
      :forced-client-edit.sync="modal.createAppointment.forcedClientEdit"

      :sexes="modal.formData.sexes"
      :online-access-options="modal.formData.onlineAccessOptions"
      :client-groups="GET_CLIENT_GROUPS"
      :full-address.sync="modal.formData.address.searchQuery"
      :document-types="GET_DOCUMENT_TYPES"
      :client-summary="modal.formData.clientSummary"
      :similar-clients="modal.formData.similarClients.clients"
      :similar-clients-selected="modal.formData.similarClients.selected"
      :client-id.sync="modal.createAppointment.form.clientId"
      :client-edited.sync="modal.createAppointment.clientEdited"

      :second-name.sync="modal.createAppointment.form.client.secondName"
      :name.sync="modal.createAppointment.form.client.name"
      :surname.sync="modal.createAppointment.form.client.surname"
      :birthdate.sync="modal.createAppointment.form.client.birthdate"
      :phone.sync="modal.createAppointment.form.client.phone"
      :sex.sync="modal.createAppointment.form.client.sex"
      :entry-online-access.sync="modal.createAppointment.form.client.onlineAccess"
      :groups.sync="modal.createAppointment.form.client.groups"
      :email="modal.createAppointment.form.client.email"
      :treating-doctor.sync="modal.createAppointment.form.client.treatingDoctor"
      :personal-discount.sync="modal.createAppointment.form.client.personalDiscount"
      :legal-representatives.sync="modal.createAppointment.form.client.legalRepresentatives"
      :additional.sync="modal.createAppointment.form.client.additional"
      :service-card.sync="modal.createAppointment.form.client.serviceCard"
      :send-data-to-egisz.sync="modal.createAppointment.form.client.sendDataToEgisz"
      :patient-card-number.sync="modal.createAppointment.form.client.patientCardNumber"

      :index.sync="modal.createAppointment.form.client.index"
      :country.sync="modal.createAppointment.form.client.country"
      :region.sync="modal.createAppointment.form.client.region"
      :nsi-russian-subject-id.sync="modal.createAppointment.form.client.nsiRussianSubjectId"
      :area.sync="modal.createAppointment.form.client.area"
      :city.sync="modal.createAppointment.form.client.city"
      :street.sync="modal.createAppointment.form.client.street"
      :house.sync="modal.createAppointment.form.client.house"
      :flat.sync="modal.createAppointment.form.client.flat"
      :document-type.sync="modal.createAppointment.form.client.documentType"
      :document-series.sync="modal.createAppointment.form.client.documentSeries"
      :document-number.sync="modal.createAppointment.form.client.documentNumber"
      :who-issue.sync="modal.createAppointment.form.client.whoIssue"
      :issue-date.sync="modal.createAppointment.form.client.issueDate"
      :company.sync="modal.createAppointment.form.client.company"
      :position.sync="modal.createAppointment.form.client.position"
      :nsi-profession.sync="modal.createAppointment.form.client.nsiProfession"
      :department.sync="modal.createAppointment.form.client.department"
      :oms.sync="modal.createAppointment.form.client.oms"
      :inn.sync="modal.createAppointment.form.client.inn"
      :snils.sync="modal.createAppointment.form.client.snils"
      :no-sms.sync="modal.createAppointment.form.client.noSms"
      :no-whats-app.sync="modal.createAppointment.form.client.noWhatsApp"
      :no-sms-dispatches.sync="modal.createAppointment.form.client.noSmsDispatches"
      :no-calls.sync="modal.createAppointment.form.client.noCalls"
      :no-email.sync="modal.createAppointment.form.client.noEmail"

      :service-search-query.sync="modal.formData.service.searchQuery"
      :services-search-result-array.sync="modal.formData.service.list"
      :service-search-allow-search="allowEntryTypesSearch"
      :service-not-results="entryTypesNotResults"
      :service-search-loading="modal.formData.service.loading"
      :currency-symbol="GET_LOCALIZATION_CURRENCY_SYMBOL"
      :currency-format="GET_LOCALIZATION_CURRENCY_PARAMS"
      :durations-array="GET_APPOINTMENT_DURATIONS_ITEMS"
      :max-duration="modal.formData.maxDuration"
      :appointment-types-array="GET_APPOINTMENT_TYPES"
      :appointment-type-id-default="GET_APPOINTMENT_TYPE_ID_DEFAULT"
      :attraction-source-array="GET_ATTRACTION_SOURCES"

      :entry-types.sync="modal.createAppointment.form.appointment.entryTypes"
      :note.sync="modal.createAppointment.form.appointment.note"
      :client-comment.sync="modal.createAppointment.form.appointment.clientComment"
      :duration="modal.createAppointment.form.appointment.duration"
      :appointment-type-id.sync="modal.createAppointment.form.appointment.appointmentTypeId"
      :referral.sync="modal.createAppointment.form.appointment.referral"
      :attraction-source-id.sync="modal.createAppointment.form.appointment.attractionSourceId"
      :by-dms.sync="modal.createAppointment.form.appointment.byDms"
      :appointment-status.sync="modal.createAppointment.form.status"
      :add-to-waiting-list.sync="modal.createAppointment.form.appointment.addToWaitingList"
      :sms-remind-option.sync="GET_SMS_CONFIRMATION_DEFAULT"
      :sms-notify-option.sync="GET_SMS_NOTIFY_DEFAULT"
      :sms-confirmation-option.sync="GET_SMS_REMIND_DEFAULT"
      :uis-remind-option.sync="GET_APP_CONF.telephony.uis_config.reminder"
      :uis-confirm-option.sync="GET_APP_CONF.telephony.uis_config.confirmation"
      :remind-before-date.sync="modal.formData.remindBeforeDate"
      :notify-after-save.sync="modal.formData.notifyAfterSave"
      :send-sms-confirmation.sync="modal.formData.sendSmsConfirmation"
      :remind-before-date-stub-modal-visible.sync="modal.formData.remindBeforeDateStubModalVisible"
      :notify-after-save-stub-modal-visible.sync="modal.formData.notifyAfterSaveStubModalVisible"
      :send-sms-confirmation-stub-modal-visible.sync="modal.formData.sendSmsConfirmationStubModalVisible"
      :remind-by-call-stub-modal-visible.sync="modal.formData.remindByCallStubModalVisible"
      :confirm-by-call-stub-modal-visible.sync="modal.formData.confirmByCallStubModalVisible"
      :remind-by-call.sync="modal.createAppointment.form.appointment.remindByCall"
      :confirm-by-call.sync="modal.createAppointment.form.appointment.confirmByCall"
      :last-sms-confirmation.sync="modal.createAppointment.form.appointment.lastSmsConfirmation"
      :last-sms-notify.sync="modal.createAppointment.form.appointment.lastSmsNotify"
      :last-sms-remind.sync="modal.createAppointment.form.appointment.lastSmsRemind"
      :appointment-source="appointmentSourceCreate"
      :created-at="modal.createAppointment.form.appointment.createdAt"
      :created-by="modal.createAppointment.form.appointment.createdBy"
      :updated-at="modal.createAppointment.form.appointment.updatedAt"
      :updated-by="modal.createAppointment.form.appointment.updatedBy"
      @submit-form="submitCreateAppointmentForm"
      @clear-form="clearCreateAppointmentForm"
      @close-modal="closeCreateAppointmentForm"
      @add-legal-representative="openModal(MODAL_TYPES.LEGAL_REPRESENTATIVE)"
      @add-company="openModal(MODAL_TYPES.COMPANY)"
      @add-referral="openModal(MODAL_TYPES.CREATE_REFERRAL)"
      @update:similar-clients-selected="modal.formData.similarClients.selected = $event;
                                        modal.formData.similarClients.currentModal = MODAL_TYPES.CREATE_APPOINTMENT"
      @update:duration="updateAppointmentDuration"
      @update:email="modal.createAppointment.form.client.email = $event; modal.createAppointment.validationMessages.email = []"
      @demo-client="showDemoClient($event, 'modal.createAppointment')"
      @phone-mask-settings-change="modal.createAppointment.clientPhoneMaskSettings = $event"
    />

    <appointment-edit-modal
      v-if="modal.editAppointment.visibility"
      :visibility="modal.editAppointment.visibility"
      :loading="modal.editAppointment.loading"
      :disable-buttons="modalHasErrors('editAppointment')"
      :disabled="modal.editAppointment.disabled"
      :errors="modal.editAppointment.validationMessages"
      :validation-messages="modal.editAppointment.validationMessages"
      :cleared="modal.editAppointment.cleared"
      :doctor="modal.formData.doctor"
      :date="modal.editAppointment.form.date"
      :time="modal.editAppointment.form.time"
      :appointment-id="modal.editAppointment.form.appointmentId"
      :clinic-id="$store.getters.GET_SCHEDULE_CURRENT_CLINIC_ID"
      :need-clinic-info="needClinicInfo"
      :forced-client-edit.sync="modal.editAppointment.forcedClientEdit"

      :sexes="modal.formData.sexes"
      :online-access-options="modal.formData.onlineAccessOptions"
      :client-groups="GET_CLIENT_GROUPS"
      :full-address.sync="modal.formData.address.searchQuery"
      :document-types="GET_DOCUMENT_TYPES"
      :client-summary="modal.formData.clientSummary"
      :similar-clients="modal.formData.similarClients.clients"
      :similar-clients-selected="modal.formData.similarClients.selected"
      :client-id.sync="modal.editAppointment.form.clientId"
      :client-edited.sync="modal.editAppointment.clientEdited"

      :second-name.sync="modal.editAppointment.form.client.secondName"
      :name.sync="modal.editAppointment.form.client.name"
      :surname.sync="modal.editAppointment.form.client.surname"
      :birthdate.sync="modal.editAppointment.form.client.birthdate"
      :phone.sync="modal.editAppointment.form.client.phone"
      :sex.sync="modal.editAppointment.form.client.sex"
      :entry-online-access.sync="modal.editAppointment.form.client.onlineAccess"
      :groups.sync="modal.editAppointment.form.client.groups"
      :email="modal.editAppointment.form.client.email"
      :treating-doctor.sync="modal.editAppointment.form.client.treatingDoctor"
      :personal-discount.sync="modal.editAppointment.form.client.personalDiscount"
      :legal-representatives.sync="modal.editAppointment.form.client.legalRepresentatives"
      :additional.sync="modal.editAppointment.form.client.additional"
      :service-card.sync="modal.editAppointment.form.client.serviceCard"
      :send-data-to-egisz.sync="modal.editAppointment.form.client.sendDataToEgisz"
      :patient-card-number.sync="modal.editAppointment.form.client.patientCardNumber"

      :index.sync="modal.editAppointment.form.client.index"
      :country.sync="modal.editAppointment.form.client.country"
      :region.sync="modal.editAppointment.form.client.region"
      :nsi-russian-subject-id.sync="modal.editAppointment.form.client.nsiRussianSubjectId"
      :area.sync="modal.editAppointment.form.client.area"
      :city.sync="modal.editAppointment.form.client.city"
      :street.sync="modal.editAppointment.form.client.street"
      :house.sync="modal.editAppointment.form.client.house"
      :flat.sync="modal.editAppointment.form.client.flat"
      :document-type.sync="modal.editAppointment.form.client.documentType"
      :document-series.sync="modal.editAppointment.form.client.documentSeries"
      :document-number.sync="modal.editAppointment.form.client.documentNumber"
      :who-issue.sync="modal.editAppointment.form.client.whoIssue"
      :issue-date.sync="modal.editAppointment.form.client.issueDate"
      :company.sync="modal.editAppointment.form.client.company"
      :position.sync="modal.editAppointment.form.client.position"
      :nsi-profession.sync="modal.editAppointment.form.client.nsiProfession"
      :department.sync="modal.editAppointment.form.client.department"
      :oms.sync="modal.editAppointment.form.client.oms"
      :inn.sync="modal.editAppointment.form.client.inn"
      :snils.sync="modal.editAppointment.form.client.snils"
      :no-sms.sync="modal.editAppointment.form.client.noSms"
      :no-whats-app.sync="modal.editAppointment.form.client.noWhatsApp"
      :no-sms-dispatches.sync="modal.editAppointment.form.client.noSmsDispatches"
      :no-calls.sync="modal.editAppointment.form.client.noCalls"
      :no-email.sync="modal.editAppointment.form.client.noEmail"

      :service-search-query.sync="modal.formData.service.searchQuery"
      :services-search-result-array.sync="modal.formData.service.list"
      :service-search-allow-search="allowEntryTypesSearch"
      :service-not-results="entryTypesNotResults"
      :service-search-loading="modal.formData.service.loading"
      :currency-symbol="GET_LOCALIZATION_CURRENCY_SYMBOL"
      :currency-format="GET_LOCALIZATION_CURRENCY_PARAMS"
      :durations-array="GET_APPOINTMENT_DURATIONS_ITEMS"
      :max-duration="modal.formData.maxDuration"
      :appointment-types-array="GET_APPOINTMENT_TYPES"
      :appointment-type-id-default="GET_APPOINTMENT_TYPE_ID_DEFAULT"
      :attraction-source-array="GET_ATTRACTION_SOURCES"

      :entry-types.sync="modal.editAppointment.form.appointment.entryTypes"
      :note.sync="modal.editAppointment.form.appointment.note"
      :client-comment.sync="modal.editAppointment.form.appointment.clientComment"
      :duration.sync="modal.editAppointment.form.appointment.duration"
      :appointment-type-id.sync="modal.editAppointment.form.appointment.appointmentTypeId"
      :referral.sync="modal.editAppointment.form.appointment.referral"
      :attraction-source-id.sync="modal.editAppointment.form.appointment.attractionSourceId"
      :by-dms.sync="modal.editAppointment.form.appointment.byDms"
      :appointment-status.sync="modal.editAppointment.form.status"
      :add-to-waiting-list.sync="modal.editAppointment.form.appointment.addToWaitingList"
      :sms-remind-option.sync="GET_SMS_CONFIRMATION_DEFAULT"
      :sms-notify-option.sync="GET_SMS_NOTIFY_DEFAULT"
      :sms-confirmation-option.sync="GET_SMS_REMIND_DEFAULT"
      :uis-remind-option.sync="GET_APP_CONF.telephony.uis_config.reminder"
      :uis-confirm-option.sync="GET_APP_CONF.telephony.uis_config.confirmation"
      :remind-before-date.sync="modal.formData.remindBeforeDate"
      :notify-after-save.sync="modal.formData.notifyAfterSave"
      :send-sms-confirmation.sync="modal.formData.sendSmsConfirmation"
      :remind-before-date-stub-modal-visible.sync="modal.formData.remindBeforeDateStubModalVisible"
      :notify-after-save-stub-modal-visible.sync="modal.formData.notifyAfterSaveStubModalVisible"
      :send-sms-confirmation-stub-modal-visible.sync="modal.formData.sendSmsConfirmationStubModalVisible"
      :remind-by-call-stub-modal-visible.sync="modal.formData.remindByCallStubModalVisible"
      :confirm-by-call-stub-modal-visible.sync="modal.formData.confirmByCallStubModalVisible"
      :remind-by-call.sync="modal.editAppointment.form.appointment.remindByCall"
      :confirm-by-call.sync="modal.editAppointment.form.appointment.confirmByCall"
      :last-sms-confirmation.sync="modal.editAppointment.form.appointment.lastSmsConfirmation"
      :last-sms-notify.sync="modal.editAppointment.form.appointment.lastSmsNotify"
      :last-sms-remind.sync="modal.editAppointment.form.appointment.lastSmsRemind"
      :appointment-source="appointmentSourceEdit"
      :created-by="modal.editAppointment.form.appointment.createdBy"
      :updated-by="modal.editAppointment.form.appointment.updatedBy"
      @submit-form="submitEditAppointmentForm"
      @clear-form="clearEditAppointmentForm"
      @close-modal="closeEditAppointmentForm"
      @add-legal-representative="openModal(MODAL_TYPES.LEGAL_REPRESENTATIVE)"
      @add-company="openModal(MODAL_TYPES.COMPANY)"
      @add-referral="openModal(MODAL_TYPES.CREATE_REFERRAL)"
      @update:similar-clients-selected="modal.formData.similarClients.selected = $event;
                                        modal.formData.similarClients.currentModal = MODAL_TYPES.EDIT_APPOINTMENT"
      @update:email="modal.editAppointment.form.client.email = $event; modal.editAppointment.validationMessages.email = []"
      @demo-client="showDemoClient($event, 'modal.editAppointment')"
      @phone-mask-settings-change="modal.editAppointment.clientPhoneMaskSettings = $event"
    />

    <waiting-create-modal
      v-if="modal.createWaitingItem.visibility"
      :client-id.sync="modal.createWaitingItem.clientId"
      :client-phone.sync="modal.createWaitingItem.clientPhone"
      :visibility.sync="modal.createWaitingItem.visibility"
      :client-groups="GET_CLIENT_GROUPS"
      :date="GET_SCHEDULE_DATE"
      :doctor="waitingListUserId"
      @close="closeModal(MODAL_TYPES.CREATE_WAITING_ITEM)"
      @add-legal-representative="openModal(MODAL_TYPES.LEGAL_REPRESENTATIVE)"
    />

    <waiting-edit-modal
      v-if="modal.editWaitingItem.visibility"
      :ref="MODAL_TYPES.EDIT_WAITING_ITEM"
      with-create-appointment
      with-move-appointment
      :visibility.sync="modal.editWaitingItem.visibility"
      :client-groups="GET_CLIENT_GROUPS"
      @close="closeModal(MODAL_TYPES.EDIT_WAITING_ITEM)"
      @add-legal-representative="openModal(MODAL_TYPES.LEGAL_REPRESENTATIVE)"
    />

    <create-legal-representative-modal
      v-if="modal.legal.visibility"
      :client-groups="GET_CLIENT_GROUPS"
      :document-types="GET_DOCUMENT_TYPES"
      @add-company="openModal(MODAL_TYPES.COMPANY)"
      @close="closeModal(MODAL_TYPES.LEGAL_REPRESENTATIVE)"
      @create="addLegalRepresentative"
      @phone-mask-settings-change="modal.legal.clientPhoneMaskSettings = $event"
    />

    <modal
      v-if="modal.referral.visibility"
      :modal-visibility="modal.referral.visibility"
      :modal-header-buttons="modalButtonsAll"
      @close="closeReferralForm"
    >
      <template #header>
        <span class="fad fa-user-md-chat" />
        {{ t('referral') }}
      </template>

      <template #body>
        <referrals-form
          :disabled="modal.referral.disabled"
          :clinic-id="GET_SCHEDULE_CURRENT_CLINIC_ID"
          :loading="modal.referral.loading"
          :errors="modal.referral.validationMessages"
          :surname.sync="modal.referral.form.surname"
          :name.sync="modal.referral.form.name"
          :second-name.sync="modal.referral.form.secondName"
          :phone.sync="modal.referral.form.phone"
          :email.sync="modal.referral.form.email"
          :company.sync="modal.referral.form.company"
          :specialty.sync="modal.referral.form.specialtyIds"
          :medical-doctor.sync="modal.referral.form.medicalDoctor"
          :code.sync="modal.referral.form.code"
          :additional.sync="modal.referral.form.about"
          :specialty-name.sync="modal.formData.specialties.searchQuery"
          :allow-specialty-search="allowSpecialtySearch"
          :specialty-loading="modal.formData.specialties.loading"
          :specialty-not-results="specialtyNotResults"
          :specialties="modal.formData.specialties.list"
          :full-address.sync="modal.formData.address.searchQuery"
          :select-address="modal.referral.form.selectAddress"
          :index.sync="modal.referral.form.index"
          :country.sync="modal.referral.form.country"
          :region.sync="modal.referral.form.region"
          :nsi-russian-subject-id.sync="modal.referral.form.nsiRussianSubjectId"
          :area.sync="modal.referral.form.area"
          :city.sync="modal.referral.form.city"
          :street.sync="modal.referral.form.street"
          :house.sync="modal.referral.form.house"
          :flat.sync="modal.referral.form.flat"
          @add-company="openModal(MODAL_TYPES.COMPANY)"
        />
      </template>

      <template #footer-right>
        <guarded-control
          tag="button"
          :permissions="['canManageReferral']"
          :disabled="modalHasErrors('referral')"
          class="btn btn-success btn-with-icon modal-save"
          type="button"
          @click="submitReferralForm"
        >
          <span class="btn-with-icon_icon fad fa-save" />
          <span class="btn-with-icon_text">
            {{ t('save') }}
          </span>
        </guarded-control>
        <button
          class="btn btn-primary btn-with-icon modal-close"
          type="button"
          @click="closeReferralForm"
        >
          <span class="btn-with-icon_icon fad fa-times" />
          <span class="btn-with-icon_text">
            {{ t('close') }}
          </span>
        </button>
      </template>
    </modal>

    <company-create-modal
      v-if="modal.company.visibility"
      :company-types="modal.company.companyTypes"
      :loading-types="modal.company.loadingTypes"
      @close="closeModal(MODAL_TYPES.COMPANY)"
      @create="setCreatedCompanyData"
    />

    <modal
      v-if="modal.createReminderItem.visibility"
      :modal-visibility="modal.createReminderItem.visibility"
      modal-class="modal-header-green"
      @close="closeCreateReminderItemForm"
    >
      <template #header>
        <span class="fad fa-tasks" />
        {{ t('reminder') }}
      </template>

      <template #body>
        <epic-spinner :visibility="modal.createReminderItem.loading" />

        <reminder-form
          :clinic-id="GET_SCHEDULE_CURRENT_CLINIC_ID"
          :types="REMINDER_TYPE_VALUES"
          :statuses="REMINDER_STATUS_VALUES"

          :errors="modal.createReminderItem.validationMessages"
          :title.sync="modal.createReminderItem.form.title"
          :date.sync="modal.createReminderItem.form.date"
          :type.sync="modal.createReminderItem.form.type"
          :status.sync="modal.createReminderItem.form.status"
          :client.sync="modal.createReminderItem.form.client"
          :responsible.sync="modal.createReminderItem.form.responsible"
          :doctor.sync="modal.createReminderItem.form.doctor"
          :note.sync="modal.createReminderItem.form.note"
          :created-at="modal.createReminderItem.form.createdAt"
          :updated-at="modal.createReminderItem.form.updatedAt"
          :created-by="modal.createReminderItem.form.createdBy"
          :updated-by="modal.createReminderItem.form.updatedBy"
          :targeted-disable="modal.createReminderItem.targetDisabled"
          :disabled="modal.createReminderItem.disabled"
          @enable-client="modal.createReminderItem.targetDisabled = {...modal.createReminderItem.targetDisabled, client: false}"
        />
      </template>

      <template #footer-right>
        <guarded-control
          tag="button"
          :permissions="['canManageReminder']"
          :disabled="modalHasErrors('createReminderItem')"
          class="btn btn-success btn-with-icon modal-save"
          type="button"
          @click="submitCreateReminderItemForm(false)"
        >
          <span class="btn-with-icon_icon fad fa-save" />
          <span class="btn-with-icon_text">
            {{ t('save') }}
          </span>
        </guarded-control>
        <guarded-control
          v-if="modal.editReminderItem.showWrite"
          tag="button"
          :permissions="['canManageAppointment', 'canManageReminder']"
          class="btn btn-warning btn-with-icon modal-save"
          :class="{ disabled: !createReminderCanAddAppointment }"
          :disabled="modalHasErrors('createReminderItem')"
          type="button"
          @click="submitCreateReminderItemForm(true)"
        >
          <span class="btn-with-icon_icon fad fa-fw fa-download" />
          <span class="btn-with-icon_text">
            {{ t('save_and_create_appointment') }}
          </span>
        </guarded-control>
        <button
          class="btn btn-primary btn-with-icon modal-close"
          type="button"
          @click="closeCreateReminderItemForm"
        >
          <span class="btn-with-icon_icon fad fa-times" />
          <span class="btn-with-icon_text">
            {{ t('close') }}
          </span>
        </button>
      </template>
    </modal>

    <modal
      v-if="modal.editReminderItem.visibility"
      :modal-visibility="modal.editReminderItem.visibility"
      modal-class="modal-header-green"
      @close="closeEditReminderItemForm"
    >
      <template #header>
        <span class="fad fa-tasks" />
        {{ t('reminder') }}
      </template>

      <template #body>
        <epic-spinner :visibility="modal.editReminderItem.loading" />

        <reminder-form
          :clinic-id="GET_SCHEDULE_CURRENT_CLINIC_ID"
          :types="REMINDER_TYPE_VALUES"
          :statuses="REMINDER_STATUS_VALUES"

          :errors="modal.editReminderItem.validationMessages"
          :title.sync="modal.editReminderItem.form.title"
          :date.sync="modal.editReminderItem.form.date"
          :type.sync="modal.editReminderItem.form.type"
          :status.sync="modal.editReminderItem.form.status"
          :client.sync="modal.editReminderItem.form.client"
          :responsible.sync="modal.editReminderItem.form.responsible"
          :doctor.sync="modal.editReminderItem.form.doctor"
          :note.sync="modal.editReminderItem.form.note"
          :created-at="modal.editReminderItem.form.createdAt"
          :updated-at="modal.editReminderItem.form.updatedAt"
          :created-by="modal.editReminderItem.form.createdBy"
          :updated-by="modal.editReminderItem.form.updatedBy"
          :targeted-disable="modal.editReminderItem.targetDisabled"
          :disabled="modal.editReminderItem.disabled"
          @enable-client="modal.editReminderItem.targetDisabled = {...modal.editReminderItem.targetDisabled, client: false}"
        />
      </template>

      <template #footer-left>
        <guarded-view :permissions="['canManageReminder']">
          <button
            type="button"
            class="btn btn-danger btn-sm modal-delete border-0"
            @click="askDestroyReminder"
          >
            {{ t('delete') }}
          </button>
        </guarded-view>
      </template>

      <template #footer-right>
        <guarded-control
          tag="button"
          :permissions="['canManageReminder']"
          class="btn btn-success btn-with-icon modal-save"
          :disabled="modalHasErrors('editReminderItem')"
          type="button"
          @click:allowed="submitEditReminderItemForm(false)"
        >
          <span class="btn-with-icon_icon fad fa-save" />
          <span class="btn-with-icon_text">
            {{ t('save') }}
          </span>
        </guarded-control>
        <guarded-control
          v-if="modal.editReminderItem.showWrite"
          tag="button"
          :permissions="['canManageAppointment', 'canManageReminder']"
          class="btn btn-warning btn-with-icon modal-save"
          :class="{ disabled: !editReminderCanAddAppointment }"
          :disabled="modalHasErrors('editReminderItem')"
          type="button"
          @click:allowed="submitEditReminderItemForm(true)"
        >
          <span class="btn-with-icon_icon fad fa-fw fa-download" />
          <span class="btn-with-icon_text">
            {{ t('save_and_create_appointment') }}
          </span>
        </guarded-control>
        <button
          class="btn btn-primary btn-with-icon modal-close"
          type="button"
          @click="closeEditReminderItemForm"
        >
          <span class="btn-with-icon_icon fad fa-times" />
          <span class="btn-with-icon_text">
            {{ t('close') }}
          </span>
        </button>
      </template>
    </modal>

    <alert-modal
      v-if="modal.alert.visibility"
      v-bind="alertProps"
      modal-class="doctor-schedules-alert-modal"
      @ok="hideAlert"
      @close="hideAlert"
    />

    <confirmation-modal
      v-if="modal.confirmation.visibility"
      modal-class="doctor-schedules-confirmation-modal"
      v-bind="confirmationProps"
      @yes="confirmationYesHandler"
      @no="confirmationNoHandler"
    />
  </div>
</template>

<script>
import { CREATE_WORK_TIME, MODE_DEFAULT, REMOVE_WORK_TIME } from '@/plugins/schedule/scheduleCommon/const.js'
import { Intent } from '@/services/intents'
import { APPOINTMENT_STATUS } from '@/vue_components/appointment/const'
import LastAppointments from '@/vue_components/appointment/last_appointments.vue'
import { ClientFormConsumer } from '@/vue_components/client/client_form_consumer'
import { SYSTEM_CLIENT_GROUPS } from '@/vue_components/client/const'
import CreateLegalRepresentativeModal from '@/vue_components/common/client/create_legal_representative_modal.vue'
import GuardedControl from '@/vue_components/common/guarded_control'
import GuardedView from '@/vue_components/common/guarded_view'
import CompanyCreateModal from '@/vue_components/companies/components/company_create_modal'
import { getDefaultCompanyData } from '@/vue_components/companies/components/consts.js'
import { WITHOUT_CABINET_ID } from '@/vue_components/employees_schedule/const'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import ItemsList from '@/vue_components/items_list.vue'
import { AlertHolder } from '@/vue_components/mixins/modals/alert_holder'
import { ConfirmationHolder } from '@/vue_components/mixins/modals/confirmation_holder'
import { ModalConsumer } from '@/vue_components/mixins/modals/modal_consumer'
import { ModalHolder } from '@/vue_components/mixins/modals/modal_holder'
import { ValidationHolder } from '@/vue_components/mixins/validationHolder'
import Modal from '@/vue_components/modal.vue'
import ReferralsForm from '@/vue_components/referrals/referrals_form.vue'
import ReminderForm from '@/vue_components/reminders/reminder_form.vue'

import { mapGetters } from 'vuex'
import AppointmentCreateModal from './components/appointment_create_modal.vue'
import AppointmentEditModal from './components/appointment_edit_modal.vue'
import WaitingCreateModal from './components/waiting_create_modal.vue'
import WaitingEditModal from './components/waiting_edit_modal.vue'
import {
  APPOINTMENT_SOURCE_MEDODS,
  APPOINTMENT_STATUS_VALUES,
  getFormData,
  MODAL_TYPES,
  REMINDER_STATUS_VALUES,
  REMINDER_TYPE_VALUES,
} from './consts'
import DoctorSchedulesContainer from './doctor_schedules_container.vue'
import methods from './doctor_schedules_methods.js'
import watch from './doctor_schedules_watchers.js'
import LeftSidebar from './left_sidebar.vue'
import {
  getDefaultFormAppointment,
  getDefaultFormClient,
  getDefaultFormClientTabs,
  getDefaultFormReferral,
  getDefaultFormReminderItem,
  getDefaultValidationsAppointment,
  getDefaultValidationsClient,
  getDefaultValidationsClientTabs,
  getDefaultValidationsCompany,
  getDefaultValidationsReferral,
  getDefaultValidationsReminderItem,
} from './methods/appointment_default_data.js'
import RightSidebar from './right_sidebar.vue'
import { Promobot } from '@/helpers/Promobot/Promobot'

/**
 * Мастер-компонент для всей страницы расписания.
 */
export default {
  name: 'DoctorSchedules',
  components: {
    GuardedView,
    CompanyCreateModal,
    GuardedControl,
    AppointmentCreateModal,
    AppointmentEditModal,
    CreateLegalRepresentativeModal,
    DoctorSchedulesContainer,
    EpicSpinner,
    ItemsList,
    LastAppointments,
    LeftSidebar,
    Modal,
    ReferralsForm,
    ReminderForm,
    RightSidebar,
    WaitingCreateModal,
    WaitingEditModal,
  },

  mixins: [
    ClientFormConsumer,
    ValidationHolder,
    ModalHolder,
    AlertHolder,
    ConfirmationHolder,
    ModalConsumer,
  ],

  provide () {
    return {
      openCopyAppointmentWithServicesModal: this.openCopyAppointmentWithServicesModal,
      closeCopyAppointmentWithServicesModal: this.closeCopyAppointmentWithServicesModal,
      openUserSelectModal: this.openUserSelectModal,
      closeUserSelectModal: this.closeUserSelectModal,
      openCreateWorkTimeModal: this.openCreateWorkTimeModal,
      closeCreateWorkTimeModal: this.closeCreateWorkTimeModal,
    }
  },

  data () {
    return {
      modalContext: [],
      currentUserId: this.GET_APP_CONF_CURRENT_USER_ID,
      users: [],
      cabinets: [],
      specialties: [],
      responsibles: [],

      filter: false,
      filteredUserIds: [],

      userIds: [],
      cabinetIds: [],

      availableCabinets: [],
      focusOnAppointment: null,
      modeWaitingInsert: false,
      oneVisibleCabinet: null,
      oneVisibleUser: null,
      showAppointment: {},
      reservedAppointmentShow: false,
      reservedAppointment: null,
      waitingUserFromGrid: null,
      waitingCount: {},

      modal: {
        formData: getFormData(this.$store),

        [MODAL_TYPES.USER_SELECT]: {
          visibility: false,
          userIds: [],
          selectedUserId: [],
          applyEvent () {},
          rejectEvent () {},
        },

        [MODAL_TYPES.CREATE_WORK_TIME]: {
          visibility: false,
          headerMessage: '',
          message: '',
          onlyCreate: false,
          applyEvent () {},
          rejectEvent () {},
          action: [CREATE_WORK_TIME],
          changeWorkTimeData: {},
          cabinetId: '',
        },

        [MODAL_TYPES.COPY_APPOINTMENT_WITH_SERVICES]: {
          visibility: false,
          rejectEvent () {},
          yesEvent () {},
          noEvent () {},
        },

        [MODAL_TYPES.LAST_APPOINTMENT]: {
          visibility: false,
          loading: false,

          client: {},
          appointments: [],
          selectedAppointments: [],
        },

        [MODAL_TYPES.CREATE_APPOINTMENT]: {
          visibility: false,
          disabled: !this.$security.canManageAppointment,
          loading: false,
          clientEdited: true,
          clientTouched: false,
          forcedClientEdit: false,
          cleared: false,
          clientPhoneMaskSettings: { ...Services.phoneMask.defaultMaskSettings },

          form: {
            appointmentSourceId: APPOINTMENT_SOURCE_MEDODS,
            clinicId: null,
            date: '',
            status: APPOINTMENT_STATUS.NEED_APPROVAL,
            time: '',
            userId: null,
            clientId: null,
            dentalOrderId: null,

            client: {
              ...getDefaultFormClient(),
              ...getDefaultFormClientTabs(),
            },

            appointment: {
              ...getDefaultFormAppointment(),
            },
          },

          validationMessages: {
            ...getDefaultValidationsClient(),
            ...getDefaultValidationsClientTabs(),
            ...getDefaultValidationsAppointment(),
          },
        },

        [MODAL_TYPES.EDIT_APPOINTMENT]: {
          visibility: false,
          disabled: !this.$security.canManageAppointment,
          loading: false,
          clientEdited: false,
          clientTouched: false,
          forcedClientEdit: false,
          cleared: false,
          clientPhoneMaskSettings: { ...Services.phoneMask.defaultMaskSettings },

          form: {
            appointmentSourceId: null,
            date: '',
            status: APPOINTMENT_STATUS.NEED_APPROVAL,
            time: '',
            userId: null,
            clientId: null,
            clinicId: null,
            appointmentId: null,

            client: {
              ...getDefaultFormClient(),
              ...getDefaultFormClientTabs(),
            },

            appointment: {
              ...getDefaultFormAppointment(),
            },
          },

          validationMessages: {
            ...getDefaultValidationsClient(),
            ...getDefaultValidationsClientTabs(),
            ...getDefaultValidationsAppointment(),
          },
        },

        [MODAL_TYPES.LEGAL_REPRESENTATIVE]: {
          visibility: false,
          loading: false,
          disabled: !this.$security.canManageClient,
          clientTouched: false,

          form: {
            client: {
              ...getDefaultFormClient(),
              ...getDefaultFormClientTabs(),
            },
          },

          validationMessages: {
            ...getDefaultValidationsClient(),
            ...getDefaultValidationsClientTabs(),
          },
        },

        [MODAL_TYPES.CREATE_REFERRAL]: {
          visibility: false,
          loading: false,
          disabled: !this.$security.canManageReferral,

          form: { ...getDefaultFormReferral() },
          validationMessages: { ...getDefaultValidationsReferral() },
        },

        [MODAL_TYPES.COMPANY]: {
          visibility: false,
          loading: false,
          disabled: !this.$security.canManageCompany,
          loadingTypes: true,

          companyTypes: [
            { id: -1, title: t('loading_1') },
          ],

          form: getDefaultCompanyData(),

          validationMessages: {
            ...getDefaultValidationsCompany(),
          },
        },

        [MODAL_TYPES.CREATE_REMINDER]: {
          visibility: false,
          disabled: !this.$security.canManageReminder,
          showWrite: true,
          targetDisabled: {},
          loading: false,

          form: { ...getDefaultFormReminderItem() },
          validationMessages: { ...getDefaultValidationsReminderItem() },
        },

        [MODAL_TYPES.EDIT_REMINDER]: {
          visibility: false,
          disabled: !this.$security.canManageReminder,
          showWrite: true,
          reminderId: null,
          targetDisabled: {},
          loading: false,

          form: { ...getDefaultFormReminderItem() },
          validationMessages: { ...getDefaultValidationsReminderItem() },
        },

        [MODAL_TYPES.CREATE_WAITING_ITEM]: {
          visibility: false,
          disabled: !this.$security.canManageWaitingList,
          clientId: null,
          clientPhone: '',
          form: {
            client: {
              legalRepresentatives: [],
            },
          },
        },

        [MODAL_TYPES.EDIT_WAITING_ITEM]: {
          visibility: false,
          disabled: !this.$security.canManageWaitingList,
          form: {
            client: {
              legalRepresentatives: [],
            },
          },
        },
      },

      APPOINTMENT_STATUS_VALUES,
      REMINDER_TYPE_VALUES,
      REMINDER_STATUS_VALUES,
      REMOVE_WORK_TIME,
      CREATE_WORK_TIME,

      Promobot: new Promobot(),
    }
  },

  computed: {
    //region vuex
    ...mapGetters([
      'GET_SCHEDULE_CURRENT_CLINIC_ID',
      'GET_LOADING_DOCTOR_SCHEDULE',
      'GET_LOADING_APPOINTMENTS',
      'GET_CONTEXT_READY',
      'GET_MODE_GRID_SCHEDULE',
      'GET_APPOINTMENT_DURATIONS_ITEMS',
      'GET_APPOINTMENT_DURATIONS',
      'GET_APPOINTMENT_SOURCES',
      'GET_DOCUMENT_TYPES',
      'GET_CLIENT_GROUPS',
      'GET_APPOINTMENT_SYSTEM_SOURCE',
      'GET_ATTRACTION_SOURCES',
      'GET_APPOINTMENT_TYPES',
      'GET_APPOINTMENT_TYPE_ID_DEFAULT',
      'GET_CURRENT_USER_CLINICS_LIST',
      'GET_SCHEDULE_DATE',
      'GET_SCHEDULE_DURATION',
      'GET_LOCALIZATION_CURRENCY_SYMBOL',
      'GET_LOCALIZATION_CURRENCY_PARAMS',
      'GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED',
      'GET_APP_CONF_SMS_MODULE_ENABLED',
      'GET_APP_CONF_TELEPHONY_MODULE_ENABLED',
      'GET_SMS_CONFIRMATION_DEFAULT',
      'GET_SMS_NOTIFY_DEFAULT',
      'GET_SMS_REMIND_DEFAULT',
      'GET_APP_CONF_CURRENT_USER_ID',
      'GET_DEFAULT_ATTRACTION_SOURCE_ID',
      'IS_ACTIVE_SCHEDULE_CABINETS',
      'IS_ACTIVE_SCHEDULE_DOCTORS',
      'getUserById',
      'GET_APP_CONF',
      'appointment/GET_MOVED_APPOINTMENT',
      'GET_APPOINTMENT_FROM_CALL',
      'GET_WAITING_ITEM_FROM_CALL',
      'GET_INSERT_PARAMS_APPOINTMENT',
    ]),
    ...mapGetters('waitingList', {
      waitingListUserId: 'GET_USER',
    }),
    //endregion vuex

    //region appointments
    appointmentClientBlacklisted () {
      return this.modal.formData.clientSummary.clientId &&
        this.modal.formData.clientSummary.clientGroups
          .some((group) => group.system_name === SYSTEM_CLIENT_GROUPS.blacklist)
    },

    needClinicInfo () {
      return this.GET_CURRENT_USER_CLINICS_LIST.length > 1
    },
    //endregion appointments

    //region reminders
    createReminderCanAddAppointment () {
      return !this.modalHasErrors('createReminderItem') && this.modal.createReminderItem.form.client
    },

    editReminderCanAddAppointment () {
      return !this.modalHasErrors('editReminderItem') && this.modal.editReminderItem.form.client
    },
    //endregion reminders

    //region entry search
    allowEntryTypesSearch () {
      return this.modal.formData.service.searchQuery.length >= 3
    },

    entryTypesNotResults () {
      return this.allowEntryTypesSearch && this.modal.formData.service.list.length < 1
    },
    //endregion entry search

    //region specialty search
    allowSpecialtySearch () {
      return this.modal.formData.specialties.searchQuery.length > 2
    },

    specialtyNotResults () {
      return this.allowSpecialtySearch && this.modal.formData.specialties.list.length === 0
    },
    //endregion specialty search

    //region appointment source
    appointmentSourceCreate () {
      return this.GET_APPOINTMENT_SOURCES.find(
        (s) => s.id === this.modal.createAppointment.form.appointmentSourceId
      )
    },

    appointmentSourceEdit () {
      return this.GET_APPOINTMENT_SOURCES.find(
        (s) => s.id === this.modal.editAppointment.form.appointmentSourceId
      )
    },
    //endregion appointment source

    //region utils
    lastModalContext () {
      return this.modalContext[this.modalContext.length - 1]
    },

    userListProps () {
      return {
        items: this.users.filter((user) => this.modal.userSelect.userIds.includes(user.id)),
        multiSelect: false,
        idItemField: 'id',
        displayedItemField: 'short_name',
        minSelectedItems: 1,
      }
    },

    lastAppointmentOrderParams () {
      const modal = this.modal.lastAppointment

      if (!modal.selectedAppointments.length) {
        return null
      }

      return {
        appointment_ids: modal.selectedAppointments,
        client_id: modal.client.id,
      }
    },

    withoutSummaryInfo () {
      return [
        MODAL_TYPES.LEGAL_REPRESENTATIVE,
      ]
    },
    //endregion utils

    //region work time
    cabinetsWithId () {
      return this.cabinets.filter((cabinet) => cabinet.id !== WITHOUT_CABINET_ID)
    },

    workTimeResponse () {
      return {
        cabinetId: this.modal.createWorkTime.cabinetId,
        action: this.modal.createWorkTime.action[0],
        clinicId: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
      }
    },
    //endregion work time
  },

  watch,

  beforeDestroy () {
    this.Promobot.unsubscribe()
  },

  created () {
    this.fetchSchedulesData(this.GET_SCHEDULE_CURRENT_CLINIC_ID)
    this.fetchCompanyTypes()

    this.Promobot.subscribe()
    this.subscribeToPromobotPubSubEvents()

    this.$pubSub.subscribe('LastAppointments.open', (clientId) => {
      this.chooseLastAppointmentClient(clientId)
    })
  },

  mounted () {
    const intent = Intent.getCurrentIntent()
    if (!intent) {
      this.$store.commit('SET_MODE_GRID_SCHEDULE', MODE_DEFAULT)

      return
    }

    switch (intent.type) {
      case Intent.SCHEDULE_APPOINTMENT_EDIT: {
        this.loadEditAppointmentForm(intent.payload.appointment)
        break
      }
      case Intent.SCHEDULE_APPOINTMENT_MOVE: {
        this.$store.commit('SET_ACTIVE_APPOINTMENT', intent.payload.appointment)
        this.$store.commit('SET_MODE_GRID_SCHEDULE', $.scheduleGrid.MODE_MOVE)
        break
      }
      case Intent.SCHEDULE_WAITING_CREATE: {
        this.openCreateWaitingItemFormWithClient(intent.payload)
        break
      }
      case Intent.SCHEDULE_APPOINTMENT_CREATE: {
        this.$store.dispatch('createAppointmentFromOutside', intent.payload)
        break
      }
    }
  },

  methods,
}
</script>
