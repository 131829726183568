import { request } from '@/lib/transport/request'
import { appointmentTypeAdapter } from './appointment_types_adapter'

export const appointmentTypesEndpoint = {
  getAll () {
    const options = {
      type: 'GET',
      url: Routes.list_reception_appointment_types_path(),
    }

    return request(options).promise
  },

  update (data) {
    const options = {
      type: 'PATCH',
      url: Routes.reception_appointment_type_path(data.id),
      data: appointmentTypeAdapter.toServer(data),
    }

    return request(options).promise
  },

  create (data) {
    const options = {
      type: 'POST',
      url: Routes.reception_appointment_types_path(),
      data: appointmentTypeAdapter.toServer(data),
    }

    return request(options).promise
  },

  /**
   * @param {number} id
   * @param {number} replacementTypeId
   * @return {Promise<void>}
   */
  destroy (id, replacementTypeId) {
    const options = {
      type: 'DELETE',
      url: Routes.reception_appointment_type_path(id, {
        replacement_type_id: replacementTypeId,
      }),
    }

    return request(options).promise
  },
}
