import { REQUEST_KEY } from './const'
import { createCaseAdapter } from '@/lib/transport/case_adapter'
import { getRegistrationDate } from '@/vue_components/companies/helpers/get_registration_date'
import { nameToCapitalize } from '@/vue_components/companies/helpers/name_to_capitalize'

export const companyAdapter = createCaseAdapter(REQUEST_KEY)

export const createAdapter = {
  toServer (data) {
    return {
      [REQUEST_KEY]: {
        ...companyAdapter.toServer(data)[REQUEST_KEY],
        registration_date: getRegistrationDate(data.registrationDate),
        head_name: nameToCapitalize(data.headName),
        head_surname: nameToCapitalize(data.headSurname),
        head_second_name: nameToCapitalize(data.headSecondName),
      },
    }
  },
}
