import { mapValues } from 'lodash'
import { FILTERS_PROFILES_OPTIONS_ERRORS as OPTIONS_ERRORS } from '../../const/consts'

const isOptionPermitted = ({ error }): Boolean =>
  error !== OPTIONS_ERRORS.NO_PERMISSION &&
    error !== OPTIONS_ERRORS.VALUE_DELETED

const removeUnpermittedOptions = (options) => options.filter(isOptionPermitted)

const cleanSelectValue = (options) => {
  const permittedOptions = removeUnpermittedOptions(options)

  if (permittedOptions.length) { return permittedOptions }
}

export const cleanUnpermittedValues = (filters: object): Record<string, any> => mapValues(
  filters,
  (value) => Array.isArray(value)
    ? cleanSelectValue(value)
    : value
)
