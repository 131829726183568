import Vue from 'vue/dist/vue.esm'
import Entries from '@/vue_components/reports/entries.vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper.js'
import { fetchExportFile, defineDateFormat } from './export.js'
import { ReportParamFactory } from '@/specific/reports/shared'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

$document.on('rez/reports/entries', function () {
  Report.init()

  const reportTable = new Vue({
    el: '#report_right_container',
    components: { Entries },
    mixins: [ReportWrapper],
    data: () => ({
      filters: getFilters()
    }),
    template: `
        <entries
          ref="report"
          :isHidden="isHidden"
          v-bind="filters"
        />`,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(getFilters())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  const header = {
    entryTitle: T.title,
    entryAmount: T.amount,
    entrySum: `${T.sum} (${Utils.currencySign()})`,
    entryFinalSum: `${T.to_pay} (${Utils.currencySign()})`,
    entrySumPercent: T.to_pay_procent,
  }
  $('.export').on('click', function () {
    const filters = getFilters()

    const data = {
      'dateFormat': defineDateFormat(),
      'csvComma': gon.application.csv_character,
      'floatingComma': gon.localization.currency.decimal_character,
      'headerRow': header,
      'totalRow': {
        'total': T.total,
      },
      'offset': 0,
      'limit': -(1 << 32 - 1),
      'report': {
        'period': filters.period,
      },
      ...ReportFiltersFactory.defineFor('entries', filters),
    }

    if (filters.assistant) {
      data.assistantIds = ReportParamFactory.buildArrayParam(filters.assistant)
    }

    fetchExportFile(data, Routes.report_entries_path(), T.reports.short_titles.entries_report)
  })

  function getFilters () {
    const filters = {
      period: ReportParamFactory.getRawParam('.period'),
      clinic: ReportParamFactory.getSelectParam('#report_clinic_id'),
      reportKind: ReportParamFactory.getButtonsParam('#report_kind'),
      machines: ReportParamFactory.getSelect2Params('#report_machine_ids'),
      user: ReportParamFactory.getSelectParam('#report_user_id'),
      reportCategory: ReportParamFactory.getSelectParam('#report_category_id'),
    }

    if ($('#report_analysis_laboratory_id').length) {
      filters.laboratory = ReportParamFactory.getSelectParam('#report_analysis_laboratory_id')
    }
    // check if assistants are enabled
    if ($('#report_assistant_id').length) {
      filters.assistant = ReportParamFactory.getSelectParam('#report_assistant_id')
    }

    return filters
  }
})
