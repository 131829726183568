<template>
  <div class="flex-form">
    <div class="form-label">
      <label for="refuse_result_title">
        {{ t('title') }}

        <required-mark />
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationErrors.title"
        fixed-height
      >
        <el-input
          id="refuse_result_title"
          name="refuse_result[title]"
          :disabled="!$security.canManageRefuseResult || isSystemName"
          :value="title"
          type="text"
          clearable
          @input="$updateSync('title', $event)"
        />
      </validation-wrapper>
    </div>
  </div>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import RequiredMark from '@/vue_components/common/required_mark'

export default {
  name: 'RefuseResultsForm',
  components: {
    RequiredMark,
    ValidationWrapper,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    validationErrors: {
      type: Object,
      default: () => ({}),
    },

    isSystemName: Boolean,
  },
}
</script>
