import {
  WORK_TIME_FACTORY,
  WORK_TIME,
  DEFAULT_START_TIME,
  DEFAULT_END_TIME,
} from '../../../../const.js'
import { commonFields } from './template_factory.js'
import Template from '../template.js'
import PersistentTemplate from '../persistent_template.js'

export default {
  initializePreset (options) {
    this.preset = WORK_TIME_FACTORY
    this.scheduleTemplates = options.scheduleTemplates
    this.defaultPropagationDate = options.defaultPropagationDate
    this.defaultCabinet = options.defaultCabinet

    this.surrogateIdsGenerator = (function * () {
      let counter = 0
      while (true) yield --counter
    })()
  },

  getPersistentTemplate (workTime) {
    const scheduleTemplate =
      workTime.schedule_template_id &&
      this.scheduleTemplates.find((template) => template.id === workTime.schedule_template_id)

    const { id } = workTime
    const cabinetId = this.availableCabinetsIds.has(workTime.cabinet_id) ? workTime.cabinet_id : null
    const [startTime, endTime] = workTime.work_time.split('-')
    const templateTypeId = scheduleTemplate ? scheduleTemplate.type : WORK_TIME
    const {
      templateDayIntervalId,
      templateWeekIntervalId,
      templateSelectedDaysIds,
    } = commonFields(templateTypeId, scheduleTemplate)
    const availabilityForOnlineRecording = (scheduleTemplate || workTime).availability_for_online_recording
    const templateActive = scheduleTemplate ? scheduleTemplate.active.toString() : 'true'
    const propagationDate = scheduleTemplate ? moment(scheduleTemplate.last_propagation_end_date).toDate() : this.defaultPropagationDate

    const initialData = {
      id,
      cabinetId,
      startTime,
      endTime,
      templateTypeId,
      templateDayIntervalId,
      templateWeekIntervalId,
      templateSelectedDaysIds,
      availabilityForOnlineRecording,
      templateActive,
      propagationDate,
    }

    return new PersistentTemplate(initialData, this.preset, scheduleTemplate)
  },

  getTemplate () {
    return new Template({
      id: this.surrogateIdsGenerator.next().value,
      cabinetId: this.defaultCabinet,
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
      templateTypeId: WORK_TIME,
      templateDayIntervalId: 1,
      templateWeekIntervalId: 1,
      templateSelectedDaysIds: [1],
      availabilityForOnlineRecording: 'arbitrary',
      templateActive: 'true',
      propagationDate: this.defaultPropagationDate,
    }, this.preset)
  },
}
