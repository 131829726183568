export const doctorSalaryByPaymentsDetailedSortingMap = Object.freeze({
  tableTitle: 'entryTitle',
  tableDate: 'entryDate',
  tableOrder: 'orderId',
  tableOrderDate: 'orderDate',
  tableClient: 'clientSurname',
  tableEntryFinalSum: 'entryFinalSum',
  tableAmountInEntry: 'entryAmount',
  tableLastPayment: 'lastPaymentId',
  tableLastPaymentDate: 'lastPaymentDate',
  tableLastPaymentTotalPaid: '',
  tableLastPaymentKind: '',
  tableLastPaymentCashier: 'cashierSurname',
  tableSum: 'paidSum',
  tableRateType: 'salaryEntryRate',
  tableSalary: 'salaryEntrySum',
})
