import { request } from '@/lib/transport/request'

// todo: подумать о переменных окружения, пока решили оставить так
const IP_URL = 'https://functions.yandexcloud.net/d4erm6uamrpio7soaise'
const IP_URL_RESERVE = 'https://api.ipify.org/'

const INJECTED_HEADER_NAME = 'Jrk-Bon-Off'
const FORM_SELECTOR = '#new_user'
const SUBMIT_SELECTOR = 'input[type="submit"]'
const FLASH_ALERT_SELECTOR = '#flash_alert'
const WHITE_LIST_ERROR = 'white_list_error'
const URL_ERROR_PARAM = 'error'

const injectFlashAlert = (text) => {
  let $flashAlert = $(FLASH_ALERT_SELECTOR)

  if (!$flashAlert.length) {
    $(FORM_SELECTOR).find('.form-value:first').prepend(`<span id="flash_alert">${text}</span>`)

    return
  }

  $flashAlert.text(text)
}

const checkError = () => {
  const url = new URL(location.href)
  if (!url.searchParams.has(URL_ERROR_PARAM)) { return }

  injectFlashAlert(t(url.searchParams.get(URL_ERROR_PARAM)))
}

const checkNetworkModule = () => gon.application.network_module_enabled

const injectIp = ($form, ip) => {
  if (!$form || !$form.length) {
    throw new Error('Form not found')
  }

  $form.submit((e) => {
    e.preventDefault()

    const formData = new FormData($form[0])

    const xHttp = new XMLHttpRequest()
    xHttp.open('POST', Routes.users_sign_in_path(), true)
    xHttp.setRequestHeader(INJECTED_HEADER_NAME, ip)
    xHttp.send(formData)

    xHttp.onreadystatechange = function () {
      if (this.readyState !== XMLHttpRequest.DONE) { return }

      if (this.responseURL.includes(WHITE_LIST_ERROR)) {
        const url = new URL(location.href)
        url.searchParams.set(URL_ERROR_PARAM, 'whitelist.message')

        location.href = url.toString()

        return
      }

      location.href = new URL(location.href).origin
    }
  })
}

const sendRequestForIp = (url) => {
  const $form = $(FORM_SELECTOR)
  const $submit = $form.find(SUBMIT_SELECTOR)

  $submit.loadSpin('start')
  request({
    type: 'get',
    url,
  })
    .promise
    .then((ip) => { injectIp($form, ip.trim()) })
    .catch((err) => {
      if (url === IP_URL) {
        sendRequestForIp(IP_URL_RESERVE)

        return
      }

      console.error(err)
      throw new Error('Request to get IP address failed')
    })
    .finally(() => { $submit.loadSpin('stop') })
}

/**
 * Получение внешнего IP клиента и передача в заголовках
 */
export const stealClientIp = () => {
  if (!checkNetworkModule()) { return }
  checkError()
  sendRequestForIp(IP_URL)
}
