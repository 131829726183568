import {
  ClientAnalysisOrderListItem,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AnalysisOrdersTab/api/ClientAnalysisOrderListItem'

export const clientAnalysisOrdersListAdapter = {
  toClient (response) {
    return {
      ...response,
      data: response.data.map((item) => new ClientAnalysisOrderListItem(item)),
    }
  },
}
