class ClinicWorkload {
  __connection = null

  connect () {
    return new Promise((resolve, reject) => {
      Services.wsSubscriptions.clinicWorkload.advancedConnection({
        received (message) {
          resolve(message.data.clinics_workload)
        },
        connected () {
          Services.wsSubscriptions.clinicWorkload.send()
        },
      })
    })
  }

  subscribe (callBack = () => {}) {
    this.__connection =
      Services
        .wsSubscriptions
        .clinicWorkload
        .connect((message) => callBack(message.data.clinics_workload))
  }
}

const instance = new ClinicWorkload()
export default instance
