import { reportErrorText, reportSuccessText } from '@/_api/_requests/helpers'

export const $tpLocale = (name) =>
  t(name, { scope: 'treatmentPlansEntriesTransfer.entries' })

export const notifyByResponseResults = (successCount = 0, errorCount = 0, notFound = false) => {
  if (notFound) {
    return Utils.reportWarning(
      'TreatmentPlansLazyTreeApi::transferTreeData',
      reportErrorText('transferredServicesWereDeletedMessage', 'treatmentEntries')
    )()
  }

  if (successCount && !errorCount) {
    return Utils.reportSuccess(reportSuccessText('transferMessage', 'treatmentEntries'))()
  }

  return Utils.reportWarning(
    'TreatmentPlansLazyTreeApi::transferTreeData',
    reportErrorText('partiallyTransferMessage', 'treatmentEntries')
  )()
}

export const getTransferAvailability = () => ({
  isForbidden: gon.specific.emk_transfer_availability === 'forbidden',
  isWithAmountChecking: gon.specific.emk_transfer_availability === 'available_with_amount_checking',
  isWithoutAmountChecking: gon.specific.emk_transfer_availability === 'available_without_amount_checking',
})
