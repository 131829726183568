const LIMIT = -(1 << 32 - 1)
const DECIMAL_CHAR = gon.localization.currency.decimal_character
const CSV_CHAR = gon.application.csv_character
const DATE_FORMAT = gon.localization.date_format.charAt(0) === 'd' ? '02.01.2006' : '01.02.2006'
const OWN_MEDCARD_NUMBERS = gon.application.changeable_medcard_numbers

export const users = {
  dateFormat: DATE_FORMAT,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    username: t('exports_page.user.username'),
    surname: t('exports_page.user.surname'),
    name: t('exports_page.user.name'),
    secondName: t('exports_page.user.secondName'),
    birthdate: t('exports_page.user.birthDate'),
    phone: t('exports_page.user.phone'),
    email: t('exports_page.user.email'),
    userStatus: t('exports_page.user.status'),
    hasAppointment: t('exports_page.user.hasAppointment'),
    appointmentDuration: t('exports_page.user.appointmentDuration'),
    createdAt: t('exports_page.user.createdAt'),
    createdById: t('exports_page.user.createdById'),
    note: t('exports_page.user.note'),
    cashierInn: t('exports_page.user.cashierInn'),
    uisEmployeeId: t('exports_page.user.uisEmployeeId'),
    uisDefaultVirtualNumber: t('exports_page.user.uisDefaultVirtualNumber'),
    commonTelephonyId: t('exports_page.user.commonTelephonyId'),
    sex: t('exports_page.user.sex'),
    onlineRecordingInformation: t('exports_page.user.onlineRecordingInformation'),
    availabilityForOnlineRecording: t('exports_page.user.availabilityForOnlineRecording'),
    code: t('exports_page.user.code'),
    itoolabsName: t('exports_page.user.itoolabsName'),
    specialtyIds: t('exports_page.user.specialtyIds'),
    clinicIds: t('exports_page.user.clinicIds'),
  },
  availabilityForOnlineRecording: {
    available: t('enums.user.availability_for_online_recording.available'),
    notAvailable: t('enums.user.availability_for_online_recording.not_available'),
    availableOnlyWithTheService: t('enums.user.availability_for_online_recording.available_only_with_the_service'),
    notDisplayedAvailableOnlyWithTheService: t('enums.user.availability_for_online_recording.not_displayed_available_only_with_the_service'),
  },
  offset: 0,
  limit: LIMIT,
}

export const clients = {
  dateFormat: DATE_FORMAT,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    username: 'username',
    surname: t('exports_page.client.surname'),
    name: t('exports_page.client.name'),
    secondName: t('exports_page.client.secondName'),
    birthDate: t('exports_page.client.birthDate'),
    phone: t('exports_page.client.phone'),
    sex: t('exports_page.client.sex'),
    patientCardNumber: t('exports_page.client.patientCardNumber'),
    addressIndex: t('exports_page.client.addressIndex'),
    addressCountry: t('exports_page.client.addressCountry'),
    addressRegion: t('exports_page.client.addressRegion'),
    addressArea: t('exports_page.client.addressArea'),
    addressCity: t('exports_page.client.addressCity'),
    addressStreet: t('exports_page.client.addressStreet'),
    addressHouse: t('exports_page.client.addressHouse'),
    addressFlat: t('exports_page.client.addressFlat'),
    idCardTypeTitle: t('exports_page.client.idCardTypeTitle'),
    nsiDocumentId: t('exports_page.client.nsiDocumentId'),
    idCardSeries: t('exports_page.client.idCardSeries'),
    idCardNumber: t('exports_page.client.idCardNumber'),
    idCardWhoIssue: t('exports_page.client.idCardWhoIssue'),
    idCardIssueDate: t('exports_page.client.idCardIssueDate'),
    additional: t('exports_page.client.additional'),
    email: t('exports_page.client.email'),
    createdAt: t('exports_page.client.createdAt'),
    temporary: t('exports_page.client.temporary'),
    parentId: t('exports_page.client.parentId'),
    finance: t('exports_page.client.finance'),
    attractionSourceId: t('exports_page.client.attractionSourceId'),
    parentName: t('exports_page.client.parentName'),
    createdById: t('exports_page.client.createdById'),
    discount: t('exports_page.client.discount'),
    bonusPoints: t('exports_page.client.bonusPoints'),
    omsNumber: t('exports_page.client.omsNumber'),
    companyId: t('exports_page.client.companyId'),
    position: t('exports_page.client.position'),
    department: t('exports_page.client.department'),
    inn: t('exports_page.client.inn'),
    snils: t('exports_page.client.snils'),
    serviceCard: t('exports_page.client.serviceCard'),
    doctorId: t('exports_page.client.doctorId'),
    denySmsDispatches: t('exports_page.client.denySmsDispatches'),
    denyCalls: t('exports_page.client.denyCalls'),
    denyEmail: t('exports_page.client.denyEmail'),
    onlineAccess: t('exports_page.client.onlineAccess'),
    parentBirthdate: t('exports_page.client.parentBirthdate'),
    denySmsNotifications: t('exports_page.client.denySmsNotifications'),
    denyWhatsappMessages: t('exports_page.client.denyWhatsappMessages'),
    payerId: t('exports_page.client.payerId'),
    importedAt: t('exports_page.client.importedAt'),
    clientGroupIds: t('exports_page.client.clientGroupIds'),
    legalRepresentativeIds: t('exports_page.client.legalRepresentativeIds'),
  },
  onlineAccess: {
    allowed: t('enums.client.online_access.allowed'),
    prohibited: t('enums.client.online_access.prohibited'),
  },
  ownMedcardNumbers: OWN_MEDCARD_NUMBERS,
  offset: 0,
  limit: LIMIT,
}

export const entryTypes = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    number: t('exports_page.entry_type.number'),
    title: t('exports_page.entry_type.title'),
    price: t('exports_page.entry_type.price'),
    kind: t('exports_page.entry_type.kind'),
    categoryTitle: t('exports_page.entry_type.categoryTitle'),
    createdAt: t('exports_page.entry_type.createdAt'),
    analysisLaboratoryId: t('exports_page.entry_type.analysisLaboratoryId'),
    createdById: t('exports_page.entry_type.createdById'),
    material: t('exports_page.entry_type.material'),
    dueDate: t('exports_page.entry_type.dueDate'),
    additional: t('exports_page.entry_type.additional'),
    costPrice: t('exports_page.entry_type.costPrice'),
    resultType: t('exports_page.entry_type.resultType'),
    teethMap: t('exports_page.entry_type.teethMap'),
    cabinet: t('exports_page.entry_type.cabinet'),
    appointmentDuration: t('exports_page.entry_type.appointmentDuration'),
    discountDisabled: t('exports_page.entry_type.discountDisabled'),
    templateRecordId: t('exports_page.entry_type.templateRecordId'),
    tip: t('exports_page.entry_type.tip'),
    needDentalOrder: t('exports_page.entry_type.needDentalOrder'),
    onlineRecordingInformation: t('exports_page.entry_type.onlineRecordingInformation'),
    onlineAccess: t('exports_page.entry_type.onlineAccess'),
    sendMessageOnReadyStatus: t('exports_page.entry_type.sendMessageOnReadyStatus'),
    extLaboratoryId: t('exports_page.entry_type.extLaboratoryId'),
    preparation: t('exports_page.entry_type.preparation'),
    appointments: t('exports_page.entry_type.appointments'),
    clinics: t('exports_page.entry_type.clinics'),
  },
  kind: {
    analysis: t('exports_page.entry_type.analysis'),
    service: t('exports_page.entry_type.service'),
    good: t('exports_page.entry_type.good'),
    complex: t('exports_page.entry_type.complex'),
  },
  onlineAccess: {
    allowed: t('enums.entry_type.online_access.allowed'),
    prohibited: t('enums.entry_type.online_access.prohibited'),
  },
  sendSmsOnReadyStatus: {
    allowed: t('enums.entry_type.send_message_on_ready_status.allowed'),
    prohibited: t('enums.entry_type.send_message_on_ready_status.prohibited'),
  },
  offset: 0,
  limit: LIMIT,
}

export const orders = {
  dateFormat: DATE_FORMAT,
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    date: t('exports_page.order.date'),
    clientId: 'clientId',
    sum: t('exports_page.order.sum'),
    finalSum: t('exports_page.order.finalSum'),
    paidSum: t('exports_page.order.paidSum'),
    discountSum: t('exports_page.order.discountSum'),
    createdAt: t('exports_page.order.createdAt'),
    orderStatus: t('exports_page.order.orderStatus'),
    orderPaidStatusId: t('exports_page.order.orderPaidStatus'),
    clinicId: t('exports_page.order.clinicId'),
    discountValue: t('exports_page.order.discountValue'),
    createdById: t('exports_page.order.createdById'),
    documentId: t('exports_page.order.documentId'),
    medicalPolicyId: t('exports_page.order.medicalPolicyId'),
    paidStatus: t('exports_page.order.paidStatus'),
    financeGuaranteeLetterId: t('exports_page.order.financeGuaranteeLetterId'),
    deletedOrderEntryIds: t('exports_page.order.deletedOrderEntryIds'),
    customerClientId: t('exports_page.order.customerClientId'),
    customerCompanyId: t('exports_page.order.customerCompanyId'),
  },
  paidStatus: {
    fullyPaid: t('_order_statuses.fully_paid'),
    paidByCredit: t('_order_statuses.paid_by_credit'),
    notPaid: t('_order_statuses.not_paid'),
    unknownPaidStatus: t('_order_statuses.unknown_paid_status'),
    free: t('_order_statuses.free'),
  },
  offset: 0,
  limit: LIMIT,
}

export const specialties = {
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    title: t('exports_page.specialty.title'),
    userGroupId: t('exports_page.specialty.userGroupId'),
    createdAt: t('exports_page.specialty.createdAt'),
    userIds: t('exports_page.specialty.userIds'),
    referralIds: t('exports_page.specialty.referralIds'),
  },
  offset: 0,
  limit: LIMIT,
}

export const appointmentTypes = {
  csvComma: CSV_CHAR,
  dateFormat: DATE_FORMAT,
  headerRow: {
    id: 'id',
    title: t('exports_page.appointment_type.title'),
    systemName: t('exports_page.appointment_type.systemName'),
    isDefault: t('exports_page.appointment_type.isDefault'),
    createdAt: t('exports_page.appointment_type.createdAt'),
  },
  offset: 0,
  limit: LIMIT,
}

export const clinics = {
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    title: t('exports_page.clinic.title'),
    phone1: t('exports_page.clinic.phone1'),
    phone2: t('exports_page.clinic.phone2'),
    fax: t('exports_page.clinic.fax'),
    web: t('exports_page.clinic.web'),
    email: t('exports_page.clinic.email'),
    companyId: t('exports_page.clinic.companyId'),
    messageTitle: t('exports_page.clinic.messageTitle'),
    timeZone: t('exports_page.clinic.timeZone'),
    legalName: t('exports_page.clinic.legalName'),
    inn: t('exports_page.clinic.inn'),
    ogrn: t('exports_page.clinic.ogrn'),
    messageAddress: t('exports_page.clinic.messageAddress'),
    accessibleFromClientWidget: t('exports_page.clinic.accessibleFromClientWidget'),
    servicesAvailableInClientWidget: t('exports_page.clinic.servicesAvailableInClientWidget'),
    specialistsAvaililableInClientWidget: t('exports_page.clinic.specialistsAvaililableInClientWidget'),
    status: t('exports_page.clinic.status'),
    onlineRecordingInformation: t('exports_page.clinic.onlineRecordingInformation'),
    showOnlineRecordingInformation: t('exports_page.clinic.showOnlineRecordingInformation'),
    license: t('exports_page.clinic.license'),
    userIds: t('exports_page.clinic.userIds'),
    entryTypeIds: t('exports_page.clinic.entryTypeIds'),
    createdAt: t('exports_page.specialty.createdAt'),
  },
  status: {
    statusActive: t('exports_page.clinic.statusActive'),
    statusNotActive: t('exports_page.clinic.statusInactive'),
  },
  offset: 0,
  limit: LIMIT,
}

export const appointments = {
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    userid: t('exports_page.appointment.userId'),
    date: t('exports_page.appointment.date'),
    clinicId: t('exports_page.appointment.clinicId'),
    clinicName: t('exports_page.appointment.clinicName'),
    clientId: t('exports_page.appointment.clientId'),
    clientSurname: t('exports_page.appointment.clientSurname'),
    clientName: t('exports_page.appointment.clientName'),
    clientSecondName: t('exports_page.appointment.clientSecondName'),
    orderId: t('exports_page.appointment.orderId'),
    time: t('exports_page.appointment.time'),
    duration: t('exports_page.appointment.duration'),
    status: t('exports_page.appointment.status'),
    note: t('exports_page.appointment.note'),
    createdById: t('exports_page.appointment.createdById'),
    createdAt: t('exports_page.appointment.createdAt'),
    remindBeforeDate: t('exports_page.appointment.remindBeforeDate'),
    clientReminded: t('exports_page.appointment.clientReminded'),
    appointmentTypeId: t('exports_page.appointment.appointmentTypeId'),
    appointmentSourceId: t('exports_page.appointment.appointmentSourceId'),
    byDms: t('exports_page.appointment.byDms'),
    dentalOrder: t('exports_page.appointment.dentalOrder'),
    attractionSourceId: t('exports_page.appointment.attractionSourceId'),
    callId: t('exports_page.appointment.callId'),
    notifyAfterSave: t('exports_page.appointment.notifyAfterSave'),
    callConfirmationStatus: t('exports_page.appointment.callConfirmationStatus'),
    callReminderStatus: t('exports_page.appointment.callReminderStatus'),
    remindByCall: t('exports_page.appointment.remindByCall'),
    confirmByCall: t('exports_page.appointment.confirmByCall'),
    roistat: t('exports_page.appointment.roistat'),
    addToWaitingList: t('exports_page.appointment.addToWaitingList'),
    clientComment: t('exports_page.appointment.clientComment'),
    sendConfirmationMessage: t('exports_page.appointment.sendConfirmationMessage'),
    referralId: t('exports_page.appointment.referralId'),
    entryTypeIds: t('exports_page.appointment.entryTypeIds'),
  },
  status: {
    needApproval: t('enums.appointment.status.need_approval'),
    confirmedByAdministrator: t('enums.appointment.status.confirmed_by_administrator'),
    approved: t('enums.appointment.status.approved'),
    arrived: t('enums.appointment.status.arrived'),
    serviced: t('enums.appointment.status.serviced'),
    billed: t('enums.appointment.status.billed'),
    paid: t('enums.appointment.status.paid'),
    canceled: t('enums.appointment.status.canceled'),
    failed: t('enums.appointment.status.failed'),
  },
  callReminderStatus: {
    pending: t('enums.appointment.call_reminder_status.pending'),
    reminded: t('enums.appointment.call_reminder_status.reminded'),
    failedToConnect: t('enums.appointment.call_reminder_status.failed_to_connect'),
  },
  callConfirmationStatus: {
    pending: t('enums.appointment.call_confirmation_status.pending'),
    confirmed: t('enums.appointment.call_confirmation_status.confirmed'),
    failedToConnect: t('enums.appointment.call_confirmation_status.failed_to_connect'),
    clientCanceled: t('enums.appointment.call_confirmation_status.client_canceled'),
  },
  offset: 0,
  limit: LIMIT,
}

export const payments = {
  csvComma: CSV_CHAR,
  floatingComma: DECIMAL_CHAR,
  headerRow: {
    id: 'id',
    clinicId: t('exports_page.payment.clinicId'),
    userId: t('exports_page.payment.userId'),
    destinationOrderId: t('exports_page.payment.destinationOrderId'),
    destinationClientId: t('exports_page.payment.destinationClientId'),
    payerCompanyId: t('exports_page.payment.payerCompanyId'),
    payerClientId: t('exports_page.payment.payerClientId'),
    byCash: t('exports_page.payment.byCash'),
    byCashless: t('exports_page.payment.byCashless'),
    byCard: t('exports_page.payment.byCard'),
    byBalance: t('exports_page.payment.byBalance'),
    byCredit: t('exports_page.payment.byCredit'),
    comment: t('exports_page.payment.comment'),
    createdAt: t('exports_page.payment.createdAt'),
    distribution: t('exports_page.payment.distribution'),
    deposit: t('exports_page.payment.deposit'),
    totalIncome: t('exports_page.payment.totalIncome'),
    totalPaid: t('exports_page.payment.totalPaid'),
    baseKind: t('exports_page.payment.baseKind'),
    date: t('exports_page.payment.date'),
    workplaceId: t('exports_page.payment.workplaceId'),
    surrender: t('exports_page.payment.surrender'),
    refundableId: t('exports_page.payment.refundableId'),
    kind: t('exports_page.payment.kind'),
  },
  kind: {
    analysis: t('analysis'),
    service: t('service'),
    good: t('good'),
    complex: t('complex'),
  },
  distribution: {
    autoDistribution: t('auto'),
    manualDistribution: t('manual'),
  },
  baseKind: {
    fund: t('fund'),
    refund: t('refund'),
  },
  offset: 0,
  limit: LIMIT,
}

export const entries = {
  csvComma: CSV_CHAR,
  floatingComma: DECIMAL_CHAR,
  headerRow: {
    id: 'id',
    entryTypeId: t('exports_page.entry.entryTypeId'),
    createdAt: t('exports_page.entry.createdAt'),
    title: t('exports_page.entry.title'),
    orderId: t('exports_page.entry.orderId'),
    clientId: t('exports_page.entry.clientId'),
    entryState: t('exports_page.entry.state'),
    price: t('exports_page.entry.price'),
    amount: t('exports_page.entry.amount'),
    stateful: t('exports_page.entry.stateful'),
    clinicId: t('exports_page.entry.clinicId'),
    sum: t('exports_page.entry.sum'),
    finalSum: t('exports_page.entry.finalSum'),
    discountSum: t('exports_page.entry.discountSum'),
    discountValue: t('exports_page.entry.discountValue'),
    userId: t('exports_page.entry.userId'),
    storeId: t('exports_page.entry.storeId'),
    kind: t('exports_page.entry.kind'),
    machineId: t('exports_page.entry.machineId'),
    complexId: t('exports_page.entry.complexId'),
    analysisLaboratoryId: t('exports_page.entry.analysisLaboratoryId'),
    createdById: t('exports_page.entry.createdById'),
    assistantId: t('exports_page.entry.assistantId'),
    date: t('exports_page.entry.date'),
    costPrice: t('exports_page.entry.costPrice'),
    comment: t('exports_page.entry.comment'),
    finalPrice: t('exports_page.entry.finalPrice'),
    number: t('exports_page.entry.number'),
    entryId: t('exports_page.entry.entryId'),
    consumable: t('exports_page.entry.consumable'),
    discountDisabled: t('exports_page.entry.discountDisabled'),
    printEntryInfo: t('exports_page.entry.printEntryInfo'),
    discountType: t('exports_page.entry.discountType'),
    extLaboratoryId: t('exports_page.entry.extLaboratoryId'),
    samplingDate: t('exports_page.entry.samplingDate'),
    analysisOrderId: t('exports_page.entry.analysisOrderId'),
    referralId: t('exports_page.entry.referralId'),
    printEntryTitle: t('exports_page.entry.printEntryTitle'),
  },
  kind: {
    analysis: t('analysis'),
    service: t('service'),
    good: t('good'),
    complex: t('complex'),
  },
  entryState: {
    notReady: t('not_ready'),
    ready: t('ready'),
    noState: t('no_state'),
  },
  discountType: {
    percent: t('percent'),
    currency: t('currency'),
  },
  offset: 0,
  limit: LIMIT,
}

export const clientGroups = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    title: t('exports_page.clientGroup.title'),
    createdAt: t('exports_page.clientGroup.createdAt'),
    systemName: t('exports_page.clientGroup.systemName'),
  },
  offset: 0,
  limit: LIMIT,
}

export const companies = {
  csvComma: CSV_CHAR,
  floatingComma: DECIMAL_CHAR,
  headerRow: {
    id: 'id',
    title: t('exports_page.company.title'),
    shortTitle: t('exports_page.company.shortTitle'),
    individualTaxNumber: t('exports_page.company.individualTaxNumber'),
    kpp: t('exports_page.company.kpp'),
    ogrn: t('exports_page.company.ogrn'),
    ogrnip: t('exports_page.company.ogrnip'),
    registrationDate: t('exports_page.company.registrationDate'),
    createdAt: t('exports_page.company.createdAt'),
    phone1: t('exports_page.company.phone1'),
    phone2: t('exports_page.company.phone2'),
    fax: t('exports_page.company.fax'),
    web: t('exports_page.company.web'),
    email: t('exports_page.company.email'),
    isDefault: t('exports_page.company.isDefault'),
    headName: t('exports_page.company.headName'),
    headSurname: t('exports_page.company.headSurname'),
    headSecondName: t('exports_page.company.headSecondName'),
    headPosition: t('exports_page.company.headPosition'),
    headOperatesBy: t('exports_page.company.headOperatesBy'),
    licTitle: t('exports_page.company.licTitle'),
    licNumber: t('exports_page.company.licNumber'),
    licDate: t('exports_page.company.licDate'),
    note: t('exports_page.company.note'),
    createdById: t('exports_page.company.createdById'),
    okved: t('exports_page.company.okved'),
    typeOfCompany: t('exports_page.company.typeOfCompany'),
    finance: t('exports_page.company.finance'),
    companyType: t('exports_page.company.companyType'),
    companyTypeIds: t('exports_page.company.companyTypeIds'),
  },
  offset: 0,
  limit: LIMIT,
}

export const companyTypes = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    title: t('exports_page.companyType.title'),
    createdAt: t('exports_page.companyType.createdAt'),
    systemName: t('exports_page.companyType.systemName'),
  },
  offset: 0,
  limit: LIMIT,
}

export const referrals = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    userId: t('exports_page.referral.userId'),
    kind: t('exports_page.referral.kind'),
    surname: t('exports_page.referral.surname'),
    name: t('exports_page.referral.name'),
    secondName: t('exports_page.referral.secondName'),
    phone: t('exports_page.referral.phone'),
    email: t('exports_page.referral.email'),
    about: t('exports_page.referral.about'),
    createdAt: t('exports_page.referral.createdAt'),
    createdById: t('exports_page.referral.createdById'),
    specialtyIds: t('exports_page.referral.specialtyIds'),
    companyId: t('exports_page.referral.companyId'),
    companyName: t('exports_page.referral.companyName'),
    codeString: t('exports_page.referral.codeString'),
    medicalUserId: t('exports_page.referral.medicalUserId'),
  },
  kind: {
    internal: t('exports_page.referral.internal'),
    external: t('exports_page.referral.external'),
  },
  offset: 0,
  limit: LIMIT,
}

export const discountSystems = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    title: t('exports_page.discountSystem.title'),
    status: t('exports_page.discountSystem.status'),
    dateStart: t('exports_page.discountSystem.dateStart'),
    dateEnd: t('exports_page.discountSystem.dateEnd'),
    priority: t('exports_page.discountSystem.priority'),
    useWithOtherDiscounts: t('exports_page.discountSystem.useWithOtherDiscounts'),
    clinicId: t('exports_page.discountSystem.clinicId'),
    clinicName: t('exports_page.discountSystem.clinicName'),
    createdAt: t('exports_page.discountSystem.createdAt'),
    discountKind: t('exports_page.discountSystem.discountKind'),
    discountUnits: t('exports_page.discountSystem.discountUnits'),
    maxBonusPointsDiscount: t('exports_page.discountSystem.maxBonusPointsDiscount'),
  },
  status: {
    enabled: t('enums.discount_system.status.enabled'),
    disabled: t('enums.discount_system.status.disabled'),
  },
  discountKind: {
    accumulatedDiscount: t('enums.discount_system.discount_kind.accumulated_discount'),
    bonusPoints: t('enums.discount_system.discount_kind.bonus_points'),
  },
  offset: 0,
  limit: LIMIT,
}

export const accounts = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    accountedSum: t('exports_page.account.accountedSum'),
    legacyType: t('exports_page.account.legacyType'),
    clientId: t('exports_page.account.clientId'),
    clientSurname: t('exports_page.account.clientSurname'),
    clientName: t('exports_page.account.clientName'),
    clientSecondName: t('exports_page.account.clientSecondName'),
    discountSystemId: t('exports_page.account.discountSystemId'),
    discountSystemTitle: t('exports_page.account.discountSystemTitle'),
    createdAt: t('exports_page.account.createdAt'),
    accountedBonusPoints: t('exports_page.account.accountedBonusPoints)'),
  },
  offset: 0,
  limit: LIMIT,
}

export const attractionSources = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    title: t('exports_page.attractionSource.title'),
    createdAt: t('exports_page.attractionSource.createdAt'),
    phone: t('exports_page.attractionSource.phone'),
  },
  offset: 0,
  limit: LIMIT,
}

export const analysisLaboratories = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    title: t('exports_page.analysisLaboratory.title'),
    createdAt: t('exports_page.analysisLaboratory.createdAt'),
    systemName: t('exports_page.analysisLaboratory.systemName'),
    clinicIdentifier: t('exports_page.analysisLaboratory.clinicIdentifier'),
    username: t('exports_page.analysisLaboratory.username'),
    entriesExtraChargeType: t('exports_page.analysisLaboratory.entriesExtraChargeType'),
    entriesExtraChargeValue: t('exports_page.analysisLaboratory.entriesExtraChargeValue'),
  },
  entriesExtraChargeType: {
    fixed: t('enums.analysis_laboratory.entries_extra_charge_type.fixed'),
    percent: t('enums.analysis_laboratory.entries_extra_charge_type.percent'),
  },
  offset: 0,
  limit: LIMIT,
}

export const expenses = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  dateFormat: DATE_FORMAT,
  headerRow: {
    id: 'id',
    title: t('exports_page.expense.title'),
    additional: t('exports_page.expense.additional'),
    price: t('exports_page.expense.price'),
    date: t('exports_page.expense.date'),
    createdAt: t('exports_page.expense.createdAt'),
    entryId: t('exports_page.expense.entryId'),
    clinicId: t('exports_page.expense.clinicId'),
    source: t('exports_page.expense.source'),
    category: t('exports_page.expense.category'),
    expenseGroupId: t('exports_page.expense.expenseGroupId'),
    consumable: t('exports_page.expense.consumable'),
    createdById: t('exports_page.expense.createdById'),
  },
  offset: 0,
  limit: LIMIT,
}

export const expenseGroups = {
  floatingComma: DECIMAL_CHAR,
  headerRow: {
    id: 'id',
    title: t('exports_page.expense.title'),
  },
  csvComma: CSV_CHAR,
  offset: 0,
  limit: LIMIT,
}

export const stores = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  headerRow: {
    id: 'id',
    title: t('exports_page.receiptGood.title'),
    createdAt: t('exports_page.receiptGood.createdAt'),
  },
  offset: 0,
  limit: LIMIT,
}

export const receiptGoods = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  dateFormat: DATE_FORMAT,
  headerRow: {
    id: 'id',
    date: t('exports_page.receiptGood.date'),
    userId: t('exports_page.receiptGood.userId'),
    comment: t('exports_page.receiptGood.comment'),
    createdAt: t('exports_page.receiptGood.createdAt'),
    transactionType: t('exports_page.receiptGood.transactionType'),
    destinationStoreId: t('exports_page.receiptGood.destinationStoreId'),
    sourceStoreId: t('exports_page.receiptGood.sourceStoreId'),
    documentNum: t('exports_page.receiptGood.documentNum'),
  },
  offset: 0,
  limit: LIMIT,
}

export const receiptGoodItems = {
  floatingComma: DECIMAL_CHAR,
  csvComma: CSV_CHAR,
  dateFormat: DATE_FORMAT,
  headerRow: {
    id: 'id',
    receiptGoodId: t('exports_page.receiptGoodsItem.receiptGoodId'),
    entryTypeId: t('exports_page.receiptGoodsItem.entryTypeId'),
    amount: t('exports_page.receiptGoodsItem.amount'),
    costPrice: t('exports_page.receiptGoodsItem.costPrice'),
    createdAt: t('exports_page.receiptGoodsItem.createdAt'),
  },
  offset: 0,
  limit: LIMIT,
}
