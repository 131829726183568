import { ReportParamFactory } from '@/specific/reports/shared'

export default function (source) {
  return {
    clinicIds: ReportParamFactory.buildArrayParam(source.clinic),
    expenseCategories: ReportParamFactory.buildArrayParam(source.expenseCategories),
    expenseConsumable: source.expenseConsumable.id,
    expenseSource: source.expenseSource.id,
    expensesGroupType: source.expensesGroupType.id,
  }
}
