import Vue from 'vue/dist/vue.esm'
import Orders from '@/vue_components/reports/orders.vue'
import {fetchExportFile, defineDateFormat} from './export.js'
import { ReportParamFactory } from './shared'
import ReportWrapper from '@/vue_components/mixins/reportWrapper.js'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

$document.on('rez/reports/orders', function () {
  Report.init()

  const reportTable = new Vue({
    el: '#report_right_container',
    components: { Orders },
    mixins: [ReportWrapper],
    data: () => ({
      filters: getFilters(),
    }),
    template:
      `<orders
        ref="report"
        :isHidden="isHidden"
        v-bind="filters"
      />`,
  })

  const tableHeader = [
    { title: '№' },
    { title: T.client },
    { title: T.customer },
    { title: T.common.date },
    { title: T.status },
    { title: T.amount },
    { title: T.to_pay },
    { title: T.paided },
  ]

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(getFilters())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', function () {
    const filters = getFilters()

    let data = {
      'dateFormat': defineDateFormat(),
      'csvComma': gon.application.csv_character,
      'floatingComma': gon.localization.currency.decimal_character,
      'headerRow': {
        'orderId': tableHeader[0].title,
        'customer': tableHeader[2].title,
        'clientFullName': tableHeader[1].title,
        'orderDate': tableHeader[3].title,
        'orderPaidStatus': tableHeader[4].title,
        'orderAmount': tableHeader[5].title,
        'orderFinalSum': tableHeader[6].title,
        'orderPaymentsTotalsPaidSum': tableHeader[7].title,
      },
      'totalRow': {
        'total': T.total,
      },
      'orderPaidStatusLocale': {
        'fullyPaid': T._order_statuses.fully_paid,
        'paidByCredit': T._order_statuses.paid_by_credit,
        'notPaid': T._order_statuses.not_paid,
        'unknownPaidStatus': T._order_statuses.unknown_paid_status,
        'free': T._order_statuses.free,
      },
      'offset': 0,
      'limit': -(1 << 32 - 1),
      'report': {
        'period': filters.period,
      },
      ...ReportFiltersFactory.defineFor('orders', filters),
    }

    fetchExportFile(data, Routes.report_orders_path(), T.orders)
  })

  function getFilters () {
    return {
      period: ReportParamFactory.getRawParam('.period'),
      clinic: ReportParamFactory.getSelectParam('#report_clinic_id'),
      orderPaidStatus: ReportParamFactory.getSelectParam('#report_paid_status'),
    }
  }
})
