import { cloneDeep } from 'lodash'
import { SPECIAL_CLINICS } from '@/vue_apps/catalogs_root/analysis_laboratories/const/create_laboratories'

const validationText = t('errors.filled?')
const validationClinicsText = t('laboratories.validation.clinic_empty_fields')

const getDefaultAuthValidationsFields = () => ({
  labellerId: [],
  labellerPassword: [],
})

const getDefaultAuthValidations = (clinics) => clinics.reduce((acc, { id }) => {
  acc[id] = getDefaultAuthValidationsFields()

  return acc
}, {})

export const laboratoriesValidationsMixin = {
  props: {
    clinics: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      clinicId: 0,
      tempSelectedLaboratory: {
        authAnalysisLaboratories: {},
      },

      activeWatchers: [],
      validationMessages: {
        title: [],
        clinicId: [],
        authValidations: getDefaultAuthValidations(this.clinics),
      },
    }
  },

  computed: {
    hasValidationErrors () {
      const AUTH_VALIDATION_DEPTH = 2

      return Object.values(this.validationMessages.authValidations)
        .map((item) => Object.values(item))
        .flat(AUTH_VALIDATION_DEPTH)
        .length
    },
  },

  watch: {
    'tempSelectedLaboratory.title' () {
      this.validationMessages.title = []
    },

    hasValidationErrors (to) {
      if (to) { return }
      this.validationMessages.clinicId = []
    },
  },

  methods: {
    resetValidations () {
      this.__resetAttributes()

      if (this.tempSelectedLaboratory.systemName === SPECIAL_CLINICS.HELIX) {
        this.__resetWatchers()
      }
    },

    hasErrors () {
      this.resetValidations()

      const results = [
        this.__emptyTitle(),
        this.__emptyLabellerFields(),
      ]

      return results.some(Boolean)
    },

    __resetAttributes () {
      this.validationMessages.title = []
      this.validationMessages.clinicId = []
      this.validationMessages.authValidations = getDefaultAuthValidations(this.clinics)
    },

    __resetWatchers () {
      this.activeWatchers.forEach((watcher) => watcher())
      this.activeWatchers = []

      this.__setWatchers()
    },

    __setWatchers () {
      this.clinics.forEach(({ id }) => {
        const { labellerIntegration } = this.tempSelectedLaboratory.authAnalysisLaboratories[id]

        this.__setIntegrationLabellerWatcher(id)

        if (!labellerIntegration) { return }

        this.__setLabellerFieldWatcher('labellerId', id)
        this.__setLabellerFieldWatcher('labellerPassword', id)
      })
    },

    __setLabellerFieldWatcher (field, clinicId) {
      this.$watch(
        () => this.tempSelectedLaboratory.authAnalysisLaboratories[clinicId][field],
        () => { this.validationMessages.authValidations[clinicId][field] = [] }
      )
    },

    __setIntegrationLabellerWatcher (clinicId) {
      this.$watch(
        () => this.tempSelectedLaboratory.authAnalysisLaboratories[clinicId].labellerIntegration,
        () => { this.validationMessages.authValidations[clinicId] = getDefaultAuthValidationsFields() }
      )
    },

    __emptyTitle () {
      let result = false
      if (!this.tempSelectedLaboratory.title) {
        this.validationMessages.title = [validationText]
        result = true
      }

      return result
    },

    __emptyLabellerFields () {
      let result = false
      const authClinics = this.tempSelectedLaboratory.authAnalysisLaboratories

      const authValidations = getDefaultAuthValidations(this.clinics)
      for (const clinicId in authClinics) {
        const authClinic = authClinics[clinicId]

        if (!authClinic.labellerIntegration) { continue }

        if (!authClinic.labellerId) {
          authValidations[clinicId].labellerId = [validationText]
          result = true
        }

        if (!authClinic.labellerPassword) {
          authValidations[clinicId].labellerPassword = [validationText]
          result = true
        }
      }

      this.validationMessages.authValidations = result
        ? cloneDeep(authValidations)
        : {}

      this.validationMessages.clinicId = result
        ? [validationClinicsText]
        : []

      return result
    },
  },
}
