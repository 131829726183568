
export const getDefaultFilters = () => ({
  date: null,
  clinic: null,
  kind: null,
  specialty: null,
  performer: null,
  teethIds: [],
  mine: false,
})
