import htmlHelper from '@/plugins/dynamic_forms/helpers/htmlHelper'
import { MSemdPresenter } from '@/_api/MSemdApi/MSemdPresenter'
import { getActiveSemdEntitiesMap } from '@/vue_apps/Protocols/const/getActiveSemdEntitiesMap'
import { ProtocolAPI } from '@/vue_apps/Protocols/entities/ProtocolAPI'
import { getDefaultProtocol } from '@/vue_apps/Protocols/const/getDefaultProtocol'
import { clearApplicationBuilder } from '@/vue_apps/Protocols/const/clearApplicationBuilder'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { ProtocolDraftStorage } from '@/plugins/dynamic_forms/components/editor/ProtocolDraftStorage'

export const ProtocolModalWrapperMixin = {
  methods: {
    __onOpenProtocolEditor () {
      document.querySelector('#protokol').click()
    },

    __setProtocolChanged (value = true) {
      this.isProtocolChanged = value
    },

    __prohibitChangesIfSemdType () {
      if (this.protocol.semdType) {
        this.protocol.prohibitChangeSemdType()
      }
    },

    __reinitProtocolEditor (id) {
      this.isProtocolChanged = false
      this.protocol = new ProtocolAPI(getDefaultProtocol({
        id,
        entry: this.entry,
        clientId: this.clientId,
        clinic: this.clinic,
        doctor: this.doctor,
        printEntryTitle: this.configurationPrintEntryTitle,
        printEntryInfo: this.configurationPrintEntryInfo,
        printClinicHeader: this.configurationPrintClinicHeader,
        printDoctorSignature: this.configurationPrintDoctorSignature,
      }))

      if (!this.legalRepresentatives.length) {
        this.removeValidator('selectedLegalRepresentativeId')
      }

      if (this.legalRepresentatives.length) {
        this.protocol.setValue('selectedLegalRepresentativeId', this.legalRepresentatives[0].id)
      }

      clearApplicationBuilder()
    },

    __openProtocol ({ externalOpening }) {
      /**
       * Если список семдов пуст или последний семд не актуален
       * то необходимо обновить протокол, т.к. переменные в нём испортятся
       * после нажатия на "Завершить заполнение"
       */
      if (!this.semdList.length || this.lastSemd?.isOutdatedVersion()) {
        this.__setProtocolChanged()
      }

      if (externalOpening) { return }

      this.__prohibitChangesIfSemdType()
      this.__onOpenProtocolEditor()
      this.closeModal()
    },

    __updateProtocolPreview (templateHtml = '') {
      const protocolContent = document.querySelector('#protokol-content')
      if (protocolContent) { protocolContent.innerHTML = templateHtml }
      this.$nextTick(() => { htmlHelper.clearHtml() })
    },

    /**
     * @param {string} tHtml
     * @return {Promise<void>}
     * @private
     */
    async __afterProtocolSaveSemdActions ({ tHtml }) {
      this.$emit('submitted')
      this.$emit('generatePdf', this.protocol.id)

      this.__updateProtocolPreview(tHtml)
      this.__setProtocolChanged(false)

      this.showLegalsSelector = false

      if (this.protocol.newSignatureApproved && this.lastSemd) {
        const result = await new MSemdPresenter().removeSignature(this.lastSemd.id)
        if (result?.errors) { return }
      }

      this.protocol.setValue('newSignatureApproved', false)
      this.protocol.setValue('newSemdApproved', false)
    },

    /**
     * @param {PointerEvent | { isTrusted: boolean, externalOpening?: boolean }} event
     * @return {Promise<void | { cancel: boolean }>}
     */
    async __newSemdApprovedConfirm (event) {
      const { cancel } = await MConfirm.warning(t('semds.confirms.successfullySent'))
      if (cancel) { return { cancel } }

      this.protocol.setValue('newSemdApproved', true)

      this.__openProtocol(event)
    },

    /**
     * @param {PointerEvent | { isTrusted: boolean, externalOpening?: boolean }} event
     * @return {Promise<void | { cancel: boolean }>}
     */
    async __newSignatureApprovedConfirm (event) {
      const { cancel } = await MConfirm.warning(t('semds.confirms.oldSigned'))
      if (cancel) { return { cancel } }

      this.protocol.setValue('newSignatureApproved', true)

      this.__openProtocol(event)
    },

    /**
     * Из-за наличия переменных в протоколе требуется:
     * 1) получить скомпилированный template_html
     * 2) распарсить данные для сэмда
     * 3) сохранить данные для сэмда
     *
     * @return {Promise<{errors}|*>}
     * @private
     */
    async __protocolSave () {
      if (this.isProtocolChanged) {
        this.protocol.fillSemdData()
      }

      const protocolId = this.protocol.id
      const result = await this.protocol.save()
      if (result?.errors) {
        this.resetValidations(result.errors)

        return result
      }

      this.__removeDraft(protocolId)

      if (this.protocol.semdType) {
        this.protocol.fillSemdData({
          ...this.protocol.semdsDocumentDetailsAttributes?.data,
          ...getActiveSemdEntitiesMap(this.protocol.semdType, this.protocol.templateHtml),
        })
      }

      await this.__afterProtocolSaveSemdActions({
        tHtml: result.templateHtml,
      })

      if (this.protocol.semdType) {
        const detailsResult = await this.protocol.updateDetails()
        if (detailsResult?.errors) { return detailsResult }
      }
    },

    __removeDraft (id = this.protocol.id) {
      this.protocolDraftData = null
      new ProtocolDraftStorage(id).drop()
    },
  },
}
