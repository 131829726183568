import CalendarWorkloadDye from './calendar_workload_dye'
import CabinetsWorkloadCalculator from './cabinets_workload_calculator.js'
import CabinetsWorkloadStorage from './cabinets_workload_storage.js'
import DoctorsWorkloadCalculator from './doctors_workload_calculator.js'
import DoctorsWorkloadStorage from './doctors_workload_storage.js'

const rulesForCalendarWorkloadDye = [
  (data) => {
    if (!data.vacation) return false

    const time = calculationTime(data.day, data.calcIntervals)
    if (!time) return false

    return {
      classes: 'tooltip-bottom black-vacation',
      tooltip: `${T.calendar_workload.vacation} ${time}`,
    }
  },
  (data) => {
    if (data.averagePercentage > 0 && data.averagePercentage < 30) {
      return {
        classes: 'tooltip-bottom without-color',
        tooltip: `${T.calendar_workload.workload} ${data.averagePercentage}% ${calculationTime(data.day, data.calcIntervals)}`,
      }
    }

    return false
  },
  (data) => {
    if (data.averagePercentage >= 30 && data.averagePercentage < 60) {
      return {
        classes: 'tooltip-bottom green-workload',
        tooltip: `${T.calendar_workload.workload} ${data.averagePercentage}% ${calculationTime(data.day, data.calcIntervals)}`,
      }
    }

    return false
  },
  (data) => {
    if (data.averagePercentage >= 60 && data.averagePercentage < 90) {
      return {
        classes: 'tooltip-bottom orange-workload',
        tooltip: `${T.calendar_workload.workload} ${data.averagePercentage}% ${calculationTime(data.day, data.calcIntervals)}`,
      }
    }

    return false
  },
  (data) => {
    if (data.averagePercentage >= 90) {
      return {
        classes: 'tooltip-bottom red-workload',
        tooltip: `${T.calendar_workload.workload} ${data.averagePercentage}% ${calculationTime(data.day, data.calcIntervals)}`,
      }
    }

    return false
  },
]

const calculationTime = (day, calcIntervals) => {
  const parsedDay = Date.parse(day)

  const existedInterval = calcIntervals.find((interval) =>
    Date.parse(interval.start_date) <= parsedDay &&
    Date.parse(interval.end_date) >= parsedDay
  )

  if (existedInterval) {
    return `<br> <span class='calculation-time'> ${T.calendar_workload.relevant} ${moment(existedInterval.calc_time).format(gon.localization.date_format.toLocaleUpperCase() + ' HH:mm')} </span>`
  }

  return ''
}

const calendarTippyConfig = { selector: '#schedule-calendar', extendDefaultConfig: { delay: [300, 0] } }

export default Object.freeze({
  rulesForCalendarWorkloadDye,
  calendarTippyConfig,
  CabinetsWorkloadStorage,
  CabinetsWorkloadCalculator,
  DoctorsWorkloadStorage,
  DoctorsWorkloadCalculator,
  CalendarWorkloadDye,
})
