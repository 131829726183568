const ZERO_SELECTED_DOCTORS = 0

class DoctorsWorkloadCalculator {
  constructor (doctorsWorkloadStorage) {
    this.doctorsWorkloadStorage = doctorsWorkloadStorage
    this.numberOfSelectedDoctors = ZERO_SELECTED_DOCTORS
    this._selectedDoctorsIds = []
  }

  set selectedDoctorsIds (selectedDoctorsIds) {
    this._selectedDoctorsIds = selectedDoctorsIds
    this.numberOfSelectedDoctors = selectedDoctorsIds.length
  }

  workload (verifiableDay) {
    if (this.numberOfSelectedDoctors === ZERO_SELECTED_DOCTORS) {
      return { averagePercentage: 0, vacation: false }
    }

    const doctorsWorkloadPerVerifiableDay =
      this._doctorsWorkloadPerVerifiableDay(verifiableDay)

    if (this._selectedDoctorsHaveGeneralVacation(doctorsWorkloadPerVerifiableDay)) {
      return { averagePercentage: 0, vacation: true }
    }

    let amountOfWorkloadPercentOfSelectedDoctors = 0
    this._selectedDoctorsIds.forEach((doctorId) => {
      const percentageWorkload = doctorsWorkloadPerVerifiableDay.get(doctorId).percentage
      amountOfWorkloadPercentOfSelectedDoctors += percentageWorkload
    })

    //Количество работников не в отпуске и не в выходных
    const selectedDoctorsWithWorkloadPercentageNumber = this._selectedDoctorsIds.reduce((acc, doctorId) => {
      return acc + Boolean(this.doctorsWorkloadStorage.doctorHasDailyWorkload(doctorId, verifiableDay) &&
        !this._selectedDoctorHasVacationInThisDay(doctorId, verifiableDay))
    }, 0) || 1

    const averagePercentage = Math.round(amountOfWorkloadPercentOfSelectedDoctors / selectedDoctorsWithWorkloadPercentageNumber)

    return { averagePercentage, vacation: false }
  }

  _doctorsWorkloadPerVerifiableDay (verifiableDay) {
    const workloadDataPerVerifiableDay = new Map()
    for (const doctorId of this._selectedDoctorsIds) {
      const doctorWorkload = this.doctorsWorkloadStorage.dailyDoctorWorkload(doctorId, verifiableDay)
      const hasDoctorVacation = this.doctorsWorkloadStorage.hasDoctorVacation(doctorId, verifiableDay)

      if (doctorWorkload) {
        workloadDataPerVerifiableDay.set(doctorId, { percentage: doctorWorkload, vacation: false })
        continue
      }

      if (hasDoctorVacation) {
        workloadDataPerVerifiableDay.set(doctorId, { percentage: 0, vacation: true })
        continue
      }
      workloadDataPerVerifiableDay.set(doctorId, { percentage: 0, vacation: false })
    }

    return workloadDataPerVerifiableDay
  }

  _selectedDoctorsHaveGeneralVacation (doctorsWorkloadPerVerifiableDay) {
    let counterVacations = 0
    doctorsWorkloadPerVerifiableDay.forEach((doctorsWorkload) => {
      if (doctorsWorkload.vacation) counterVacations++
    })

    return (counterVacations === this.numberOfSelectedDoctors)
  }

  _selectedDoctorHasVacationInThisDay (doctorId, day) {
    return this.doctorsWorkloadStorage.hasDoctorVacation(doctorId, day)
  }
}

export default DoctorsWorkloadCalculator
