
export const DEFAULT_CLIENTS_COUNT = 100

export const getClientsCountItems = () => [
  { id: 10, title: '10' },
  { id: 25, title: '25' },
  { id: 50, title: '50' },
  { id: DEFAULT_CLIENTS_COUNT, title: DEFAULT_CLIENTS_COUNT.toString() },
  { id: 250, title: '250' },
  { id: 500, title: '500' },
  { id: 1000, title: '1000' },
  { id: 2000, title: '2000' },
]
