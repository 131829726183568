<template>
  <button
    v-tooltip="$attrs.title"
    class="btn btn-primary btn-xs form-add-btn hidden-print"
    type="button"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>

<script>
/**
 * todo: удалить после переписывания счетов
 */
export default {
  name: 'AddBtn',
}
</script>
