export const APPOINTMENT_TYPE_DEFAULT_COLOR = '#ffffff'

/**
 * @returns {{default: string, color: string, title: string}}
 */
export const createDefaultAppointmentType = () => ({
  title: '',
  default: '',
  color: APPOINTMENT_TYPE_DEFAULT_COLOR,
})
