import htmlHelper from '@/plugins/dynamic_forms/helpers/htmlHelper'

export const clearApplicationBuilder = () => {
  htmlHelper.clearHtml()
  try {
    window.application.form_builder?.current?.freeComponents()
    window.application.form_builder.current = {}
    window.application.form_builder?.frontend?.freeComponents()
    window.application.form_builder.frontend = {}
    window.application.form_builder = {}
  } catch (e) {}
}
