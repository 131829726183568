<template>
  <div
    id="cabinet-list"
    class="cabinet-list"
  >
    <specialty-list
      v-if="!usersEmpty"
      :selected-specialty-id="selectedSID"
      :show-specialties-list="showSpecialtiesList"
      :visible-specialties="visibleSpecialties"
      @select-specialty="_specialtyOnClick"
      @toggle-show="_showSpecialtiesOnClick"
    />

    <div
      v-if="!usersEmpty"
      class="users-without-cabinets"
    >
      <button
        :class="{selected: showUsersWithoutCabinets}"
        @click="_showUsersWithoutCabinetsOnClick"
      >
        {{ T.doctors_without_a_cabinet }}
      </button>
    </div>

    <select-entry-buttons
      v-if="!usersEmpty"
      @select-all-entries="_selectAllOnClick"
      @unselect-all-entries="_unselectAllOnClick"
    />

    <empty-users v-if="usersEmpty" />

    <div
      v-if="cabinets.length === 0 && !usersEmpty"
      class="new-cabinet-notification"
    >
      <div>{{ T.no_cabinet_created }}</div>
      <div>
        <a :href="newCabinetPath">{{ T.create }}</a>
      </div>
    </div>

    <ul class="specific-items-list">
      <li
        v-for="cabinet in visibleCabinets"
        :key="cabinet.id"
        :class="{selected: selectedCIDsInSID[selectedSID].includes(cabinet.id)}"
        class="specific-item cut"
        @click="_cabinetOnClick(cabinet.id)"
      >
        <span class="left-container cut">
          <span class="specific-item-info-container cut">
            {{ cabinet.title }}
          </span>
        </span>
      </li>
    </ul>
    <guarded-view
      :permissions="['canManageScheduleTemplateScheduleTemplateBaseModel']"
    >
      <schedule-link v-if="!usersEmpty" />
    </guarded-view>
  </div>
</template>

<script>
import EmptyUsers from '../components/empty_users.vue'
import ScheduleLink from '../components/schedule_link.vue'
import SelectEntryButtons from '../components/select_entry_buttons.vue'
import SpecialtyList from '../components/specialty_list.vue'

import { WITHOUT_CABINET_ID } from './const.js'
import globalObjectsAccessHelpers from '../../global_objects_access_helpers.js'
import core from './core.js'
import eventsMethods from './events.js'
import storeMethods from './store.js'
import watch from './watch.js'
import GuardedView from '@/vue_components/common/guarded_view'

export default {
  name: 'CabinetsList',

  components: {
    GuardedView,
    EmptyUsers,
    ScheduleLink,
    SelectEntryButtons,
    SpecialtyList,
  },

  props: {
    contextReady: Boolean,
    tableVisibility: Boolean,

    users: {
      type: Array,
      required: true,
    },

    cabinets: {
      type: Array,
      required: true,
    },

    specialties: {
      type: Array,
      required: true,
    },

    availableCabinets: {
      type: Array,
      required: true,
    },

    filterCabinets: Boolean,

    filteredUserIds: {
      type: Array,
      required: true,
    },

    oneVisibleCabinet: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data () {
    return {
      newCabinetPath: window.Routes.new_reception_cabinet_path(),
      showSpecialtiesList: false,
      showUsersWithoutCabinets: false,
      onChangeTimeout: null,

      allSIDs: [],
      allUIDs: [],
      cabinetsMap: Object.create(null),
      specialtiesMap: Object.create(null),
      usersMap: Object.create(null),

      visibleCabinets: [],
      visibleSpecialties: [],
      selectedSID: 0,
      selectedCIDsInSID: {},
      availableUIDs: [],
      selfFilteredUserIds: [],

      dates: [],
    }
  },

  computed: {
    GET_SCHEDULE_CURRENT_CLINIC_ID () {
      return this.$store.getters.GET_SCHEDULE_CURRENT_CLINIC_ID
    },

    usersEmpty () {
      return this.users.length === 0
    },
  },

  watch,

  mounted () {
    Services.wsSubscriptions.workTime.connect((data) => {
      if (this.tableVisibility) {
        this._workTimeUpdatesHandler(data)
      }
    })
  },

  methods: {
    ...globalObjectsAccessHelpers,
    ...eventsMethods,
    ...storeMethods,
    ...core,

    clearUsersList () {
      this.allUIDs = []
      this.usersMap = Object.create(null)
    },

    clearSpecialtyList () {
      this.allSIDs = []
      this.specialtiesMap = Object.create(null)
    },

    clearCabinetsList () {
      this.cabinetsMap = Object.create(null)
    },

    _changeCabinetIds () {
      if (this.tableVisibility && this.contextReady) {
        this.$emit('onChange', this.showUsersWithoutCabinets
          ? [WITHOUT_CABINET_ID]
          : this.selectedCIDsInSID[this.selectedSID].filter((id) => id !== WITHOUT_CABINET_ID),
        )
      }
    },
  },
}
</script>
