import applicationFactory from '@/plugins/dynamic_forms/application_factory'
import { registerEntryTypesBreadcrumbsSub } from '@/specific/entry_types_helpers/entryTypesSubs'
import { subscribeToLaboratoryField } from '@/specific/entry_types_helpers/field_enable_check'

/**
 * Поиск по имени в Поступлении на склад
 */
$document.on('rez/receipt_goods/receipts', () => {
  const $supplierId = $('#supplier_id')
  $supplierId.on('change', function () {
    const supplierId = this.value
    Turbolinks.visit(Routes.receipts_receipt_goods_path((
      supplierId ? { supplier_id: parseInt(this.value) } : ''))
    )
  })

  $supplierId.select2({
    placeholder: T.select_goods_provider,
    dropdownCheckXPosition: true,
    allowClear: true,
    width: '230',
  })

  smartSearch(
    '#receipt_search_document',
    { url: Routes.search_receipts_receipt_goods_path() }
  )

  smartSearch(
    '#receipt_search_document_by_good_name',
    { url: Routes.search_receipts_by_product_name_receipt_goods_path() }
  )
})

/**
 * Поиск по имени в Списание/перемещение
 */
$document.on('rez/receipt_goods/cancellations', () => {
  smartSearch(
    '#receipt_search_document',
    { url: Routes.search_cancellations_receipt_goods_path() }
  )

  smartSearch(
    '#receipt_search_document_by_good_name',
    { url: Routes.search_cancellations_by_product_name_receipt_goods_path() }
  )
})

$document.on('rez/receipt_goods/edit rez/receipt_goods/new', function () {
  const ReceiptGood = FormFactory.build('receipt_good')
  ReceiptGood.newItem()

  applicationFactory.generate('backend')
  setTimeout(subscribeToLaboratoryField, 0)
})

$document.on('rez/receipt_goods/show', function () {
  /**
   * If add new transaction type, you need create
   * translates in files ru.yml and en.yml in `receipt_good_actions` element
   *
   * @param {string} transactionType This param contain type transaction receipt good,
   * you can take of from gon.specific.receipt_good.transaction_type
   * @returns {string} Translate current type receipt_good transaction
   */
  const getTranslateReceiptGoods = (transactionType) =>
    t(`receipt_good_actions.${transactionType}`)

  const transactionType = gon.specific.receipt_good.transaction_type
  const $delete = $('#delete-record')

  $delete.deleteButton({
    url: Routes.receipt_good_path(gon.specific.receipt_good.id),
    name: getTranslateReceiptGoods(transactionType),
    message: t('delete_receipt_message'),
  })
})

/**
 * @param {String} elementName
 * @param {Object} config
 */
function smartSearch (elementName, config) {
  const searchElement = $(elementName)

  searchElement.smartSearch({
    url: config.url,
    method: config.method || 'GET',
    itemRender: config.itemRender || itemRender,
    onSelect: config.onSelect || function (item) {
      Turbolinks.visit(Routes.edit_receipt_good_path(item.id || item.receipt_good_id))
      searchElement.val('').change()
    },
  })
}

/**
 * Стандартный рендер элемента
 *
 * @param {jQuery.element} html
 * @param {Object} item - объект из результата поиска
 * @returns {*}
 */
const itemRender = function (html, item) {
  const localeStr = t('stocks.search_template', {
    id: item.id || item.receipt_good_id,
    title: item.title ? ` ${item.title}` : '',
    num: item.document_num ? ` № ${item.document_num}` : '',
    date: item.formatted_date,
  })

  const refRow = $('<span>').text(localeStr)

  return html.prepend(refRow)
}

$document.on('rez/receipt_goods', () => {
  registerEntryTypesBreadcrumbsSub()
})
