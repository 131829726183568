<template>
  <div id="analysis_order_show">
    <div id="analysis_order_laboratory">
      <div id="analysis_order_area">
        <slot name="order-info-printable" />

        <div
          id="analysis_order_info"
          class="hidden-print"
        >
          <div class="col-md-6">
            <table
              id="client_info_table"
              v-loading="!analysisOrder.analysisLaboratoryTitle"
              class="show_info_table show_info_table_fix"
            >
              <tr>
                <td class="show_info_td">
                  {{ T.analysis_laboratory }}
                </td>
                <td class="show_info_td">
                  <skeleton
                    v-if="!analysisOrder.analysisLaboratoryTitle"
                    width="100px"
                  />
                  {{ analysisOrder.analysisLaboratoryTitle }}
                </td>
              </tr>
              <tr>
                <td class="show_info_td">
                  {{ T.patient }}
                </td>
                <td class="show_info_td">
                  <skeleton
                    v-if="!analysisOrder.client"
                    width="300px"
                  />
                  {{ clientFullName }}
                  <span
                    v-if="analysisOrder.client"
                    class="hidden-print"
                  >
                    /
                    <a
                      :href="`/clients/${analysisOrder.client.id}`"
                      target="_blank"
                    >
                      <span class="fad fa-link" />
                      {{ T.infocard }}
                    </a>
                  </span>
                  <span
                    v-if="analysisOrder.client"
                    class="hidden-print"
                  >
                    /
                    <a
                      :href="`/medical_records/${analysisOrder.client.medicalRecordId }`"
                      target="_blank"
                    >
                      <span class="fad fa-link" />
                      {{ T.medcard }}
                    </a>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="show_info_td">
                  {{ T.client_phone }}
                </td>
                <td class="show_info_td">
                  <span
                    v-if="analysisOrder.client"
                    class="phone_number"
                    :data-client-id="analysisOrder.client.id"
                  >
                    {{ clientPhone }}
                  </span>
                  <skeleton
                    v-else
                    width="150px"
                  />
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-6">
            <table
              id="client_info_table"
              class="show_info_table show_info_table_fix"
            >
              <tr>
                <td class="show_info_td">
                  {{ T.laboratories.analysis_order_state }}
                </td>
                <td class="show_info_td">
                  <skeleton
                    v-if="!analysisOrder.state"
                    width="150px"
                  />
                  <span
                    :class="analysisOrderStateIcon"
                  />
                  {{ analysisOrderState }}
                </td>
              </tr>
              <tr>
                <td class="show_info_td">
                  {{ T.laboratories.analysis_order_date }}
                </td>
                <td class="show_info_td">
                  <skeleton
                    v-if="!analysisOrder.date"
                    width="130px"
                  />
                  {{ formattedDate }}
                </td>
              </tr>
              <tr>
                <td class="show_info_td">
                  {{ T.laboratories.ready_notification }}
                </td>
                <td class="show_info_td">
                  <skeleton
                    v-if="analysisOrder.notificationTypes === undefined"
                    width="200px"
                  />
                  {{ analysisOrder.notificationTypes }}
                </td>
              </tr>
              <tr>
                <td class="show_info_td">
                  {{ T.clinic }}
                </td>
                <td class="show_info_td">
                  <skeleton
                    v-if="!clinicTitle"
                    width="200px"
                  />
                  {{ clinicTitle }}
                </td>
              </tr>
              <tr v-if="isInvitro">
                <td class="show_info_td">
                  {{ T.laboratories.comment }}
                </td>
                <td
                  class="show_info_td comment cut tooltip-bottom"
                  :title="analysisOrderComment"
                >
                  {{ analysisOrderComment }}
                </td>
              </tr>
              <tr v-if="isInvitro && analysisOrder.location">
                <td class="show_info_td">
                  {{ T.laboratories.location }}
                </td>
                <td
                  class="show_info_td location cut tooltip-bottom"
                  :title="analysisOrder.location"
                >
                  {{ analysisOrder.location }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <entries-container
          :entries="analysisOrder.entries"
          :saved-containers="analysisOrder.biomaterialContainers"
          :error-entries-numbers="errorEntriesNumbers"
          :is-kdl="isKdl"
          :is-form="false"
          :reset-containers-btn-enabled="resetContainersBtnEnabled"
          @setSelectedContainers="setSelectedContainers"
          @registerValidator="onRegisterValidator"
        />

        <slot name="biomaterials-info-printable" />

        <biomaterial-containers
          :biomaterial-containers="analysisOrder.biomaterialContainers"
          :entries="analysisOrder.entries"
          :analysis-order-state="analysisOrder.state"
          :show-entry-selector="showEntrySelector"
          :analysis-laboratory-system-name="analysisOrder.analysisLaboratorySystemName"
          :workplace-id="workplaceId"
          :workplace-list="workplaceList"
          :workplace-list-is-loading="workplaceListIsLoading"
          :barcode-editable="isHelix"
          :labeller-connected="labellerConnected"
          :labeller-integration-enabled="labellerIntegrationEnabled"
          :reset-containers-btn-enabled="resetContainersBtnEnabled"
          @print-barcode="printBarcodes"
          @print-labeller="printLabeller"
          @entry-ids-changed="updateBiomaterialContainer"
          @containersReset="resetContainers"
        />

        <slot name="client-signature-info-printable" />

        <attachments
          v-if="showAttachmentsPanel"
          class="hidden-print"
          :steps="analysisOrderSteps"
          :attachments="analysisOrder.attachments"
          :is-kdl="isKdl"
          @print-cover-letter="printCoverLetter"
          @print-attachment-clicked="printAttachmentClicked"
        />

        <kdl-attachments
          v-if="isKdl && resetContainersBtnEnabled"
          :order-number="analysisOrder.id"
          :order-date="formattedDateWithoutTime"
          :biomaterials="analysisOrder.biomaterialContainers"
          :clinic-identifier="clinicIdForKdlWaybill"
          @print-cover-letter="printCoverLetter"
        />

        <kdl-waybill
          v-if="isKdl"
          :biomaterial-containers="analysisOrder.biomaterialContainers"
          :clinic-identifier="clinicIdForKdlWaybill"
          :use-hidden-print="false"
          @print-cover-letter="printCoverLetter"
        />

        <control-panel
          v-if="showControlPanel"
          class="hidden-print"
          :cancel-order-btn-enabled="cancelOrderBtnEnabled"
          :check-result-btn-enabled="checkResultBtnEnabled"
          :analysis-order-state="analysisOrder.state"
          :sending-order-btn-loading="sendingOrderBtnLoading"
          :preanalytics-btn-loading="preanalyticsBtnLoading"
          :cancel-order-btn-loading="cancelOrderBtnLoading"
          :check-result-btn-loading="checkResultBtnLoading"
          :reset-containers-btn-enabled="resetContainersBtnEnabled"
          :remove-order-btn-loading="removeOrderBtnLoading"
          :steps="analysisOrderSteps"
          :cover-letter-attachment-id="coverLetterAttachmentId"
          :current-laboratory="analysisOrder.analysisLaboratorySystemName"
          :workplace-id.sync="workplaceId"
          :workplace-list="workplaceList"
          @request-questions="requestQuestions"
          @save-questions="saveQuestionare"
          @check-result="checkResult"
          @send-analysis-order="sendAnalysisOrder"
          @send-preanalytics-request="sendPreanalyticsRequest"
          @cancel-analysis-order="cancelAnalysisOrder"
          @edit-analysis-order="editAnalysisOrder"
          @remove-analysis-order="removeAnalysisOrder"
          @request-analysis-order-steps="requestAnalysisOrderSteps"
          @onPreanalyticsArrived="$emit('onPreanalyticsArrived')"
          @onQuestionnaireFilled="$emit('onQuestionnaireFilled')"
          @containersCalculation="containersCalculation"
        />

        <a
          id="analysis_order_show_back"
          class="btn btn-primary btn-xs hidden-print btn-back js_redirect_back"
          href="/#analysis_order"
        >
          {{ T.back }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import ControlPanel from './components/control_panel.vue'
import Attachments from './components/attachments.vue'
import EntriesContainer from './components/entries_container'
import BiomaterialContainers from './components/biomaterial_containers'
import Skeleton from '@/vue_components/skeleton'
import {
  ANALYSIS_ORDER_HUMAN_STATES,
  ANALYSIS_ORDER_PUBSUB_PREFIX,
  ANALYSIS_ORDER_STATE_ICONS,
  ANALYSIS_ORDER_STATES,
  EVENTS,
  LABORATORY_SYSTEM_NAMES,
  SEND_ORDER_MAX_WAIT_TIME_MS,
} from './constants.js'
import formatter from '@/lib/formatters/formatter'
import { clinicsEndpoint } from '@/api_entities/catalogs/clinics/clinics_endpoint'
import { cloneDeep } from 'lodash'
import { snakeToCamel } from '@/_api/_requests/helpers'
import KdlAttachments from '@/vue_components/analysis_orders/components/KdlAttachments.vue'
import KdlWaybill from '@/vue_components/analysis_orders/components/KdlWaybill.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { showOrderErrorNotification } from '@/vue_components/analysis_orders/const/const'

const ANALYSIS_ORDER_INDIVIDUAL_NUMBER_LENGTH = 9

export default {
  name: 'AnalysisOrder',
  components: {
    KdlWaybill,
    KdlAttachments,
    EntriesContainer,
    ControlPanel,
    BiomaterialContainers,
    Attachments,
    Skeleton,
  },

  mixins: [ValidationParentMixin],

  props: {
    analysisOrderId: {
      default: null,
      type: String,
    },

    wsHandler: {
      default: null,
      type: Object,
    },
  },

  data () {
    return {
      sendingOrderBtnLoading: false,
      preanalyticsBtnLoading: false,
      healthCheckLabellerBtnLoading: false,
      cancelOrderBtnLoading: false,
      checkResultBtnLoading: false,
      removeOrderBtnLoading: false,
      analysisOrderSteps: [],
      barcodesValidated: false,
      analysisOrder: {},
      clinics: [],
      coverLetterAttachmentId: null,
      uniqueComponentId: Math.random().toString().match(/\d+/g).pop(),
      workplaceListIsLoading: true,
      workplaceId: null,
      workplaceList: [],
      labellerConnected: false,
      selectedContainers: null,

      errorsSet: new Set(),
      errorEntriesNumbers: [],
    }
  },

  computed: {
    isInvitro () {
      return this.analysisOrder.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.INVITRO
    },

    isHelix () {
      return this.analysisOrder.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.HELIX
    },

    isKdl () {
      return this.analysisOrder.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.KDL ||
          this.analysisOrder.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.KDL_CHECKUPS
    },

    clientFullName () {
      const client = this.analysisOrder.client
      if (client) {
        return [client.surname, client.name, client.secondName].join(' ')
      }

      return ''
    },

    clientPhone () {
      return `+${Services.phoneMask.setMask(this.analysisOrder.client.phone) || ''}`
    },

    clinicTitle () {
      return this.clinics.find((clinic) => clinic.id === this.analysisOrder.clinicId)?.title || '-'
    },

    analysisOrderNumber () {
      const uniqueOrderNumbers = new Set()

      this.analysisOrder.biomaterialContainers.forEach((container) => {
        uniqueOrderNumbers.add(container.label.slice(0, ANALYSIS_ORDER_INDIVIDUAL_NUMBER_LENGTH))
      })

      const orderNumbers = Array.from(uniqueOrderNumbers)

      return orderNumbers.length
        ? t('laboratories.individualNumber') + orderNumbers.join(', ')
        : ''
    },

    analysisOrderComment () {
      return this.analysisOrderNumber.length
        ? `${this.analysisOrderNumber}. ${this.analysisOrder.comment}`
        : this.analysisOrder.comment
    },

    analysisOrderState () {
      return T.enums.analysis_order.state[ANALYSIS_ORDER_HUMAN_STATES[this.analysisOrder.state]]
    },

    analysisOrderStateIcon () {
      return `${ANALYSIS_ORDER_STATE_ICONS[this.analysisOrder.state]} analysis_order_state_icon`
    },

    formattedDate () {
      if (!this.analysisOrder.date) return ''

      return moment.unix(this.analysisOrder.date).format('DD.MM.YYYY, HH:mm')
    },

    formattedDateWithoutTime () {
      if (!this.analysisOrder.date) return ''

      return moment.unix(this.analysisOrder.date).format('DD.MM.YYYY')
    },

    showEntrySelector () {
      if (!this.analysisOrder.analysisLaboratorySystemName) return true

      return this.analysisOrder.analysisLaboratorySystemName !== LABORATORY_SYSTEM_NAMES.HELIX
    },

    showControlPanel () {
      if (this.analysisOrder.state) {
        return this.analysisOrder.state !== ANALYSIS_ORDER_STATES.READY &&
          this.analysisOrder.state !== ANALYSIS_ORDER_STATES.ALMOST_READY
      }

      return false
    },

    showAttachmentsPanel () {
      if (this.analysisOrder.attachments) {
        return this.analysisOrder.attachments.length > 0
      }

      return false
    },

    cancelOrderBtnEnabled () {
      if (!this.analysisOrder.analysisLaboratorySystemName) return true

      return this.analysisOrder.analysisLaboratorySystemName !== LABORATORY_SYSTEM_NAMES.HELIX
    },

    checkResultBtnEnabled () {
      if (!this.analysisOrder.analysisLaboratorySystemName) return false

      return this.analysisOrder.analysisLaboratorySystemName !== LABORATORY_SYSTEM_NAMES.INVITRO
    },

    labellerIntegrationEnabled () {
      return gon.specific.labeller_integration
    },

    resetContainersBtnEnabled () {
      if (!this.analysisOrder.biomaterialContainers) { return false }

      return this.analysisOrder.biomaterialContainers.length !== 0
    },

    clinicIdForKdlWaybill () {
      const clinicId = this.analysisOrder.analysisLaboratory?.username

      return clinicId
        ? clinicId.substring(clinicId.lastIndexOf('_') + 1)
        : ''
    },
  },

  created () {
    this.workplaceListIsLoading = true
    this.subscribeToEvents()

    this.fetchClinics()
  },

  updated () {
    // выглядит как дичь полная
    this.$nextTick(Utils.updateTooltips)
    this.$nextTick(Services.telephony.reset)
  },

  methods: {
    getCoverLetterAttachmentId () {
      if (this.analysisOrder.attachments) {
        const coverLetterAttachment = this.analysisOrder.attachments.find(
          (attachment) => attachment.name === `${T.laboratories.cover_letter_for}${this.analysisOrder.id}`
        )
        if (coverLetterAttachment) {
          this.coverLetterAttachmentId = parseInt(coverLetterAttachment.id)
        }
      }
    },

    sendMessage (event, params = {}) {
      params.uniqueComponentId = this.uniqueComponentId
      params.workplaceId = params.workplaceId || gon.application.current_user.workplace_id

      this.wsHandler.sendMessage(event, params)
    },

    loadAnalysisOrder () {
      this.sendMessage(EVENTS.FETCH_ANALYSIS_ORDER, {
        analysis_order_id: this.analysisOrderId,
      })
    },

    setSelectedContainers (containers) {
      this.selectedContainers = cloneDeep(containers)
    },

    containersCalculation () {
      if (this.hasErrors()) { return }

      const containersCalculationData = {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
        biomaterialContainersTypes: snakeToCamel(this.selectedContainers),
      }

      this.sendMessage(EVENTS.CALCULATION_CONTAINERS, containersCalculationData)
    },

    resetContainers () {
      this.sendMessage(EVENTS.DELETE_CONTAINERS, {
        analysis_order_id: this.analysisOrder.id,
        entry_ids: this.analysisOrder.entries.map((entry) => entry.id),
      })
    },

    requestPreanalytics () {
      this.sendMessage(EVENTS.PREANALYTICS_REQUEST, {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
      })
    },

    requestQuestions () {
      this.sendMessage(EVENTS.REQUEST_QUESTIONS, {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
      })
    },

    sendAnalysisOrder (validate = true) {
      this.sendingOrderBtnLoading = true

      if (this.isHelix && validate) return this.validateBarcodes()

      this.sendMessage(EVENTS.SEND_ANALYSIS_ORDER, {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
        workplaceId: this.workplaceId,
      })

      setTimeout(this.notifyAboutSendQueue, SEND_ORDER_MAX_WAIT_TIME_MS)
    },

    sendPreanalyticsRequest () {
      this.preanalyticsBtnLoading = true

      this.sendMessage(EVENTS.PREANALYTICS_REQUEST, {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
      })
    },

    healthCheckLabeller () {
      if (!this.labellerIntegrationEnabled) { return }

      this.sendMessage(EVENTS.HEALTH_CHECK_LABELLER, {
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
        clinic_id: gon.application.current_clinic.id,
      })
    },

    notifyAboutSendQueue () {
      if (this.sendingOrderBtnLoading) {
        Notificator.info(T.laboratories.send_order_request_in_queue)
      }
    },

    editAnalysisOrder () {
      Turbolinks.visit(Routes.edit_analysis_order_path(this.analysisOrder.id))
    },

    cancelAnalysisOrder () {
      this.cancelOrderBtnLoading = true

      this.sendMessage(EVENTS.CANCEL_ANALYSIS_ORDER, {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
      })
    },

    checkResult () {
      this.checkResultBtnLoading = true

      this.sendMessage(EVENTS.CHECK_RESULT, {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
      })
    },

    removeAnalysisOrder () {
      this.removeOrderBtnLoading = true

      this.sendMessage(EVENTS.REMOVE_ANALYSIS_ORDER, {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
      })
    },

    getConnectedWorkplaces () {
      this.sendMessage(EVENTS.GET_CONNECTED_WORKPLACES)
    },

    saveQuestionare (data) {
      this.sendMessage(EVENTS.SAVE_QUESTIONNAIRE, {
        analysis_order_id: this.analysisOrder.id,
        questions: data,
      })
    },

    printCoverLetter () {
      if (this.isKdl) {
        this.$pubSub.emitAsync(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.cover_letter_printed`)

        return
      }

      if (this.coverLetterAttachmentId) {
        this.$pubSub.emitAsync(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.cover_letter_printed`)
        window.open(Routes.show_analysis_order_attachment_path(this.coverLetterAttachmentId))
      } else {
        Notificator.error(T.laboratories.cover_letter_not_found)
      }
    },

    printAttachmentClicked (attachmentId) {
      if (parseInt(this.coverLetterAttachmentId) === parseInt(attachmentId)) {
        this.$pubSub.emitAsync(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.cover_letter_printed`)
      }
    },

    printBarcodes (biomaterialContainerId = undefined, workplaceId = null) {
      this.$pubSub.emitAsync(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.print_barcodes`)

      this.workplaceId = workplaceId

      this.sendMessage(EVENTS.PRINT_BARCODE, {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
        biomaterial_container_id: biomaterialContainerId,
        labeller_integration: this.labellerIntegrationEnabled,
        clinicTitle: gon.application.current_clinic.title,
        workplaceId,
      })

      Notificator.info(T.laboratories.added_to_print_queue)
    },

    validateBarcodes () {
      this.sendMessage(EVENTS.VALIDATE_BARCODE, {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
        biomaterial_containers: this.analysisOrder.biomaterialContainers,
      })
    },

    printLabeller (biomaterialContainerId = undefined) {
      const client = this.analysisOrder.client

      const params = {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
        biomaterial_container_id: biomaterialContainerId,
        labeller_integration: this.labellerIntegrationEnabled,
        workplaceId: this.workplaceId,
        client: {
          ...client,
          dob: client?.birthdate
            ? Utils.getFormattedDate(client.birthdate * 1000)
            : undefined,
          shortName: formatter.formatValue(this.analysisOrder?.client, ['user:shortName']),
        },
      }

      this.sendMessage(EVENTS.PRINT_BARCODE_LABELLER, params)

      Utils.reportSuccess(t('laboratories.labeller_status.success'))()
    },

    updateBiomaterialContainer (biomaterialContainer) {
      this.checkContainersHaveEntries()

      this.sendMessage(EVENTS.UPDATE_BIOMATERIAL_CONTAINER, {
        analysis_order_id: this.analysisOrder.id,
        analysis_laboratory_id: this.analysisOrder.analysisLaboratoryId,
        biomaterial_container_id: biomaterialContainer.id,
        entry_ids: biomaterialContainer.entryIds,
      })
    },

    checkContainersHaveEntries () {
      if (this.analysisOrder.biomaterialContainers.length > 0) {
        this.$pubSub.emitAsync(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.preanalytics`)
      }

      const allContainersHaveEntries = this.analysisOrder.biomaterialContainers.every(
        (container) => container.entryIds.length > 0
      )

      this.$pubSub.emitAsync(
        `${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.biomaterial_containers_changed`, { allContainersHaveEntries }
      )
    },

    requestAnalysisOrderSteps () {
      this.sendMessage(EVENTS.REQUEST_ANALYSIS_ORDER_STEPS, {
        analysis_order_id: this.analysisOrderId,
      })
    },

    disableLoadingButtons () {
      if (this.sendingOrderBtnLoading) {
        this.sendingOrderBtnLoading = false
      }

      if (this.cancelOrderBtnLoading) {
        this.cancelOrderBtnLoading = false
      }

      if (this.checkResultBtnLoading) {
        this.checkResultBtnLoading = false
      }

      if (this.removeOrderBtnLoading) {
        this.removeOrderBtnLoading = false
      }

      if (this.preanalyticsBtnLoading) {
        this.preanalyticsBtnLoading = false
      }
    },

    toCamelCase (string) {
      return string.toLowerCase().replace(
        /([-_][a-z])/g,
        (group) => group
          .toUpperCase()
          .replace('-', '')
          .replace('_', '')
      )
    },

    notificationTypes (analysisOrder) {
      const selectedTypes = []

      Object.keys(T.laboratories.notification_types).forEach((type) => {
        if (analysisOrder[this.toCamelCase(type)]) {
          selectedTypes.push(T.laboratories.notification_types[type])
        }
      })

      return selectedTypes.join(', ')
    },

    fetchClinics () {
      clinicsEndpoint.getAll({
        status: 'active',
      })
        .then((data) => {
          this.clinics = data.data
        })
        .catch((err) => Utils.reportError(
          'getClinics error',
          t('reception.errors.get_list')
        )(err))
    },

    subscribeToEvents () {
      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_ANALYSIS_ORDER}`, (data) => {
        if (parseInt(this.analysisOrderId) === parseInt(data.result.id)) {
          this.disableLoadingButtons()

          data.result.notificationTypes = this.notificationTypes(data.result)
          this.$set(this, 'analysisOrder', data.result)
          this.checkContainersHaveEntries()
          this.getCoverLetterAttachmentId()
          this.getConnectedWorkplaces()
          this.healthCheckLabeller()

          this.$updateSync('analysisOrder', data.result)
        }
      })

      // CU-bgwah1 - получение списка рабочих мест
      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.GET_CONNECTED_WORKPLACES}`, (data) => {
        this.workplaceId = gon.application.current_user.workplace_id
        this.workplaceList = data.result && data.result.workplaces
          ? data.result.workplaces
          : []

        this.workplaceListIsLoading = false
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.REQUEST_QUESTIONS}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          this.$pubSub.emitAsync(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.show_questionnaire`, data)
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.REQUEST_ANALYSIS_ORDER_STEPS}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          this.analysisOrderSteps = data.result
          this.$updateSync('analysisOrderSteps', data.result)
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.PREANALYTICS_REQUEST}`, (data) => {
        if (parseInt(this.analysisOrder.id) === parseInt(data.result.id)) {
          this.analysisOrder.biomaterialContainers = data.result.biomaterialContainers
          this.getCoverLetterAttachmentId()
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.HEALTH_CHECK_LABELLER}`, (data) => {
        if (data.requestStatus[0] === '2') {
          this.labellerConnected = true

          return
        }

        this.labellerConnected = false
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.ORDER_REMOVED}`, (data) => {
        if (parseInt(this.analysisOrder.id) === parseInt(data.result.analysis_order_id)) {
          Notificator.success(T.laboratories.order_removed)
          this.disableLoadingButtons()

          HistoryBrowser.goBackFrom('analysis_order')
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.CANCEL_ANALYSIS_ORDER}`, (data) => {
        if (parseInt(this.analysisOrder.id) === parseInt(data.result.analysis_order_id)) {
          this.analysisOrder.state = data.result.state

          Notificator.success(T.laboratories.order_canceled)
          this.disableLoadingButtons()
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.CHECK_RESULT}`, (data) => {
        if (parseInt(this.analysisOrder.id) === parseInt(data.analysis_order_id)) {
          this.disableLoadingButtons()
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.PRINT_BARCODE}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          if (data.error !== undefined) {
            Notificator.error(data.error)
          } else {
            data.result.forEach((barcode) => {
              const index = this.analysisOrder.biomaterialContainers.findIndex((container) => parseInt(container.id) === parseInt(barcode.id))
              this.analysisOrder.biomaterialContainers[index].label = barcode.label
            })
          }
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.WEBSOCKETS_CONNECTED}`, (data) => {
        this.loadAnalysisOrder()
        this.requestAnalysisOrderSteps()
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.ERROR}`, (data) => {
        const errorsHas = (uniqueComponentId) => this.errorsSet.has(uniqueComponentId)
        const errorsPush = (uniqueComponentId) => { this.errorsSet.add(uniqueComponentId) }
        const errorsPop = (uniqueComponentId) => { this.errorsSet.delete(uniqueComponentId) }

        const { uniqueComponentId } = data

        if (errorsHas(uniqueComponentId)) { return }
        errorsPush(uniqueComponentId)

        this.disableLoadingButtons()

        showOrderErrorNotification(data)

        this.errorEntriesNumbers = cloneDeep(data?.entry_numbers || [])

        setTimeout(() => { errorsPop(uniqueComponentId) }, 1000)
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.VALIDATE_BARCODE}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          if (this.barcodesValidated) return

          const { errors } = data

          if (errors.length) {
            this.disableLoadingButtons()

            errors.forEach((error) => {
              const index = this.analysisOrder.biomaterialContainers.findIndex(
                (container) => parseInt(container.id) === parseInt(error.id)
              )
              const container = this.analysisOrder.biomaterialContainers[index]

              Notificator.error(
                `${t('laboratories.barcode')} ${container.label} ${t(`laboratories.errors.barcode.${error.message}`)}`
              )

              this.$set(this.analysisOrder.biomaterialContainers[index], 'errors', errors)
            })
          } else {
            this.barcodesValidated = true
            this.sendAnalysisOrder(false)
          }
        }
      })
    },
  },
}
</script>
