<template>
  <div class="client-feedback-configuration">
    <div class="flex-form">
      <div class="form-label">
        Сотрудники, получающие уведомления по новым отзывам пациентов
      </div>
      <div class="form-value">
        <base-select
          v-model="selectedUsers"
          clearable
          multiple
          popper-class="user-dropdown-list"
          :disabled="forbidUI"
        >
          <el-option
            v-for="user in users"
            :key="user.id"
            :value="user.id"
            :label="user | shortName"
          >
            <div class="user-dropdown">
              <user-avatar
                :user-id="user.id"
                :params="{tag: user.avatar_tag, version: 'thumb25'}"
              />
              <span>
                {{ user | shortName }}
              </span>
            </div>
          </el-option>
        </base-select>
      </div>
    </div>
    <button
      :class="['btn btn-success btn-sm btn-with-icon', {disabled: forbidUI}]"
      @click="update"
    >
      <span class="btn-with-icon_icon fad fa-save" />
      <span class="btn-with-icon_text">{{ t('save') }}</span>
    </button>
  </div>
</template>

<script>
import nameFormatter from '@/vue_components/mixins/name_formatter.js'
import BaseSelect from '@/vue_components/common/select/base_select/base_select.vue'
import UserAvatar from '@/vue_components/user_avatar.vue'

export default {
  name: 'ClientFeedbacksConfiguration',
  components: {
    BaseSelect,
    UserAvatar,
  },
  mixins: [nameFormatter],
  data: () => ({
    selectedUsers: gon.specific.responsible_users_for_new_client_feedbacks,
    users: gon.specific.users,
    forbidUI: false,
  }),
  methods: {
    update () {
      if (this.forbidUI) return

      this.forbidUI = true

      $.ajax({
        url: Routes.client_feedbacks_configuration_path(gon.page.params.id),
        type: 'PATCH',
        data: {
          configuration: {
            responsible_users_for_new_client_feedback_ids: this.selectedUsers.toString(),
          },
        },
        success () {
          Notificator.success(T.change_successful)
        },
        error (err) {
          Notificator.error(T.error)
          console.log('error: ', err)
        },
        complete: () => {
          this.forbidUI = false
        },
      })
    },
  },
}
</script>
