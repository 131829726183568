import { addEntryPack } from '@/specific/entry_types_helpers/addEntryPack'
import { GLOBAL_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import { orderStartSpin } from '@/specific/orders_helpers/orderSpin'

/**
 * @param {IEntryPackEntryType[]} entryPackEntryTypes
 * @return {Promise<void>}
 */
export const orderAddEntryPackCallback = async (entryPackEntryTypes) => {
  const entryTypes = await addEntryPack(entryPackEntryTypes)
  if (!entryTypes?.length) { return }

  orderStartSpin()

  setTimeout(() => {
    PubSub.emit('page.form.order.orderEntryList.askAddEntries', {
      entryTypes,
      onComplete () {
        Utils.reportSuccess(t('entryPacks.successAdd'))()
      },
    })
  }, GLOBAL_DEBOUNCE_DEFAULT)
}
