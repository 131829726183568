import { MReportPresenterBase } from '@/_api/Reports/_base/MReportPresenterBase'
import { reportSalaryAdapter } from '@/_api/Reports/Salary/reportSalaryAdapter'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestError } from '@/_api/_requests/MRequestError'

export class MReportSalaryPresenter extends MReportPresenterBase {
  constructor () {
    super(Routes.report_salary_path)
  }

  generate (data, toClientAdapter = (data) => data) {
    const notification = new MRequestNotification(
      this.constructor.name,
      'generateMessage',
      'report'
    )

    return super.generate(reportSalaryAdapter.toServer(data))
      .then((response) => toClientAdapter(response))
      .catch(MRequestError.withDefault({ errors: true }, notification))
  }

  export (data = {}) {
    const adaptedData =
      reportSalaryAdapter.toExport(data)
    super.export(adaptedData, t('reports.salary'))
  }
}
