import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getMedicalPolicesSchema = () =>
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addHeaders({
          status: new MTableHeader(t('status')).addFixedWidth('100px'),
          number: new MTableHeader(t('common.number')).addFixedWidth('200px'),
          dateStart: new MTableHeader(t('start')).addFixedWidth('100px'),
          dateEnd: new MTableHeader(t('end')).addFixedWidth('100px'),
          insuranceCompany: new MTableHeader(t('insurance_company')),
          policyType: new MTableHeader(t('policy_type')).addFixedWidth('100px'),
        })
    )
