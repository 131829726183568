import Vue from 'vue/dist/vue.esm'
import RefuseResultsCatalog from './refuse_results'

export const VueRefuseResultsEntryPoint = () => {
  const el = '#vue_refuse_results_catalog'

  if (!document.querySelector(el)) return
  // eslint-disable-next-line no-new
  new Vue({
    el,
    render: (h) => h(RefuseResultsCatalog),
  })
}
