<template>
  <el-collapse-item>
    <template slot="title">
      <i class="fad fa-fw fa-city" />
      <span class="collapse-text">{{ T.exports_page.companyType.head }}</span>
    </template>
    <div class="company-type si-control vue-items-list">
      <div class="buttons vue-list">
        <button
          class="vue-list-item"
          @click="exportCompanyTypes()"
        >
          {{ T.download }}
        </button>
      </div>
    </div>
  </el-collapse-item>
</template>

<script>
import { companyTypes } from '../request_data.js'
import { headers } from '../const.js'
import eventMethods from '../events.js'

export default {
  name: 'CompanyTypesExport',

  props: {
    withLocale: {
      default: false,
      type: Boolean,
    },
  },

  methods: {
    ...eventMethods,

    exportCompanyTypes () {
      this.$emit('request-state')

      let data = {
        ...companyTypes,
        withLocale: this.withLocale,
      }

      fetch(Routes.exports_company_types_path(), {
        method: 'POST',
        headers: {
          ...headers,
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        credentials: 'same-origin',
        body: JSON.stringify(data),
      })
        .then(this._handleErrors)
        .then((result) => {
          Utils.downloadExportFile(result, T.exports_page.companyType.head)
        })
        .catch((error) => {
          this._errorNotify(error)
        })
        .finally(() => {
          this.$emit('request-state')
        })
    },
  },
}
</script>
