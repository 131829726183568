<template>
  <div class="egisz-client-sender">
    <select-clinic-popover
      :registration-status="isStatusSuccess"
      :current-clinic-id.sync="currentClinicId"
      :egisz-ready-clinics="egiszReadyClinics || []"
      @submitClient="sendClientToEgisz"
    >
      <template #body>
        <span class="prompt-notice font-size-12">
          * {{ t('egisz.components.egisz_client_sender.frmo_clinics') }}
        </span>
      </template>

      <template #reference>
        <div
          v-tooltip="registerDisabledTooltipOrFalse"
          class="inline-block"
        >
          <button
            v-loading="loading"
            type="button"
            class="btn btn-xs btn-with-icon btn-default"
            :disabled="Boolean(registerDisabledTooltipOrFalse)"
          >
            <i class="fad fa-share mr-5" />
            {{ btnSendText }}
          </button>
        </div>
      </template>
    </select-clinic-popover>

    <span class="ml-5">
      {{ t(`egisz.common_statuses.${registrationStatus}`) }}
    </span>
  </div>
</template>

<script>
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { clientsEndpoint } from '@/api_entities/clients/clients_endpoint'
import { mapGetters, mapMutations } from 'vuex'
import SelectClinicPopover
  from '@/vue_components/egisz/egisz_module/components/EgiszClients/EgiszClientsTable/SelectClinicPopover'
import { EGISZ_STATUSES } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'

export default {
  name: 'EgiszClientSender',
  components: { SelectClinicPopover },
  mixins: [SpinnerHolder],

  props: {
    clientId: {
      type: Number,
      required: true,
    },

    canManage: Boolean,
  },

  data () {
    return {
      currentClinicId: null,
      registrationStatus: EGISZ_STATUSES.NOT_REGISTERED,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      egiszReadyClinics: 'vxGetDoctorClinicsEgiszReady',
    }),

    ...mapGetters('globalCatalogs/clinicStore', {
      getClinicInFrmo: 'vxGetClinicInFrmo',
    }),

    ...mapGetters('egisz/egiszWs', {
      vxGetLastUser: 'vxGetLastUser',
    }),

    ...mapGetters('egisz/user', {
      canUserSendToEgisz: 'vxGetCanUserSendToEgiszValue',
    }),

    isStatusSuccess () {
      return this.registrationStatus === EGISZ_STATUSES.SUCCESS
    },

    btnSendText () {
      return this.isStatusSuccess
        ? t('egisz.components.egisz_clients_table.update')
        : t('egisz.components.egisz_clients_table.send')
    },

    registerDisabledTooltipOrFalse () {
      if (!this.canManage) {
        return t('insufficient_access_rights')
      }

      return false
    },
  },

  watch: {
    vxGetLastUser (to) {
      this.setCanUserSendToEgiszValue(to.registered_in_frmr)
    },
  },

  created () {
    this.initAccessRights()
    this.getEgiszClient()
    this.$store.dispatch('globalCatalogs/clinicStore/vxLoadClinicsInFrmo')
  },

  methods: {
    ...mapMutations('egisz/user', {
      vxSetAccessRights: 'vxSetAccessRights',
    }),

    initAccessRights () {
      this.setCanUserSendToEgiszValue(gon.application.current_user.registered_in_frmr)
    },

    setCanUserSendToEgiszValue (value) {
      this.vxSetAccessRights({ rule: 'canUserSendToEgisz', value })
    },

    getEgiszClient () {
      const promise = clientsEndpoint.getEgiszClientById(this.clientId)
        .then((data) => {
          this.registrationStatus = (data && data.registration_status) ||
              EGISZ_STATUSES.NOT_REGISTERED
        })
        .catch(Utils.reportError(
          'EgiszClientSender:getEgiszClient()',
          reportErrorText('read_message', 'client')
        ))

      this.withSpinner(promise)
    },

    sendClientToEgisz () {
      const data = {
        client_id: this.clientId,
        clinic_id: this.currentClinicId,
        clinic_lpu_id: this.getClinicInFrmo(this.currentClinicId).lpu_id,
      }

      const submitClient = this.isStatusSuccess
        ? clientsEndpoint.updatePatient
        : clientsEndpoint.addPatient

      const promise = submitClient(data)
        .then(() => {
          this.registrationStatus = EGISZ_STATUSES.SUCCESS
          const message = this.registrationStatus
            ? t('egisz.messages.success.client_updated')
            : t('egisz.messages.success.client_registered')
          Notificator.success(message)
        })
        .catch(Utils.reportError(
          'EgiszClients:registerClient()',
          reportErrorText('send_message', 'client')
        ))

      this.withSpinner(promise)
    },
  },
}
</script>
