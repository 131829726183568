import { ReportParamFactory, ReportParamStrict } from '../shared'
import { administratorsProductivityReportFiltersSelectors as selectors } from './filtersSelectors'
import { IAdministratorsProductivityReportFiltersGet } from './interfaces'

export const _getAdministratorsProductivityReportFilters = (): IAdministratorsProductivityReportFiltersGet => ({
  period: ReportParamFactory.getRawParam(selectors.period),
  clinic: ReportParamFactory.getSelectParam(selectors.clinic) as ReportParamStrict,
  countType: ReportParamFactory.getButtonsParam(selectors.countType) as ReportParamStrict,
  appointmentTypes: ReportParamFactory.getSelect2Params(selectors.appointmentTypes) as ReportParamStrict[],
})
