<template>
  <panel-heading
    id="analysis_order_show"
    :title="panelTitle"
    icon="fad fa-fw fa-flask"
    :panel-heading-class="{
      'hidden-print-fixed': true
    }"
  >
    <h3
      v-if="!isKdl"
      class="showed-print mt-0"
    >
      {{ panelTitle }}
    </h3>

    <analysis-order
      :analysis-order-id="analysisOrderId"
      :ws-handler="wsHandler"
      :analysis-order.sync="analysisOrder"
      :analysis-order-steps.sync="analysisOrderSteps"
      @onPreanalyticsArrived="onPreanalyticsArrived"
      @onQuestionnaireFilled="onQuestionnaireFilled"
    >
      <template #order-info-printable>
        <div
          v-if="!isKdl"
          id="order-info-printable"
          class="flex showed-print"
        >
          <div class="order-info-column mr-15">
            <div class="flex">
              <span class="bold title">{{ `${t('surname')}: ` }}</span>
              <span>{{ client.surname }}</span>
            </div>
            <div class="flex">
              <span class="bold title">{{ `${t('name')}: ` }}</span>
              <span>{{ client.name }}</span>
            </div>
            <div class="flex">
              <span class="bold title">{{ `${t('second_name')}: ` }}</span>
              <span>{{ client.secondName }}</span>
            </div>
            <div class="flex">
              <span class="bold title">{{ `${t('birthdate')}: ` }}</span>
              <span>{{ client.birthdate }}</span>
            </div>
            <div class="flex">
              <span class="bold title">{{ `${t('sex')}: ` }}</span>
              <span>{{ client.sex }}</span>
            </div>
            <div class="flex" />
          </div>

          <div class="order-info-column flex-grow-1">
            <div class="flex">
              <span class="bold title">{{ `${t('phone')}: ` }}</span>
              <span>{{ client.phone }}</span>
            </div>
            <div class="flex">
              <span class="bold title">{{ `${t('email')}: ` }}</span>
              <span>{{ client.email }}</span>
            </div>
            <div class="flex">
              <span class="bold title">{{ `${t('contractor')}: ` }}</span>
              <span>{{ contractor }}</span>
            </div>
            <div class="flex">
              <span class="bold title">{{ `${t('sampling_place')}: ` }}</span>
              <span>{{ samplingPlace }}</span>
            </div>
            <div class="flex">
              <span class="bold title">{{ `${t('date_of_issue')}: ` }}</span>
              <span>{{ createdAt }}</span>
            </div>
            <div class="flex">
              <span class="bold title">{{ `${t('deadlines')}: ` }}</span>
              <span>{{ `${maxDue} ${t('day_short')}` }}</span>
            </div>
          </div>
        </div>

        <div
          v-if="!isKdl"
          class="showed-print mt-10"
        >
          {{ t('laboratories.helix_result') }}
        </div>
      </template>

      <template #biomaterials-info-printable>
        <h4
          v-if="!isKdl"
          class="showed-print"
        >
          {{ t('laboratories.samples') }}
        </h4>
      </template>

      <template #client-signature-info-printable>
        <div
          v-if="!isKdl"
          class="client-signature showed-print flex-column"
        >
          <p>{{ t('laboratories.client_warning') }}</p>
          <p>{{ t('laboratories.client_signature') }}</p>
        </div>
      </template>
    </analysis-order>

    <template #buttons>
      <m-button
        v-if="isHelix"
        :text="t('laboratories.print_blank')"
        icon="print"
        type="dark"
        size="mini"
        button-style="fill"
        @click="onPrint"
      />
    </template>
  </panel-heading>
</template>

<script>
import AnalysisOrder from '@/vue_components/analysis_orders/analysis_order'
import PanelHeading from '@/vue_components/common/panel_heading'
import { LABORATORY_SYSTEM_NAMES, QR } from '@/vue_components/analysis_orders/constants'
import qrcode from 'qrcode-generator'
import { transformClient } from '@/vue_components/analysis_orders/logic'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

/**
 * Специальная обёртка для того чтобы не сломать оригинальный analysis_order.vue
 * Добавляет новый вид формы печати для хеликса+labeller
 */
export default {
  name: 'AnalysisOrderShow',
  components: { MButton, PanelHeading, AnalysisOrder },
  props: {
    currentClinicTitle: PropsTypes.String(),

    analysisOrderId: {
      default: null,
      type: String,
    },

    wsHandler: {
      default: null,
      type: Object,
    },
  },

  data () {
    return {
      analysisOrder: {},
      analysisOrderSteps: [],
      questionnaireFilled: false,
      preanalyticsArrived: false,
      labellerConnected: false,
    }
  },

  computed: {
    orderNumber () {
      return this.analysisOrderId
    },

    panelTitle () {
      return this.orderNumber
        ? `${t('laboratories.order')} № ${this.orderNumber}`
        : t('laboratories.analysis_order')
    },

    isHelix () {
      return this.analysisOrder.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.HELIX
    },

    isKdl () {
      return this.analysisOrder.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.KDL ||
      this.analysisOrder.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.KDL_CHECKUPS
    },

    client () {
      return transformClient(this.analysisOrder.client)
    },

    contractor () {
      return this.currentClinicTitle
    },

    samplingPlace () {
      return this.currentClinicTitle
    },

    createdAt () {
      return this.analysisOrder.date
        ? Utils.getFormattedDate(this.analysisOrder.date * 1000, Utils.momentDateTimeFormat)
        : null
    },

    entries () {
      return this.analysisOrder.entries || []
    },

    maxDue () {
      return Math.max(...this.entries.map((entry) => parseInt(entry.dueDate) || 0))
    },

    biomaterialContainers () {
      return this.analysisOrder.biomaterialContainers || []
    },

    requireLabellerPrintWarning () {
      return (this.preanalyticsArrived && this.questionnaireFilled) || this.preanalyticsArrived
    },

    labellerIntegrationEnabled () {
      return gon.specific.labeller_integration
    },
  },

  watch: {
    analysisOrder () {
      if (!this.orderNumber) { return }
      if (!this.labellerIntegrationEnabled) { return }

      this.generateQr()
    },
  },

  methods: {
    onPreanalyticsArrived () {
      this.preanalyticsArrived = true
    },

    onQuestionnaireFilled () {
      this.questionnaireFilled = true
    },

    generateQr () {
      const qr = qrcode(QR.TYPE_NUMBER, QR.ERROR_CORRECTION_LEVEL)
      qr.addData(this.orderNumber)
      qr.make()
      document.querySelector('#order-number-qr').innerHTML = qr.createSvgTag()
    },

    async onPrint () {
      if (this.requireLabellerPrintWarning) {
        print()

        return
      }

      await this.$confirm(t('laboratories.table.print_warning'), t('warning'), {
        confirmButtonText: t('yes'),
        cancelButtonText: t('no'),
        type: 'warning',
      })

      print()
    },
  },
}
</script>
