<template>
  <m-select
    v-bind="$attrs"
    :items="reportSources"
    :label="t('reports.reportSourceFilter.label')"
    :class="$attrs.css"
    :clearable="false"
    v-on="$listeners"
  />
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { REPORT_SOURCE_FILTERS } from '@/vue_apps/ReportsModule/_filters/components/ReportSourceFilter/const'

export default {
  name: 'ReportSourceFilter',
  components: { MSelect },
  computed: {
    reportSources () {
      return REPORT_SOURCE_FILTERS()
    },
  },
}
</script>
