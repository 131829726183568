export default {
  clinicId: {
    type: Number,
    required: true,
  },
  errors: {
    type: Object,
    required: true,
  },
  targetedDisable: {
    type: Object,
    default () {
      return {}
    },
  },
  disabled: {
    type: Boolean,
  },
  doctor: {
    type: Object,
    default: () => null,
  },
  client: {
    type: Object,
    default: () => null,
  },
  title: {
    type: String,
    required: true,
  },
  date: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: null,
  },
  status: {
    type: String,
    default: null,
  },
  /**
   * @type {import('vue').PropOptions<{
   *  id: number,
   *  name?: string,
   *  surname: string,
   *  second_name?: string,
   *  phone?: string,
   *  birthdate?: string
   * }[]>}
   */
  responsible: {
    type: Array,
    required: true,
  },
  note: {
    type: String,
    required: true,
  },
  /**
   * Date string
   */
  createdAt: {
    type: String,
    default: '',
  },
  /**
   * Date string
   */
  updatedAt: {
    type: String,
    default: '',
  },
  /**
   * User name in short format (surname and initials)
   */
  createdBy: {
    type: String,
    default: '',
  },
  /**
   * User name in short format (surname and initials)
   */
  updatedBy: {
    type: String,
    default: '',
  },
  /**
   * Array of {id: string, title: string}
   */
  types: {
    type: Array,
    required: true,
  },
  /**
   * Array of {id: string, title: string}
   */
  statuses: {
    type: Array,
    required: true,
  },
}
