<template>
  <m-panel
    class="client-treatment-plans w-100"
    :title="t('treatment_plans')"
    icon="treatmentPlan"
  >
    <treatment-plans-list-view
      :items="list.data"
      :schema="schema"
      :current-page="list.currentPage"
      :total-pages="list.totalPages"
      @onItemClick="onItemClick"
      @onRefreshClick="list.refreshFilters()"
      @onResetClick="list.resetFilters()"
      @update:currentPage="list.setPage($event)"
    >
      <template #filters>
        <m-button
          v-if="isMedBranch"
          :tooltip="t('add_tp_medical')"
          icon="briefcase"
          plus-icon
          type="success"
          disabled="TreatmentPlan"
          @click="addTreatmentPlan('medical')"
        />

        <m-button
          v-if="isDentBranch"
          :tooltip="t('add_tp_dental')"
          icon="tooth"
          plus-icon
          type="success"
          disabled="TreatmentPlan"
          @click="addTreatmentPlan('dental')"
        />

        <treatment-plans-client-filters
          :filters-data="list.filtersData"
          @onSetFilterValue="list.setFilterValue($event.filter, $event.value)"
        />
      </template>
    </treatment-plans-list-view>

    <template #headExtra>
      <m-button
        button-style="fill"
        type="dark"
        size="mini"
        :text="t('print')"
        icon="print"
        @click="onPrintClick"
      />

      <m-button
        button-style="fill"
        type="dark"
        size="mini"
        :text="t('export')"
        icon="export"
        @click="onExportClick"
      />
    </template>
  </m-panel>
</template>

<script>
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import TreatmentPlansListView from '@/vue_apps/TreatmentPlans/TreatmentPlansList/components/TreatmentPlansListView.vue'
import {
  clientTreatmentPlansListSchema,
} from '@/vue_apps/TreatmentPlans/TreatmentPlansList/store/TreatmentPlansListSchema'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { TreatmentPlansList } from '@/vue_apps/TreatmentPlans/TreatmentPlansList/store/TreatmentPlansList'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import TreatmentPlansClientFilters
  from '@/vue_apps/TreatmentPlans/TreatmentPlansList/components/TreatmentPlansClientFilters.vue'

export default defineComponent({
  name: 'TreatmentPlansListClientApp',
  components: { TreatmentPlansClientFilters, MButton, TreatmentPlansListView, MPanel },
  props: {
    clientId: PropsTypes.Number(),
    isMedBranch: Boolean,
    isDentBranch: Boolean,
  },

  data () {
    return {
      list: new TreatmentPlansList(this.clientId),
      schema: clientTreatmentPlansListSchema,
    }
  },

  computed: {
    filters () {
      return {
        ...this.list.filtersData,
        searchQuery: this.list.searchQuery,
        offset: this.list.offset,
        limit: this.list.limit,
      }
    },
  },

  watch: {
    filters () {
      this.fetchAll()
    },
  },

  created () {
    this.fetchAll()
  },

  methods: {
    fetchAll () {
      this.list.fetchAll()
    },

    addTreatmentPlan (tpType = 'medical') {
      window.open(
        Routes.new_treatment_plan_path({ client_id: this.clientId, kind: tpType }),
        '_blank'
      ).focus()
    },

    onItemClick ({ id }) {
      Turbolinks.visit(Routes.treatment_plan_path(id))
    },

    onPrintClick () {
      window.print()
    },

    onExportClick () {
      this.list.exportToCSV()
    },
  },
})
</script>
