<template>
  <div
    id="attachments_container"
    class="panel panel-primary panel-inner"
  >
    <panel-heading
      :title="T.attached_files"
      icon="fad fa-file-alt"
    >
      <template #buttons>
        <el-button
          v-if="coverLetterEnabled && !isKdl"
          :class="['btn', 'btn-grey-dark', 'btn-xs']"
          icon="fad fa-envelope"
          @click="$emit('print-cover-letter')"
        >
          {{ T.laboratories.print_cover_letter }}
        </el-button>
      </template>

      <div id="attachments_table">
        <div id="attachments_table_header">
          <div class="attachment_row">
            <div
              class="date"
            >
              {{ T.common.date }}
            </div>
            <div class="title">
              {{ T.title }}
            </div>
            <div class="options" />
            <div class="options" />
          </div>
        </div>

        <div id="attachments_table_body">
          <div
            v-for="attachment in attachments"
            :key="attachment.id"
            class="attachment_row"
          >
            <div class="date">
              {{ attachment.updatedAt | formattedDate }}
            </div>
            <div
              class="title "
            >
              <span
                v-tooltip="attachment.name"
                class="tooltip-bottom"
              >
                <a
                  data-mimetype="application/pdf"
                  data-no-turbolink="true"
                  class="attachment-preview-pdf "
                  :href="remoteFileUrl(attachment.id)"
                >
                  {{ attachment.name }}
                </a>
              </span>
            </div>
            <div class="options">
              <button
                v-tooltip="t('epic_download')"
                class="btn btn-grey-dark btn-xs"
              >
                <a
                  class="attachment-preview-btn attachment-preview-inline-download fad fa-download"
                  data-turbolinks="false"
                  :download="attachment.name"
                  :href="remoteFileUrl(attachment.id)"
                />
              </button>
            </div>
            <div class="options">
              <button
                v-tooltip="t('print')"
                class="btn btn-grey-dark btn-xs"
              >
                <span
                  class="attachment-preview-btn fad fa-print"
                  @click="printAttachment(attachment.id)"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </panel-heading>
  </div>
</template>
<script>
import PanelHeading from '../../common/panel_heading'
import { STEPS } from '../constants.js'

export default {
  name: 'AttachmentPanel',
  components: {
    PanelHeading,
  },

  filters: {
    formattedDate (date) {
      return moment(date).format(gon.localization.date_format.toUpperCase().replace(/\//g, '.'))
    },

  },

  props: {
    steps: {
      default: () => [],
      type: Array,
    },

    attachments: {
      default: () => [],
      type: Array,
    },

    isKdl: Boolean,
  },

  computed: {
    coverLetterEnabled () {
      return this.steps.includes(STEPS.PRINT_COVER_LETTER)
    },
  },

  mounted () {
    this.subscribeToEvents()
    this.addPreview()
  },

  updated () {
    this.addPreview()
  },

  methods: {
    remoteFileUrl (id) {
      return Routes.show_analysis_order_attachment_path(id)
    },

    addPreview () {
      $('.attachment-preview-pdf').attachmentPreview({
        type: 'application/pdf',
        hideDownloadBtn: true,
        hidePrintBtn: true,
      })
    },

    printAttachment (attachmentId) {
      this.$emit('print-attachment-clicked', attachmentId)

      window.printJS(this.remoteFileUrl(attachmentId), 'pdf')
    },

    subscribeToEvents () {},
  },
}
</script>
