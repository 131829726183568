import {
  TREATMENT_PLANS_STATUSES,
  TREATMENT_PLANS_TYPES,
} from '@/vue_apps/TreatmentPlans/TreatmentPlansList/const/const'
import { statusIcons, typeIcons } from '@/vue_apps/TreatmentPlans/TreatmentPlansList/const/treatmentPlansIcons'

export const treatmentPlansTypes = Object.freeze([
  {
    id: TREATMENT_PLANS_TYPES.MEDICAL,
    title: t(TREATMENT_PLANS_TYPES.MEDICAL, { scope: 'treatmentPlanTypes' }),
    icon: typeIcons[TREATMENT_PLANS_TYPES.MEDICAL],
  },

  {
    id: TREATMENT_PLANS_TYPES.DENTAL,
    title: t(TREATMENT_PLANS_TYPES.DENTAL, { scope: 'treatmentPlanTypes' }),
    icon: typeIcons[TREATMENT_PLANS_TYPES.DENTAL],
  },
])

export const treatmentPlansStatuses = Object.freeze([
  {
    id: TREATMENT_PLANS_STATUSES.DRAFT,
    title: t(TREATMENT_PLANS_STATUSES.DRAFT, { scope: 'treatmentPlanStatuses' }),
    icon: statusIcons[TREATMENT_PLANS_STATUSES.DRAFT],
  },

  {
    id: TREATMENT_PLANS_STATUSES.APPROVES,
    title: t(TREATMENT_PLANS_STATUSES.APPROVES, { scope: 'treatmentPlanStatuses' }),
    icon: statusIcons[TREATMENT_PLANS_STATUSES.APPROVES],
  },

  {
    id: TREATMENT_PLANS_STATUSES.IN_PROGRESS,
    title: t(TREATMENT_PLANS_STATUSES.IN_PROGRESS, { scope: 'treatmentPlanStatuses' }),
    icon: statusIcons[TREATMENT_PLANS_STATUSES.IN_PROGRESS],
  },

  {
    id: TREATMENT_PLANS_STATUSES.READY,
    title: t(TREATMENT_PLANS_STATUSES.READY, { scope: 'treatmentPlanStatuses' }),
    icon: statusIcons[TREATMENT_PLANS_STATUSES.READY],
  },

  {
    id: TREATMENT_PLANS_STATUSES.CANCELED,
    title: t(TREATMENT_PLANS_STATUSES.CANCELED, { scope: 'treatmentPlanStatuses' }),
    icon: statusIcons[TREATMENT_PLANS_STATUSES.CANCELED],
  },
])
