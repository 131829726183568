<template>
  <m-select-lazy
    :fetch-method="(params) => presenter.list(params)"
    :search-method="(params) => presenter.list(params)"
    use-first-time-fetch
    option-label="fullName"
    use-user-mode
    filterable
    :label="label"
    :placeholder="placeholder"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MUserPresenter } from '@/_api/MUser/MUserPresenter'

export default {
  name: 'UserFilter',
  components: { MSelectLazy },
  data () {
    return {
      presenter: new MUserPresenter(),
    }
  },

  computed: {
    label () {
      return this.$attrs.multiply
        ? t('users')
        : t('user')
    },

    placeholder () {
      return this.$attrs.placeholder || t('all_employees')
    },
  },

}
</script>
