import {
  Tax2024ListItem,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/Tax2024ListItem'

export const tax2024ListAdapter = {

  toClient (response) {
    return {
      ...response,
      data: (response.data || []).map((item) => new Tax2024ListItem(item)),
    }
  },

  toServer (payload) {
    return {
      date: payload.period,
      clientId: payload.clientId,
      sort: { byDate: 'desc' },
      withLegalRepresentatives: payload.withLegalRepresentatives,
      offset: payload.offset,
      limit: payload.limit,
    }
  },
}
