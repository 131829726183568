export const getGoods = (params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: Routes.order_goods_json_stocks_path(),
      data: params,
      method: 'POST',
      success: resolve,
      error: reject,
    })
  })
}

export const getCategoriesTree = () => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: Routes.goods_category_tree_categories_path(),
      method: 'POST',
      success: resolve,
      error: reject,
    })
  })
}
