import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import {
  getAnalysisOrderIcon,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AnalysisOrdersTab/const/getAnalysisOrderIcon'

export class ClientAnalysisOrderListItem extends MListServiceItem {
  /**
   * @param {{
   *   date: string,
   *   id: number,
   *   laboratory: {
   *     id: number,
   *     title: string,
   *   }
   *   state: string,
   * }} data
   */
  constructor (data) {
    super()
    this.id = data.id

    this.date = new MTableCell(vueFilters.date(data.date))

    const icon = getAnalysisOrderIcon(data.state)
    this.statusIcon = new MTableCell()
      .addTypeIcon(icon.icon)
      .addClasses(icon.css)

    this.status = new MTableCell(t(data.state, { scope: 'enums.analysis_order.state' }))

    this.analysisLaboratoryTitle = new MTableCell(data.laboratory?.title)
  }
}
