import { CASES, CLIENTS, EGISZ_MED_DOCUMENTS, EGISZ_SEMDS } from '@/vue_components/egisz/egisz_module/router/route_names'

/**
 * @typedef {{
 *   isCategory: Boolean,
 *   title: String
 * }} NavRouteCategory
 *
 * isCategory: Boolean(true) - указывает меню, что нужно отрисовать категорию <br>
 * title: название категории меню <br>
 */

/**
 * @typedef {{
 *   index: String,
 *   routerName: String,
 *   title: String,
 *   icon: String
 * }} NavRouteItem
 *
 * index: уникальное имя для меню <br>
 * routerName: имя роута (vue-router) <br>
 * title: название пункта меню <br>
 * icon: классы иконки в пункте меню <br>
 */

const getSemdRoute = () => {
  return gon.application.semd_module_enabled
    ? [{ index: EGISZ_SEMDS.INDEX, routerName: EGISZ_SEMDS.TABLE, title: t('egisz.routes.semds'), icon: 'fad fa-fw fa-files-medical' }]
    : []
}

/**
 * Конфиг главного меню
 *
 * @return {Array<NavRouteCategory | NavRouteItem>} navRoutes
 */
export const navRoutes = () => ([
  { isCategory: true, title: t('egisz.categories.main') },
  { index: CLIENTS.INDEX, routerName: CLIENTS.TABLE, title: t('egisz.routes.clients'), icon: 'fad fa-fw fa-user' },
  { index: CASES.INDEX, routerName: CASES.TABLE, title: t('egisz.routes.cases'), icon: 'fad fa-fw fa-notes-medical' },
  { index: EGISZ_MED_DOCUMENTS.INDEX, routerName: EGISZ_MED_DOCUMENTS.TABLE, title: t('egisz.routes.med_records'), icon: 'fad fa-fw fa-files-medical' },
  ...getSemdRoute(),
])
