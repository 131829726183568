import { MReportPresenterBase } from '@/_api/Reports/_base/MReportPresenterBase'
import { loyalReportAdapter } from '@/vue_apps/ReportsModule/LoyalReport/api/loyalReportAdapter'
import { snakeToCamel } from '@/_api/_requests/helpers'

export class MLoyalReportPresenter extends MReportPresenterBase {
  constructor () {
    super(Routes.report_customer_loyalty_path)
  }

  list (data, config = {}) {
    return super.list(data, {
      toServerAdapter: loyalReportAdapter.toServer,
      toClientAdapter: (toClientData) => loyalReportAdapter.toClient(snakeToCamel(toClientData), data),
      ...config,
    })
  }

  export (data = {}, fileName) {
    super.export(data, fileName)
  }
}
