<template>
  <modal
    :modal-class="'add-vacation-modal'"
    :modal-visibility="showAddVacationModal"
    @close="$pubSub.emit('EMPLOYEES_SCHEDULE.HIDE_ADD_VACATION_MODAL')"
  >
    <span slot="header">{{ T.add_vacation_modal }}</span>
    <add-vacation-modal-body
      slot="body"
      ref="addVacationModal"
      v-bind="addVacationModalData"
    />
    <add-vacation-modal-footer
      slot="footer-right"
      @add="$refs.addVacationModal.addVacation()"
    />
  </modal>
</template>

<script>
import Modal from '../../../modal.vue'
import AddVacationModalBody from './add_vacation_modal_body.vue'
import AddVacationModalFooter from './add_vacation_modal_footer.vue'

export default {
  name: 'AddVacationModal',
  components: {
    Modal,
    AddVacationModalBody,
    AddVacationModalFooter,
  },
  props: {
    showAddVacationModal: { type: Boolean, required: true },
    addVacationModalData: { type: Object, required: true },
  },
}
</script>
