import { request } from '@/lib/transport/request'

export const doctorAreasEndpoint = {
  getInitialData () {
    return request({
      url: Routes.doctor_areas_init_path(),
      type: 'GET',
    }).promise
  },
}
