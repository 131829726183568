import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { mCategoriesAdapter } from '@/_api/EntryTypesCategories/mCategoriesAdapter'
import { GLOBAL_CATEGORY_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'

export class MEntryTypesCategoriesPresenter extends MPresenterBase {
  constructor () {
    super({
      location: 'MEntryTypesCategoriesPresenter',
      entity: 'category',
      routes: { all: Routes.entry_types_categories_path, one () {} },
    })
  }

  /**
   * @param data
   * @param config
   * @returns { Promise<TreeNestedCategories> }
   */
  async __fetchNode (data, config = {}) {
    return this.fetchAll(
      data,
      {
        ...config,
        toClientAdapter: mCategoriesAdapter.toClient,
      }
    ).then((data) => {
      if (!data?.errors) { return data }

      return {
        catalogs: [],
        current: null,
        items: [],
      }
    })
  }

  /**
   * @param data
   * @param config
   * @returns { Promise<TreeNestedCategories> }
   */
  async fetchEntryTypes (data, config = {}) {
    return this.__fetchNode({
      ...data,
      categoryType: GLOBAL_CATEGORY_KINDS.ENTRY_TYPE,
    }, config)
  }
}
