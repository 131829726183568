export const statusClass = Object.freeze({
  need_approval: 'schedule-grid-appointment-status-need-approval',
  confirmed_by_administrator: 'schedule-grid-appointment-status-confirmed-by-administrator',
  approved: 'schedule-grid-appointment-status-approved',
  arrived: 'schedule-grid-appointment-status-arrived',
  serviced: 'schedule-grid-appointment-status-serviced',
  billed: 'schedule-grid-appointment-status-billed',
  paid: 'schedule-grid-appointment-status-paid',
  canceled: 'schedule-grid-appointment-status-canceled',
  failed: 'schedule-grid-appointment-status-failed',
})

export const statusIconClass = Object.freeze({
  need_approval: 'fad fa-circle fa-swap-opacity',
  confirmed_by_administrator: 'fad fa-adjust',
  approved: 'fad fa-circle',
  arrived: 'fad fa-user',
  serviced: 'fad fa-user-md',
  billed: 'fad fa-credit-card',
  paid: 'fad fa-check-square',
  canceled: 'fad fa-times',
  failed: 'fad fa-user-times',
})

export const statuses = Object.freeze(
  Object.keys(statusClass).map((status) => ({
    class: statusClass[status],
    icon: statusIconClass[status],
  }))
)
