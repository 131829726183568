import { MListService } from '@/_api/_requests/MListService'
import { getDefaultFilters } from '@/vue_apps/Protocols/const/getDefaultFilters'
import { MProtocolPresenter } from '@/vue_apps/Protocols/api/MProtocolPresenter'

export class ProtocolsList extends MListService {
  constructor (entryId) {
    super(
      getDefaultFilters(),
      new MProtocolPresenter()
    )

    this.setFilterValue('entryIds', [entryId])
  }

  /**
   * @param {ProtocolAPI} protocol
   */
  prepend (protocol) {
    this.data.unshift(protocol)
  }
}
