import { createVueApp } from '@/vue_components/create_vue_app'
import ClientFeedbacks from '@/vue_apps/ClientsModule/components/Feedbacks/ClientFeedbacks.vue'

let vueApp = null

export const createClientFeedbacksVue = (el = '#vue_client_client_feedbacks_root') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    name: 'ClientFeedbacksApp',
    render: (h) => h(ClientFeedbacks, {
      props: {
        clientId: +gon.page.params.id,
      },
    }),
  })
}

$document.on('rez/clients/feedbacks', () => {
  createClientFeedbacksVue()
})
