import { createVueApp } from '@/vue_components/create_vue_app'
import DocumentApp from '@/vue_apps/ClientsModule/components/Documents/DocumentApp.vue'

let vueApp = null
export const createClientsModuleDocumentsApp = (el = '#vue_clients_module_documents_root') => {
  if (!document.querySelector(el)) {
    return
  }
  if (vueApp?.destroy) {
    vueApp.destroy()
  }

  vueApp = createVueApp({
    el,
    name: 'ClientsModuleDocumentsApp',
    render: (h) => h(DocumentApp),
  })
}
