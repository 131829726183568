import {
  IAttachmentTabHyperlinkToServerDTO,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/interfaces/IAttachmentTabHyperlinkToServerDTO'

export const attachmentTabHyperlinkAdapter = {
  toServer (data: IAttachmentTabHyperlinkToServerDTO) {
    return {
      title: data.title || data.link, // Если описание не заполнено, то при сохранении добавлять текст из поля "Адрес ссылки"
      link: data.link,
      clientId: data.clientId,
    }
  },
}
