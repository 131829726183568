export const taxCertificatesReportDocumentTypes = Object.freeze([
  {
    id: '0',
    title: t('taxCertificatesReport.documentKinds.primary'),
  },
  {
    id: '1',
    title: t('taxCertificatesReport.documentKinds.correction'),
  },
  {
    id: '2',
    title: t('taxCertificatesReport.documentKinds.annulling'),
  },
])

export const taxCertificatesReportStatuses = Object.freeze([
  {
    id: 'paperCarrier',
    title: t('taxCertificatesReport.certificateStatuses.paperCarrier'),
  },
  {
    id: 'xmlFileGenerated',
    title: t('taxCertificatesReport.certificateStatuses.xmlFileGenerated'),
  },
])
