import { createVueApp } from '@/vue_components/create_vue_app'
import { createStore } from '@/vue_components/store/store'

export const createReport = (renderedComponent, options = {}, appOptions = {}) => {
  return createVueApp({
    el: '#report_right_container',
    name: 'ReportContainerRoot',
    store: createStore(),
    render: (h) => h(renderedComponent, options),
    ...appOptions,
  })
}
