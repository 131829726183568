<template>
  <span :class="containerIcon">
    {{ containerIconText }}
  </span>
</template>

<script>
import { HELIX_CONTAINER_CODES, HELIX_CONTAINER_TEXT } from '@/vue_components/analysis_orders/const/helix'
import { INVITRO_CONTAINER_CODES } from '@/vue_components/analysis_orders/const/invitro'
import { CONTAINER_ICONS_TEXTS, LABORATORY_SYSTEM_NAMES } from '@/vue_components/analysis_orders/constants'

export default {
  name: 'BiomaterialContainerIcon',
  props: {
    containerCode: {
      type: String,
      default: null,
    },

    analysisLaboratorySystemName: {
      type: String,
      default: null,
    },
  },

  computed: {
    containerIcon () {
      if (!this.analysisLaboratorySystemName) {
        return ''
      } else if (this.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.HELIX) {
        return HELIX_CONTAINER_CODES.hasOwnProperty(this.containerCode)
          ? (HELIX_CONTAINER_CODES[this.containerCode] + ' circle-icon')
          : ''
      } else if (this.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.INVITRO) {
        let colorCode = this.defineContainerCode()
        if (!colorCode) { return }

        return INVITRO_CONTAINER_CODES[colorCode][this.containerCode] + ' circle-icon'
      }
    },

    containerIconText () {
      if (!this.analysisLaboratorySystemName) {
        return ''
      } else if (this.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.HELIX) {
        return HELIX_CONTAINER_TEXT[this.containerCode]
      } else if (this.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.INVITRO) {
        let colorCode = this.defineContainerCode()
        if (!colorCode) { return }

        return CONTAINER_ICONS_TEXTS[colorCode]
      }
    },
  },

  methods: {
    defineContainerCode () {
      for (let code in INVITRO_CONTAINER_CODES) {
        if (INVITRO_CONTAINER_CODES[code].hasOwnProperty(this.containerCode)) {
          return code
        }
      }
    },
  },
}
</script>
