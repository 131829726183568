import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import {
  TreatmentPlansReportItemCells,
} from '@/vue_apps/ReportsModule/TreatmentPlansReports/classes/TreatmentPlansReportItemCells'
import {
  TreatmentPlansReportTotalCells,
} from '@/vue_apps/ReportsModule/TreatmentPlansReports/classes/TreatmentPlansReportTotalCells'
import { DateHelpers } from '@/helpers/DateHelpers'
import { extractId, orUndefined } from '@/_medods_standart_library/msl'
import { MAX_LIMIT } from '@/specific/reports/const'
import { createStore } from '@/vue_components/store/store'

/**
 * Хак для вывода планов лечения по клиникам, в которых работает врач
 *
 * @param {{ id: number }} clinic - фильтрация по-умолчанию по 1 клинике
 * @return {{ clinicIds: number[], withoutClinic?: boolean }}
 */
const extractClinicIds = (clinic) => {
  if (clinic?.id) {
    return {
      clinicIds: [clinic.id],
    }
  }

  const store = createStore()

  return {
    clinicIds: store.getters['globalCatalogs/doctorStore/vxGetDoctorClinicsIds'] || [],
    withoutClinic: true,
  }
}

export const treatmentPlansReportAdapter = {
  /**
   * @param {ITreatmentPlansReportResponse} response
   * @param limit
   * @return {ITreatmentPlansReportList}
   */
  toClient (response, limit = DEFAULT_LIMIT) {
    return {
      data: (response.data || [])
        .map((item) => new TreatmentPlansReportItemCells(item.treatmentPlan)),
      total: new TreatmentPlansReportTotalCells(response.total, response.count),
      totalItems: response.count,
      totalPages: Math.floor(response.count / limit) + 1,
    }
  },

  toServer (data) {
    const period = DateHelpers.toBasePeriod(data.period, Utils.longDateFormat).join(' - ')
    const { clinicIds, withoutClinic } = extractClinicIds(data.clinic)

    return {
      offset: data.offset,
      limit: data.limit,

      clinicIds,
      withoutClinic,

      kinds: data.treatmentPlanKinds ? [data.treatmentPlanKinds.id] : undefined,
      statuses: orUndefined((data.treatmentPlanStatuses || []).map(extractId)),

      doctorIds: data.doctor
        ? [data.doctor.id]
        : undefined,

      responsibleIds: data.responsible
        ? [data.responsible.id]
        : undefined,

      report: { period },
      sorting: orUndefined(data.__sorting),
    }
  },

  toExport (data) {
    return {
      ...treatmentPlansReportAdapter.toServer(data),
      offset: 0,
      limit: MAX_LIMIT,
      headerRow: {
        treatmentPlanId: '№',
        treatmentPlanKind: 'Тип',
        treatmentPlanStatus: 'Статус',
        treatmentPlanDate: 'Дата',
        treatmentPlanTitle: 'Наименование',
        clientFullName: 'Пациент',
        doctorFullName: 'Исполнитель',
        responsibleFullName: 'Ответственный',
        treatmentEntriesAmount: 'Услуг в плане',
        treatmentPlanFinalSum: 'Сумма к оплате',
        treatmentEntriesCreatedAmount: 'Услуг создано',
        treatmentEntriesCreatedTotalSum: 'Сумма созданных услуг',
        treatmentEntriesPaidSum: 'Оплачено',
        treatmentEntriesUnpaidSum: 'Неоплачено',
        clientsFinance: 'Остаток на балансе',
        treatmentPlanTotal: 'Итого по плану',
      },
      treatmentPlanStatus: {
        draft: 'Черновик',
        approves: 'Одобрен',
        ready: 'Готов',
        inProgress: 'Исполняется',
        canceled: 'Отменен',
      },
      treatmentPlanKind: {
        medical: 'Медицинский',
        dental: 'Стоматологический',
      },

      totalRow: { total: t('reports.total') },
    }
  },
}
