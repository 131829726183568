<template>
  <div class="control-component">
    <div class="specialties-list">
      <ul>
        <li
          :class="{ active: selectAllSpecialties }"
          :data-id="SELECT_ALL_SPECIALTIES_ID"
          class="control-component-list-item specialty-item"
          @click="_specialtyOnClick"
        >
          <div class="item-left cut">
            <span class="fad fa-fw fa-address-card" />
            <span
              v-show="!showSpecialtiesList && !selectAllSpecialties"
              class="selected-counter active"
            >
              {{ selectAllSpecialties ? allSIDs.length : selectedSpecialtiesIds.length }}
            </span>
            <span class="cut">{{ T.all_specialties }}</span>
          </div>
          <div
            class="item-right item-list-minimize cut"
            @click.stop="_showSpecialtiesOnClick"
          >
            <span
              v-if="showSpecialtiesList"
              class="item-list-icon-minimize fad fa-chevron-down"
            />
            <span
              v-else
              class="item-list-icon-unminimize fad fa-chevron-right"
            />
          </div>
        </li>
        <template v-if="showSpecialtiesList">
          <li
            v-for="specialty in _specialties"
            :key="specialty.id"
            :class="{ active: selectedSpecialtiesIds.includes(specialty.id) }"
            :data-id="specialty.id"
            class="control-component-list-item specialty-item"
            @click="_specialtyOnClick"
          >
            <div class="item-left cut">
              <span class="cut">{{ specialty.title }}</span>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="cabinet-list">
      <ul>
        <li
          :class="{ active: selectedCabinetsIds.includes(SELECT_ALL_CABINETS_ID) }"
          :data-id="SELECT_ALL_CABINETS_ID"
          class="control-component-list-item cabinet-item"
          @click="_cabinetOnClick"
        >
          <div class="item-left cut">
            <span class="fad fa-fw fa-door-closed" />
            <span
              v-show="!showCabinetsList && !selectedCabinetsIds.includes(SELECT_ALL_CABINETS_ID)"
              class="selected-counter active"
            >
              {{ selectedVisibleCabinetsIds.length }}
            </span>
            <span class="cut">{{ T.all_cabinets }}</span>
          </div>
          <div
            class="item-right item-list-minimize cut"
            @click.stop="_showCabinetsOnClick"
          >
            <span
              v-if="showCabinetsList"
              class="item-list-icon-minimize fad fa-chevron-down"
            />
            <span
              v-else
              class="item-list-icon-unminimize fad fa-chevron-right"
            />
          </div>
        </li>
        <template v-if="showCabinetsList">
          <li
            v-for="cabinet in visibleCabinets"
            :key="cabinet.id"
            :class="{ active: selectedCabinetsIds.includes(cabinet.id) }"
            :data-id="cabinet.id"
            class="control-component-list-item cabinet-item"
            @click="_cabinetOnClick"
          >
            <div class="item-left cut">
              <span class="cut">{{ cabinet.title }}</span>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div
      id="user-list"
      class="user-list"
    >
      <ul>
        <li
          :class="{ active: selectedUsersIds.includes(SELECT_ALL_USERS_ID) }"
          :data-id="SELECT_ALL_USERS_ID"
          class="control-component-list-item user-item"
          @click="_userOnClick"
        >
          <div class="item-left cut">
            <span class="fad fa-fw fa-user-md" />
            <span
              v-show="!showUsersList && !selectedUsersIds.includes(SELECT_ALL_USERS_ID)"
              class="selected-counter active"
            >
              {{ selectedVisibleUsersIds.length }}
            </span>
            <span class="cut">{{ T.all_employees }}</span>
          </div>
          <div
            class="item-right item-list-minimize cut"
            @click.stop="_showUsersOnClick"
          >
            <span
              v-if="showUsersList"
              class="item-list-icon-minimize fad fa-chevron-down"
            />
            <span
              v-else
              class="item-list-icon-unminimize fad fa-chevron-right"
            />
          </div>
        </li>
        <template v-if="showUsersList">
          <li
            v-for="user in visibleUsers"
            :key="user.id"
            :class="{ active: selectedUsersIds.includes(user.id) }"
            :data-id="user.id"
            class="control-component-list-item with-avatar user-item"
            @click="_userOnClick"
          >
            <div class="item-left cut">
              <span class="user-avatar-container">
                <img :src="getUserAvatar(user)">
              </span>
              <span class="cut">{{ user.short_name }}</span>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import globalObjectsAccessHelpers from '../../global_objects_access_helpers.js'
import coreMethods from './core.js'
import storeMethods from './store.js'
import eventsMethods from './events.js'
import {
  CONTROL_COMPONENT_REQUEST_TIMEOUT_DELAY,
  SELECT_ALL_SPECIALTIES_ID,
  SELECT_ALL_CABINETS_ID,
  SELECT_ALL_USERS_ID,
} from '../const.js'

export default {
  name: 'EmployeesScheduleControlComponent',
  props: {
    _specialties: { type: Array, required: true },
    _cabinets: { type: Array, required: true },
    _users: { type: Array, required: true },
    currentClinic: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    SELECT_ALL_SPECIALTIES_ID,
    SELECT_ALL_CABINETS_ID,
    SELECT_ALL_USERS_ID,

    date: null,
    employeesScheduleTableData: null,

    requestDelay: null,
    lastRequest: null,
    loadingSpinner: null,

    showSpecialtiesList: true,
    showUsersList: true,
    showCabinetsList: true,

    allSIDs: [],
    allUIDs: [],
    allCIDs: [],

    allUIDsInSIDs: Object.create(null),

    specialtiesMap: Object.create(null),
    usersMap: Object.create(null),
    cabinetsMap: Object.create(null),

    visibleSpecialties: [],
    visibleUsers: [],
    visibleCabinets: [],

    selectedSpecialtiesIds: [],
    selectedUsersIds: [],
    selectedCabinetsIds: [],

    selectAllSpecialties: true,

    selectedVisibleUsersIds: [],
    selectedVisibleCabinetsIds: [],

    dates: [],
  }),
  watch: {
    date () { this.requestData(true) },
  },
  created () {
    this._cabinets.forEach((cabinet) => {
      this.allCIDs.push(cabinet.id)
      this.cabinetsMap[cabinet.id] = cabinet
    })

    this.allCIDs = Object.freeze(this.allCIDs)
    this.cabinetsMap = Object.freeze(this.cabinetsMap)

    this._users.forEach((user) => {
      this.allUIDs.push(user.id)
      this.usersMap[user.id] = user
    })

    this.allUIDs = Object.freeze(this.allUIDs)
    this.usersMap = Object.freeze(this.usersMap)

    this._specialties.forEach((specialty) => {
      this.allSIDs.push(specialty.id)
      this.specialtiesMap[specialty.id] = specialty
      this.allUIDsInSIDs[specialty.id] = Object.freeze(this._users
        .filter((user) => user.specialty_ids.includes(specialty.id))
        .map((user) => user.id))
    })

    this.allSIDs = Object.freeze(this.allSIDs)
    this.specialtiesMap = Object.freeze(this.specialtiesMap)
    this.allUIDsInSIDs = Object.freeze(this.allUIDsInSIDs)

    this._validateStore()
    const currentStore = this._getStore()
    const state = currentStore[this.currentClinic.id]

    this.showSpecialtiesList = state.showSpecialtiesList
    this.showUsersList = state.showUsersList
    this.showCabinetsList = state.showCabinetsList

    const userIdParam = parseInt(new URLSearchParams(window.location.search).get('user'), 10)

    if (typeof userIdParam === 'number' && !Number.isNaN(userIdParam) && this.allUIDs.includes(userIdParam)) {
      this.selectedSpecialtiesIds = [SELECT_ALL_SPECIALTIES_ID]
      this.selectedUsersIds = [userIdParam]
      this.selectedCabinetsIds = [SELECT_ALL_CABINETS_ID]
    } else {
      this.selectedSpecialtiesIds = state.selectedSpecialtiesIds
      this.selectedUsersIds = state.selectedUsersIds
      this.selectedCabinetsIds = state.selectedCabinetsIds
    }

    this.selectAllSpecialties = this.selectedSpecialtiesIds.includes(SELECT_ALL_SPECIALTIES_ID)

    this._setVisibleUsers()

    const ps = this.$pubSub

    ps.subscribe('EMPLOYEES_SCHEDULE.UPDATE_SCHEDULE_GRID_DATE', (date) => { this.date = date }, 10)
    ps.subscribe('EMPLOYEES_SCHEDULE.SELECT_ONE_USER', this.selectOneUser)
    ps.subscribe('EMPLOYEES_SCHEDULE.SELECT_ONE_SPECIALTY', this.selectOneSpecialty)
    ps.subscribe('EMPLOYEES_SCHEDULE.SELECT_ONE_CABINET', this.selectOneCabinet)
    ps.subscribe('EMPLOYEES_SCHEDULE.RESET_ALL_FILTERS', this.resetAllFilters)
    ps.subscribe('EMPLOYEES_SCHEDULE.RELOAD_SCHEDULE', () => this.requestData(true))
  },
  mounted () {
    Services.wsSubscriptions.workTime.connect((data) => this._workTimeUpdatesHandler(data))
  },
  methods: {
    ...globalObjectsAccessHelpers,
    ...storeMethods,
    ...eventsMethods,
    ...coreMethods,

    requestData (force = false) {
      const god = this

      god._setSelectedVisibleUsersIds()
      const specialtiesIds =
        god.selectedSpecialtiesIds.includes(SELECT_ALL_SPECIALTIES_ID) ? god.allSIDs : god.selectedSpecialtiesIds

      god.requestDelay && window.clearTimeout(god.requestDelay)

      god.requestDelay = window.setTimeout(() => {
        god.lastRequest && god.lastRequest.abort()
        Services.ui.spinner().show(true)

        $.ajax({
          url: Routes.employees_schedule_data_path(),
          method: 'POST',
          data: {
            user_ids: JSON.stringify(god.selectedVisibleUsersIds),
            specialty_ids: JSON.stringify(specialtiesIds),
            date: god.date,
            clinic_id: god.currentClinic.id,
          },
          success (data) {
            god.employeesScheduleTableData = data
            god.dates = data.dates
            god._setVisibleCabinets(data.cabinet_ids)
            god._onChange()
          },
          error () {
            god.$pubSub.emitAsync('EMPLOYEES_SCHEDULE.UNEXPECTED_ERROR_NOTIFICATION')
          },
          complete () {
            Services.ui.spinner().show(false)
          },
        })
      }, force ? 0 : CONTROL_COMPONENT_REQUEST_TIMEOUT_DELAY)
    },

    selectOneUser (id) {
      this.selectedUsersIds = [parseInt(id, 10)]
      this._updateStore()
      this.requestData(true)
    },

    selectOneSpecialty (id) {
      this.selectedSpecialtiesIds = [parseInt(id, 10)]
      this._updateStore()
      this.selectAllSpecialties = false
      this._setVisibleUsers()
      this.requestData(true)
    },

    selectOneCabinet (id) {
      this.selectedCabinetsIds = [parseInt(id, 10)]
      this._updateStore()
      this._onChange()
    },

    resetAllFilters () {
      if (this.selectedUsersIds.includes(SELECT_ALL_USERS_ID) &&
          this.selectedSpecialtiesIds.includes(SELECT_ALL_SPECIALTIES_ID) &&
          this.selectedCabinetsIds.includes(SELECT_ALL_CABINETS_ID)) return

      this.selectedUsersIds = [SELECT_ALL_USERS_ID]
      this.selectedSpecialtiesIds = [SELECT_ALL_SPECIALTIES_ID]
      this.selectedCabinetsIds = [SELECT_ALL_CABINETS_ID]
      this._updateStore()
      this.selectAllSpecialties = true
      this._setVisibleUsers()
      this.requestData(true)
    },

    _onChange () {
      this._setSelectedVisibleCabinetsIds()

      const resetAllFilters =
        !this.selectedCabinetsIds.includes(SELECT_ALL_CABINETS_ID) ||
        !this.selectedUsersIds.includes(SELECT_ALL_USERS_ID) ||
        !this.selectedSpecialtiesIds.includes(SELECT_ALL_CABINETS_ID)

      this.$pubSub.emitAsync(
        'EMPLOYEES_SCHEDULE.UPDATE_SCHEDULE_GRID_GRAPH',
        {
          scheduleGridGraph: this.employeesScheduleTableData,
          selectedCabinetIds: this.selectedVisibleCabinetsIds,
          resetAllFilters,
        },
      )
    },
  },
}
</script>
