import SmsEditor from '@/modules/variables_editor.js'
import { CLIENTS_BIRTHDAY } from '@/modules/sms/editor_presets.js'
import { smsCount } from '@/modules/sms/utils.js'

export default function clientsBirthday () {
  if (!gon.application.sms_module_enabled) return

  const $form = $('#sms_configuration_form')
  const $submit = $form.find('#sms_settings_submit').eq(0)
  const $clientsBirthdayEditor = $form.find('.clients_birthday_template_editor_container')
  const $clientsBirthdayCb = $form.find('#sms_configuration_clients_birthday')
  const $clientsBirthdayFields = $form.find('.clients_birthday_field')
  const $clientsBirthdayApproximate = $form.find('.clients_birthday_approximate_value')
  const $symbolCount = $form.find('.symbol_count')

  const clientsBirthdayEditor = new SmsEditor({
    container: $clientsBirthdayEditor,
    onPreviewChanged (text) {
      const count = smsCount(text)
      $clientsBirthdayApproximate.text(count)
      $symbolCount.text(text.length)
    },
    validationCallback (value) {
      $submit.prop('disabled', !value)
    },
    ...CLIENTS_BIRTHDAY,
  })

  clientsBirthdayEditor.visualize()

  $form.remote_form({
    model: 'sms_configuration',
    beforeSend () {
      clientsBirthdayEditor.originalize()
    },
    onSuccess (res) {
      clientsBirthdayEditor.visualize()
      Notificator.info(I18n.t('sms.configuration_updated'))
      $submit.loadSpin('stop')
    },
    onError (err) {
      Notificator.error(T.check_correctness_filing_form)
      console.log(err)
      clientsBirthdayEditor.visualize()
      $submit.loadSpin('stop')
    },
  })

  const toggleClientsBirthdayFields = () => {
    $clientsBirthdayFields.toggleClass('hidden', !$clientsBirthdayCb.prop('checked'))
  }

  toggleClientsBirthdayFields()
  $clientsBirthdayCb.on('change', toggleClientsBirthdayFields)
  $submit.on('click', (e) => {
    if (!clientsBirthdayEditor.validate()) e.preventDefault()
  })
}
