import Vue from 'vue/dist/vue.esm'
import AnalysisOrderForm from './analysis_order_form.vue'
import AnalysisOrderShow from './AnalysisOrderShow'
import AnalysisOrderIndex from './analysis_order_index.vue'
import ConfigurationControl from './configuration_control.vue'
import WebSocketsHandler from '@/vue_components/analysis_orders/web_sockets_handler.js'

const wsHandler = new WebSocketsHandler()

export default {
  newForm () {
    wsHandler.subscribe()

    return new Vue({
      el: '#analysis_order_form',
      components: {
        AnalysisOrderForm,
      },
      data: {
        clientId: gon.specific.client_id,
        orderId: gon.specific.order_id,
        wsHandler,
      },
      methods: {},
      template:
        '<analysis-order-form :client-id="clientId" :order-id="orderId" :ws-handler="wsHandler"/>',
    })
  },

  editForm () {
    wsHandler.subscribe()

    return new Vue({
      el: '#analysis_order_form',
      components: {
        AnalysisOrderForm,
      },
      data: {
        analysisOrderId: gon.specific.analysis_order_id,
        wsHandler,
      },
      methods: {},
      template:
        '<analysis-order-form :analysis-order-id="analysisOrderId" :ws-handler="wsHandler"/>',
    })
  },

  index () {
    // 7jt1ev
    if (gon.specific.laboratories_integration && gon.specific.laboratories_integration.moduleIsEnabled) {
      wsHandler.subscribe()

      return new Vue({
        el: '#analysis_order_index',
        components: {
          AnalysisOrderIndex,
        },
        data: {
          wsHandler,
        },
        methods: {},
        template:
          '<analysis-order-index :ws-handler="wsHandler"/>',
      })
    }

    return null
  },

  show () {
    wsHandler.subscribe()

    return new Vue({
      el: '#analysis_order_show',
      name: 'AnalysisOrderShowApp',
      components: {
        AnalysisOrderShow,
      },
      data: {
        analysisOrderId: gon.specific.analysis_order_id,
        currentClinicTitle: gon.application.current_clinic.title,
        wsHandler,
      },
      methods: {},
      template:
        `<AnalysisOrderShow 
          :analysis-order-id="analysisOrderId" 
          :ws-handler="wsHandler" 
          :currentClinicTitle="currentClinicTitle"
        />`,
    })
  },

  configuration () {
    if (gon.specific.laboratoriesIntegration.moduleIsEnabled) {
      wsHandler.subscribe()

      return new Vue({
        el: '#configuration_control',
        components: {
          ConfigurationControl,
        },
        data: {
          wsHandler,
        },
        methods: {},
        template:
          '<configuration-control :ws-handler="wsHandler"/>',
      })
    }
  },
}
