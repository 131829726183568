<template>
  <div style="position: relative">
    <epic-spinner :visibility="showLoadingSpinner" />
    <div
      class="el-card__body__content"
      style="height: 290px"
    >
      <div id="department-container" />
    </div>
    <div class="el-card__body__footer">
      <div class="el-card__body__footer__filters">
        <clinic-selector :card-name="cardName" />
        <entry-type-selector :card-name="cardName" />
        <period-selector :card-name="cardName" />
      </div>
      <div class="el-card__body__footer__actions" />
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import EpicSpinner from '../../../epic_spinner/epic_spinner.vue'
import ClinicSelector from '../selectors/clinic_selector.vue'
import PeriodSelector from '../selectors/period_selector.vue'
import EntryTypeSelector from '../selectors/entry_type_selector.vue'
import Card from '@/vue_components/head_desktop/components/cards/card'
import CardWithChart from '@/vue_components/head_desktop/components/cards/cardWithChart'

export default {
  components: {
    EpicSpinner,
    ClinicSelector,
    PeriodSelector,
    EntryTypeSelector,
  },

  mixins: [Card, CardWithChart],

  data () {
    return {
      route: this.$routes.reports_create_head_desktop_department_path(),
      filters: {
        clinic_id: null,
        kind: null,
        date_start: null,
        date_end: null,
      },
    }
  },

  computed: {
    requestParams () {
      return {
        clinic_id: this.cardParams.clinic_id,
        date_start: this.cardParams.date_range[0],
        date_end: this.cardParams.date_range[1],
        kind: this.cardParams.kind,
      }
    },
  },

  methods: {
    cardAdapter (result) {
      this.chartData = result.department
    },

    renderChart () {
      Highcharts.chart('department-container', {
        chart: {
          type: 'bar',
          height: '290px',
        },
        series: [{
          name: this.T.head_desktop.categories,
          color: '#9C27B0',
          data: this.chartData,
          dataLabels: {
            enabled: true,
            rotation: 0,
            color: '#fff',
            textOutline: false,
            align: 'right',
            format: '{point.y:.0f}' + gon.localization.currency.symbol_character,
            y: -1,
            style: {
              fontSize: '10px',
              fontFamily: 'Verdana, sans-serif',
              textOutline: false,
            },
          },
        }],
        title: false,
        legend: {
          enabled: false,
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: 0,
            style: {
              fontSize: '12px',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        tooltip: {
          pointFormat: t('head_desktop.bills') + ': <b>{point.y:.0f}</b>' + gon.localization.currency.symbol_character + ` (${t('head_desktop.bills_tip')})`,
        },
      })
    },
  },
}
</script>
