<template>
  <a
    :href="url"
    target="_blank"
    class="context-menu-new-tab flex align-center text-decoration-none"
  >
    <m-icon
      icon="link"
      color="primary"
      class="mr-5"
    />
    <span class="hover-underline">
      {{ t('open_new_tab') }}
    </span>
  </a>
</template>

<script>
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'MContextMenuNewTab',
  components: { MIcon },
  props: {
    url: { type: String, required: true },
  },
}
</script>
