<template>
  <div>
    <button
      class="btn btn-sm btn-success btn-with-icon modal-save"
      @click="$pubSub.emit('EMPLOYEES_SCHEDULE.SPLIT_VACATION')"
    >
      <span class="btn-with-icon_icon fad fa-trash-alt" />
      <span class="btn-with-icon_text">{{ T.delete_this_day_only }}</span>
    </button>
    <button
      class="btn btn-sm btn-warning btn-with-icon modal-save"
      @click="$pubSub.emit('EMPLOYEES_SCHEDULE.SLICE_VACATION')"
    >
      <span class="btn-with-icon_icon fad fa-trash-alt" />
      <span class="btn-with-icon_text">{{ T.remove_from_this_day }}</span>
    </button>
    <button
      class="btn btn-sm btn-danger btn-with-icon modal-save"
      @click="$pubSub.emit('EMPLOYEES_SCHEDULE.REMOVE_VACATION')"
    >
      <span class="btn-with-icon_icon fad fa-exclamation-circle" />
      <span class="btn-with-icon_text">{{ T.delete_all }}</span>
    </button>
    <button
      class="btn btn-sm btn-primary btn-with-icon modal-close"
      @click="$pubSub.emit('EMPLOYEES_SCHEDULE.HIDE_REMOVE_VACATION_MODAL')"
    >
      <span class="btn-with-icon_icon fad fa-times" />
      <span class="btn-with-icon_text">{{ T.close }}</span>
    </button>
  </div>
</template>

<script>
export default { name: 'RemoveVacationModalFooter' }
</script>
