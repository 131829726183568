export default {
  /**
   * @type import('vue').PropOptions<
   * discount_created_at: String,
   * discount_issuer_id: Number,
   * discount_issuer_name: String,
   * discount_reason_title: String,
   * discount_sum_after: Number,
   * discount_sum_before: Number,
   * discount_type: String,
   * discount_value: Number,
   * entry_id: Number,
   * entry_title: String,
   * id: Number
   * >
   */
  historyArray: {
    type: Array,
    required: true,
  },

  /**
   * @type import('vue').PropOptions<{}>
   */
  discountTypeObj: {
    type: Object,
    required: true,
  },

  entryId: {
    type: Number,
    default: 0,
  },

  entryTitle: {
    type: String,
    default: '',
  },

  /* Для управления видимостью el-select */
  isAllEntries: {
    type: Boolean,
    default: true,
  },
}
