import { ITreatmentPlansReportFilters as ReportFilters } from '@/vue_apps/ReportsModule/TreatmentPlansReports/interfaces/ITreatmentPlansReportFilters'
import {
  ITreatmentPlansReportFiltersProfileFiltersToServer as IFiltersToServer,
  ITreatmentPlansReportFiltersProfileFiltersToClient as IFiltersToClient,
} from '../../interfaces/filtersTypes/treatmentPlansReport'
import { IFiltersProfilesFetchResponse } from '../../interfaces/IFIltersProfilesFetchResponse'
import { FiltersProfilesFormattersToServer as FormattersToServer } from '../../helpers/formatters/toServer'
import { FiltersProfilesFormattersToClient as FormattersToClient } from '../../helpers/formatters/toClient'
import { gatherErrors } from '../../helpers/gatherErrors'
import { IFiltersProfilesModel } from '../../interfaces/IFiltersProfilesModel'
import { omitBy, isUndefined } from 'lodash'
import { cleanUnpermittedValues } from '../../helpers/formatters/shared'
import { TOnlyShortNameHuman } from '@/_declarations/TOnlyShortNameHuman'
import { treatmentPlansTypes } from '@/vue_apps/TreatmentPlans/TreatmentPlansList/const/treatmentPlansFilters'

export const treatmentPlansReportFiltersProfileAdapter = {
  toServer (data: IFiltersProfilesModel<ReportFilters>): IFiltersProfilesModel<IFiltersToServer> {
    const { data: filters } = data

    const adaptedFilters: IFiltersToServer = {
      period: FormattersToServer.formatPeriod(filters.period),
      clinic: FormattersToServer.formatSingle(filters.clinic),
      treatmentPlanKinds: FormattersToServer.formatSingle(filters.treatmentPlanKinds),
      treatmentPlanStatuses: FormattersToServer.formatMulti(filters.treatmentPlanStatuses),
      doctor: FormattersToServer.formatHuman(filters.doctor),
      responsible: FormattersToServer.formatHuman(filters.responsible),
    }

    return { ...data, data: adaptedFilters }
  },

  toClient (data: IFiltersProfilesFetchResponse<IFiltersToClient>): IFiltersProfilesModel<Partial<ReportFilters>> {
    const { data: filters } = data

    const validFilters = omitBy(cleanUnpermittedValues(filters), isUndefined) as IFiltersToClient

    const adaptedFilters: ReportFilters = {
      period: FormattersToClient.formatPeriod(validFilters.period),
      clinic: FormattersToClient.formatSingle(validFilters.clinic),
      treatmentPlanKinds: FormattersToClient.formatSingle(validFilters.treatmentPlanKinds),
      treatmentPlanStatuses: FormattersToClient.formatMulti(validFilters.treatmentPlanStatuses),
      doctor: FormattersToClient.formatHuman(validFilters.doctor) as TOnlyShortNameHuman,
      responsible: FormattersToClient.formatHuman(validFilters.responsible) as TOnlyShortNameHuman,
    }

    adaptedFilters.treatmentPlanKinds &&= treatmentPlansTypes
      .find((type) => type.id === adaptedFilters.treatmentPlanKinds.id)

    const definedAdaptedFilters: Partial<ReportFilters> = omitBy(adaptedFilters, isUndefined)

    return {
      ...data,
      data: definedAdaptedFilters,
      dataErrors: gatherErrors(filters),
    }
  },
}
