import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

const DOCTOR_CSS_CLASS = 'salary-by-payments-doctor'
export class SalaryByPaymentsDetailedReportDoctorTotalItem {
  /** @param {TSalaryByPaymentsDetailedDoctorTotal} doctor */
  constructor (doctor) {
    this.id = `doctorTotal:${doctor.id}`
    this.paidSum = doctor.paidSum
    this.totalPaid = doctor.totalSalary
  }

  get tableTitle () {
    return new MTableCell(t('reports.userTotal'))
      .addClasses(DOCTOR_CSS_CLASS)
  }

  get tableDate () {
    return new MTableCell()
  }

  get tableOrder () {
    return new MTableCell()
  }

  get tableOrderDate () {
    return new MTableCell()
  }

  get tableEntrySum () {
    return new MTableCell()
  }

  get tableEntryFinalSum () {
    return new MTableCell()
  }

  get tableAmountInEntry () {
    return new MTableCell()
  }

  get tableLastPayment () {
    return new MTableCell()
  }

  get tableLastPaymentDate () {
    return new MTableCell()
  }

  get tableLastPaymentTotalPaid () {
    return new MTableCell()
  }

  get tableLastPaymentKind () {
    return new MTableCell()
  }

  get tableLastPaymentCashier () {
    return new MTableCell()
  }

  get tableSum () {
    return new MTableCell(vueFilters.price(this.paidSum))
  }

  get tableRateType () {
    return new MTableCell()
  }

  get tableSalary () {
    return new MTableCell(vueFilters.price(this.totalPaid))
  }
}
