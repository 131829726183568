/**
 * Применяет метод trim() на все строковые значения передаваемой сущности
 * @param {[Object, Array]} entity
 */
export const trimValues = (entity) => {
  for (const key in entity) {
    if (typeof entity[key] === 'object' && entity[key] !== null) {
      trimValues(entity[key])
    } else if (typeof entity[key] === 'string') {
      entity[key] = entity[key].trim()
    }
  }
}
