$document.on('rez/reminders/index', () => {
  Utils.initCalendar()

  SortItems.init({
    itemName: ['reminders'],
    url: '/reminders',
    defaults: {period: 'today'},
  })

  var msgPrefix = 'page.form.reminder.'

  var createReminder = $('#add_reminder')

  $document.on('click', 'tr.reminder_row', function () {
    var id = parseInt($(this).data('id'))

    $.get(Routes.reminder_path(id)).done(function (data) {
      Notification.getReminderForm().editItem(data)
    })
  })

  Notification.generateReminderForm()

  createReminder.on('click', function () {
    Notification.getReminderForm().newItem('', true)
  })

  PubSub.on(msgPrefix + 'submitSuccess', function (msg, data) {
    SortItems.refresh()
  })

  PubSub.on(msgPrefix + 'modal.close', function (msg, data) {
    SortItems.refresh()
  })

  $.contextMenu(ContextMenu.client({items: ['toInfoCard',
    'toClientReminders',
    'createAppointment'],
  selector: '.client-actions'}))

  adjustHeight()

  $(window).on('resize', function () {
    adjustHeight()
  })

  function adjustHeight () {
    $('#reminders_table_body').height($('.main-container').height() - $('.sort-form-container').height() - 143)
  }
})
