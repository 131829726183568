import { ReportParamFactory, ReportParamStrict } from '../shared'
import { doctorAppointmentsReportFiltersSelectors as selectors } from './filtersSelectors'
import { IDoctorAppointmentsReportFiltersGet } from './interfaces'

export const _getDoctorAppointmentsReportFilters = (): IDoctorAppointmentsReportFiltersGet => ({
  period: ReportParamFactory.getRawParam(selectors.period),
  clinic: ReportParamFactory.getSelectParam(selectors.clinic) as ReportParamStrict,
  clientGroups: ReportParamFactory.getSelect2Params(selectors.clientGroups) as ReportParamStrict[],
  entryTypes: ReportParamFactory.getSelect2Params(selectors.entryTypes) as ReportParamStrict,
  appointmentTypes: ReportParamFactory.getSelect2Params(selectors.appointmentTypes) as ReportParamStrict[],
  appointmentSources: ReportParamFactory.getSelect2Params(selectors.appointmentSources) as ReportParamStrict[],
  attractionSources: ReportParamFactory.getSelect2Params(selectors.attractionSources) as ReportParamStrict[],
  appointmentStatuses: ReportParamFactory.getSelect2Params(selectors.appointmentStatuses) as ReportParamStrict[],
  paymentType: ReportParamFactory.getSelectParam(selectors.paymentType) as ReportParamStrict,
  administrator: ReportParamFactory.getSelectParam(selectors.administrator) as ReportParamStrict,
  doctor: ReportParamFactory.getSelectParam(selectors.doctor) as ReportParamStrict,
  referral: ReportParamFactory.getSelectParam(selectors.referral) as ReportParamStrict,
  newPatients: ReportParamFactory.getCheckboxParam(selectors.newPatients) as ReportParamStrict,
})
