import { DateHelpers } from '@/helpers/DateHelpers'
import { IInsuranceCompaniesReportFiltersGet, IInsuranceCompaniesReportFiltersSet } from './interfaces'
import { _getInsuranceCompaniesReportFilters as getFilters } from './_filtersGetter'

let initialFilters: IInsuranceCompaniesReportFiltersGet = null

export const saveInsuranceCompaniesReportInitialFilters = (): void => {
  initialFilters = getFilters()
}

export const getInsuranceCompaniesReportInitialFilters = (): IInsuranceCompaniesReportFiltersSet => ({
  ...initialFilters,
  period: DateHelpers.getCurrentMonthPeriod(),
})
