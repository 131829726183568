
export const getDefaultFilters = () => {
  return {
    clientId: null,
    clinicIds: [],
    doctorIds: [],
    userIds: [],
    entryIds: [],
    date: null,
  }
}
