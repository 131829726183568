<template>
  <simple-select
    class="el-select-multi"
    :value="value"
    :items="items"
    :fixed-height="false"
    value-key="id"
    clearable
    filterable
    multiple
    collapse-tags
    @change="$emit('change', $event)"
  />
</template>

<script>
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'SpecialtyMultiSelect',
  components: { SimpleSelect },
  props: {
    items: PropsTypes.Array(),
    value: PropsTypes.Array(),
  },
}
</script>
