class Storage {
  constructor (nameStorage) {
    this.nameStorage = nameStorage
  }

  setItem (prop, value) {
    const storage = this._get()
    storage[prop] = value
    this._set(storage)
  }

  getItem (prop) {
    return this._get()[prop]
  }

  _get () {
    return JSON.parse(localStorage.getItem(this.nameStorage)) || {}
  }

  _set (value) {
    localStorage.setItem(this.nameStorage, JSON.stringify(value))
  }
}

const DATA_NOT_DEFINED = `Set data
*
*  Example:
*    options = {
*      data: { ...state }
*    }
*
*`

const NAME_NOT_DEFINED = `Set name
*
*  Example:
*    options = {
*      name: string
*    }
*`

const WATHCER_MUST_BY = `Watcher must be string or object or undefined
*
*  Example:
*    options: {
*      data: {
*         prop: 'any value'
*      },
*      watch: {
*        prop: {
*          deep: true
*          handler: function () { },
*        },
*        prop: function () {}
*        prop: undefined
*      }
*    }
*
`

const vuels = (options) => {
  const {data, watch = {}, name} = options

  if (!data) throw new Error(DATA_NOT_DEFINED)
  if (!name) throw new Error(NAME_NOT_DEFINED)

  const storage = new Storage(name)
  const state = {}
  const watchers = {}

  Object.keys(data).forEach((key) => {
    const storedValue = storage.getItem(key)
    const customWatcher = watch[key]
    const prop = data[key]

    let watcher = {}

    // prepare state
    if (storedValue === null || storedValue === undefined) {
      storage.setItem(key, prop)
      state[key] = prop
    } else state[key] = storedValue

    // prepare watchers
    if (typeof customWatcher === 'function') {
      watcher.handler = function (n, o) {
        storage.setItem(key, n)
        customWatcher.call(this, n, o)
      }
    } else if (typeof customWatcher === 'object') {
      const customWatcherHandler = customWatcher.handler
      if (typeof customWatcherHandler !== 'function') throw new Error(WATHCER_MUST_BY)

      watcher = { ...customWatcher }
      watcher.handler = function (n, o) {
        storage.setItem(key, n)
        customWatcherHandler.call(this, n, o)
      }
    } else if (typeof customWatcher === 'undefined') {
      watcher.deep = true
      watcher.handler = function (n) {
        storage.setItem(key, n)
      }
    } else {
      throw new Error(WATHCER_MUST_BY)
    }

    watchers[key] = watcher
  })

  return {
    data: () => state,
    watch: watchers,
  }
}

export default vuels
