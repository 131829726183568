<template>
  <simple-select
    :value="value"
    :fixed-height="false"
    :items="entryTypes"
    filterable
    multiple
    collapse-tags
    clearable
    :value-key="valueKey"
    popper-class="user-dropdown-list"
    :filter-method="searchEntryTypes"
    :placeholder="t('enter_service_name_or_code')"
    @change="$emit('change', $event)"
  >
    <template
      v-for="item in entryTypes"
      #option="{ item }"
    >
      <el-option
        :key="item.id"
        :value="item"
        :label="item.title"
      >
        <div class="entry-type-dropdown">
          <span
            class="entry-type-dropdown-number cut tooltip-bottom"
            :title="item.number"
          >
            {{ item.number }}
          </span>
          <span
            class="entry-type-dropdown-title cut tooltip-bottom"
            :title="item.title"
          >
            {{ item.title }}
          </span>
        </div>
      </el-option>
    </template>
  </simple-select>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { findEntryTypes } from '@/vue_components/doctor_schedules/rest'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { cloneDeep } from 'lodash'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect.vue'

export default {
  name: 'EntryTypeSearchMultiSelect',
  components: { SimpleSelect },
  mixins: [SpinnerHolder],
  props: {
    value: PropsTypes.Array(),
    clinicIds: PropsTypes.Array(),
    valueKey: { type: String, default: undefined },
  },

  data () {
    return {
      search: '',
      entryTypes: [],
      searchRequest: null,
    }
  },

  watch: {
    value (to) {
      if (to && !this.entryTypes.length) { this.entryTypes = cloneDeep(to) }
    },
  },

  methods: {
    async searchEntryTypes (searchQuery) {
      if (!searchQuery || searchQuery.length < 3) { return }

      if (this.searchRequest) { this.searchRequest.abort() }
      const params = {
        searchQuery,
        clinicIds: this.clinicIds.map((clinic) => clinic.id),
        forCurrentClinics: true,
      }

      const { ajax, promise } = findEntryTypes(params)
      this.searchRequest = ajax

      try {
        this.entryTypes = await this.withSpinner(promise)
        this.searchRequest = null
      } catch (err) {
        if (err?.statusText === 'abort') { return }
        Utils.reportError('searchEntryTypes')(err)
      }
    },
  },
}
</script>
