class AvatarUploader {
  constructor (routes) {
    const { patch } = routes
    this.patch = patch
  }

  upload (file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('user[avatar]', file)

      $.ajax({
        url: this.patch,
        type: 'PATCH',
        processData: false,
        contentType: false,
        data: formData,
        success (data) { resolve(data) },
        error (err) { reject(err) },
      })
    })
  }

  remove () {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: this.patch,
        type: 'PATCH',
        data: {
          user: {
            delete_avatar: true,
            delete_avatar_by_id: gon.application.current_user.id,
          },
        },
        success (data) {
          resolve(data)
        },
        error (err) {
          reject(err)
        },
      })
    })
  }
}

export default AvatarUploader
