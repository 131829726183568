import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { entryTypesTransferAdapter } from '@/_api/EntryTypes/MEntryTypesTransfer/entryTypesTransferAdapter'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'

export class MEntryTypesTransferPresenter extends MPresenterBase {
  constructor () {
    super({
      location: 'MEntryTypesTransferPresenter',
      entity: 'entryTypesFromAppointment',
      routes: { all () {}, one () {}, list: Routes.entry_types_from_appointment_medical_records_path },
    })
  }

  count (clientId) {
    const options = {
      url: Routes.entry_types_from_appointment_count_medical_records_path(),
      data: camelToSnake({ clientId }),
    }

    return this.post(options, (count) => ({ count })).promise
      .catch(() => Promise.resolve({ count: 0 }))
  }

  list (data, config) {
    return super.list(data, config)
      .then((response) => Promise.resolve(entryTypesTransferAdapter.toClient(response)))
  }

  deleteFromAppointments ({ clientId, entryTypeIds }) {
    const options = {
      url: Routes.delete_entry_types_from_appointment_medical_records_path(),
      data: camelToSnake({ clientId, entryTypeIds }),
    }

    const notification = new MRequestNotification(
      'MEntryTypesTransferPresenter:deleteFromAppointments',
      'destroyMessage',
      'entryTypesFromAppointments'
    )

    return this.delete(options).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }

  createFromAppointments ({ clientId, entriesParams }) {
    const options = {
      url: Routes.create_from_entry_type_entries_path(),
      data: JSON.stringify(camelToSnake({ clientId, entriesParams })),
      dataType: 'json',
      contentType: 'application/json',
    }

    const notification = new MRequestNotification(
      'MEntryTypesTransferPresenter:createFromAppointments',
      'createMessage',
      'entryTypesFromAppointments'
    )

    return this.post(options).promise
      .then((data) => {
        const { failedEntriesErrors } = data
        if (failedEntriesErrors?.length === entriesParams.length) {
          throw data
        }

        if (failedEntriesErrors?.length !== 0) {
          Utils.reportWarning(notification.location, t('transferSuccessPartially'))()

          return Promise.resolve({
            errors: true,
            ...data,
          })
        }

        return MRequestSuccess.withNotify(undefined, notification)(data)
      })
      .catch((error) => {
        const errorData = error?.responseJSON || error

        return MRequestError.withDefault(
          snakeToCamel({
            errors: true,
            ...errorData,
          }),
          notification
        )(error)
      })
  }
}
