<template>
  <si-field>
    <div class="title wrap">
      {{ row.entry.title }}
    </div>
    <div
      v-if="false"
      class="comment wrap"
      :data-tooltip="row.entry.comment"
    >
      {{ row.entry.comment }}
    </div>
    <div class="client wrap">
      <a
        v-if="row.entry.client && !row.entry.client.deletedAt"
        :href="$routes.client_path(row.entry.client.id)"
        target="_blank"
      >
        {{ $filters.fullName(row.entry.client) }}
      </a>
      <span
        v-else
        :class="{deleted: row.entry.client.deletedAt}"
      >
        {{ $filters.fullName(row.entry.client) }}
      </span>
    </div>
    <div class="date">
      {{ $filters.date(row.entry.date) }}
    </div>
    <div class="price">
      {{ $filters.price(row.entry.price) }}
    </div>
    <div class="price-with-discount">
      {{ $filters.price(row.entry.finalPrice) }}
    </div>
    <div class="amount">
      {{ row.entry.amount }}
    </div>
    <div class="sum wrap">
      {{ $filters.price(row.entry.finalSum) }}
    </div>
    <div class="reward">
      {{ row.entry.rewardReferral.rate }} {{ $filters.rateType(row.entry.rewardReferral.rateType) }}
    </div>
    <div class="reward-sum">
      {{ $filters.price(row.entry.rewardReferral.finalSum) }}
    </div>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { ReferralRow } from '@/vue_components/reports/referrals/row/referralRow'

export default {
  name: 'ReferralsRowUngroupedReferral',
  components: {
    SiField,
  },
  mixins: [ReferralRow],
}
</script>
