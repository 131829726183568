import { ReportParamFactory } from '@/specific/reports/shared'

export default function (source) {
  return {
    clinicIds: ReportParamFactory.buildArrayParam(source.clinic),
    clientGroupIds: ReportParamFactory.buildArrayParam(source.clientGroups),
    entryTypeIds: ReportParamFactory.buildArrayParam(source.entryTypes),
    appointmentTypeIds: ReportParamFactory.buildArrayParam(source.appointmentTypes),
    appointmentSourceIds: ReportParamFactory.buildArrayParam(source.appointmentSources),
    attractionSourceIds: ReportParamFactory.buildArrayParam(source.attractionSources),
    appointmentStatuses: ReportParamFactory.buildArrayParam(source.appointmentStatuses),
    doctorIds: ReportParamFactory.buildArrayParam(source.doctor),
    administratorIds: ReportParamFactory.buildArrayParam(source.administrator),
    referralIds: ReportParamFactory.buildArrayParam(source.referral),
    appointmentByDms: source.paymentType.id,
    newPatients: source.newPatients.id,
  }
}
