import Vue from 'vue/dist/vue.esm'
import ExportPage from '@/vue_components/export/export.vue'

$document.on('rez/exports/index', () => {
  if (gon.specific.moduleIsEnabled) {
    return new Vue({
      el: '#exports_container',

      components: { ExportPage },

      template: `<export-page/>`,
    })
  }
})
