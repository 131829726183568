import { createVueApp } from '@/vue_components/create_vue_app'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { snakeToCamel } from '@/_api/_requests/helpers'

let vueApp = null
export const createPerformerApp = (el = '#treatment_plan_user_id', isNewTreatmentPlan = false) => {
  if (!document.querySelector(el)) { return }
  if (vueApp?.destroy) { return vueApp.destroy() }

  const performer = isNewTreatmentPlan
    ? snakeToCamel(gon.application.current_user)
    : gon.specific.treatment_plan_user

  vueApp = createVueApp({
    el,
    name: 'PerformerApp',
    components: { ReusableDoctorsList },

    data () {
      return {
        elementId: el.slice(1),
        performer,
        currentClinicId: gon.application.current_clinic?.id,
      }
    },

    computed: {
      inputHiddenValue () {
        return this.performer?.id || ''
      },
    },

    template: `
      <div class="performer-app" :id="elementId">
        <input type="hidden" name="treatment_plan[user_id]" :value="inputHiddenValue">
        <ReusableDoctorsList
          class="treatment-plan__performer"
          v-model="performer"
          :current-clinic="currentClinicId"
          label=""
          @onDoctorChange="performer = $event"
        />
      </div>
    `,
  })
}
