import { CONTAINER_ICONS_CLASSES } from '@/vue_components/analysis_orders/constants'

export const RED_CODES = Object.freeze({
  '045 СЛЮНСКК ПЦР': CONTAINER_ICONS_CLASSES.RED_CODE,
  '030 КРАСНАЯ ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '221 КРАСНАЯ ПЦРКР': CONTAINER_ICONS_CLASSES.RED_CODE,
  '282 СИН ПЦР': CONTAINER_ICONS_CLASSES.RED_CODE,
  '281 2МЛПРСЖ ПЦР': CONTAINER_ICONS_CLASSES.RED_CODE,
  '272 2МЛПР ПЦРСП/Э': CONTAINER_ICONS_CLASSES.RED_CODE,
  '013 МОЧОТЛ ПЦРМОЧ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '131 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '156 ИФА НВСБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '145 ИФА5 НВСБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '186 ПК НВСБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '193 СКК1 ЛМБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '103 СКК2 ЛМБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '194 СКК3 ЛМБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '243 СККРАЗ ВИ-ET': CONTAINER_ICONS_CLASSES.RED_CODE,
  '267 МОЧОТЛ3 ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '362 МОЧОТЛ1 ЧЛБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '041 МОЧСКК ВИ-ЕВРО': CONTAINER_ICONS_CLASSES.RED_CODE,
  '700 КРАСНАЯ ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '701 КРАСНАЯ ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '141 ИФА2 НВСБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '142 ИФА2 НВСБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '143 ИФА2 НВСБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '246 КРАСНАЯ ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '039 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '046 МОЧАКОН ВИ-ЦБМ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '012 МОЧОТЛ4 ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '268 КРАСНАЯ ВИ-ЛАП': CONTAINER_ICONS_CLASSES.RED_CODE,
  '164 КРАСНА СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '287 КРАСНАЯ ВИ-ЛАП': CONTAINER_ICONS_CLASSES.RED_CODE,
  '081 КРАСНАЯ ВИ-ЮНИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '347 КРАСНА ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '001 КРАСНА ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '037 КРАСНА ВИ-ET': CONTAINER_ICONS_CLASSES.RED_CODE,
  '033 КРАСНАЯ ВИ-ЭФ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '163 КР199 СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '166 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '363 МОЧОТЛ ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '158 КРАСНАЯ НВСБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '162 -20К ВИ-МГНЦ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '004 КРАСНАЯ ВИ-ЦБМ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '006 МОЧ-ОТЛ ЧЛБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '099 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '126 КРАСНАЯ НВСБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '604 КРАСНАЯ ПЦРКР': CONTAINER_ICONS_CLASSES.RED_CODE,
  '157 КРАСНАЯ ВИ-ET': CONTAINER_ICONS_CLASSES.RED_CODE,
  '396 КРАСНА ИФА': CONTAINER_ICONS_CLASSES.RED_CODE,
  '177 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '219 КРАСНА ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '376 КРАСНАЯ ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '125 КР169 ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '127 КРАСНА СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '007 КРАСНА ВИ-ЭФ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '395 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '036 КРАСНАЯ ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '032 КРАСНАЯ ВИ-ФМБА': CONTAINER_ICONS_CLASSES.RED_CODE,
  '288 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '005 ОТЛ КР ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '493 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '370 КРАСНА СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '209 КРАСНА ИФА': CONTAINER_ICONS_CLASSES.RED_CODE,
  '222 ПК ЛОКИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '529 КРАСНА ВИ-ЛАП': CONTAINER_ICONS_CLASSES.RED_CODE,
  '525 МОЧОТЛ5 ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '526 МОЧОТЛ ЧЛБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '262 КРАСНА СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '326 КР199 СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '327 КР199 СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '765 КРАСНАЯ ВИ-ЭКЗ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '226 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '124 ОТЛИВ-К ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '579 КРАЛБ НВСБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '455 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '577 СККСУТ ВИ-ЕВРО': CONTAINER_ICONS_CLASSES.RED_CODE,
  '581 КРАСНА СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '979 СКК1 ВИ-ЛАП': CONTAINER_ICONS_CLASSES.RED_CODE,
  '650 КРАСНАЯ ИФА': CONTAINER_ICONS_CLASSES.RED_CODE,
  '593 КРАСНА ЛБИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '595 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '599 КР199 СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '619 КРАСНАЯ СПБ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '608 КРАСНАЯ ВИ-ЛАП': CONTAINER_ICONS_CLASSES.RED_CODE,
  '589 КРАСНАЯ ВИ': CONTAINER_ICONS_CLASSES.RED_CODE,
  '106 КРАСНАЯ ЦБМ-АОС': CONTAINER_ICONS_CLASSES.RED_CODE,
})

export const GREEN_CODES = Object.freeze({
  '073 ЗЕЛЁ БГ ВИ-ЭКЗ': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '019 ЗЕЛЁ БГ ВИ-9': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '040 ЗЕЛЁ БГ ВИ-ЦБМ': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '021 ЗЕЛЁН Г ЛБИ': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '108 ЗЕЛЁ БГ ЦБМ-АОС': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '051 ЗЕЛЁ БГ ВИ': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '017 ЗЕЛЁ Г ЛБИ': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '316 ЗЕЛ3 ВИ-СПБ': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '317 ЗЕЛ-Ц ВИ-СПБ': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '562 ЗЕЛЁ БГ ВИ-АБТ': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '117 ЗЕЛ6 ИФА': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '092 СРКБ ЛМБ': CONTAINER_ICONS_CLASSES.GREEN_CODE,
  '101 СРКБ ЛМБ': CONTAINER_ICONS_CLASSES.GREEN_CODE,
})

export const YELLOW_CODES = Object.freeze({
  '091 СП ЛМБ': CONTAINER_ICONS_CLASSES.YELLOW_CODE,
  '137 AMIES16 ЛМБ': CONTAINER_ICONS_CLASSES.YELLOW_CODE,
})

export const GRAY_CODES = Object.freeze({
  '702 СЕРАЯ ЛБИ': CONTAINER_ICONS_CLASSES.GRAY_CODE,
  '707 СЕРАЯ ЛБИ': CONTAINER_ICONS_CLASSES.GRAY_CODE,
  '708 СЕРАЯ ЛБИ': CONTAINER_ICONS_CLASSES.GRAY_CODE,
  '709 СЕРАЯ ЛБИ': CONTAINER_ICONS_CLASSES.GRAY_CODE,
  '703 СЕРАЯ ЛБИ': CONTAINER_ICONS_CLASSES.GRAY_CODE,
  '056 СЕРАЯ ЛБИ': CONTAINER_ICONS_CLASSES.GRAY_CODE,
  '057 ОТЛИВ-С ЛБИ': CONTAINER_ICONS_CLASSES.GRAY_CODE,
})

export const BLUE_CODES = Object.freeze({
  '228 ОТЛИВ Г ВИ-ЛАП': CONTAINER_ICONS_CLASSES.BLUE_CODE,
  '016 ОТЛИВ Г ЛОКИ': CONTAINER_ICONS_CLASSES.BLUE_CODE,
  '173 ОТЛИВ Г ЛОКИ': CONTAINER_ICONS_CLASSES.BLUE_CODE,
  '097 ГОЛ1 ЛОКИ': CONTAINER_ICONS_CLASSES.BLUE_CODE,
})

export const LILAC_CODES = Object.freeze({
  '229 СИР/Ч/К ПЦРКР': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '151 СИР/Ж/Г ПЦРКР': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '074 СИР/Ж/Г ПЦРКР': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '605 ОТЛИВ С ВИ-ЕВРО': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '211 ОТЛИВ С ВИ-ЕВРО': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '343 НАТОЩАК СПБ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '064 СИРЕНЕВ ЛОКИ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '130 СИРИО НВСБ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '063 СИРЕНЕВ ЛОКИ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '058 СИРЕНЕ2 ЛОКИ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '227 СИРЕНЕВ НСК-ВИ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '060 СИРЕНЕВ ВИ-ЦМГ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '179 СИРЕНЕВ ВИ-МГНЦ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '002 ОТЛИВ С ЛБИ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '098 СИРБХ СПБ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '003 ОТЛИВ С ЛБИ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '315 СИРЕНЕВ ВИ-СПБ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '495 СИРЕНЕВ ВИ-ЕВРО': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '461 МАТЬ ВИ-ГЕН': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '462 ОТЕЦ ВИ-ГЕН': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '978 СИРЕНЕВ ВИ-ЛАП': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '152 СИР/Ж/Г ПЦРКР': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '224 СИР/Ж/Г ПЦРКР': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '369 СИРЕН ВИ-СПБ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '344 СТИМУЛ СПБ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '061 СИР/Г ЛБИ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '489 СИРГ ПЦР': CONTAINER_ICONS_CLASSES.LILAC_CODE,
  '527 СИРЕНЕВ ВИ-КДЛ': CONTAINER_ICONS_CLASSES.LILAC_CODE,
})

export const WHITE_CODES = Object.freeze({
  '088 МИК ЛМБМИК': CONTAINER_ICONS_CLASSES.WHITE_CODE,
})

export const INVITRO_CONTAINER_CODES = Object.freeze({
  RED_CODE: RED_CODES,
  GREEN_CODE: GREEN_CODES,
  YELLOW_CODE: YELLOW_CODES,
  GRAY_CODE: GRAY_CODES,
  BLUE_CODE: BLUE_CODES,
  LILAC_CODE: LILAC_CODES,
  WHITE_CODE: WHITE_CODES,
})
