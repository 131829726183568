import Template from './template.js'
import { injectWatchers } from './persistent_template_watchers.js'

export default class PersistentTemplate extends Template {
  constructor (initialData, preset, template) {
    super(initialData, preset, template)
    injectWatchers(this, initialData)
  }

  dependentWorkTimesChanges () {
    return this.workTimeTransformationChain.some((watcher) => watcher())
  }

  scheduleTemplateChanges () {
    return this.scheduleTemplateTransformationChain.some((watcher) => watcher())
  }

  intervalToIntervalTransformation () {
    return this.intervalToIntervalTransformationChain.some((watcher) => watcher())
  }
}
