/**
 * @typedef {Function} RequestBuilderStep
 * @returns {Object}
 */

import { LIMIT } from '@/specific/reports/const'

/**
 * This class requires the Report mixin to be included into target component.
 * It uses data from that mixin.
 */
export default class ReportRequestBuilder {
  /**
   * @param {Vue} vm
   */
  constructor (vm) {
    this.vm = vm
    this.params = {}
    /**
     * @type {RequestBuilderStep[]}
     */
    this.steps = []
  }

  /**
   * @return {ReportRequestBuilder}
   */
  build () {
    this.add({
      offset: (this.vm.currentPage - 1) * LIMIT,
      limit: LIMIT,
      report: {
        period: this.vm.period,
      },
    })

    /**
     * Добавление через forEach быстрее на ~50% чем `Object.assign` с `...steps.map()`
     * @link https://jsbench.me/2vkp2hqeh7/1
     */
    this.steps.forEach((step) => this.add(step))

    return this
  }

  /**
   * Метод добавления шагов вручную. С его помощью можно добавить шаг непосредственно перед отправкой запроса.
   *
   * @param {object|RequestBuilderStep} newParams
   * @return {ReportRequestBuilder}
   */
  add (newParams) {
    Object.assign(this.params, typeof newParams === 'function' ? newParams() : newParams)

    return this
  }

  /**
   * Add a step to the build process. The callback should return an object
   * that will be merged into the request body. Steps are merged in the FIFO order.
   *
   * @param {RequestBuilderStep} cb
   */
  addStep (cb) {
    this.steps.push(cb)
  }

  /**
   * @return {object}
   */
  get () {
    return this.params
  }
}
