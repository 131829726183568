<!--
  7jt1ev (10)
  Компонент выводит кнопку "Подключить модуль" на карточке в рабочем столе руководителя
-->
<template>
  <div class="introduction-button-wrapper">
    <button
      class="btn btn-grey-dark btn-with-icon introduction-button"
      type="button"
      @click="onClick"
    >
      <i class="btn-with-icon_icon fad fa-toggle-off" />
      <span class="btn-with-icon_text">{{ T.introduction.connect_module }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'IntoductionButton',
  props: {
    icon: {
      type: String,
      default: '',
    },
    innerText: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClick () {
      let modal = Utils.gIntroductionModal
      modal.icon = this.icon
      modal.innerText = this.innerText
      modal.modalShow()
    },
  },
}
</script>
