<template>
  <modal
    id="legal-rep-create-modal"
    :modal-header-buttons="modalButtonsAll"
    modal-visibility
    modal-class="modal-xl"
    @close="close"
  >
    <template #header>
      <span class="fad fa-user-edit" />
      {{ t('create_legal_representative') }}
    </template>

    <template #body>
      <epic-spinner :visibility="loading" />
      <div class="legal-form d-flex">
        <client-form
          :sexes="[...SEXES_VALUES]"
          :online-access-options="[...ALLOWED_PROHIBITED_FORM]"
          :client-groups="clientGroups"
          :errors="validationMessages"
          :disabled="loading"

          :second-name.sync="form.secondName"
          :name.sync="form.name"
          :surname.sync="form.surname"
          :birthdate.sync="form.birthdate"
          :phone.sync="form.phone"
          :sex.sync="form.sex"
          :entry-online-access.sync="form.onlineAccess"
          :groups.sync="form.groups"
          :email="form.email"
          :treating-doctor.sync="form.treatingDoctor"
          :personal-discount.sync="form.personalDiscount"
          :send-data-to-egisz.sync="form.sendDataToEgisz"
          :snils.sync="form.snils"
          :additional.sync="form.additional"
          :patient-card-number.sync="form.patientCardNumber"

          @update:second-name="setClientSearchParam('second_name', $event)"
          @update:name="setClientSearchParam('name', $event)"
          @update:surname="setClientSearchParam('surname', $event)"
          @update:phone="setClientSearchParam('phone', $event)"
          @update:email="form.email = $event; validationMessages.email = []"
          @request-demo="showDemoClient"
          @phone-mask-settings-change="clientPhoneMaskSettings = $event"
        >
          <template #legal-representatives-label>
            <span class="empty-slot" />
          </template>
          <template #legal-representatives-value>
            <span class="empty-slot" />
          </template>
        </client-form>

        <div class="legal-form-middle">
          <client-form-tabs
            :errors="validationMessages"
            :full-address="form.fullAddress"
            :document-types="documentTypes"

            :index.sync="form.index"
            :country.sync="form.country"
            :region.sync="form.region"
            :area.sync="form.area"
            :city.sync="form.city"
            :street.sync="form.street"
            :house.sync="form.house"
            :flat.sync="form.flat"
            :document-type.sync="form.documentType"
            :document-series.sync="form.documentSeries"
            :document-number.sync="form.documentNumber"
            :who-issue.sync="form.whoIssue"
            :issue-date.sync="form.issueDate"
            :company.sync="company"
            :position.sync="form.position"
            :nsi-profession.sync="form.nsiProfession"
            :department.sync="form.department"
            :oms.sync="form.oms"
            :inn.sync="form.inn"
            :snils.sync="form.snils"
            :no-sms.sync="form.noSms"
            :no-whats-app.sync="form.noWhatsApp"
            :no-sms-dispatches.sync="form.noSmsDispatches"
            :no-calls.sync="form.noCalls"
            :no-email.sync="form.noEmail"
            :nsi-russian-subject-id.sync="form.nsiRussianSubjectId"
            @add-company="$emit('add-company')"
          />

          <client-service-card :content.sync="form.serviceCard" />
        </div>

        <appointment-similar-clients
          :clients="similarClients.clients"
          :selected.sync="similarClients.selected"
          @update:selected="setClientFromSearch"
        />
      </div>
    </template>

    <template #footer-right>
      <guarded-control
        tag="button"
        :permissions="['canManageClient']"
        :disabled="hasErrors()"
        class="btn btn-success btn-with-icon modal-save"
        type="button"
        @click:allowed="submit"
      >
        <span class="btn-with-icon_icon fad fa-save" />
        <span class="btn-with-icon_text">
          {{ t('save') }}
        </span>
      </guarded-control>
      <button
        class="btn btn-primary btn-with-icon modal-close"
        type="button"
        @click="close"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">
          {{ t('close') }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>
import { birthdate, minLength } from '@/lib/validators/rules'
import { requiredValidator } from '@/lib/validators/validators'
import AppointmentSimilarClients from '@/vue_components/appointment/appointment_similar_clients.vue'
import ClientForm from '@/vue_components/client/client_form.vue'
import { ClientFormConsumer } from '@/vue_components/client/client_form_consumer'
import ClientFormTabs from '@/vue_components/client/client_form_tabs.vue'
import ClientServiceCard from '@/vue_components/client/client_service_card.vue'
import { SimilarClientsConsumer } from '@/vue_components/client/similar_clients_consumer'
import GuardedControl from '@/vue_components/common/guarded_control'
import { clientAdapter } from '@/vue_components/doctor_schedules/adapters'
import { ALLOWED_PROHIBITED_FORM, SEXES_VALUES } from '@/vue_components/doctor_schedules/consts'
import {
  getDefaultFormClient,
  getDefaultValidationsClient,
  getDefaultValidationsClientTabs,
} from '@/vue_components/doctor_schedules/methods/appointment_default_data'
import { clientEndpoint } from '@/api_entities/client/clients_endpoint'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import { ModalConsumer } from '@/vue_components/mixins/modals/modal_consumer'
import { ValidationHolder } from '@/vue_components/mixins/validationHolder'
import Modal from '@/vue_components/modal.vue'
import { validateSnils } from '@/vue_components/doctor_schedules/doctor_schedules_methods'
import { getMatchingText, pinText } from '@/vue_components/client/MatchingClient/composables/texts'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { fillClientFormByPromobotData } from '@/vue_components/doctor_schedules/promobotHelpers'

export default {
  components: {
    GuardedControl,
    AppointmentSimilarClients,
    ClientForm,
    ClientFormTabs,
    ClientServiceCard,
    EpicSpinner,
    Modal,
  },

  mixins: [SimilarClientsConsumer, ValidationHolder, ClientFormConsumer, ModalConsumer, SpinnerHolder],

  props: {
    clientGroups: {
      type: Array,
      required: true,
    },
    documentTypes: {
      type: Array,
      required: true,
    },
  },

  data () {
    return {
      validationMessages: {
        ...getDefaultValidationsClient(),
        ...getDefaultValidationsClientTabs(),
      },
      form: getDefaultFormClient(),
      loading: false,
      SEXES_VALUES,
      ALLOWED_PROHIBITED_FORM,
      clientPhoneMaskSettings: { ...Services.phoneMask.defaultMaskSettings },
    }
  },

  computed: {
    company: {
      get () {
        return this.$store.getters['legalRepresentatives/GET_COMPANY']
      },
      set (value) {
        this.$store.commit('legalRepresentatives/SET_COMPANY', value)
      },
    },
    clientFormDisabled () {
      return this.disabled || this.loading
    },
  },

  watch: {
    clientsSearch: {
      deep: true,
      handler (newValue) {
        if (
          !this.similarClients.selected &&
          (newValue.surname.length > 2 ||
            newValue.name.length > 2 ||
            newValue.second_name.length > 2 ||
            newValue.phone.length > 2)
        ) {
          this.findSimilarClients(newValue)
        }
      },
    },
    'form.surname' (newValue) {
      this.validate('surname', newValue, requiredValidator)
    },
    'form.birthdate' (newValue) {
      const rules = [birthdate].filter(Boolean)
      const messages = {
        birthdate: t('incorrect_birthdate'),
      }
      this.validateRules('birthdate', newValue, rules, messages)
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
    },
    'form.phone' (newValue) {
      const minPhoneLength = this.clientPhoneMaskSettings.length
      this.validateRules('phone', newValue, [minLength(minPhoneLength)], {
        minLength: t('errors.rules.phone.length?', { length: minPhoneLength }),
      })
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
    },
    'form.name' () {
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
    },
    'form.secondName' () {
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
    },

    'form.snils' () {
      this.validationMessages.snils = []
      this.validationMessages.other.snils = []
    },

    'form.patientCardNumber' () {
      this.validationMessages.patient_card_number = []
    },
  },

  created () {
    this.subscribeToPromobotPubSubEvents()
  },

  methods: {
    setClientFromSearch (selected) {
      if (selected) {
        this.form.clientId = selected.id
        Object.assign(this.form, clientAdapter.toClient(selected))
        this.resetSimilarClients()
        this.clientEdited = false
      }
    },

    submit () {
      if (!validateSnils(
        { form: { client: this.form }, validationMessages: this.validationMessages },
        this.setValidationMessages
      )) { return }

      this.loading = true

      const clientData = { ...this.form, company: this.company }
      clientEndpoint.create(clientData)
        .then(this.createAndClose)
        .catch((error) => {
          this.setValidationMessages(this.validationMessages, error.responseJSON)
          this.confirmAddExistedLegal(error.responseText, clientData)
        })
        .finally(() => {
          this.loading = false
        })
    },

    clearData () {
      Object.assign(this.$data, this.$options.data())
      this.$store.dispatch('legalRepresentatives/resetForm')
      this.$nextTick(() => {
        this.validationMessages = getDefaultValidationsClient()
      })
    },

    close () {
      this.$emit('close')

      this.clearData()
    },

    createAndClose (legalRep) {
      this.$emit('create', legalRep)
      this.close()
    },

    showDemoClient () {
      Utils.fakeForms.client()
        .then((client) => {
          this.resetSimilarClients()
          this.setDemoClient(client, 'form')
        })
    },

    confirmAddExistedLegal (rawErrorText, legalRep) {
      const messageText = getMatchingText(rawErrorText)
      if (!messageText) { return }

      this.$confirm(
        `${messageText} ${pinText}`,
        t('client_matching'),
        { type: 'warning', confirmButtonText: t('yes'), cancelButtonText: t('cancel') }
      )
        .then(() => { this.findClientById(this.form.clientId) })
        .catch(() => {})
    },

    findClientById (id) {
      const promise = clientEndpoint.get(id)
        .then((legalRep) => { this.createAndClose(legalRep) })
        .catch(Utils.reportError('create_legal_representative_modal:findClientById'))

      this.withSpinner(promise)
    },

    subscribeToPromobotPubSubEvents () {
      window.PubSub.on('promobotDataScanned', (target, data) => { this.onPromobotDataScanned(target, data) })
      window.PubSub.on('promobotDataScanStarted', (target, data) => { this.onPromobotDataScanStarted(target, data) })
      window.PubSub.on('promobotDataScanFinished', (target, data) => { this.onPromobotDataScanFinished(target, data) })
    },

    /**
     * @param {string} target
     * @param {{ type: DocTypes, data: DocumentData[] }} scannedData
     */
    onPromobotDataScanned (target, scannedData) {
      const client = this.form
      const { type, data, doc_type: docType, results } = scannedData

      fillClientFormByPromobotData(
        client,
        results || data,
        {
          type: type || docType,
          documentTypes: this.GET_DOCUMENT_TYPES,
        }
      )
    },

    onPromobotDataScanStarted (target, data) {
      this.outerDataLoading = true
    },

    onPromobotDataScanFinished (target, data) {
      this.outerDataLoading = false
    },
  },
}
</script>
