<template>
  <switch-filter
    v-bind="$attrs"
    :label="t('reports.include_discount')"
    v-on="$listeners"
  />
</template>

<script>
import SwitchFilter from '@/vue_apps/ReportsModule/_filters/components/share/SwitchFilter.vue'

export default {
  name: 'IncludingDiscountsFilter',
  components: { SwitchFilter },
}
</script>
