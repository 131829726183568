import { ReportParamFactory, ReportParamStrict } from '../shared'
import { doctorsProductivityReportFiltersSelectors as selectors } from './filtersSelectors'
import { IDoctorsProductivityReportFiltersGet } from './interfaces'

export const _getDoctorsProductivityReportFilters = (): IDoctorsProductivityReportFiltersGet => ({
  period: ReportParamFactory.getRawParam(selectors.period),
  clinic: ReportParamFactory.getSelectParam(selectors.clinic) as ReportParamStrict,
  appointmentType: ReportParamFactory.getSelectParam(selectors.appointmentType) as ReportParamStrict,
  specialty: ReportParamFactory.getSelectParam(selectors.specialty) as ReportParamStrict,
  entryWithoutOrderInclusion: ReportParamFactory.getButtonsParam(selectors.entryWithoutOrderInclusion) as ReportParamStrict,
  usersWithWorkTime: ReportParamFactory.getButtonsParam(selectors.usersWithWorkTime) as ReportParamStrict,
})
