import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { treatmentPlanListAdapter } from '@/_api/MTreatmentPlan/treatmentPlanListAdapter'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { MAX_LIMIT } from '@/specific/reports/const'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'

export class MTreatmentPlanPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'treatmentPlan',
      location: 'MTreatmentPlanPresenter',
      routes: { all () {}, one () {}, list: Routes.list_treatment_plans_path },
    })
  }

  list (data, config) {
    const preparedData = treatmentPlanListAdapter.toServer(data)

    return super.list(preparedData, {
      toClientAdapter: (data) => treatmentPlanListAdapter.toClient(snakeToCamel(data)),
      ...config,
    })
  }

  activeTreatmentPlansCount (clientId) {
    return this.get({
      url: Routes.client_active_treatment_plans_count_path(clientId),
    }).promise
      .catch(() => Promise.resolve(0))
  }

  fetchEntries (treatmentPlanId, clientId) {
    const notification = new MRequestNotification(
      'MTreatmentPlanPresenter',
      'fetchAllMessage',
      'treatmentEntries'
    )

    return this.post({
      url: Routes.list_treatment_entries_path(),
      data: camelToSnake({ sortParams: { treatmentPlanId, clientId, offset: 0, limit: MAX_LIMIT } }),
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }

  transferTreeData (treatmentEntries, clientId) {
    const notification = new MRequestNotification(
      'MTreatmentPlanPresenter',
      'transferMessage',
      'treatmentEntries'
    )

    const defaultValue = {
      failedEntriesErrors: [],
      successfullySavedTreatmentEntryIds: [],
      requestError: true,
    }

    return this.post({
      url: Routes.create_from_treatment_entry_entries_path(),
      data: JSON.stringify(camelToSnake({ treatmentEntries, clientId })),
      dataType: 'json',
      contentType: 'application/json',
    }).promise
      .catch((request) =>
        MRequestError.withDefault({ ...defaultValue, request }, notification)())
  }
}
