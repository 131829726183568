import { defineDateFormat, fetchExportFile } from '@/specific/reports/export'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import { headerRow, paymentKind, totalRow } from '@/specific/reports/payments/const'
import { DEFAULT_OFFSET, MAX_LIMIT } from '@/specific/reports/const'

const getQueryParameters = (getFilters) => {
  const filters = getFilters()

  return {
    dateFormat: defineDateFormat(),
    csvComma: gon.application.csv_character,
    floatingComma: gon.localization.currency.decimal_character,
    headerRow,
    totalRow,
    paymentKind,
    offset: DEFAULT_OFFSET,
    limit: MAX_LIMIT,
    report: {
      period: filters.period,
    },
    ...ReportFiltersFactory.defineFor('payments', filters),
  }
}

/**
 * @param {() => PaymentsReportFilters } getFilters
 */
export function exportReport (getFilters) {
  return () => {
    const data = getQueryParameters(getFilters)
    fetchExportFile(data, Routes.report_payments_path(), t('payments'))
  }
}
