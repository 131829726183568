<template>
  <div class="entry-client-search">
    <m-search-select-input
      :placeholder="t('search_patien_of_service')"
      :loading="loading"
      :not-results="notResults"
      :allow-search="allowSearch"
      :disabled="false"
      :search-query="searchQuery"
      @update:searchQuery="setSearchQuery"
      @clearSelect="clearSelect"
    >
      <div
        v-if="sliced.clients.length > 0"
        class="clients-search-results"
      >
        <div class="dropdown-category-title">
          {{ t('clients') }}
        </div>
        <div
          v-for="client in sliced.clients"
          :key="client.id"
          @click="selectClient(client)"
        >
          <el-dropdown-item>
            {{ client.short_info }}
          </el-dropdown-item>
        </div>
      </div>
      <div
        v-if="sliced.entry_types.length > 0"
        class="entry-types-search-results"
      >
        <div class="dropdown-category-title">
          {{ t('entries') }}
        </div>
        <div
          v-for="entry in sliced.entry_types"
          :key="entry.id"
          class="entry-option"
          @click="clearSelect"
        >
          <el-dropdown-item>
            <span class="entry-option__number">{{ entry.number }}</span>

            <span
              v-if="entry.analysis_laboratory"
              class="entry-option__analysis-laboratory-title"
            >
              {{ entry.analysis_laboratory.title }}
            </span>

            <span class="entry-option__title">{{ entry.title }}</span>
            <span class="entry-option__price">{{ entry.price }}</span>
          </el-dropdown-item>
        </div>
      </div>
    </m-search-select-input>
  </div>
</template>

<script>
import { findEntriesAndClient } from '../rest.js'
import MSearchSelectInput
  from '@/vue_present/_base/inputs/MSearchSelectInputDeprecated/MSearchSelectInputDeprecated.vue'
import { GLOBAL_DEBOUNCE_DEFAULT, GLOBAL_SEARCH_MIN_LENGTH } from '@/_global_scripts/GLOBAL_CONSTS'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { debounce } from 'lodash'

export default {
  name: 'EntryClientSearch',
  components: { MSearchSelectInput },
  mixins: [SpinnerHolder],

  inheritAttrs: false,

  data () {
    return {
      lastAppointment: null,
      searchQuery: '',
      loading: false,
      sliced: {
        clients: [],
        entry_types: [],
      },

      debouncedFindEntries () {},
    }
  },

  computed: {
    allowSearch () {
      return this.searchQuery.length >= GLOBAL_SEARCH_MIN_LENGTH
    },

    notResults () {
      return this.allowSearch &&
        this.sliced.clients.length < 1 &&
        this.sliced.entry_types.length < 1
    },

    reqEntriesParams () {
      if (this.searchQuery === '' || this.searchQuery.length < GLOBAL_SEARCH_MIN_LENGTH) {
        return {}
      }

      return {
        clinic_id: this.$store.getters.GET_SCHEDULE_CURRENT_CLINIC_ID,
        title: this.searchQuery,
      }
    },
  },

  watch: {
    reqEntriesParams () {
      this.debouncedFindEntries()
    },

    lastAppointment () {
      if (this.lastAppointment) {
        const clientAppointment = this.sliced.clients.find((client) => client.id === this.lastAppointment.id)
        if (clientAppointment) {
          this.$emit('choose-last-appointment-client', this.lastAppointment.id)
        }

        this.clearSelect()
      }
    },
  },

  created () {
    this.debouncedFindEntries = debounce(() => this.findEntries(), GLOBAL_DEBOUNCE_DEFAULT)
  },

  methods: {
    findEntries () {
      if (!this.allowSearch) { return }

      const promise = findEntriesAndClient(this.reqEntriesParams)
        .then((response) => {
          this.sliced.clients = response.clients
          this.sliced.entry_types = response.entry_types
        })
        .catch((error) => { console.error(error) })

      this.withSpinner(promise)
    },

    clearSelect () {
      this.searchQuery = ''
      this.sliced = {
        clients: [],
        entry_types: [],
      }
    },

    selectClient (item) {
      this.lastAppointment = item
    },

    setSearchQuery (newValue) {
      this.searchQuery = newValue ?? ''
    },
  },
}
</script>
