const PERMITTED_TABS = Object.freeze([
  'entries',
  'documents',
  'files',
  'treatment_plans',
  'dental_orders',
  'analysis_orders',
  'egisz',
])

export const getTabIfPermit = (tab = '') => {
  return PERMITTED_TABS.includes(tab)
    ? tab
    : PERMITTED_TABS[0]
}
