<template>
  <div id="egisz-root">
    <panel-heading-collapsed
      v-if="isNavigationSidebarCollapsed"
      :show="isNavigationSidebarCollapsed"
      :title="t('egisz.title')"
      :icon="'fad fa-browser'"
      @panel-collapsed-toggle="onNavigationPanelShowClick"
    />
    <panel-heading
      v-else
      :title="t('egisz.title')"
      :icon="'fad fa-browser'"
      class="navigation-menu"
    >
      <template #buttons>
        <i
          class="fad fa-angle-left clickable hidden-print navigation-menu__hide-navigation-icon"
          @click="onNavigationPanelShowClick"
        />
      </template>
      <div>
        <div
          v-if="!$route.meta.disableFilters"
          class="sidebar-switch"
        >
          <items-list
            v-model="currentSidebar"
            :items="buttons"
            id-item-field="id"
            displayed-item-field="title"
            item-class="vue-list-item"
            :multi-select="false"
          />
        </div>
        <menu-bar
          v-if="isSidebarMenu"
          :items="navRoutes"
          :collapse-transition="false"
          @menuItemClick="menuItemClick"
        />
        <router-view
          v-else
          name="filters"
        />
      </div>
    </panel-heading>

    <panel-heading
      class="current-page"
      :class="`current-page__${$route.name}`"
      :title="$route.meta.title"
      :icon="$route.meta.icon"
    >
      <router-view :can-manage="canManage" />
    </panel-heading>
  </div>
</template>

<script>
import PanelHeading from '@/vue_components/common/panel_heading'
import MenuBar from '@/vue_components/common/menu_bar'
import PanelHeadingCollapsed from '@/vue_components/common/panel_heading_collapsed'
import ItemsList from '@/vue_components/items_list'
import { navRoutes } from '@/vue_components/egisz/egisz_module/router/nav_routes'
import { mapGetters, mapMutations } from 'vuex'
import Storage from '@/services/storage'
import { DEFAULT_NAVIGATION_PANEL_EXPANDED, NAVIGATION_PANEL_LS } from '@/vue_components/egisz/egisz_module/const/const'

const SIDEBAR = {
  MENU: 'menu',
  FILTERS: 'filters',
}

export default {
  name: 'Egisz',
  components: { ItemsList, PanelHeadingCollapsed, MenuBar, PanelHeading },

  data () {
    return {
      storage: Storage,
      navRoutes: navRoutes(),

      isNavigationSidebarCollapsedState: false,

      buttons: [
        { id: 'menu', title: t('egisz.sidebar.menu') },
        { id: 'filters', title: t('egisz.sidebar.filters') },
      ],

      currentSidebar: [SIDEBAR.MENU],
      canManage: false,
    }
  },

  computed: {
    ...mapGetters('egisz/egiszWs', {
      vxGetLastUser: 'vxGetLastUser',
    }),

    isNavigationSidebarCollapsed: {
      get () { return this.isNavigationSidebarCollapsedState },
      set (value) {
        this.isNavigationSidebarCollapsedState = value
        this.storage.set(NAVIGATION_PANEL_LS, value)
      },
    },

    isSidebarMenu () {
      return this.currentSidebar[0] === SIDEBAR.MENU
    },
  },

  watch: {
    vxGetLastUser (to) {
      this.setCanUserSendToEgiszValue(to.registered_in_frmr)
    },
  },

  created () {
    this.extractNavigationPanelState()
    this.initAccessRights()
    this.maybeOpenFilters()
  },

  beforeDestroy () {
    this.vxSetWSConnection(null)
  },

  methods: {
    ...mapMutations('egisz/user', {
      vxSetAccessRights: 'vxSetAccessRights',
    }),

    ...mapMutations('egisz/egiszWs', {
      vxSetWSConnection: 'vxSetWSConnection',
    }),

    extractNavigationPanelState () {
      const navigationPanelState = this.storage.get(NAVIGATION_PANEL_LS)
      this.isNavigationSidebarCollapsed = navigationPanelState === null
        ? DEFAULT_NAVIGATION_PANEL_EXPANDED
        : navigationPanelState
    },

    initAccessRights () {
      this.canManage = Utils.can(Utils.ACTIONS.MANAGE, 'Egisz')
      this.setCanUserSendToEgiszValue(gon.application.current_user.registered_in_frmr)
    },

    setCanUserSendToEgiszValue (value) {
      this.vxSetAccessRights({ rule: 'canUserSendToEgisz', value })
    },

    /**
     * @param {NavRouteItem} menuItem
     */
    menuItemClick (menuItem) {
      /* todo: (если потребуется)
          реализовать предупреждение при переходе на другую страницу, не сохранив изменения */
      if (this.$route.name !== menuItem.routerName) {
        this.$router.push({ name: menuItem.routerName })
        this.maybeOpenFilters()
      }
    },

    maybeOpenFilters () {
      const route = this.$route.matched[0]

      if (route.components && route.components.filters) {
        this.currentSidebar = [SIDEBAR.FILTERS]
      }

      const hasMenuQueryParam = this.$route.query.hasOwnProperty(SIDEBAR.MENU)
      if (hasMenuQueryParam) {
        this.currentSidebar = [SIDEBAR.MENU]
      }
    },

    onNavigationPanelShowClick () {
      this.isNavigationSidebarCollapsed = !this.isNavigationSidebarCollapsed
    },
  },
}
</script>
