import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import {
  IAttachmentTabHyperlink,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/interfaces/IAttachmentTabHyperlink'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class AttachmentTabHyperlinkItem extends MListServiceItem {
  title: MTableCell

  link: string

  date: MTableCell

  constructor (item: IAttachmentTabHyperlink) {
    super()

    this.id = item.id

    this.date = new MTableCell(Utils.getFormattedDate(item.createdAt))

    this.link = item.link

    this.title = new MTableCell(item.title || this.link)
      .addClasses('primary')
      .addCut()
      .addTooltip(this.link)
  }
}
