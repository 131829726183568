export const salaryByPaymentsDetailedSortingMap = Object.freeze({
  tableDoctor: 'entryDoctor',
  tableTitle: 'entryTitle',
  tableDate: 'entryDate',
  tableOrder: 'orderNumber',
  tableOrderDate: 'orderDate',
  tableClient: 'clientSurname',
  tableOrderSum: 'orderSum',
  tableOrderFinalSum: 'orderFinalSum',
  tableAmountInOrder: 'orderAmount',
  tableLastPayment: 'lastPayment',
  tableLastPaymentDate: 'lastPaymentDate',
  tableLastPaymentTotalPaid: 'lastPaymentTotalPaid',
  tableLastPaymentKind: 'lastPaymentKind',
  tableLastPaymentUser: 'lastPaymentUser',
  tableSum: 'totalSum',
  tableRate: 'userRate',
  tableSalary: 'totalSalary',
})
