import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { protocolListAdapter } from '@/vue_apps/Protocols/api/protocolListAdapter'
import { protocolAdapter } from '@/vue_apps/Protocols/api/protocolAdapter'
import { camelToSnake } from '@/_api/_requests/helpers'

export class MProtocolPresenter extends MPresenterBase {
  /* Routes.synchronize_pdf_api_internal_entry_path */
  /* Routes.perform_pdf_api_internal_protocol_path */

  constructor () {
    super({
      routes: {
        all: Routes.api_internal_protocols_path,
        one: Routes.api_internal_protocol_path,
        list: Routes.list_api_internal_protocols_path,
      },
      entity: 'protocol',
      location: 'MProtocolPresenter',
    })
  }

  fetch (id, config = {}) {
    return super.fetch(id, {
      ...config,
      toClientAdapter: protocolAdapter.toClientAdapter,
    })
  }

  list (listData, config = {}) {
    return super.list(listData, config)
      .then(protocolListAdapter.toClient)
  }

  submit (protocol, config = {}) {
    return super.submit(protocol, {
      ...config,
      toJSON: true,
      toServerAdapter: (data) => ({
        protocol: camelToSnake(protocolAdapter.toServer(data)),
      }),
      toClientAdapter: protocolAdapter.toClientAdapter,
    })
  }

  generatePdf (entryId, protocolId) {
    return super.post({
      url: Routes.synchronize_pdf_api_internal_entry_path(entryId),
      data: JSON.stringify(camelToSnake({ protocolId })),
      dataType: 'json',
      contentType: 'application/json',
    }).promise
  }
}
