import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getListSchema = () =>
  new MSiSchema()
    .addPagination()
    .addCreateButton()
    .addTableSchema(
      new MTableSchema({
        headers: {
          number: new MTableHeader().addFixedWidth('50px'),
          customNumber: new MTableHeader().addFixedWidth('100px'),
          statusIcon: new MTableHeader().addFixedWidth('30px'),
          status: new MTableHeader(),
          date: new MTableHeader().addFixedWidth('100px'),
          dueDate: new MTableHeader().addFixedWidth('100px'),
        },
      })
    )
