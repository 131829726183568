import { ICatalog } from '@/_declarations/ICatalog'
import { TDatePeriod } from '@/_declarations/TDatePeriod'
import { periodToCalendarRangeName } from '@/vue_components/calendar_ranges'
import { TFiltersProfileToServerPeriod as ToServerPeriod } from '../../interfaces/filtersTypes/shared/toServer/periods'
import {
  TFiltersProfileToServerHumanSelect as ToServerHumanSelect,
  TFiltersProfileToServerSelectMulti as ToServerSelectMulti,
  TFiltersProfileToServerSelectSingle as ToServerSelectSingle,
} from '../../interfaces/filtersTypes/shared/toServer/selects'
import { IHuman } from '@/_declarations/IHuman'
import { TFullOrShortNameHuman } from '@/_declarations/TFullOrShortNameHuman'
import { extractCatalog } from '@/_medods_standart_library/msl'

export class FiltersProfilesFormattersToServer {
  /* Для периодов в формате ['YYYY-MM-DD', 'YYYY-MM-DD'] */
  static formatPeriod (period: TDatePeriod): ToServerPeriod {
    return periodToCalendarRangeName(period) || period
  }

  /* Для значений селекторов */
  static formatSingle (item: ICatalog | null): ToServerSelectSingle {
    if (item) { return [extractCatalog(item)] }
  }

  /* Для значений мультиселекторов */
  static formatMulti (items: ICatalog[] | [] | null): ToServerSelectMulti {
    if (items && items.length) { return items.map(extractCatalog) }
  }

  /* Для значений селекторов, где выбираются людишки */
  static formatHuman (
    human: IHuman | TFullOrShortNameHuman | null,
    isShortName: Boolean = true
  ): ToServerHumanSelect {
    if (!human) { return }

    const { id, shortName, fullName } = human

    return [
      isShortName
        ? { id, shortName }
        : { id, fullName },
    ]
  }
}
