<template>
  <table class="kdl-waybill-table">
    <thead class="table-header">
      <tr>
        <td colspan="5">
          {{ t('laboratories.waybill') }}
        </td>
      </tr>
      <tr>
        <td colspan="2">
          {{ currentClinicTitle }}
        </td>
        <td colspan="3">
          {{ clinicIdentifier }}
        </td>
      </tr>
      <tr>
        <td colspan="5">
          {{ currentDate }}
        </td>
      </tr>
      <tr>
        <td rowspan="2">
          {{ t('laboratories.waybillFields.numberField') }}
        </td>
        <td rowspan="2">
          {{ t('laboratories.waybillFields.biomaterial') }}
        </td>
        <td colspan="3">
          {{ t('laboratories.waybillFields.quantity') }}
        </td>
      </tr>
      <tr>
        <td>
          {{ t('laboratories.waybillFields.transferredLpu') }}
        </td>
        <td>
          {{ t('laboratories.waybillFields.acceptedByCourier') }}
        </td>
        <td>
          {{ t('laboratories.waybillFields.kdlAccepted') }}
        </td>
      </tr>
    </thead>
    <tbody>
      <slot name="kdl-biomaterials" />
      <tr>
        <td class="number" />
        <td />
        <td />
        <td />
        <td />
      </tr>
      <tr>
        <td
          class="demarcation-line"
          colspan="5"
        />
      </tr>

      <tr class="table-header">
        <td rowspan="2">
          {{ t('laboratories.waybillFields.numberField') }}
        </td>
        <td rowspan="2">
          {{ t('laboratories.waybillFields.biomaterialForBacteriologicalExamination') }}
        </td>
        <td colspan="3">
          {{ t('laboratories.waybillFields.quantity') }}
        </td>
      </tr>
      <tr class="table-header">
        <td>
          {{ t('laboratories.waybillFields.transferredLpu') }}
        </td>
        <td>
          {{ t('laboratories.waybillFields.acceptedByCourier') }}
        </td>
        <td>
          {{ t('laboratories.waybillFields.kdlAccepted') }}
        </td>
      </tr>
      <slot name="kdl-biomaterials-for-bacteriological-examination" />
      <tr>
        <td class="number" />
        <td />
        <td />
        <td />
        <td />
      </tr>
      <tr>
        <td
          class="demarcation-line"
          colspan="5"
        />
      </tr>

      <tr>
        <td colspan="2">
          {{ t('laboratories.waybillFields.deliveryTimeToCourier') }}
        </td>
        <td>
          {{ t('laboratories.waybillFields.timestamp') }}
        </td>
        <td />
        <td />
      </tr>
      <tr>
        <td colspan="2">
          {{ t('laboratories.waybillFields.courierHandedOver') }}
        </td>
        <td />
        <td />
        <td />
      </tr>
      <tr>
        <td colspan="2">
          {{ t('laboratories.waybillFields.couriersSignature') }}
        </td>
        <td />
        <td />
        <td />
      </tr>
      <tr>
        <td colspan="2" />
        <td>
          {{ t('laboratories.waybillFields.inKdl') }}
        </td>
        <td />
        <td>
          {{ t('laboratories.waybillFields.inMbl') }}
        </td>
      </tr>
      <tr>
        <td colspan="2">
          {{ t('laboratories.waybillFields.deliveryTimeToLab') }}
        </td>
        <td>
          {{ t('laboratories.waybillFields.timestamp') }}
        </td>
        <td />
        <td>
          {{ t('laboratories.waybillFields.timestamp') }}
        </td>
      </tr>
      <tr>
        <td colspan="2">
          {{ t('laboratories.waybillFields.laboratoryAssistant') }}
        </td>
        <td />
        <td />
        <td />
      </tr>
      <tr>
        <td colspan="2">
          {{ t('laboratories.waybillFields.laboratoryAssistantSignature') }}
        </td>
        <td />
        <td />
        <td />
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'KdlWaybillTable',

  props: {
    clinicIdentifier: {
      type: String,
      default: '',
    },
  },

  computed: {
    currentClinicTitle () {
      const clinicTitle = gon.application.current_clinic.title

      return `${t('laboratories.waybillFields.sender')} ${clinicTitle}`
    },

    currentDate () {
      const date = Utils.getFormattedDate(moment(), Utils.longDateFormat)

      return `${t('laboratories.waybillFields.date')} ${date}`
    },
  },
}
</script>
