import {
  SemdListSeparator,
} from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdListSeparator'
import { SemdEntity } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdEntity'
import { SemdArea } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdArea'
import { getSemdEntities } from '@/vue_apps/Protocols/const/getSemdEntities'

const extractContent = (entity: SemdEntity | SemdArea, templateHtml: string) => {
  if (entity instanceof SemdEntity) {
    return SemdEntity.parseContent(entity.id, templateHtml)
  }

  if (entity instanceof SemdArea) {
    return SemdArea.parseContent(entity.id, templateHtml)
  }

  return null
}

export const getActiveSemdEntitiesMap = (semdType: number, templateHtml: string) => {
  const semdEntities = getSemdEntities(semdType)
    .filter((entity) => !(entity instanceof SemdListSeparator))
    .map((entity: SemdEntity | SemdArea) => {
      return {
        id: entity.id,
        content: extractContent(entity, templateHtml),
      }
    })
    .filter((entity) => entity.content)

  return semdEntities.reduce((acc, entity) => {
    acc[entity.id] = entity.content

    return acc
  }, {})
}
