<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>
    <si-table
      ref="table"
      :spinner="isLoading"
      horizontal
      class="print-flex"
    >
      <template #header>
        <procedures-report-table-header
          :doctor-area-module-enabled="doctorAreaModuleEnabled"
          :workplaces-enabled="workplacesEnabled"
          :header="header"
          :sortable-column="sortableColumn"
          @onSortChange="onSortChange"
        />
      </template>

      <template
        v-if="rows.length"
        #body
      >
        <procedures-report-table-row
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
          :row="row"
          :doctor-area-module-enabled="doctorAreaModuleEnabled"
          :workplaces-enabled="workplacesEnabled"
          :money-symbol="moneySymbol"
        />
      </template>

      <template #footer>
        <procedures-report-table-footer
          v-if="isDirty"
          class="total"
          :footer="footer"
          :workplaces-enabled="workplacesEnabled"
        />
      </template>
    </si-table>

    <si-footer v-if="rows.length">
      <pagination
        :page-count="pageCount"
        layout="prev, pager, next"
        class="hidden-print"
        @current-change="loadData"
      />
    </si-footer>
  </si>
</template>

<script>
import Pagination from '@/vue_components/common/pagination.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import Report from '@/vue_components/mixins/report.js'
import SortableReport from '@/vue_components/mixins/sortableReport'
import ReportWithTooltips from '@/vue_components/mixins/withTooltips'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import { props } from '@/vue_components/reports/ProceduresReport/ProceduresReportProps'
import { header } from '@/vue_components/reports/ProceduresReport/consts'
import ProceduresReportTableHeader
  from '@/vue_components/reports/ProceduresReport/components/ProceduresReportTableHeader'
import ProceduresReportTableRow from '@/vue_components/reports/ProceduresReport/components/ProceduresReportTableRow'
import ProceduresReportTableFooter
  from '@/vue_components/reports/ProceduresReport/components/ProceduresReportTableFooter'

export default {
  name: 'ProceduresReport',

  components: {
    ProceduresReportTableFooter,
    ProceduresReportTableRow,
    ProceduresReportTableHeader,
    Pagination,
    Si,
    SiTable,
    SiFooter,
  },

  mixins: [Report, SortableReport, ReportWithTooltips],

  props,

  data () {
    return {
      header,
    }
  },

  computed: {
    subtitleParts () {
      return [
        this.clinic.name,
        this.period,
        this.entryWithOrder.id && this.entryWithOrder.name,
        this.entryWithDiscount.id && this.entryWithDiscount.name,
        this.entryTypes.map((entryType) => entryType.name).join(', '),
        this.client.name,
        this.clientGroups.map((group) => group.name).join(', '),
        this.clientStatus.name,
        this.clientSex.name,
        this.entryKind.name,
        this.entryTypeCategories.map((category) => category.name).join(', '),
        this.administrator.name,
        this.doctor.name,
      ]
    },
  },

  created () {
    this.reportClient.init({
      url: Routes.report_procedures_path(),
    })

    this.requestBuilder.addStep(() => ReportFiltersFactory.defineFor('procedures', this))
  },

  methods: {
    fillTable (result) {
      this.rows = result.data.map(({ entry }) => entry)

      this.footer = {
        total: T.total,
        ...result.total,
      }
    },

    afterReportUpdated () {
      this.updateTooltips()
    },
  },

}
</script>
