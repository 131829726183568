import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import {
  clientAnalysisOrdersListAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AnalysisOrdersTab/api/clientAnalysisOrdersListAdapter'

export class MClientAnalysisOrderPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'analysisOrder',
      location: 'MClientAnalysisOrderPresenter',
      routes: {
        all () {},
        one () {},
        list: Routes.list_api_internal_medical_records_analysis_orders_path,
      },
    })
  }

  list (listData, config = {}) {
    return super.list(listData, {
      ...config,
      toJson: true,
      toClientAdapter: clientAnalysisOrdersListAdapter.toClient,
    })
  }
}
