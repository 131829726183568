<template>
  <div class="work-time-adjust">
    <div>
      <span class="fad fa-fw fa-calendar" />
      <span>
        <a :href="schedulePath">{{ t('set_up_work_schedule') }}</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    schedulePath () {
      return window.Routes.schedule_path()
    }
  }
}
</script>
