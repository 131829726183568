import { createVueApp } from '@/vue_components/create_vue_app'
import UserRolesLegacy from '@/vue_components/users/UserRolesLegacy/UserRolesLegacy'
import { useRolesAdapter } from '@/vue_components/users/UserRolesLegacy/logic'
import { cloneDeep } from 'lodash'

export const createUserRolesLegacy = async (el) => {
  createVueApp({
    el,
    name: 'UserRolesLegacyRoot',
    render: (h) => h(UserRolesLegacy, {
      props: {
        roles: useRolesAdapter(
          cloneDeep(gon.specific.roles),
          cloneDeep(gon.specific.authorized)
        ),

        userId: parseInt(gon.page.params.id),
      },
    }),
  })
}
