import Vue from 'vue/dist/vue.esm'
import EgiszClientSendCard from '@/vue_components/egisz/egisz_client_card/EgiszClientSender'
import { createStore } from '@/vue_components/store/store'

export const createEgiszClientCard = (el) => {
  if (!document.querySelector(el)) { return }

  const store = createStore()

  // eslint-disable-next-line no-new
  new Vue({
    el,
    name: 'CreateEgiszClientCardMaster',
    store,
    render: (h) => h(EgiszClientSendCard, {
      props: {
        clientId: gon.specific.client.id,
        canManage: Utils.can(Utils.ACTIONS.MANAGE, 'Egisz'),
      },
    }),
  })
}
