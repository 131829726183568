<template>
  <si-field>
    <div class="tbl-icon no-clip">
      {{ footer.total }}
      <footnote :text="footnotes.total" />
    </div>

    <div class="short_name" />

    <div class="tbl-icon" />

    <div class="date" />

    <div>
      <span :data-tooltip="footer.byCash | currency">
        {{ footer.byCash | currency }}
      </span>
    </div>

    <div>
      <span :data-tooltip="footer.byCashless | currency">
        {{ footer.byCashless | currency }}
      </span>
    </div>

    <div>
      <span :data-tooltip="footer.byCard | currency">
        {{ footer.byCard | currency }}
      </span>
    </div>

    <div>
      <span :data-tooltip="footer.byBalance | currency">
        {{ footer.byBalance | currency }}
      </span>
    </div>

    <div>
      <span :data-tooltip="footer.byCredit | currency">
        {{ footer.byCredit | currency }}
      </span>
    </div>

    <div>
      <span :data-tooltip="footer.totalIncome | currency">
        {{ footer.totalIncome | currency }}
      </span>
    </div>

    <div>
      <span :data-tooltip="footer.totalPaid | currency">
        {{ footer.totalPaid | currency }}
      </span>
    </div>

    <div>
      <span>
        {{ '‒' }}
        <footnote :text="footnotes.finalSum" />
      </span>
    </div>

    <div />
  </si-field>
</template>

<script>
import Footnote from '@/vue_components/common/footnote'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'PaymentsReportTableFooter',
  components: { SiField, Footnote },
  props: {
    footer: PropsTypes.Object(undefined, true),
    footnotes: PropsTypes.Object(undefined, true),
  },
}
</script>
