<template>
  <!--  <m-button-->
  <!--    :tooltip="t('checkDebtCorrectTooltip')"-->
  <!--    :loading="loading"  -->
  <!--    class="ml-5"-->
  <!--    size="mini"-->
  <!--    icon="billed"-->
  <!--    :text="t('check')"-->
  <!--    @click="onClick"-->
  <!--  />-->

  <!-- todo: удалить это, вернуть то, что выше  -->
  <button
    v-loading="loading"
    type="button"
    class="btn btn-default btn-with-icon btn-xs ml-5"
    @click="onClick"
  >
    <i
      class="btn-with-icon_icon fad fa-fw fa-credit-card"
      style="top: 1px"
    />

    <span>{{ t('check') }}</span>
  </button>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { MClientPresenter } from '@/_api/MClient/MClientPresenter'

export default {
  name: 'DebtCheckerApp',
  components: { MButton },
  mixins: [SpinnerHolder],

  props: {
    clientId: { type: [String, Number], required: true },
  },

  methods: {
    rewriteDebtValue (value) {
      const debtHTMLElement = document.querySelector('#debt_value')
      if (!debtHTMLElement) { return }
      debtHTMLElement.innerText = this.$filters.currency(value)
    },

    async onClick () {
      if (this.loading) { return }

      const { errors, isCorrect, value } = await this.withSpinner(new MClientPresenter().checkDebt(this.clientId))
      if (errors) { return }

      if (isCorrect) {
        return Utils.reportSuccess(t('debts.correctCalculation'))()
      }

      this.rewriteDebtValue(value)
      Utils.reportWarning('DebtCheckerApp::onClick', t('debts.incorrectCalculation', { value }))()
    },
  },
}
</script>
