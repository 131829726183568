import { createVueApp } from '@/vue_components/create_vue_app'
import ClientSearchFilter from '@/vue_apps/TrashBinsModule/ClientSearchFilter/ClientSearchFilter.vue'

let vueApp = null
export const createClientSearchFilterApp = (el = '#vue_client_filter_root') => {
  if (!document.querySelector(el)) { return }
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'ClientSearchFilterApp',
    render: (h) => h(ClientSearchFilter),
  })
}
