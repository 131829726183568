<template>
  <div
    class="appointment-type-preview"
    :class="status.class"
  >
    <span
      class="status-icon"
      :class="status.icon"
    />
    {{ t('client_fullname') }} 09:05-09:10
    <span class="fad fa-user new-client" />
    <span
      class="appointment-type-indicator__wrapper"
    >
      <span
        class="appointment-type-indicator__corner"
        :style="typeColor"
      />
    </span>
  </div>
</template>

<script>
import { statusIconClass } from '@/plugins/schedule/scheduleCommon/css_classes'

export default {
  name: 'AppointmentTypePreview',
  props: {
    color: {
      type: String,
      required: true,
    },
    status: {
      type: Object,
      required: true,
    },
  },

  computed: {
    backgroundColor () {
      return {
        backgroundColor: this.status.color,
      }
    },

    typeColor () {
      return {
        backgroundColor: this.color,
      }
    },

    statusIcon () {
      return statusIconClass[this.status]
    },
  },
}
</script>
