<template>
  <report-base
    class="report-loyal"
    :report="report"
    :schema="schema"
    @onReportClick="onReportClick"
    @onResetClick="onResetClick"
  >
    <!-- Клиники -->
    <reusable-doctor-clinics-list
      :value="report.filtersData.clinic"
      full-width
      :m-fixed-height="false"
      @onClinicChange="report.setFilterValue('clinic', $event)"
    />

    <!--    Период   -->
    <div class="report-filters__filter_periodFilter">
      <period-filter
        :value="report.filtersData.period"
        :m-fixed-height="false"
        @change="report.setFilterValue('period', $event)"
      />
    </div>

    <!-- Услуги -->
    <reusable-entry-types-list
      :value="report.filtersData.entryTypes"
      :m-fixed-height="false"
      full-width
      multiple
      @changeEntryTypes="report.setFilterValue('entryTypes', $event)"
    />

    <!-- Категории -->
    <m-tree-select
      :value="report.filtersData.categoryId"
      :m-fixed-height="false"
      :label="t('categories')"
      :items="entryTypesCategories"
      :full-path="false"
      full-width
      can-select-category
      show-only-last
      @change="report.setFilterValue('categoryId', $event)"
    />

    <!-- Минимальная сумма -->
    <m-input
      type="number"
      :label="t('reports.loyal.filters.finalSum')"
      :value="report.filtersData.finalSum"
      :m-fixed-height="false"
      @input="report.setFilterValue('finalSum', $event)"
    />

    <!-- Количество пациентов -->
    <m-select
      :value="report.filtersData.clientsCount"
      :label="t('reports.loyal.filters.clientsCount')"
      :items="clientsCountItems"
      :m-fixed-height="false"
      :clearable="false"
      use-custom-result="simple"
      full-width
      @change="report.setFilterValue('clientsCount', $event)"
    />
  </report-base>
</template>

<script>
import { LoyalReport } from '@/vue_apps/ReportsModule/LoyalReport/classes/LoyalReport'
import { getLoyalReportSchema } from '@/vue_apps/ReportsModule/LoyalReport/const/schema/getLoyalReportSchema'
import ReportBase from '@/vue_apps/ReportsModule/_ReportBase/ReportBase.vue'
import ReusableDoctorClinicsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorClinicsList.vue'
import PeriodFilter from '@/vue_apps/ReportsModule/_filters/components/PeriodFilter.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import {
  DEFAULT_CLIENTS_COUNT,
  getClientsCountItems,
} from '@/vue_apps/ReportsModule/LoyalReport/const/filters/getClientsCountItems'
import ReusableEntryTypesList from '@/vue_present/Reuse/Lists/EntryTypes/ReusableEntryTypesList.vue'
import MTreeSelect from '@/vue_present/_base/MTreeSelect/MTreeSelect.vue'
import {
  fetchTwoLevelsOfEntryTypesCategories,
} from '@/vue_apps/ReportsModule/LoyalReport/api/fetchTwoLevelsOfEntryTypesCategories'

export default {
  name: 'LoyalReportApp',
  components: { MTreeSelect, ReusableEntryTypesList, MSelect, MInput, PeriodFilter, ReusableDoctorClinicsList, ReportBase },
  data () {
    return {
      report: new LoyalReport(),
      schema: getLoyalReportSchema(),
      clientsCountItems: getClientsCountItems(),
      entryTypesCategories: [],
    }
  },

  mounted () {
    this.fetchTwoLevelsOfEntryTypesCategories()
  },

  methods: {
    async fetchTwoLevelsOfEntryTypesCategories () {
      this.entryTypesCategories = await fetchTwoLevelsOfEntryTypesCategories()
    },

    onReportClick () {
      document.querySelector('#report-top-info').innerText = t(
        'reports.long_titles.customer_loyalty',
        { clientsCount: this.report.filtersData.clientsCount }
      )
    },

    onResetClick () {
      document.querySelector('#report-top-info').innerText = t(
        'reports.long_titles.customer_loyalty',
        { clientsCount: DEFAULT_CLIENTS_COUNT }
      )
    },
  },
}
</script>
