import {
  DAY_INTERVAL_TEMPLATE,
  EVEN_TEMPLATE,
  ODD_TEMPLATE,
  WEEK_INTERVAL_TEMPLATE,
  WORK_TIME,
} from '../../../const.js'

export default class Template {
  constructor (initialData, preset, template) {
    this.id = initialData.id
    this.cabinetId = initialData.cabinetId
    this._startTime = initialData.startTime
    this._endTime = initialData.endTime
    this.templateTypeId = [initialData.templateTypeId]
    this.templateDayIntervalId = [initialData.templateDayIntervalId]
    this.templateWeekIntervalId = [initialData.templateWeekIntervalId]
    this.templateSelectedDaysIds = initialData.templateSelectedDaysIds
    this.availabilityForOnlineRecording = [initialData.availabilityForOnlineRecording]
    this.templateActive = [initialData.templateActive]
    this._propagationDate = initialData.propagationDate

    this.preset = preset
    this.template = template
  }

  get startTime () {
    return this._startTime
  }

  set startTime (newTime) {
    if (newTime) this._startTime = newTime
  }

  get endTime () {
    return this._endTime
  }

  set endTime (newTime) {
    if (newTime) this._endTime = newTime
  }

  get propagationDate () {
    return this._propagationDate
  }

  set propagationDate (newDate) {
    if (newDate) this._propagationDate = newDate
  }

  getRequestData (requestData = {}, applyOptions = {}) {
    const commonFields = {
      [`${this.preset}_id`]: this.id > 0 ? this.id : undefined,
      cabinet_id: this.cabinetId,
      start_time: this.startTime,
      end_time: this.endTime,
      type: this.templateTypeId[0],
      availability_for_online_recording: this.availabilityForOnlineRecording[0],
      ...requestData,
      apply_options: {
        ...applyOptions,
        interval_end_date: Utils.dateJStoRuby(this._propagationDate),
      },
    }

    switch (commonFields.type) {
      case WORK_TIME: return {
        ...commonFields,
        apply_options: undefined,
      }
      case EVEN_TEMPLATE: return {
        ...commonFields,
        active: JSON.parse(this.templateActive[0]),
      }
      case ODD_TEMPLATE: return {
        ...commonFields,
        active: JSON.parse(this.templateActive[0]),
      }
      case DAY_INTERVAL_TEMPLATE: return {
        ...commonFields,
        day_period: this.templateDayIntervalId[0],
        active: JSON.parse(this.templateActive[0]),
      }
      case WEEK_INTERVAL_TEMPLATE: return {
        ...commonFields,
        week_period: this.templateWeekIntervalId[0],
        week_period_days_include: this.templateSelectedDaysIds,
        active: JSON.parse(this.templateActive[0]),
      }
      default: throw new Error('invalid template type')
    }
  }
}
