import { showPaymentComments } from './payment_comments'
import { createPrintReceiptApp } from '@/vue_apps/FiscalService/printReceipt'

$document.on('rez/payments/new', () => {
  const form = FormFactory.build('payment')
  form.newItem()

  if (gon.specific.payer.id) {
    showPaymentComments(gon.specific.payer.id)
  }
})

$document.on('rez/payments/show', () => {
  const receiptButton = $('#print-receipt')
  const electronicalReceiptButton = $('#print-electronical-receipt')
  const copyReceiptButton = $('#print-receipt-copy')
  const workplaceId = gon.application.current_user.workplace_id

  Services.pubSub.subscribe('workplace.print_receipt', () => {
    $('#payments_page').loadSpin('stop')
  })

  Services.pubSub.subscribe('workplace.receipt_record', () => {
    $('#payments_page').loadSpin('stop')
    Turbolinks.visit()
  })

  Services.pubSub.subscribe('workplace.error', (msg) => {
    $('#payments_page').loadSpin('stop')
    bootbox.alert(msg.data.human_message)
  })

  if (gon.specific.receipt_records.length === 0) {
    if (!copyReceiptButton.hasClass('we-disabled')) {
      copyReceiptButton.addClass('we-disabled')
    }
  }

  copyReceiptButton.on('click', (e) => {
    e.preventDefault()

    if (gon.specific.receipt_records.length === 1) {
      if (gon.specific.receipt_records[0].document_number) {
        const documentNumber = gon.specific.receipt_records[0].document_number.toString()

        Services.workplace.printReport('doc_by_number', workplaceId, { doc_number: documentNumber })
      } else {
        bootbox.alert(t('fiscal.fd_number_not_found'))
      }
    }

    if (gon.specific.receipt_records.length === 0) {
      bootbox.alert(t('fiscal.printed_receipts_not_exist'))
    }

    if (gon.specific.receipt_records.length > 1) {
      bootbox.confirmYN(t('fiscal.last_receipt_copy?'), (confirmed) => {
        if (!confirmed) return
        const documentIndex = gon.specific.receipt_records.length - 1
        const documentNumber = gon.specific.receipt_records[documentIndex].document_number.toString()

        Services.workplace.printReport('doc_by_number', workplaceId, { doc_number: documentNumber })
      })
    }
  })

  receiptButton.on('click', (e) => {
    e.preventDefault()

    printReceiptBtnHandler('normal')
  })

  electronicalReceiptButton.on('click', (e) => {
    e.preventDefault()

    printReceiptBtnHandler('electronically')
  })

  const printReceipt = (printMethod) => {
    if (printMethod === 'electronically') {
      createPrintReceiptApp()
    } else {
      Services.workplace.sendMessage('print_receipt', {
        print_method: printMethod,
        payment_id: gon.specific.payment.id,
      })
      $('#payments_page').loadSpin('start')
    }
  }

  const printReceiptBtnHandler = (type) => {
    if (gon.specific.receipt_records.length === 0) {
      if (type === 'electronically') {
        printReceipt(type)

        return
      }

      bootbox.confirmYN(t('fiscal.print_receipt?'), (confirmed) => {
        if (!confirmed) return

        printReceipt(type)
      })

      return
    }
    const msg = t('fiscal.check_already_printed_msg')
    bootbox.confirmYN(msg, (confirmed) => {
      if (!confirmed) return

      printReceipt(type)
    }, { className: 'confirm-danger' })
  }
})
