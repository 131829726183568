import { Api } from '@/_api/decorators/api/Api'
import { FiltersProfileLogic } from './FiltersProfileLogic'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { Callback } from '@/_api/decorators/callback'
import { IList } from '@/_declarations/IList'
import { filtersProfilesGeneralSubmitAdapter } from '../api/generalSubmitAdapter'
import { filtersProfilesGeneralFetchAdapter } from '../api/generalFetchAdapter'

export class FiltersProfileApi extends FiltersProfileLogic {
  @Callback((response: IList) => ({
    ...response,
    data: response.data.map((item) => new FiltersProfileApi(item)),
  }))
  @Api.notify('listMessage', 'filterProfile', { success: false })
  @Api.list(Routes.list_api_internal_report_profiles_path)
  static list (sortParams) {
    return {
      payload: {
        ...sortParams,
        clinicId: gon.application.current_clinic.id,
      },
    } as ICRUDReturn as Promise<IList>
  }

  @Callback('handleSubmitResponse')
  @Api.notify('submitMessage', 'filterProfile')
  @Api.submit(
    Routes.api_internal_report_profiles_path,
    Routes.api_internal_report_profile_path
  )
  @Api.serverAdapter(filtersProfilesGeneralSubmitAdapter)
  submit () {
    return {
      routeArgs: [this.id],
      payload: this.getAttributes(),
    } as ICRUDReturn as Promise<void>
  }

  @Api.notify('destroyMessage', 'filterProfile')
  @Api.delete(Routes.api_internal_report_profile_path)
  destroy () {
    return {
      routeArgs: [this.id],
    } as ICRUDReturn as Promise<void>
  }

  @Callback('handleFetchResponse')
  @Api.clientAdapter(filtersProfilesGeneralFetchAdapter)
  @Api.notify('fetchMessage', 'filterProfile', { success: false })
  @Api.get(Routes.api_internal_report_profile_path)
  fetch () {
    return {
      routeArgs: [this.id],
    } as ICRUDReturn as Promise<void>
  }

  private handleFetchResponse (data) {
    const { errors } = data
    if (errors) { return errors }

    this._fillProps(data)
  }

  private handleSubmitResponse ({ errors, data }) {
    if (errors) { return errors }

    this._fillProps(data.attributes)
  }
}
