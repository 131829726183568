export const PAGINATION_LIMIT = 20

const createDefaultDental = () => ({
  id: '',
  title: '',
  phone: '',
  companyShortInfo: null,
})

const getValidationMessages = () => ({
  title: [],
  phone: [],
})

export const creators = Object.freeze({
  formDataCreator: createDefaultDental,
  validationMessagesCreator: getValidationMessages,
})
