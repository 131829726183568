<template>
  <div class="protocols-tree-modal">
    <m-modal
      :visible.sync="modalVisibility"
      :dialog-title="t('template_record')"
      modal-title-icon="file"
      type="primary"
    >
      <reusable-lazy-tree
        v-slot="{ node, data}"
        :tree="tree"
        @onNodeClick="onNodeClick"
      >
        <lazy-tree-node-component
          :data="data"
          :node="node"
        />
      </reusable-lazy-tree>
    </m-modal>

    <slot :activator="openModal">
      <m-button
        :text="t('change_template_protokol')"
        icon="reset"
        disabled="TemplateRecord:View"
        @click="modalVisibility = true"
      />
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import ReusableLazyTree from '@/vue_present/Reuse/LazyTree/ReusableLazyTree.vue'
import LazyTree from '@/vue_present/Reuse/LazyTree/store/LazyTree'
import { ProtocolsCategoriesPresenter } from '@/vue_present/Reuse/ProtocolsTree/api/ProtocolsCategoriesPresenter'
import LazyTreeNodeComponent from '@/vue_present/Reuse/LazyTree/components/LazyTreeNodeComponent.vue'
import { extractItemId } from '@/vue_apps/catalogs_root/EntryPacks/const/extractItemId'

export default defineComponent({
  name: 'ReusableProtocolsTreeModal',
  components: { LazyTreeNodeComponent, ReusableLazyTree, MModal, MButton },

  emits: [
    /** @type {ITemplateRecordsTreeItem}*/
    'templateClick',
  ],

  data () {
    return {
      modalVisibility: false,
      tree: new LazyTree(
        new ProtocolsCategoriesPresenter(),
        {
          extraAttributes: [
            'templateData',
            'templateHtml',
            'printClinicHeader',
            'printDoctorSignature',
            'printEntryInfo',
            'printEntryTitle',
            'semdTemplateDocumentDetail',
          ],
        }
      ),
    }
  },

  methods: {
    openModal () {
      this.modalVisibility = true
    },

    /** @param {LazyTreeNode} item */
    onNodeClick (item) {
      if (!item.isLeaf) { return }

      this.modalVisibility = false

      /** @type {ITemplateRecordsTreeItem}*/
      const protocolItem = {
        id: +extractItemId(item.id),
        title: item.title,
        ...item.extraAttributes,
      }

      this.$emit('templateClick', protocolItem)
    },
  },
})
</script>
