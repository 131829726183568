import { typeIcons } from '@/vue_apps/TreatmentPlans/TreatmentPlansList/const/treatmentPlansIcons'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export const TreatmentPlanNodeRenderMixin = {
  components: {
    MCheckbox,
    MInput,
    MIcon,
  },

  methods: {
    /**
     * @param h
     * @param node
     * @param {TreatmentPlansListItemRaw} data
     * @param store
     * @return {JSX.Element}
     */
    _getTreatmentPlanNodeContent (h, { node, data, store }) {
      const title =
        h('div', {
          class: 'treatment-plan-node__title cut',
          directives: [
            {
              name: 'tooltip',
              value: data.title,
            },
          ],
        }, [
          h('div', { class: 'cut' }, data.title),
          h('div', {
            domProps: {
              innerHTML: t('treatmentPlansEntriesTransfer.collapseItemTitleTailTemplate', {
                performer: data.performer?.shortName || '',
                finalSum: data.finalSum || '',
                currencySign: Utils.currencySign(),
              }),
            },
          }),
        ])

      const tpIcon = typeIcons[data.kind]

      return h('div', {
        class: 'custom-tree-node treatment-plan-node cut',
      }, [
        h(MIcon, {
          props: {
            icon: tpIcon.icon,
            color: tpIcon.css,
          },
          style: 'min-width: 20px',
        }),
        title,
        h(MIcon, {
          props: {
            icon: 'link',
            color: 'primary',
          },
          directives: [
            {
              name: 'tooltip',
              value: t('treatmentPlansEntriesTransfer.openPlanTooltip'),
            },
          ],
          on: {
            click: () => window.open(Routes.treatment_plan_path(data.id)),
          },
        }),
      ])
    },
  },
}
