import { CREATE_WORK_TIME } from '@/plugins/schedule/scheduleCommon/const.js'
import { birthdate, required } from '@/lib/validators/rules'
import { MODAL_TYPES } from '@/vue_components/doctor_schedules/consts'
import { createPhoneValidator, requiredValidator } from '@/lib/validators/validators'
import { issueDateValidation } from '@/vue_components/doctor_schedules/doctor_schedules_methods'

export default {
  GET_SCHEDULE_CURRENT_CLINIC_ID () {
    this.loadInitialDataSchedule(this.GET_SCHEDULE_CURRENT_CLINIC_ID)
    this.fetchSchedulesData(this.GET_SCHEDULE_CURRENT_CLINIC_ID)
    Notificator.success(t('clinic_switched'))
  },

  'modal.createWorkTime.visibility' () {
    this.modal[MODAL_TYPES.CREATE_WORK_TIME].action = [CREATE_WORK_TIME]
    this.modal[MODAL_TYPES.CREATE_WORK_TIME].cabinetId = ''
  },

  //region validations
  'modal.createAppointment.form.client.surname' (newValue) {
    const modal = this.modal[MODAL_TYPES.CREATE_APPOINTMENT]
    this.validate('surname', newValue, requiredValidator, modal.validationMessages)
  },

  'modal.createAppointment.form.client.name' (newValue) {
    const {
      validationMessages,
      forcedClientEdit,
    } = this.modal[MODAL_TYPES.CREATE_APPOINTMENT]

    this.removeValidationMessage('surname',
      t('client_with_the_same_fio_phone_and_birthdate_exist'),
      validationMessages
    )

    if (!forcedClientEdit) return

    this.validate('name', newValue, requiredValidator, validationMessages)
  },

  'modal.createAppointment.form.client.secondName' () {
    this.removeValidationMessage('surname',
      t('client_with_the_same_fio_phone_and_birthdate_exist'),
      this.modal[MODAL_TYPES.CREATE_APPOINTMENT].validationMessages
    )
  },

  'modal.createAppointment.form.client.birthdate' (newValue) {
    const modal = this.modal[MODAL_TYPES.CREATE_APPOINTMENT]

    const rules = [birthdate, modal.forcedClientEdit && required].filter(Boolean)
    const messages = {
      required: t('activerecord.required.text'),
      birthdate: t('incorrect_birthdate'),
    }
    this.validateRules('birthdate', newValue, rules, messages, modal.validationMessages)
    this.removeValidationMessage('surname',
      t('client_with_the_same_fio_phone_and_birthdate_exist'),
      modal.validationMessages
    )
  },

  'modal.editAppointment.form.client.issueDate' (newValue) {
    const modal = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]
    issueDateValidation(newValue, modal, this)
  },

  'modal.createAppointment.form.client.issueDate' (newValue) {
    const modal = this.modal[MODAL_TYPES.CREATE_APPOINTMENT]
    issueDateValidation(newValue, modal, this)
  },

  'modal.createAppointment.form.client.phone' (newValue) {
    const {
      validationMessages,
      clientPhoneMaskSettings,
    } = this.modal[MODAL_TYPES.CREATE_APPOINTMENT]
    const validator = createPhoneValidator(clientPhoneMaskSettings.length)
    this.validate('phone', newValue, validator, validationMessages)
    this.removeValidationMessage('surname',
      t('client_with_the_same_fio_phone_and_birthdate_exist'),
      validationMessages
    )
  },

  'modal.createAppointment.form.client.sex' (newValue) {
    const {
      validationMessages,
      forcedClientEdit,
    } = this.modal[MODAL_TYPES.CREATE_APPOINTMENT]

    if (!forcedClientEdit) return

    this.validate('sex_id', newValue, requiredValidator, validationMessages)
  },

  'modal.createAppointment.form.client.snils' () {
    this.modal[MODAL_TYPES.CREATE_APPOINTMENT].validationMessages.snils = []
    this.modal[MODAL_TYPES.CREATE_APPOINTMENT].validationMessages.other.snils = []
  },
  'modal.createAppointment.form.appointment.duration' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.CREATE_APPOINTMENT]
    this.validate('duration', newValue, requiredValidator, validationMessages)
  },

  'modal.createAppointment.form.appointment.appointmentTypeId' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.CREATE_APPOINTMENT]
    this.validate('appointment_type_id', newValue, requiredValidator, validationMessages)
  },

  'modal.createAppointment.form.appointment.attractionSourceId': {
    immediate: true,
    handler (newValue) {
      const { validationMessages } = this.modal[MODAL_TYPES.CREATE_APPOINTMENT]
      this.validate('attraction_source_id', newValue, requiredValidator, validationMessages)
    },
  },

  'modal.createAppointment.form.appointment.status' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.CREATE_APPOINTMENT]
    this.validate('status', newValue, requiredValidator, validationMessages)
  },

  'modal.createAppointment.form.client.patientCardNumber' () {
    const { validationMessages } = this.modal[MODAL_TYPES.CREATE_APPOINTMENT]
    validationMessages.patient_card_number = []
  },

  'modal.editAppointment.form.client.patientCardNumber' () {
    const { validationMessages } = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]
    validationMessages.patient_card_number = []
  },

  'modal.editAppointment.form.client.surname' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]
    this.validate('surname', newValue, requiredValidator, validationMessages)
  },

  'modal.editAppointment.form.client.name' (newValue) {
    const modal = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]
    this.removeValidationMessage('surname',
      t('client_with_the_same_fio_phone_and_birthdate_exist'),
      modal.validationMessages
    )
    if (!modal.forcedClientEdit) return

    this.validate('name', newValue, requiredValidator, modal.validationMessages)
  },

  'modal.editAppointment.form.client.secondName' () {
    this.removeValidationMessage('surname',
      t('client_with_the_same_fio_phone_and_birthdate_exist'),
      this.modal[MODAL_TYPES.EDIT_APPOINTMENT].validationMessages
    )
  },

  'modal.editAppointment.form.client.birthdate' (newValue) {
    const modal = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]
    this.removeValidationMessage('surname',
      t('client_with_the_same_fio_phone_and_birthdate_exist'),
      modal.validationMessages
    )
    if (!modal.forcedClientEdit) return

    const rules = [birthdate, modal.forcedClientEdit && required].filter(Boolean)
    const messages = {
      required: t('activerecord.required.text'),
      birthdate: t('incorrect_birthdate'),
    }
    this.validateRules('birthdate', newValue, rules, messages, modal.validationMessages)
  },

  'modal.editAppointment.form.client.phone' (newValue) {
    const {
      validationMessages,
      clientPhoneMaskSettings,
    } = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]
    const validator = createPhoneValidator(clientPhoneMaskSettings.length)
    this.validate('phone', newValue, validator, validationMessages)
    this.removeValidationMessage('surname',
      t('client_with_the_same_fio_phone_and_birthdate_exist'),
      validationMessages
    )
  },

  'modal.editAppointment.form.client.sex' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]
    this.validate('sex_id', newValue, requiredValidator, validationMessages)
  },

  'modal.editAppointment.form.client.snils' () {
    this.modal[MODAL_TYPES.EDIT_APPOINTMENT].validationMessages.snils = []
    this.modal[MODAL_TYPES.EDIT_APPOINTMENT].validationMessages.other.snils = []
  },

  'modal.editAppointment.form.appointment.duration' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]
    this.validate('duration', newValue, requiredValidator, validationMessages)
  },

  'modal.editAppointment.form.appointment.appointmentTypeId' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]
    this.validate('appointment_type_id', newValue, requiredValidator, validationMessages)
  },

  'modal.editAppointment.form.appointment.attractionSourceId': {
    immediate: true,
    handler (newValue) {
      const { validationMessages } = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]
      this.validate('attraction_source_id', newValue, requiredValidator, validationMessages)
    },
  },

  'modal.editAppointment.form.appointment.status' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]
    this.validate('status', newValue, requiredValidator, validationMessages)
  },

  'modal.legal.form.client.surname' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.LEGAL_REPRESENTATIVE]
    this.validate('surname', newValue, requiredValidator, validationMessages)
  },

  'modal.legal.form.client.birthdate' (newValue) {
    const modal = this.modal[MODAL_TYPES.LEGAL_REPRESENTATIVE]
    const rules = [birthdate, modal.forcedClientEdit && required].filter(Boolean)
    const messages = {
      required: t('activerecord.required.text'),
      birthdate: t('incorrect_birthdate'),
    }
    this.validateRules('birthdate', newValue, rules, messages, modal.validationMessages)
  },

  'modal.legal.form.client.phone' (newValue) {
    const {
      validationMessages,
      clientPhoneMaskSettings,
    } = this.modal[MODAL_TYPES.LEGAL_REPRESENTATIVE]
    const validator = createPhoneValidator(clientPhoneMaskSettings.length)
    this.validate('phone', newValue, validator, validationMessages)
    this.removeValidationMessage('surname',
      t('client_with_the_same_fio_phone_and_birthdate_exist'),
      validationMessages
    )
  },

  'modal.referral.form.surname' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.CREATE_REFERRAL]
    this.validate('surname', newValue, requiredValidator, validationMessages)
  },

  'modal.referral.form.email' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.CREATE_REFERRAL]
    validationMessages.email = []
  },

  'modal.createReminderItem.form.title' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.CREATE_REMINDER]
    this.validate('title', newValue, requiredValidator, validationMessages)
  },

  'modal.createReminderItem.form.date' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.CREATE_REMINDER]
    this.validate('date', newValue, requiredValidator, validationMessages)
  },

  'modal.createReminderItem.form.type' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.CREATE_REMINDER]
    this.validate('reminder_type', newValue, requiredValidator, validationMessages)
  },

  'modal.createReminderItem.form.status' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.CREATE_REMINDER]
    this.validate('status', newValue, requiredValidator, validationMessages)
  },

  'modal.editReminderItem.form.title' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.EDIT_REMINDER]
    this.validate('title', newValue, requiredValidator, validationMessages)
  },

  'modal.editReminderItem.form.date' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.EDIT_REMINDER]
    this.validate('date', newValue, requiredValidator, validationMessages)
  },

  'modal.editReminderItem.form.type' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.EDIT_REMINDER]
    this.validate('reminder_type', newValue, requiredValidator, validationMessages)
  },

  'modal.editReminderItem.form.status' (newValue) {
    const { validationMessages } = this.modal[MODAL_TYPES.EDIT_REMINDER]
    this.validate('status', newValue, requiredValidator, validationMessages)
  },
  //endregion validations

  clientIsEmptyCreateReminder () {
    this.modal.createReminderItem.targetDisabled.client = !this.clientIsEmptyCreateReminder
  },

  'modal.formData.similarClients.selected' () {
    if (this.modal.formData.similarClients.selected) {
      this.setSimilarSelectedToClientForm()

      const currentModal = this.modal.formData.similarClients.currentModal

      if (
        this.modal[currentModal].form.clientId &&
        !this.withoutSummaryInfo.includes(currentModal)
      ) {
        this.fetchClientSummaryInfo()
      }
    }
  },

  GET_APPOINTMENT_FROM_CALL (appointment) {
    this.loadEditAppointmentForm(appointment)
  },

  GET_WAITING_ITEM_FROM_CALL (clientInfo) {
    this.openCreateWaitingItemFormWithClient(clientInfo)
  },
}
