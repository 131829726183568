<template>
  <div ref="configurationContainer">
    <table class="show_info_table configuration_info_table">
      <tbody>
        <tr>
          <td>
            <div>
              {{ T.client_widget.url_address }}
            </div>
          </td>
          <td>
            <input
              v-model="configuration.widget_url"
              type="text"
              spellcheck="false"
              class="form-control"
            >
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.application_title }}
            </div>
            <div class="label-desc">
              {{ T.client_widget.will_update_in_five_min }}
            </div>
          </td>
          <td>
            <input
              v-model="configuration.application_title"
              spellcheck="false"
              class="form-control"
            >
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ t('client_widget.logo') }}
            </div>
          </td>
          <td>
            <widget-logo />
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.color_scheme.title }}
            </div>
          </td>
          <td>
            <div class="client-widget-color-scheme-container">
              <template v-if="colorScheme.isDefaultScheme">
                <input
                  v-model="colorScheme.isDefaultScheme"
                  class="checkbox"
                  type="checkbox"
                >{{ T.client_widget.color_scheme.use_default_colors }}
              </template>
              <div
                v-else
                class="theme-color-picker-wrapper"
              >
                <input
                  v-model="colorScheme.scheme.partsColor"
                  v-tooltip="t('client_widget.color_scheme.details')"
                  class="custom-color-picker"
                  type="color"
                  @change="onElementsColorChange"
                >
                <input
                  v-model="colorScheme.scheme.partsFontColor"
                  v-tooltip="t('client_widget.color_scheme.font')"
                  class="custom-color-picker"
                  type="color"
                >
                <i
                  v-tooltip="t('client_widget.color_scheme.restore_default_colors')"
                  class="fad fa-fw fa-times use-default-scheme"
                  @click="colorScheme.isDefaultScheme = !colorScheme.isDefaultScheme"
                />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.display_results_for }}
            </div>
          </td>
          <td>
            <validation-wrapper :errors="kindsCombinationErrors">
              <items-list
                v-model="configuration.available_entry_type_kinds"
                v-bind="AVAILABLE_ENTRY_TYPE_KINDS_CONFIGURATION"
              />
            </validation-wrapper>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.max_appointments_per_day }}
            </div>
          </td>
          <td>
            <input
              v-model="configuration.max_appointments_per_day"
              spellcheck="false"
              class="form-control"
            >
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.maximum_length_of_appointment_period }}
            </div>
            <div class="label-desc">
              {{ T.client_widget.from_current_date }}
            </div>
          </td>
          <td>
            <input
              v-model="configuration.max_schedule_propagation_from_current_date"
              spellcheck="false"
              class="form-control"
            >
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.entry_protocols_available }}
            </div>
          </td>
          <td>
            <input
              v-model="configuration.entry_protocols_available"
              class="checkbox"
              type="checkbox"
            >
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.entry_attachments_available }}
            </div>
          </td>
          <td>
            <input
              v-model="configuration.entry_attachments_available"
              class="checkbox"
              type="checkbox"
            >
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.enable_google_analytics }}
            </div>
          </td>
          <td>
            <input
              v-model="configuration.enable_google_analytics"
              class="checkbox"
              type="checkbox"
            >
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.google_analytics_tracking_id }}
            </div>
          </td>
          <td>
            <input
              v-model="configuration.google_analytics_tracking_id"
              spellcheck="false"
              class="form-control"
            >
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.enable_yandex_metrika }}
            </div>
          </td>
          <td>
            <input
              v-model="configuration.enable_yandex_metrika"
              class="checkbox"
              type="checkbox"
            >
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.yandex_metrika_tag_number }}
            </div>
          </td>
          <td>
            <input
              v-model="configuration.yandex_metrika_tag_number"
              spellcheck="false"
              class="form-control"
            >
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.end_user_license_agreement }}
            </div>
          </td>
          <td>
            <m-textarea
              v-model="configuration.end_user_license_agreement"
              :autosize="{ minRows: 5, maxRows: 50 }"
            />
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ T.client_widget.privacy_policy }}
            </div>
          </td>
          <td>
            <m-textarea
              v-model="configuration.privacy_policy"
              :autosize="{ minRows: 5, maxRows: 50 }"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <button
      class="btn btn-success btn-sm btn-with-icon"
      @click="submit"
    >
      <span class="btn-with-icon_icon fad fa-save" />
      <span class="btn-with-icon_text">{{ t('save') }}</span>
    </button>
  </div>
</template>

<script>
import ItemsList from '@/vue_components/items_list.vue'
import { getFontColorByBackground, getSecondaryColor } from './user_color_methods.js'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import WidgetLogo from '@/vue_components/client_widget/WidgetLogo/WidgetLogo.vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'

const ANALYSIS = 1
const SERVICE = 4
const COMPLEX = 6
const AVAILABLE_ENTRY_TYPE_KINDS_CONFIGURATION = Object.freeze({
  items: [
    { entryTypeKind: ANALYSIS, title: t('analyzes') },
    { entryTypeKind: SERVICE, title: t('services') },
    { entryTypeKind: COMPLEX, title: t('entry_complexes') },
  ],
  minSelectedItems: 0,
  multiSelect: true,
  idItemField: 'entryTypeKind',
  displayedItemField: 'title',
})
// 6rpx7t
const DEFAULT_COLOR_SCHEME = {
  isDefaultScheme: true,
  scheme: {
    partsColor: '#3FAECA',
    partsSecondaryColor: '#2190ac',
    partsFontColor: '#ffffff',
    partsSecondaryFontColor: '#ebebeb',
  },
}

export default {
  name: 'ClientWidgetConfiguration',

  components: { MTextarea, WidgetLogo, ValidationWrapper, ItemsList },

  props: {
    configuration: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    colorScheme: DEFAULT_COLOR_SCHEME, // 6rpx7t
    AVAILABLE_ENTRY_TYPE_KINDS_CONFIGURATION,
    kindsCombinationErrors: [],
  }),
  mounted () {
    // 6rpx7t
    try {
      const scheme = JSON.parse(this.configuration.color_scheme)
      if (scheme.isDefaultScheme === false && scheme.scheme) {
        this.colorScheme = scheme
      } else {
        // eslint-disable-next-line no-throw-literal
        throw ''
      }
    } catch (err) {
      console.error(T.client_widget.color_scheme.error_load, err)
      this.colorScheme = DEFAULT_COLOR_SCHEME
    }
  },

  methods: {
    // 6rpx7t
    onCustomColorSave () {
      // настройка цветов
      if (!this.colorScheme.scheme.isDefaultScheme) {
        this.colorScheme.scheme.partsSecondaryColor = getSecondaryColor(this.colorScheme.scheme.partsColor)
        this.colorScheme.scheme.partsSecondaryFontColor = getSecondaryColor(this.colorScheme.scheme.partsFontColor)
      } else {
        this.colorScheme = DEFAULT_COLOR_SCHEME
      }

      // применение схемы
      this.configuration.color_scheme = JSON.stringify(this.colorScheme)
    },
    // 6rpx7t - расчёт подходящего цвета шрифта в зависимости от цвета фона
    onElementsColorChange () {
      this.colorScheme.scheme.partsFontColor = getFontColorByBackground(this.colorScheme.scheme.partsColor)
    },

    /**
     * БЛ: если комплекс, то должны быть выбраны анализы или услуги
     * @returns {boolean}
     */
    checkKindsCombination () {
      const kinds = this.configuration.available_entry_type_kinds

      return kinds.includes(COMPLEX) && kinds.length === 1
    },

    submit () {
      this.kindsCombinationErrors = []
      if (this.checkKindsCombination()) {
        const errorText = t('client_widget.invalid_available_entry_type_kinds_combination')
        this.kindsCombinationErrors = [errorText]
        Notificator.error(errorText)

        return
      }

      this.onCustomColorSave()

      $.ajax({
        method: 'PATCH',
        url: Routes.client_widget_configuration_path(this.configuration.id),
        data: {
          client_widget_configuration: this.configuration,
        },
        success () {
          Notificator.success(T.configuration_updated)
        },
        error (err) {
          Notificator.error(T.errors.save_error)
          console.error(err)
        },
      })
    },
  },
}
</script>
