
export const TREATMENT_PLANS_TYPES = Object.freeze({
  MEDICAL: 'medical',
  DENTAL: 'dental',
})

export const TREATMENT_PLANS_STATUSES = Object.freeze({
  DRAFT: 'draft',
  APPROVES: 'approves',
  READY: 'ready',
  IN_PROGRESS: 'in_progress',
  CANCELED: 'canceled',
})
