import { ItemBase } from '@/_api/_classes/ItemBase'
import { ISemdsDocumentDetailsAttributes } from '@/vue_apps/Semds/interfaces/ISemdsDocumentDetailsAttributes'

export class ProtocolModel<DocumentDetails> extends ItemBase {
  protected _permit = [
    'id',
    'title',
    'doctorShortName',

    'templateData',
    'templateHtml',
    'printClinicHeader',
    'printDoctorSignature',
    'printEntryInfo',
    'printEntryTitle',
    'templateRecordId',
    'entries',
    'entryIds',
    'clinic',
    'clientId',
    'client',
    'doctor',
    'onlineAccess',
    'date',
    'pdfGenerated',
    'semdType',
    'semdsDocumentDetailsAttributes',
    'newSemdApproved',
    'newSignatureApproved',
    'selectedLegalRepresentativeId',
  ]

  id = null

  title = null

  doctorShortName = null

  templateData = null

  templateHtml = null

  printClinicHeader = null

  printDoctorSignature = null

  printEntryInfo = null

  printEntryTitle = null

  templateRecordId = null

  entries = []

  entryIds = []

  clientId = null

  client = null

  clinic = null

  doctor = null

  onlineAccess = true

  date = null

  pdfGenerated = false

  semdType: number = null

  semdsDocumentDetailsAttributes: ISemdsDocumentDetailsAttributes<DocumentDetails> = null

  newSemdApproved = false

  newSignatureApproved = false

  selectedLegalRepresentativeId = null

  constructor (props?) {
    super()
    this._fillProps(props)
  }
}
