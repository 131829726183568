import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

const $t = (cellName) => t(cellName, { scope: 'reports.treatmentPlans' })

export const treatmentPlansReportSchema = Object.freeze(
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addMinWidth('1700px')
        .addHeaders({
          numberCell: new MTableHeader($t('numberCell'))
            .addFixedWidth('45px'),
          statusCell: new MTableHeader($t('statusCell'))
            .addTypeIcon('tasks')
            .addTooltip($t('statusCell'))
            .addFixedWidth('45px'),

          doctorCell: new MTableHeader($t('doctorCell'))
            .addTooltip($t('doctorCell'))
            .addFixedWidth('150px'),
          responsibleCell: new MTableHeader($t('responsibleCell'))
            .addTooltip($t('responsibleCell'))
            .addFixedWidth('150px'),

          dateCell: new MTableHeader($t('dateCell'))
            .addFixedWidth('100px'),
          clientCell: new MTableHeader($t('clientCell')),
          titleCell: new MTableHeader($t('titleCell'))
            .addTooltip(true),

          treatmentEntriesAmountCell: new MTableHeader($t('treatmentEntriesAmountCell'))
            .addTooltip(true)
            .addFixedWidth('80px'),
          finalSumCell: new MTableHeader($t('finalSumCell'))
            .addTooltip(true)
            .addFixedWidth('120px'),
          treatmentEntriesCreatedAmountCell: new MTableHeader($t('treatmentEntriesCreatedAmountCell'))
            .addTooltip(true)
            .addFixedWidth('100px'),
          treatmentEntriesCreatedTotalSumCell: new MTableHeader($t('treatmentEntriesCreatedTotalSumCell'))
            .addTooltip(true)
            .addFixedWidth('120px'),
          paidSumCell: new MTableHeader($t('paidSumCell'))
            .addTooltip(true)
            .addFixedWidth('120px'),
          unpaidSumCell: new MTableHeader($t('unpaidSumCell'))
            .addTooltip(true)
            .addFixedWidth('120px'),
          balanceCell: new MTableHeader($t('balanceCell'))
            .addTooltip(true)
            .addFixedWidth('120px'),
          totalCell: new MTableHeader($t('totalCell'))
            .addTooltip(true)
            .addFixedWidth('120px'),
        })
        .addFooter()
        .addSorting()
    )
)
