<template>
  <el-dropdown
    :size="size"
    :trigger="trigger"
    class="small-dropdown"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span class="small-dropdown__button btn-grey-dark">
      <slot name="button-content" />
    </span>
    <el-dropdown-menu
      slot="dropdown"
      class="small-dropdown__menu"
    >
      <slot />
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'SmallDropdown',
  props: {
    size: {
      type: String,
      default: 'small',
    },
    trigger: {
      type: String,
      default: 'click',
      validator (value) {
        return ['click', 'hover'].includes(value)
      },
    },
  },
}
</script>
