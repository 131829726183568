<template>
  <m-modal
    class="protocol-modal"
    width="825px"
    :visible="visible"
    :dialog-title="protocol.title"
    :type="modalType"
    modal-title-icon="file"
    @close="onCloseModal"
  >
    <div class="flex flex-column gap-indent">
      <slot name="templateOptions" />

      <!-- Форма протокола -->
      <protocol-modal-form
        :protocol="protocol"
        :confirm-protocol-opening-callback="confirmProtocolOpeningCallback"
        @setProtocolChanged="setProtocolChanged()"
        @registerValidator="$registerValidator($event)"
      />

      <!-- Контент протокола -->
      <m-panel
        :title="t('protocol')"
        icon="file"
        :type="modalType"
        :use-type-by-route="false"
      >
        <div class="template-html-container mb-indent">
          <div
            class="editor-body"
            style="width: 760px"
          >
            <div
              id="protokol-content"
              ref="editor"
              class="editor-mount"
              readonly
              style="height: 100px"
              v-html="protocol.templateHtml"
            />
          </div>
        </div>

        <m-select
          v-if="legalRepresentatives.length && showLegalsSelector"
          full-width
          :value="protocol.selectedLegalRepresentativeId"
          :label="t('legal_representative')"
          :items="legalRepresentatives"
          :clearable="false"
          :disabled="disabledWhileLoading"
          option-label="shortInfo"
          use-custom-result="simple"
          required
          validator-name="selectedLegalRepresentativeId"
          @change="protocol.setValue('selectedLegalRepresentativeId', $event)"
          @registerValidator="$registerValidator($event)"
        />

        <div class="flex gap-indent-small">
          <m-button
            :text="t('compose_protocol')"
            type="primary"
            icon="edit"
            :disabled="disabledWhileLoading"
            @click="$emit('composeProtocol', $event)"
          />

          <slot name="protocolDraftButton" />
        </div>
      </m-panel>

      <!-- СЭМД -->
      <m-panel
        v-if="protocol.semdType && egiszEnabled"
        :title="t('semds.semd')"
        icon="file"
        :type="modalType"
        :use-type-by-route="false"
      >
        <slot name="semdList" />
      </m-panel>
    </div>

    <template #footer-left>
      <m-button-submit
        :text="t('save_and_close')"
        :disabled="disabledWhileLoading"
        :mode="modalType"
        :use-mode-by-route="false"
        @click="$emit('saveProtocol', true)"
      />
    </template>
  </m-modal>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ProtocolAPI } from '@/vue_apps/Protocols/entities/ProtocolAPI'
import ProtocolModalForm from '@/vue_apps/Protocols/components/ProtocolModal/ProtocolModalForm.vue'
import { pick } from 'lodash'
import htmlHelper from '@/plugins/dynamic_forms/helpers/htmlHelper'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { hasLegalRepresentativeVariables } from '@/vue_apps/Protocols/const/hasLegalRepresentativeVariables'

export default {
  name: 'ProtocolModal',
  components: {
    MSelect,
    MPanel,
    MButtonSubmit,
    ProtocolModalForm,
    MButton,
    MModal,
  },

  props: {
    visible: Boolean,
    protocol: ProtocolAPI,
    isProtocolChanged: Boolean,
    isExternalCreatedProtocol: Boolean,
    egiszEnabled: Boolean,
    modalType: { type: String, default: undefined },
    legalRepresentatives: { type: Array, default: () => [] },
    disabledWhileLoading: Boolean,
    showLegalsSelector: Boolean,
    confirmProtocolOpeningCallback: {
      type: Function,
      async default () {
      },
    },
  },

  emits: [
    'close',
    'openProtocolEditor',
    'composeProtocol',
    'saveProtocol',
    'fillProtocolSemdData',
    'onProtocolEditorClose',
  ],

  created () {
    this.$pubSub.subscribe('onProtocolClose', () => {
      this.openModal()
      this.$emit('onProtocolEditorClose')
    })

    this.$pubSub.subscribe('onProtocolEditFinished', (data) => {
      this.onProtocolEditFinished(data)
    })

    this.$pubSub.subscribe('getPrintingToolsValues:emit', () => {
      this.$pubSub.emit(
        'getPrintingToolsValues:resolve',
        pick(this.protocol, [
          'printEntryTitle',
          'printEntryInfo',
          'printClinicHeader',
          'printDoctorSignature',
        ])
      )
    })
  },

  beforeDestroy () {
    this.$pubSub.unsubscribeAll('onProtocolClose')
    this.$pubSub.unsubscribeAll('onProtocolEditFinished')
  },

  methods: {
    openModal () {
      this.$updateSync('visible', true)
    },

    closeModal () {
      this.$updateSync('visible', false)
    },

    setProtocolChanged (value = true) {
      this.$updateSync('isProtocolChanged', value)
    },

    closeProtocol () {
      this.$emit('close')
    },

    onProtocolEditFinished (data) {
      application.protocolModalOpened = false

      this.setProtocolData(data)

      this.$updateSync(
        'showLegalsSelector',
        hasLegalRepresentativeVariables()
      )

      if (!data.saveAndClose) {
        this.$nextTick(() => {
          this.openModal()
        })

        return
      }

      /**
       * Требуется выбрать легала, для заполнения переменных протокола
       */
      if (this.showLegalsSelector && this.legalRepresentatives.length > 1) {
        this.openModal()
        this.$nextTick(() => {
          Notificator.warning(t('semds.errors.hasLegals'))
        })

        return
      }

      this.$emit('saveProtocol', true)
    },

    setProtocolData ({ printingTools, templateData, templateHtml, storeState }) {
      this.protocol.fillPrintingTools(printingTools)
      this.protocol.setValue('templateData', templateData)
      this.protocol.setValue('templateHtml', templateHtml)
      this.protocol.setValue('storeState', storeState)

      this.$nextTick(() => {
        htmlHelper.clearHtml()
      })
    },

    async onCloseModal () {
      if (!this.isProtocolChanged) {
        return this.closeProtocol()
      }

      const { cancel, close } = await MConfirm.confirm(t('save_protocol_content'), t('confirmation'), {
        distinguishCancelAndClose: true,
        showClose: true,
        customClass: 'with-title',
        confirmButtonText: t('yes'),
        cancelButtonText: t('closeWithoutSaving'),
      })

      if (close) {
        return
      }

      if (cancel) {
        return this.closeProtocol()
      }

      this.$emit('saveProtocol', true)
    },
  },
}
</script>
