/**
 * {@link https://github.com/fengyuanchen/cropperjs?tab=readme-ov-file#viewmode} официальная документация
 * @property {number} noRestrictions - без ограничений
 * @property {number} restrictCropBoxSizeToCanvas - ограничить рамку обрезки так, чтобы она не превышала размер картинки (canvas)
 * @property {number} restrictCanvasSizeToFitWithinContainer - ограничить размер canvas, чтобы он поместился в контейнер.
 * Если пропорции canvas и контейнера различаются, то останется пустое пространство с одной из стороны
 *
 * @property {number} restrictCanvasSizeToFillFitContainer - ограничить размер canvas, чтобы он соответствовал контейнеру.
 * Если пропорции canvas и контейнера различаются, то картинка будет обрезана с одной из стороны
 */
export const viewModes = {
  noRestrictions: 0,
  restrictCropBoxSizeToCanvas: 1,
  restrictCanvasSizeToFitWithinContainer: 2,
  restrictCanvasSizeToFillFitContainer: 3,
}
