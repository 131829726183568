<template>
  <div class="prices-import inline-block">
    <button
      class="btn btn-grey-dark btn-xs btn-with-icon"
      @click="modalOpen"
    >
      <i class="fad fa-file-import btn-with-icon_icon" />
      {{ t('prices_import') }}
    </button>

    <modal
      :modal-visibility="modalVisibility"
      modal-size="md"
      @close="modalClose"
    >
      <template #header>
        <i class="fad fa-file-import" />
        {{ t('prices_import') }}
      </template>

      <template #body>
        <div class="flex mb-15">
          <i class="fad fa-exclamation-triangle warning font-size-72 mr-15" />
          <div>
            <div class="prompt-notice">
              1) {{ t('prices_import_prompt_1') }}
            </div>
            <div class="prompt-notice">
              2) {{ t('prices_import_prompt_2') }}
            </div>
          </div>
        </div>

        <files-loader
          v-if="modalVisibility"
          ref="fileLoader"
          :disabled="successValidation"
          :action="$routes.import_validations_update_prices_path()"
          :ajax-loader="ajaxFileLoader"
          drag
          @setUploadFiles="setUploadFiles"
        />
        <span
          v-tooltip="file.file && t('cancel')"
          class="pointer"
          @click="resetFileData"
        >
          <span class="prompt-notice cut">
            {{ fileName }}
            <i
              v-if="file.file"
              class="fas fa-times danger"
            />
          </span>
        </span>
      </template>

      <template #footer-left>
        <button
          v-if="!successValidation"
          v-loading="loading"
          type="button"
          class="btn btn-sm btn-with-icon btn-primary"
          @click="$refs.fileLoader.submitUpload()"
        >
          <i class="fad fa-check btn-with-icon_icon" />
          {{ t('check_file') }}
        </button>
        <button
          v-else
          v-loading="loading"
          type="button"
          class="btn btn-sm btn-with-icon btn-success"
          @click="importPrices"
        >
          <i class="fad fa-file-import btn-with-icon_icon" />
          {{ t('prices_import') }}
        </button>
      </template>

      <template #footer-right>
        <button
          v-if="successValidation"
          type="button"
          class="btn-with-icon btn btn-sm btn-primary"
          @click="resetFileData()"
        >
          <i class="fad fa-undo btn-with-icon_icon" />
          {{ t('cancel') }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/vue_components/modal'
import FilesLoader from '@/vue_components/common/FilesLoader/FilesLoader'
import { entryTypesEndpoint } from '@/api_entities/entry_types/entry_types_endpoint'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { getFileNameExtension, isCSV } from '@/vue_components/EntryTypes/PricesImport/composables/helpers'

export default {
  name: 'PricesImport',
  components: { FilesLoader, Modal },

  mixins: [SpinnerHolder],

  data () {
    return {
      modalVisibility: false,
      ajaxFileLoader: this.getAjaxFileLoader(),
      file: {},
      successValidation: false,
    }
  },

  computed: {
    fileName () {
      return (this.file && this.file.file && this.file.file.name) ||
          t('files_loader.not_selected_one')
    },
  },

  methods: {
    getAjaxFileLoader () {
      return (file) => {
        this.file = file
        const fileName = file.file.name.substr(0, file.file.name.lastIndexOf('.'))

        const promise = entryTypesEndpoint.pricesValidate(file)
          .then(() => { this.successValidation = true })
          .catch((err) => {
            if (err && err.status === 422) {
              Notificator.error(t('files_loader.errors_found'), t('files_loader.import'))
              Utils.downloadExportFile(err.responseText, fileName)

              return
            }

            Utils.reportError('PricesImport:getAjaxFileLoader()')(err)
          })

        this.withSpinner(promise)
      }
    },

    modalOpen () {
      this.modalVisibility = true
    },

    modalClose () {
      this.modalVisibility = false
      this.resetFileData()
    },

    resetFileData () {
      this.file = {}
      this.$refs.fileLoader.clearFiles()
      this.successValidation = false
      this.loading = false
    },

    setUploadFiles (files) {
      if (!files.length) { return }

      const file = files[0].raw
      if (!isCSV(getFileNameExtension(file.name))) {
        Notificator.error(t('files_loader.csv_only'), t('files_loader.import'))
        this.resetFileData()

        return
      }

      this.setFile(file)
    },

    setFile (file) {
      this.file = { file }
    },

    importPrices () {
      const promise = entryTypesEndpoint.pricesImport(this.file)
        .then(this.showAlert)
        .catch(Utils.reportError('PricesImport:importPrices()'))

      this.withSpinner(promise)
    },

    showAlert (responseData) {
      const count = responseData
        ? responseData.count
        : '-'

      this.$alert(
        `${t('success_import')} ${count}. ${t('page_will_be_reloaded')}`,
        t('files_loader.import'),
        {
          callback: () => {
            this.modalClose()
            location.reload()
          },
        })
    },
  },
}
</script>
