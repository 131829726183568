import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { PAYMENT_KIND, PAYMENT_KINDS_WITH_REFUND } from '@/vue_apps/ReportsModule/SalaryReports/const/const'

export class SalaryByPaymentsDetailedReportItem {
  /** @param {TSalaryByPaymentsDetailedEntry} entry */
  constructor (entry) {
    this.id = `${entry.id}:${Utils.newGUID()}`
    this.title = entry.title
    this.date = entry.date
    this.sum = entry.sum
    this.finalSum = entry.finalSum
    this.amount = entry.amount
    this.order = entry.order || {}
    this.lastPayment = entry.lastPayment || {}
    this.paymentEntry = entry.paymentEntry || {}
    this.salaryEntry = entry.salaryEntry || {}
  }

  get tableTitle () {
    return new MTableCell(this.title)
      .addTooltip(this.title)
      .addCut()
  }

  get tableDate () {
    return new MTableCell(vueFilters.date(this.date))
  }

  get tableOrder () {
    return this.order.id
      ? new MTableCell(this.order.id)
        .addTypeUrl(Routes.order_path(this.order.id))
      : new MTableCell()
  }

  get tableOrderDate () {
    return new MTableCell(vueFilters.date(this.order.date))
  }

  get tableEntrySum () {
    return new MTableCell(vueFilters.price(this.sum))
  }

  get tableEntryFinalSum () {
    return new MTableCell(vueFilters.price(this.finalSum))
  }

  get tableAmountInEntry () {
    return new MTableCell(this.amount)
  }

  get tableLastPayment () {
    return this.lastPayment.id
      ? new MTableCell(this.lastPayment.id)
        .addTypeUrl(Routes.payment_path(this.lastPayment.id))
      : new MTableCell()
  }

  get tableLastPaymentDate () {
    return new MTableCell(vueFilters.date(this.lastPayment.date))
  }

  get tableLastPaymentTotalPaid () {
    let totalPaid = ''
    PAYMENT_KINDS_WITH_REFUND.includes(this.lastPayment.kind)
      ? totalPaid = '-' + vueFilters.price(this.lastPayment.totalPaid)
      : totalPaid = vueFilters.price(this.lastPayment.totalPaid)

    return new MTableCell(totalPaid)
  }

  get tableLastPaymentKind () {
    if (!this.lastPayment) { return new MTableCell() }
    const paymentKind = PAYMENT_KIND[this.lastPayment.kind]

    return new MTableCell()
      .addTypeIcon(paymentKind.icon)
      .addClasses(paymentKind.class)
      .addTooltip(paymentKind.tooltip)
  }

  get tableLastPaymentCashier () {
    const fullName = vueFilters.fullName(this.lastPayment.cashier)

    return this.lastPayment.cashier
      ? new MTableCell(fullName)
        .addTypeUrl(Routes.user_path(this.lastPayment.cashier.id))
        .addTooltip(fullName)
      : new MTableCell()
  }

  get tableSum () {
    return new MTableCell(vueFilters.price(this.paymentEntry?.paidSum))
  }

  get tableRateType () {
    return new MTableCell(`${vueFilters.price(this.salaryEntry.rate)} ${vueFilters.rateType(this.salaryEntry.rateType)}`)
  }

  get tableSalary () {
    return new MTableCell(vueFilters.price(this.salaryEntry.sum))
  }
}
