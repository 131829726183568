<template>
  <catalog-wrapper
    model="ReceptionAppointmentType"
    custom-table-class="appointment-types-table"
    custom-form-class="appointment-types-form"
    catalog-icon="fad fa-fw fa-calendar-alt"
    :table-title="t('reception.appointment_types')"
    :form-create-title="t('reception.creating_appointment_type')"
    :form-edit-title="t('reception.appointment_type')"
    :add-item-text="t('add_appointment_type')"
    :items="appointmentTypes"
    :loading="loadingList"
    :selected-mode="selectedMode"
    :is-show-form="isShowForm"
    :is-pagination-enabled="false"
    :can-delete="!isSystemName"

    @ask-create="openCreateForm"
    @close="hideForm"
    @submit="handleSubmit"
    @ask-delete="openDeleteModal"
  >
    <template #table>
      <appointment-types-table
        :appointment-types="appointmentTypes"
        :selected-list-item="selectedListItem"
        @ask-edit="openEditForm"
      />
    </template>

    <template #form>
      <appointment-types-form
        :is-system-name="isSystemName"
        :title.sync="formData.title"
        :is-default.sync="formData.default"
        :color.sync="formData.color"
        :validation-errors="validationMessages"
      />
    </template>

    <template #extra>
      <confirmation-modal
        v-if="modal.confirmation.visibility"
        v-bind="confirmationProps"
        modal-size="md"
        @yes="confirmationYesHandler"
        @no="confirmationNoHandler"
      />

      <appointment-type-delete-modal
        v-if="showDeleteModal"
        :visible.sync="showDeleteModal"
        :appointment-type="formData"
        :appointment-types="appointmentTypes"
        @delete="handleDelete"
      />
    </template>
  </catalog-wrapper>
</template>

<script>
import { isEqual } from 'lodash'
import CatalogWrapper from '../catalog_wrapper/catalog_wrapper_deprecated.vue'
import AppointmentTypesTable from './components/appointment_types_table'
import AppointmentTypesForm from './components/appointment_types_form'
import AppointmentTypeDeleteModal
  from '@/vue_components/catalogs/appointment_types/components/appointment_type_delete_modal'
import { CatalogHolder } from '@/vue_components/mixins/catalogs/catalogHolder'
import { requiredValidator } from '@/lib/validators/validators'
import { ConfirmationHolder } from '@/vue_components/mixins/modals/confirmation_holder'
import { appointmentTypesEndpoint } from '@/api_entities/catalogs/appointment_types/appointment_types_endpoint'
import { trimValues } from '../../helpers'
import { findPreExistingTitle } from '../helpers'
import { MODES, NOTIFICATOR_LIFETIME } from '../const'
import { creators } from './const'

export default {
  name: 'AppointmentTypesCatalog',
  components: {
    CatalogWrapper,
    AppointmentTypeDeleteModal,
    AppointmentTypesTable,
    AppointmentTypesForm,
  },

  mixins: [CatalogHolder, ConfirmationHolder],

  data () {
    return {
      appointmentTypes: [],
      showDeleteModal: false,
    }
  },

  computed: {
    isSystemName () {
      return !!this.formData.system_name
    },
  },

  watch: {
    'formData.title' (newValue) {
      this.validate('title', newValue, requiredValidator)
    },
  },

  created () {
    this.initCatalogHolder(creators)
    this.getAppointmentTypes()
  },

  methods: {
    getAppointmentTypes () {
      this.loadingList = true

      appointmentTypesEndpoint.getAll()
        .then((data) => {
          this.appointmentTypes = data
        })
        .catch((err) => Utils.reportError(
          'getAppointmentTypes error',
          t('reception.errors.get_list')
        )(err))
        .finally(() => {
          this.loadingList = false
        })
    },

    createAppointmentType (data) {
      if (findPreExistingTitle(this.appointmentTypes, data)) {
        Notificator.warning(
          t(
            'reception.pre_existing_item',
            { item: t('reception.catalog_items_names.type') }
          ),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      appointmentTypesEndpoint.create(data)
        .then(() => {
          this.getAppointmentTypes()
          this.clearForm()

          Notificator.success(
            t('record_successfully_created'),
            '',
            NOTIFICATOR_LIFETIME
          )
        })
        .catch((err) => {
          if (err instanceof Error) {
            Utils.reportError(
              'createAppointmentType error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.create') }
              )
            )(err)
          } else {
            this.setValidationMessages(this.validationMessages, err.responseJSON)
          }
        })
    },

    updateAppointmentType (data) {
      if (findPreExistingTitle(this.appointmentTypes, data)) {
        Notificator.warning(
          t(
            'reception.pre_existing_item',
            { item: t('reception.catalog_items_names.type') }
          ),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      if (isEqual(this.previousFormData, data)) {
        Notificator.warning(
          t('reception.dont_find_update'),
          '',
          NOTIFICATOR_LIFETIME
        )

        return
      }

      appointmentTypesEndpoint.update(data)
        .then(() => {
          this.getAppointmentTypes()
          this.previousFormData = { ...data }

          Notificator.info(
            t('changes_updated'),
            '',
            NOTIFICATOR_LIFETIME
          )
        })
        .catch((err) => {
          if (err instanceof Error) {
            Utils.reportError(
              'updateAppointmentType error',
              t(
                'reception.errors.request_error',
                { action: t('reception.actions.update') }
              )
            )(err)
          } else {
            this.setValidationMessages(this.validationMessages, err.responseJSON)
          }
        })
    },

    handleSubmit () {
      trimValues(this.formData)

      if (this.hasErrors()) return

      if (
        this.formData.default &&
        this.findDefaultType(this.formData.id) &&
        !this.modal.confirmation.visibility
      ) {
        this.showUpdateDefaultChangeModal()

        return
      }

      if (this.selectedMode === MODES.NEW) {
        this.createAppointmentType(this.formData)
      } else {
        this.updateAppointmentType(this.formData)
      }
    },

    async handleDelete ({ appointmentTypeId, replacementTypeId }) {
      this.loadingList = true

      try {
        await appointmentTypesEndpoint.destroy(appointmentTypeId, replacementTypeId)

        const index = this.appointmentTypes.findIndex((type) => type.id === appointmentTypeId)
        if (index > 0) {
          this.appointmentTypes.splice(index, 1)
          this.hideForm()
          Notificator.success(t('record_successfully_deleted'))
        }
      } catch (error) {
        Utils.reportError('appointment_types:handleDestroy')(error)
      } finally {
        this.loadingList = false
      }
    },

    findDefaultType (id) {
      return this.appointmentTypes.find((newType) => newType.default && newType.id !== id)
    },

    showUpdateDefaultChangeModal () {
      this.showConfirmation(
        t('reception.change_default_appointment_type'),
        this.handleSubmit,
        this.hideConfirmation,
        t('reception.accept_change_default_type')
      )
    },

    openDeleteModal () {
      this.showDeleteModal = true
    },
  },
}
</script>
