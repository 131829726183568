import Vue from 'vue/dist/vue.esm'
import OrderGoodsList from '@/vue_components/stocks/order_goods/order_goods_list.vue'
import fieldValidation from '../forms/field_validation_handler.js'

$document.on('rez/stocks/index', function () {
  // let modalShow = $('#modal_stocks_edit')
  const jQM = {}
  const limit = -(1 << 32 - 1)
  const MAX_STOCK_AMOUNT = 100000000
  const $amountFields = $('.stock-amount')

  jQM.storeID = $('#store_id')
  jQM.formSubmit = $('#submit_stock')
  jQM.goodsList = $('#stocks_goods')
  jQM.modalForm = $('#modal_stocks_edit')
  jQM.stockForm = $('#stocks_edit_form')
  jQM.amount = $('#good_details_amount')
  jQM.criticalAmount = $('#stock_critical_amount')
  jQM.desiredAmount = $('#stock_desired_amount')

  const Stocks = FormFactory.build('stock')

  loadCategoriesx()

  jQM.storeID.select2Buttons()

  jQM.storeID.on('change', function () {
    redrawList()
  })

  function loadCategoriesx () {
    jQM.goodsList.categoriesx({
      url: '/categories/entry_type_goods',
      category_type: 7,
      columns: [
        {
          render: renderAmount,
          width: '90ch',
        }, {
          render: criticalAmount,
          width: '180ch',
        }, {
          render: desiredAmount,
          width: '180ch',
        },
      ],
      columnsWidth: {
        icon: '30ch',
      },
      responsive: {
        offset: 165,
      },
      onClick: onGoodClick,
    })
  }

  function onGoodClick (item) {
    const storeId = parseInt(jQM.storeID.val() || 0)

    // get stock for current item in current store
    const stock = item.stocks.find((stock) => +stock.store_id === +storeId)

    if (!stock) {
      Services
        .ui
        .notificator()
        .warning(T.no_reciption_for_current_store, T.warning)

      return
    }

    jQM.currentStock = stock
    Stocks.editItem(stock)
  }

  function redrawList () {
    jQM.goodsList.categoriesx('forceReload')
  }

  function renderAmount (item, td) {
    let storeId = jQM.storeID.val()
    storeId = parseInt(storeId, 10)
    let stock = 0

    for (let i = 0, l = item.stocks.length; i < l; i++) {
      if (item.stocks[i].store_id === storeId) {
        stock = item.stocks[i].amount
        break
      }
    }
    const element = td.text(stock).addClass('tooltip-bottom').attr('data-title', t('amount'))

    return element
  }

  function criticalAmount (item, td) {
    let storeId = jQM.storeID.val()
    storeId = parseInt(storeId, 10)
    let stock = 0

    for (let i = 0, l = item.stocks.length; i < l; i++) {
      if (item.stocks[i].store_id === storeId) {
        stock = item.stocks[i].critical_amount || 0
        break
      }
    }
    const element = td.text(stock).addClass('tooltip-bottom').attr('data-title', t('critical_amount'))

    return element
  }

  function desiredAmount (item, td) {
    let storeId = jQM.storeID.val()
    storeId = parseInt(storeId, 10)
    let stock = 0

    for (let i = 0, l = item.stocks.length; i < l; i++) {
      if (item.stocks[i].store_id === storeId) {
        stock = item.stocks[i].desired_amount || 0
        break
      }
    }
    const element = td.text(stock).addClass('tooltip-bottom').attr('data-title', t('desired_amount'))

    return element
  }

  function handleErrors (response) {
    if (!response.ok) throw Error(response.status)

    return response.text()
  }

  function updateData () {
    if (!jQM.currentStock) return

    const stockData = {
      ...jQM.currentStock,
      critical_amount: Number(jQM.criticalAmount.val()),
      desired_amount: Number(jQM.desiredAmount.val()),
    }

    $.ajax({
      method: 'PATCH',
      url: Routes.stock_path(jQM.currentStock.id),
      data: { stock: stockData },
      success () {
        redrawList()
        PubSub.emit('page.form.stock.modal.close')
      },
      error (err) { _errorHandler(err) },
      complete () { PubSub.emit('page.form.stock.modal.unblockButtons') },
    })
  }

  function _errorHandler (err) {
    if (err.status === 422) {
      Object.keys(err.responseJSON).forEach((field) => {
        fieldValidation.showErrorMessages(
          $(`#stock_${field}`).parent(),
          err.responseJSON[field]
        )
      })
    } else {
      Services
        .ui
        .notificator()
        .error(T.abstract_error_message, T.error, 0)
      console.error(err)
    }
  }

  jQM.stockForm.on('submit', (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (Number(jQM.criticalAmount.val()) > Number(jQM.desiredAmount.val())) {
      bootbox.confirmYN(T.critical_more_than_desire_warn, (res) => {
        if (res) updateData()
        else PubSub.emit('page.form.stock.modal.unblockButtons')
      })
    } else {
      updateData()
    }
  })

  $amountFields.on('keydown', function () {
    if (Number(this.value) < MAX_STOCK_AMOUNT && $(this).parent().hasClass('has-error')) {
      fieldValidation.hideErrorMessages($(this).parent())
    }
  })

  $('#stocks-export').on('click', function () {
    const storeId = $('#store_id').val()

    const data = {
      csvComma: gon.application.csv_character,
      headerRow: {
        entryTypeId: 'id',
        entryTypeNumber: T.code,
        entryTypeTitle: T.title,
        amount: T.attributes.amount,
      },
      storeId,
      limit,
      offset: 0,
    }

    fetch(Routes.to_xls_stocks_path(), {
      method: 'POST',
      headers: {
        Accept: 'text/csv',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      credentials: 'same-origin',
      body: JSON.stringify(data),
    })
      .then(handleErrors)
      .then((result) => {
        Utils.downloadExportFile(result, T.export_stock)
      })
      .catch((error) => { _errorHandler(error) })
  })

  const search = $('#search_stocks')
  const searchUrl = Routes.search_stocks_path()

  search.smartSearch({
    url: searchUrl,
    method: 'GET',
    queryParams: {
      store_id () {
        return $('#store_id').val()
      },
    },
    itemRender (html, item) {
      const refRow = $('<span>').text(item.title)
      const itemCategory = item.category_title || t('stocks.without_category')
      refRow.append($('<span class="prompt-notice">').text(`, ${itemCategory}`))

      return html.prepend(refRow)
    },
    onSelect (item) {
      onGoodClick(item)
      search.val('')
    },
  })
})

// stocks/order_goods vue component add
$document.on('rez/stocks/order_goods', () => {
  return new Vue({
    el: '#order_goods_list',
    components: {
      OrderGoodsList,
    },
    template: '<order-goods-list/>',
  })
})
