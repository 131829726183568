
export const header = {
  number: { text: '№', sortable: 'entryId' },
  order: { text: t('order'), sortable: 'orderId' },
  client: { text: t('client'), sortable: 'clientSurname' },
  age: { text: t('age'), sortable: 'clientBirthDate' },
  emkNumber: { text: t('emkNumber'), sortable: 'emkNumber' },
  date: { text: t('custom_date'), sortable: 'entryDate' },
  doctor: { text: t('executor') },
  title: { text: t('title'), sortable: 'entryTitle' },
  amount: { text: t('amount_short'), sortable: 'entryAmount' },
  sum: {
    text: t('sum'),
    footnote: t('reports.to_pay_without_discount'),
    sortable: 'entrySum',
  },
  toPay: {
    text: t('to_pay'),
    footnote: t('reports.to_pay_with_discount'),
    sortable: 'entryFinalSum',
  },
  paid: { text: t('paided'), sortable: 'paymentEntrySum' },
  discountValue: { text: t('discount'), sortable: 'discountValue' },
  receipt: { text: t('receipt'), sortable: 'receipt' },
  documentNumber: { text: t('exports_page.activity.reports.documentNumber'), sortable: 'documentNumber' },
  documentCreatedAt: { text: t('exports_page.activity.reports.documentCreatedAt'), sortable: 'documentCreatedAt' },
}

export const BY_DMS_TYPE = 2
