import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class DoctorSalaryByPaymentsDetailedReportTotal {
  /** @param {TDoctorSalaryByPaymentsDetailedTotal} total */
  constructor (total) {
    this.sum = total.sum
    this.salarySum = total.salarySum
  }

  get tableTitle () {
    return new MTableCell(t('reports.total'))
  }

  get tableDate () {
    return new MTableCell()
  }

  get tableOrder () {
    return new MTableCell()
  }

  get tableOrderDate () {
    return new MTableCell()
  }

  get tableClient () {
    return new MTableCell()
  }

  get tableOrderSum () {
    return new MTableCell(vueFilters.currency(this.sum))
  }

  get tableOrderFinalSum () {
    return new MTableCell()
  }

  get tableAmountInOrder () {
    return new MTableCell()
  }

  get tableLastPayment () {
    return new MTableCell()
  }

  get tableLastPaymentDate () {
    return new MTableCell()
  }

  get tableLastPaymentTotalPaid () {
    return new MTableCell()
  }

  get tableLastPaymentKind () {
    return new MTableCell()
  }

  get tableLastPaymentCashier () {
    return new MTableCell()
  }

  get tableSum () {
    return new MTableCell(vueFilters.currency(this.sum))
  }

  get tableRateType () {
    return new MTableCell()
  }

  get tableSalary () {
    return new MTableCell(vueFilters.currency(this.salarySum))
  }
}
