import { ReportParamFactory, ReportParamStrict } from '../shared'
import { referralsReportFiltersSelectors as selectors } from './filtersSelectors'
import { IReferralsReportFiltersGet } from './interfaces'

export const _getReferralsReportFilters = (): IReferralsReportFiltersGet => ({
  clinic: ReportParamFactory.getSelectParam(selectors.clinic) as ReportParamStrict,
  period: ReportParamFactory.getRawParam(selectors.period),
  entryWithOrder: ReportParamFactory.getRawParam(selectors.entryWithOrder),
  includingDiscounts: ReportParamFactory.getRawParam(selectors.includingDiscounts),
  reportTypeFilter: ReportParamFactory.getRawParam(selectors.reportTypeFilter),
  groupByEntry: ReportParamFactory.getCheckboxParam(selectors.groupByEntry) as ReportParamStrict,
  entry: ReportParamFactory.getSelect2Params(selectors.entry) as ReportParamStrict,
  category: ReportParamFactory.getSelect2Params(selectors.category) as ReportParamStrict,
  medicalUser: ReportParamFactory.getSelect2Params(selectors.medicalUser) as ReportParamStrict,
  city: ReportParamFactory.getSelectParam(selectors.city) as ReportParamStrict,
  referral: ReportParamFactory.getSelect2Params(selectors.referral) as ReportParamStrict,
})
