import { DEFAULT_CLIENTS_COUNT } from '@/vue_apps/ReportsModule/LoyalReport/const/filters/getClientsCountItems'
import { DateHelpers } from '@/helpers/DateHelpers'

export const getDefaultFilters = () => ({
  clinic: null,
  period: DateHelpers.getMonthPeriod(),
  entryTypes: [],
  categoryId: null,
  finalSum: null,
  clientsCount: DEFAULT_CLIENTS_COUNT,
})
