import { TreatmentPlansListItem } from '@/vue_apps/TreatmentPlans/TreatmentPlansList/store/TreatmentPlansListItem'

const extractTPType = (type) => {
  return Utils.branch.isCustom
    ? type?.id
    : undefined
}

export const treatmentPlanListAdapter = {
  toServer (data = {}) {
    return {
      ...data,
      type: extractTPType(data.type),
      status: data.status?.id,
    }
  },

  toClient (response) {
    response.data = (response.data || []).map((tp) => new TreatmentPlansListItem(tp))

    return response
  },
}
