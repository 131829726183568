import { ReportParamFactory } from '@/specific/reports/shared'

export default function (source) {
  return {
    clinicIds: ReportParamFactory.buildArrayParam(source.clinic),
    entryKinds: ReportParamFactory.buildArrayParam(source.entryKind),
    doctorIds: ReportParamFactory.buildArrayParam(source.doctor),
    administratorIds: ReportParamFactory.buildArrayParam(source.administrator),
    entryTypeIds: ReportParamFactory.buildArrayParam(source.entryTypes),
    entryTypeCategoryIds: ReportParamFactory.buildArrayParam(source.entryTypeCategories),
    clientIds: ReportParamFactory.buildArrayParam(source.client),
    clientGroupIds: ReportParamFactory.buildArrayParam(source.clientGroups),
    clientSex: source.clientSex.id,
    entryWithDiscount: source.entryWithDiscount.id,
    entryWithOrder: source.entryWithOrder.id,
    clientDeletedAt: source.clientStatus.id,
    doctorSpecialtiesIds: ReportParamFactory.buildArrayParam(source.doctorSpecialtiesIds),
  }
}
