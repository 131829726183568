import { REPORT_EVENTS } from '@/vue_apps/ReportsModule/_filters/const/events'
import { createFiltersProfilesAppForLegacy } from '@/vue_apps/FiltersProfilesApp/index.js'
import { FILTERS_PROFILES_TYPES } from '@/vue_apps/FiltersProfilesApp/const/consts'
import { administratorsProductivityReportFiltersAccessors } from './reports/administratorsProductivity/filtersAccessors'
import { administratorsProductivityReportFiltersSelectors } from './reports/administratorsProductivity/filtersSelectors'
import { doctorsProductivityReportFiltersAccessors } from './reports/doctorsProductivity/filtersAccessors'
import { doctorsProductivityReportFiltersSelectors } from './reports/doctorsProductivity/filtersSelectors'
import { saveAdministratorsProductivityReportInitialFilters } from './reports/administratorsProductivity/initialFilters'
import { saveDoctorsProductivityReportInitialFilters } from './reports/doctorsProductivity/initialFilters'
import { removeFilterValidationWrapper, showFiltersErrors } from '@/vue_apps/FiltersProfilesApp/helpers/legacyErrorsRenderingHelpers'

$document.on('rez/reports', () => {
  $('#report_payment_kinds').select2({
    width: '100%',
  })

  Utils.clickableTr({ saveScroll: '#report_list_body' })

  $('.export-to-xls-btn').on('click', function (e) {
    const reportTable = document.getElementById('report_table')

    if (reportTable) {
      return $(this).hasClass('xls_export') ? BetterExport.excel(this, reportTable) : BetterExport.csv(this, reportTable)
    }

    e.preventDefault()
    Services
      .ui
      .notificator()
      .error(T.wrong_data_for_export, T.error, 0)
  })

  $('.export').on('click', () => { PubSub.emit(REPORT_EVENTS.EXPORT) })
})

$document.on('rez/reports/main_page', () => {
  Report.init({
    hideFloatingPanel: true,
    showList: false,
    showFilters: true,
  })

  $('#filters_switch_btn')
    .unbind()
    .addClass('disabled')
})

$document.on('rez/reports/index', () => {
  Report.init({
    hideFloatingPanel: true,
    showList: false,
    showFilters: true,
    scrollPosition: 0,
  })

  Report.generate({
    url: '/reports/create_index_preview',
    reportName: 'createIndexPreview',
    disableTooltipThead: true,
  })

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_index_preview',
      reportName: 'createIndexPreview',
      disableTooltipThead: true,
    })
  })

  _resizeTabelContainer()
  $(window).resize(() => _resizeTabelContainer())

  function _resizeTabelContainer () {
    const tableContainerHeight = $('.main-container').height() - $('.report-subtitle').height() - $('.inner_tabpanel').height() - 120
    if ($('.chart-container').length || $('.report-chart-container').length) {
    } else {
      $('.report_table:not(.initial_size) tbody').height(tableContainerHeight)
    }
  }
})

$document.on('rez/reports/day_report', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_day_report',
      reportName: 'createDayReport',
    })
  })
})

$document.on('rez/reports/refuse_results', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_refuse_results',
      reportName: 'createRefuseResults',
    })
  })
})

$document.on('rez/reports/call_effect', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_call_effect',
      reportName: 'createCallEffect',
    })
  })
})

$document.on('rez/reports/income', function () {
  Report.init({ start: moment().startOf('year'), end: moment().endOf('year') })

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_income',
      reportName: 'createIncome',
    })
  })
})

$document.on('rez/reports/attraction_source', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_sources',
      reportName: 'createSources',
    })
  })
})

$document.on('rez/reports/appointment_dynamic', function () {
  Report.init({ start: moment().subtract(1, 'years').startOf('month'), end: moment().endOf('month') })

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_appointment_dynamic',
      reportName: 'createAppointmentDynamic',
    })
  })
})

$document.on('rez/reports/new_clients_dynamic', function () {
  Report.init({ start: moment().startOf('year'), end: moment().endOf('year') })

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_new_clients_dynamic',
      reportName: 'createNewClientsDynamic',
    })
  })
})

$document.on('rez/reports/repeated_visits', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_repeated_visits',
      reportName: 'createRepeatedVisits',
    })
  })
})

$document.on('rez/reports/clients_debt', function () {
  Report.init()

  const $period = $('#period')
  const $debtType = $('#report_debt_type')
  const $byCompany = $('#report_by_company')

  const debtTypes = $debtType.find('option').toArray().map((option) => ({
    value: option.value,
    text: option.textContent,
  }))

  const setDebtTypes = ($select, options) => {
    $select.html(options.map((option) => `<option value="${option.value}">${option.text}</option>`).join())
    $select.trigger('change')
  }

  $debtType.on('change', function () {
    if ($(this).val() === 'by_unpaid_credit') {
      Report.hideFilter($period)
    } else {
      Report.showFilter($period)
    }
  })

  $byCompany.on('change', (e) => {
    const $company = $('#report_company_id')
    if (!e.target.checked) {
      // show all report types
      setDebtTypes($debtType, debtTypes)
      // report for companies is built for all companies only
      Report.hideFilter($company)
    } else {
      // report for companies cannot have entries without bill
      setDebtTypes($debtType, debtTypes.filter((type) => type.value !== 'by_entries_without_bill'))
      Report.showFilter($company)
    }
  })

  $byCompany.trigger('change')
  $debtType.trigger('change')

  const $report = $('#report_clients_debt')
  const $modal = $('#bills_list_modal')
  const $modalBody = $('.bills_list_modal_body')

  /**
   * @typedef {{route: string, templateSelector: string}} DebtModalConfig
   * @type {Record<string, DebtModalConfig>}
   */
  const modals = {
    entries: {
      route: 'client_entries_without_bill_client_path',
      templateSelector: '#entries_without_bill',
    },
    bills: {
      route: 'client_bills_without_payments_client_path',
      templateSelector: '#bills_without_payments',
    },
    companyBills: {
      route: 'company_bills_without_payments_company_path',
      templateSelector: '#bills_without_payments',
    },
  }

  $report.on('click', '.js-show-debts', amountClickListener)

  function amountClickListener (e) {
    e.preventDefault()

    $modal.modal('show')
    $modalBody.empty().loadSpin('start')

    const id = $(this).data('id')
    const modalType = $(this).data('modalType')

    if (!modalType) {
      throw new Error('Cannot find modal type')
    }

    if (!modals[modalType]) {
      throw new Error('No such modal type: ' + modalType)
    }

    const { route, templateSelector } = modals[modalType]

    $.ajax({
      type: 'post',
      url: Routes[route](id),
      success (items) {
        const template = Handlebars.compile($(templateSelector).html())
        $modalBody.append(template(items))
        $modalBody.find('.js-tablesorter').tablesorter({
          widgets: ['staticRow'],
          dateFormat: 'dd.mm.yyyy',
          headers: { 1: { sorter: 'eudate' } },
        })

        Utils.updateTooltips($modalBody[0])
      },
      error (err) {
        console.error(err)
      },
      complete () {
        $modalBody.loadSpin('stop')
      },
    })
  }

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_clients_debt',
      reportName: 'createClientsDebt',
    })
  })
})

$document.on('rez/reports/dental_labs', function () {
  Report.init()

  $('#period').show()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_dental_labs',
      reportName: 'createDentalLabs',
    })
  })
})

$document.on('rez/reports/appointments', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_appointments',
      reportName: 'createAppointments',
    })
  })
})

$document.on('rez/reports/busyness', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_busyness',
      reportName: 'createBusyness',
    })
  })
})

$document.on('rez/reports/productivity', function () {
  Report.init()
  saveDoctorsProductivityReportInitialFilters()

  $('#report_form').on('change', removeFilterValidationWrapper)

  createFiltersProfilesAppForLegacy({
    filtersSetter ({ filters, errors }) {
      doctorsProductivityReportFiltersAccessors.set(filters)
      showFiltersErrors(errors, doctorsProductivityReportFiltersSelectors)
    },
    filtersGetter: doctorsProductivityReportFiltersAccessors.get,
    profileType: FILTERS_PROFILES_TYPES.DOCTOR_PRODUCTIVITY,
  })

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_productivity',
      reportName: 'createProductivity',
    })
  })
})

$document.on('rez/reports/payment_types', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_payment_types',
      reportName: 'createPaymentTypes',
    })
  })
})

$document.on('rez/reports/client_groups', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_client_groups',
      reportName: 'createClientGroups',
    })
  })
})

$document.on('rez/reports/users_income', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_users_income',
      reportName: 'createUsersIncome',
    })
  })
})

$document.on('rez/reports/administrators', function () {
  Report.init()
  saveAdministratorsProductivityReportInitialFilters()

  $('#report_form').on('change', removeFilterValidationWrapper)

  createFiltersProfilesAppForLegacy({
    filtersSetter ({ filters, errors }) {
      administratorsProductivityReportFiltersAccessors.set(filters)
      showFiltersErrors(errors, administratorsProductivityReportFiltersSelectors)
    },
    filtersGetter: administratorsProductivityReportFiltersAccessors.get,
    profileType: FILTERS_PROFILES_TYPES.ADMINISTRATOR_PRODUCTIVITY,
  })

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_administrators',
      reportName: 'createAdministrators',
    })
  })
})

$document.on('rez/reports/departments', function () {
  Report.init()
  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_departments',
      reportName: 'createDepartments',
    })
  })
})

$document.on('rez/reports/diseases', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_diseases',
      reportName: 'createDiseases',
    })
  })
})

$document.on('rez/reports/customer_loyalty', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_customer_loyalty',
      reportName: 'createCustomerLoyalty',
    })
  })
})

$document.on('rez/reports/laboratory_registers', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_laboratory_registers',
      reportName: 'createLaboratoryRegisters',
    })
  })
})

$document.on('rez/reports/department_by_period', function () {
  Report.init({ start: moment().startOf('year'), end: moment().endOf('year') })

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_department_by_period',
      reportName: 'createDepartmentByPeriod',
    })
  })
})

$document.on('rez/reports/entries_by_period', function () {
  Report.init({ start: moment().subtract(2, 'months').startOf('month'), end: moment().endOf('month') })

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_entries_by_period',
      reportName: 'createEntriesByPeriod',
    })
  })
})

$document.on('rez/reports/client_duplicates', function () {
  Report.init()

  $('#report_submit').on('click', function () {
    Report.generate({
      url: '/reports/create_client_duplicates',
      reportName: 'createClientDuplicates',
    })
  })
})

$document.on('rez/reports/offices_workload', () => {
  Report.init()

  $('#report_submit').on('click', () => {
    Report.generate({
      url: '/reports/create_offices_workload',
      reportName: 'createOfficesWorkload',
    })
  })
})

$document.on('rez/reports/nps_dynamics', () => {
  Report.init({ start: moment().subtract(1, 'years').startOf('month'), end: moment().endOf('month') })

  $('#report_submit').on('click', () => {
    Report.generate({
      url: '/reports/create_nps_dynamics',
      reportName: 'createNpsDynamics',
    })
  })
})

$document.on('rez/reports/admission_assessment_dynamics', () => {
  Report.init({ start: moment().subtract(1, 'years').startOf('month'), end: moment().endOf('month') })

  $('#report_submit').on('click', () => {
    Report.generate({
      url: '/reports/create_admission_assessment_dynamics',
      reportName: 'createAdmissionAssessmentDynamics',
    })
  })
})
