<template>
  <m-si-generator
    :items="list.data"
    :si-generator-schema="schema"
    nfr-small
    class="max-w-100"
    @onSetSelectedItems="$emit('onSetSelectedItems', $event)"
  />
</template>

<script>
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import {
  EntryTypesTransferList,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/EntryTypesTransfer/store/EntryTypesTransferList'
import {
  getEntryTypesTransferSchema,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/EntryTypesTransfer/store/getEntryTypesTransferSchema'

export default {
  name: 'EntryTypesTransferBody',
  components: { MSiGenerator },
  props: {
    list: { type: EntryTypesTransferList, required: true },
  },

  computed: {
    schema () {
      return getEntryTypesTransferSchema()
    },
  },
}
</script>
