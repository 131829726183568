import { createVueApp } from '@/vue_components/create_vue_app'
import EntryProtocolsApp from '@/vue_apps/MedicalRecords/EntryProtocols/EntryProtocolsApp.vue'
import { pick } from 'lodash'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

let vueApp = null
export const createEntryProtocolApp = (el = '#vue_entry_protocol_root') => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'EntryProtocolsAppRoot',
    render: (h) => h(EntryProtocolsApp, {
      props: {
        entryId: +gon.page.params.id,
        entryTitle: gon.specific.entry.title,
        clientId: gon.specific.entry.client_id,
        clinic: pick(
          gon.application.current_clinic,
          ['id', 'title']
        ),
        doctor: {
          ...pick(
            gon.application.current_user,
            ['id', 'surname', 'name', 'second_name']
          ),
          fullName: vueFilters.fullName(gon.application.current_user),
        },

        configurationPrintEntryTitle: gon.specific.configuration_print_entry_title,
        configurationPrintEntryInfo: gon.specific.configuration_print_entry_info,
        configurationPrintClinicHeader: gon.specific.configuration_print_clinic_header,
        configurationPrintDoctorSignature: gon.specific.configuration_print_doctor_signature,
      },
    }),
  })
}
