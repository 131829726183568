<template>
  <div class="col-container">
    <div
      :class="activeTab !== WIDGET_CATEGORIES_LIST ? 'margin-right-5px' : ''"
      class="col-item-45"
    >
      <div
        class="panel panel-primary"
      >
        <epic-spinner
          :visibility="activeRequestState"
        />
        <div
          class="panel-heading panel-heading-flex"
        >
          <div
            class="panel-heading-title"
          >
            <span
              class="panel-heading-icon fad fa-fw fa-globe"
            />
            <h3
              class="panel-title"
            >
              {{ T.client_widget.categories }}
            </h3>
          </div>
        </div>
        <div
          class="panel-top-btn-container"
        >
          <div
            class="flex-item"
          >
            <button
              class="btn btn-with-icon btn-success clients-options-btn btn-sm"
              :title="t('add_category')"
              @click="activeTab = NEW_WIDGET_CATEGORY, newWidgetCategory()"
            >
              <span class="btn-with-icon_icon fad fa-fw fa-globe" />
              <span class="fas fa-fw fa-plus" />
            </button>
          </div>
        </div>
        <div
          class="panel-body panel-list-body"
        >
          <table
            class="table table-striped table-hover"
          >
            <tbody>
              <tr
                v-for="category in categories"
                :key="category.id"
                :class="category.id === activeTr[0] && 'active'"
                @click="widgetCategoryEdit(category)"
              >
                <td class="options">
                  <i class="fad fa-fw fa-globe" />
                </td>

                <td
                  class="name cut"
                  data-name="title"
                >
                  {{ category.title }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination
          v-if="categoriesCount > PAGINATION_LIMIT"
          :current-page.sync="currentPage"
          :page-count="pageCount"
          :margin-top="10"
          :margin-bottom="10"
          :margin-left="15"
          :margin-right="15"
          layout="prev, pager, next"
          @current-change="loadWidgetCategories"
        />
      </div>
    </div>
    <div
      v-show="activeTab !== WIDGET_CATEGORIES_LIST"
      class="col-item-55"
    >
      <widget-categories-new
        ref="createForm"
        :show="activeTab === NEW_WIDGET_CATEGORY"
        @created="widgetCategoryCreated"
        @close="activeTab = WIDGET_CATEGORIES_LIST"
      />
      <widget-categories-edit
        ref="editForm"
        :show="activeTab === EDIT_WIDGET_CATEGORY"
        @updated="loadWidgetCategories"
        @close="activeTab = WIDGET_CATEGORIES_LIST"
      />
    </div>
  </div>
</template>

<script>
import WidgetCategoriesNew from '@/vue_components/online_recording_categories/online_recording_categories_new.vue'
import WidgetCategoriesEdit from '@/vue_components/online_recording_categories/online_recording_categories_edit.vue'
import Pagination from '@/vue_components/common/pagination.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'

const PAGINATION_LIMIT = 30
const WIDGET_CATEGORIES_LIST = 2
const NEW_WIDGET_CATEGORY = 4
const EDIT_WIDGET_CATEGORY = 8
export default {
  name: 'WidgetCategoriesPage',

  components: {
    WidgetCategoriesNew,
    WidgetCategoriesEdit,
    Pagination,
    EpicSpinner,
  },

  data: () => ({
    PAGINATION_LIMIT,
    WIDGET_CATEGORIES_LIST,
    NEW_WIDGET_CATEGORY,
    EDIT_WIDGET_CATEGORY,

    activeTab: WIDGET_CATEGORIES_LIST,
    currentPage: 1,
    pageCount: 0,
    activeRequestState: false,
    categories: [],
    categoriesCount: 0,
    activeTr: [],
  }),

  watch: {
    categoriesCount () {
      this.pageCount = Math.ceil(this.categoriesCount / PAGINATION_LIMIT)
    },
  },

  created () {
    this.loadWidgetCategoriesCount()
    this.loadWidgetCategories()
  },

  methods: {
    loadWidgetCategories () {
      const self = this

      self.activeRequestState = true

      $.ajax({
        type: 'GET',
        url: Routes.list_online_recording_categories_path(),
        data: {
          offset: (self.currentPage - 1) * PAGINATION_LIMIT,
          limit: PAGINATION_LIMIT,
        },
        success (categories) {
          self.categories = categories
        },
        complete () {
          self.activeRequestState = false
        },
      })
    },

    loadWidgetCategoriesCount () {
      const self = this

      self.activeRequestState = true

      $.ajax({
        type: 'GET',
        url: Routes.count_online_recording_categories_path(),
        success (count) {
          self.categoriesCount = count
        },
        complete () {
          self.activeRequestState = false
        },
      })
    },

    newWidgetCategory () {
      if (this.activeTr.length > 0) {
        this.activeTr.pop()
      }
    },

    widgetCategoryCreated (category) {
      this.loadWidgetCategoriesCount()
      this.loadWidgetCategories()
    },

    widgetCategoryEdit (category) {
      this.$refs.editForm.edit(category)

      if (this.activeTr.length > 0) {
        this.activeTr.pop()
      }
      this.activeTr.push(category.id)

      this.activeTab = EDIT_WIDGET_CATEGORY
    },
  },
}
</script>
