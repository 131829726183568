$document.on('rez/machines/new rez/machines/edit', function () {
  let form = FormFactory.build('machine')

  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      break
    case 'edit':
      form.editItem()
      break
  }
})

// $document.on('rez/machines/show rez/machines/new', function () {
//   $('#machine_price').inputmask('currency', {prefix: '', groupSeparator: ' '})
// })
