/**
 * Эта миксина позволяет получить параметры пагинации и установить новое смещение
 * элементов при изменении порядкового номера страницы.
 * Для корректной инициализации миксины в хуке created компонента нужно
 * вызвать метод 'initPaginationHolder' с объектом параметров (см. метод 'initPaginationHolder').
 * Для изменения смещения нужно пользоваться методом 'onPageChange'. В него передаётся порядковый номер
 * страницы, на основании которого вычисляется нужное смещение.
 * Метод навешивается на событие @current-change компонента 'Pagination'.
 */
export const PaginationHolder = {
  data () {
    return {
      pagination: {
        offset: 0,
        limit: 0,
      },
      totalPages: 1,
      offsetCallback: null,
    }
  },

  watch: {
    'pagination.offset' () {
      if (!this.offsetCallback) return

      this.offsetCallback()
    },
  },

  methods: {
    /**
     * Данный метод определяет лимит кол-ва элементов на одной странице
     * и устанавливает колбэк функцию, вызываемую при изменении смещения.
     * @param {{limit: Number, offsetCallback: Function|null}}
     */
    initPaginationHolder ({limit, offsetCallback}) {
      this.pagination.limit = limit
      this.offsetCallback = offsetCallback
    },

    onPageChange (currentPageNumber) {
      this.pagination.offset = (currentPageNumber - 1) * this.pagination.limit
    },
  },
}
