<template>
  <div class="sidebar-calendar side-menu-wrapper">
    <div class="schedule-calendar-container">
      <calendar-picker
        :calendar-workload-dye="calendarWorkloadDye"
        :highlighted-days.sync="highlightedDays"
      />
    </div>

    <calendar-controls />
  </div>
</template>

<script>
import CalendarPicker from './calendar_picker.vue'
import CalendarControls from './calendar_controls.vue'

import { mapActions, mapGetters } from 'vuex'
import calendarWorkload from './calendar_workload'

export default {
  components: {
    CalendarPicker,
    CalendarControls,
  },

  props: {
    userIds: {
      type: Array,
      required: true,
    },

    cabinetIds: {
      type: Array,
      required: true,
    },

    clinicsWorkload: {
      type: Object,
      required: true,
    },

    workloadReady: Boolean,
  },

  data () {
    return {
      cabinetsWorkloadCalculator: null,
      doctorsWorkloadCalculator: null,
      calendarWorkloadDye: {},
      highlightedDays: [],
    }
  },

  computed: {
    ...mapGetters([
      'GET_ACTIVE_SCHEDULE',
      'GET_SCHEDULE_CURRENT_CLINIC_ID',
      'IS_ACTIVE_SCHEDULE_DOCTORS',
      'IS_ACTIVE_SCHEDULE_CABINETS',
      'GET_LOADING_APPOINTMENTS',
    ]),
  },

  watch: {
    cabinetIds () {
      this.updateCalculator()
    },

    userIds () {
      this.updateCalculator()
    },

    IS_ACTIVE_SCHEDULE_DOCTORS () {
      this.initCalendarMethods()
    },

    GET_LOADING_APPOINTMENTS (to) {
      if (to) { return }
      this.initCalendarMethods()
    },
  },

  mounted () {
    this.initCalendarMethods()
  },

  methods: {
    ...mapActions([
      'setActiveScheduleStore',
      'setScheduleDate',
      'setScheduleDaysCount',
    ]),
    ...calendarWorkload,

    initCalendarMethods () {
      this.$store.commit('SET_LOADING_RIGHT_SIDEBAR', true)

      this.configurationWorkload()
      this.updateCalculator()
      this.afterInit()
    },

    updateCalculator () {
      if (this.cabinetsWorkloadCalculator && this.IS_ACTIVE_SCHEDULE_CABINETS) {
        this.cabinetsWorkloadCalculator.selectedCabinetsIds = this.cabinetIds
      }

      if (this.doctorsWorkloadCalculator && this.IS_ACTIVE_SCHEDULE_DOCTORS) {
        this.doctorsWorkloadCalculator.selectedDoctorsIds = this.userIds
      }
    },

    afterInit () {
      this.$nextTick(() => {
        this.$store.commit('SET_LOADING_RIGHT_SIDEBAR', false)
      })
    },
  },
}
</script>
