<template>
  <panel-heading
    :title="`${t('appointment')} ${scheduleTitle}`"
    icon="fad fa-fw fa-calendar-edit"
    class="main-item-center schedule-grid-container"
  >
    <template #buttons>
      <button
        v-if="GET_SHOW_CANCEL_BUTTON_STATE"
        class="btn btn-danger btn-xs"
        @click="cancelInsert"
      >
        {{ t('reception.cancel_paste') }}
      </button>

      <entry-client-search
        class="main-item-entry-client-search"
        @choose-last-appointment-client="$emit('choose-last-appointment-client', $event)"
      />

      <clinic-selector
        class="main-item-clinic-selector"
        :clinics="clinics"
      />

      <div class="schedule-grid-change-days hidden-print">
        <basic-tooltip :text="t('reception.previous_period')">
          <span
            class="fad fa-fw fa-arrow-alt-circle-left"
            @click="stepDate('prev')"
          />
        </basic-tooltip>
        <basic-tooltip :text="t('reception.next_period')">
          <span
            class="fad fa-fw fa-arrow-alt-circle-right"
            @click="stepDate('next')"
          />
        </basic-tooltip>
      </div>

      <print-dropdown />

      <appointment-call-log-root
        :doctor-schedules-clinic-id="GET_SCHEDULE_CURRENT_CLINIC_ID"
        @findAppointment="onFindAppointment"
        @findAppointmentInOtherClinic="onFindAppointmentInOtherClinic"
        @editAppointment="onEditAppointment"
        @editAppointmentInOtherClinic="onEditAppointmentInOtherClinic"
      />

      <appointment-time-finder-root
        :doctor-schedules-clinic-id="GET_SCHEDULE_CURRENT_CLINIC_ID"
        @set-show-appointment="$emit('show-appointment-in-schedule', $event)"
        @set-create-appointment="$emit('create-appointment-in-schedule', $event)"
      />

      <button
        class="btn btn-grey-dark btn-xs"
        :title="sizeScreenSchedule.title"
        @click="sizeScreenSchedule.action"
      >
        <i
          class="fad"
          :class="sizeScreenSchedule.icon"
        />
      </button>
    </template>

    <doctor-schedules-grid
      :context-ready="contextReady"
      :show-cancel-paste.sync="showCancelPaste"
      :schedule-title.sync="scheduleTitle"
      :focus-on-appointment="focusOnAppointment"
      :reserved-appointment-show="reservedAppointmentShow"
      :reserved-appointment="reservedAppointment"
      :users="users"
      :user-ids="userIds"
      :cabinet-ids="cabinetIds"
      :available-cabinets="availableCabinets"
      :one-visible-user="oneVisibleUser"
      :one-visible-cabinet="oneVisibleCabinet"
      :waiting-count="waitingCount"
      :waiting-user-from-grid="waitingUserFromGrid"

      @update:focusOnAppointment="$updateSync('focusOnAppointment', $event)"
      @update:waitingUserFromGrid="$updateSync('waitingUserFromGrid', $event)"
      @update:oneVisibleUser="$updateSync('oneVisibleUser', $event)"
      @update:availableCabinets="$updateSync('availableCabinets', $event)"
      @update:oneVisibleCabinet="$updateSync('oneVisibleCabinet', $event)"
      @create-appointment-of-grid="$emit('create-appointment-modal-of-grid', $event)"
      @edit-appointment-of-grid="$emit('edit-appointment-modal-of-grid', $event)"
      @create-reminder-of-grid="$emit('create-reminder-of-grid', $event)"
      @choose-last-appointment-client="$emit('choose-last-appointment-client', $event)"
      @move-appointment-from-waiting-list="$emit('move-appointment-from-waiting-list', $event)"
    />
  </panel-heading>
</template>

<script>
import ClinicSelector from './components/clinic_selector.vue'
import DoctorSchedulesGrid from './doctor_schedules_grid.vue'
import EntryClientSearch from './components/entry_client_search'
import PanelHeading from '@/vue_components/common/panel_heading.vue'

import { mapGetters } from 'vuex'
import PrintDropdown from '@/vue_components/doctor_schedules/print_dropdown'
import BasicTooltip from '@/vue_components/common/basic_tooltip'
import AppointmentTimeFinderRoot from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/AppointmentTimeFinderRoot'
import { Intent } from '@/services/intents'
import AppointmentCallLogRoot from '@/vue_apps/DoctorSchedules/AppointmentCallLog/AppointmentCallLogRoot.vue'
import { getDefaultInsertParams } from '@/vue_components/store/modules/doctor_schedules/doctor_schedules'

export default {
  name: 'DoctorSchedulesContainer',
  components: {
    AppointmentCallLogRoot,
    AppointmentTimeFinderRoot,
    BasicTooltip,
    PrintDropdown,
    ClinicSelector,
    DoctorSchedulesGrid,
    EntryClientSearch,
    PanelHeading,
  },

  props: {
    contextReady: Boolean,

    clinics: {
      type: Array,
      required: true,
    },

    users: {
      type: Array,
      required: true,
    },

    specialties: {
      type: Array,
      required: true,
    },

    userIds: {
      type: Array,
      required: true,
    },

    cabinetIds: {
      type: Array,
      required: true,
    },

    availableCabinets: {
      type: Array,
      required: true,
    },

    focusOnAppointment: {
      type: Number,
      default: null,
    },

    oneVisibleCabinet: {
      type: Number,
      default: null,
    },

    oneVisibleUser: {
      type: Number,
      default: null,
    },

    reservedAppointmentShow: Boolean,

    reservedAppointment: {
      type: Object,
      required: false,
      default: null,
    },

    waitingCount: {
      type: Object,
      required: true,
    },

    waitingUserFromGrid: {
      type: [Number, null],
      required: false,
      default: null,
    },
  },

  data () {
    return {
      showCancelPaste: false,
      scheduleTitle: '',
    }
  },

  computed: {
    ...mapGetters([
      'GET_SHOW_CANCEL_BUTTON_STATE',
      'GET_SCHEDULE_DATE',
      'GET_SCHEDULE_DAYS_COUNT',
      'GET_SHOW_LEFT_PANEL_STATE',
      'GET_SHOW_RIGHT_PANEL_STATE',
      'GET_LOCALIZATION_DATE_FORMAT',
      'GET_SCHEDULE_CURRENT_CLINIC_ID',
    ]),

    sizeScreenSchedule () {
      if (this.GET_SHOW_LEFT_PANEL_STATE && this.GET_SHOW_RIGHT_PANEL_STATE) {
        return {
          title: t('unroll'),
          icon: 'fa-expand-arrows-alt',
          action: this.expandSchedule,
        }
      }

      return {
        title: t('roll'),
        icon: 'fa-compress-alt',
        action: this.compressSchedule,
      }
    },
  },

  methods: {
    cancelInsert () {
      this.$store.commit('SET_MODE_GRID_SCHEDULE', $.scheduleGrid.MODE_DEFAULT)
      this.$store.commit('appointment/SET_MOVED_APPOINTMENT', null)
      this.$store.commit('SET_INSERT_PARAMS_APPOINTMENT', getDefaultInsertParams())
      Utils.URL.silentClearSearchParams()
      Intent.removeIntent()
      this.$store.dispatch('changeScheduleClinic')
    },

    /**
     * @param {'next'|'prev'} direction
     */
    stepDate (direction) {
      const directionFactor = direction === 'next' ? 1 : -1
      this.$store.dispatch('setScheduleDate',
        moment(this.GET_SCHEDULE_DATE, this.GET_LOCALIZATION_DATE_FORMAT)
          .add(directionFactor * parseInt(this.GET_SCHEDULE_DAYS_COUNT), 'days')
          .format(this.GET_LOCALIZATION_DATE_FORMAT)
      )
    },

    expandSchedule () {
      this.$store.dispatch('setShowLeftPanelStore', false)
      this.$store.dispatch('setShowRightPanelStore', false)
    },

    compressSchedule () {
      this.$store.dispatch('setShowLeftPanelStore', true)
      this.$store.dispatch('setShowRightPanelStore', true)
    },

    /** @type {callLogAppointment} appointment */
    onFindAppointment (appointment) {
      this.$emit('show-appointment-in-schedule', {
        ...appointment,
        userId: appointment.userId,
        date: moment(appointment.date, Utils.momentDateFormat).format(Utils.baseDateFormat),
        appointmentId: appointment.id,
        clinicId: appointment.clinicId,
      })
    },

    /** @type {callLogAppointment} appointment */
    onFindAppointmentInOtherClinic (appointment) {
      this.$store.dispatch('setScheduleCurrentClinicId', appointment.clinicId)
      this.onFindAppointment(appointment)
    },

    /** @type {callLogAppointment} appointment */
    onEditAppointment (appointment) {
      this.$emit('edit-appointment-modal-of-grid', appointment)
    },

    /** @type {callLogAppointment} appointment */
    onEditAppointmentInOtherClinic (appointment) {
      this.$store.dispatch('setScheduleCurrentClinicId', appointment.clinicId)
      this.onEditAppointment(appointment)
    },

  },
}
</script>
