const HIDDEN = 'hidden-print'
const HIDDEN_FIXED = 'hidden-print-fixed'

/**
 * @param {Element} element
 * @param {boolean} visible
 * @param {string} [hiddenClass]
 */
const setElementVisibility = (element, visible, hiddenClass = HIDDEN) => {
  visible
    ? element.classList.remove(hiddenClass)
    : element.classList.add(hiddenClass)
}

/**
 * @param {string} selector
 * @param {boolean} visible
 * @param {string} [hiddenClass]
 */
const setSelectorClass = (selector, visible, hiddenClass = HIDDEN) => {
  const element = document.querySelector(selector)
  if (!element) { return }
  setElementVisibility(element, visible, hiddenClass)
}

const applyPrintClinicHeader = (visible = false) => {
  /* TODO: выяснить почему их стало две */

  const clinicHeaders = document.querySelectorAll('.print-area__clinic-header')
  if (!clinicHeaders?.length) { return }

  clinicHeaders.forEach((header) => {
    setElementVisibility(header, visible)
  })

  if (visible && clinicHeaders.length > 1) {
    setElementVisibility(clinicHeaders[0], false)
  }
}

const applyPrintDoctorSignature = (visible = false) => {
  setSelectorClass('.print-area__doctor-signature', visible)
}

const applyPrintEntryInfo = (visible = false) => {
  setSelectorClass('.print-area__entry-info', visible, HIDDEN_FIXED)
}

const applyPrintEntryTitle = (visible = false) => {
  setSelectorClass('.print-area__entry-title', visible, HIDDEN_FIXED)
}

export const applyPrintingTools = ({
  printClinicHeader,
  printDoctorSignature,
  printEntryInfo,
  printEntryTitle,
}) => {
  applyPrintClinicHeader(printClinicHeader)
  applyPrintDoctorSignature(printDoctorSignature)
  applyPrintEntryInfo(printEntryInfo)
  applyPrintEntryTitle(printEntryTitle)
}
