<template>
  <el-collapse-item>
    <template slot="title">
      <i class="fad fa-fw fa-list" />
      <span class="collapse-text">{{ T.exports_page.entry_type.head }}</span>
    </template>
    <div class="entry-types si-control vue-items-list">
      <div class="filters">
        <base-select
          v-model="entryTypeKindIds"
          clearable
          collapse-tags
          multiple
          size="small"
          :placeholder="T.all_types"
          class="entry-types-export entry-types-export__types"
        >
          <el-option
            v-for="(entryTypeKind, kind) in entryTypesKinds"
            :key="entryTypeKind.id"
            :value="kind"
            :label="entryTypeKind"
          />
        </base-select>

        <reusable-doctor-clinics-list
          v-model="clinicIds"
          label=""
          :m-fixed-height="false"
          multiple
          class="entry-types-export entry-types-export__clinics"
        />
      </div>
      <div class="buttons vue-list">
        <button
          class="vue-list-item"
          @click="exportEntryTypes()"
        >
          {{ T.download }}
        </button>
      </div>
    </div>
  </el-collapse-item>
</template>

<script>
import { entryTypes } from '../request_data.js'
import { headers } from '../const.js'
import eventMethods from '../events.js'

import BaseSelect from '@/vue_components/common/select/base_select/base_select.vue'
import ReusableDoctorClinicsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorClinicsList.vue'

export default {
  name: 'EntryTypesExport',

  components: {
    ReusableDoctorClinicsList,
    BaseSelect,
  },

  props: {
    withLocale: {
      default: false,
      type: Boolean,
    },
    entryTypesKinds: {
      default: null,
      type: Object,
    },
  },

  data: () => ({
    entryTypeKindIds: [],
    clinicIds: [],
    dateFormat: gon.localization.date_format,
  }),

  methods: {
    ...eventMethods,

    exportEntryTypes () {
      this.$emit('request-state')

      const data = {
        ...entryTypes,
        withLocale: this.withLocale,
        entryTypeKinds: this.entryTypeKindIds,
        clinicIds: this.clinicIds.map((clinic) => clinic.id),
      }

      fetch(Routes.exports_entry_types_path(), {
        method: 'POST',
        headers: {
          ...headers,
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        credentials: 'same-origin',
        body: JSON.stringify(data),
      })
        .then(this._handleErrors)
        .then((result) => {
          Utils.downloadExportFile(result, T.entry_types)
        })
        .catch((error) => {
          this._errorNotify(error)
        })
        .finally(() => {
          this.$emit('request-state')
        })
    },
  },
}
</script>
