export const ANALYSIS_ORDERS_STATES = Object.freeze({
  PREPARED: 'prepared',
  SENT: 'sent',
  ACCEPTED: 'accepted',
  REFUSED: 'refused',
  READY: 'ready',
  PARTIALLY_READY: 'partially_ready',
})

export const ANALYSIS_ORDERS_STATES_ICONS = {
  [ANALYSIS_ORDERS_STATES.PREPARED]: { icon: 'analysisOrderPrepared', css: 'primary' },
  [ANALYSIS_ORDERS_STATES.SENT]: { icon: 'analysisOrderSent', css: 'warning' },
  [ANALYSIS_ORDERS_STATES.ACCEPTED]: { icon: 'analysisOrderAccepted', css: 'gray' },
  [ANALYSIS_ORDERS_STATES.REFUSED]: { icon: 'analysisOrderRefused', css: 'cancel' },
  [ANALYSIS_ORDERS_STATES.READY]: { icon: 'analysisOrderReady', css: 'success' },
  [ANALYSIS_ORDERS_STATES.PARTIALLY_READY]: { icon: 'analysisOrderPartiallyReady', css: 'gray' },
}

export const getAnalysisOrderIcon = (state) => {
  if (!ANALYSIS_ORDERS_STATES_ICONS[state]) {
    return { icon: '', css: '' }
  }

  return ANALYSIS_ORDERS_STATES_ICONS[state]
}
