<template>
  <div class="col col__callStatus">
    <m-icon
      v-tooltip="statusObject.title"
      class="pointer"
      :class="iconCss"
      :icon="statusObject.icon"
      color=""
      @click="$emit('click', $event)"
    />
  </div>
</template>

<script>
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { CALL_CONFIRMATION_STATUS } from '@/vue_apps/DoctorSchedules/AppointmentCallLog/store/const'

export default {
  name: 'AppointmentCallLogCallStatus',
  components: { MIcon },

  props: {
    callStatus: PropsTypes.String(),
    appointmentCallConfirmationStatuses: PropsTypes.Array(),
  },

  computed: {
    statusObject () {
      return this.appointmentCallConfirmationStatuses.find(({ id }) => id === (this.callStatus || CALL_CONFIRMATION_STATUS.PENDING)) || {}
    },

    iconCss () {
      return `appointment-call-status-icon__${this.statusObject.id}`
    },
  },
}
</script>
