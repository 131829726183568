<template>
  <div class="transparent-dom-node">
    <si-field class="flex-stretch">
      <div class="fio report-column-locked wrap">
        {{ $filters.fullName(row.referral) }}
      </div>
      <div
        class="city report-column-locked wrap"
        :data-tooltip="$filters.address(row.referral)"
      >
        {{ $filters.address(row.referral) }}
      </div>
      <div class="company report-column-locked wrap">
        {{ row.firstEntry.company && row.firstEntry.company.title }}
      </div>
      <div class="title wrap">
        {{ row.firstEntry.title }}
      </div>
      <div
        v-if="false"
        class="comment wrap"
        :data-tooltip="row.firstEntry.comment"
      >
        {{ row.firstEntry.comment }}
      </div>
      <div class="client wrap">
        <a
          v-if="row.firstEntry.client && !row.firstEntry.client.deletedAt"
          :href="$routes.client_path(row.firstEntry.client.id)"
        >
          {{ $filters.fullName(row.firstEntry.client) }}
        </a>
        <span
          v-else
          :class="{deleted: row.firstEntry.client.deletedAt}"
        >
          {{ $filters.fullName(row.firstEntry.client) }}
        </span>
      </div>
      <div class="date">
        {{ $filters.date(row.firstEntry.date) }}
      </div>
      <div class="price">
        {{ $filters.price(row.firstEntry.price) }}
      </div>
      <div class="price-with-discount">
        {{ $filters.price(row.firstEntry.finalPrice) }}
      </div>
      <div class="amount">
        {{ row.firstEntry.amount }}
      </div>
      <div class="sum">
        {{ $filters.price(row.firstEntry.finalSum) }}
      </div>
      <div class="reward">
        {{ $filters.price(row.firstEntry.rewardReferral.rate) }} {{ $filters.rateType(row.firstEntry.rewardReferral.rateType) }}
      </div>
      <div class="reward-sum">
        {{ $filters.price(row.firstEntry.rewardReferral.finalSum) }}
      </div>
    </si-field>
    <si-field
      v-for="(entry, index) in row.entries"
      :key="index"
      class="flex-stretch"
    >
      <div class="fio report-column-locked wrap" />
      <div class="city report-column-locked wrap" />
      <div class="company report-column-locked wrap" />
      <div class="title wrap">
        {{ entry.title }}
      </div>
      <div
        v-if="false"
        class="comment wrap"
        :data-tooltip="entry.comment"
      >
        {{ entry.comment }}
      </div>
      <div class="client wrap">
        <a
          v-if="entry.client && !entry.client.deletedAt"
          :href="$routes.client_path(entry.client.id)"
          target="_blank"
        >
          {{ $filters.fullName(entry.client) }}
        </a>
        <span
          v-else
          :class="{deleted: entry.client.deletedAt}"
        >
          {{ $filters.fullName(entry.client) }}
        </span>
      </div>
      <div class="date">
        {{ $filters.date(entry.date) }}
      </div>
      <div class="price">
        {{ $filters.price(entry.price) }}
      </div>
      <div class="price-with-discount">
        {{ $filters.price(entry.finalPrice) }}
      </div>
      <div class="amount">
        {{ entry.amount }}
      </div>
      <div class="sum">
        {{ $filters.price(entry.finalSum) }}
      </div>
      <div class="reward">
        {{ $filters.price(entry.rewardReferral.rate) }} {{ $filters.rateType(entry.rewardReferral.rateType) }}
      </div>
      <div class="reward-sum">
        {{ $filters.price(entry.rewardReferral.finalSum) }}
      </div>
    </si-field>

    <si-field class="section-totals">
      <div class="fio report-column-locked wrap">
        {{ t('reports.referral_total') }}
      </div>
      <div class="city report-column-locked wrap" />
      <div class="company report-column-locked wrap" />
      <div class="title wrap" />
      <div
        v-if="false"
        class="comment wrap"
      />
      <div class="client wrap" />
      <div class="date" />
      <div class="price" />
      <div class="price-with-discount" />
      <div class="amount" />
      <div class="sum">
        {{ $filters.price(row.finalSum) }}
      </div>
      <div class="reward" />
      <div class="reward-sum">
        {{ $filters.price(row.rewardFinalSum) }}
      </div>
    </si-field>
  </div>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { ReferralRow } from '@/vue_components/reports/referrals/row/referralRow'

export default {
  name: 'ReferralsRowUngrouped',
  components: {
    SiField,
  },
  mixins: [ReferralRow],
}
</script>
