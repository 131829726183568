export const TABS = Object.freeze({
  COMPANY: 'company',
  ADDRESS_FORMAL: 'address_formal',
  ADDRESS_FACT: 'address_fact',
  CONTACTS: 'contacts',
  HEAD: 'head',
})

export const createDefaultCompany = () => ({
  title: '',
  companyTypeIds: [],
  typeOfCompany: '',
  individualTaxNumber: '',
  kpp: '',
  ogrn: '',
  okved: '',
  registrationDate: '',
  note: '',
  addressFormal: {
    index: '',
    country: '',
    region: '',
    area: '',
    city: '',
    street: '',
    house: '',
    flat: '',
    nsiRussianSubjectId: null,
  },
  addressFact: {
    index: '',
    country: '',
    region: '',
    area: '',
    city: '',
    street: '',
    house: '',
    flat: '',
    nsiRussianSubjectId: null,
  },
  phone1: '',
  phone2: '',
  web: '',
  email: '',
  headName: '',
  headSurname: '',
  headSecondName: '',
  headPosition: '',
  headOperatesBy: '',
})

export const ADDRESS_VALUES = Object.freeze({
  INDEX: 'index',
  COUNTRY: 'country',
  REGION: 'region',
  AREA: 'area',
  CITY: 'city',
  STREET: 'street',
  HOUSE: 'house',
  FLAT: 'flat',
})

export const ADDRESS_TYPE_FORMS = Object.freeze({
  FORMAL: 'address_formal',
  FACT: 'address_fact',
})

export const getValidationMessages = () => ({
  title: [],
  company_types: [],
  kpp: [],
  phone1: [],
  phone2: [],
  email: [],
  individual_tax_number: [],
})
