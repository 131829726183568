<template>
  <div class="client-form">
    <div class="flex-form">
      <slot name="demo-data">
        <div
          v-if="ENV_IS_DEVELOPMENT"
          class="form-label"
        >
          <label>
            {{ t('past_random_data') }}
          </label>
        </div>
        <div
          v-if="ENV_IS_DEVELOPMENT"
          class="form-value"
        >
          <button
            :disabled="disabled"
            class="btn btn-primary btn-sm"
            @click="$emit('request-demo')"
          >
            {{ t('past_data') }}
          </button>
        </div>
      </slot>
      <slot name="surname-label">
        <div class="form-label">
          <label for="surname">
            {{ t('surname') }}
            <required-mark />
          </label>
        </div>
      </slot>
      <slot name="surname-value">
        <div class="form-value">
          <validation-wrapper :errors="errors.surname">
            <div class="flex">
              <input
                id="surname"
                ref="firstField"
                class="form-control"
                type="text"
                :value="surname"
                :disabled="targetedDisable.surname || disabled"
                @input="$updateSync('surname', $event.target.value)"
              >
            </div>
          </validation-wrapper>
        </div>
      </slot>
      <slot name="name-label">
        <div class="form-label">
          <label for="name">
            {{ t('name') }}
          </label>
          <required-mark v-if="strict" />
        </div>
      </slot>
      <slot name="name-value">
        <div class="form-value">
          <validation-wrapper :errors="errors.name">
            <input
              id="name"
              class="form-control"
              type="text"
              :value="name"
              :disabled="targetedDisable.name || disabled"
              @input="$updateSync('name', $event.target.value)"
            >
          </validation-wrapper>
        </div>
      </slot>
      <slot name="second-name-label">
        <div class="form-label">
          <label for="second-name">
            {{ t('second_name') }}
          </label>
        </div>
      </slot>
      <slot name="second-name-value">
        <div class="form-value">
          <validation-wrapper :errors="errors.second_name">
            <input
              id="second-name"
              class="form-control"
              type="text"
              :value="secondName"
              :disabled="targetedDisable.secondName || disabled"
              @input="$updateSync('second-name', $event.target.value)"
            >
          </validation-wrapper>
        </div>
      </slot>
      <slot name="birthdate-label">
        <div class="form-label">
          <label for="birthdate">
            {{ t('birthdate') }}
          </label>
          <required-mark v-if="strict" />
        </div>
      </slot>
      <slot name="birthdate-value">
        <div class="form-value">
          <validation-wrapper :errors="errors.birthdate">
            <date-picker
              id="birthdate"
              popper-class="client-birthday-picker"
              :value="birthdate"
              :disabled="targetedDisable.birthdate || disabled"
              :date-format="GET_LOCALIZATION_DATEPICKER_FORMAT"
              @input="$updateSync('birthdate', formatDate($event))"
            />
          </validation-wrapper>
        </div>
      </slot>
      <slot name="phone-label">
        <div class="form-label">
          <label for="phone">
            {{ t('phone') }}
            <required-mark />
          </label>
        </div>
      </slot>
      <slot name="phone-value">
        <div class="form-value flex gap-indent-small align-items-center">
          <validation-wrapper
            :errors="errors.phone"
            class="w-100"
          >
            <m-phone-input
              :value="phone"
              :m-fixed-height="false"
              :disabled="targetedDisable.phone || disabled"
              @input="$updateSync('phone', $event)"
            />
          </validation-wrapper>

          <slot name="betterCallSaul" />
        </div>
      </slot>
      <slot name="sex-label">
        <div class="form-label">
          <label for="sex">
            {{ t('sex') }}
          </label>
          <required-mark v-if="strict" />
        </div>
      </slot>
      <slot name="sex-value">
        <div class="form-value">
          <validation-wrapper :errors="errors.sex_id">
            <items-list
              id="sex"
              unpressed
              :selected-items-ids="clientSex"
              :disabled="targetedDisable.sex || disabled"
              :items="sexes"
              v-bind="sexOptions"
              @change="$updateSync('sex', $event[0] !== undefined ? $event[0] : null)"
            />
          </validation-wrapper>
        </div>
      </slot>
      <slot name="entry-online-access-label">
        <div class="form-label">
          <label for="entry-online-access">
            {{ t('entry_online_access') }}
          </label>
        </div>
      </slot>
      <slot name="entry-online-access-value">
        <div class="form-value">
          <element-stub-wrapper
            v-if="!GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED"
            :is-visible="clientWidgetStubVisibility"
            :requirements="[
              { name: MODULES.CLIENT_WIDGET, isEnabled: GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED }
            ]"
            @changeVisibility="clientWidgetStubVisibility = $event"
          />
          <items-list
            v-else
            id="entry-online-access"
            unpressed
            :selected-items-ids="[entryOnlineAccess]"
            :disabled="targetedDisable.entryOnlineAccess || disabled"
            :items="onlineAccessOptions"
            v-bind="onlineAccess"
            @change="$updateSync('entry-online-access', $event[0] !== undefined ? $event[0] : null)"
          />
        </div>
      </slot>

      <template v-if="isChangeableMedcardNumbers">
        <div class="form-label">
          {{ t('emk_id') }}
        </div>
        <div class="form-value">
          <validation-wrapper :errors="errors.patient_card_number">
            <custom-emk-input-deprecated
              :patient-card-number="patientCardNumber"
              :disabled="targetedDisable.patientCardNumber || disabled"
              @input="$updateSync('patientCardNumber', $event)"
            />
          </validation-wrapper>
        </div>
      </template>

      <slot name="groups-label">
        <div class="form-label">
          <label for="groups">
            {{ t('groups') }}
          </label>
        </div>
      </slot>
      <slot name="groups-value">
        <div class="form-value">
          <validation-wrapper :errors="errors.groups">
            <filterable-select
              multiple
              clearable
              :value="groups"
              :placeholder="t('groups')"
              :disabled="targetedDisable.groups || disabled"
              @change="$updateSync('groups', $event)"
            >
              <el-option
                v-for="group in clientGroups"
                :key="group.id"
                :label="group.title"
                :value="group.id"
              />
            </filterable-select>
          </validation-wrapper>
        </div>
      </slot>
      <slot name="email-label">
        <div class="form-label">
          <label for="email">
            {{ t('email') }}
          </label>
        </div>
      </slot>
      <slot name="email-value">
        <div class="form-value">
          <validation-wrapper :errors="errors.email">
            <input
              id="email"
              class="form-control"
              type="email"
              :value="email"
              :disabled="targetedDisable.email || disabled"
              @input="$updateSync('email', $event.target.value)"
            >
          </validation-wrapper>
        </div>
      </slot>
      <slot name="treating-doctor-label">
        <div class="form-label">
          <label for="treating-doctor">
            {{ t('treating_doctor') }}
          </label>
        </div>
      </slot>
      <slot name="treating-doctor-value">
        <div class="form-value">
          <validation-wrapper :errors="errors.treatingDoctor">
            <user-simple-select
              :clinic-id="clinicId"
              :value="treatingDoctor"
              :disabled="targetedDisable.treatingDoctor || disabled"
              full-width
              @change="$updateSync('treating-doctor', $event)"
            />
          </validation-wrapper>
        </div>
      </slot>
      <slot name="personal-discount-label">
        <div class="form-label">
          <label for="personal-discount">
            {{ t('discount') }}
          </label>
        </div>
      </slot>
      <slot name="personal-discount-value">
        <div class="form-value">
          <validation-wrapper :errors="errors.personalDiscount">
            <percent-input
              id="personal-discount"
              class="form-control"
              :disabled="targetedDisable.personalDiscount || disabled"
              :value="personalDiscount"
              @input="$updateSync('personal-discount', $event)"
            />
          </validation-wrapper>
        </div>
      </slot>
      <slot name="legal-representatives-label">
        <div class="form-label">
          <label for="legal-representatives">
            {{ t('legal_representatives') }}
          </label>
        </div>
      </slot>
      <slot name="legal-representatives-value">
        <div class="form-value">
          <validation-wrapper :errors="errors.legalRepresentatives">
            <div class="flex">
              <slot name="legal-representatives-select-value">
                <legal-rep-select
                  :value="legalRepresentatives"
                  :disabled="targetedDisable.legalRepresentatives || disabled"
                  @change="$updateSync('legal-representatives', $event)"
                />
              </slot>
              <slot name="legal-representatives-new-value">
                <guarded-control
                  tag="button"
                  :permissions="['canManageClient']"
                  :tooltip="t('create_legal_representative')"
                  class="btn btn-primary btn-xs input-button input-button_for-select-multiple fad fa-user-friends"
                  :disabled="targetedDisable.legalRepresentatives || disabled"
                  @click:allowed="$emit('add-legal-representative')"
                />
              </slot>
              <slot name="legal-relations">
                <legal-relations-modal
                  v-if="isEgiszEnabled"
                  :legal-reps="legalRepresentatives"
                  :disabled="targetedDisable.legalRepresentatives || disabled"
                  can-manage
                  @setLegalRelations="$updateSync('legalRepresentatives', $event)"
                />
              </slot>
            </div>
          </validation-wrapper>
        </div>
      </slot>

      <template v-if="canEgiszManage">
        <slot name="send_data_to_egisz-label">
          <div class="form-label">
            <label for="send_data_to_egisz">
              {{ t('egisz.egisz_enable_sending') }}
            </label>
          </div>
        </slot>

        <slot name="send_data_to_egisz-value">
          <div class="form-value">
            <checkbox
              id="send_data_to_egisz"
              :checked="sendDataToEgisz"
              :disabled="disabled"
              @change="$updateSync('sendDataToEgisz', $event)"
            />
          </div>
        </slot>

        <slot
          v-if="sendDataToEgisz"
          name="snils-label"
        >
          <div class="form-label">
            <label for="snils-client">
              {{ t('snils') }}<required-mark />
            </label>
          </div>
        </slot>

        <slot
          v-if="sendDataToEgisz"
          name="snils-value"
        >
          <div class="form-value">
            <snils-input
              id="snils-client"
              :value="snils"
              :validation-messages="errors.snils"
              :disabled="disabled"
              @input="$updateSync('snils', $event)"
            />
          </div>
        </slot>
      </template>

      <slot name="additional-label">
        <div class="form-label">
          <label for="additional">
            {{ t('additional') }}
          </label>
        </div>
      </slot>
      <slot name="additional-value">
        <div class="form-value">
          <validation-wrapper :errors="errors.additional">
            <textarea
              id="additional"
              rows="2"
              class="form-control"
              :value="additional"
              :disabled="targetedDisable.additional || disabled"
              @input="$updateSync('additional', $event.target.value)"
            />
          </validation-wrapper>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/vue_components/common/select/date_picker'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import ItemsList from '@/vue_components/items_list'
import PercentInput from '@/vue_components/common/percent_input'
import props from './client_form_props'
import withTooltips from '@/vue_components/mixins/withTooltips'
import LegalRepSelect from '../common/select/legal_rep_select'
import RequiredMark from '@/vue_components/common/required_mark'
import { mapGetters } from 'vuex'
import GuardedControl from '@/vue_components/common/guarded_control'
import ElementStubWrapper from '@/vue_components/stubs/element_stub_wrapper'
import { MODULES } from '@/specific/stubs/_helpers/constants'
import formatter from '@/lib/formatters/formatter'
import FilterableSelect from '@/vue_components/doctor_schedules/components/filterable-select'
import Checkbox from '@/vue_components/common/checkbox'
import SnilsInput from '@/vue_components/common/SnilsInput'
import LegalRelationsModal from '@/vue_components/client/RelationManager/LegalRelationsModal'
import UserSimpleSelect from '@/vue_components/common/select/UserSimpleSelect'
import CustomEmkInputDeprecated from '@/vue_components/client/CustomEmkInputDeprecated.vue'
import MPhoneInput from '@/vue_present/_base/MPhoneInput/MPhoneInput.vue'

/**
 *
 * To paste demo data, subscribe to event `request-demo` in the parent component,
 * get demo data somewhere and set new props based on that data.
 *
 * Emits `request-demo`, `add-legal-representative`  without arguments
 */
export default {
  name: 'ClientForm',

  components: {
    MPhoneInput,
    CustomEmkInputDeprecated,
    UserSimpleSelect,
    LegalRelationsModal,
    SnilsInput,
    Checkbox,
    FilterableSelect,
    ElementStubWrapper,
    GuardedControl,
    LegalRepSelect,
    PercentInput,
    ItemsList,
    ValidationWrapper,
    DatePicker,
    RequiredMark,
  },

  mixins: [withTooltips],

  props,

  data () {
    return {
      sexOptions: Object.freeze({
        minSelectedItems: 0,
        multiSelect: false,
        idItemField: 'id',
        displayedItemField: 'title',
      }),

      onlineAccess: Object.freeze({
        minSelectedItems: 0,
        multiSelect: false,
        idItemField: 'id',
        displayedItemField: 'title',
      }),

      clientWidgetStubVisibility: false,
      MODULES,
    }
  },

  computed: {
    ...mapGetters([
      'ENV_IS_DEVELOPMENT',
      'GET_LOCALIZATION_DATEPICKER_FORMAT',
      'GET_LOCALIZATION_DATE_FORMAT',
      'GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED',
    ]),

    ...mapGetters({
      isChangeableMedcardNumbers: 'vxGetIsChangeableMedcardNumbers',
    }),

    clientSex () {
      return [this.sex]
    },

    groupsIds () {
      return this.groups.map((group) => group.id)
    },

    isEgiszEnabled () {
      return gon.application.egisz_module_enabled
    },

    canEgiszManage () {
      return this.isEgiszEnabled &&
        Utils.can(Utils.ACTIONS.MANAGE, 'Egisz')
    },
  },

  mounted () {
    this.updateTooltips()
  },

  methods: {
    /**
     * @param {Date|null} date
     * @return {string|null}
     */
    formatDate (date) {
      return formatter.formatValue(date, ['date:rubyDate'])
    },

    focusFirstField () {
      this.$refs.firstField.focus()
    },
  },
}
</script>
