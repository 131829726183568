import { request } from '@/lib/transport/request'

export const specialtiesEndpoint = {
  /**
   * @param {string} query
   * @return {Promise<{id: number, title: string}[]>}
   */
  find (query) {
    return request({
      type: 'GET',
      url: Routes.search_specialties_path({title: query}),
    }).promise.then((response) => response.result)
  },
}
