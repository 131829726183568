import { ReportParamFactory } from '@/specific/reports/shared'

/**
 * @typedef {{
 *  period: String,
 *  clinic: String | ReportParam,
 *  paymentKinds: String | ReportParam,
 *  paymentType: Array
 * }} PaymentsReportFilters
 */

/**
 * @return {PaymentsReportFilters}
 */
export const getFilters = () => ({
  period: ReportParamFactory.getRawParam('.period'),
  clinic: ReportParamFactory.getSelectParam('#report_clinic_id'),
  paymentKinds: ReportParamFactory.getSelectParam('#report_payment_kinds'),
  paymentType: ReportParamFactory.getSelect2Params('#report_payment_species'),
  payerType: ReportParamFactory.getSelect2Params('#report_payment_payer_kinds'),
  doctorId: ReportParamFactory.getSelectParam('#report_medical_users_with_clinics_id'),
  chequePrinted: ReportParamFactory.getSelect2Params('#report_cheque_printed'),
})
