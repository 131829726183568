import { request } from '@/lib/transport/request'
import { discountAdapter } from './discount_reasons_adapter'

export const discountReasonsEndpoint = {
  getAll (sort_params) {
    const options = {
      type: 'POST',
      url: Routes.catalogs_discount_reasons_list_path(),
      data: {
        sort_params,
      },
    }

    return request(options).promise
  },

  update (data) {
    const options = {
      type: 'PATCH',
      url: Routes.discount_reason_path(data.id),
      data: discountAdapter.toServer(data),
    }

    return request(options).promise
  },

  create (data) {
    const options = {
      type: 'POST',
      url: Routes.discount_reasons_path(),
      data: discountAdapter.toServer(data),
    }

    return request(options).promise
  },
}
