<template>
  <div
    v-show="visibility"
    id="uis_control_panel"
    class="uis"
  >
    <epic-spinner :visibility="showLoadingSpinner" />
    <table class="show_info_table">
      <tr>
        <td>{{ T.telephony.uis.employee_id }}</td>
        <td>
          <div class="field-with-btn">
            <div class="field-with-btn_field">
              <el-input
                v-model="employeeId"
                type="string"
                placeholder="ID"
              />
            </div>
            <div class="field-with-btn_btn">
              <el-button
                :loading="buttonLoading"
                type="warning"
                size="mini"
                icon="fad fa-fw fa-sync-alt"
                @click="syncUser"
              >
                {{ T.telephony.sync_user }}
              </el-button>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>{{ T.telephony.uis.default_virtual_number }}</td>
        <td>
          <el-select
            v-model="virtualNumber"
            :placeholder="'Номера'"
          >
            <el-option
              v-for="item in virtualNumbersList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </td>
      </tr>
      <tr>
        <td>{{ t('telephony.uis.internal_phone_number') }}</td>
        <td>
          <m-input
            v-model="internalNumber"
            type="string"
            :m-fixed-height="false"
            style="max-width: 210px"
          />
        </td>
      </tr>
      <tr>
        <td>{{ T.telephony.uis.sync_status }}</td>
        <td>
          <span>{{ sync_msg }}</span>
        </td>
      </tr>
    </table>
    <div class="control">
      <el-button
        type="success"
        size="mini"
        :class="'btn-with-icon'"
        @click="updateUser"
      >
        <span class="btn-with-icon_icon fad fa-save" />
        <span class="btn-with-icon_text">{{ t('save').toUpperCase() }}</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import EpicSpinner from '../../../epic_spinner/epic_spinner.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'

export default {
  name: 'UisControlPanel',
  components: {
    MInput,
    EpicSpinner,
  },

  props: {
    user: {
      default: undefined,
      type: Object,
    },
    visibility: Boolean,
  },

  data () {
    return {
      buttonLoading: false,
      showLoadingSpinner: false,
      virtualNumber: '',
      sync_msg: '',
      employeeId: null,
      virtualNumbersList: [],
      internalNumber: '',
    }
  },

  mounted () {
    this.checkVirtualNumbersList()
    if (this.user) {
      this.virtualNumber = this.user.uis_default_virtual_number
      this.internalNumber = this.user.uis_internal_phone_number
      this.employeeId = this.user.uis_employee_id
    }
  },

  methods: {
    syncUser () {
      this.buttonLoading = true
      $.ajax({
        url: Routes.sync_user_telephony_telephony_route_index_path('uis'),
        method: 'POST',
        data: {
          user_id: this.user.id,
        },
      })
        .success((data) => {
          this.employeeId = data.uis_employee_id
          Notificator.success(T.telephony.user_updated)
          this.sync_msg = T.telephony.uis.errors[data.responseJSON]
        })
        .error((data) => {
          console.error(data)
          Notificator.error(T.telephony.uis.errors[data.responseJSON])
          this.sync_msg = T.telephony.uis.errors[data.responseJSON]
        })
        .complete(() => {
          this.buttonLoading = false
        })
    },

    checkVirtualNumbersList () {
      if (this.virtualNumbersList.length === 0) {
        this.getVirtualNumbersList()
      }
    },

    getVirtualNumbersList () {
      this.showLoadingSpinner = true
      $.ajax({
        url: Routes.virtual_numbers_telephony_telephony_route_index_path(),
        method: 'GET',
      })
        .success((data) => {
          this.virtualNumbersList = data.numbers
          this.sync_msg = T.telephony.uis.errors[data.responseJSON]
        })
        .error((data) => {
          console.error(data)
          this.sync_msg = T.telephony.uis.errors[data.responseJSON]
        })
        .complete(() => {
          this.showLoadingSpinner = false
        })
    },

    updateUser () {
      $.ajax({
        url: Routes.user_path(this.user.id),
        method: 'PATCH',
        data: {
          user: {
            uis_default_virtual_number: this.virtualNumber,
            uis_internal_phone_number: this.internalNumber,
            uis_employee_id: this.employeeId,
          },
        },
      })
        .success((data) => {
          Notificator.success(T.telephony.settings_updated)
        })
        .error((data) => {
          console.error(data)
          Notificator.error(T.telephony.uis.errors[data.responseJSON])
        })
    },
  },
}
</script>
