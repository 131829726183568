<template>
  <search-select
    clearable
    size="small"
    value-key="id"
    custom-class="full-width"
    :not-results="noResults"
    :loading="loading"
    :allow-search="allowSearch"
    :search-query.sync="searchQuery"
    :value="value"
    :disabled="disabled"
    @change="onChange"
    @update:searchQuery="search"
    @clearSelect="clearAll"
    @visible-change="onListVisibilityChange"
  >
    <el-option
      v-for="option in optionsList"
      :key="option.id"
      :value="option"
      :label="option.title"
    >
      <basic-tooltip
        v-if="showTooltip"
        :text="option | companyInfo"
      >
        <span class="fad fa-info-circle more-info-company" />
      </basic-tooltip>
      {{ option | companyInfo }}
    </el-option>
  </search-select>
</template>

<script>
import SearchSelect
  from '@/vue_components/common/select/search_select/search_select'
import { ElSelectFix } from '@/vue_components/common/select/elSelectFix'
import { findCompanies } from '@/vue_components/companies/rest'
import payerSelectorMixin
  from '@/vue_components/mixins/payer_selector/payer_selector_mixin'
import BasicTooltip from '@/vue_components/common/basic_tooltip'

/**
 * @callback getCompanies
 * @param {{
 *   search_query: string,
 *   offset?: number,
 *   limit?: number,
 *   query_addresses?: boolean
 * }} params
 * @returns {Promise<{id: number, title: string}[]>}
 */
export default {
  name: 'CompanySelect',
  components: { BasicTooltip, SearchSelect },
  mixins: [ElSelectFix, payerSelectorMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * @type {import('vue').PropOptions<{id: number, title: string}>}
     */
    value: {
      type: Object,
      default: () => null,
    },
    /**
     * @type {import('vue').PropOptions<{id: number, title: string}[]>}
     */
    defaultOptions: {
      type: Array,
      default: () => [],
    },
    /**
     * @type {import('vue').PropOptions<getCompanies>}
     */
    getCompanies: {
      type: Function,
      default: findCompanies,
    },
    limit: {
      type: Number,
      default: 50,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      error: false,
      loading: false,
      searchQuery: '',
      pagination: {
        currentPage: 1,
        pageCount: 1,
      },
      noResults: false,
    }
  },
  computed: {
    allowSearch () {
      return this.searchQuery.length >= 3
    },
  },
  methods: {
    onListVisibilityChange (visible) {
      if (!visible) {
        this.optionsList = this.getDefaultOptions()
      }
    },
    clearAll () {
      this.$emit('change', null)
      this.optionsList = this.getDefaultOptions()
    },
    search (query) {
      if (this.allowSearch) {
        this.getCompanies({
          search_query: query,
          limit: this.limit,
          query_addresses: true,
        })
          .then((options) => {
            this.optionsList = options
            this.noResults = !options.length
          })
      } else {
        this.noResults = false
      }
    },
    /**
     * @param {object[]} legalReps
     */
    onChange (legalReps) {
      this.$emit('change', legalReps)
    },
  },
}
</script>
