import TemplateEditor from '@/modules/variables_editor.js'
import { APPOINTMENT_CONFIRMATION, APPOINTMENT_REMINDER } from '@/modules/telephony/const.js'

$document.on('rez/telephony/uis_configuration/edit', () => {
  const $form = $('#uis_configuration_form')
  const subBtn = $form.find("[type='submit']").eq(0)
  const syncUsersBtn = $('#sync_users_btn')
  const $reminderCb = $('#telephony_uis_configuration_reminder')
  const $confirmationCB = $('#telephony_uis_configuration_confirmation')
  const $reminderFields = $('.reminder_field')
  const $confirmationFields = $('.confirmation_field')
  const $commonFields = $('.common_field')
  const $confirmationEditor = $('.confirmation_template_editor_container')
  const $reminderEditor = $('.reminder_template_editor_container')

  const confirmationEditor = new TemplateEditor({
    container: $confirmationEditor,
    validationCallback (value) {
      subBtn.prop('disabled', !value)
    },
    ...APPOINTMENT_CONFIRMATION,
  })

  confirmationEditor.visualize()

  const reminderEditor = new TemplateEditor({
    container: $reminderEditor,
    validationCallback (value) {
      subBtn.prop('disabled', !value)
    },
    ...APPOINTMENT_REMINDER,
  })

  reminderEditor.visualize()

  function checkErrors () {
    const errorFields = ['numbers_error', 'scenarios_error']
    errorFields.forEach((error) => {
      notifyAboutError(error)
    })
  }

  function notifyAboutError (error) {
    if (Object.keys(gon.specific[error]).length) {
      Notificator.error(t('telephony.uis.errors.' + gon.specific[error]))
    } else {
      Notificator.success(t('telephony.uis.' + error.split('_')[0] + '_updated'))
    }
  }

  const toggleOptionalFields = () => {
    let showCommonField = $reminderCb.prop('checked') || $confirmationCB.prop('checked')
    $reminderFields.toggleClass('hidden', !$reminderCb.prop('checked'))
    $confirmationFields.toggleClass('hidden', !$confirmationCB.prop('checked'))
    $commonFields.toggleClass('hidden', !showCommonField)
  }

  $('#telephony_uis_configuration_confirm_before').inputmask('Regex', { regex: '^[1-9]$|^1[0-9]$|^2[0-3]$' })
  $('#telephony_uis_configuration_remind_before').inputmask('Regex', { regex: '^[1-9]$|^1[0-9]$|^2[0-3]$' })
  $('#telephony_uis_configuration_call_from').inputmask('hh:mm')
  $('#telephony_uis_configuration_call_to').inputmask('hh:mm')

  $reminderCb.on('change', toggleOptionalFields)
  $confirmationCB.on('change', toggleOptionalFields)

  toggleOptionalFields()

  checkErrors()

  $form.remote_form({
    model: 'uis_configuration',
    beforeSend () {
      confirmationEditor.originalize()
      reminderEditor.originalize()
    },
    onSuccess (res) {
      confirmationEditor.visualize()
      reminderEditor.visualize()
      Notificator.info(t('configuration_updated'))
      subBtn.loadSpin('stop')
    },
    onError (err) {
      console.error(err)
      confirmationEditor.visualize()
      reminderEditor.visualize()
      subBtn.loadSpin('stop')
    },
  })

  syncUsersBtn.on('click', function () {
    $.ajax({
      url: Routes.sync_users_telephony_telephony_route_index_path('uis'),
      method: 'POST',
    })
      .success(() => {
        Notificator.success(t('telephony.users_updated'))
      })
      .error((data) => {
        console.error(data)
        Notificator.error(t('telephony.uis.errors.' + data.responseJSON))
      })
      .complete(() => {
        $(this).loadSpin('stop')
      })
    $(this).loadSpin('start')
  })
})
