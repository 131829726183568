import { TDatePeriod } from '@/_declarations/TDatePeriod'
import { DateHelpers } from '@/helpers/DateHelpers'
import { ReportParamStrict } from 'src/specific/reports/shared'
import { FiltersProfilesFormattersToServer as FormattersToServer } from '../toServer'
import {
  TFiltersProfileToServerSelectMulti as ToServerSelectMulti,
  TFiltersProfileToServerSelectSingle as ToServerSelectSingle,
  TFiltersProfileToServerOption as ToServerOption,
  TFiltersProfileToServerHumanSelect as ToServerHumanSelect,
} from '@/vue_apps/FiltersProfilesApp/interfaces/filtersTypes/shared/toServer/selects'
import { TFiltersProfileToServerPeriod as ToServerPeriod } from '@/vue_apps/FiltersProfilesApp/interfaces/filtersTypes/shared/toServer/periods'
import { TFiltersProfileToServerCheckbox as ToServerCheckbox } from '@/vue_apps/FiltersProfilesApp/interfaces/filtersTypes/shared/toServer/checkbox'

const fromLegacyOptionToServerOption = (
  { id, name }: ReportParamStrict,
  withIdTransform: Boolean = true
): ToServerOption => ({
  id: withIdTransform ? +id : id,
  title: name,
})

export class FiltersProfilesLegacyFormattersToServer {
  /* Для периодов в виде строки "1 сентября 2001 - 24 декабря 2024" */
  static formatPeriod (periodInLegacyDatepickerFormat: string): ToServerPeriod {
    const periodInBaseFormat: TDatePeriod = DateHelpers
      .fromLegacyDatepickerFormatToBase(periodInLegacyDatepickerFormat)

    return FormattersToServer.formatPeriod(periodInBaseFormat)
  }

  /* Для значений селекторов */
  static formatSingle (option: ReportParamStrict, withIdTransform: Boolean = true): ToServerSelectSingle {
    if (option.id) { return [fromLegacyOptionToServerOption(option, withIdTransform)] }
  }

  /* Для значений мультиселекторов */
  static formatMulti (options: ReportParamStrict[], withIdTransform: Boolean = true): ToServerSelectMulti {
    if (options[0].id) {
      return options.map((option) => fromLegacyOptionToServerOption(option, withIdTransform))
    }
  }

  /* Для значений селекторов, где выбираются людишки */
  static formatHuman (
    { id, name }: ReportParamStrict,
    isShortName: Boolean = true
  ): ToServerHumanSelect {
    if (id) {
      return [
        isShortName
          ? { id: +id, shortName: name }
          : { id: +id, fullName: name },
      ]
    }
  }

  /* Для чекбоксов */
  static formatCheckbox ({ id }: ReportParamStrict): ToServerCheckbox {
    return !!id
  }
}
