import {
  USER_LIST_STORAGE_NAME,
  SELECT_ALL_ID,
} from './const.js'

export default {
  _getStore () {
    return window.store.get(USER_LIST_STORAGE_NAME)
  },

  _validateStore () {
    const currentStore = this._getStore() || {}
    const ownStore = currentStore[this.GET_SCHEDULE_CURRENT_CLINIC_ID] || {}
    const validState = {}

    if (!this.allSIDs.includes(ownStore.selectedSID)) {
      validState.selectedSID = SELECT_ALL_ID
    } else {
      validState.selectedSID = ownStore.selectedSID
    }

    if (ownStore.showSpecialtiesList === undefined) {
      validState.showSpecialtiesList = true
    } else {
      validState.showSpecialtiesList = ownStore.showSpecialtiesList
    }

    if (ownStore.selectedUIDsInSID === undefined) {
      validState.selectedUIDsInSID = { [SELECT_ALL_ID]: [...this.allUIDs] }
    } else {
      validState.selectedUIDsInSID = ownStore.selectedUIDsInSID
    }

    Object.keys(validState.selectedUIDsInSID)
      .forEach((sid) => {
        sid = parseInt(sid, 10)

        if (sid === 0) return

        validState.selectedUIDsInSID[sid] =
          validState.selectedUIDsInSID[sid].filter((userId) => {
            return this.usersMap[userId] && this.usersMap[userId].specialty_ids.includes(sid)
          })

        if (validState.selectedUIDsInSID[sid].length) return

        delete validState.selectedUIDsInSID[sid]
      })

    this.allSIDs.forEach((sid) => {
      if (!Array.isArray(validState.selectedUIDsInSID[sid])) {
        validState.selectedUIDsInSID[sid] = []
      }
    })

    if (!Array.isArray(validState.selectedUIDsInSID[SELECT_ALL_ID])) {
      validState.selectedUIDsInSID[SELECT_ALL_ID] = []
    } else {
      validState.selectedUIDsInSID[SELECT_ALL_ID] =
        validState.selectedUIDsInSID[SELECT_ALL_ID].filter((userId) => this.usersMap[userId])
    }

    currentStore[this.GET_SCHEDULE_CURRENT_CLINIC_ID] = validState

    window.store.set(USER_LIST_STORAGE_NAME, currentStore)

    return validState
  },

  _updateStore () {
    const currentStore = this._getStore() || {}

    const state = {
      selectedSID: this.selectedSID,
      showSpecialtiesList: this.showSpecialtiesList,
      selectedUIDsInSID: this.selectedUIDsInSID,
    }

    currentStore[this.GET_SCHEDULE_CURRENT_CLINIC_ID] = state
    window.store.set(USER_LIST_STORAGE_NAME, currentStore)
  },
}
