<template>
  <si-table>
    <template v-slot:header>
      <si-field>
        <div class="catalogs_table__title">
          <span>
            {{ t('title') }}
          </span>
        </div>
      </si-field>
    </template>

    <template v-slot:body>
      <si-field
        v-for="cabinet in cabinets"
        :key="cabinet.id"
        class="catalogs_table__item"
        :class="{active: cabinet.id === selectedListItem}"
        @click="$emit('ask-edit', cabinet)"
      >
        <div class="catalogs_table__title">
          <span class="fad fa-fw fa-door-closed catalogs_table__icon" />

          <span class="cut">
            {{ cabinet.title }}
          </span>
        </div>
      </si-field>
    </template>
  </si-table>
</template>

<script>
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'

export default {
  name: 'CabinetsTable',
  components: {
    SiTable,
    SiField,
  },

  props: {
    cabinets: {
      type: Array,
      default: () => [],
    },

    selectedListItem: {
      type: Number,
      default: 0,
    },
  },
}
</script>
