import { request } from '@/lib/transport/request'
import { createAdapter } from './company_form_tabbed_adapter'

export const companyEndpoint = {
  getAll () {
    const options = {
      type: 'POST',
      url: Routes.catalogs_company_types_list_path(),
      data: {
        sort_params: {
          offset: 0,
          limit: 2 ** 25,
        },
      },
    }

    return request(options).promise
  },

  create (data) {
    const options = {
      type: 'POST',
      url: Routes.companies_path(),
      data: createAdapter.toServer(data),
    }

    return request(options).promise
  },
}
