export default {
  buildDateRow (date) {
    this.date = date
    const momentDate = moment(this.date).subtract(1, 'days')
    this.dateRange = Object.create(null)

    for (let i = 0; i < 7; i++) {
      momentDate.add(1, 'days')
      this.dateRange[Utils.dateJStoRuby(momentDate.toDate())] = {
        year: momentDate.format('YYYY'),
        month: momentDate.format('MMMM').toUpperCase(),
        dayOfMonth: momentDate.format('D'),
        weekDay: momentDate.format('ddd').toUpperCase(),
        weekDayFull: momentDate.format('dddd'),
      }
    }
  },

  _updateTooltips () {
    if (this.$refs?.employeesScheduleContainer) {
      this.$nextTick(() => {
        Utils.updateTooltips(this.$refs.employeesScheduleContainer)
      })
    }
  },

  _setScheduleTemplatesMaps () {
    const currentDate = moment(this.currentDate).valueOf()
    const templatesMap = this.scheduleTemplatesMap = Object.create(null)
    const activeTemplatesMap = this.usersActiveScheduleTemplatesMap = Object.create(null)

    Object.keys(this.scheduleGridGraph.schedule_templates).forEach((uid) => {
      activeTemplatesMap[uid] = 0
      this.scheduleGridGraph.schedule_templates[uid].forEach((template) => {
        templatesMap[template.id] = {
          template,
          templateExpired: template.active && moment(template.last_propagation_end_date).valueOf() <= currentDate,
        }
        if (template.active) activeTemplatesMap[uid] = activeTemplatesMap[uid] + 1
      })
    })
  },

  _getIntervalStatus (templateId) {
    if (this.scheduleTemplatesMap[templateId].templateExpired) return 'expired-interval'
    else if (this.scheduleTemplatesMap[templateId].template.active) return 'active-interval'

    return null
  },

  _getUserProfileLink (id) {
    return window.Routes.user_path(id)
  },

  _setActiveRequestState (state) {
    this.activeRequestState = state
    Services.ui.spinner().show(state)
  },

  _workTimeUpdatesHandler (packet) {
    if (this.activeRequestState ||
      (!this.showWorkTimeModal && !this.showExtendScheduleTemplateModal)) {
      this.activeRequestState = false

      return
    }

    let hideModals = false

    if (packet.dates_with_changes.length) {
      const selectedUserIds = this.getSelectedUserIds()

      packet.dates_with_changes.forEach((data) => {
        if (!hideModals &&
          window.application.current_clinic.id === data.template.clinic_id &&
          ((this.showExtendScheduleTemplateModal &&
            selectedUserIds.includes(data.template.user_id)) ||
            (this.showWorkTimeModal &&
              data.dates.includes(this.workTimeModalData.date) &&
              this.workTimeModalData.user.id === data.template.user_id))) {
          hideModals = true
        }
      })
    }

    if (!hideModals && packet.days_with_changes.length) {
      packet.days_with_changes.forEach((data) => {
        if (window.application.current_clinic.id === data.clinic_id &&
          this.showWorkTimeModal &&
          this.workTimeModalData.date === data.date &&
          this.workTimeModalData.user.id === data.user_id) {
          hideModals = true
        }
      })
    }

    hideModals && this.closeScheduleTemplateModals()
  },
}
