<template>
  <div class="appointment-content">
    <div class="appointment__params">
      <div class="params__box">
        <div class="flex-form">
          <!-- Поиск услуги -->
          <div class="form-label">
            <slot name="entry-search-label">
              <label for="entry-search">
                {{ t('services') }}
              </label>
            </slot>
          </div>
          <div class="form-value">
            <slot name="entry-search-value">
              <appointment-services-search
                :disabled="disabled"
                :search-query="serviceSearchQuery"
                :search-result-array="servicesSearchResultArray"
                :service-search-allow-search="serviceSearchAllowSearch"
                :service-search-loading="serviceSearchLoading"
                :service-not-results="serviceNotResults"
                @addService="$emit('addService', $event)"
                @searchQueryInput="$emit('update:serviceSearchQuery', $event)"
              />
            </slot>
          </div>

          <!-- Список услуг -->
          <div class="form-label selected-entries-label">
            <slot name="entry-list-label">
              <label for="entry-list">
                {{ t('selected_entry_services') }}
              </label>
            </slot>
          </div>
          <div class="form-value">
            <slot name="entry-list-value">
              <appointment-entry-types
                :readonly="disabled"
                :entry-types-array="entryTypes"
                :currency-format="currencyFormat"
                :currency-symbol="currencySymbol"
                @entryRemove="$emit('entryRemove', $event)"
              />
            </slot>
          </div>

          <!-- Комментарий пациента -->
          <template v-if="clientComment">
            <slot name="client-comment-label">
              <div class="form-label">
                <label for="client-comment">
                  {{ t('attributes.client_comment') }}
                </label>
              </div>
            </slot>
            <div class="form-value">
              <slot name="client-comment-value">
                <textarea
                  id="client-comment"
                  class="disabled w-100"
                  readonly
                  :value="clientComment"
                  rows="2"
                />
              </slot>
            </div>
          </template>

          <!-- Дополнительно -->
          <div class="form-label">
            <slot name="note-label">
              <label for="additionally">
                {{ t('attributes.note') }}
              </label>
            </slot>
          </div>
          <div class="form-value">
            <slot name="note-value">
              <textarea
                id="additionally"
                class="w-100"
                :disabled="disabled"
                :value="note"
                rows="2"
                @input="$emit('update:note', $event.target.value)"
              />
            </slot>
          </div>

          <!-- Длительность приёма -->
          <div class="form-label">
            <slot name="duration-label">
              <label for="duration">
                {{ t('duration') }}
                <required-mark />
              </label>
            </slot>
          </div>
          <div class="form-value">
            <slot name="duration-value">
              <validation-wrapper :errors="validationMessages.duration">
                <duration-selector
                  :disabled="disabled"
                  :durations="durationsArray"
                  :current="duration"
                  :min-value="minDuration"
                  :max-value="maxDuration"
                  @newDuration="$emit('add-duration', $event)"
                  @updateCurrent="$emit('update:duration', $event)"
                />
              </validation-wrapper>
            </slot>
          </div>

          <!-- Тип приёма -->
          <div class="form-label">
            <slot name="appointment-type-label">
              <label for="appointment-type">
                {{ t('appointment_type') }}
                <required-mark />
              </label>
            </slot>
          </div>
          <div class="form-value">
            <slot name="appointment-type-value">
              <validation-wrapper :errors="validationMessages.appointment_type_id">
                <items-list
                  :items="appointmentTypesArray"
                  :multi-select="false"
                  :selected-items-ids="[appointmentTypeId]"
                  :disabled="disabled"
                  id-item-field="id"
                  displayed-item-field="title"
                  @change="$emit('update:appointmentTypeId', $event[0])"
                />
              </validation-wrapper>
            </slot>
          </div>

          <!-- Реферал (Направил) -->
          <div class="form-label">
            <slot name="referred-by-label">
              <label for="referred-by">
                {{ t('referred_by') }}
              </label>
            </slot>
          </div>
          <div class="form-value d-flex w-100">
            <slot name="referred-by-value">
              <user-simple-select
                :value="referral"
                :disabled="disabled"
                :get-users="fetchReferralsList"
                full-width
                @change="$updateSync('referral', $event)"
              />
              <guarded-control
                tag="button"
                :permissions="['canManageReferral']"
                :tooltip="t('new_referral')"
                type="button"
                class="btn btn-primary btn-xs form-add-btn"
                @click:allowed="$emit('add-referral')"
              >
                <i class="fad fa-fw fa-user-md-chat p-0" />
              </guarded-control>
            </slot>
          </div>

          <!-- Источник привлечения-->
          <div class="form-label">
            <slot name="attraction-source-label">
              <label for="attraction-source">
                {{ t('attraction_source') }}
                <required-mark />
              </label>
            </slot>
          </div>
          <div class="form-value">
            <slot name="attraction-source-value">
              <validation-wrapper :errors="validationMessages.attraction_source_id">
                <m-select-lazy
                  :value="attractionSourceIdAdapted"
                  :fetch-method="(params) => attractionSourcesFetchAll(params)"
                  :m-fixed-height="false"
                  :items="attractionSourceArray"
                  use-first-time-fetch
                  filterable
                  full-width
                  @change="$emit('update:attractionSourceId', $event && $event.id)"
                />
              </validation-wrapper>
            </slot>
          </div>

          <!-- Чекбокс "По ДМС" -->
          <div class="form-label">
            <slot name="by-dms-label" />
          </div>
          <div class="form-value">
            <slot name="by-dms-value">
              <m-checkbox
                :disabled="disabled"
                :label="t('reception.by_dms')"
                :value="byDms"
                @input="$emit('update:byDms', $event)"
              />
            </slot>
          </div>

          <!-- Статус приёма -->
          <div class="form-label">
            <slot name="status-label">
              <label for="status">
                {{ t('status') }}
                <required-mark />
              </label>
            </slot>
          </div>
          <div class="form-value">
            <slot name="status-value">
              <validation-wrapper :errors="validationMessages.status">
                <items-list
                  :items="appointmentStatuses"
                  :multi-select="false"
                  :selected-items-ids="[appointmentStatus]"
                  :disabled="disabled"
                  id-item-field="id"
                  displayed-item-field="title"
                  @change="$emit('update:appointmentStatus', $event[0])"
                />
              </validation-wrapper>
            </slot>
          </div>

          <!-- Чекбокс "Добавить в лист ожидания" -->
          <slot name="add-to-waiting-list">
            <div class="form-label" />
            <div class="form-value">
              <m-checkbox
                :disabled="disabled || !!createdBy"
                :label="t('add_to_waiting_list')"
                :value="addToWaitingList"
                @input="$emit('update:addToWaitingList', $event)"
              />
            </div>
          </slot>

          <!-- Чекбоксы уведомлений -->
          <slot name="notifications">
            <div class="form-label">
              <label for="notifications">
                {{ t('configuration.notifications') }}
              </label>
            </div>
            <div class="form-value">
              <!-- Напомнить по смс/wa -->
              <slot name="notification-sms-remind">
                <div class="notification-sms__remind">
                  <element-stub-wrapper
                    :requirements="[
                      { name: MODULES.MESSAGES, isEnabled: GET_APP_CONF_SMS_MODULE_ENABLED || GET_APP_CONF_WHATSAPP_MODULE_ENABLED },
                      { name: OPTIONS.SMS_REMIND, isEnabled: GET_APP_CONF_MESSAGES_REMIND }
                    ]"
                    :modal-styles="'appointment-modal'"
                    :is-visible="remindBeforeDateStubModalVisible"
                    @changeVisibility="$emit('update:remindBeforeDateStubModalVisible', $event)"
                  >
                    <m-checkbox
                      :disabled="disabled"
                      :label="t('sms.remind_before_date')"
                      :value="remindBeforeDate"
                      @input="$emit('update:remindBeforeDate', $event)"
                    />
                  </element-stub-wrapper>
                </div>
              </slot>

              <!-- Уведомить по смс/wa -->
              <slot name="notification-sms-notify">
                <div class="notification-sms__notify">
                  <element-stub-wrapper
                    :requirements="[
                      { name: MODULES.MESSAGES, isEnabled: GET_APP_CONF_SMS_MODULE_ENABLED || GET_APP_CONF_WHATSAPP_MODULE_ENABLED },
                      { name: OPTIONS.SMS_NOTIFY, isEnabled: GET_APP_CONF_MESSAGES_NOTIFY },
                    ]"
                    :modal-styles="'appointment-modal'"
                    :is-visible="notifyAfterSaveStubModalVisible"
                    @changeVisibility="$emit('update:notifyAfterSaveStubModalVisible', $event)"
                  >
                    <m-checkbox
                      :disabled="disabled"
                      :label="t('sms.notify_after_save')"
                      :value="notifyAfterSave"
                      @input="$emit('update:notifyAfterSave', $event)"
                    />
                  </element-stub-wrapper>
                </div>
              </slot>

              <!-- Отправить SMS/wa с просьбой подтвердить запись -->
              <slot name="notification-sms-confirmation">
                <div class="notification-sms__confirmation">
                  <element-stub-wrapper
                    :requirements="[
                      { name: MODULES.CLIENT_WIDGET, isEnabled: GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED },
                      { name: MODULES.MESSAGES, isEnabled: GET_APP_CONF_SMS_MODULE_ENABLED || GET_APP_CONF_WHATSAPP_MODULE_ENABLED },
                      { name: OPTIONS.SMS_CONFIRMATION, isEnabled: GET_APP_CONF_MESSAGES_CONFIRM }
                    ]"
                    :modal-styles="'appointment-modal'"
                    :is-visible="sendSmsConfirmationStubModalVisible"
                    @changeVisibility="$emit('update:sendSmsConfirmationStubModalVisible', $event)"
                  >
                    <m-checkbox
                      :disabled="disabled"
                      :label="t('sms.send_sms_confirmation')"
                      :value="sendSmsConfirmation"
                      @input="$emit('update:sendSmsConfirmation', $event)"
                    />
                  </element-stub-wrapper>
                </div>
              </slot>

              <!-- Напомнить звонком -->
              <slot name="notification-uis-remind-by-call">
                <div class="notification-uis__remind-by-call">
                  <element-stub-wrapper
                    :requirements="[
                      { name: MODULES.TELEPHONY, isEnabled: GET_APP_CONF_TELEPHONY_MODULE_ENABLED },
                      { name: OPTIONS.UIS_REMIND, isEnabled: uisRemindOption },
                    ]"
                    :modal-styles="'appointment-modal'"
                    :is-visible="remindByCallStubModalVisible"
                    @changeVisibility="$emit('update:remindByCallStubModalVisible', $event)"
                  >
                    <m-checkbox
                      :disabled="disabled"
                      :label="t('telephony.uis.remind_by_call')"
                      :value="remindByCall"
                      @input="$emit('update:remindByCall', $event)"
                    />
                  </element-stub-wrapper>
                </div>
              </slot>

              <!-- Подтвердить звонком -->
              <slot name="notification-uis-confirm-by-call">
                <div class="notification-uis__confirm-by-call">
                  <element-stub-wrapper
                    :requirements="[
                      { name: MODULES.TELEPHONY, isEnabled: GET_APP_CONF_TELEPHONY_MODULE_ENABLED },
                      { name: OPTIONS.UIS_CONFIRM, isEnabled: uisConfirmOption },
                    ]"
                    :modal-styles="'appointment-modal'"
                    :is-visible="confirmByCallStubModalVisible"
                    @changeVisibility="$emit('update:confirmByCallStubModalVisible', $event)"
                  >
                    <m-checkbox
                      :disabled="disabled"
                      :label="t('telephony.uis.confirm_by_call')"
                      :value="confirmByCall"
                      @input="$emit('update:confirmByCall', $event)"
                    />
                  </element-stub-wrapper>
                </div>
              </slot>
            </div>
          </slot>

          <!-- Последние сообщения -->
          <slot name="last-notifications">
            <div class="form-label" />
            <div class="form-value">
              <slot name="last-sms-confirmation">
                <div class="last-sms__confirmation">
                  <span class="prompt-notice">{{ t('sms.app_confirmed') }}</span>
                  <span class="prompt-notice">{{ showMessageInfo(lastSmsConfirmation) }}</span>
                </div>
              </slot>

              <slot name="last-sms-notify">
                <div class="last-sms__notify">
                  <span class="prompt-notice">{{ t('sms.app_notified') }}</span>
                  <span class="prompt-notice">{{ showMessageInfo(lastSmsNotify) }}</span>
                </div>
              </slot>

              <slot name="last-sms-notify">
                <div class="last-sms__remind">
                  <span class="prompt-notice">{{ t('sms.app_reminded') }}</span>
                  <span class="prompt-notice">{{ showMessageInfo(lastSmsRemind) }}</span>
                </div>
              </slot>
            </div>
          </slot>
        </div>
      </div>

      <slot name="source">
        <div class="form-value">
          <span class="prompt-notice">{{ t('source') }}:</span>
          <span class="prompt-notice">{{ appointmentSource.title }}</span>
        </div>
      </slot>
      <entity-info
        :created-by="createdBy"
        :updated-by="updatedBy"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import props from './props'

import AppointmentServicesSearch from './appointment_form/appointment_services_search.vue'
import AppointmentEntryTypes from './appointment_form/appointment_entry_types.vue'
import DurationSelector from '@/vue_components/duration_selector/duration_selector.vue'
import ItemsList from '@/vue_components/items_list.vue'
import ElementStubWrapper from '@/vue_components/stubs/element_stub_wrapper.vue'
import EntityInfo from '@/vue_components/entity_info.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import RequiredMark from '@/vue_components/common/required_mark'

import { MODULES, OPTIONS } from '@/specific/stubs/_helpers/constants'
import { fetchReferralsList } from '@/vue_components/doctor_schedules/rest'
import withTooltips from '@/vue_components/mixins/withTooltips'
import GuardedControl from '@/vue_components/common/guarded_control'
import { APPOINTMENT_SOURCE_MEDODS, APPOINTMENT_STATUS_VALUES } from '@/vue_components/doctor_schedules/consts'
import { APPOINTMENT_STATUS } from '@/vue_components/appointment/const'
import UserSimpleSelect from '@/vue_components/common/select/UserSimpleSelect'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { attractionSourcesPresenter } from '@/api_entities/catalogs/attraction_sources/attraction_sources_presenter'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'

export default {
  name: 'AppointmentForm',
  components: {
    MCheckbox,
    MSelectLazy,
    UserSimpleSelect,
    GuardedControl,
    ValidationWrapper,
    AppointmentServicesSearch,
    AppointmentEntryTypes,
    DurationSelector,
    ItemsList,
    ElementStubWrapper,
    EntityInfo,
    RequiredMark,
  },

  mixins: [withTooltips],

  props,

  data () {
    return {
      attractionSourcesFetchAll: attractionSourcesPresenter.fetchAll,

      MODULES,
      OPTIONS,
    }
  },

  computed: {
    ...mapGetters([
      'GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED',
      'GET_APP_CONF_SMS_MODULE_ENABLED',
      'GET_APP_CONF_TELEPHONY_MODULE_ENABLED',
      'GET_APP_CONF_MESSAGES_CONFIRM',
      'GET_APP_CONF_MESSAGES_NOTIFY',
      'GET_APP_CONF_MESSAGES_REMIND',
      'GET_LOCALIZATION_DATE_TIME_FORMAT',
      'GET_DEFAULT_ATTRACTION_SOURCE_ID',
      'GET_APP_CONF_WHATSAPP_MODULE_ENABLED',
    ]),
    appointmentStatuses () {
      return this.appointmentSource.id !== APPOINTMENT_SOURCE_MEDODS
        ? APPOINTMENT_STATUS_VALUES
        : APPOINTMENT_STATUS_VALUES.filter(({ id }) => {
          return id !== APPOINTMENT_STATUS.CONFIRMED_BY_ADMINISTRATOR
        })
    },

    attractionSourceIdAdapted () {
      return { id: this.attractionSourceId }
    },
  },

  mounted () {
    this.updateTooltips()
  },

  methods: {
    fetchReferralsList,

    extractMessageInfo (transport, messageData) {
      if (!messageData) { return '' }

      return [
        transport,
        messageData.updated_at &&
          moment(messageData.updated_at).format(this.GET_LOCALIZATION_DATE_TIME_FORMAT),
        t(`enums.delivery.status.${messageData.status}`),
      ]
        .filter(Boolean)
        .join(', ')
    },

    showMessageInfo ({ sms, whatsapp }) {
      if (!sms && !whatsapp) { return }

      return [
        this.extractMessageInfo('SMS', sms),
        this.extractMessageInfo('WHATSAPP', whatsapp),
      ]
        .filter(Boolean)
        .join(', ')
    },
  },
}
</script>
