const items = [
  {
    'id': 60733,
    'title': null,
    'created_at': '2022-04-29T11:02:03.897+03:00',
    'updated_at': '2022-04-29T11:02:03.897+03:00',
    'client_id': 4744,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '3693.61',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '3693.61',
    'discount_sum': '0.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-29',
    'medical_policy_id': null,
    'paid_status': 'not_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 4744,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60733',
    'paid_status_human': '<span class="order_paid_status_not_paid">Не оплачен</span>',
    'client': 'Иваненко Зоя Юрьевна',
    'client_short_name': 'Иваненко З. Ю.',
    'client_deleted': null,
    'formatted_created_at': '29.04.2022',
    'formatted_date': '29.04.2022',
    'paid_sum': '0.0',
  },
  {
    'id': 60732,
    'title': null,
    'created_at': '2022-04-29T11:00:27.922+03:00',
    'updated_at': '2022-04-29T11:00:27.922+03:00',
    'client_id': 7456,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '1750.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '17.5',
    'discount_sum': '1732.5',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-29',
    'medical_policy_id': null,
    'paid_status': 'not_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 7456,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60732',
    'paid_status_human': '<span class="order_paid_status_not_paid">Не оплачен</span>',
    'client': 'Эктова Надежда Владимировна',
    'client_short_name': 'Эктова Н. В.',
    'client_deleted': null,
    'formatted_created_at': '29.04.2022',
    'formatted_date': '29.04.2022',
    'paid_sum': '0.0',
  },
  {
    'id': 60731,
    'title': null,
    'created_at': '2022-04-27T08:23:27.583+03:00',
    'updated_at': '2022-04-27T08:23:41.673+03:00',
    'client_id': 48139,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '1715.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '1715.0',
    'discount_sum': '0.0',
    'created_by_id': 180,
    'updated_by_id': 180,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-26',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 48139,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60731',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Петров Юрий Андреевич',
    'client_short_name': 'Петров Ю. А.',
    'client_deleted': null,
    'formatted_created_at': '27.04.2022',
    'formatted_date': '26.04.2022',
    'paid_sum': '1715.0',
  },
  {
    'id': 60730,
    'title': null,
    'created_at': '2022-04-25T12:18:41.184+03:00',
    'updated_at': '2022-04-25T12:18:52.634+03:00',
    'client_id': 55968,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '32500.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '32500.0',
    'discount_sum': '0.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-25',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 55968,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60730',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Эсипрва Валентина Дмитриевна',
    'client_short_name': 'Эсипрва В. Д.',
    'client_deleted': null,
    'formatted_created_at': '25.04.2022',
    'formatted_date': '25.04.2022',
    'paid_sum': '32500.0',
  },
  {
    'id': 60729,
    'title': null,
    'created_at': '2022-04-22T08:46:51.518+03:00',
    'updated_at': '2022-04-22T08:46:51.518+03:00',
    'client_id': 56476,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '2950.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '2787.75',
    'discount_sum': '162.25',
    'created_by_id': 180,
    'updated_by_id': 180,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-22',
    'medical_policy_id': null,
    'paid_status': 'not_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 2481,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60729',
    'paid_status_human': '<span class="order_paid_status_not_paid">Не оплачен</span>',
    'client': 'Абаева Мария Ивановна',
    'client_short_name': 'Абаева М. И.',
    'client_deleted': null,
    'formatted_created_at': '22.04.2022',
    'formatted_date': '22.04.2022',
    'paid_sum': '0.0',
  },
  {
    'id': 60728,
    'title': null,
    'created_at': '2022-04-20T14:20:16.472+03:00',
    'updated_at': '2022-04-20T14:22:19.448+03:00',
    'client_id': 24186,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '10500.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '9691.5',
    'discount_sum': '808.5',
    'created_by_id': 180,
    'updated_by_id': 180,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-20',
    'medical_policy_id': 4075,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': 722,
    'deleted_order_entry_ids': [],
    'customer_client_id': null,
    'customer_company_id': 7,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60728',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Ацута Любовь Вячеславовна',
    'client_short_name': 'Ацута Л. В.',
    'client_deleted': null,
    'formatted_created_at': '20.04.2022',
    'formatted_date': '20.04.2022',
    'paid_sum': '9691.5',
  },
  {
    'id': 60727,
    'title': null,
    'created_at': '2022-04-20T14:14:18.443+03:00',
    'updated_at': '2022-04-20T14:14:32.981+03:00',
    'client_id': 24186,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '530.98',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '530.98',
    'discount_sum': '0.0',
    'created_by_id': 180,
    'updated_by_id': 180,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-20',
    'medical_policy_id': 4075,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': 722,
    'deleted_order_entry_ids': [],
    'customer_client_id': null,
    'customer_company_id': 7,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60727',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Ацута Любовь Вячеславовна',
    'client_short_name': 'Ацута Л. В.',
    'client_deleted': null,
    'formatted_created_at': '20.04.2022',
    'formatted_date': '20.04.2022',
    'paid_sum': '530.98',
  },
  {
    'id': 60726,
    'title': null,
    'created_at': '2022-04-20T14:09:41.931+03:00',
    'updated_at': '2022-04-20T14:09:41.931+03:00',
    'client_id': 24186,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '3600.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '3600.0',
    'discount_sum': '0.0',
    'created_by_id': 180,
    'updated_by_id': 180,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-20',
    'medical_policy_id': null,
    'paid_status': 'not_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 24186,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60726',
    'paid_status_human': '<span class="order_paid_status_not_paid">Не оплачен</span>',
    'client': 'Ацута Любовь Вячеславовна',
    'client_short_name': 'Ацута Л. В.',
    'client_deleted': null,
    'formatted_created_at': '20.04.2022',
    'formatted_date': '20.04.2022',
    'paid_sum': '0.0',
  },
  {
    'id': 60725,
    'title': null,
    'created_at': '2022-04-18T09:12:39.364+03:00',
    'updated_at': '2022-04-18T09:13:25.979+03:00',
    'client_id': 25872,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '10000.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '10000.0',
    'discount_sum': '0.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-18',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 25872,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60725',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Капаев Михаил Романович',
    'client_short_name': 'Капаев М. Р.',
    'client_deleted': null,
    'formatted_created_at': '18.04.2022',
    'formatted_date': '18.04.2022',
    'paid_sum': '10000.0',
  },
  {
    'id': 60724,
    'title': null,
    'created_at': '2022-04-15T16:21:54.049+03:00',
    'updated_at': '2022-04-19T08:44:23.425+03:00',
    'client_id': 5338,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '2099.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '1983.5',
    'discount_sum': '115.5',
    'created_by_id': 1,
    'updated_by_id': 176,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-15',
    'medical_policy_id': 4074,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': null,
    'customer_company_id': 7,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60724',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Давлатзода Александр Игоревич',
    'client_short_name': 'Давлатзода А. И.',
    'client_deleted': null,
    'formatted_created_at': '15.04.2022',
    'formatted_date': '15.04.2022',
    'paid_sum': '1983.5',
  },
  {
    'id': 60723,
    'title': null,
    'created_at': '2022-04-15T14:37:31.021+03:00',
    'updated_at': '2022-04-15T14:37:42.266+03:00',
    'client_id': 72406,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '5000.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '5000.0',
    'discount_sum': '0.0',
    'created_by_id': 180,
    'updated_by_id': 180,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-15',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 15656,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60723',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Сапожков Игорь Николаевич',
    'client_short_name': 'Сапожков И. Н.',
    'client_deleted': null,
    'formatted_created_at': '15.04.2022',
    'formatted_date': '15.04.2022',
    'paid_sum': '5000.0',
  },
  {
    'id': 60722,
    'title': null,
    'created_at': '2022-04-14T07:56:25.530+03:00',
    'updated_at': '2022-04-14T07:56:47.108+03:00',
    'client_id': 45455,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '1715.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '1715.0',
    'discount_sum': '0.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-14',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 45455,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60722',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Эндрушко Екатерина Александровна',
    'client_short_name': 'Эндрушко Е. А.',
    'client_deleted': null,
    'formatted_created_at': '14.04.2022',
    'formatted_date': '14.04.2022',
    'paid_sum': '1715.0',
  },
  {
    'id': 60721,
    'title': null,
    'created_at': '2022-04-14T07:53:17.649+03:00',
    'updated_at': '2022-04-14T07:54:42.525+03:00',
    'client_id': 45455,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '315.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '306.0',
    'discount_sum': '9.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-14',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 45455,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': true,
    'formatted_id': '60721',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Эндрушко Екатерина Александровна',
    'client_short_name': 'Эндрушко Е. А.',
    'client_deleted': null,
    'formatted_created_at': '14.04.2022',
    'formatted_date': '14.04.2022',
    'paid_sum': '306.0',
  },
  {
    'id': 60720,
    'title': null,
    'created_at': '2022-04-14T07:48:41.723+03:00',
    'updated_at': '2022-04-14T07:49:00.858+03:00',
    'client_id': 55391,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '3600.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '3600.0',
    'discount_sum': '0.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-14',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 55391,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60720',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Волков Алан Юрьевич',
    'client_short_name': 'Волков А. Ю.',
    'client_deleted': null,
    'formatted_created_at': '14.04.2022',
    'formatted_date': '14.04.2022',
    'paid_sum': '3600.0',
  },
  {
    'id': 60719,
    'title': null,
    'created_at': '2022-04-14T07:47:00.495+03:00',
    'updated_at': '2022-04-14T07:47:16.279+03:00',
    'client_id': 48017,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '120.86',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '120.86',
    'discount_sum': '0.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-14',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 48017,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60719',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Кормилицин Александр Николаевич',
    'client_short_name': 'Кормилицин А. Н.',
    'client_deleted': null,
    'formatted_created_at': '14.04.2022',
    'formatted_date': '14.04.2022',
    'paid_sum': '120.86',
  },
  {
    'id': 60718,
    'title': null,
    'created_at': '2022-04-14T07:43:39.756+03:00',
    'updated_at': '2022-04-14T07:50:54.035+03:00',
    'client_id': 48017,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '34.55',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '34.55',
    'discount_sum': '0.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-14',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 48017,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60718',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Кормилицин Александр Николаевич',
    'client_short_name': 'Кормилицин А. Н.',
    'client_deleted': null,
    'formatted_created_at': '14.04.2022',
    'formatted_date': '14.04.2022',
    'paid_sum': '34.55',
  },
  {
    'id': 60717,
    'title': null,
    'created_at': '2022-04-14T07:42:15.191+03:00',
    'updated_at': '2022-04-14T07:42:30.949+03:00',
    'client_id': 41409,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '550.0',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '550.0',
    'discount_sum': '0.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-14',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 41409,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60717',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Эминова Валентина Николаевна',
    'client_short_name': 'Эминова В. Н.',
    'client_deleted': null,
    'formatted_created_at': '14.04.2022',
    'formatted_date': '14.04.2022',
    'paid_sum': '550.0',
  },
  {
    'id': 60716,
    'title': null,
    'created_at': '2022-04-14T07:40:44.614+03:00',
    'updated_at': '2022-04-14T07:41:00.689+03:00',
    'client_id': 41409,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '358.86',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '358.86',
    'discount_sum': '0.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-14',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 41409,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60716',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Эминова Валентина Николаевна',
    'client_short_name': 'Эминова В. Н.',
    'client_deleted': null,
    'formatted_created_at': '14.04.2022',
    'formatted_date': '14.04.2022',
    'paid_sum': '358.86',
  },
  {
    'id': 60715,
    'title': null,
    'created_at': '2022-04-14T07:38:21.809+03:00',
    'updated_at': '2022-04-14T07:38:40.291+03:00',
    'client_id': 41409,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '18.29',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '18.29',
    'discount_sum': '0.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-14',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 41409,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60715',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Эминова Валентина Николаевна',
    'client_short_name': 'Эминова В. Н.',
    'client_deleted': null,
    'formatted_created_at': '14.04.2022',
    'formatted_date': '14.04.2022',
    'paid_sum': '18.29',
  },
  {
    'id': 60714,
    'title': null,
    'created_at': '2022-04-14T07:36:56.673+03:00',
    'updated_at': '2022-04-14T07:37:16.610+03:00',
    'client_id': 41409,
    'order_status_id': 2,
    'order_paid_status_id': null,
    'sum': '40.91',
    'clinic_id': 1,
    'discount_value': '0.0',
    'final_sum': '40.91',
    'discount_sum': '0.0',
    'created_by_id': 1,
    'updated_by_id': 1,
    'discount_type': 1,
    'deleted_at': null,
    'payment_type_id': null,
    'deleted_by_id': null,
    'document_id': null,
    'date': '2022-04-14',
    'medical_policy_id': null,
    'paid_status': 'fully_paid',
    'finance_guarantee_letter_id': null,
    'deleted_order_entry_ids': [],
    'customer_client_id': 41409,
    'customer_company_id': null,
    'orders_registry_id': null,
    'rounded_prices': false,
    'formatted_id': '60714',
    'paid_status_human': '<span class="order_paid_status_fully_paid">Полностью оплачен</span>',
    'client': 'Эминова Валентина Николаевна',
    'client_short_name': 'Эминова В. Н.',
    'client_deleted': null,
    'formatted_created_at': '14.04.2022',
    'formatted_date': '14.04.2022',
    'paid_sum': '40.91',
  },
]
export const orderItems = items.map((item) => {
  item.selected = false
  item.selectable = true
  item.deleted = false

  return item
})
