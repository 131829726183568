import {
  WORK_TIME_FACTORY,
  SCHEDULE_TEMPLATE_FACTORY,
  DAY_INTERVAL_TEMPLATE,
  WEEK_INTERVAL_TEMPLATE,
} from '../../../../const.js'

import workTimePreset from './work_time_preset.js'
import scheduleTemplatePreset from './schedule_template_preset.js'

const FactoryCore = Object.create(null)

FactoryCore[WORK_TIME_FACTORY] = workTimePreset
FactoryCore[SCHEDULE_TEMPLATE_FACTORY] = scheduleTemplatePreset

export const commonFields = (templateTypeId, scheduleTemplate) => ({
  templateDayIntervalId: templateTypeId === DAY_INTERVAL_TEMPLATE ? scheduleTemplate.day_period : 1,
  templateWeekIntervalId: templateTypeId === WEEK_INTERVAL_TEMPLATE ? scheduleTemplate.week_period : 1,
  templateSelectedDaysIds: templateTypeId === WEEK_INTERVAL_TEMPLATE ? scheduleTemplate.week_period_days_include.slice() : [1],
})

export default class TemplateFactory {
  constructor (preset, options = {}) {
    if (!FactoryCore[preset]) throw new Error('unsupported preset')

    Object.assign(this, FactoryCore[preset])
    this.initializePreset(options)

    this.availableCabinetsIds = new Set(options.availableCabinetsIds)
  }
}
