<template>
  <div>
    <button
      class="btn btn-success btn-with-icon modal-save"
      @click="$emit('save')"
    >
      <span class="btn-with-icon_icon fad fa-save" />
      <span class="btn-with-icon_text">{{ T.save }}</span>
    </button>
    <button
      class="btn btn-primary btn-with-icon modal-close"
      @click="$emit('close')"
    >
      <span class="btn-with-icon_icon fad fa-times" />
      <span class="btn-with-icon_text">{{ T.close }}</span>
    </button>
  </div>
</template>

<script>
export default { name: 'WorkTimeModalFooter' }
</script>
