export const getDiscountCatalog = () => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.catalogs_discount_reasons_list_path(),
      data: {},
      success: resolve,
      error: reject,
    })
  })
}

export const getHistoryArray = (orderId) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.order_discount_histories_path(orderId),
      data: {},
      success: resolve,
      error: reject,
    })
  })
}
