<template>
  <validation-wrapper
    :m-fixed-height="mFixedHeight"
    :errors="validationErrors"
    class="m-tree-select-wrapper position-relative"
  >
    <el-cascader
      :id="id"
      ref="cascader"
      v-model="vmValue"
      class="m-tree-select"
      :class="{
        'w-100': fullWidth
      }"
      :options="items"
      :props="cascaderProps"
      :disabled="canManage.disabled"
      :clearable="clearable"
      :show-all-levels="!showOnlyLast"
      :filter-method="filterMethod"
      filterable
      @focus="focused = true"
      @blur="focused = false; $emit('blur', $event); __validate()"
      @visible-change="onVisibleChange"
    />

    <m-label
      v-if="label"
      :id="id"
      :label="label"
      :required="isRequiredField && !requiredWhenValue"
      :disabled="canManage.disabled"
      :focused="focused"
    />
  </validation-wrapper>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import { CommonInputProps } from '@/vue_present/_base/inputs/mixins/CommonInputProps'
import { CommonInputMethods } from '@/vue_present/_base/inputs/mixins/CommonInputMethods'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'
import MLabel from '@/vue_present/_base/inputs/MLabel.vue'
import { defaultSearchFilter } from '@/helpers/lambda'

/**
 * На базе https://element.eleme.io/#/en-US/component/cascader
 */
export default {
  name: 'MTreeSelect',
  components: { MLabel, ValidationWrapper },

  mixins: [
    CommonInputProps,
    CommonInputMethods,
    DisabledMixin,
    ValidationChildMixin,
  ],

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: { type: [String, Number, Array], default: null },
    items: { type: Array, default: () => [] },

    valueKey: { type: String, default: 'id' },
    optionLabel: { type: String, default: 'title' },
    leafAttribute: { type: String, default: 'isLeaf' },

    fullWidth: Boolean,
    fullPath: { type: Boolean, default: true },
    canSelectCategory: Boolean,
    showOnlyLast: Boolean,
  },

  emits: [
    'change',
    'onVisibleChange',
    'blur',
  ],

  data () {
    return {
      focused: false,
    }
  },

  computed: {
    vmValue: {
      get () { return this.value },
      set (value) { return this.$emit('change', value) },
    },

    cascaderProps () {
      return {
        value: this.valueKey,
        label: this.optionLabel,
        emitPath: this.fullPath,
        checkStrictly: this.canSelectCategory,
      }
    },
  },

  methods: {
    onVisibleChange (value) {
      this.$emit('onVisibleChange', value)
    },

    filterMethod ({ data }, keyword) {
      return defaultSearchFilter(keyword, this.optionLabel)(data)
    },
  },
}
</script>
