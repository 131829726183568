<template>
  <si-field>
    <div class="tbl-icon no-clip">
      {{ footer.total }}
    </div>

    <div class="number" />

    <div class="short_name cut" />

    <div class="age cut" />

    <div class="emk_number cut" />

    <div class="date" />

    <div class="title" />

    <div class="short_name" />

    <div
      :data-tooltip="footer.amount"
      class="amount"
    >
      {{ footer.amount }}
    </div>

    <div
      :data-tooltip="$filters.currency(footer.sum)"
      class="sum cut"
    >
      {{ $filters.currency(footer.sum) }}
    </div>

    <div
      :data-tooltip="$filters.currency(footer.finalSum)"
      class="sum cut"
    >
      {{ $filters.currency(footer.finalSum) }}
    </div>

    <div
      :data-tooltip="$filters.currency(footer.paidSum)"
      class="sum cut"
    >
      {{ $filters.currency(footer.paidSum) }}
    </div>

    <div class="sum" />

    <div
      v-if="workplacesEnabled"
      class="receipt-field"
    />

    <div class="document-number" />

    <div class="document-createdAt" />
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'ProceduresReportTableFooter',
  components: { SiField },

  props: {
    workplacesEnabled: PropsTypes.Boolean(undefined, true),
    footer: PropsTypes.Object(undefined, true),
  },
}
</script>
