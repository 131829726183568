import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { statusIcons, typeIcons } from '@/vue_apps/TreatmentPlans/TreatmentPlansList/const/treatmentPlansIcons'

export class TreatmentPlansListItem extends MListServiceItem {
  /**
   * @param {TreatmentPlansListItemRaw} tpItemRaw
   */
  constructor (tpItemRaw = {}) {
    super()
    this.id = tpItemRaw.id
    this.date = tpItemRaw.date
    this.title = tpItemRaw.title
    this.performer = tpItemRaw.performer
    this.status = tpItemRaw.status
    this.kind = tpItemRaw.kind
    this.finalSum = tpItemRaw.finalSum

    this.treatmentEntries = tpItemRaw.treatmentEntries || []
  }

  get tableDate () {
    return new MTableCell(Utils.getFormattedDate(this.date))
  }

  get tableTitle () {
    return new MTableCell(this.title)
  }

  get tablePerformer () {
    return new MTableCell(this.performer?.shortName)
  }

  get tableStatus () {
    const { icon, css } = statusIcons[this.status]

    return new MTableCell()
      .addTypeIcon(icon)
      .addClasses(css)
      .addTooltip(t(this.status, { scope: 'treatmentPlanStatuses' }))
  }

  get tableType () {
    const { icon, css } = typeIcons[this.kind]

    return new MTableCell()
      .addTypeIcon(icon)
      .addClasses(css)
      .addTooltip(t(this.kind, { scope: 'treatmentPlanTypes' }))
  }

  get tableFinalSum () {
    return new MTableCell(vueFilters.currency(this.finalSum))
  }
}
