<template>
  <el-select
    class="filterable-select"
    filterable
    :value="value"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template
      v-if="!value.length"
      #prefix
    >
      <span class="fad fa-search" />
    </template>
    <slot />
  </el-select>
</template>

<script>

export default {
  name: 'FilterableSelect',

  props: {
    value: {
      type: [Array, String, Number],
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
