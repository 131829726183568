export const paymentKind = Object.freeze({
  orderInitial: 'Первичный платеж',
  orderSubsequent: 'Оплата кредита',
  orderRefund: 'Возврат оплаты',
  cashIn: 'Внесение денег на лицевой счет',
  cashInRefund: 'Возврат внесения на лицевой счет',
  cashOut: 'Вывод денег с лицевого счета',
  orderFull: 'Полная оплата счета',
  orderInCredit: 'Оплата в кредит',
})
