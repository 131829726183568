import { doctorsProductivityReportFiltersSelectors as selectors } from './filtersSelectors'
import { IDoctorsProductivityReportFiltersSet } from './interfaces'
import { TDatePeriod } from '@/_declarations/TDatePeriod'
import { LegacyFormSetters } from '@/helpers/LegacyFormSetters'
import { _getDoctorsProductivityReportFilters } from './_filtersGetter'
import { getDoctorsProductivityReportInitialFilters as getInitialFilters } from './initialFilters'

export const doctorsProductivityReportFiltersAccessors = {
  get: _getDoctorsProductivityReportFilters,

  set (filtersToSet: IDoctorsProductivityReportFiltersSet): void {
    const filters = { ...getInitialFilters(), ...filtersToSet }

    LegacyFormSetters.setDateRangePickerValue(selectors.period, filters.period as TDatePeriod)
    LegacyFormSetters.setSelectValue(selectors.clinic, filters.clinic.id)
    LegacyFormSetters.setSelectValue(selectors.appointmentType, filters.appointmentType.id)
    LegacyFormSetters.setSelect2Value(selectors.specialty, filters.specialty.id)
    LegacyFormSetters.setButtons2Value(selectors.entryWithoutOrderInclusion, filters.entryWithoutOrderInclusion.id)
    LegacyFormSetters.setButtons2Value(selectors.usersWithWorkTime, filters.usersWithWorkTime.id)
  },
}
