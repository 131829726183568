<template>
  <div class="invitro_form">
    <div class="form-label">
      <label for="analysis_laboratory_entries_invitro_api_token">
        {{ t('laboratories.form_fields.api_token') }}
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationMessages.apiToken || []"
        fixed-height
      >
        <el-input
          id="analysis_laboratory_entries_invitro_api_token"
          :value="apiToken"
          :disabled="isReadOnly"
          type="text"
          name="analysis_laboratory[entries_invitro_api_token]"
          clearable
          @input="$updateSync('apiToken', $event)"
        />
      </validation-wrapper>
    </div>

    <el-checkbox
      class="requestCostPrice-fields-checkbox w-100"
      :disabled="isReadOnly"
      :value="requestCostPrice"
      @change="$updateSync('requestCostPrice', $event)"
    >
      <span class="font-size-13">
        {{ t('laboratories.form_fields.requestCostPrice') }}
      </span>
    </el-checkbox>
  </div>
</template>
<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'

export default {
  name: 'InvitroForm',
  components: { ValidationWrapper },
  props: {
    validationMessages: {
      type: Object,
      default: () => ({}),
    },

    apiToken: {
      type: String,
      default: null,
    },

    canManage: Boolean,
    requestCostPrice: Boolean,
  },

  computed: {
    isReadOnly () {
      return !this.canManage
    },
  },
}
</script>
