<template>
  <modal
    :modal-visibility="modalVisible"
    :class="modalClass"
    @close="saveQuestionnaire"
  >
    <template #header>
      {{ T.laboratories.questionnaire }}
    </template>

    <template #body>
      <div id="questionnaire_body">
        <div
          v-for="question in questionsWithoutDuplicates"
          :key="question.id"
          :ref="question.id"
          class="question"
        >
          <template v-if="!questionHidden(question)">
            <div
              v-if="inputType(question) !== 'checkbox'"
              class="title"
            >
              <span>{{ question.question }}</span>
              <abbr
                v-if="question.required"
                class="required tooltip-bottom"
                :title="T.laboratories.required"
              > * </abbr>
            </div>

            <div class="value">
              <el-select
                v-if="chooseFromVariants(question)"
                v-model="question.answer"
                filterable
                :class="['answer_input', question.error ? 'error' : '']"
                size="mini"
              >
                <el-option
                  v-for="(variant, index) in question.variants"
                  :key="index"
                  :label="variantLabel(variant)"
                  :value="variantValue(variant)"
                />
              </el-select>

              <div
                v-else-if="inputType(question) === 'checkbox'"
                class="checkbox_value"
              >
                <input
                  v-model="question.answer"
                  class="checkbox"
                  type="checkbox"
                >
                <span>
                  {{ question.question }}
                </span>
                <abbr
                  v-if="question.required"
                  class="required tooltip-bottom"
                  :title="T.laboratories.required"
                > * </abbr>
              </div>

              <template
                v-else-if="question.externalLaboratoryId === 'doctor'"
              >
                <reusable-doctors-list
                  v-if="!question.answer"
                  :value="question.answer"
                  label=""
                  :m-fixed-height="false"
                  :use-user-mode="false"
                  @onDoctorChange="question.answer = $event.fullName"
                />

                <m-input
                  v-else
                  v-model="question.answer"
                  :m-fixed-height="false"
                />
              </template>

              <template
                v-else-if="question.externalLaboratoryId === 'ICDcode'"
              >
                <mkb-10
                  v-if="!question.answer"
                  :fixed-height="false"
                  @changeItem="question.answer = $event.code_string"
                >
                  <template #option="{ option }">
                    <el-option
                      :value="option"
                      :label="`${option.code_string} - ${option.title}`"
                    />
                  </template>
                </mkb-10>

                <m-input
                  v-else
                  :value="question.answer"
                  :m-fixed-height="false"
                  @input="question.answer = $event"
                />
              </template>

              <el-input
                v-else
                v-model="question.answer"
                :type="inputType(question)"
                :placeholder="inputPlaceholder(question)"
                :class="['answer_input', question.error ? 'error' : '']"
              >
                <template
                  v-if="question.minimum !== null"
                  slot="prepend"
                >
                  {{ question.minimum }} {{ question.valueType }}
                </template>
                <template
                  v-if="question.maximum !== null"
                  slot="append"
                >
                  {{ question.maximum }} {{ question.valueType }}
                </template>
              </el-input>
            </div>
          </template>
        </div>
      </div>
    </template>

    <template
      #footer-left
    >
      <template v-if="hiddenQuestionsCount > 0">
        <el-button
          :class="['btn', 'btn-warning', 'btn-sm']"
          @click="showQuestions"
        >
          {{ T.show_all }}
        </el-button>
      </template>

      <template v-if="showedHiddenQuestionIds.length > 0">
        <el-button
          :class="['btn', 'btn-warning', 'btn-sm']"
          @click="hideQuestions"
        >
          {{ T.hide_all }}
        </el-button>
      </template>
    </template>

    <template #footer-right>
      <el-button
        :class="['btn', 'btn-primary', 'btn-sm']"
        @click="saveQuestionnaire"
      >
        {{ T.save }}
      </el-button>
    </template>
  </modal>
</template>
<script>
import Modal from '../../modal.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import Mkb10 from '@/vue_components/Mkb10/Mkb-10.vue'

const MODAL_RISE_QUESTIONS_COUNT = 10
const VALID_INPUT_VALUE_TYPES = [
  'string',
  'integer',
  'date',
  'dateTime',
  'boolean',
  'decimal',
]

export default {
  name: 'Questionnaire',
  components: {
    Mkb10,
    MInput,
    ReusableDoctorsList,
    Modal,
  },

  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    questionnaireLoading: {
      type: Boolean,
      default: false,
    },
    modalVisible: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    questionnaireValidated: false,
    showedHiddenQuestionIds: [],
  }),

  computed: {
    questionsWithoutDuplicates () {
      return this.removeDuplicates(this.questions)
    },

    hiddenQuestionsCount () {
      if (this.questions.length === 0) return 0

      return this.questions.filter((question) => this.questionHidden(question)).length
    },

    modalClass () {
      if (this.questions.length > MODAL_RISE_QUESTIONS_COUNT) {
        return 'modal-high'
      }

      return ''
    },
  },

  methods: {
    removeDuplicates (questions) {
      const fieldsHash = Utils.arrayToMap(questions, 'question')

      return Object.values(fieldsHash)
    },

    updateOriginalArray (originalData, filledData) {
      filledData.forEach((filledItem) => {
        originalData.forEach((originalItem) => {
          if (filledItem.externalLaboratoryId === originalItem.externalLaboratoryId &&
          filledItem.question === originalItem.question) {
            originalItem.answer = filledItem.answer
          }
        })
      })

      return originalData
    },

    showQuestions () {
      const showedQuestionIds = []

      this.questions.forEach((question) => {
        if (this.questionHidden(question)) {
          question.hidden = false
          showedQuestionIds.push(question.id)
        }
      })

      this.showedHiddenQuestionIds.push(...showedQuestionIds)
    },

    hideQuestions () {
      const showedQuestionIds = [...this.showedHiddenQuestionIds]
      this.showedHiddenQuestionIds.splice(0)

      this.questions.forEach((question) => {
        if (showedQuestionIds.includes(question.id)) {
          question.hidden = true
        }
      })
    },

    questionHidden (question) {
      return question.hidden && !question.required
    },

    chooseFromVariants (question) {
      if (question.variants instanceof Array) return question.variants.length !== 0

      return false
    },

    closeModal () {
      this.removeErrors()
      this.$emit('close-modal')
    },

    removeErrors () {
      this.questions.forEach((question) => {
        question.error = false
      })
    },

    saveQuestionnaire () {
      //eslint-disable-next-line
      this.questions = this.updateOriginalArray(this.questions, this.questionsWithoutDuplicates)

      this.questions.forEach((question) => {
        if (question.valueType === 'date' &&
            question.answer === 'Invalid date'
        ) {
          question.answer = ''
        }
      })

      if (this.validateQuestionnaire()) {
        this.$emit('save-questions', this.questions)
        this.questionnaireValidated = true
        this.closeModal()
      } else {
        Notificator.error(T.errors.save_error)
      }
    },

    validateQuestionnaire () {
      let validated = true
      this.questions.forEach((question) => {
        question.error = false

        if (question.required) {
          if ((question.answer === null) || (question.answer === '')) {
            question.error = true
          }
        }
        if (this.questionHasRange(question)) {
          if (!this.answerInRange(question)) question.error = true
        }
        if (question.error) validated = false
      })

      if (validated) return true

      return false
    },

    questionHasRange (question) {
      return (question.minimum !== null) || (question.maximum !== null)
    },

    answerInRange (question) {
      if (!this.isNumber(question.answer)) return false
      const answer = Number.isInteger(question.minimum) ? parseInt(question.answer) : parseFloat(question.answer)

      const minimumCondition = answer >= question.minimum
      const maximumCondition = answer <= question.maximum

      if (question.minimum && !question.maximum) {
        return minimumCondition
      }

      if (!question.minimum && question.maximum) {
        return maximumCondition
      }

      return (minimumCondition) && (maximumCondition)
    },

    isNumber (str) {
      if (typeof str === 'string') return !isNaN(str.trim()) && !isNaN(parseFloat(str.trim()))

      return false
    },

    inputType (question) {
      if (VALID_INPUT_VALUE_TYPES.includes(question.valueType)) {
        switch (question.valueType) {
          case 'boolean': return 'checkbox'
          case 'integer':
          case 'decimal': return 'number'
          case 'dateTime': return 'datetime'
          case 'date': return 'date'
          default: return 'text'
        }
      }

      return 'text'
    },

    inputPlaceholder (question) {
      if (VALID_INPUT_VALUE_TYPES.includes(question.valueType)) {
        switch (question.valueType) {
          case 'integer':
          case 'decimal': return T.numeric
          case 'string': return T.print_as_text
          default: return ''
        }
      }

      return ''
    },

    needDescription (question) {
      const { maximum, minimum, variants } = question

      return maximum || minimum || variants.length !== 0
    },

    variantLabel (variant) {
      if (variant instanceof Object) {
        return Object.values(variant)[0]
      }

      return variant
    },

    variantValue (variant) {
      if (variant instanceof Object) {
        return Object.keys(variant)[0]
      }

      return variant
    },
  },
}
</script>
