class CalendarWorkloadDye {
  constructor (params) {
    this.calculateWorkload = () => ({})
    this.rules = params.rules
    this.calcIntervals = params.calcIntervals
    this.additionalClassHandlers = []
  }

  dye (day) {
    const workload = this.calculateWorkload(day)
    workload.day = day
    workload.calcIntervals = this.calcIntervals

    let colorizedParams = { classes: '', enabled: true }

    for (const rule of this.rules) {
      const result = rule(workload)
      if (result) {
        colorizedParams = result
        for (const handler of this.additionalClassHandlers) {
          colorizedParams.classes += handler(day)
        }
      }
    }

    return colorizedParams
  }
}

export default CalendarWorkloadDye
