const WORKLOAD_NOT_FOUND = 0

class CabinetsWorkloadStorage {
  constructor (cabinetsWorkload = {}) {
    this.cabinetsWorkload = new Map()

    Object
      .entries(cabinetsWorkload)
      .forEach(([cabinetId, objectWithCabinetsWorkloadByDay]) => {
        const mapWithCabinetsWorkloadByDay = new Map()

        Object
          .entries(objectWithCabinetsWorkloadByDay)
          .forEach(([day, workload]) => mapWithCabinetsWorkloadByDay.set(day, workload))

        this.cabinetsWorkload.set(Number(cabinetId), mapWithCabinetsWorkloadByDay)
      })
  }

  dailyCabinetWorkload (cabinetId, day) {
    const cabinetWorkloadData = this.cabinetsWorkload.get(cabinetId)

    if (!cabinetWorkloadData) return WORKLOAD_NOT_FOUND

    const dailyWorkload = cabinetWorkloadData.get(day)
    if (!dailyWorkload) return WORKLOAD_NOT_FOUND

    return dailyWorkload
  }

  static instance (clinicId, clinicsWorkload) {
    try {
      const {
        cabinets: {
          workload: {
            [clinicId]: workloadByClinic,
          },
        },
      } = clinicsWorkload

      if (workloadByClinic) return new CabinetsWorkloadStorage(workloadByClinic)

      throw new Error(`clinic workload with id ${clinicId} does not exist`)
    } catch (e) {
      console.debug(e)

      return new CabinetsWorkloadStorage()
    }
  }
}

export default CabinetsWorkloadStorage
