<template>
  <div class="treatment-plans-tree">
    <reusable-lazy-tree
      :tree="tree"
      :render-function="renderFunction"
    />
  </div>
</template>

<script>

import ReusableLazyTree from '@/vue_present/Reuse/LazyTree/ReusableLazyTree.vue'
import {
  TreatmentPlanNodeRenderMixin,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/components/TreatmentPlansTree/TreatmentPlanNodeRenderMixin'
import {
  TreatmentEntryNodeRenderMixin,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/components/TreatmentPlansTree/TreatmentEntryNodeRenderMixin'
import {
  TreatmentPlansLazyTreeApi,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/TreatmentPlansLazyTreeApi'

export default {
  name: 'TreatmentPlansTree',
  components: { ReusableLazyTree },

  mixins: [
    TreatmentPlanNodeRenderMixin,
    TreatmentEntryNodeRenderMixin,
  ],

  props: {
    clientId: { type: Number, required: true },
    tree: TreatmentPlansLazyTreeApi,
  },

  methods: {
    renderFunction (h, $node) {
      return $node.data.parent.id
        ? this._getTreatmentEntryNodeContent(h, $node)
        : this._getTreatmentPlanNodeContent(h, $node)
    },
  },
}
</script>
