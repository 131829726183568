
export class MFetchClient {
  fetch (url, params) {
    return fetch(url, params)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        return response.json()
      })
  }
}
