<template>
  <modal
    v-if="showUpdateWorkTimeModal"
    :modal-class="'update-work-time-modal'"
    :modal-visibility="showUpdateWorkTimeModal"
    @close="$emit('updateNone')"
  >
    <span slot="header">{{ T.change_interval_rule }}</span>
    <template slot="body">
      <span>{{ T.change_recurrence_interval_rule }}</span>
      <span class="modal-time">{{ startTime }}</span>
      <span> — </span>
      <span class="modal-time">{{ endTime }}</span>
      <span>{{ T.with }}</span>
      <span class="modal-time">{{ formattedDate }}</span>
      <template v-if="!beginningOfTheInterval">
        <span>{{ `${T.from_first_day_it_started} - ` }}</span>
        <span class="modal-time">{{ formattedStartDate }}</span>
      </template>
      <span>?</span>
    </template>
    <update-work-time-modal-footer
      slot="footer-right"
      :beginning-of-the-interval="beginningOfTheInterval"
      @close="$emit('updateNone')"
      @updateAllFromCurrentDate="$emit('updateAllFromCurrentDate')"
      @updateAll="$emit('updateAll')"
    />
  </modal>
</template>

<script>
import Modal from '../../../modal.vue'
import UpdateWorkTimeModalFooter from './update_work_time_modal_footer.vue'

export default {
  name: 'UpdateWorkTimeModal',
  components: {
    Modal,
    UpdateWorkTimeModalFooter,
  },
  props: {
    showUpdateWorkTimeModal: Boolean,
    startTime: String,
    endTime: String,
    startDate: String,
    date: String,
    formattedStartDate: String,
    formattedDate: String,
  },
  computed: {
    beginningOfTheInterval () {
      return this.date === this.startDate
    },
  },
}
</script>
