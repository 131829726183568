<template>
  <m-panel
    class="client-feedbacks-info-card"
    :title="t('feedback.feedbacks')"
    icon="star"
    use-print-button
  >
    <feedback-module
      :client-id="clientId"
      cache-key="clients-feedbacks-client"
    />
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import FeedbackModule from '@/vue_apps/FeedbackModule/FeedbackModule.vue'

export default {
  name: 'ClientFeedbacks',
  components: { FeedbackModule, MPanel },

  props: {
    clientId: { type: Number, required: true },
  },
}
</script>
