$document.on('rez/expense_groups/new rez/expense_groups/edit', function () {
  let form = FormFactory.build('expense_group')

  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      break
    case 'edit':
      form.editItem()
      break
  }
},
)
