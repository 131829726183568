import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const $t = (text) => t(text, { scope: 'reports.loyal' })

export const getLoyalReportSchema = () =>
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addSorting()
        .addHeaders({
          clientCell: new MTableHeader($t('clientCell')),
          phoneCell: new MTableHeader($t('phoneCell'))
            .addFixedWidth('150px'),
          emailCell: new MTableHeader($t('emailCell'))
            .addFixedWidth('200px'),
          totalAmountCell: new MTableHeader($t('totalAmountCell'))
            .addFixedWidth('70px'),
          totalFinalSumCell: new MTableHeader($t('totalFinalSumCell'))
            .addFixedWidth('150px'),
          totalPaidSumCell: new MTableHeader($t('totalPaidSumCell'))
            .addTooltip(t('per_period'))
            .addFixedWidth('150px'),
        })
    )
