import { REQUEST_KEY } from './const'
import { createCaseAdapter } from '@/lib/transport/case_adapter'

export const clinicsAdapter = createCaseAdapter(REQUEST_KEY)

export const getClinicAdapter = {
  toClient (data) {
    return {
      ...clinicsAdapter.toClient(data),
      licenseIssueDate: Utils.dateRubyToHuman(data.license_issue_date),
      licenseIssueDateEnd: Utils.dateRubyToHuman(data.license_issue_date_end),
    }
  },
}
