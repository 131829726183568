import { request } from '@/lib/transport/request'
import { getAllAdapter, defaultAdapter } from './company_types_adapter'

export const companyTypesEndpoint = {
  getAll (pageInfo) {
    const options = {
      type: 'POST',
      url: Routes.catalogs_company_types_list_path(),
      data: getAllAdapter.toServer(pageInfo),
    }

    return request(options).promise
  },

  update (data) {
    const options = {
      type: 'PATCH',
      url: Routes.company_type_path(data.id),
      data: defaultAdapter.toServer(data.title),
    }

    return request(options).promise
  },

  create (data) {
    const options = {
      type: 'POST',
      url: Routes.company_types_path(),
      data: defaultAdapter.toServer(data.title),
    }

    return request(options).promise
  },

  destroy (id) {
    const options = {
      type: 'DELETE',
      url: Routes.company_type_path(id),
    }

    return request(options).promise
  },
}
