<template>
  <div
    v-loading="loading"
    class="nsi-configuration flex flex-column"
  >
    <h1 class="nsi-configuration__title">
      {{ t('nsi.dictionaries') }}
    </h1>

    <div class="nsi-configuration__warning mb-indent-validation">
      <m-icon
        icon="warning"
        color="warning"
      />
      <span class="prompt-notice">{{ t('nsi.egiszWarning') }}</span>
    </div>

    <m-input
      v-model="nsiConfiguration.nsiUserKey"
      v-tooltip="t('nsi.userKeyTooltip')"
      class="nsi-configuration__userKey w-25"
      :label="t('nsi.userKey')"
      required
      validator-name="nsi_user_key"
      @registerValidator="onRegisterValidator"
    />

    <!-- Документы -->
    <div class="nsi-configuration__documents mb-indent">
      <m-checkbox
        v-model="nsiConfiguration.idCardTypes.enabled"
        :m-fixed-height="false"
        :label="t('nsi.label_desc.id_card_types')"
      />

      <div class="nsi-configuration__nsiDocumentsDictionary nsi-info">
        <div class="prompt-notice">
          {{ t('nsi.last_update') }}:
          <span :class="{ red: !loading && !nsiConfiguration.idCardTypes.version }">
            {{ nsiConfiguration.idCardTypes.updatedAt }}
          </span>
        </div>
        <div class="prompt-notice">
          {{ t('nsi.version_number') }}: {{ nsiConfiguration.idCardTypes.version || '-' }}
        </div>
      </div>
    </div>

    <!-- Субъекты РФ -->
    <div class="nsi-configuration__subjects mb-indent-validation">
      <m-checkbox
        v-model="nsiConfiguration.russianSubjects.enabled"
        :m-fixed-height="false"
        :label="t('nsi.label_desc.russian_subjects')"
      />

      <div class="nsi-configuration__nsiRussianSubjectsDictionary nsi-info">
        <div class="prompt-notice">
          {{ t('nsi.last_update') }}:
          <span :class="{ red: !loading && !nsiConfiguration.russianSubjects.version }">
            {{ nsiConfiguration.russianSubjects.updatedAt }}
          </span>
        </div>
        <div class="prompt-notice">
          {{ t('nsi.version_number') }}: {{ nsiConfiguration.russianSubjects.version || '-' }}
        </div>
      </div>
    </div>

    <!-- Кнопки -->
    <div class="nsi-configuration__footer">
      <m-button-submit @click="onSubmit" />

      <m-button
        v-loading="pingLoading"
        :text="t('nsi.check_connection')"
        icon="relations"
        @click="onPingClick"
      />
    </div>
  </div>
</template>

<script>
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { NSIConfiguration } from '@/vue_apps/app_configuration/NSI/store/NSIConfiguration'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

export default {
  name: 'NSIConfiguration',
  components: { MInput, MIcon, MButton, MButtonSubmit, MCheckbox },
  mixins: [SpinnerHolder, ValidationParentMixin],

  data () {
    return {
      nsiConfiguration: new NSIConfiguration(),
      pingLoading: false,
    }
  },

  async created () {
    await this.withSpinner(this.nsiConfiguration.load())
  },

  methods: {
    async onSubmit () {
      if (this.hasErrors()) { return }

      await this.withSpinner(this.nsiConfiguration.save())
      await this.withSpinner(this.nsiConfiguration.load())
    },

    async onPingClick () {
      const result = await this.withSpinner(this.nsiConfiguration.ping(), 'pingLoading')
      if (result?.errors) { this.errorsHandler(result.errors) }
    },

    errorsHandler (errors) {
      const errorText = Object.values(errors).join(', ')

      if (errorText.toLowerCase().includes('timed out')) {
        return Notificator.error(t('nsi.errors.timedOut'))
      }

      Notificator.error(errorText)
    },
  },
}
</script>
