import {set} from 'lodash/object'
import formatter from '@/lib/formatters/formatter'
export const ClientFormConsumer = {
  data () {
    // Можно использовать этот флаг для того, чтобы отличать
    // демо-клиентов от всех остальных
    return {
      isDemoClient: false,
    }
  },
  methods: {
    /**
     * Устанавливает поля клиента из демо данных.
     * Здесь не используются два доступных поля: attractionSourcesId и companyId.
     * @param {ClientStub} client
     * @param {string} clientPath Путь к объекту клиента в компоненте
     */
    setDemoClient (client, clientPath) {
      this.isDemoClient = true
      set(this, clientPath + '.name', client.firstName)
      set(this, clientPath + '.surname', client.lastName)
      set(this, clientPath + '.secondName', client.secondName)
      set(this, clientPath + '.email', client.email)
      set(this, clientPath + '.additional', client.clientAdditional)

      set(this, clientPath + '.groups', [client.clientGroupId])

      set(this, clientPath + '.birthdate', this.rubyDate(client.birthDate, 'DD.MM.YYYY'))
      set(this, clientPath + '.phone', client.phone)

      set(this, clientPath + '.index', client.addressZipCode)
      set(this, clientPath + '.country', client.addressCountry)
      set(this, clientPath + '.region', client.addressState)
      set(this, clientPath + '.city', client.addressCity)
      set(this, clientPath + '.street', client.addressStreet)
      set(this, clientPath + '.house', client.addressHouse.toString())
      set(this, clientPath + '.flat', client.addressFlat.toString())

      set(this, clientPath + '.whoIssue', client.whoIssue)
      set(this, clientPath + '.idCardSeries', client.idCardSeries)
      set(this, clientPath + '.idCardNumber', client.idCardNumber)

      set(this, clientPath + '.issueDate', this.rubyDate(client.issueDate, 'DD/MM/YYYY'))
    },

    /**
     * @param {string} date
     * @param {string} fromFormat
     * @return {string}
     */
    rubyDate (date, fromFormat) {
      return formatter.formatValue(date, [['date:rubyDate', fromFormat]])
    },
  },
}
