import { transformKeys } from '@/api_entities/_helpers/transform_keys'
import { camelCase } from 'lodash'
import {
  mappedVariablesValues,
  replaceVariables,
  variablesParser,
} from '@/vue_apps/catalogs_root/analysis_laboratories/const/variables'
import { helixMapper, invitroMapper, kdlMapper } from '@/api_entities/catalogs/analysis_laboratories/const'
import { SPECIAL_CLINICS } from '@/vue_apps/catalogs_root/analysis_laboratories/const/create_laboratories'
import { camelToSnake } from '@/_api/_requests/helpers'

export const analysisLaboratoriesAuthAdapter = {
  toClient (data) {
    return data.reduce((acc, item) => {
      item.clinic_id = Number(item.clinic_id)
      acc[item.clinic_id] = transformKeys(camelCase)(item)

      return acc
    }, {})
  },
}

export const analysisLaboratoriesGetAllAdapter = {
  toClient (data) {
    data.data = data.data.map((item) => {
      item.entries_extra_charge_value = Number(item.entries_extra_charge_value)

      return item
    })
    data.data = transformKeys(camelCase)(data.data)

    return data
  },
  toServer (laboratory) {
    if (!laboratory.id) { return { analysis_laboratory: { title: laboratory.title } } }
    if (!laboratory.systemName) {
      return {
        analysis_laboratory: {
          title: laboratory.title,
          entries_extra_charge_type: '',
          entries_extra_charge_value: '',
        },
      }
    }

    laboratory.barcodeTemplate = replaceVariables(
      laboratory.barcodeTemplate,
      variablesParser(laboratory.barcodeTemplate || ''),
      mappedVariablesValues(laboratory.systemName)
    )

    let data = {}
    if (laboratory.systemName === SPECIAL_CLINICS.INVITRO) {
      data = invitroMapper(laboratory)
    }

    if (laboratory.systemName === SPECIAL_CLINICS.HELIX) {
      data = helixMapper(laboratory)
    }

    if (laboratory.systemName === SPECIAL_CLINICS.KDL || laboratory.systemName === SPECIAL_CLINICS.KDL_CHECKUPS) {
      data = kdlMapper(laboratory)
    }

    return camelToSnake(data)
  },
}
