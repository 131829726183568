import { salaryReportSortingMap } from '@/vue_apps/ReportsModule/SalaryReports/Salary/salaryReportSortingMap'
import { getSalaryReportSchema } from '@/vue_apps/ReportsModule/SalaryReports/Salary/getSalaryReportSchema'
import { SALARY_REPORT_TYPE } from '@/vue_apps/ReportsModule/SalaryReports/const/const'
import { getDoctorSalarySchema } from '@/vue_apps/ReportsModule/SalaryReports/DoctorSalary/getDoctorSalarySchema'
import { reportSalaryAdapter } from '@/_api/Reports/Salary/reportSalaryAdapter'
import {
  doctorSalaryReportSortingMap,
} from '@/vue_apps/ReportsModule/SalaryReports/DoctorSalary/doctorSalaryReportSortingMap'
import {
  getSalaryByPaymentsSchema,
} from '@/vue_apps/ReportsModule/SalaryReports/SalaryByPayments/getSalaryByPaymentsSchema'
import {
  salaryByPaymentsSortingMap,
} from '@/vue_apps/ReportsModule/SalaryReports/SalaryByPayments/salaryByPaymentsSortingMap'
import {
  getSalaryByPaymentsDetailedSchema,
} from '@/vue_apps/ReportsModule/SalaryReports/SalaryByPaymentsDetailed/getSalaryByPaymentsDetailedSchema'
import {
  salaryByPaymentsDetailedSortingMap,
} from '@/vue_apps/ReportsModule/SalaryReports/SalaryByPaymentsDetailed/salaryByPaymentsDetailedSortingMap'
import {
  getDoctorSalaryByPaymentsDetailedSchema,
} from '@/vue_apps/ReportsModule/SalaryReports/DoctorSalaryByPaymentsDetailed/getDoctorSalaryByPaymentsDetailedSchema'
import {
  doctorSalaryByPaymentsDetailedSortingMap,
} from '@/vue_apps/ReportsModule/SalaryReports/DoctorSalaryByPaymentsDetailed/doctorSalaryByPaymentsDetailedSortingMap'
import {
  getDoctorSummarySalarySchema,
} from '@/vue_apps/ReportsModule/SalaryReports/DoctorSummarySalary/getDoctorSummarySalarySchema'
import {
  doctorSummarySalarySortingMap,
} from '@/vue_apps/ReportsModule/SalaryReports/DoctorSummarySalary/doctorSummarySalarySortingMap'

export const getReportConfig = (filtersData = {}) => {
  const userFilter = filtersData.userFilter || filtersData.userOnlyCurrentFilter

  // Раскомментировать, если потребуется сводный отчёт по врачу по платежам
  // if (filtersData.reportSourceFilter?.id === 'payments' && userFilter && filtersData.reportTypeFilter?.id === 'summary') {
  //   return {
  //     siSchema: getDoctorSalaryByPaymentsSchema(),
  //     reportType: SALARY_REPORT_TYPE.DOCTOR_SALARY_BY_PAYMENTS,
  //     sortingMap: doctorSalaryByPaymentsSortingMap,
  //     toClientDataAdapter: reportSalaryAdapter.toClientDoctorSalaryByPayments,
  //   }
  // }

  if (filtersData.reportSourceFilter?.id === 'payments' && userFilter && filtersData.reportTypeFilter?.id === 'detailed') {
    return {
      siSchema: getDoctorSalaryByPaymentsDetailedSchema(),
      reportType: SALARY_REPORT_TYPE.DOCTOR_SALARY_BY_PAYMENTS_DETAILED,
      sortingMap: doctorSalaryByPaymentsDetailedSortingMap,
      toClientDataAdapter: reportSalaryAdapter.toClientDoctorSalaryByPaymentsDetailed,
    }
  }

  if (filtersData.reportSourceFilter?.id === 'payments' && filtersData.reportTypeFilter?.id === 'detailed') {
    return {
      siSchema: getSalaryByPaymentsDetailedSchema(),
      reportType: SALARY_REPORT_TYPE.SALARY_BY_PAYMENTS_DETAILED,
      sortingMap: salaryByPaymentsDetailedSortingMap,
      toClientDataAdapter: reportSalaryAdapter.toClientSalaryByPaymentsDetailed,
    }
  }

  if (filtersData.reportSourceFilter?.id === 'payments') {
    return {
      siSchema: getSalaryByPaymentsSchema(),
      reportType: SALARY_REPORT_TYPE.SALARY_BY_PAYMENTS,
      sortingMap: salaryByPaymentsSortingMap,
      toClientDataAdapter: reportSalaryAdapter.toClientSalaryByPayments,
    }
  }

  if (userFilter && filtersData.reportTypeFilter?.id === 'summary') {
    return {
      siSchema: getDoctorSummarySalarySchema(),
      reportType: SALARY_REPORT_TYPE.DOCTOR_SUMMARY_SALARY,
      sortingMap: doctorSummarySalarySortingMap,
      toClientDataAdapter: reportSalaryAdapter.toClientDoctorSummarySalary,
    }
  }

  if (userFilter) {
    return {
      siSchema: getDoctorSalarySchema(),
      reportType: SALARY_REPORT_TYPE.DOCTOR_SALARY,
      sortingMap: doctorSalaryReportSortingMap,
      toClientDataAdapter: reportSalaryAdapter.toClientDoctorSalary,
    }
  }

  return {
    siSchema: getSalaryReportSchema(),
    reportType: SALARY_REPORT_TYPE.SALARY,
    sortingMap: salaryReportSortingMap,
    toClientDataAdapter: reportSalaryAdapter.toClientSalary,
  }
}
