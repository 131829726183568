export const getDefaultFilters = () => ({
  clinic: null,
  period: [
    moment().startOf('month').format('YYYY-MM-DD'),
    moment().endOf('month').format('YYYY-MM-DD'),
  ],
  treatmentPlanKinds: null,
  treatmentPlanStatuses: [],
  doctor: null,
  responsible: null,
})
