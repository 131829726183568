<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>
    <epic-spinner :visibility="isLoading" />
    <si-table
      ref="table"
      horizontal
    >
      <template #header>
        <si-field ref="header">
          <div class="number">
            {{ header.number }}
          </div>
          <div class="fio cut">
            {{ header.fullName }}
          </div>
          <div class="surname cut">
            {{ header.surname }}
          </div>
          <div class="name cut">
            {{ header.firstName }}
          </div>
          <div class="second_name cut">
            {{ header.secondName }}
          </div>
          <div class="birthdate cut">
            {{ header.birthday }}
          </div>
          <div class="id_card cut">
            {{ header.idCard }}
          </div>
          <div class="address cut">
            {{ header.address }}
          </div>
          <div class="emk cut">
            {{ header.card }}
          </div>
          <div class="police_number cut">
            {{ header.police }}
          </div>
          <div class="date">
            {{ header.date }}
          </div>
          <div class="order">
            {{ header.orderId }}
          </div>
          <div class="code">
            {{ header.code }}
          </div>
          <div class="title">
            {{ header.service }}
          </div>
          <div class="price">
            {{ header.price }}
          </div>
          <div class="final_price">
            {{ header.discountedPrice }}
          </div>
          <div class="amount">
            {{ header.amount }}
          </div>
          <div class="sum">
            {{ header.sum }}
          </div>
          <div class="mkb_codes cut">
            {{ header.mkb }}
          </div>
          <div class="diagnoses cut">
            {{ header.diagnoses }}
          </div>
          <div class="conclusions cut">
            {{ header.conclusions }}
          </div>
          <div class="guarantee_letter_number">
            {{ header.guaranteeLetterNumber }}
          </div>
          <div class="date">
            {{ header.guaranteeLetterDate }}
          </div>
          <div class="doctor">
            {{ header.doctor }}
          </div>
          <div class="doctor_specialties">
            {{ header.specialty }}
          </div>
          <div class="doctor_code">
            {{ header.doctorCode }}
          </div>
          <div class="insurance_company cut">
            {{ header.insuranceCompany }}
          </div>
          <div class="insurant cut">
            {{ header.insured }}
          </div>
          <div
            v-if="stomatologyEnabled"
            class="teeth_numbers cut"
          >
            {{ header.teethNumbers }}
          </div>
        </si-field>
      </template>
      <template #body>
        <template v-for="patient in rows">
          <si-field :key="`${patient.firstEntry.id}_${Math.random()}`">
            <div class="number">
              {{ patient.number }}
            </div>
            <div
              class="fio wrap"
              :data-tooltip="patient.client | clientName"
            >
              <a
                :href="patient.client.id | route('client_path')"
                target="_blank"
              >
                {{ patient.client | clientName }}
              </a>
            </div>
            <div
              :data-tooltip="patient.client.surname"
              class="surname cut"
            >
              {{ patient.client.surname }}
            </div>
            <div
              :data-tooltip="patient.client.name"
              class="name cut"
            >
              {{ patient.client.name }}
            </div>
            <div
              :data-tooltip="patient.client.secondName"
              class="second_name cut"
            >
              {{ patient.client.secondName }}
            </div>
            <div class="birthdate cut">
              {{ patient.client.birthDate | date }}
            </div>
            <div
              :data-tooltip="patient.client | idCard"
              class="id_card wrap"
            >
              {{ patient.client | idCard }}
            </div>
            <div
              :data-tooltip="patient.client | address"
              class="address wrap"
            >
              {{ patient.client | address }}
            </div>
            <div class="emk cut">
              <a
                :href="patient.client.medicalRecordId | route('medical_record_path')"
                target="_blank"
              >
                {{ patient.client.medicalRecordId }}
              </a>
            </div>
            <div
              :data-tooltip="patient.firstEntry.medicalPolicy ? patient.firstEntry.medicalPolicy.number : ''"
              class="police_number cut"
            >
              {{ patient.firstEntry.medicalPolicy ? patient.firstEntry.medicalPolicy.number : '' }}
            </div>
            <div class="date">
              {{ patient.firstEntry.date | date }}
            </div>
            <div class="order">
              <a
                v-if="patient.firstEntry.orderId"
                :href="patient.firstEntry.orderId | route('order_path')"
                target="_blank"
              >
                {{ patient.firstEntry.orderId }}
              </a>
            </div>
            <div class="code">
              {{ patient.firstEntry.number }}
            </div>
            <div class="title">
              {{ patient.firstEntry.title }}
            </div>
            <div class="price">
              {{ patient.firstEntry.sum | price }}
            </div>
            <div class="final_price">
              {{ patient.firstEntry.finalSum | price }}
            </div>
            <div class="amount">
              {{ patient.firstEntry.amount }}
            </div>
            <div class="sum">
              {{ patient.firstEntry.paidSum | price }}
            </div>
            <div class="mkb_codes cut">
              {{ patient.firstEntry.diagnoses | mkbCodes }}
            </div>
            <div class="diagnoses cut">
              {{ patient.firstEntry.diagnoses | titles }}
            </div>
            <div class="conclusions cut">
              {{ patient.firstEntry.conclusions | texts }}
            </div>
            <div class="guarantee_letter_number">
              {{ patient.firstEntry.financeGuaranteeLetter ? patient.firstEntry.financeGuaranteeLetter.number : '' }}
            </div>
            <div class="date">
              {{ (patient.firstEntry.financeGuaranteeLetter ? patient.firstEntry.financeGuaranteeLetter.issueDate : '') | date }}
            </div>
            <div class="doctor">
              {{ patient.firstEntry.doctor | doctor }}
            </div>
            <div class="doctor_specialties wrap">
              <span>
                {{ patient.firstEntry.doctor | specialties }}
              </span>
            </div>
            <div class="doctor_code">
              {{ patient.firstEntry.doctor && patient.firstEntry.doctor.code }}
            </div>
            <div class="insurance_company wrap">
              {{ patient.firstEntry.insuranceCompany.title }}
            </div>
            <div class="insurant wrap">
              {{ patient.firstEntry.company ? patient.firstEntry.company.title : '' }}
            </div>
            <div
              v-if="stomatologyEnabled"
              class="teeth_numbers cut"
            >
              {{ patient.firstEntry.teeth | join }}
            </div>
          </si-field>
          <si-field
            v-for="entry in patient.entries"
            :key="`${entry.id}_${Math.random()}`"
          >
            <div class="number" />
            <div class="fio" />
            <div class="surname" />
            <div class="name" />
            <div class="second_name" />
            <div class="birthdate" />
            <div class="id_card" />
            <div class="address" />
            <div class="emk" />
            <div class="police_number" />
            <div class="date">
              {{ entry.date | date }}
            </div>
            <div class="order">
              <a
                v-if="entry.orderId"
                :href="entry.orderId | route('order_path')"
                target="_blank"
              >
                {{ entry.orderId }}
              </a>
            </div>
            <div class="code">
              {{ entry.number }}
            </div>
            <div class="title">
              {{ entry.title }}
            </div>
            <div class="price">
              {{ entry.sum | price }}
            </div>
            <div class="final_price">
              {{ entry.finalSum | price }}
            </div>
            <div class="amount">
              {{ entry.amount }}
            </div>
            <div class="sum">
              {{ entry.paidSum | price }}
            </div>
            <div class="mkb_codes cut">
              {{ entry.diagnoses | mkbCodes }}
            </div>
            <div class="diagnoses cut">
              {{ entry.diagnoses | titles }}
            </div>
            <div class="conclusions cut">
              {{ entry.conclusions | texts }}
            </div>
            <div class="guarantee_letter_number">
              {{ entry.financeGuaranteeLetter ? entry.financeGuaranteeLetter.number : '' }}
            </div>
            <div class="date">
              {{ (entry.financeGuaranteeLetter ? entry.financeGuaranteeLetter.issueDate : '') | date }}
            </div>
            <div class="doctor">
              {{ entry.doctor | doctor }}
            </div>
            <div class="doctor_specialties wrap">
              <span>
                {{ entry.doctor | specialties }}
              </span>
            </div>
            <div class="doctor_code">
              {{ entry.doctor && entry.doctor.code }}
            </div>
            <div class="insurance_company cut">
              {{ entry.insuranceCompany.title }}
            </div>
            <div class="insurant wrap">
              {{ entry.company ? entry.company.title : '' }}
            </div>
            <div
              v-if="stomatologyEnabled"
              class="teeth_numbers wrap"
            >
              {{ entry.teeth | join }}
            </div>
          </si-field>
          <!-- use negative indices to prevent overlapping with previous loop indices -->
          <si-field
            :key="`${patient.client.id}_${Math.random()}`"
            class="section-totals"
          >
            <div class="number" />
            <div class="fio cut">
              {{ clientTotalLabel }}
            </div>
            <div class="surname" />
            <div class="name" />
            <div class="second_name" />
            <div class="birthdate" />
            <div class="id_card cut" />
            <div class="address cut" />
            <div class="emk" />
            <div class="police_number cut" />
            <div class="date" />
            <div class="order" />
            <div class="code" />
            <div class="title" />
            <div class="price" />
            <div class="final_price cut" />
            <div class="amount" />
            <div class="sum cut">
              {{ patient.paidSum | price }}
            </div>
            <div class="mkb_codes cut" />
            <div class="diagnoses cut" />
            <div class="conclusions cut" />
            <div class="guarantee_letter_number" />
            <div class="date" />
            <div class="doctor" />
            <div class="doctor_specialties" />
            <div class="doctor_code" />
            <div class="insurance_company cut" />
            <div class="insurant cut" />
            <div
              v-if="stomatologyEnabled"
              class="teeth_numbers cut"
            />
          </si-field>
        </template>
      </template>
      <template
        v-if="isDirty"
        #footer
      >
        <si-field class="total">
          <div class="number" />
          <div class="fio cut">
            {{ totalLabel }}
          </div>
          <div class="surname" />
          <div class="name" />
          <div class="second_name" />
          <div class="birthdate" />
          <div class="id_card cut" />
          <div class="address cut" />
          <div class="emk" />
          <div class="police_number cut" />
          <div class="date" />
          <div class="order" />
          <div class="code" />
          <div class="title" />
          <div class="price cut" />
          <div class="final_price cut" />
          <div class="amount cut" />
          <div
            class="sum cut"
            :data-tooltip="footer.paidSum | currency"
          >
            {{ footer.paidSum | currency }}
          </div>
          <div class="mkb_codes cut" />
          <div class="diagnoses cut" />
          <div class="conclusions cut" />
          <div class="guarantee_letter_number" />
          <div class="date" />
          <div class="doctor" />
          <div class="doctor_specialties" />
          <div class="doctor_code" />
          <div class="insurance_company cut" />
          <div class="insurant cut" />
          <div
            v-if="stomatologyEnabled"
            class="teeth_numbers cut"
          />
        </si-field>
      </template>
    </si-table>
    <si-footer v-if="rows.length">
      <pagination
        style="margin: 10px 15px 10px 5px;"
        :page-count="pageCount"
        layout="prev, pager, next"
        @current-change="loadData"
      />
    </si-footer>
  </si>
</template>

<script>
import Pagination from '@/vue_components/common/pagination.vue'
import Report from '@/vue_components/mixins/report.js'
import HorizontalReport from '@/vue_components/mixins/horizontalReport.js'
import ReportWithTooltips from '@/vue_components/mixins/withTooltips.js'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import Si from '@/vue_components/sort_items/si.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import formatter from '@/lib/formatters/formatter'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

const BRANCH_CUSTOM = 30
const BRANCH_STOMATOLOGY = 20

export default {
  name: 'InsuranceCompanies',

  components: {
    Pagination,
    Si,
    SiTable,
    SiField,
    SiFooter,
    EpicSpinner,
  },

  filters: {
    clientName (client) {
      return formatter.formatValue(client, ['client:fullName'])
    },
    orderStatus (status) {
      return formatter.formatValue(status, ['order:paidStatus'])
    },
    doctor (doctor) {
      return formatter.formatValue(doctor, ['user:name'])
    },
    mkbCodes (diagnoses = []) {
      return diagnoses.map((diagnose) => diagnose.code).join(', ')
    },
    join (list) {
      return list.join(', ')
    },
    idCard (client) {
      return formatter.formatValue(client, ['client:idCard'])
    },
    address (client) {
      return formatter.formatValue(client, ['client:address'])
    },
    specialties (doctor) {
      return formatter.formatValue(doctor, ['user:specialties'])
    },
    titles (list) {
      return formatter.formatValue(list, [['list:extractProp', 'title']])
    },
    texts (list = []) {
      return list
        .map((value) => formatter.formatValue(value.text, ['html:evaluateEntities']).trim())
        .filter(Boolean)
        .join(', ')
    },
  },

  mixins: [
    Report,
    HorizontalReport,
    ReportWithTooltips,
  ],

  props: {
    clinic: { type: Object, required: true },
    period: { type: String, default: '' },
    insuranceCompany: { type: Object, required: true },
    company: { type: Object, required: true },
    medicalPolicyType: { type: Object, required: true },
  },

  data () {
    return {
      header: {
        number: '№',
        fullName: T.full_name,
        surname: T.surname,
        firstName: T.name,
        secondName: T.second_name,
        birthday: T.birthday,
        idCard: T.id_card,
        address: T.reports.address,
        card: T.card,
        police: T.police,
        date: T.custom_date,
        orderId: T.order,
        code: T.code,
        service: T.service,
        price: T.price,
        discountedPrice: T.discount_price,
        amount: T.amount,
        sum: T.sum,
        mkb: T.mkb,
        diagnoses: T.diagnoses,
        conclusions: T.conclusions,
        guaranteeLetterNumber: T.finance.guarantee_letter_number_shortly,
        guaranteeLetterDate: T.finance.guarantee_letter_issue_date_shortly,
        doctor: T.doctor,
        specialty: T.specialty,
        doctorCode: T.doctor_code,
        insuranceCompany: T.insurance_company,
        insured: T.insurant,
        teethNumbers: T.teeth_numbers,
      },
      stomatologyEnabled: gon.application.branch === BRANCH_CUSTOM || gon.application.branch === BRANCH_STOMATOLOGY,
      clientTotalLabel: T.client_total,
      totalLabel: T.total,
    }
  },

  computed: {
    subtitleParts () {
      return [
        this.period,
        this.clinic.name,
        this.insuranceCompany.name,
        this.company.name,
        this.medicalPolicyType.name,
      ]
    },
  },

  created () {
    this.reportClient.init({
      url: Routes.report_insurance_companies_path(),
    })
    this.requestBuilder.addStep(() => ReportFiltersFactory.defineFor('insuranceCompanies', this))
  },

  methods: {
    fillTable (result) {
      this.rows = result.data.map((row) => {
        row.firstEntry = row.entries.shift() || {}

        return row
      })

      this.footer = result.total
    },

    afterReportUpdated () {
      this.updateTooltips()
    },
  },

}
</script>
