import { MReportListService } from '@/_api/_requests/MReportListService'
import { getDefaultFilters } from '@/vue_apps/ReportsModule/TaxSertificatesReport/store/getDefaultFilters'
import {
  TaxCertificatesReportPresenter,
} from '@/vue_apps/ReportsModule/TaxSertificatesReport/api/TaxCertificateReportsPresenter'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import {
  taxCertificatesReportAdapter,
} from '@/vue_apps/ReportsModule/TaxSertificatesReport/api/taxCertificatesReportAdapter'

export class TaxCertificatesReport extends MReportListService {
  constructor (sortingMap = {}) {
    super(
      getDefaultFilters(),
      new TaxCertificatesReportPresenter(),
      { sortingMap, limit: DEFAULT_LIMIT }
    )
  }

  async export () {
    return new TaxCertificatesReportPresenter()
      .export(taxCertificatesReportAdapter.toExport(this.filtersData))
  }
}
