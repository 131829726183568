<template>
  <div class="col col__note">
    <m-icon
      v-tooltip="note"
      class="pointer"
      :icon="icon"
      color="primary"
      @click="$emit('click', $event)"
    />
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'AppointmentCallLogNote',
  components: { MIcon },
  props: {
    note: PropsTypes.String(),
  },

  computed: {
    icon () {
      return this.note ? 'noteFilled' : 'noteEmpty'
    },
  },
}
</script>
