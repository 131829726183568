<template>
  <div class="schedule-control-elements">
    <div class="control-element-title">
      {{ t('reception.grid_step') }}
    </div>

    <div class="control-element-buttons control-element-grid-step">
      <items-list
        v-model="duration"
        id-item-field="value"
        displayed-item-field="title"
        :items="appointmentDurationsOptions"
        :selected-items-ids="duration"
        :multi-select="false"
      />
    </div>

    <div class="control-element-title">
      {{ t('reception.days_count') }}
    </div>

    <div class="control-element-buttons control-element-grid-days">
      <items-list
        v-model="daysCount"
        id-item-field="value"
        displayed-item-field="title"
        :items="DAYS_COUNT"
        :selected-items-ids="daysCount"
        :multi-select="false"
      />
    </div>

    <div
      v-if="IS_ACTIVE_SCHEDULE_DOCTORS"
      class="control-element-checkbox hide-users"
    >
      <span>
        <input
          id="hide_not_working_users_in_schedule"
          type="checkbox"
          class="checkbox"
          :checked="GET_SIDEBAR_HIDE_USERS_WITHOUT_SCHEDULE_STATE"
          @change="setHideUsersWithoutSchedule($event.target.checked)"
        >
      </span>
      <span class="control-element-checkbox-label hide-users-label">
        <label for="hide_not_working_users_in_schedule">
          {{ t('reception.hide_not_working_users') }}
        </label>
      </span>
    </div>

    <div
      v-if="IS_ACTIVE_SCHEDULE_CABINETS"
      class="control-element-checkbox hide-cabinets"
    >
      <span>
        <input
          id="hide_not_working_cabinets_in_schedule"
          type="checkbox"
          class="checkbox"
          :checked="GET_SIDEBAR_HIDE_CABINETS_WITHOUT_SCHEDULE_STATE"
          @change="setHideCabinetsWithoutSchedule($event.target.checked)"
        >
      </span>
      <span class="control-element-checkbox-label hide-cabinets-label">
        <label for="hide_not_working_cabinets_in_schedule">
          {{ t('reception.hide_not_working_cabinets') }}
        </label>
      </span>
    </div>

    <div
      v-if="IS_ACTIVE_SCHEDULE_CABINETS"
      class="control-element-checkbox hide-appointments"
    >
      <span>
        <input
          id="hide_appointments_in_schedule"
          type="checkbox"
          class="checkbox"
          :checked="GET_SIDEBAR_HIDE_APPOINTMENTS_SCHEDULE_STATE"
          @change="setHideAppointmentsSchedule($event.target.checked)"
        >
      </span>
      <span class="control-element-checkbox-label hide-appointments-label">
        <label for="hide_appointments_in_schedule">
          {{ t('reception.hide_appointments') }}
        </label>
      </span>
    </div>

    <div
      v-if="IS_ACTIVE_SCHEDULE_DOCTORS"
      class="control-element-checkbox only-working-days"
    >
      <span>
        <input
          id="show_only_working_days_in_schedule"
          type="checkbox"
          class="checkbox"
          :checked="GET_SIDEBAR_SHOW_ONLY_WORKING_DAYS_STATE"
          @change="setShowOnlyWorkingDays($event.target.checked)"
        >
      </span>
      <span class="control-element-checkbox-label only-working-days-label">
        <label for="show_only_working_days_in_schedule">
          {{ t('reception.show_only_working_days_in_schedule') }}
        </label>
      </span>
    </div>

    <div class="control-element-title appointments-filter">
      {{ t('reception.filter_appointments') }}
    </div>

    <div
      class="control-element-checkbox"
    >
      <span>
        <input
          id="show_only_new_patients_in_schedule"
          type="checkbox"
          class="checkbox"
          :checked="GET_SIDEBAR_SHOW_ONLY_NEW_PATIENTS_STATE"
          @change="setShowOnlyNewPatients($event.target.checked)"
        >
      </span>
      <span class="control-element-checkbox-label only-working-days-label">
        <label for="show_only_new_patients_in_schedule">
          {{ t('reception.show_only_new_patients_in_schedule') }}
        </label>
      </span>
    </div>

    <filterable-select
      :value="GET_SIDEBAR_FILTRATION_CLIENT_GROUPS"
      :placeholder="t('groups')"
      multiple
      clearable
      collapse-tags
      @change="setFiltrationClientGroups($event)"
    >
      <el-option
        v-for="group in GET_CLIENT_GROUPS"
        :key="group.id"
        :label="group.title"
        :value="group.id"
      />
    </filterable-select>

    <base-select
      class="small"
      multiple
      clearable
      collapse-tags
      :placeholder="t('status')"
      :value="GET_SIDEBAR_FILTRATION_APPOINTMENTS_STATUSES"
      @change="setFiltrationAppointmentsStatuses($event)"
    >
      <el-option
        v-for="status in APPOINTMENT_STATUS_VALUES"
        :key="status.id"
        :label="status.title"
        :value="status.id"
      />
    </base-select>

    <base-select
      class="small"
      multiple
      clearable
      collapse-tags
      :placeholder="t('appointment_type')"
      :value="GET_SIDEBAR_FILTRATION_APPOINTMENTS_TYPES"
      @change="setFiltrationAppointmentsTypes($event)"
    >
      <el-option
        v-for="type in GET_APPOINTMENT_TYPES"
        :key="type.id"
        :label="type.title"
        :value="type.id"
      />
    </base-select>

    <button
      class="btn btn-warning btn-sm tooltip-bottom reset-filters"
      :title="t('reset_filter')"
      @click="clearAppointmentsFiltration"
    >
      <span class="fas fa-filter-remove" />
    </button>
  </div>
</template>

<script>
import ItemsList from '@/vue_components/items_list.vue'

import { mapActions, mapGetters } from 'vuex'
import { APPOINTMENT_STATUS_VALUES, DAYS_COUNT } from '../consts'
import BaseSelect from '@/vue_components/common/select/base_select/base_select'
import FilterableSelect from '@/vue_components/doctor_schedules/components/filterable-select'

export default {
  components: {
    FilterableSelect,
    BaseSelect,
    ItemsList,
  },

  data () {
    return {
      DAYS_COUNT,
      APPOINTMENT_STATUS_VALUES,
    }
  },

  computed: {
    ...mapGetters([
      'GET_GRID_STEPS',
      'GET_ACTIVE_SCHEDULE',
      'GET_SCHEDULE_DURATION',
      'GET_SCHEDULE_DAYS_COUNT',
      'GET_SIDEBAR_HIDE_USERS_WITHOUT_SCHEDULE_STATE',
      'GET_SIDEBAR_HIDE_CABINETS_WITHOUT_SCHEDULE_STATE',
      'GET_SIDEBAR_HIDE_APPOINTMENTS_SCHEDULE_STATE',
      'GET_SIDEBAR_SHOW_ONLY_WORKING_DAYS_STATE',
      'IS_ACTIVE_SCHEDULE_DOCTORS',
      'IS_ACTIVE_SCHEDULE_CABINETS',
      'GET_SIDEBAR_SHOW_ONLY_NEW_PATIENTS_STATE',
      'GET_APPOINTMENT_TYPES',
      'GET_CLIENT_GROUPS',
      'GET_SIDEBAR_FILTRATION_APPOINTMENTS_STATUSES',
      'GET_SIDEBAR_FILTRATION_APPOINTMENTS_TYPES',
      'GET_SIDEBAR_FILTRATION_CLIENT_GROUPS',
    ]),

    duration: {
      get () {
        return [this.GET_SCHEDULE_DURATION]
      },
      set (value) {
        this.setScheduleDuration(value[0])
      },
    },

    daysCount: {
      get () {
        return [this.GET_SCHEDULE_DAYS_COUNT]
      },
      set (value) {
        this.setScheduleDaysCount(value[0])
      },
    },

    appointmentDurationsOptions () {
      return this.GET_GRID_STEPS.map((value) => ({ title: value, value }))
    },
  },

  methods: {
    ...mapActions([
      'setScheduleDuration',
      'setScheduleDaysCount',
      'setHideUsersWithoutSchedule',
      'setHideCabinetsWithoutSchedule',
      'setHideAppointmentsSchedule',
      'setShowOnlyWorkingDays',
      'setShowOnlyNewPatients',
      'setFiltrationAppointmentsStatuses',
      'setFiltrationAppointmentsTypes',
      'setFiltrationClientGroups',
    ]),

    clearAppointmentsFiltration () {
      this.setShowOnlyNewPatients()
      this.setFiltrationClientGroups()
      this.setFiltrationAppointmentsStatuses()
      this.setFiltrationAppointmentsTypes()
    },
  },
}
</script>
