export default {
  contextReady () {
    if (this.contextReady) {
      this.clearUsersList()
      this.clearSpecialtyList()
      this.clearCabinetsList()

      this.users.forEach((user) => {
        this.allUIDs.push(user.id)
        this.usersMap[user.id] = user
      })

      this.specialties.forEach((specialty) => {
        this.allSIDs.push(specialty.id)
        this.specialtiesMap[specialty.id] = specialty
      })

      this.cabinets.forEach((cabinet) => {
        this.cabinetsMap[cabinet.id] = cabinet
      })

      let state = this._validateStore()

      this.selectedSID = state.selectedSID
      this.showUsersWithoutCabinets = state.showUsersWithoutCabinets
      this.showSpecialtiesList = state.showSpecialtiesList
      this.selectedCIDsInSID = state.selectedCIDsInSID

      this._setVisibleSpecialties()
      this._setAvailableUserIds()
      this._changeCabinetIds()
    }
  },

  filterCabinets () {
    this._setVisibleSpecialties()
    this._setAvailableUserIds()
    this._changeCabinetIds()
  },

  oneVisibleCabinet () {
    this.selectedCIDsInSID[this.selectedSID] = [this.oneVisibleCabinet]
    this._updateStore()
    this._changeCabinetIds()
  },

  tableVisibility (flag) {
    if (flag) {
      this._setVisibleSpecialties()
      this._setAvailableUserIds()
      this._changeCabinetIds()
    }
  },

  availableCabinets () {
    this.visibleCabinets = this.availableCabinets
      .map((cabinetId) => this.cabinetsMap[cabinetId])
      .sort((cabinet1, cabinet2) => cabinet1.title.localeCompare(cabinet2.title))
  },
}
