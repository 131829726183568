import { WORK_TIME } from '../../../const.js'

export default {
  _prepareCreateData () {
    const commonRequestData = { date: this.date, user_id: this.user.id }

    return this.isomorphicTemplates
      .filter((template) => template.id < 0)
      .map((template) => template.getRequestData(commonRequestData))
  },

  _prepareUpdateData () {
    const updateData = []
    const commonRequestData = { date: this.date, user_id: this.user.id }

    this.isomorphicTemplates.forEach((isomorphicTemplate) => {
      if (isomorphicTemplate.id > 0) {
        const dependentWorkTimesChanges = isomorphicTemplate.dependentWorkTimesChanges()
        const scheduleTemplateChanges = isomorphicTemplate.scheduleTemplateChanges()

        if (!dependentWorkTimesChanges && !scheduleTemplateChanges) return

        const requestData = isomorphicTemplate.getRequestData(commonRequestData)

        if (dependentWorkTimesChanges && isomorphicTemplate.intervalToIntervalTransformation()) {
          const workTime = this.workTimes.find((workTime) => workTime.id === isomorphicTemplate.id)
          const template = this.scheduleTemplatesMap[workTime.schedule_template_id].template

          this.requiringConfirmationRequestData.push({
            workTime,
            template,
            requestData,
          })
        } else {
          updateData.push(requestData)
        }
      }
    })

    return updateData
  },

  _removeIsomorphicTemplateById (id) {
    this.isomorphicTemplates = this.isomorphicTemplates.filter((template) => template.id !== id)
  },

  _getDatePickerOptions (template) {
    if (template.template) {
      const closureStartPropagationDate =
        window.moment(template.template.last_propagation_end_date).subtract(1, 'days').toDate()

      return {
        ...(this.defaultDatePickerOptions),
        disabledDate (time) { return time.getTime() <= closureStartPropagationDate },
      }
    }

    return this.defaultDatePickerOptions
  },

  _templateExpired (template) {
    return template && this.scheduleTemplatesMap[template.id].templateExpired
  },

  _showExtendTemplateInput (template) {
    return !template.templateTypeId.includes(WORK_TIME) && (template.templateActive.includes('true') || template.id < 0)
  },
}
