import { REPORT_SOURCE_FILTERS } from '../../_filters/components/ReportSourceFilter/const'
import { REPORT_TYPE_FILTERS } from '../../_filters/components/ReportTypeFilter/const'
import { ISalaryReportFilters } from '../interfaces/ISalaryReportFilters'

const getCurrentUser = () => Services.security.canSalaryReport
  ? null
  : {
      id: gon.application.current_user.id,
      fullName: gon.application.current_user.full_name,
    }

export const getSalaryReportDefaultFiltersValues = (): ISalaryReportFilters => {
  const user = getCurrentUser()

  const userFilterName = user
    ? 'userOnlyCurrentFilter'
    : 'userFilter'

  return {
    clinicFilter: null,
    periodFilter: [
      moment().startOf('month').format(Utils.baseDateFormat),
      moment().endOf('month').format(Utils.baseDateFormat),
    ],
    [userFilterName]: user,
    reportSourceFilter: REPORT_SOURCE_FILTERS()[0],
    includingDiscountsFilter: false,
    entryWithOrderFilter: true,
    reportTypeFilter: REPORT_TYPE_FILTERS()[0],
  }
}
