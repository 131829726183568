
export const mCategoriesAdapter = {
  /**
   *
   * @param {NestedCategories} nestedCategories
   * @returns
   */
  toClient (nestedCategories) {
    return {
      catalogs: nestedCategories.catalogs.map((category) => ({ ...category, children: [] })),
      current: { ...nestedCategories.current, children: [] },
      items: nestedCategories.items.map((item) => ({ ...item, isLeaf: true })),
    }
  },
}
