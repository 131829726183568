<template>
  <div class="form-block">
    <div class="form-block__title">
      {{ title }}
    </div>
    <div class="form-block__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormBlock',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
