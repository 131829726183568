import { createVueApp } from '@/vue_components/create_vue_app'
import MedicalPoliciesApp from '@/vue_apps/ClientsModule/components/MedicalPolices/MedicalPoliciesApp.vue'

let vueApp = null
export const createMedicalPolicesApp = (el = '#vue_medical_polices_root') => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'MedicalPoliciesAppRoot',
    render: (h) => h(MedicalPoliciesApp, {
      props: {
        clientId: +gon.page.params.client_id,
      },
    }),
  })
}
