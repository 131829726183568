$document.on('rez/telephony/itoolabs_configuration/edit', () => {
  const $form = $('#itoolabs_configuration_form')
  const subBtn = $form.find("[type='submit']").eq(0)
  const syncUsersBtn = $('#sync_users_btn')

  syncUsersBtn.on('click', function () {
    $.ajax({
      url: Routes.sync_users_telephony_telephony_route_index_path('itoolabs'),
      method: 'POST',
    })
      .success(() => {
        Notificator.success(t('telephony.users_updated'))
      })
      .error((data) => {
        console.error(data)
        Notificator.error(t('telephony.itoolabs.errors.' + data.responseJSON))
      })
      .complete(() => {
        $(this).loadSpin('stop')
      })
    $(this).loadSpin('start')
  })

  $form.remote_form({
    model: 'itoolabs_configuration',
    onSuccess (res) {
      Notificator.info(t('configuration_updated'))
      subBtn.loadSpin('stop')
    },
    onError (err) {
      console.error(err)
      subBtn.loadSpin('stop')
    },
  })
})
