<template>
  <m-si-generator
    id="report_right_container"
    :class="{
      'hide-doctor': !!report.filtersData.doctor,
      'hide-responsible': !!report.filtersData.responsible,
    }"
    :items="report.data"
    :si-generator-schema="treatmentPlansReportSchema"
    :total="report.total"
    :page-count="report.totalPages"
    :current-page="report.currentPage"
    @update:currentPage="report.fetchPage($event)"
    @onSortChange="onSortChange"
  />
</template>

<script>
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { TreatmentPlansReport } from '@/vue_apps/ReportsModule/TreatmentPlansReports/classes/TreatmentPlansReport'
import {
  treatmentPlansReportSchema,
} from '@/vue_apps/ReportsModule/TreatmentPlansReports/store/treatmentPlansReportSchema'

export default {
  name: 'TreatmentPlansReportsContainer',
  components: { MSiGenerator },
  props: {
    report: TreatmentPlansReport,
  },

  data () {
    return {
      treatmentPlansReportSchema,
    }
  },

  methods: {
    onSortChange (sorting) {
      this.report.setSorting(sorting)
      this.report.fetchAll()
    },
  },
}
</script>
