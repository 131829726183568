import { createVueApp } from '@/vue_components/create_vue_app'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { createStore } from '@/vue_components/store/store'
import { mapGetters } from 'vuex'

let vueApp = null
export const createResponsibleApp = (el = '#treatment_plan_responsible_id') => {
  if (vueApp?.destroy) { return vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'ResponsibleApp',
    components: { ReusableDoctorsList },
    store: createStore(),

    data () {
      return {
        elementId: el.slice(1),
        responsible: gon.specific.treatmentPlanResponsibleUser || null,
      }
    },

    computed: {
      ...mapGetters('globalCatalogs/doctorStore', {
        clinicIds: 'vxGetDoctorClinicsIds',
      }),

      inputHiddenValue () {
        return this.responsible?.id || ''
      },
    },

    template: `
      <div class="responsible-app" :id="elementId">
        <input type="hidden" name="treatment_plan[responsible_id]" :value="inputHiddenValue">
        <ReusableDoctorsList
          class="treatment-plan__responsible"
          v-model="responsible"
          :current-clinic="clinicIds"
          label=""
          @onDoctorChange="responsible = $event"
        />
      </div>
    `,
  })
}
