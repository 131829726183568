import { DateHelpers } from 'src/helpers/DateHelpers'
import { IDoctorsProductivityReportFiltersGet, IDoctorsProductivityReportFiltersSet } from './interfaces'
import { _getDoctorsProductivityReportFilters as getFilters } from './_filtersGetter'

let initialFilters: IDoctorsProductivityReportFiltersGet = null

export const saveDoctorsProductivityReportInitialFilters = (): void => {
  initialFilters = getFilters()
}

export const getDoctorsProductivityReportInitialFilters = (): IDoctorsProductivityReportFiltersSet => ({
  ...initialFilters,
  period: DateHelpers.getCurrentMonthPeriod(),
})
