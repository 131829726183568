import PrintAreaSelector from '@/modules/protocol_entities_editror/libs/print_area_selector'

export const drawPrintAreaSelector = (templateData) => {
  const printAreaItems = JSON.parse(templateData || '""')?.print_area || []
  const editor = document.querySelector('#protokol-content')

  // eslint-disable-next-line no-new
  new PrintAreaSelector({
    items: printAreaItems,
    editorHTMLElement: editor,
  })
}
