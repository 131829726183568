import { REQUEST_KEY } from '@/api_entities/catalogs/appointment_durations/const'

export const durationAdapter = {
  toServer (duration) {
    return {
      [REQUEST_KEY]: {
        duration,
      },
    }
  },
}
