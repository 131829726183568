$document.on('rez/client_groups/new rez/client_groups/edit', function () {
  var form = FormFactory.build('client_group')

  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      break
    case 'edit':
      form.editItem()
      break
  }
})
