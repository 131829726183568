import Vue from 'vue/dist/vue.esm'
import PricesImport from '@/vue_components/EntryTypes/PricesImport/PricesImport'

export const createPricesImportVue = (el) => {
  if (!document.querySelector(el)) { return }

  // eslint-disable-next-line no-new
  new Vue({
    el,
    name: 'PriceImport',
    render: (h) => h(PricesImport),
  })
}
