import { stealClientIp } from '@/specific/sessions/sessions_helpers'

$document.on('rez/users/sessions', function () {
  stealClientIp()

  var $changeBtn = $('#enter_username_manually_btn')
  if ($changeBtn.length === 0) return

  var $inputUsername = $('input#user_username')
  var $inputPassword = $('input#user_password')

  var $selectUsername = $('select#user_list')
  var selectUsernameContainer = $('.select-username-container')

  $selectUsername.select2({ width: '250px', 'language': gon.localization.locale })

  clearFields()

  $selectUsername.on('change', () => {
    $inputUsername.val($selectUsername.val())
  })

  // whats a crappy plugin
  $('#switch-on').add('#switch-off').on('click', function () {
    selectUsernameContainer.toggle()
    $inputUsername.toggle()
    clearFields()
  })

  function clearFields () {
    $selectUsername.select2('val', '')
    $inputUsername.val('')
    $inputPassword.val('')
  }
})
