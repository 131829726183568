import { addEntryType } from '@/specific/entry_types_helpers/addEntryType'
import { GLOBAL_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'

export const orderAddEntryTypeCallback = async (entryType) => {
  const item = await addEntryType(entryType)
  if (item.errors) { return }

  PubSub.emit(
    'page.form.order.orderEntryList.askAddEntry',
    { item }
  )

  setTimeout(() => {
    Utils.reportSuccess(t('entryAdded'))()
  }, GLOBAL_DEBOUNCE_DEFAULT)
}
