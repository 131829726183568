<template>
  <span
    class="asterisk"
    :data-tooltip="text"
  >
    *
  </span>
</template>

<script>
export default {
  name: 'Footnote',
  props: {
    text: {
      required: true,
      type: String,
    },
  },
}
</script>

<style scoped>

</style>
