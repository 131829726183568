<template>
  <div style="position: relative">
    <epic-spinner :visibility="showLoadingSpinner" />
    <div
      class="el-card__body__header"
    >
      <div class="el-card__body__header__child">
        <div class="el-card__body__header__indicator">
          {{ today }}
        </div>
        <div class="el-card__body__header__explanation">
          {{ T.head_desktop.today }}
        </div>
      </div>
      <div class="el-card__body__header__child">
        <div class="el-card__body__header__indicator">
          {{ currentMonth }}
        </div>
        <div class="el-card__body__header__explanation">
          {{ T.head_desktop.current_month }}
        </div>
      </div>
      <div class="el-card__body__header__child">
        <div class="el-card__body__header__indicator">
          {{ averageMonth }}
        </div>
        <div class="el-card__body__header__explanation">
          {{ T.head_desktop.average }}
        </div>
      </div>
    </div>
    <div class="el-card__body__content">
      <div id="average-check-container" />
    </div>
    <div class="el-card__body__footer">
      <div class="el-card__body__footer__filters">
        <clinic-selector :card-name="cardName" />
      </div>
      <div class="el-card__body__footer__actions" />
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import EpicSpinner from '../../../epic_spinner/epic_spinner.vue'
import ClinicSelector from '../selectors/clinic_selector.vue'
import Card from './card'
import CardWithChart from './cardWithChart'

export default {
  components: { EpicSpinner, ClinicSelector },
  mixins: [Card, CardWithChart],
  data () {
    return {
      route: this.$routes.reports_create_head_desktop_average_check_path(),
      today: 0,
      currentMonth: 0,
      averageMonth: 0,
    }
  },
  computed: {
    requestParams () {
      return {
        clinic_id: this.cardParams.clinic_id,
        date_start: this.cardParams.date_range[0],
        date_end: this.cardParams.date_range[1],
      }
    },
  },
  methods: {
    cardAdapter (result) {
      this.chartData = result.average_check
      this.today = result.today
      this.currentMonth = result.current_month
      this.averageMonth = result.average_month
    },
    renderChart () {
      Highcharts.chart('average-check-container', {
        chart: {
          type: 'area',
          height: '240px',
        },
        series: [{
          name: this.T.head_desktop.average_check,
          color: '#9C27B0',
          data: this.chartData,
        }],
        title: false,
        legend: {
          enabled: false,
        },
        xAxis: {
          visible: false,
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        tooltip: {
          pointFormat: this.T.head_desktop.average_total_bill_sum +
            ': <b>{point.y:.0f}</b>' +
            gon.localization.currency.symbol_character,
        },
      })
    },
  },
}
</script>
