/**
 * Трансформирует ключи всех объектов передаваемой сущности, в том числе ключи
 * сущности-объекта (ключи начинающиеся с '_' пропускают преобразование).
 * @param {Function} transformer возвращает строку с трансформированным ключом \
 * (напр: snakeCase, camelCase из lodash)
 * @param {[Object, Array]} entity сущность, по ключам объектов которой
 * идет трансформация
 * @returns {[Object, Array]} возвращает копию передаваемой сущности с
 * трансформированными ключами
 */
export const transformKeys = (transformer) => (entity) => {
  if (Array.isArray(entity)) {
    return entity.map((item) => transformKeys(transformer)(item))
  } else if (typeof entity === 'object' && entity !== null) {
    return transformObjectKeys(entity, transformer)
  }

  return entity
}

function transformObjectKeys (object, transformer) {
  const newObject = {}

  for (const key in object) {
    const newKey = key.startsWith('_') ? key : transformer(key)

    newObject[newKey] = transformKeys(transformer)(object[key])
  }

  return newObject
}
