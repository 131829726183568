
export const header = {
  number: {
    text: '№',
    sortable: 'paymentId',
  },

  payer: {
    text: t('payer'),
    sortable: 'paymentPayer',
  },

  paymentKind: {
    text: t('type'),
    sortable: 'paymentKind',
  },

  date: {
    text: t('custom_date'),
    sortable: 'paymentDate',
  },

  byCash: {
    text: t('by_cash_short'),
    sortable: 'paymentByCash',
  },

  byCashless: {
    text: t('by_cashless_short'),
    sortable: 'paymentByCashless',
  },

  byCard: {
    text: t('by_card'),
    sortable: 'paymentByCard',
  },

  byBalance: {
    text: t('by_balance_short'),
    sortable: 'paymentByBalance',
  },

  byCredit: {
    text: t('by_credit'),
    sortable: 'paymentByCredit',
  },

  income: {
    text: t('made_payment'),
    sortable: 'paymentTotalIncome',
  },

  paid: {
    text: t('payment'),
    sortable: 'paymentTotalPaid',
  },

  order: {
    text: t('order'),
    sortable: 'destinationOrderFinalSum',
  },

  chequePrinted: {
    text: t('receipt'),
    sortable: 'chequePrinted',
  },

  user: {
    text: t('doctor'),
    sortable: 'paymentByDoctor',
  },
}

export const footnotes = {
  total: t('reports.with_refunds'),
  income: t('reports.paid_by_money_without_cash_in'),
  paid: t('reports.paid_by_all_payment_kinds_without_credit'),
  order: t('reports.to_pay_by_order'),
  finalSum: t('this_sum_cannot_be_calculated_correctly'),
}
