import { createVueApp } from '@/vue_components/create_vue_app'
import ClientsList from '@/vue_apps/ClientsModule/components/ClientsList/ClientsList.vue'

let vueApp = null
export const createClientsListApp = () => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el: '#vue_clients_app_root',
    name: 'ClientsListApp',
    render: (h) => h(ClientsList),
  })
}
