<template>
  <modal
    v-if="showRemoveWorkTimeModal"
    :modal-class="'remove-work-time-modal'"
    :modal-visibility="showRemoveWorkTimeModal"
    @close="$emit('removeNone')"
  >
    <span slot="header">{{ T.remove_interval }}</span>
    <template slot="body">
      <span>{{ T.remove_all_interval_occurrences }}</span>
      <span class="modal-time">{{ startTime }}</span>
      <span> — </span>
      <span class="modal-time">{{ endTime }}</span>
      <span>{{ T.with }}</span>
      <span class="modal-time">{{ formattedDate }}</span>
      <span>?</span>
    </template>
    <remove-work-time-modal-footer
      slot="footer-right"
      :beginning-of-the-interval="beginningOfTheInterval"
      @close="$emit('removeNone')"
      @deleteThisDayOnly="$emit('removeOneWorkTime')"
      @removeFromThisDay="$emit('removeAllFromCurrentDate')"
      @deleteAll="$emit('removeAll')"
    />
  </modal>
</template>

<script>
import Modal from '../../../modal.vue'
import RemoveWorkTimeModalFooter from './remove_work_time_modal_footer.vue'

export default {
  name: 'RemoveWorkTimeModal',
  components: {
    Modal,
    RemoveWorkTimeModalFooter,
  },
  props: {
    showRemoveWorkTimeModal: Boolean,
    startTime: String,
    endTime: String,
    startDate: String,
    date: String,
    formattedDate: String,
  },
  computed: {
    beginningOfTheInterval () {
      return this.date === this.startDate
    },
  },
}
</script>
