export const REFERRALS_UNGROUPED = 'referrals'
export const REFERRALS_GROUPED = 'referrals_grouped_by_entry_type'
export const REFERRALS_UNGROUPED_REFERRAL = 'referrals_single'
export const REFERRALS_GROUPED_REFERRAL = 'referrals_grouped_by_entry_type_single'
export const REFERRALS_SUMMARY = 'referrals_summary'

/**
 * @typedef {{
 *  entry: (ReportParam|ReportParam[]),
 *  period: (string|undefined),
 *  referral: (ReportParam|ReportParam[]),
 *  groupByEntry: ReportParam,
 *  medicalUser: (ReportParam|ReportParam[]),
 *  city: (ReportParam|ReportParam[]),
 *  clinic: (ReportParam|ReportParam[]),
 *  category: (ReportParam|ReportParam[]),
 *  reportTypeFilter: (string)
 * }} ReferralsReportFilters
 */
/**
 * @param {ReferralsReportFilters} filters
 * @return {string}
 */
export const getReportType = (filters) => {
  if (filters.reportTypeFilter === REFERRALS_SUMMARY) {
    return REFERRALS_SUMMARY
  }

  if (filters.groupByEntry.id) {
    return filters.referral.id
      ? REFERRALS_GROUPED_REFERRAL
      : REFERRALS_GROUPED
  }

  return filters.referral.id
    ? REFERRALS_UNGROUPED_REFERRAL
    : REFERRALS_UNGROUPED
}

/**
 * Возвращает хедер для экспорта, основываясь на типе отчёта.
 * Хедер в отчёте на странице рендерится независимо, чтобы не пихать сюда лишние данные.
 * Тут могут возникать расходящиеся изменения, так что при изменении хедера здесь,
 * нужно всегда править хедер в соответсвующем компоненте.
 *
 * @param {string} type
 * @return {Record<string, string>}
 */
export const getHeader = (type) => {
  switch (type) {
    case REFERRALS_UNGROUPED: {
      return {
        referralFullName: t('referral'),
        address: t('city'),
        company: t('company'),
        entryTitle: t('title'),
        entryComment: t('comment'),
        clientFullName: t('client'),
        entryDate: t('custom_date'),
        entryPrice: t('price'),
        entryFinalPrice: t('reports.priceWithDiscount'),
        entryAmount: t('amount'),
        entryFinalSum: t('sum'),
        rate: t('reports.reward'),
        rewardFinalSum: t('reports.rewardSum'),
      }
    }
    case REFERRALS_GROUPED: {
      return {
        referralFullName: t('referral'),
        address: t('city'),
        company: t('company'),
        entryTitle: t('title'),
        entryAmount: t('amount'),
        entryFinalSum: t('sum'),
        rewardFinalSum: t('reports.rewardSum'),
      }
    }
    case REFERRALS_UNGROUPED_REFERRAL: {
      return {
        entryTitle: t('title'),
        entryComment: t('comment'),
        clientFullName: t('client'),
        entryDate: t('custom_date'),
        entryPrice: t('price'),
        entryFinalPrice: t('reports.priceWithDiscount'),
        entryAmount: t('amount'),
        entryFinalSum: t('sum'),
        rate: t('reports.reward'),
        rewardFinalSum: t('reports.rewardSum'),
      }
    }
    case REFERRALS_GROUPED_REFERRAL: {
      return {
        entryTitle: t('title'),
        entryAmount: t('amount'),
        entryFinalSum: t('sum'),
        rewardFinalSum: t('reports.rewardSum'),
      }
    }

    case REFERRALS_SUMMARY: {
      return {
        referralFullName: t('referrals'),
        goodsReward: t('goods'),
        analysisReward: t('analyzes'),
        servicesReward: t('services'),
        totalReward: t('reports.total'),
      }
    }
  }
}
