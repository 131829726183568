import Vue from 'vue/dist/vue.esm'
import CompanyTypesCatalog from './company_types'

export const VueCompanyTypesEntryPoint = () => {
  const el = '#vue_company_types_catalog'

  if (!document.querySelector(el)) { return }
  // eslint-disable-next-line no-new
  new Vue({
    el,
    render: (h) => h(CompanyTypesCatalog),
  })
}
