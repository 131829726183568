import { addEntryPack } from '@/specific/entry_types_helpers/addEntryPack'

/**
 * @param {IEntryPackEntryType[]} entryPackEntryTypes
 * @return {Promise<void>}
 */
export const tpAddEntryPackCallback = async (entryPackEntryTypes) => {
  const entryTypes = await addEntryPack(entryPackEntryTypes)
  if (!entryTypes?.length) { return }

  PubSub.emit('page.form.treatment_plan.treatmentPlanList.askAddEntries', {
    entryTypes,
    onComplete () {
      Utils.reportSuccess(t('entryPacks.successAdd'))()
    },
  })
}
