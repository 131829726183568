import { createVueApp } from '@/vue_components/create_vue_app'
import OrderPoliciesWithGuaranteeLetters
  from '@/vue_apps/FinanceModule/OrderPoliciesWithGuaranteeLetters/OrderPoliciesWithGuaranteeLetters.vue'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { buildGuaranteeLetterTitle } from '@/_api/MClient/MMedicalPoliceApi/MGuaranteeLetterApi/guaranteeLettersAdapter'

let vueApp = null

export const createOrderPoliciesWithGuaranteeLetters = ({
  el = '#vue_order_policies_with_guarantee_letters_root',
  clientId,
}) => {
  if (vueApp?.destroy) { vueApp.destroy() }

  const letter = snakeToCamel(gon.specific.financeGuaranteeLetter)
  const orderFinanceGuaranteeLetter = letter
    ? {
        ...letter,
        sum: Utils.toMoney(letter.sum),
        title: buildGuaranteeLetterTitle(letter),
      }
    : null

  vueApp = createVueApp({
    el,
    name: 'OrderPoliciesWithGuaranteeLetters',
    render: (h) => h(OrderPoliciesWithGuaranteeLetters, {
      props: {
        clientId,
        orderMedicalPolicy: snakeToCamel(gon.specific.medicalPolicy),
        orderFinanceGuaranteeLetter,
      },
    }),
  })
}
