<template>
  <div :class="`col-container catalog-container ${customMainContainerClass}`">
    <div :class="tableContainerClass">
      <panel-heading
        :icon="catalogIcon"
        :title="tableTitle + readOnlyText"
      >
        <si
          v-loading="loading"
          :class="`catalogs_table ${customTableClass}`"
        >
          <si-control>
            <slot name="custom-control">
              <guarded-control
                tag="button"
                :permissions="permissionRules"
                :tooltip="addItemText"
                class="btn btn-with-icon btn-success hidden-print catalogs_table__add-btn"
                @click:allowed="$emit('ask-create')"
              >
                <span :class="'btn-with-icon_icon ' + catalogIcon" />

                <span class="fas fa-fw fa-plus" />
              </guarded-control>
            </slot>

            <template #reset>
              <span class="empty-slot" />
            </template>

            <template #refresh>
              <span class="empty-slot" />
            </template>
          </si-control>

          <not-found-result v-if="firstLoadingCompleted && !items.length" />

          <slot
            v-else
            name="table"
          />

          <si-footer v-if="isPaginationEnabled && items.length">
            <pagination
              layout="prev, pager, next"
              :page-count="totalPages"
              @current-change="$emit('change-pagination', $event)"
            />
          </si-footer>
        </si>
      </panel-heading>
    </div>

    <div
      v-if="isShowForm"
      :class="formContainerClass"
    >
      <panel-heading
        :class="`catalogs-form ${customFormClass}`"
        :custom-class="isCreateMode ? 'panel-green' : 'panel-orange'"
        :icon="catalogIcon"
        :title="formTitle + readOnlyText"
      >
        <template #buttons>
          <span
            class="panel_close_button fad fa-fw fa-times"
            @click="$emit('close')"
          />
        </template>

        <div>
          <slot name="form" />

          <div
            v-if="isShowFormBtns"
            class="catalogs-form__btns"
          >
            <slot name="submit-btn">
              <guarded-control
                tag="button"
                class="btn btn-primary form-add-btn hidden-print btn-sm btn-with-icon"
                :class="isCreateMode ? 'btn-success' : 'btn-warning'"
                :permissions="permissionRules"
                @click:allowed="$emit('submit')"
              >
                <span class="btn-with-icon_icon fad fa-save" />
                <span class="btn-with-icon_text">{{ t('save') }}</span>
              </guarded-control>
            </slot>

            <basic-tooltip
              v-if="!isCreateMode && !disabled && canDelete"
              placement="left"
              :text="t('delete')"
            >
              <popover @yes="$emit('ask-delete')">
                <template #reference>
                  <span
                    class="fad fa-fw fa-trash btn-show-delete hidden-print red"
                  />
                </template>
              </popover>
            </basic-tooltip>
          </div>
        </div>
      </panel-heading>
    </div>

    <slot name="extra" />
  </div>
</template>

<script>
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiControl from '@/vue_components/sort_items/si_control.vue'
import GuardedControl from '@/vue_components/common/guarded_control'
import SiFooter from '@/vue_components/sort_items/si_footer'
import Pagination from '@/vue_components/common/pagination'
import Popover from '@/vue_components/common/popover/popover'
import BasicTooltip from '@/vue_components/common/basic_tooltip'
import NotFoundResult from '@/vue_components/common/not_found_result'
import { MODES } from '../const'

/**
 * @deprecated
 */
export default {
  name: 'CatalogWrapperDeprecated',
  components: {
    PanelHeading,
    Si,
    SiControl,
    SiFooter,
    GuardedControl,
    Pagination,
    NotFoundResult,
    Popover,
    BasicTooltip,
  },

  props: {
    model: {
      type: String,
      default: '',
    },

    tableContainerClass: {
      type: String,
      default: 'col-item-45',
    },

    formContainerClass: {
      type: String,
      default: 'col-item-55',
    },

    customMainContainerClass: {
      type: String,
      default: '',
    },

    customTableClass: {
      type: String,
      default: '',
    },

    customFormClass: {
      type: String,
      default: '',
    },

    catalogIcon: {
      type: String,
      default: '',
    },

    tableTitle: {
      type: String,
      default: '',
    },

    formCreateTitle: {
      type: String,
      default: '',
    },

    formEditTitle: {
      type: String,
      default: '',
    },

    addItemText: {
      type: String,
      default: '',
    },

    items: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: true,
    },

    selectedMode: {
      type: String,
      default: MODES.NEW,
    },

    isShowForm: Boolean,

    firstLoadingCompleted: Boolean,

    isPaginationEnabled: {
      type: Boolean,
      default: true,
    },

    totalPages: {
      type: Number,
      default: 1,
    },

    isShowFormBtns: {
      type: Boolean,
      default: true,
    },

    canDelete: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    readOnlyText () {
      return !this.disabled
        ? ''
        : ` (${t('read_only')})`
    },

    isCreateMode () {
      return this.selectedMode === MODES.NEW
    },

    formTitle () {
      return this.isCreateMode
        ? this.formCreateTitle
        : this.formEditTitle
    },

    disabled () {
      return this.model
        ? !this.$security[`canManage${this.model}`]
        : false
    },

    permissionRules () {
      return this.model
        ? [`canManage${this.model}`]
        : []
    },
  },
}
</script>
