$document.on('rez/calls/index', function () {
  Utils.initCalendar()
  const callForm = FormFactory.build('call')
  let ShowRefuseModalAgain = false

  // Сортировка звонков
  SortItems.init({
    itemName: ['calls'],
    url: '/calls',
    defaults: { period: 'today' },
  })

  CallModule.init()

  $('#calls_table').on('click', '.fa-phone-rotary', function () {
    CallModule.show($(this).closest('tr').data('id'))
  })

  $('#calls_table').on('click', '.show-result-modal', function () {
    const id = $(this).closest('tr').data('id')
    ShowRefuseModalAgain = true
    openCallResultForm(id)
  })

  $('#calls_table').on('click', '.show-refuse-result-modal', function () {
    const id = $(this).closest('tr').data('id')
    ShowRefuseModalAgain = true
    openCallResultForm(id)
  })

  $('#calls_table').on('click', '.client_name', function () {
    const id = $(this).data('client-id')
    window.open(Routes.client_path(id), '_blank')
  })

  PubSub.on('page.specific.sort_items.finish', function () {
    $('.call_record_player_show').popover({
      trigger: 'manual',
      toggle: 'popover',
      html: true,
      content: '<audio controls=true preload=auto autoplay download="call_record.mp3"></audio>',
      placement: 'auto',
    })
    Utils.updateTooltips(document.querySelector('.call_record_player_show'))
    recordBtnEvents()
  })

  function openCallResultForm (callId) {
    $.ajax(Routes.call_path(callId), {
      success (data) {
        callForm.editItem(data)
        $('#call_refuse_result_from_modal').val('false').change()
        $('#modal_call_result').find('.modal-buttons').show()
      },
      error (err) {
        console.log(err)
      },
    })
  }

  // Services.wsSubscriptions.calls.connect(msg => {
  //   //refresh call list
  //   SortItems.refresh()

  //   //open call result modal window
  //   let call = msg.data
  //   let id = $('#call_result_form')[0].action.split('/').slice(-1)[0]
  //   let isFormVisible = $('#call_result_form').is(':visible')

  //   if (call['id'] == id && isFormVisible && ShowRefuseModalAgain) {
  //     openCallResultForm(id)
  //   }
  // })

  PubSub.on('page.form.call.modal.clickSave', (msg, data) => {
    ShowRefuseModalAgain = false
  })

  function recordBtnEvents () {
    $('tbody').on('scroll', function () {
      if ($('.popover').length > 0) {
        const popover = $('.popover')
        const sender = $('.call_record_player_show.active')
        const adjustment = (sender.position().top - popover.height()) + 58
        popover.css({ top: adjustment })
      }
    })

    $('.call_record_player_show').on('show.bs.popover', function () {
      hidePopovers(this)
    })
    $('.call_record_player_show').on('shown.bs.popover', function () {
      $(this).addClass('active')
      $(this).parents('tr').addClass('selected')
    })
    $('.call_record_player_show').on('hidden.bs.popover', function () {
      $(this).removeClass('active')
      $(this).parents('tr').removeClass('selected')
    })
    $('.call_record_player_show').on('click', function (e) {
      e.stopImmediatePropagation()
      const fileLink = $(this).data('file-link')

      const mangoId = fileLink.split('!')
      // if provider is mango fileLink is somthing like mango!MToxMDEzNDgzNTo5MDQ0Njg2NDc2OjA=
      // split fileLink and get record id stored in mangoId[1]
      if (mangoId[0] === 'mango') {
        getMangoRecordUrl(mangoId[1], this)
      } else {
        // for all other providers
        $(this).popover('toggle')
        $(this.nextSibling).find('audio').html("<source src='" + fileLink + "' type='audio/mpeg'></source>")
      }
    })
  }

  function getMangoRecordUrl (recordId, el) {
    $.ajax({
      url: Routes.call_record_get_telephony_telephony_route_index_path('mango', { record_id: recordId }),
      method: 'POST',
      beforeSend () {
        $(el).popover('toggle')
        $('.popover-content').loadSpin('start')
      },
      success (data) {
      // mp3 file load from mango temp link and insert in player like blob
        const xhr = new XMLHttpRequest()
        xhr.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            const audio = window.URL.createObjectURL(this.response)
            $(el.nextSibling).find('audio').html("<source src='" + audio + "' type='audio/mpeg' preload='auto'></source>")
            $('.popover-content').loadSpin('stop')
          }
        }
        xhr.open('GET', data.url)
        xhr.responseType = 'blob'
        xhr.send()
      },
      error (data) {
        console.error(data)
        $(el).popover('toggle')
        $('.popover-content').loadSpin('stop')
        Notificator.error(t('telephony.mango.errors.' + data.responseJSON))
      },
    })
  }

  function hidePopovers (filterElement = null) {
    $('.call_record_player_show').not(filterElement).popover('hide')
    $('.call_record_player_show').removeClass('active')
    $('.call_row').removeClass('selected')
  }
})
