<template>
  <m-panel
    class="medical-polices"
    icon="medicalPolices"
    :title="modalTitle"
  >
    <m-si-generator
      :items="list.data"
      :current-page="list.currentPage"
      :page-count="list.totalPages"
      :si-generator-schema="schema"
      @update:currentPage="list.fetchPage($event)"
      @onItemClick="onItemClick"
    >
      <template #filters>
        <medical-polices-editor
          :client-id="clientId"
          @registerOpenModal="openModal = $event"
          @updateList="list.fetchPage(1)"
        />
      </template>
    </m-si-generator>
  </m-panel>
</template>

<script>
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { MListService } from '@/_api/_requests/MListService'
import {
  getMedicalPolicesSchema,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/const/getMedicalPolicesSchema'
import {
  MedicalPolicyListItem,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/entities/list/MedicalPolicyListItem'
import MedicalPolicesEditor
  from '@/vue_apps/ClientsModule/components/MedicalPolices/components/MedicalPoliciesEditor.vue'
import { MMedicalPolicesPresenter } from '@/_api/MClient/MMedicalPoliceApi/MMedicalPolicesPresenter'

export default defineComponent({
  name: 'MedicalPoliciesApp',
  components: {
    MedicalPolicesEditor,
    MSiGenerator,
    MPanel,
  },

  props: {
    clientId: { type: Number, required: true },
  },

  data () {
    return {
      schema: getMedicalPolicesSchema(),
      list: new MListService(
        { clientId: this.clientId },
        new MMedicalPolicesPresenter(),
        { listItemEntity: MedicalPolicyListItem }
      ),

      openModal () {},
    }
  },

  computed: {
    modalTitle () {
      return t('client_card') + ' - ' + t('medical_policies')
    },
  },

  created () {
    setTimeout(() => {
      this.list.fetchAll()
    })
  },

  methods: {
    onItemClick ({ id }) {
      this.openModal(id)
    },
  },
})
</script>
