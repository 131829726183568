<template>
  <div class="entry-types__list-item">
    <div
      class="list-item__title cut flex-grow-1"
    >
      <basic-tooltip
        v-if="entryType.additional || entryType.tip"
        :text="tooltipTipsEntryType(entryType)"
      >
        <span class="fad fa-info-circle more-info-company" />
      </basic-tooltip>
      <basic-tooltip :text="tooltipEntryType(entryType)">
        <span>{{ entryType.title }}</span>
      </basic-tooltip>
    </div>
    <span
      v-if="!readonly"
      class="list-item__remove fad fa-fw fa-times"
      @click="$emit('entryRemove', entryType.id)"
    />
  </div>
</template>

<script>
import { currencyFormatter } from '@/vue_components/mixins/formatters.js'
import BasicTooltip from '@/vue_components/common/basic_tooltip'

export default {
  name: 'EntryTypeListItem',
  components: { BasicTooltip },
  mixins: [currencyFormatter],
  props: {
    entryType: {
      type: Object,
      required: true,
    },

    readonly: Boolean,
  },

  computed: {
    GET_LOCALIZATION_CURRENCY_PARAMS () {
      return this.$store.getters.GET_LOCALIZATION_CURRENCY_PARAMS
    },
  },

  methods: {
    tooltipEntryType (entryType) {
      return entryType.title + ' ' + this.$options.filters.formatNumberToFixed(entryType.price, this.GET_LOCALIZATION_CURRENCY_PARAMS)
    },

    tooltipTipsEntryType (entryType) {
      const additional = entryType.additional ? `<span>${entryType.additional}.</span>` : ''
      const tip = entryType.tip ? `<span>${entryType.tip}.</span>` : ''

      return additional + tip
    },
  },
}
</script>
