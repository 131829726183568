export const EXPENSES_NO_GROUPING = 'expenses'
export const GROUP_EXPENSES_BY_TITLE = 'expenses/group_by_title'
export const GROUP_EXPENSES_BY_EXPENSE_GROUP = 'expenses/group_by_expense_group'

/**
 * Returns appropriate header object
 *
 * @param {string} type
 * @return {object}
 */
export const getHeader = (type) => {
  switch (type) {
    case GROUP_EXPENSES_BY_EXPENSE_GROUP: {
      return {
        expenseGroupTitle: T.expense_group,
        expenseAmount: T.amount,
        expensePrice: T.sum,
      }
    }
    case GROUP_EXPENSES_BY_TITLE: {
      return {
        expenseTitle: T.title,
        expenseAmount: T.amount,
        expensePrice: T.sum,
      }
    }
    default: {
      return {
        expenseId: '№',
        expenseDate: T.custom_date,
        expenseTitle: T.title,
        expensePrice: T.sum,
        expenseAdditional: T.additional,
        expenseGroupTitle: T.expense_group,
      }
    }
  }
}
