import { createVueApp } from '@/vue_components/create_vue_app'
import EntryTypesBreadcrumbs from '@/vue_components/EntryTypes/Breadcrumbs/EntryTypesBreadcrumbs'

export const createEntryTypesBreadcrumbs = (el, { id }) => {
  return createVueApp({
    el,
    name: 'EntryTypesBreadcrumbsRoot' + el,
    render: (h) => h(EntryTypesBreadcrumbs, {
      props: {
        id: el.slice(1),
        entryTypeId: id,
      },
    }),
  })
}
