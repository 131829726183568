/**
 * @typedef {{id?: number, name?: string}} NamedEntity
 */

/**
 * Creates a named param or an array of such
 *
 * @param {String} selector id of the select element
 * @param {Boolean} [isArray=false] is it a multiple choice select
 * @return {NamedEntity|NamedEntity[]}
 */
export function defineNamedParam (selector, isArray = false) {
  let param
  if (isArray) {
    param = []
    $(selector).find('option:selected')
      .each((i, item) => {
        const $item = $(item)
        param.push({
          id: $item.attr('value'),
          name: $item.text(),
        })
      })
  } else {
    param = {}
    // use string ids because some values are not numbers
    param.id = $(selector).val()
    if (param.id) {
      param.name = $(selector).find('option:selected').text()
    }
  }

  return param
}

/**
 * @typedef {{id?: string, name?: string}} ReportParam
 */

/**
 * @typedef {{id: string, name: string}} ReportParamStrict
 */

/**
 * @typedef {{id: boolean, name?: string}} BoolReportParam
 */

export class ReportParamFactory {
  /**
   *
   * @param {string} [id]
   * @param {string} [name]
   * @return {ReportParam}
   * @private
   */
  static _createParam (id, name) {
    return { id, name }
  }

  /**
   *
   * @param {ReportParam} param
   * @return {BoolReportParam}
   */
  static toBooleanParam (param) {
    return {
      id: !!(param.id && param.id === 'true'),
      name: param.name,
    }
  }

  static getCheckboxParam (selector) {
    const $input = $(selector)
    const id = $input.is(':checked') ? 'true' : ''
    const name = $(`label[for="${$input.attr('id')}"]`).text()

    return ReportParamFactory._createParam(id, name)
  }

  /**
   *
   * @param {HTMLOptionElement} option
   * @return {ReportParam}
   * @private
   */
  static _getSelectOption (option) {
    let name = option.textContent
    if (!name) {
      name = option.parentElement.dataset.placeholder
    }

    return ReportParamFactory._createParam(option.value, name)
  }

  /**
   *
   * @param {string} selector
   * @param {string} [defaultValue]
   * @return {ReportParam | ReportParam[]}
   */
  static getSelectParam (selector, defaultValue) {
    const $select = $(selector)
    if ($select.attr('multiple')) {
      const params = []
      $select.find('option:selected')
        .each((i, option) => {
          params.push(ReportParamFactory._getSelectOption(option))
        })
      if (!params.length) {
        params.push(ReportParamFactory._getDefaultOption($select))
      }

      return params
    }

    const option = $select.find('option:selected').get(0)
    if (option) {
      return ReportParamFactory._getSelectOption(option)
    }

    return ReportParamFactory._getDefaultOption($select, defaultValue)
  }

  static getSelect2Params (selector, search = false) {
    const param = ReportParamFactory.getSelectParam(selector)

    let option
    if (Array.isArray(param) && param.length === 1) {
      // problem with the name not showing is only occurring if there is one default option selected
      option = param[0]
    } else {
      option = param
    }

    // there will be no name if nothing was selected
    if (!option.name) {
      const $select = $(selector)
      if (search) {
        option.name = ReportParamFactory._getSearchFieldName($select)
      } else {
        option.name = ReportParamFactory._getSelectFieldName($select)
      }
    }

    return param
  }

  /**
   *
   * @param {String} selector
   * @return {ReportParam}
   */
  static getButtonsParam (selector) {
    return ReportParamFactory.getSelectParam(selector)
  }

  /**
   *
   * @param {String} selector
   * @return {string | undefined}
   */
  static getRawParam (selector) {
    return $(selector).val()
  }

  /**
   *
   * @param $select
   * @param defaultValue
   * @return {ReportParam}
   * @private
   */
  static _getDefaultOption ($select, defaultValue = '') {
    return ReportParamFactory._createParam(defaultValue, ReportParamFactory.getSelectName($select))
  }

  /**
   * @param $select
   * @return {string}
   * @private
   */
  static _getSearchFieldName ($select) {
    return $select.parent().find('.select2-search__field').attr('placeholder')
  }

  /**
   * @param $select
   * @return {string}
   * @private
   */
  static _getSelectFieldName ($select) {
    return $select.parent().find('.select2-selection__placeholder').text()
  }

  /**
   * @param $control
   * @return {string}
   * @private
   */
  static _getDefaultGenericFieldName ($control) {
    return $control.get(0).dataset.placeholder ||
    $control.attr('name').match(/\[(.*)]/)[0]
  }

  static getSelectName ($select) {
    return $select.parent().find('.select2-search__field').attr('placeholder') ||
      $select.parent().find('.select2-selection__placeholder').text() ||
      $select.get(0).dataset.placeholder ||
      $select.attr('name').match(/\[(.*)]/)[0]
  }

  /**
   * Creates a param for backend from both arrays and objects
   *
   * @param {object | object[]} [source]
   * @return {Array | undefined}
   */
  static buildArrayParam (source) {
    if (!source) {
      return
    }

    let arr = source

    if (!Array.isArray(arr)) {
      arr = [source.id]
    } else {
      arr = arr.map((item) => item.id)
    }

    return arr.filter(Boolean)
  }
}
