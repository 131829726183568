export default {
  checkboxOnClick (event) {
    const elementId = (event.target.tagName === 'INPUT' ? event.target.parentNode : event.target).id
    this.updateCheckboxesState(elementId)
  },

  dateCellOnClick (event) {
    const date = event.currentTarget.attributes['data-date'].value
    const newDate = window.moment(date).subtract(3, 'days').toDate()

    if (newDate === this.date) return

    this.$emit('changeDatePickerDate', newDate)
  },

  _userCellOnClick (event) {
    const date = event.currentTarget.attributes['data-date'].value
    const sidCidUid = event.currentTarget.attributes['data-sid-cid-uid'].value.split('_')
    const specialtyId = sidCidUid[0]
    const cabinetId = parseInt(sidCidUid[1], 10)
    const userId = sidCidUid[2]
    const dateRange = this.dateRange[date]
    const user = this.usersMap[userId]
    const workTimes = this.scheduleGridGraph.schedule[specialtyId][cabinetId][userId][date]
    const scheduleTemplates = []

    workTimes.forEach((workTime) => {
      if (workTime.schedule_template_id) {
        scheduleTemplates.push(
          this.scheduleGridGraph.schedule_templates[userId]
            .find((template) => template.id === workTime.schedule_template_id),
        )
      }
    })

    this.workTimeModalData = {
      date,
      month: dateRange.month.toLowerCase(),
      dayOfMonth: dateRange.dayOfMonth,
      weekDay: dateRange.weekDayFull,
      user,
      defaultCabinet: cabinetId > 0 ? cabinetId : null,
      specialties: user.specialty_ids.map((sid) => this.specialtiesMap[sid]),
      workTimes,
      scheduleTemplates,
    }

    this.showWorkTimeModal = true
  },

  _vacationCellOnClick (event) {
    const userId = parseInt(event.currentTarget.attributes['data-user-id'].value)
    const vacationIds = JSON.parse(event.currentTarget.attributes['data-vacation-ids'].value)
    const date = event.currentTarget.attributes['data-date'].value

    if (vacationIds.length === 1) {
      this.showRemoveVacationModalHandler({userId, vacationId: vacationIds[0], date})
    } else if (vacationIds.length > 1) {
      this.selectVacationModalData = {
        userId,
        date,
        vacations: this.scheduleGridGraph.vacations[userId]
          .filter((vacation) => vacationIds.includes(vacation.id)),
      }
      this.showSelectVacationModal = true
    }
  },
}
