<template>
  <div
    v-show="show"
    class="panel panel-primary"
  >
    <epic-spinner
      :visibility="activeRequestState"
    />
    <div
      class="panel-heading panel-heading-flex"
    >
      <div
        class="panel-heading-title"
      >
        <span
          class="panel-heading-icon fad fa-fw fa-user-lock"
        />
        <h3 class="panel-title">
          {{ T.api_page.roles.creating_api_role }}
        </h3>
      </div>
      <div
        class="panel-heading-buttons"
      >
        <span
          class="panel_close_button fad fa-fw  fa-times hover-white-icon"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div
      class="panel-body"
    >
      <form
        class="simple_form flex-form"
      >
        <div
          class="form-label"
        >
          <label
            class="string required"
            for="api_role_title"
          >
            <span>
              {{ T.activerecord.attributes.api_client.title }}
            </span>
            <abbr
              :title="T.field_required"
            >
              *
            </abbr>
          </label>
        </div>
        <div
          class="form-value"
        >
          <div
            :class="titleError ? 'has-error' : ''"
            class="form-group string required api_client_title"
          >
            <input
              id="api_client_title"
              v-model="title"
              class="form-control string required tooltip-bottom"
              type="text"
              autocomplete="off"
            >
            <div
              v-if="titleError"
              class="under_field_error"
            >
              {{ titleError }}
            </div>
          </div>
        </div>
      </form>
      <div
        class="form-btns"
      >
        <div>
          <button
            :disabled="!formValid"
            class="btn btn-primary btn-sm btn-with-icon"
            @click="createApiRole"
          >
            <span class="btn-with-icon_icon fad fa-save" />
            <span class="btn-with-icon_text">{{ t('save') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'

export default {
  name: 'ApiRoleNew',

  components: { EpicSpinner },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    activeRequestState: false,
    title: '',
    titleError: null,
  }),

  computed: {
    formValid () {
      return this.title && this.title.trim().length > 0
    },
  },

  watch: {
    show () {
      if (this.show) this.resetForm()
    },
  },

  methods: {
    createApiRole () {
      const god = this

      god.activeRequestState = true

      $.ajax({
        type: 'POST',
        url: Routes.api_roles_path(),
        data: {
          api_role: {
            name: god.title,
          },
        },
        success (role) {
          god.$emit('created', role)
          god.resetForm()
        },
        error ({ status, responseJSON: errors }) {
          if (status !== 422) return

          if (Array.isArray(errors.title) && errors.title.length) {
            god.titleError = errors.title[0]
          }
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    resetForm () {
      this.title = ''
      this.titleError = null
    },
  },
}
</script>
