<template>
  <panel-heading>
    <si-generator
      :table-schema="tableSchema"
      :items="orderItems"
      :use-checkboxes="true"
      :search-value.sync="searchValue"
      :search-input-placeholder="'Яндекс, найдется всё'"
      :current-page.sync="currentPage"
      :total-pages="totalPages"
      @onRefreshData="() => {}"
      @onResetFilters="() => {}"
      @onItemClick="(item) => {}"
      @onSetItemSelected="(item) => {}"
      @onEditItemClick="(item) => {}"
      @onSetSelectedFields="(items) => {}"
      @onDeleteItem="deleteItem"
      @onRestoreItem="restoreItem"
      @onMassDelete="massDelete"
      @onMassRestore="massRestore"
    >
      <template #control-filters>
        <m-select
          class="mr-5"
          :m-fixed-height="false"
        />
        <m-select
          class="mr-5"
          :m-fixed-height="false"
        />
        <m-select
          class="mr-5"
          :m-fixed-height="false"
        />
        <m-select
          class="mr-5"
          :m-fixed-height="false"
        />
      </template>

      <!-- Значения полей "Номер" окрашено в danger -->
      <template
        v-for="item in orderItems"
        #tr-id="{ item }"
      >
        <div :key="`id:${item.id}`">
          <span class="danger">{{ item.id }}</span>
        </div>
      </template>

      <!-- Значения полей "Клиент" окрашено в warning -->
      <template
        v-for="item in orderItems"
        #tr-client="{ item }"
      >
        <div :key="`client:${item.id}`">
          <span
            v-tooltip="item.client"
            class="warning"
          >{{ item.client }}</span>
        </div>
      </template>

      <template #table-footer>
        <si-field>
          <div>тотал</div>
        </si-field>
      </template>
    </si-generator>
  </panel-heading>
</template>

<script>
import SiGenerator from '@/vue_components/sort_items/SiGenerator/SiGenerator'
import PanelHeading from '@/vue_components/common/panel_heading'
import { orderItems } from '@/vue_components/sort_items/SiGenerator/mocks'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'

export default {
  name: 'SiGeneratorExample',
  components: { MSelect, SiField, PanelHeading, SiGenerator },
  data () {
    return {
      tableSchema: {
        options: {
          edit: true,
          delete: true,
        },

        headers: {
          id: {
            title: 'Номер',
            css: 'italic',
          },

          date: 'Дата',
          paid_status: 'Статус',

          client: {
            title: 'Клиент',
            tooltip: true,
          },

          final_sum: 'К оплате',
          paid_sum: 'Оплачено',
        },
      },

      orderItems,
      orderItemsMap: {},
      searchValue: '',
      currentPage: 1,
      totalPages: 10,
    }
  },

  watch: {
    orderItems (to) {
      this.createOrderItemsMap(to)
    },
  },

  created () {
    this.createOrderItemsMap(this.orderItems)
  },

  methods: {
    createOrderItemsMap (orderItems = []) {
      const result = {}
      orderItems.forEach((item) => { result[item.id] = item })

      this.orderItemsMap = result
    },

    deleteItem (item) {
      item.deleted = true
    },

    restoreItem (item) {
      item.deleted = false
    },

    massDelete (items) {
      items.forEach((item) => { this.orderItemsMap[item.id].deleted = true })
    },

    massRestore (items) {
      items.forEach((item) => { this.orderItemsMap[item.id].deleted = false })
    },
  },
}
</script>
