<template>
  <treatment-plans-list-view
    :schema="schema"
    :items="list.data"
    :current-page="list.currentPage"
    :total-pages="list.totalPages"
    @onItemClick="onItemClick"
    @update:currentPage="list.setPage($event)"
  >
    <template #filters>
      <m-button
        v-if="isMedBranch"
        :text="t('add_tp_medical')"
        icon="briefcase"
        type="success"
        disabled="TreatmentPlan"
        @click="addTreatmentPlan('medical')"
      />

      <m-button
        v-if="isDentBranch"
        :text="t('add_tp_dental')"
        icon="tooth"
        type="success"
        disabled="TreatmentPlan"
        @click="addTreatmentPlan('dental')"
      />
    </template>
  </treatment-plans-list-view>
</template>

<script>
import { defineComponent } from 'vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import {
  emkTreatmentPlansListSchema,
} from '@/vue_apps/TreatmentPlans/TreatmentPlansList/store/TreatmentPlansListSchema'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { TreatmentPlansList } from '@/vue_apps/TreatmentPlans/TreatmentPlansList/store/TreatmentPlansList'
import TreatmentPlansListView from '@/vue_apps/TreatmentPlans/TreatmentPlansList/components/TreatmentPlansListView.vue'

export default defineComponent({
  name: 'TreatmentPlansListEmkApp',
  components: { TreatmentPlansListView, MButton },
  props: {
    clientId: PropsTypes.Number(),
    isMedBranch: Boolean,
    isDentBranch: Boolean,
  },

  data () {
    return {
      list: new TreatmentPlansList(this.clientId),
      schema: emkTreatmentPlansListSchema,
    }
  },

  computed: {
    filters () {
      return {
        ...this.list.filtersData,
        searchQuery: this.list.searchQuery,
        offset: this.list.offset,
        limit: this.list.limit,
      }
    },
  },

  watch: {
    filters () {
      this.list.fetchAll()
    },
  },

  created () {
    this.list.fetchAll()
  },

  methods: {
    addTreatmentPlan (tpType = 'medical') {
      window.open(
        Routes.new_treatment_plan_path({ client_id: this.clientId, kind: tpType }),
        '_blank'
      ).focus()
    },

    onItemClick ({ id }) {
      Turbolinks.visit(Routes.treatment_plan_path(id))
    },
  },
})
</script>
