export const KDL_WAYBILL_BIOMATERIALS_CONSTS = {
  ПЖК: 'Пробирка с желтой крышкой (гель) (5,0 мл)',
  ПСК2: 'Пробирка сиреневая с белым кольцом (2,0 мл) Грайнер',
  ПСК4: 'Пробирка сиреневая с черным кольцом (4,0 мл) Грайнер',
  ПСК10: 'Пробирка с сиреневой крышкой (ЭДТА) (10,0 мл)',
  ПГК: 'Пробирка с голубой крышкой и черным кольцом (цитрат) (5,0 мл)',
  ПЗК: 'Пробирка с зеленой крышкой (гепарин) (4,0 мл)',
  ПЗК6: 'Пробирка с зеленой крышкой (гепарин) (6,0 мл)',
  'ПЗК-ЖК': 'Пробирка с зеленой крышкой и желтым кольцом (сразделительным гелем)',
  ПСЕРК: 'Пробирка с серой крышкой и белым кольцом (на глюкозу) (2,0 мл)',
  ПРК: 'Пробирка с розовой крышкой (5,0 мл)',
  'ПСК-ПЦР': 'Пробирка сиреневая с желтым кольцом (гель) (ПЦР) (5 мл)',
  ПБЧК: 'Пробирка с белой крышкой и черным кольцом (без наполнителя)',
  ППК: 'Пробирка с пестрой крышкой со стабилизирующим консервантом (НИПТ)',
  ПЖК9: 'Пробирка с желтой крышкой с CPDA (9 мл)',
  МВ: 'Большая микровета с EDTA',
  ПСИНК15: 'Пробирка с синей крышкой (15 мл) (на кортизол в слюне)',
  ПСМО: 'Предметное стекло с матовым окошком (на цитологию)',
  ПС: 'Предметное стекло без матового окошка (на микроскопию)',
  КЖЦ: 'Контейнер с консервантом для жидкостной цитологии',
  ЭБТС: '«Эппендорф» белый с транспортной средой (на фемофлор, флороценоз, андрофлор)',
  МСТС: 'Микропробирка с сиреневой транспортной средой (на фемофлор, флороценоз, андрофлор)',
  ЭЖТС: '«Эппендорф» желтый для ПЦР со средой',
  ЭБЧП: '«Эппендорф» белый с черной полоской для ПЦР',
  ЭБС: '«Эппендорф» без среды',
  'СК-МОЧА': 'Стерильный контейнер с пробой мочи',
  СКЛ: 'Стерильный контейнер с пробой кала',
  'СК-СПЕРМА': 'Стерильный контейнер со спермой',
  'СК-СЛЮНА': 'Стерильный контейнер со слюной',
  СК: 'Стерильный контейнер с мокротой (др. биологическими  жидкостями)',
  КФ: 'Контейнер для гистологического исследования (10%  формальдегид)',
  ПБ: 'Готовые парафиновые блоки/стеклопрепараты',
  КБС: 'Коллектор без среды на энтеробиоз',
  'НЭК-СК': 'Набор на энтеробиоз: контейнер и стекло с клеем',
  ПСВК: 'Чашка Петри (влажная камера) (исследование на демодекс)',
  КВП: 'Конверт с ватными палочками (на установление родства)',
  ПакетБП: 'Пакет БП (базовая проба)',
  ПакетДП: 'Пакет ДП (диагностическая проба)',
  ИРМ: 'Иной расходный материал (только для розничных клиентов)',
  ЗП: 'Зип-пакет (волосы, др. биоматериал)',

  'МВ NaF': 'Микровета с NaF',
  SIBR0: 'Пакет (СИБР) 0 мин',
  SIBR15: 'Пакет (СИБР) 15 мин',
  SIBR30: 'Пакет (СИБР) 30 мин',
  SIBR45: 'Пакет (СИБР) 45 мин',
  SIBR60: 'Пакет (СИБР) 60 мин',
  SIBR75: 'Пакет (СИБР) 75 мин',
  SIBR90: 'Пакет (СИБР) 90 мин',
  SIBR120: 'Пакет (СИБР) 120 мин',
  'БМВ EDTA': 'Большая микровета с ЭДТА',
  КВЗВ: 'КВЗВ Клеточная взвесь',
  КомпВЖ: 'Комплект для взятия выпотных жидкостей',
  КомпМ: 'Комплект для взятия мокроты, БАЛ',
  КРИО: 'Криомешок',
  МГБС: 'Микропробирка с голубой крышкой без среды',
  'ПЖК-Бикарбон': 'Пробирка с желтой крышкой (бикарбонат)',
  'ПЖК-БН': 'Пробирка с желтой крышкой (без наполнителя)',
  'ПЖК-РС': 'Пробирка с желтой крышкой и розовой средой',
  ПКК4: 'Пробирка с красной крышкой',
  'ПКК-ЧК': 'Пробирка с красной крышкой и черным кольцом, 9 мл',
  ППМ: 'ППМ Пробирка для посева мочи',
  ППТС: 'Пробирка прозрачная с транспортной средой',
  ПСК5: 'Пробирка с сиреневой крышкой 5мл',
  ПСК6: 'Пробирка с сиреневой крышкой 6мл',
  'ПСинК-ПК': 'Пробирка с синей крышкой и красной полосой',
  'ПСинК-ПС': 'Пробирка с синей крышкой и сиреневой полосой',
  ПХ: 'Пробирка сухая стеклянная ПХ-14/ПХ-16',
  'СВАБ-ГК': 'СВАБ-система, голубая крышка',
  'СВАБ-ЗК': 'СВАБ-система, зеленая крышка',
  СККБ: 'Стерильный коллектор с Кери-Блаэр',
  'СКГС-КБ': 'Стерильный коллектор с гелевой средой Кэри-Блаэр',
  'СК-ВЖ': 'Стерильный контейнер (выпотные жидкости)',
  'СК-ВОЛОСЫ': 'Стерильный контейнер (волосы)',
  'СК-КАМНИ': 'Стерильный контейнер (камни)',
  'СК-ЛИКВОР': 'Стерильный контейнер (ликвор)',
  'СК-МОКРОТА': 'Стерильный контейнер (мокрота)',
  'СК-НОГТИ': 'Стерильный контейнер (ногти)',
  'СК-ПЦР': 'Стерильный контейнер (ПЦР)',
  'СК-СЖ': 'Стерильный контейнер (синовиальная жидкость)',
  'СК-ЦИТ': 'Стерильный контейнер (цитология)',
  'СКЛ-ПЦР': 'Стерильный контейнер с ложкой (ПЦР)',
  СКсВТ: 'СКсВТ Стерильный контейнер с ватным тампоном',
  'СКсВТ утро': 'Стерильный контейнер с ватным тампоном (утро)',
  'СКсВТ полдень': 'Стерильный контейнер с ватным тампоном (полдень)',
  'СКсВТ день': 'Стерильный контейнер с ватным тампоном (день)',
  'СКсВТ вечер': 'Стерильный контейнер с ватным тампоном (вечер)',
  'ФК-ЖК': 'Флакон для посева на стерильность с желтой крышкой',
  ФРТС: 'Флакон с розовой транспортной средой',
  ЭБПС: 'Эппендорф белый с питательной средой (5 мл)',
  ЭСТС: 'Эппендорф со специальной транспортной средой',
}

export const KDL_BIOLOGICAL_BIOMATERIALS = {
  ФТС: 'Флакон с зеленой крышкой (Мycоplasma, Ureaplasma)',
  'ПС + СВАБ-ФК': 'eSWAB с фиолетовой крышкой+предметное стекло+большой зип-пакет (на биоценоз влагалища)',
  'СВАБ-ФК': 'eSWAB с фиолетовой крышкой',
  'СВАБ-ОК': 'eSWAB с оранжевой крышкой',
  'СВАБ-БК': 'eSWAB с белой крышкой',
  'ПЖК-МОЧА': 'Пробирка с желтой крышкой для мочи (10 мл)',
  'СКЛ-БАК': 'Стерильный контейнер с пробой кала для БАК-посева, маркированный буквой "Б"',
  'СКМ-БАК': 'Стерильный контейнер с пробой мочи для БАК-посева, маркированный буквой "Б"',
  'СКС-БАК': 'Стерильный контейнер со спермой для БАК-посева, маркированный буквой "Б"',
  'СКБЖ-БАК': 'Стерильный контейнер с мокротой (слюной, др. биологическими жидкостями) для БАК-посева, маркированный буквой "Б"',
  СКУС: 'Стерильный коллектор с угольной средой для посева на Дифтерийную палочку',
  СКГС: 'Стерильный коллектор с гелевой средой для исследования на Стафилококк',
  ФПК: 'Флаконы со средой для посева крови на стерильность (комплект - 1 оранж.крышка+1 зел. крышка)',
}
