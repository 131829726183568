<template>
  <div class="helix_form">
    <div class="form-label">
      <label for="analysis_laboratory_entries_clinic_identifier">
        {{ t('laboratories.form_fields.clinic_identifier') }}
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationMessages.clinicIdentifier"
        fixed-height
      >
        <el-input
          id="analysis_laboratory_entries_clinic_identifier"
          :value="clinicIdentifier"
          :disabled="isReadOnly"
          name="analysis_laboratory[entries_clinic_identifier]"
          clearable
          @input="$updateSync('clinicIdentifier', $event)"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label for="analysis_laboratory_entries_helix_username">
        {{ t('laboratories.form_fields.username') }}
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationMessages.username"
        fixed-height
      >
        <el-input
          id="analysis_laboratory_entries_helix_username"
          :value="username"
          :disabled="isReadOnly"
          name="analysis_laboratory[entries_helix_username]"
          clearable
          @input="$updateSync('username', $event)"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label for="analysis_laboratory_entries_helix_password">
        {{ t('laboratories.form_fields.password') }}
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationMessages.password"
        fixed-height
      >
        <el-input
          id="analysis_laboratory_entries_helix_password"
          type="password"
          size="small"
          :value="password"
          :disabled="isReadOnly"
          name="analysis_laboratory[entries_helix_password]"
          clearable
          @input="$updateSync('password', $event)"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label for="analysis_laboratory_entries_helix_client_id">
        {{ t('laboratories.form_fields.client_id') }}
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationMessages.clientId"
        fixed-height
      >
        <el-input
          id="analysis_laboratory_entries_helix_client_id"
          :value="clientId"
          :disabled="isReadOnly"
          name="analysis_laboratory[entries_helix_client_id]"
          clearable
          @input="$updateSync('clientId', $event)"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label for="analysis_laboratory_entries_helix_client_secret">
        {{ t('laboratories.form_fields.client_secret') }}
      </label>
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationMessages.clientSecret"
        fixed-height
      >
        <el-input
          id="analysis_laboratory_entries_helix_client_secret"
          type="password"
          size="small"
          :value="clientSecret"
          :disabled="isReadOnly"
          name="analysis_laboratory[entries_helix_client_secret]"
          clearable
          @input="$updateSync('clientSecret', $event)"
        />
      </validation-wrapper>
    </div>

    <el-checkbox
      class="requestCostPrice-fields-checkbox w-100 mb-25"
      :disabled="isReadOnly"
      :value="requestCostPrice"
      @change="$updateSync('requestCostPrice', $event)"
    >
      <span class="font-size-13">
        {{ t('laboratories.form_fields.requestCostPrice') }}
      </span>
    </el-checkbox>

    <el-switch
      class="labeller-fields-checkbox"
      :disabled="isReadOnly"
      :value="labellerIntegration"
      :active-text="t('laboratories.form_fields.labeller_fields')"
      @change="$updateSync('labellerIntegration', $event)"
    />
    <div
      v-if="labellerIntegration"
      class="labeller-fields"
    >
      <div class="form-label">
        <label for="analysis_laboratory_entries_helix_labeller_id">
          {{ t('laboratories.form_fields.labeller_id') }}
        </label>

        <required-mark />
      </div>

      <div class="form-value">
        <validation-wrapper
          :errors="validationMessages.labellerId"
          fixed-height
        >
          <el-input
            id="analysis_laboratory_entries_helix_labeller_id"
            :value="labellerId"
            :disabled="isReadOnly"
            name="analysis_laboratory[entries_helix_labeller_id]"
            clearable
            @input="$updateSync('labellerId', $event)"
          />
        </validation-wrapper>
      </div>

      <div class="form-label">
        <label for="analysis_laboratory_entries_helix_labeller_password">
          {{ t('laboratories.form_fields.labeller_password') }}
        </label>

        <required-mark />
      </div>

      <div class="form-value">
        <validation-wrapper
          :errors="validationMessages.labellerPassword"
          fixed-height
        >
          <el-input
            id="analysis_laboratory_entries_helix_labeller_password"
            type="password"
            :value="labellerPassword"
            :disabled="isReadOnly"
            size="small"
            name="analysis_laboratory[entries_helix_labeller_password]"
            clearable
            @input="$updateSync('labellerPassword', $event)"
          />
        </validation-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import RequiredMark from '@/vue_components/common/RequiredMark/RequiredMark.vue'

export default {
  name: 'HelixForm',
  components: { ValidationWrapper, RequiredMark },

  props: {
    validationMessages: {
      type: Object,
      default: () => ({}),
    },

    username: {
      type: String,
      default: null,
    },

    password: {
      type: String,
      default: null,
    },

    clientId: {
      type: String,
      default: null,
    },

    clientSecret: {
      type: String,
      default: null,
    },

    clinicIdentifier: {
      type: String,
      default: null,
    },

    canManage: Boolean,

    labellerIntegration: Boolean,

    labellerId: {
      type: String,
      default: null,
    },

    labellerPassword: {
      type: String,
      default: null,
    },

    requestCostPrice: Boolean,
  },

  data () {
    return {
      labellerFieldsShow: false,
    }
  },

  computed: {
    isReadOnly () {
      return !this.canManage
    },
  },
}
</script>
