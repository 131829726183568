<template>
  <si-table>
    <template v-slot:header>
      <si-field>
        <div class="catalogs_table__title">
          <span>
            {{ t('title') }}
          </span>
        </div>

        <div
          v-if="!isShowForm"
          class="catalogs_table__company"
        >
          <span>
            {{ t('company') }}
          </span>
        </div>
      </si-field>
    </template>

    <template v-slot:body>
      <si-field
        v-for="dental in dentals"
        :key="dental.id"
        class="catalogs_table__item"
        :class="{active: dental.id === selectedListItem}"
        @click="$emit('ask-edit', dental)"
      >
        <div class="catalogs_table__title">
          <span class="fad fa-fw fa-tooth catalogs_table__icon" />

          <span class="cut">
            {{ dental.title }}
          </span>
        </div>

        <div v-if="!isShowForm">
          <span class="cut">
            {{ dental.company_title }}
          </span>
        </div>
      </si-field>
    </template>
  </si-table>
</template>

<script>
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'

export default {
  name: 'DentalCompaniesTable',
  components: {
    SiTable,
    SiField,
  },

  props: {
    dentals: {
      type: Array,
      default: () => [],
    },

    selectedListItem: {
      type: Number,
      default: 0,
    },

    isShowForm: Boolean,
  },
}
</script>
