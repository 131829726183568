import { createVueApp } from '@/vue_components/create_vue_app'
import OrderBoostPopup from '@/vue_apps/Orders/OrderBoostPopup/OrderBoostPopup.vue'
import { getStoresDisabledValue } from '@/vue_apps/Orders/helpers/getStoresDisabledValue'

let vueApp = null
export const createOrderBoostPopup = () => {
  if (vueApp?.destroy) { return vueApp.destroy() }

  vueApp = createVueApp({
    el: '#vue_order_boost_popup',
    name: 'OrderBoostPopupRoot',
    render: (h) => h(OrderBoostPopup, {
      props: {
        targetContainer: '#order_entries_table',
        targetSelectorClass: 'order-boost',
        useAssistants: gon.specific.useAssistants,
        storesDisabled: getStoresDisabledValue(),
      },
    }),
  })
}
