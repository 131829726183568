import { MODAL_HEADER_BUTTONS } from '@/vue_components/mixins/modals/const'

/**
 * Миксина для настройки модалок. Используется в компонентах, которые обёрнуты в
 * `<modal>`. Позволяет управлять отображением кнопок.
 *
 * Пример использования в waiting_create_modal.vue
 */
export const ModalConsumer = {
  data () {
    return {
      MODAL_HEADER_BUTTONS,
    }
  },
  computed: {
    modalButtonsAll () {
      // порядок кнопок в массиве не важен, он зафиксирован в шаблоне
      return Object.values(MODAL_HEADER_BUTTONS)
    },
  },
}
