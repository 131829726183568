<template>
  <div class="card-selector-container">
    <el-select
      v-model="cardSelector"
      multiple
      collapse-tags
      placeholder="Выберите карточки"
      class="head-desktop__card-selector"
    >
      <el-option
        v-for="item in cardsList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },

  computed: {
    cardsList () {
      //TODO vuex map
      return this.$store.state.headDesktopStore.cards
    },

    cardSelector: {
      get () {
        //TODO add one more getter
        return this.$store.getters['headDesktopStore/pickedCards'].map((card) => card.value)
      },
      set (newPickedCards) {
        return this.$store.commit('headDesktopStore/replacePickedCardIds', newPickedCards)
      },
    },
  },
}
</script>
