import { administratorsProductivityReportFiltersSelectors as selectors } from './filtersSelectors'
import { IAdministratorsProductivityReportFiltersSet } from './interfaces'
import { extractId } from '@/_medods_standart_library/msl'
import { LegacyFormSetters } from '@/helpers/LegacyFormSetters'
import { _getAdministratorsProductivityReportFilters } from './_filtersGetter'
import { getAdministratorsProductivityReportInitialFilters as getInitialFilters } from './initialFilters'

export const administratorsProductivityReportFiltersAccessors = {
  get: _getAdministratorsProductivityReportFilters,

  set (filtersToSet: IAdministratorsProductivityReportFiltersSet): void {
    const filters = { ...getInitialFilters(), ...filtersToSet }

    LegacyFormSetters.setDateRangePickerValue(selectors.period, filters.period)
    LegacyFormSetters.setSelectValue(selectors.clinic, filters.clinic.id)
    LegacyFormSetters.setButtons2Value(selectors.countType, filters.countType.id)
    LegacyFormSetters.setMultipleSelect2Value(selectors.appointmentTypes, filters.appointmentTypes.map(extractId) as string[])
  },
}
