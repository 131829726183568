<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>
    <si-table :spinner="isLoading">
      <template #header>
        <si-field>
          <si-column
            :active="sortableColumn"
            class="number"
            name="orderId"
            @sort-change="onSortChange"
          >
            {{ header.number }}
          </si-column>
          <si-column
            :active="sortableColumn"
            class="full_name cut"
            name="clientSurname"
            @sort-change="onSortChange"
          >
            {{ header.clientName }}
          </si-column>
          <si-column
            :active="sortableColumn"
            class="full_name cut"
            name="paymentPayer"
            @sort-change="onSortChange"
          >
            {{ header.customerName }}
          </si-column>
          <si-column
            :active="sortableColumn"
            class="date"
            name="orderDate"
            @sort-change="onSortChange"
          >
            {{ header.date }}
          </si-column>
          <si-column
            :active="sortableColumn"
            name="orderPaidStatus"
            @sort-change="onSortChange"
          >
            {{ header.status }}
          </si-column>
          <si-column
            :active="sortableColumn"
            class="amount"
            name="orderAmount"
            @sort-change="onSortChange"
          >
            {{ header.amount }}
          </si-column>
          <si-column
            :active="sortableColumn"
            class="sum"
            name="orderFinalSum"
            @sort-change="onSortChange"
          >
            {{ header.toPay }}
          </si-column>
          <si-column
            :active="sortableColumn"
            class="sum"
            name="orderPaymentsTotalsPaidSum"
            @sort-change="onSortChange"
          >
            {{ header.paid }}
          </si-column>
        </si-field>
      </template>
      <template
        v-if="rows.length"
        #body
      >
        <si-field
          v-for="row in rows"
          :key="row.id"
        >
          <div class="number">
            <a
              :href="row.id | route('order_path')"
              target="_blank"
            >
              {{ row.id }}
            </a>
          </div>
          <div class="full_name wrap">
            <a
              v-if="!row.client.deleted"
              :href="row.client.id | route('client_path')"
              target="_blank"
            >
              {{ row.client | clientName }}
            </a>
            <span v-else>
              {{ row.client | clientName }}
            </span>
          </div>
          <div class="full_name wrap">
            <a
              v-if="!row.deletedCustomer"
              :href="row | customerLink"
              target="_blank"
            >
              {{ row | customerName }}
            </a>
            <span v-else>
              {{ row | customerName }}
            </span>
          </div>
          <div class="date">
            {{ row.date | date }}
          </div>
          <div>
            {{ row.paidStatus | orderStatus }}
          </div>
          <div class="amount">
            <!-- amount does not com from the server, maybe delete the column? -->
            -
          </div>
          <div class="sum">
            {{ row.finalSum | price }}
          </div>
          <div class="sum">
            {{ row.paidSum | price }}
          </div>
        </si-field>
      </template>
      <template #footer>
        <si-field
          v-if="isDirty"
          class="total"
        >
          <div class="number">
            {{ footer.total }}
          </div>
          <div class="full_name" />
          <div class="full_name" />
          <div class="date" />
          <div />
          <div
            :data-tooltip="footer.amount"
            class="amount"
          >
            {{ footer.amount }}
          </div>
          <div
            :data-tooltip="footer.finalSum | currency"
            class="sum"
          >
            {{ footer.finalSum | currency }}
          </div>
          <div
            :data-tooltip="footer.paid | currency"
            class="sum"
          >
            {{ footer.paid | currency }}
          </div>
        </si-field>
      </template>
    </si-table>
    <si-footer v-if="rows.length">
      <pagination
        class="hidden-print"
        :page-count="pageCount"
        layout="prev, pager, next"
        @current-change="loadData"
      />
    </si-footer>
  </si>
</template>

<script>
/**
 * @typedef {Object} Client
 * @property {number} id
 * @property {string} name
 * @property {string} secondName
 * @property {string} surname
 * @property {string} deletedAt
 */
/**
 * @typedef {Object} Company
 * @property {number} id
 * @property {string} title
 */
/**
 * @typedef {Object} Order
 * @property {Client} client
 * @property {Client} [customerClient]
 * @property {Company} [customerCompany]
 * @property {Client|Company} [customer]
 * @property {string} date
 * @property {number} finalSum
 * @property {number} id
 * @property {number} paidStatus
 * @property {number} paidSum
 */
/**
 * @typedef {{data: {order: Order}[]}} OrdersData
 */
/**
 * @typedef {{total: {amount: number, finalSum: number, paidSum: number}}} OrdersTotal
 */
/**
 * @typedef {ReportEntry & OrdersData & OrdersTotal} OrdersReportData
 */
import Pagination from '@/vue_components/common/pagination.vue'
import Report from '@/vue_components/mixins/report.js'
import SortableReport from '@/vue_components/mixins/sortableReport.js'
import ReportWithTooltips from '@/vue_components/mixins/withTooltips.js'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiColumn from '@/vue_components/sort_items/si_table/si_column.vue'
import Si from '@/vue_components/sort_items/si.vue'
import formatter from '@/lib/formatters/formatter'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

export default {
  name: 'Orders',

  components: {
    Pagination,
    Si,
    SiTable,
    SiField,
    SiFooter,
    SiColumn,
  },

  filters: {
    clientName (client) {
      return formatter.formatValue(client, ['client:fullName'])
    },
    orderStatus (status) {
      return formatter.formatValue(status, ['order:paidStatus'])
    },
    customerName (order) {
      if (order.customerClient) {
        return formatter.formatValue(order.customerClient, ['client:fullName'])
      } else if (order.customerCompany) {
        return order.customerCompany.title
      }
    },
    customerLink (order) {
      if (order.customerClient) {
        return Routes.client_path(order.customerClient.id)
      } else if (order.customerCompany) {
        return Routes.company_path(order.customerCompany.id)
      }
    },
  },

  mixins: [
    Report,
    SortableReport,
    ReportWithTooltips,
  ],

  props: {
    clinic: { type: Object, required: true },
    period: { type: String, default: '' },
    orderPaidStatus: { type: Object, required: true },
  },

  data () {
    return {
      header: {
        number: '№',
        clientName: t('client'),
        customerName: t('customer'),
        date: t('common.date'),
        status: t('status'),
        amount: t('amount'),
        toPay: t('to_pay'),
        paid: t('paided'),
      },
    }
  },
  computed: {
    subtitleParts () {
      return [
        this.clinic.name,
        this.period,
        this.orderPaidStatus.name,
      ]
    },
  },

  created () {
    this.reportClient.init({
      url: Routes.report_orders_path(),
    })
    this.requestBuilder.addStep(() => ReportFiltersFactory.defineFor('orders', this))
  },

  methods: {
    /**
     * @param {OrdersReportData} result
     */
    fillTable (result) {
      this.rows = result.data.map(({ order }) => {
        // check if customer is deleted
        order.deletedCustomer = (order.customerClient || order.customerCompany).deletedAt

        return order
      })

      this.footer = {
        total: t('total'),
        amount: result.total.amount,
        finalSum: result.total.finalSum,
        paid: result.total.paidSum,
      }
    },

    afterReportUpdated () {
      this.updateTooltips()
    },

    handleErrors (response) {
      if (!response.ok) throw Error(response.status)

      return response.json()
    },
  },

}
</script>
