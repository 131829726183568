<template>
  <si-field>
    <div class="title wrap">
      {{ t('total') }}
    </div>
    <div
      v-if="false"
      class="comment wrap"
    />
    <div class="client wrap" />
    <div class="date" />
    <div class="price" />
    <div class="price-with-discount" />
    <div class="amount" />
    <div class="sum wrap">
      {{ $filters.currency(footer.finalSum) }}
    </div>
    <div class="reward" />
    <div class="reward-sum">
      {{ $filters.currency(footer.rewardFinalSum) }}
    </div>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { ReferralFooter } from '@/vue_components/reports/referrals/footer/referralFooter'

export default {
  name: 'ReferralsFooterUngroupedReferral',
  components: {
    SiField,
  },
  mixins: [ReferralFooter],
}
</script>
