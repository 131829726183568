import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'

export class DoctorSummarySalaryReportItem extends MListServiceNestedItemsItem {
  /**
   * @param {number} id
   * @param {TSalaryEntryType} entryType */
  constructor (id, entryType) {
    super(id, entryType)
    this.entry = entryType.entry
    this.number = entryType.number
    this.salaryEntry = entryType.salaryEntry
    this.title = entryType.title
  }

  get tableNumber () {
    return new MTableCell(this.number || '-')
  }

  get tableTitle () {
    return new MTableCell(this.title)
      .addTooltip(this.title)
      .addCut()
  }

  get tableEntryAmount () {
    return new MTableCell(this.entry.amount)
  }

  get tableEntrySum () {
    return new MTableCell(vueFilters.price(this.entry.sum))
  }

  get tableRate () {
    return new MTableCell(`${vueFilters.price(this.salaryEntry.rate)} ${vueFilters.rateType(this.salaryEntry.rateType)}`)
  }

  get tableSalary () {
    return new MTableCell(vueFilters.price(this.salaryEntry.sum))
  }
}
