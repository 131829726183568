import { MFetchClient } from '@/_api/_requests/MFetchClient'

/**
 * This class fetches data from report endpoints.
 */
export default class ReportClient {
  constructor (url) {
    this.params = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this._getCSRFToken(),
      },
      credentials: 'same-origin',
    }

    this.url = url
  }

  /**
   * @param {object} params
   * @param {string} params.url
   * @deprecated
   */
  init (params) {
    this.url = params.url
  }

  /**
   * @param {object} body
   * @deprecated
   */
  get (body) {
    this.params.body = JSON.stringify(body)

    return fetch(this.url, this.params)
      .then(this._handleErrors)
      .catch(this._onError.bind(this))
  }

  fetch (body) {
    this.params.body = JSON.stringify(body)

    return new MFetchClient().fetch(this.url, this.params)
  }

  /**
   * @param {Error} error
   * @private
   */
  _onError (error) {
    console.error(error)
    throw error
  }

  /**
   * @private
   * @return {string}
   */
  _getCSRFToken () {
    return document.querySelector('meta[name="csrf-token"]').content
  }

  /**
   * @param {Response} response
   * @return {Promise<object>}
   * @private
   */
  _handleErrors (response) {
    if (!response.ok) {
      console.error('Report Error:', response)
      throw Error(response.status.toString())
    }

    return response.json()
  }
}
