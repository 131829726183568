import { createUserServicesRateApp } from '@/vue_apps/UserServicesApp'

export const SALARY_TAB = '#salary_tab'

/**
 * TODO: удалить после переписывания справочника врачей
 */
const usePanelWidthCrutch = () => {
  const WIDTH_OPENED = '47.4vw'
  const WIDTH_HIDDEN = '54vw'

  const catalogs = $('.catalogs-list')
  const panel = $('.panel-edit')

  const recalculate = () => {
    panel.css({
      maxWidth: catalogs.hasClass('hide-catalogs-list')
        ? WIDTH_HIDDEN
        : WIDTH_OPENED,
    })
  }

  recalculate()
  $('#hide-catalogs, #show-catalogs').on('click', recalculate)
}

let vueApp = null
export const registerSalaryApp = () => {
  usePanelWidthCrutch()

  const { hash } = new URL(location.href)
  if (hash === SALARY_TAB) {
    if (vueApp?.destroy) { vueApp.destroy() }
    vueApp = createUserServicesRateApp()

    return
  }

  $(`[href="${SALARY_TAB}"]`).on('click', () => {
    vueApp = createUserServicesRateApp()
    $('[href="#salary_tab"]').off('click')
  })
}
