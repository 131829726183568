<template>
  <report-base
    class="tax-certificates-report"
    :report="report"
    :schema="schema"
  >
    <tax-certificates-filters :report="report" />
  </report-base>
</template>

<script>
import { TaxCertificatesReport } from '@/vue_apps/ReportsModule/TaxSertificatesReport/api/TaxCertificatesReport'
import { getTaxReportSchema } from '@/vue_apps/ReportsModule/TaxSertificatesReport/store/getTaxReportSchema'
import ReportBase from '@/vue_apps/ReportsModule/_ReportBase/ReportBase.vue'
import TaxCertificatesFilters from '@/vue_apps/ReportsModule/TaxSertificatesReport/TaxCertificatesFilters.vue'
import {
  taxCertificatesReportSortingMap,
} from '@/vue_apps/ReportsModule/TaxSertificatesReport/store/taxCertificatesReportSortingMap'

export default {
  name: 'TaxCertificatesReportApp',
  components: {
    TaxCertificatesFilters,
    ReportBase,
  },

  data () {
    return {
      report: new TaxCertificatesReport(taxCertificatesReportSortingMap),
      schema: getTaxReportSchema(),
    }
  },
}
</script>
