import { APPOINTMENT_STATUS, MIN_DURATION } from './const'

export default {
  disabled: {
    type: Boolean,
  },

  clinicId: {
    type: Number,
    default: null,
  },
  /**
   * @type {import('vue').PropOptions<Object>}
   *
   * attractionSourceId: required
   */
  validationMessages: {
    type: Object,
    required: true,
  },

  serviceSearchQuery: {
    type: String,
    default: '',
  },

  /**
   * @type {import('vue').PropOptions<{id: Number, number:String, analysis_laboratory: Object, title: String, price: Number}[]>}
   *
   * id - нужен для entryTypes
   * number - используется для поиска
   * analysis_laboratory.title - название лаборатории (если есть)
   * title - название услуги
   * price - цена
   */
  servicesSearchResultArray: {
    type: Array,
    default: () => [],
  },

  serviceSearchAllowSearch: {
    type: Boolean,
    default: false,
  },

  serviceSearchLoading: {
    type: Boolean,
    default: false,
  },

  serviceNotResults: {
    type: Boolean,
    default: false,
  },

  /**
   * @type {import('vue').PropOptions<{id: Number, title: String, price: Number}[]>}
   */
  entryTypes: {
    type: Array,
    default: () => [],
  },

  currencySymbol: {
    type: String,
    required: true,
  },

  currencyFormat: {
    type: Object,
    required: true,
  },

  note: {
    type: String,
    default: '',
  },

  clientComment: {
    type: String,
    default: '',
  },

  /**
   * @type {import('vue').PropOptions<Number[]>}
   */
  durationsArray: {
    type: Array,
    default: () => [],
    required: true,
  },

  duration: {
    type: Number,
    default: MIN_DURATION,
    required: true,
  },

  minDuration: {
    type: Number,
    default: MIN_DURATION,
    required: false,
  },

  /* требует подсчёта, work_time.end - current_appointment.time */
  maxDuration: {
    type: Number,
    required: true,
  },

  /**
   * @type {import('vue').PropOptions<{id: Number, title: String}[]>}
   */
  appointmentTypesArray: {
    type: Array,
    default: () => [],
    required: true,
  },

  appointmentTypeId: {
    type: Number,
    default: null,
    validator: (prop) => typeof prop === 'number' || prop === null,
  },

  appointmentTypeIdDefault: {
    type: Number,
    default: 1,
  },

  referral: {
    type: Object,
    default: () => null,
  },

  attractionSourceId: {
    type: Number,
    default: null,
  },

  /**
   * @type {import('vue').PropOptions<{id: Number, title: String, default: boolean}[]>}
   */
  attractionSourceArray: {
    type: Array,
    default: () => [],
  },

  byDms: {
    type: Boolean,
    default: false,
  },

  appointmentStatus: {
    type: String,
    default: APPOINTMENT_STATUS.NEED_APPROVAL,
    required: true,
  },

  /**
   * @type {import('vue').PropOptions<{id: String, title: String}[]>}
   */
  appointmentSource: {
    type: Object,
    default: () => ({}),
    required: true,
  },

  addToWaitingList: {
    type: Boolean,
    default: false,
  },

  smsRemindOption: {
    type: Boolean,
    default: false,
  },

  smsNotifyOption: {
    type: Boolean,
    default: false,
  },

  smsConfirmationOption: {
    type: Boolean,
    default: false,
  },

  uisRemindOption: {
    type: Boolean,
    default: false,
  },

  uisConfirmOption: {
    type: Boolean,
    default: false,
  },

  /* Чекбоксы */
  /* Напомнить по смс */
  remindBeforeDate: {
    type: Boolean,
    default: false,
  },

  /* Уведомить по смс */
  notifyAfterSave: {
    type: Boolean,
    default: false,
  },

  /* Отправить SMS с просьбой подтвердить запись */
  sendSmsConfirmation: {
    type: Boolean,
    default: false,
  },

  /* Напомнить звонком */
  remindByCall: {
    type: Boolean,
    default: false,
  },

  /* Подтвердить звонком */
  confirmByCall: {
    type: Boolean,
    default: false,
  },

  /* Для видимости stub-модалок */
  remindBeforeDateStubModalVisible: {
    type: Boolean,
    default: false,
  },

  notifyAfterSaveStubModalVisible: {
    type: Boolean,
    default: false,
  },

  sendSmsConfirmationStubModalVisible: {
    type: Boolean,
    default: false,
  },

  remindByCallStubModalVisible: {
    type: Boolean,
    default: false,
  },

  confirmByCallStubModalVisible: {
    type: Boolean,
    default: false,
  },

  /**
   * Последнее sms подтверждение
   *
   * @type {import('vue').PropOptions<{date: [String|Date], status: String}>}
   */
  lastSmsConfirmation: {
    type: Object,
    default: () => ({}),
  },

  /**
   * Последнее sms уведомление
   *
   * @type {import('vue').PropOptions<{date: [String|Date], status: String}>}
   */
  lastSmsNotify: {
    type: Object,
    default: () => ({}),
  },

  /**
   * Последнее sms напоминание
   *
   * @type {import('vue').PropOptions<{date: [String|Date], status: String}>}
   */
  lastSmsRemind: {
    type: Object,
    default: () => ({}),
  },

  createdBy: {
    type: String,
    default: '',
  },

  updatedBy: {
    type: String,
    default: '',
  },
}
