
const STOP_FIELDS = Object.freeze([
  'performer',
  'assistant',
  'store',
  'referral',
])

const getAttributeFilter = (fields = []) => {
  return fields
    .map((field) => `[data-entity="${field}"] input`)
    .join(', ')
}

export function dropNamesFromFields ($container) {
  const attributeFilter = getAttributeFilter(STOP_FIELDS)
  const $stopFields = $container.find(attributeFilter)

  $stopFields.each((i, item) => {
    item.removeAttribute('name')
  })
}
