<template>
  <div class="col-container">
    <div class="panel panel-primary">
      <epic-spinner
        :visibility="activeRequestState"
      />
      <div class="panel-heading panel-heading-flex">
        <div class="panel-heading-title">
          <span class="panel-heading-icon fad fa-fw fa-file-export" />
          <h3 class="panel-title">
            {{ T.export }}
          </h3>
        </div>
      </div>
      <div class="panel-body panel-list-body">
        <el-switch
          v-model="withLocale"
          style="display: block"
          active-color="#3FAECA"
          inactive-color="#6c6c6c"
          :active-text="T.exports_page.withLocale"
          :inactive-text="T.exports_page.withoutLocale"
        />
        <el-collapse>
          <clients-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <client-groups-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <users-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <referrals-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <orders-export
            :with-locale="withLocale"
            :clinics="clinics"
            @request-state="requestState"
          />

          <payments-export
            :with-locale="withLocale"
            :clinics="clinics"
            @request-state="requestState"
          />

          <entries-export
            :with-locale="withLocale"
            :clinics="clinics"
            @request-state="requestState"
          />

          <entry-types-export
            :with-locale="withLocale"
            :entry-types-kinds="entry_types"
            @request-state="requestState"
          />

          <specialties-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <clinics-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <appointments-export
            :with-locale="withLocale"
            :clinics="clinics"
            @request-state="requestState"
          />

          <appointment-types-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <companies-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <company-types-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <!-- <discount-systems-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <accounts-export
            :with-locale="withLocale"
            @request-state="requestState"
          /> -->

          <attraction-sources-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <analysis-laboratories-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <expenses-export
            :with-locale="withLocale"
            :clinics="clinics"
            @request-state="requestState"
          />

          <expense-groups-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <stores-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <receipt-goods-export
            :with-locale="withLocale"
            @request-state="requestState"
          />

          <receipt-goods-items-export
            :with-locale="withLocale"
            @request-state="requestState"
          />
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
// import AccountsExport from './components/accounts.vue'
import AnalysisLaboratoriesExport from './components/analysis_laboratories.vue'
import AppointmentTypesExport from './components/appointment_types.vue'
import AppointmentsExport from './components/appointments.vue'
import AttractionSourcesExport from './components/attraction_sources.vue'
import ClientGroupsExport from './components/client_groups.vue'
import ClientsExport from './components/clients.vue'
import ClinicsExport from './components/clinics.vue'
import CompaniesExport from './components/companies.vue'
import CompanyTypesExport from './components/company_types.vue'
// import DiscountSystemsExport from './components/discount_systems.vue'
import EntriesExport from './components/entries.vue'
import EntryTypesExport from './components/entry_types.vue'
import ExpenseGroupsExport from './components/expense_groups.vue'
import ExpensesExport from './components/expenses.vue'
import OrdersExport from './components/orders.vue'
import PaymentsExport from './components/payments.vue'
import ReceiptGoodsExport from './components/receipt_goods.vue'
import ReceiptGoodsItemsExport from './components/receipt_goods_items.vue'
import ReferralsExport from './components/referrals.vue'
import SpecialtiesExport from './components/specialties.vue'
import StoresExport from './components/stores.vue'
import UsersExport from './components/users.vue'

import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'

export default {
  name: 'ExportPage',

  components: {
    // AccountsExport,
    AnalysisLaboratoriesExport,
    AppointmentTypesExport,
    AppointmentsExport,
    AttractionSourcesExport,
    ClientGroupsExport,
    ClientsExport,
    ClinicsExport,
    CompaniesExport,
    CompanyTypesExport,
    // DiscountSystemsExport,
    EntriesExport,
    EntryTypesExport,
    ExpenseGroupsExport,
    ExpensesExport,
    OrdersExport,
    PaymentsExport,
    ReceiptGoodsExport,
    ReceiptGoodsItemsExport,
    ReferralsExport,
    SpecialtiesExport,
    StoresExport,
    UsersExport,
    EpicSpinner,
  },

  data: () => ({
    activeRequestState: false,
    clinics: gon.application.current_user_clinics,
    entry_types: gon.specific.entry_types,
    users: gon.specific.users,
    withLocale: false,
  }),

  methods: {
    requestState () {
      this.activeRequestState = !this.activeRequestState
    },
  },

}
</script>
